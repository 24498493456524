import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { SidepanelPatientVitalsAdd as SidepanelPatientVitalsAddPage } from 'src/pages/PatientChartsPage/PatientResults/SidepanelPatientVitalsAdd'

const SidepanelPatientVitalsAdd = () => {
  return (
    <SidepanelPage testId="sidepanel-vitals-add" header={`Add Vitals`}>
      <SidepanelPatientVitalsAddPage />
    </SidepanelPage>
  )
}

export default SidepanelPatientVitalsAdd
