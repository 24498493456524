import type {
  FindPracticeFormQuery,
  FindPracticeFormQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { practiceFormTypeDisplay } from 'src/data/practiceformTypes'

import SidepanelDocumentPage from '../organisms/SidepanelDocumentPage/SidepanelDocumentPage'

export const QUERY = gql`
  query FindPracticeFormQuery($id: String!) {
    practiceForm(id: $id) {
      id
      version
      isCurrent
      createdAt
      createdBy {
        id
        givenName
        familyName
      }
      updatedAt
      updatedBy {
        id
        givenName
        familyName
      }
      binary {
        id
        content {
          url
          contentType
        }
      }
      practiceFormDefinition {
        id
        isRequired
        type
      }
      formSchemas
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindPracticeFormQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  practiceForm,
}: CellSuccessProps<FindPracticeFormQuery, FindPracticeFormQueryVariables>) => {
  return (
    <SidepanelDocumentPage
      header={`Practice form: ${
        practiceFormTypeDisplay[practiceForm.practiceFormDefinition.type]
      }`}
      subHeader={`Version ${practiceForm.version} ${
        practiceForm.isCurrent && practiceForm.practiceFormDefinition.isRequired
          ? '(Required for all patients to sign)'
          : ''
      }`}
      document={practiceForm}
      testId="practice-form-view"
      faxable
      editRoute={`/documents/practice-form/${practiceForm.id}/edit`}
    />
  )
}
