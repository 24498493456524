import locale from 'date-fns/locale/en-US'

const getWeekdays = () => {
  return [...Array(7).keys()].reduce((acc, dayNum) => {
    acc[locale.localize.day(dayNum).toUpperCase().slice(0, 3)] =
      locale.localize.day(dayNum)
    return acc
  }, {})
}

export default getWeekdays
