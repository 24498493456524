import type { InsuranceOptOutStatus } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const insuranceOptOutStatusDisplay: {
  [key in InsuranceOptOutStatus]: string
} = {
  INSURED_SELF_PAY: 'Insured, self-pay',
  UNINSURED_SELF_PAY: 'Uninsured, self-pay',
  INSURANCE_PENDING: 'Insurance pending',
  OTHER: 'Other',
}

export const insuranceOptOutStatusDescription: {
  [key in InsuranceOptOutStatus]: string | undefined
} = {
  INSURED_SELF_PAY:
    'Patient (or guarantor) would like to self-pay even with active health insurance coverage available.',
  UNINSURED_SELF_PAY:
    'Patient does not have health insurance, and will self-pay for healthcare services (directly or through guarantor).',
  INSURANCE_PENDING:
    "Patient's health insurance coverage is in-progress and not yet finalized.",
  OTHER: undefined,
}

export const insuranceOptOutStatuses =
  nameValueArrayFromObject<InsuranceOptOutStatus>(insuranceOptOutStatusDisplay)
