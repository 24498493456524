import { useParams } from 'react-router-dom'

import Box from 'src/components/atoms/Box/Box'
import OutboundFaxCell from 'src/pages/OutboundFaxPage/OutboundFaxCell'

const SidepanelOutboundFax = () => {
  const { outboundFaxId } = useParams()
  return (
    <Box verticalPadding={75} horizontalPadding={100} className="grow">
      <OutboundFaxCell outboundFaxId={outboundFaxId} />
    </Box>
  )
}

export default SidepanelOutboundFax
