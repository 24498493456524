import { useState } from 'react'

import { ArrowUturnRightIcon } from '@heroicons/react/20/solid'
import { formatDisplayName } from 'common/utils'
import {
  CreateCaseRoutedEvent,
  GetPractitionersAndUserPools,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import Button from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import { Input } from 'src/components/atoms/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import NotificationSuccess from 'src/components/molecules/Notification/Notification'
import PopoverButton from 'src/components/PopoverButton/PopoverButton'
import { useSelectNextTask } from 'src/hooks/useHandleCaseResolution/useHandleCaseResolution'

const QUERY = gql`
  query GetPractitionersAndUserPools {
    taskUserPools {
      id
      displayName
    }
    users {
      id
      givenName
      familyName
    }
  }
`
const CREATE_CASE_ROUTED_EVENT = gql`
  mutation CreateCaseRoutedEvent($input: CreateCaseRoutedEventInput!) {
    createCaseRoutedEvent(input: $input) {
      id
      routedToPoolId
      routedToTaskUserPool {
        id
        displayName
      }
      routedToUser {
        id
        givenName
        familyName
      }
      createdBy {
        id
        givenName
        familyName
      }
      baseCase {
        id
        assignedUserId
        assignedTaskUserPoolId
        dueAt
        assignedUser {
          id
          familyName
          givenName
        }
        assignedTaskUserPool {
          id
          displayName
          users {
            id
            givenName
            familyName
            userType {
              id
              name
            }
          }
        }
      }
    }
  }
`
export interface RouteToDropdownProps {
  caseId: string
  baseCaseId: string
  code: number
  disabled?: boolean
}

const RouteToDropdown = ({
  caseId,
  baseCaseId,
  code,
  disabled = false,
}: RouteToDropdownProps) => {
  const { data } = useQuery<GetPractitionersAndUserPools>(QUERY)
  const { selectNextTask } = useSelectNextTask()
  const [createCaseRoutedEvent] = useMutation<CreateCaseRoutedEvent>(
    CREATE_CASE_ROUTED_EVENT,
    {
      refetchQueries: [
        'GetActivityItems',
        'GetNotResolvedCasesCountByType',
        'GetTaskNotificationCount',
        'FindDocumentCases',
        'FindResultsReviewCases',
        'FindSchedulingCases',
        'FindScreeningCases',
        'FindStaffMessageCases',
      ],
      onCompleted: (data) => {
        selectNextTask({ caseId })

        const { routedToUser, routedToTaskUserPool } =
          data.createCaseRoutedEvent

        const routedToDisplayName = routedToUser
          ? formatDisplayName(routedToUser)
          : routedToTaskUserPool.displayName

        const toastId = `toast-routed-to-${code}-${
          routedToUser?.id ?? routedToTaskUserPool.id
        }`
        toast.custom(
          () => {
            return (
              <NotificationSuccess
                title={`Task ${code} routed to ${routedToDisplayName}`}
                onClose={() => {
                  toast.dismiss(toastId)
                }}
              />
            )
          },
          {
            duration: 4000,
            position: 'top-right',
            id: toastId,
          }
        )
      },
    }
  )
  const [filter, setFilter] = useState('')

  const sections = [
    {
      type: 'userPool' as const,
      heading: 'User pools',
      items: data?.taskUserPools?.map((pool) => ({
        value: pool.id,
        name: pool.displayName,
      })),
    },
    {
      type: 'user' as const,
      heading: 'Users',
      items: data?.users?.map((user) => ({
        value: user.id,
        name: formatDisplayName(user),
      })),
    },
  ]
    .map((section) => ({
      ...section,
      items: section.items.filter((option) =>
        option.name.toLowerCase().includes(filter.toLowerCase())
      ),
    }))
    .filter((section) => section.items.length)

  return (
    <div className="relative">
      <PopoverButton
        buttonProps={{
          disabled,
          buttonStyle: 'secondary',
          icon: ArrowUturnRightIcon,
          text: 'Route',
          testId: 'route-case-button',
        }}
        panelWidth="w-core-space-1500"
        renderPanel={({ close }) => (
          <Card className="p-core-space-50" testId="route-case-container">
            <Input
              name="search-task-routing-options"
              placeholder="Search..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              fullWidth
            />
            <StackView
              direction="col"
              alignItems="start"
              className="max-h-core-space-2000 overflow-y-auto pt-core-space-50"
              gap={50}
            >
              {sections.map((section) => (
                <StackView key={section.heading}>
                  <Typography
                    textStyle="interface-strong-xs"
                    color="text-base-color-fg-subtle"
                    className="px-core-space-50"
                  >
                    {section.heading}
                  </Typography>
                  {section.items.map((item) => (
                    <Button
                      key={item.value}
                      text={item.name}
                      buttonStyle="ghost"
                      justifyContent="start"
                      onClick={() => {
                        createCaseRoutedEvent({
                          variables: {
                            input: {
                              baseCaseId,
                              routedToTaskUserPoolId:
                                section.type === 'userPool'
                                  ? item.value
                                  : undefined,
                              routedToUserId:
                                section.type === 'user'
                                  ? item.value
                                  : undefined,
                            },
                          },
                        })
                        close()
                        setFilter('')
                      }}
                    />
                  ))}
                </StackView>
              ))}
            </StackView>
          </Card>
        )}
      />
    </div>
  )
}

export default RouteToDropdown
