import {
  BillingCodeFilter,
  BillingCodeSearchSet,
  SearchBillingCodes,
} from 'types/graphql'

import { RegisterOptions } from '@redwoodjs/forms'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { nameValueArrayFromObject } from 'src/data/utils'

import AdvancedSearchField from '../AdvancedSearchField/AdvancedSearchField'

const SEARCH_BILLING_CODES_QUERY = gql`
  query SearchBillingCodes(
    $input: BillingCodeSearchInput!
    $filters: [BillingCodeFilter!]
  ) {
    searchBillingCodes(input: $input, filters: $filters) {
      id
      code
      description
    }
  }
`

type SearchResult = SearchBillingCodes['searchBillingCodes'][0]

const filterOptionsDisplay: {
  [key in BillingCodeFilter]: string
} = {
  FAVORITE: 'Favorite billing codes',
  BY_APPOINTMENT_TYPE: 'Common billing codes for current visit type',
}

const SelectedValue = (billingCode: SearchResult) => (
  <StackView>
    <Typography textStyle="subtitle">{billingCode.code}</Typography>
    <Typography size="s" color="text-base-color-fg-muted">
      {billingCode.description}
    </Typography>
  </StackView>
)

const SearchResultItem = (billingCode: SearchResult) => (
  <>
    <Typography className="shrink-0 basis-20" fontWeight="medium">
      {billingCode.code}
    </Typography>
    <Typography textStyle="description">{billingCode.description}</Typography>
  </>
)

export type BillingCodeSearchFieldProps = {
  name: string
  patientId: string
  searchSet: BillingCodeSearchSet
  encounterId?: string
  validation?: RegisterOptions
  defaultSearching?: boolean
}

const BillingCodeSearchField = ({
  name,
  searchSet,
  encounterId,
  validation,
  defaultSearching,
}: BillingCodeSearchFieldProps) => {
  const filterOptions = nameValueArrayFromObject(filterOptionsDisplay).filter(
    (option) => {
      if (encounterId) {
        return option.value !== 'BY_APPOINTMENT_TYPE'
      }
    }
  )

  return (
    <AdvancedSearchField
      name={name}
      placeholder={'Search and add a billing code...'}
      query={SEARCH_BILLING_CODES_QUERY}
      queryKey={'searchBillingCodes'}
      queryInput={{
        encounterId,
        searchSet,
      }}
      searchPlaceholder={'Search by billing code or keyword'}
      emptyResultMessage={'No billing codes to display'}
      valueRenderer={SelectedValue}
      resultRenderer={SearchResultItem}
      filterOptions={filterOptions}
      defaultFilters={filterOptions.map((option) => option.value)}
      validation={validation}
      defaultSearching={defaultSearching}
    />
  )
}

export default BillingCodeSearchField
