import { portalUserStatusDisplay } from 'common/patientPortalAuth'
import { match } from 'ts-pattern'
import { PatientPortalUserStatus } from 'types/graphql'

import { BadgeColor } from 'src/components/atoms/Badge'
import Badge from 'src/components/atoms/Badge/Badge'
import { Tooltip } from 'src/components/molecules/Tooltip/Tooltip'

export const PortalUserStatusBadge = (props: {
  userType: 'patient' | 'relatedPerson'
  status: PatientPortalUserStatus
  testId?: string
}) => {
  const { testId, status } = props

  return (
    <Tooltip
      maxWidth={400}
      text={match(props)
        .with({ status: 'ACTIVE' }, () => null)
        .with(
          { status: 'DISABLED' },
          () =>
            "This individual's family portal access is disabled due to a duplicate mobile number issue that has not yet been resolved."
        )
        .with(
          { status: 'INELIGIBLE', userType: 'patient' },
          () => 'This patient is age 12 or younger, inactive, and/or deceased.'
        )
        .with(
          { status: 'INELIGIBLE', userType: 'relatedPerson' },
          () =>
            'This caregiver does not have access to any patients in the portal. All linked patients are inactive, deceased, in a non-guardian/guarantor relationship with the caregiver, and/or age 18+ without access granted to the caregiver.'
        )
        .exhaustive()}
    >
      <Badge
        showDot
        text={portalUserStatusDisplay[status]}
        color={portalUserStatusToColor[status]}
        testId={testId}
      />
    </Tooltip>
  )
}

const portalUserStatusToColor: {
  [Status in PatientPortalUserStatus]: BadgeColor
} = {
  ACTIVE: 'green',
  DISABLED: 'dark-gray',
  INELIGIBLE: 'yellow',
}
