import clsx from 'clsx'
import { useParams } from 'react-router-dom'
import {
  CreateSignedPracticeFormInput,
  CreateSignedPracticeFormMutation,
} from 'types/graphql'

import { FieldError, Form, Submit } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Box from 'src/components/atoms/Box/Box'
import FileInput from 'src/components/atoms/FileInput/FileInput'
import Typography from 'src/components/atoms/Typography/Typography'
import { base64EncodeFile } from 'src/utils'

const CREATE_SIGNED_FORM_MUTATION = gql`
  mutation CreateSignedPracticeFormMutation(
    $input: CreateSignedPracticeFormInput!
  ) {
    createSignedPracticeForm(input: $input) {
      id
      completionStatus

      practiceForm {
        id
      }
      signedAt
      signedByPatientId
    }
  }
`

const SidepanelPatientSignedPracticeFormUpload = () => {
  const [
    createSignedPracticeForm,
    {
      loading: creatingSignedPracticeForm,
      error: createSignedPracticeFormError,
    },
  ] = useMutation<CreateSignedPracticeFormMutation>(
    CREATE_SIGNED_FORM_MUTATION,
    {
      onCompleted: () => {
        toast.success('SignedPracticeForm created')
      },
    }
  )

  const { patientId, practiceFormId } = useParams()

  const onSubmit = async (data: { fileList: File[] }) => {
    const signedPracticeFormBinary = {
      content: await base64EncodeFile(data.fileList[0]),
      contentType: data.fileList[0].type,
    }

    const input: CreateSignedPracticeFormInput = {
      completionStatus: 'COMPLETE',
      practiceFormId,
      signedByPatientId: patientId,
      signedPracticeFormBinary: signedPracticeFormBinary,
    }

    createSignedPracticeForm({ variables: { input } })
  }

  return (
    <>
      <Typography textStyle="subtitle">
        {`Signing Form ${practiceFormId} for Patient ${patientId}`}
      </Typography>
      <Form onSubmit={onSubmit} error={createSignedPracticeFormError}>
        <FileInput name="fileList" />
        <FieldError name="fileList" className="rw-field-error" />
        <Box className="px-4 py-3 text-right sm:px-6">
          <Submit
            className={clsx(
              'inline-flex justify-center rounded-md border border-transparent bg-primary px-3 py-2 text-sm font-medium',
              'text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-1 focus:ring-primary focus:ring-offset-2'
            )}
            disabled={creatingSignedPracticeForm}
          >
            Upload
          </Submit>
        </Box>
      </Form>
    </>
  )
}

export default SidepanelPatientSignedPracticeFormUpload
