const NumberedListIcon = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.3335 12.1663H14.6668M1.3335 11.0708V10.9163C1.3335 10.226 1.89314 9.66634 2.5835 9.66634H2.61728C3.28898 9.66634 3.83367 10.2109 3.83367 10.8826C3.83367 11.175 3.73866 11.4596 3.56323 11.6935L1.3335 14.6665L3.8335 14.6663M6.3335 7.99967H14.6668M6.3335 3.83301H14.6668M1.3335 2.16634L3.00016 1.33301V6.33301"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default NumberedListIcon
