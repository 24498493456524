import { navigate, routes, useParams } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import { getVisibleCaseInboxItems, selectNextTask } from 'src/utils/task'

const goToNextTask = ({
  caseId,
  params,
}: {
  caseId: string
  params: Record<string, string>
}) => {
  const caseInboxItems = getVisibleCaseInboxItems()

  if (!caseInboxItems.length) {
    const [currentTab] = params.glob.split('/')
    navigate(
      routes.cases({
        ...params,
        sidepanelContext: '',
        glob: currentTab,
      })
    )
    return
  }

  selectNextTask({ caseId })
}

export const useSelectNextTask = () => {
  const params = useParams()

  const selectNextTask = ({ caseId }: { caseId: string }) => {
    goToNextTask({ caseId, params })
  }

  return { selectNextTask }
}

export const useHandleCaseResolution = () => {
  const params = useParams()

  const getNextTaskId = async ({
    taskId: currentTaskId,
  }: {
    taskId: string
  }): Promise<string | undefined> => {
    const taskInboxItems = await getVisibleCaseInboxItems()

    const currentIndex = taskInboxItems.findIndex(
      (el) => el.dataset.caseId === currentTaskId
    )

    const nextIndex = (currentIndex + 1) % taskInboxItems.length

    const nextTaskId = taskInboxItems[nextIndex]?.dataset?.caseId

    return nextTaskId
  }

  const handleCaseResolution = async ({
    nextTaskId,
  }: {
    nextTaskId: string | undefined
  }) => {
    const [currentTab] = params.glob.split('/').filter(Boolean)

    // Don't include sidepanelContext so it closes when navigating
    const { sidepanelContext: _sidepanelContext, ...newParams } = params

    navigate(
      routes.cases({
        ...newParams,
        glob: nextTaskId ? `${currentTab}/${nextTaskId}/details` : currentTab,
      }),
      {
        replace: true,
      }
    )

    toast.success('Task resolved')
  }

  return { handleCaseResolution, getNextTaskId }
}
