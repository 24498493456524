import { useParams } from 'react-router-dom'

import PatientFormCell from 'src/components/PatientFormCell'

const SidepanelPatientForm = () => {
  const { patientFormId } = useParams()

  return <PatientFormCell id={patientFormId} />
}

export default SidepanelPatientForm
