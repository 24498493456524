import clsx from 'clsx'
import { formatMoneyInCents } from 'common/utils'
import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import type { PatientPayments } from 'types/graphql'

import Box from 'src/components/atoms/Box'
import Divider from 'src/components/atoms/Divider'
import StackView from 'src/components/atoms/StackView'
import Typography, { TypographyColor } from 'src/components/atoms/Typography'
import {
  getPaymentNoteDescription,
  getPaymentNoteName,
} from 'src/components/PatientFinancials/components/HistoricalPatientPaymentsTable'
import { sexDisplay } from 'src/data/sexes'

import { LetterHead, SubHeading } from './common'

const TableHeaderCell = ({
  text,
  firstColumn = false,
}: {
  text: string
  firstColumn?: boolean
}) => {
  const textDirection = firstColumn ? 'text-left' : 'text-right'
  const width = firstColumn ? 'w-10/12' : 'w-2/12'
  return (
    <Typography
      textStyle="interface-strong-xs"
      color="text-base-color-fg-muted"
      className={clsx(textDirection, width)}
    >
      {text}
    </Typography>
  )
}

const TableRowCell = ({
  text,
  subtext,
  color = 'text-base-color-fg-muted',
  firstColumn = false,
  testId,
  bold,
}: {
  text: string
  subtext?: string
  color?: TypographyColor
  firstColumn?: boolean
  testId?: string
  bold?: boolean
}) => {
  const textDirection = firstColumn ? 'text-left' : 'text-right'
  return (
    <StackView className="w-2/12" data-testid={testId}>
      <Typography
        textStyle={bold ? 'interface-strong-xs' : 'interface-default-xs'}
        color={color}
        className={textDirection}
      >
        {text}
      </Typography>
      {subtext && (
        <Typography
          textStyle="interface-default-xs"
          color="text-base-color-fg-subtle"
          className={textDirection}
        >
          {subtext}
        </Typography>
      )}
    </StackView>
  )
}

const PrintPatientPaymentReceipt = ({
  patient,
  paymentDate,
  candidPayments,
  candidRefunds,
  testId,
}: {
  patient: PatientPayments['patient']
  paymentDate: Date
  candidPayments: PatientPayments['patientPayments']
  candidRefunds: PatientPayments['develoCandidPatientRefunds']
  testId?: string
}) => {
  const paymentsTotal = candidPayments.reduce(
    (sum, payment) => sum + payment.amountCents,
    0
  )
  const refundsTotal = candidRefunds.reduce(
    (sum, refund) => sum + refund.amountCents,
    0
  )
  const total = paymentsTotal - refundsTotal

  const updatedRefunds = candidRefunds.map((refund) => ({
    id: refund.id,
    title: refund.candidPatientPayment
      ? `Refund for ${getPaymentNoteName(refund.candidPatientPayment)}`
      : 'Refund',
    amountCents: -refund.amountCents,
    paidAt: refund.createdAt,
    paymentLineItemType: refund.candidPatientPayment?.paymentLineItemType,
    status: refund.status,
  }))
  return (
    <StackView space={150} data-testid={testId}>
      <LetterHead tenant={patient.tenant} />
      <Box
        color="bg-base-color-bg-subtle"
        padding={50}
        data-testid="patient-details"
      >
        <StackView
          direction="row"
          justifyContent="between"
          space={50}
          className="pb-core-space-50"
        >
          <StackView>
            <SubHeading>Patient name</SubHeading>
            <Typography textStyle="interface-default-xs">
              {formatDisplayName(patient)}
            </Typography>
            <Typography textStyle="interface-default-xs">
              {sexDisplay[patient.sexAtBirth]} &bull;{' '}
              {format(parseISO(patient.birthDate), 'M/d/yyyy')}
            </Typography>
          </StackView>
          <StackView>
            <SubHeading>Payment date</SubHeading>
            <Typography textStyle="interface-default-xs">
              {paymentDate.toLocaleDateString()}
            </Typography>
          </StackView>
          <StackView>
            <SubHeading>Receipt date and time</SubHeading>
            <Typography textStyle="interface-default-xs">
              {format(new Date(), "MMM d, yyyy 'at' h:mm a")}
            </Typography>
          </StackView>
        </StackView>
      </Box>
      <StackView space={50}>
        <Typography textStyle="title-l">Payment receipt</Typography>
      </StackView>
      <Box rounded border data-testid="visit-section">
        <Box
          color="bg-base-color-bg-subtle"
          horizontalPadding={75}
          verticalPadding={50}
        >
          <StackView
            direction="row"
            space={75}
            className="bg-base-color-bg-subtle p-core-space-75"
            justifyContent="between"
            alignItems="center"
          >
            <TableHeaderCell text="Description" firstColumn />
            <TableHeaderCell text="Amount" />
          </StackView>
        </Box>
        <Divider />
        <Box horizontalPadding={75} verticalPadding={50}>
          {candidPayments.map((payment) => (
            <StackView
              key={payment.id}
              direction="row"
              space={75}
              className="p-core-space-75"
              justifyContent="between"
              alignItems="center"
            >
              <TableRowCell
                text={getPaymentNoteName(payment)}
                subtext={getPaymentNoteDescription(payment)}
                firstColumn
              />
              <TableRowCell text={formatMoneyInCents(payment.amountCents)} />
            </StackView>
          ))}
          {updatedRefunds.map((refund) => (
            <StackView
              key={refund.id}
              direction="row"
              space={75}
              className="p-core-space-75"
              justifyContent="between"
              alignItems="center"
            >
              <TableRowCell
                text={refund.title}
                subtext={getPaymentNoteDescription(refund)}
                firstColumn
              />
              <TableRowCell text={formatMoneyInCents(refund.amountCents)} />
            </StackView>
          ))}
        </Box>
        <Divider />
        <Box
          color="bg-base-color-bg-subtle"
          horizontalPadding={75}
          verticalPadding={50}
        >
          <StackView
            direction="row"
            space={75}
            className="bg-base-color-bg-subtle p-core-space-75"
            justifyContent="between"
            alignItems="center"
          >
            <TableRowCell text="Total" firstColumn bold />
            <TableRowCell text={formatMoneyInCents(total)} bold />
          </StackView>
        </Box>
      </Box>
    </StackView>
  )
}

export default PrintPatientPaymentReceipt
