export type SidepanelWidth = 'large' | 'medium' | 'small'

export interface SidepanelRouteContext {
  route: string
  width?: SidepanelWidth
  overlay?: boolean
  onCloseSidepanelGoToPreviousRoute?: boolean
  [key: string]: string | number | boolean | ((value) => void)
}

export interface SidepanelPortalContext {
  route?: never
  width?: SidepanelWidth
  overlay?: boolean
  closePortal: () => void
  afterSidepanelFormSubmit?: (value: string) => void
  [key: string]: string | number | boolean | ((value) => void)
}

export const setSidepanelContextDefaults = (
  context: SidepanelRouteContext | SidepanelPortalContext
): SidepanelRouteContext | SidepanelPortalContext => {
  const defaultWidth = 'small'
  const width = context.width ?? defaultWidth
  const defaultOverlay = width !== 'small' ? true : false
  const overlay = context.overlay ?? defaultOverlay
  const defaultRoute = ''
  const route = context.route ?? defaultRoute

  return {
    ...context,
    width,
    overlay,
    route,
  }
}
