import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'

const SidepanelForbidden = () => {
  return (
    <SidepanelPage header="Whoops, you do not have access">
      <StackView className="p-6">
        <Typography>
          It looks like you are trying to access content that you do not have
          access to. Please contact your admin if this is an error.
        </Typography>
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelForbidden
