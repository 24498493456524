import { useEffect, useState } from 'react'

import {
  ucumUnitToUnitEnumMap,
  unitDisplay,
} from 'common/unitConverter/unitConverter'
import { isBoolean, isString } from 'mathjs'

import {
  ValidateResult,
  ValidationRule,
  useFormContext,
} from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box'
import { DropdownField } from 'src/components/atoms/Dropdown'
import InputField, {
  InputFieldProps,
} from 'src/components/atoms/InputField/InputField'
import { Option } from 'src/components/atoms/Select/Select'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
export const InputFieldWithUnits = ({
  name,
  validation,
  description,
  ...props
}: InputFieldProps & { units: string[]; description: string }) => {
  const { register } = useFormContext()

  register(name, validation)

  return (
    <StackView fullWidth direction="row" gap={50}>
      {props.units.map((unit, index) => {
        return (
          <Box key={index} fullWidth>
            <InputFieldWithUnit
              {...props}
              key={index}
              description={description}
              name={`${name}.${unit}`}
              unit={unit}
            />
          </Box>
        )
      })}
    </StackView>
  )
}

export const InputFieldWithUnit = ({
  name,
  description,
  ...props
}: InputFieldProps & { unit: string; name: string; description?: string }) => {
  return (
    <StackView space={50}>
      <StackView direction="row" className="relative">
        <InputField emptyAs={null} fullWidth name={name} {...props} />
        <Box className="absolute right-core-space-50 top-0 z-10 flex h-full items-center">
          <Typography>{unitDisplay[props.unit] || props.unit}</Typography>
        </Box>
      </StackView>
      {description ? (
        <Typography
          textStyle="interface-default-s"
          color="text-comp-textfield-color-clean-enabled-placeholder"
        >
          {description}
        </Typography>
      ) : null}
    </StackView>
  )
}

export type InputFieldWithSelectableUnitProps = {
  valueName?: string
  unitName?: string
  unitOptions: Option[]
  setValueAsString?: boolean
} & InputFieldProps

const validateEmptyInput = (
  required: string | ValidationRule<boolean>
): ValidateResult => {
  if (!required) return true

  if (isBoolean(required)) {
    return !(required as boolean)
  } else if (isString(required)) {
    return required as string
  }
}

export const InputFieldWithSelectableUnit = ({
  name,
  valueName = `${name}.value`,
  unitName = `${name}.unit`,
  unitOptions,
  validation,
  setValueAsString = false,
  ...props
}: InputFieldWithSelectableUnitProps) => {
  const {
    register,
    unregister,
    watch,
    getFieldState,
    setValue,
    trigger: triggerValidation,
  } = useFormContext()
  register(name, {
    ...validation,
    validate: (value) => {
      if (validation?.required && !value?.value) {
        return validation.required as string
      }
    },
  })

  const [hasBeenDirty, setHasBeenDirty] = useState(false)

  useEffect(() => {
    return () => {
      if (validation?.shouldUnregister) {
        unregister(name)
      }
    }
  }, [name, unregister, validation?.shouldUnregister])

  const currentValue = watch(valueName)
  const currentUnit = watch(unitName)

  const currentFieldState = getFieldState(valueName)
  const isDirty = currentFieldState?.isDirty

  useEffect(() => {
    if (isDirty && !hasBeenDirty) {
      setHasBeenDirty(true)
    }
  }, [isDirty, hasBeenDirty])

  useEffect(() => {
    if (!hasBeenDirty) return
    if (currentValue === '') {
      setValue(valueName, '')
      setValue(unitName, currentUnit)
      register(valueName, {
        validate: () => validateEmptyInput(validation?.required),
      })
    }
    triggerValidation(valueName)
  }, [
    name,
    unitName,
    valueName,
    currentValue,
    currentUnit,
    hasBeenDirty,
    setValue,
    register,
    triggerValidation,
    validation?.required,
  ])

  return (
    <Box className="relative rounded-md shadow-sm" id={name}>
      <InputField
        disabled={props.disabled}
        className="pr-20 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
        fullWidth
        name={valueName}
        emptyAs={null}
        type="number"
        step="any"
        validation={{
          setValueAs: (value) => {
            if (setValueAsString && value !== undefined) {
              return value.toString()
            }
            return Number(value)
          },
          min: 0,
          ...validation,
        }}
        {...props}
      />
      <Box className="absolute inset-y-0 right-0 flex items-center">
        <DropdownField
          disabled={props.disabled || unitOptions.length === 1}
          name={unitName}
          options={unitOptions}
          className="!border-0 !bg-transparent !shadow-none"
          showChevron={!props.disabled && unitOptions.length > 1}
        />
      </Box>
    </Box>
  )
}

export const getUnitOptions = (units: string[]) => {
  return units.map((unit) => {
    return {
      name: unitDisplay[unit] ?? unit,
      value: ucumUnitToUnitEnumMap[unit] ?? unit,
    }
  })
}
