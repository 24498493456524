import {
  DetailedHTMLProps,
  HTMLAttributes,
  ReactElement,
  ReactNode,
} from 'react'

import clsx from 'clsx'

type PaddingType = 0 | 25 | 50 | 75 | 100 | 125 | 150 | 175 | 200 | 2400
export type BoxColorType =
  | 'bg-base-color-bg-default'
  | 'bg-base-color-bg-subtle'
  | 'bg-base-color-bg-attention'
  | 'bg-base-color-bg-brand'
  | 'bg-base-color-bg-danger'
  | 'bg-base-color-bg-success'
  | 'bg-base-color-bg-accent'
  | 'bg-base-color-fg-subtle'
  | 'bg-base-color-fg-brand'
  | 'bg-base-color-fg-danger'
  | 'bg-base-color-fg-accent'
  | 'bg-core-color-teal-50'
export interface BoxProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  flex?:
    | '1/3'
    | '2/3'
    | '1/4'
    | '1/12'
    | '2/12'
    | '3/12'
    | '4/12'
    | '5/12'
    | '6/12'
    | '7/12'
    | '8/12'
    | '9/12'
    | '10/12'
    | '11/12'
    | 'full'
  children?: ReactNode
  className?: string
  horizontalPadding?: PaddingType
  verticalPadding?: PaddingType
  padding?: PaddingType
  color?: BoxColorType
  fullWidth?: boolean
  id?: string
  inverse?: boolean
  grow?: boolean
  rounded?: boolean
  border?: boolean
  isCol?: boolean
  testId?: string
  onClick?: () => void
}

const paddingMapping = {
  x: {
    0: 'px-core-space-0',
    25: 'px-core-space-25',
    50: 'px-core-space-50',
    75: 'px-core-space-75',
    100: 'px-core-space-100',
    125: 'px-core-space-125',
    150: 'px-core-space-150',
    175: 'px-core-space-175',
    200: 'px-core-space-200',
    2400: 'px-core-space-2400',
  },
  y: {
    0: 'py-core-space-0',
    25: 'py-core-space-25',
    50: 'py-core-space-50',
    75: 'py-core-space-75',
    100: 'py-core-space-100',
    125: 'py-core-space-125',
    150: 'py-core-space-150',
    175: 'py-core-space-175',
    200: 'py-core-space-200',
    2400: 'py-core-space-2400',
  },
}
const flexMapping = {
  w: {
    '1/3': 'w-1/3',
    '2/3': 'w-2/3',
    '1/4': 'w-1/4',
    '1/12': 'w-1/12',
    '2/12': 'w-2/12',
    '3/12': 'w-3/12',
    '4/12': 'w-4/12',
    '5/12': 'w-5/12',
    '6/12': 'w-6/12',
    '7/12': 'w-7/12',
    '8/12': 'w-8/12',
    '9/12': 'w-9/12',
    '10/12': 'w-10/12',
    '11/12': 'w-11/12',
    full: 'w-full',
  },
  basis: {
    '1/3': 'basis-1/3',
    '2/3': 'basis-2/3',
    '1/4': 'basis-1/4',
    '1/12': 'basis-1/12',
    '2/12': 'basis-2/12',
    '3/12': 'basis-3/12',
    '4/12': 'basis-4/12',
    '5/12': 'basis-5/12',
    '6/12': 'basis-6/12',
    '7/12': 'basis-7/12',
    '8/12': 'basis-8/12',
    '9/12': 'basis-9/12',
    '10/12': 'basis-10/12',
    '11/12': 'basis-11/12',
    full: 'basis-full',
  },
}

const Box = ({
  flex,
  id,
  children,
  className,
  color,
  fullWidth = false,
  horizontalPadding,
  verticalPadding,
  rounded,
  padding,
  grow = false,
  inverse,
  isCol = false,
  border = false,
  testId,
  onClick,
  ...rest
}: BoxProps): ReactElement => {
  const classes = [
    border && 'border-base-border-width-container-s',
    rounded && 'rounded-base-border-radius-container-s',
    inverse &&
      'bg-base-color-bg-subtle border-base-border-width-container-s border-base-color-border-subtle',
    fullWidth && 'w-full',
    grow && (isCol ? 'w-full' : 'grow'),
    (horizontalPadding || padding) &&
      paddingMapping.x[horizontalPadding || padding],
    (verticalPadding || padding) &&
      paddingMapping.y[verticalPadding || padding],
    className,
    color,
  ]
  if (flex) {
    classes.push(flexMapping[isCol ? 'w' : 'basis'][flex])
  }
  const additionalProps = { ...rest }
  if (onClick) {
    additionalProps['onClick'] = onClick
    additionalProps['role'] = 'button'
    additionalProps['tabIndex'] = 0
    additionalProps['onKeyDown'] = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === ' ') {
        // do the same thing as onClick when user presses Enter or Space
        // relies on onClick not needing the event object
        onClick()
      }
    }
  }
  return (
    <div
      id={id}
      className={clsx(classes)}
      data-testid={testId}
      {...additionalProps}
    >
      {children}
    </div>
  )
}

export default Box
