import { useParams } from 'react-router-dom'
import { OrderWithObservationsFragment } from 'types/graphql'

import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import CompleteQuestionnaireOrderSidepanelForm from 'src/components/Order/CompleteQuestionnaireOrderSidepanelForm/CompleteQuestionnaireOrderSidepanelForm'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import { usePatientOrdersWithObservationsQuery } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'
import SidepanelNotAvailable from 'src/pages/Sidepanel/SidepanelNotAvailable/SidepanelNotAvailable'

const getQuestionnaireDescription = (order: OrderWithObservationsFragment) => {
  const orderIsCompleted = order.status === 'COMPLETED'

  return (
    order.questionnaire?.['description'] ||
    order.questionnaires?.[0]?.['description'] ||
    `${orderIsCompleted ? 'Update' : 'Input'} results for this Questionnaire`
  )
}

const getQuestionnaireHeader = (order: OrderWithObservationsFragment) => {
  if (order.questionnaire) {
    return order.questionnaire['title'] || order.codeDisplay
  }

  if (order.questionnaires) {
    return order.questionnaires[0]?.['title'] // TODO: change [0] to [tabIndex] when second tab implemented
  }
}

const SidepanelCompleteQuestionnaireOrderEdit = () => {
  const { patientId, isPatientChart } = useIsPatientChart()

  const { orderId, encounterId } = useParams()

  const { ordersWithObservations } =
    usePatientOrdersWithObservationsQuery(patientId)

  const order = ordersWithObservations?.find((order) => order.id === orderId)

  if (!isPatientChart || !order) {
    return <SidepanelNotAvailable />
  }

  return (
    <SidepanelPage
      testId="sidepanel-questionnaire-order-complete"
      header={getQuestionnaireHeader(order)}
      description={getQuestionnaireDescription(order)}
    >
      <CompleteQuestionnaireOrderSidepanelForm
        key={order.id}
        patientId={patientId}
        encounterId={encounterId === 'undefined' ? undefined : encounterId}
        order={order}
      />
    </SidepanelPage>
  )
}

export default SidepanelCompleteQuestionnaireOrderEdit
