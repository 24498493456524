const BulletedListIcon = ({ className }) => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.49984 9.16634H12.8332M4.49984 4.99967H12.8332M4.49984 0.833008H12.8332M1.16813 9.16634V9.16801L1.1665 9.16797V9.16634H1.16813ZM1.16813 4.99967V5.00134L1.1665 5.0013V4.99967H1.16813ZM1.16813 0.833008V0.834674L1.1665 0.834635V0.833008H1.16813Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BulletedListIcon
