import { useMemo, useRef } from 'react'

import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { evaluate, extractValue } from 'common/unitConverter/unitConverter'
import { format, add, parseISO, isWithinInterval } from 'date-fns'
import { Patient } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import Table from 'src/components/molecules/Table/Table'
import { toDecimal } from 'src/lib/formatters'

export type GrowthTableProps = {
  testId: string
  label?: string
  showCorrectedAge: boolean
  patient: Patient
  tableRows: TableRow[]
  tableColorThreshold?: TableColorThreshold[]
  isInfantData: boolean
  isWeightForHeightData?: boolean
  isCorrectedAgeData?: boolean
}

type GrowthTableRow = {
  rowHeader: string
  data: {
    [key: string]: string
  }
}

type TableRow = {
  rowHeader: string
  codes: string[]
  key:
    | 'value.value'
    | 'imperial.value'
    | 'percentile'
    | 'weightImperial.value'
    | 'weight.value'
    | 'heightImperial.value'
    | 'height.value'
}

type TableColorThreshold = {
  range: {
    start: number
    end: number
  }
  color: string
}

const decideWhichColumnsToShow = (
  isInfantData,
  isWeightForHeightData,
  birthDate
) => {
  let columnsToShow
  if (isInfantData) {
    columnsToShow = [
      {
        colId: 'birth',
        headerName: 'Birth',
        intervalStart: format(parseISO(birthDate), 'yyyy-MM-dd'),
        intervalEnd: format(
          add(parseISO(birthDate), { weeks: 1 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '1w',
        headerName: '1W',
        intervalStart: format(
          add(parseISO(birthDate), { weeks: 1 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { months: 1 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '1m',
        headerName: '1M',
        intervalStart: format(
          add(parseISO(birthDate), { months: 1 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { months: 2 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '2m',
        headerName: '2M',
        intervalStart: format(
          add(parseISO(birthDate), { months: 2 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { months: 4 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '4m',
        headerName: '4M',
        intervalStart: format(
          add(parseISO(birthDate), { months: 4 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { months: 6 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '6m',
        headerName: '6M',
        intervalStart: format(
          add(parseISO(birthDate), { months: 6 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { months: 9 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '9m',
        headerName: '9M',
        intervalStart: format(
          add(parseISO(birthDate), { months: 9 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { months: 12 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '12m',
        headerName: '12M',
        intervalStart: format(
          add(parseISO(birthDate), { months: 12 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { months: 15 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '15m',
        headerName: '15M',
        intervalStart: format(
          add(parseISO(birthDate), { months: 15 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { months: 18 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '18m',
        headerName: '18M',
        intervalStart: format(
          add(parseISO(birthDate), { months: 18 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { months: 24 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '24m',
        headerName: '24M',
        intervalStart: format(
          add(parseISO(birthDate), { months: 24 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { months: 30 }),
          'yyyy-MM-dd'
        ),
      },
    ]
  } else {
    columnsToShow = [
      {
        colId: '2y',
        headerName: '2Y',
        intervalStart: format(
          add(parseISO(birthDate), { months: 24 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { months: 30 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '2.5y',
        headerName: '2.5Y',
        intervalStart: format(
          add(parseISO(birthDate), { months: 30 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { months: 36 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '3y',
        headerName: '3Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 3 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 4 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '4y',
        headerName: '4Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 4 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 5 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '5y',
        headerName: '5Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 5 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 6 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '6y',
        headerName: '6Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 6 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 7 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '7y',
        headerName: '7Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 7 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 8 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '8y',
        headerName: '8Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 8 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 9 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '9y',
        headerName: '9Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 9 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 10 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '10y',
        headerName: '10Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 10 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 11 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '11y',
        headerName: '11Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 11 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 12 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '12y',
        headerName: '12Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 12 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 13 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '13y',
        headerName: '13Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 13 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 14 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '14y',
        headerName: '14Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 14 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 15 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '15y',
        headerName: '15Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 15 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 16 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '16y',
        headerName: '16Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 16 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 17 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '17y',
        headerName: '17Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 17 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 18 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '18y',
        headerName: '18Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 18 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 19 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '19y',
        headerName: '19Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 19 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 20 }),
          'yyyy-MM-dd'
        ),
      },
      {
        colId: '20y',
        headerName: '20Y',
        intervalStart: format(
          add(parseISO(birthDate), { years: 20 }),
          'yyyy-MM-dd'
        ),
        intervalEnd: format(
          add(parseISO(birthDate), { years: 21 }),
          'yyyy-MM-dd'
        ),
      },
    ]
  }

  // CDC Weight for Stature data only goes to 5 years old
  if (!isInfantData && isWeightForHeightData) {
    columnsToShow = columnsToShow.slice(0, 5)
  }

  return columnsToShow
}

const defaultColDef: ColDef = {
  cellStyle: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    padding: 0,
  },
  minWidth: 80,
}

const nameColDef: ColDef = {
  colId: 'name',
  pinned: 'left',
  cellClass: 'bg-gray-100',
  cellStyle: { padding: 15 },
  minWidth: 160,
  cellRenderer: ({ data }: ICellRendererParams<GrowthTableRow>) => {
    return (
      <StackView className="h-full" justifyContent="center">
        <Typography>{data.rowHeader}</Typography>
      </StackView>
    )
  },
}

const VitalCellRenderer = (params: ICellRendererParams<GrowthTableRow>) => {
  const key = params.colDef.colId

  let value
  if (parseFloat(params.data[key]?.value)) {
    value = toDecimal(params.data[key].value)
  } else {
    value = '\u2014'
  }

  const backgroundColor = params.data[key]?.bgColor

  return (
    <StackView
      data-testid="growth-table-cell-background"
      className="h-full"
      justifyContent="center"
      style={{ backgroundColor: backgroundColor }}
    >
      <Typography className="text-center">{value}</Typography>
    </StackView>
  )
}

const transformVitals = (
  vitals,
  tableRows,
  tableColorThreshold,
  columns,
  isWeightForHeightData
) => {
  return tableRows.map((row) => {
    let vitalsToUse
    if (isWeightForHeightData) {
      // If we are using weight for height data, we have already filtered our data in the API
      vitalsToUse = vitals
    } else {
      vitalsToUse = vitals.filter((v) => row.codes.includes(v.code.code))
    }

    const getValuesForColumn = columns.reduce((acc, col) => {
      if (!acc[col.colId]) {
        acc[col.colId] = {}
      }

      acc[col.colId] = vitalsToUse
        .filter((v) => {
          return isWithinInterval(new Date(v.effectiveAt), {
            start: new Date(col.intervalStart),
            end: new Date(col.intervalEnd),
          })
        })
        .sort((a, b) => a.effectiveAt.localeCompare(b.effectiveAt))
        .reverse()[0]

      if (acc[col.colId]) {
        const shouldConvertToKilograms =
          acc[col.colId].value?.unit === 'g' &&
          row.key !== 'percentile' &&
          row.key !== 'imperial.value'
        acc[col.colId] = {
          value: shouldConvertToKilograms
            ? extractValue(
                evaluate(
                  getNestedRowInformation(acc[col.colId], row.key),
                  acc[col.colId].value.unit,
                  'kg'
                )
              )
            : getNestedRowInformation(acc[col.colId], row.key),
          percentile: acc[col.colId].percentile,
          bgColor: findBackgroundColor(
            acc[col.colId].percentile,
            tableColorThreshold
          ),
        }
      }
      return acc
    }, {}) as { [key: string]: GrowthTableRow }

    return {
      rowHeader: row.rowHeader,
      ...getValuesForColumn,
    }
  })
}

const getNestedRowInformation = (baseObject, rowKey) => {
  if (rowKey.includes('.')) {
    return rowKey.split('.').reduce((acc, x) => {
      return acc[x]
    }, baseObject)
  }
  return baseObject[rowKey]
}

const buildColDefs = (columns) => {
  return [
    nameColDef,
    ...columns.map((col) => {
      return {
        colId: col.colId,
        headerName: col.headerName,
        cellRenderer: VitalCellRenderer,
        headerClass: 'develo-ag-centered-header',
      }
    }),
  ]
}

const findBackgroundColor = (percentile, tableColorThreshold) => {
  return tableColorThreshold.find(
    (t) => percentile >= t.range.start && percentile <= t.range.end
  )?.color
}

const GrowthTable = ({
  testId,
  label,
  showCorrectedAge,
  patient,
  tableRows,
  tableColorThreshold,
  isInfantData,
  isWeightForHeightData,
  isCorrectedAgeData = false,
}: GrowthTableProps) => {
  const gridRef = useRef<AgGridReact>()

  let vitals
  if (isWeightForHeightData) {
    vitals = patient?.['vitalsForWeightAndHeight']
  } else if (isCorrectedAgeData) {
    vitals = patient?.['vitalsForCorrectedAge']
  } else {
    vitals = patient?.['vitals']
  }

  const birthDate = patient?.['birthDate']

  const { vitalsByDateAndColumn, colDefs } = useMemo(() => {
    const columns = decideWhichColumnsToShow(
      isInfantData,
      isWeightForHeightData,
      birthDate
    )
    return {
      vitalsByDateAndColumn: transformVitals(
        vitals,
        tableRows,
        tableColorThreshold,
        columns,
        isWeightForHeightData
      ),
      colDefs: buildColDefs(columns),
    }
  }, [
    isInfantData,
    birthDate,
    vitals,
    tableRows,
    tableColorThreshold,
    isWeightForHeightData,
  ])

  const gridOptions: GridOptions = {
    rowHeight: 76,
    suppressMovableColumns: true,
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
  }

  return (
    <div data-testid={testId}>
      {showCorrectedAge && (
        <Typography className="mt-12 block uppercase" fontWeight="medium">
          {label}
        </Typography>
      )}

      <StackView space={100} className="relative my-6 overflow-auto">
        <Table
          testId="growth-table"
          innerRef={gridRef}
          rowData={vitalsByDateAndColumn}
          domLayout="autoHeight"
          headerHeight={36}
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          animateRows={true}
          gridOptions={gridOptions}
          pagination={false}
        />
      </StackView>
    </div>
  )
}

export default GrowthTable
