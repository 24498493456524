import { useParams } from 'react-router-dom'

import ExternalRequestCell from 'src/components/ExternalRequestCell'

const SidepanelExternalRequest = () => {
  const { externalRequestId } = useParams()

  return <ExternalRequestCell id={externalRequestId} />
}

export default SidepanelExternalRequest
