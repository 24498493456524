import { TemperatureSourceCodingKey } from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box'
import { DropdownField } from 'src/components/atoms/Dropdown'
import { InputFieldProps } from 'src/components/atoms/InputField'
import StackView from 'src/components/atoms/StackView'
import FieldError from 'src/components/FieldError/FieldError'
import {
  getUnitOptions,
  InputFieldWithSelectableUnit,
} from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import { validateVitalFn } from 'src/components/VisitIntakeVital'

const bodyTemperatureTypeOptions: {
  name: string
  value: TemperatureSourceCodingKey
}[] = [
  { name: 'Oral (mouth)', value: 'ORAL_TEMPERATURE' },
  { name: 'Axillary (armpit)', value: 'AXILLARY_TEMPERATURE' },
  { name: 'Rectal (rectum)', value: 'RECTAL_TEMPERATURE' },
  { name: 'Tympanic (ear)', value: 'TYMPANIC_TEMPERATURE' },
  {
    name: 'Temporal artery (forehead)',
    value: 'TEMPORAL_ARTERY_TEMPERATURE',
  },
]

export type BodyTemperatureInputProps = {
  name: string
  unitName: string
  optionName: string
} & InputFieldProps

export const BodyTemperatureInput = ({
  name,
  optionName = 'bodyTemperature.type',
  validation,
  ...props
}: BodyTemperatureInputProps) => {
  const { register, watch } = useFormContext()

  const formState = watch(name)

  register(name, {
    ...validation,
  })

  return (
    <StackView id={name}>
      <StackView direction="row" gap={50} alignItems="center">
        <Box grow>
          <InputFieldWithSelectableUnit
            name={name}
            unitOptions={getUnitOptions(['degF', 'Cel'])}
            validation={{
              shouldUnregister: true,
              validate:
                formState?.value && formState?.unit
                  ? {
                      validate: validateVitalFn(
                        'bodyTemperature',
                        formState.unit
                      ),
                    }
                  : undefined,
            }}
            setValueAsString
            {...props}
          />
        </Box>
        <Box className="w-1/2 rounded-md shadow-sm">
          <DropdownField
            name={optionName}
            options={bodyTemperatureTypeOptions}
            className="!bg-transparent !shadow-none"
          />
        </Box>
      </StackView>
      <FieldError name={`${name}.value`} label="Temperature" />
    </StackView>
  )
}
