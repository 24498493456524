const NoPaymentIcon = () => {
  return (
    <svg
      width="172"
      height="139"
      viewBox="0 0 172 139"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="86" cy="67" r="64" fill="#EAECF0" />
      <ellipse
        cx="23.7634"
        cy="11.5763"
        rx="5.65789"
        ry="5.67797"
        fill="#F2F4F7"
      />
      <ellipse
        cx="20.3683"
        cy="123.78"
        rx="7.92105"
        ry="7.94915"
        fill="#F2F4F7"
      />
      <ellipse
        cx="164.079"
        cy="39.7458"
        rx="7.92105"
        ry="7.94915"
        fill="#F2F4F7"
      />
      <ellipse
        cx="151.632"
        cy="9.08473"
        rx="4.52632"
        ry="4.54237"
        fill="#F2F4F7"
      />
      <g filter="url(#filter0_dd_1854_765)">
        <rect
          x="17"
          y="19"
          width="137"
          height="86"
          rx="6"
          fill="url(#paint0_linear_1854_765)"
        />
        <rect
          x="28.9131"
          y="35.7222"
          width="23.8261"
          height="17.9167"
          rx="2"
          fill="#D0D5DD"
        />
        <ellipse
          cx="132.159"
          cy="43.2872"
          rx="9.92754"
          ry="9.9537"
          fill="#98A2B3"
        />
        <ellipse
          cx="120.246"
          cy="43.2871"
          rx="9.92754"
          ry="9.9537"
          fill="#D0D5DD"
        />
        <path
          d="M28.9131 92.6574C28.9131 91.5579 29.802 90.6666 30.8986 90.6666H50.7537C51.8502 90.6666 52.7392 91.5579 52.7392 92.6574C52.7392 93.7568 51.8502 94.6481 50.7537 94.6481H30.8986C29.802 94.6481 28.9131 93.7568 28.9131 92.6574Z"
          fill="#D0D5DD"
        />
        <path
          d="M58.6957 92.6574C58.6957 91.5579 59.5846 90.6666 60.6812 90.6666H80.5363C81.6328 90.6666 82.5218 91.5579 82.5218 92.6574C82.5218 93.7568 81.6328 94.6481 80.5363 94.6481H60.6812C59.5846 94.6481 58.6957 93.7568 58.6957 92.6574Z"
          fill="#D0D5DD"
        />
        <path
          d="M88.4783 92.6574C88.4783 91.5579 89.3672 90.6666 90.4638 90.6666H110.319C111.415 90.6666 112.304 91.5579 112.304 92.6574C112.304 93.7568 111.415 94.6481 110.319 94.6481H90.4638C89.3672 94.6481 88.4783 93.7568 88.4783 92.6574Z"
          fill="#D0D5DD"
        />
        <path
          d="M118.261 92.6574C118.261 91.5579 119.15 90.6666 120.246 90.6666H140.101C141.198 90.6666 142.087 91.5579 142.087 92.6574C142.087 93.7568 141.198 94.6481 140.101 94.6481H120.246C119.15 94.6481 118.261 93.7568 118.261 92.6574Z"
          fill="#D0D5DD"
        />
      </g>
      <g filter="url(#filter1_b_1854_765)">
        <rect
          x="58"
          y="68"
          width="56"
          height="56"
          rx="28"
          fill="#344054"
          fillOpacity="0.4"
        />
        <path
          d="M86 83C83.4288 83 80.9154 83.7624 78.7776 85.1909C76.6398 86.6194 74.9735 88.6497 73.9896 91.0251C73.0056 93.4006 72.7482 96.0144 73.2498 98.5362C73.7514 101.058 74.9895 103.374 76.8076 105.192C78.6257 107.01 80.9421 108.249 83.4638 108.75C85.9856 109.252 88.5995 108.994 90.9749 108.01C93.3503 107.026 95.3807 105.36 96.8091 103.222C98.2376 101.085 99 98.5712 99 96C98.9964 92.5533 97.6256 89.2488 95.1884 86.8116C92.7512 84.3744 89.4467 83.0036 86 83ZM86 107C83.8244 107 81.6977 106.355 79.8887 105.146C78.0798 103.937 76.6699 102.22 75.8373 100.21C75.0048 98.1995 74.7869 95.9878 75.2114 93.854C75.6358 91.7202 76.6835 89.7602 78.2218 88.2218C79.7602 86.6834 81.7202 85.6358 83.854 85.2114C85.9878 84.7869 88.1995 85.0048 90.2095 85.8373C92.2195 86.6699 93.9375 88.0798 95.1462 89.8887C96.3549 91.6977 97 93.8244 97 96C96.9967 98.9164 95.8367 101.712 93.7745 103.775C91.7123 105.837 88.9164 106.997 86 107ZM85 97V90C85 89.7348 85.1054 89.4804 85.2929 89.2929C85.4804 89.1054 85.7348 89 86 89C86.2652 89 86.5196 89.1054 86.7071 89.2929C86.8946 89.4804 87 89.7348 87 90V97C87 97.2652 86.8946 97.5196 86.7071 97.7071C86.5196 97.8946 86.2652 98 86 98C85.7348 98 85.4804 97.8946 85.2929 97.7071C85.1054 97.5196 85 97.2652 85 97ZM87.5 101.5C87.5 101.797 87.412 102.087 87.2472 102.333C87.0824 102.58 86.8481 102.772 86.574 102.886C86.2999 102.999 85.9983 103.029 85.7074 102.971C85.4164 102.913 85.1491 102.77 84.9393 102.561C84.7296 102.351 84.5867 102.084 84.5288 101.793C84.471 101.502 84.5007 101.2 84.6142 100.926C84.7277 100.652 84.92 100.418 85.1667 100.253C85.4133 100.088 85.7033 100 86 100C86.3978 100 86.7794 100.158 87.0607 100.439C87.342 100.721 87.5 101.102 87.5 101.5Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_1854_765"
          x="0.333333"
          y="19"
          width="170.333"
          height="119.333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.33333"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_1854_765"
          />
          <feOffset dy="6.66667" />
          <feGaussianBlur stdDeviation="3.33333" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1854_765"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3.33333"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_1854_765"
          />
          <feOffset dy="16.6667" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1854_765"
            result="effect2_dropShadow_1854_765"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1854_765"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_1854_765"
          x="50"
          y="60"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1854_765"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1854_765"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1854_765"
          x1="21.771"
          y1="103.982"
          x2="19.2664"
          y2="25.2719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4E7EC" />
          <stop offset="1" stopColor="#F9FAFB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default NoPaymentIcon
