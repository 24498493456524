import type { LocationsQuery } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import LocationSelectField, {
  LocationSelectFieldProps,
} from 'src/components/Location/LocationsSelectField/LocationsSelectField'

export const QUERY = gql`
  query LocationsQuery {
    locations {
      id
      name
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  locations,
  ...rest
}: CellSuccessProps<LocationsQuery> & LocationSelectFieldProps) => {
  return <LocationSelectField locations={locations} {...rest} />
}
