import { DocumentCaseResolution } from 'src/components/DocumentCaseResolution/DocumentCaseResolution'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'

const DOCUMENT_CASE_RESOLVE_FRAGMENT = gql`
  fragment DocumentCaseResolveFragment on DocumentCase {
    id
    resolvedAt
    baseCase {
      id
      updatedAt
    }
    resolvedBy {
      id
      familyName
      givenName
    }
    resolvedStatus
    contacts {
      id
    }
  }
`

export const RESOLVE_DOCUMENT_CASE_MUTATION = gql`
  mutation ResolveDocumentCaseMutation(
    $documentCaseId: String!
    $input: ResolveDocumentCaseInput!
    $sendEFaxBackToSender: Boolean
    $sendEFaxBackToSenderBinary: CreateBinaryInput
  ) {
    resolveDocumentCase(
      documentCaseId: $documentCaseId
      input: $input
      sendEFaxBackToSender: $sendEFaxBackToSender
      sendEFaxBackToSenderBinary: $sendEFaxBackToSenderBinary
    ) {
      ...DocumentCaseResolveFragment
    }
  }
  ${DOCUMENT_CASE_RESOLVE_FRAGMENT}
`
export const RESOLVE_DOCUMENT_CASE_MULTIFAX_MUTATION = gql`
  mutation ResolveDocumentCaseMultiFaxMutation(
    $documentCaseId: String!
    $input: ResolveDocumentCaseInput!
    $sendEFaxBackToSenderBinary: CreateBinaryInput
  ) {
    resolveDocumentCaseMultiFax(
      documentCaseId: $documentCaseId
      input: $input
      sendEFaxBackToSenderBinary: $sendEFaxBackToSenderBinary
    ) {
      ...DocumentCaseResolveFragment
    }
  }
  ${DOCUMENT_CASE_RESOLVE_FRAGMENT}
`

const SidepanelDocumentCaseResolution = () => {
  return (
    <SidepanelPage
      header="Resolve task"
      description="Resolve this task by categorizing this document"
    >
      <DocumentCaseResolution />
    </SidepanelPage>
  )
}

export default SidepanelDocumentCaseResolution
