import { ReactNode } from 'react'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'

import { SubHeading } from './SubHeading'

export const SubSection = ({
  header,
  children,
  testId,
}: {
  header?: string
  children: ReactNode
  testId?: string
}) => {
  return (
    <StackView
      data-testid={testId}
      className="border-b-core-border-width-10 border-base-color-border-default p-core-space-100 pb-core-space-200 last:border-b-0"
      gap={50}
    >
      {header && <SubHeading>{header}</SubHeading>}
      <Box>{children}</Box>
    </StackView>
  )
}
