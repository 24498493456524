import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import { useEmrAuth } from 'src/auth'
import Box from 'src/components/atoms/Box'
import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useEmrPaymentTransactionQuery } from 'src/hooks/usePaymentTransactions/usePaymentTransactions'
import PrintPaymentTransactionReceipt from 'src/pdf/components/PrintPaymentTransactionReceipt'
import { useGeneratePDF } from 'src/pdf/useGeneratePDF'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

const SidepanelPaymentReceipt = () => {
  const { currentUser } = useEmrAuth()
  const { paymentTransactionId } = useParams()

  const [isGenerating, setIsGenerating] = useState(false)
  const [generated, setGenerated] = useState<URL>()
  const generatePDF = useGeneratePDF()

  const { paymentTransaction, loading } = useEmrPaymentTransactionQuery({
    paymentTransactionId,
  })

  useEffect(() => {
    if (paymentTransaction) {
      setIsGenerating(true)
      void generatePDF({
        component: (
          <PrintPaymentTransactionReceipt
            paymentTransaction={paymentTransaction}
          />
        ),
        callback: (doc) => {
          setIsGenerating(false)
          setGenerated(doc.output('bloburl'))
        },
      })
    }
  }, [generatePDF, paymentTransaction])

  if (!currentUser.featureFlags.includes('EMR_PAYMENT_TRANSACTION_RECEIPTS'))
    return <SidepanelNotAvailable />

  if (loading) return <LoadingSpinner />

  return (
    <SidepanelPage header="Payment receipt">
      <StackView className="h-full" space={50}>
        {isGenerating ? (
          <Box padding={100}>
            <LoadingSpinner />
          </Box>
        ) : (
          <FileDisplay
            name="Receipt"
            type="application/pdf"
            url={generated}
            showDownloadLink={false}
          />
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPaymentReceipt
