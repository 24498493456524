import { format, parseISO } from 'date-fns'
import { useParams } from 'react-router-dom'

import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { SidepanelPatientVitalsEdit as SidepanelPatientVitalsEditPage } from 'src/pages/PatientChartsPage/PatientResults/SidepanelPatientVitalsEdit'

const SidepanelPatientVitalsEdit = () => {
  const { date } = useParams()

  return (
    <SidepanelPage
      testId="sidepanel-vitals-edit"
      header={`Edit vitals entered on ${format(parseISO(date), 'MM/dd/yyyy')}`}
    >
      <SidepanelPatientVitalsEditPage />
    </SidepanelPage>
  )
}

export default SidepanelPatientVitalsEdit
