import { useContext } from 'react'

import { FetchAppointmentNotification } from 'types/graphql'

import {
  Redirect,
  navigate,
  routes,
  useMatch,
  useParams,
} from '@redwoodjs/router'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import { useAppointmentConfirmation } from 'src/hooks/useAppointmentConfirmation/useAppointmentConfirmation'
import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage'
import { createNamedContext, useEffectOnce } from 'src/utils'
import { useLastSavedStep } from 'src/utils/patientSelfRegistration'

export type AppointmentConfirmation =
  FetchAppointmentNotification['appointmentConfirmation']

const PatientSelfRegistrationContext = createNamedContext<{
  appointmentConfirmation: AppointmentConfirmation
}>('PatientSelfRegistrationContext')

export const usePatientSelfRegistrationContext = () =>
  useContext(PatientSelfRegistrationContext)

export const PatientSelfRegistrationProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { notificationId, step } = useParams()
  const matchesSuccessRoute = useMatch(
    routes.patientSelfRegistrationSuccess({ notificationId })
  )

  const { data, loading } = useAppointmentConfirmation({ notificationId })

  const [lastSavedStep] = useLastSavedStep({ notificationId })

  useEffectOnce(() => {
    if (!lastSavedStep) {
      return navigate(routes.patientSelfRegistrationIntro({ notificationId }), {
        replace: true,
      })
    }

    if (lastSavedStep && lastSavedStep !== step) {
      return navigate(
        lastSavedStep === 'sign-practice-forms'
          ? routes.patientSelfRegistrationSignPracticeForms({
              notificationId,
            })
          : routes.patientSelfRegistrationForms({
              notificationId,
              step: lastSavedStep,
            }),
        { replace: true }
      )
    }
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (!data?.appointmentConfirmation) {
    return <NotFoundPage />
  }

  const { appointmentConfirmation } = data

  if (appointmentConfirmation.patientId && !matchesSuccessRoute.match) {
    return (
      <Redirect
        to={routes.patientSelfRegistrationSuccess({ notificationId })}
        options={{
          replace: true,
        }}
      />
    )
  }

  return (
    <PatientSelfRegistrationContext.Provider
      value={{
        appointmentConfirmation,
      }}
    >
      {children}
    </PatientSelfRegistrationContext.Provider>
  )
}

export default PatientSelfRegistrationContext
