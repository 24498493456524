import { useContext } from 'react'

import { useLazyQuery } from '@apollo/client'
import { format, isSameDay } from 'date-fns'
import {
  GetAppointmentForVisitCheckInWarning,
  GetAppointmentForVisitCheckInWarningVariables,
} from 'types/graphql'

import { useConfirmation } from 'src/hooks/useConfirmation/useConfirmation'
import { createNamedContext } from 'src/utils'

import Typography from '../atoms/Typography'
import Modal from '../molecules/Modal'

const GET_APPOINTMENT_QUERY = gql`
  query GetAppointmentForVisitCheckInWarning($id: String!) {
    appointment(id: $id) {
      id
      start
    }
  }
`

const VisitCheckInWarningContext = createNamedContext<{
  checkForVisitCheckInWarnings: (data: {
    appointmentId: string
  }) => Promise<{ confirmed: boolean }>
  isCheckingForWarnings: boolean
}>('VisitCheckInWarningContext')

export const useVisitCheckInWarningContext = () =>
  useContext(VisitCheckInWarningContext)

export const VisitCheckInWarningProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [getAppointment, { data }] = useLazyQuery<
    GetAppointmentForVisitCheckInWarning,
    GetAppointmentForVisitCheckInWarningVariables
  >(GET_APPOINTMENT_QUERY)

  const [differentDayModalState, waitForDifferentDayConfirmation] =
    useConfirmation()

  const checkIfAppointmentIsToday = async ({
    appointmentId,
  }: {
    appointmentId: string
  }) => {
    const appointmentResult = await getAppointment({
      variables: {
        id: appointmentId,
      },
    })
    const { appointment } = appointmentResult.data

    const appointmentIsToday = isSameDay(
      new Date(),
      new Date(appointment.start)
    )

    if (!appointmentIsToday) {
      const { confirmed } = await waitForDifferentDayConfirmation()

      if (!confirmed) return { confirmed }
    }

    return { confirmed: true }
  }

  const appointmentStart = data?.appointment?.start
    ? new Date(data.appointment.start)
    : null

  return (
    <VisitCheckInWarningContext.Provider
      value={{
        checkForVisitCheckInWarnings: checkIfAppointmentIsToday,
        isCheckingForWarnings: differentDayModalState.isConfirming,
      }}
    >
      {children}
      <Modal
        isOpen={differentDayModalState.isConfirming}
        title="Visit check-in"
        content={
          <Typography textStyle="body-s" color="text-base-color-fg-subtle">
            {appointmentStart
              ? `You are attempting to check-in the patient for a visit on ${format(
                  appointmentStart,
                  'MM/dd/yyyy'
                )} which is different from today's date. Please make sure that this is the correct visit to check-in.`
              : ''}
          </Typography>
        }
        modalStyle="warning"
        primaryButton={{
          text: 'Continue check-in',
          onClick: differentDayModalState.confirm,
        }}
        setIsOpen={(isOpen) => {
          if (isOpen) {
            return
          }

          differentDayModalState.cancel()
        }}
      />
    </VisitCheckInWarningContext.Provider>
  )
}
