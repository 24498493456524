import { formatDisplayName } from 'common/utils'
import { useParams } from 'react-router-dom'
import {
  ActivatePortalAccount,
  ActivatePortalAccountVariables,
  GetContactInformationMobileNumber,
  GetContactInformationMobileNumberVariables,
  ListDuplicatePortalAccountsForResolution,
  ListDuplicatePortalAccountsForResolutionVariables,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { FormInputList } from 'src/components/molecules/FormInputList'
import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { genderIdentityAbbreviation } from 'src/data/genderIdentities'
import { sexAbbreviation } from 'src/data/sexes'
import {
  formatAddressLine1,
  formatAge,
  formatDateDisplay,
  formatPhoneNumber,
} from 'src/lib/formatters'
import { refetchQueries } from 'src/pages/FamilyPortalAdminPage/FamilyPortalAdminPage'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const CONTACT_INFORMATION_QUERY = gql`
  query GetContactInformationMobileNumber($id: String!) {
    contactInformation(id: $id) {
      id
      mobileNumber
    }
  }
`

const DUPLICATE_ACCOUNT_QUERY = gql`
  query ListDuplicatePortalAccountsForResolution($mobileNumber: PhoneNumber!) {
    contactInformations(input: { mobileNumber: $mobileNumber }) {
      id
      mobileNumber
      homeAddress {
        id
        line1
        line2
        city
        state
        postalCode
      }
      patient {
        id
        givenName
        familyName
        sexAtBirth
        birthDate
        patientRelatedPersonRelationships {
          id
          relatedPerson {
            id
            givenName
            familyName
          }
        }
        portalUser {
          id
        }
      }
      relatedPerson {
        id
        givenName
        familyName
        genderIdentity
        birthDate
        patientRelatedPersonRelationships {
          id
          patient {
            id
            givenName
            familyName
          }
        }
        portalUser {
          id
        }
      }
      practitioner {
        id
      }
    }
  }
`

const ACTIVATE_ACCOUNT = gql`
  mutation ActivatePortalAccount($input: ActivatePatientPortalUserInput!) {
    activatePatientPortalUser(input: $input) {
      id
    }
  }
`

const SidepanelPortalUserDuplicateResolution = () => {
  const { contactInformationId } = useParams()
  const { closeSidePanel } = useSidepanel()

  const formMethods = useForm()

  const contactInfoQuery = useQuery<
    GetContactInformationMobileNumber,
    GetContactInformationMobileNumberVariables
  >(CONTACT_INFORMATION_QUERY, {
    variables: {
      id: contactInformationId,
    },
  })

  const mobileNumber = contactInfoQuery.data?.contactInformation?.mobileNumber

  const { data } = useQuery<
    ListDuplicatePortalAccountsForResolution,
    ListDuplicatePortalAccountsForResolutionVariables
  >(DUPLICATE_ACCOUNT_QUERY, {
    variables: {
      mobileNumber,
    },
    skip: !mobileNumber,
  })

  const [activateAccount, { loading }] = useMutation<
    ActivatePortalAccount,
    ActivatePortalAccountVariables
  >(ACTIVATE_ACCOUNT)

  const duplicateAccounts =
    data?.contactInformations
      ?.filter((contactInfo) => !contactInfo.practitioner)
      ?.sort((a, b) => {
        if (a.id === contactInformationId) return -1
        if (b.id === contactInformationId) return 1

        return 0
      }) ?? []

  const existingAccount = duplicateAccounts.find(
    (account) =>
      account.patient?.portalUser ?? account.relatedPerson?.portalUser
  )

  return (
    <SidepanelPage
      header="Resolve duplicate accounts"
      description="After making mobile number adjustments that are needed, select a single account to activate among the duplicate accounts below. This will make all other accounts sharing the same mobile number ineligible for portal access."
    >
      <SidepanelForm
        formMethods={formMethods}
        onSubmit={(data) => {
          const [key, value] = data.accountToActivate.split(':')

          void activateAccount({
            variables: {
              input: {
                [key]: value,
              },
            },
            onCompleted: () => {
              closeSidePanel()

              toast.success('Account successfully activated')
            },
            refetchQueries,
          })
        }}
        footerProps={{
          submitText: 'Activate',
          disabled: !!existingAccount,
          cancelText: 'Cancel',
          submitting: loading || formMethods.formState.isSubmitting,
        }}
      >
        <StackView gap={150} className="py-core-space-100">
          <StackView gap={25}>
            <Typography textStyle="title-xs">
              Select the account that you would like to activate
            </Typography>

            {existingAccount ? (
              <Typography
                textStyle="interface-default-xs"
                color="text-base-color-fg-danger"
              >
                Cannot activate an account because there is already an active
                account for this mobile number. To resolve, the mobile number on
                one of the accounts must be changed first.
              </Typography>
            ) : null}
          </StackView>

          <FormInputList
            className="py-0"
            items={[
              {
                name: 'accountToActivate',
                label: 'Duplicate accounts',
                direction: 'col',
                required: true,
                formInputComponent: MultiRadioButtonField,
                inputProps: {
                  radioPosition: 'right',
                  radioStyle: 'card',
                  values: duplicateAccounts.map((account) => {
                    const person = account.patient ?? account.relatedPerson

                    const value = [
                      account.patient ? 'patientId' : 'relatedPersonId',
                      account.patient?.id ?? account.relatedPerson?.id,
                    ].join(':')

                    return {
                      value,
                      disabled: !!existingAccount,
                      label: [
                        formatDisplayName(person),
                        account.id === contactInformationId
                          ? '(Current account)'
                          : null,
                      ]
                        .filter(Boolean)
                        .join(' '),
                      description: [
                        account.patient
                          ? sexAbbreviation[account.patient.sexAtBirth]
                          : genderIdentityAbbreviation[
                              account.relatedPerson.genderIdentity
                            ],
                        `${formatAge(person.birthDate)} (${formatDateDisplay(
                          person.birthDate
                        )})`,
                        formatAddressLine1(account.homeAddress),
                        formatPhoneNumber(account.mobileNumber),
                        account.patient
                          ? [
                              'Caregiver(s)',
                              account.patient.patientRelatedPersonRelationships
                                .map((relationship) =>
                                  formatDisplayName(relationship.relatedPerson)
                                )
                                .join(', '),
                            ].join(': ')
                          : [
                              'Patient(s)',
                              account.relatedPerson.patientRelatedPersonRelationships
                                .map((relationship) =>
                                  formatDisplayName(relationship.patient)
                                )
                                .join(', '),
                            ].join(': '),
                      ].join(' • '),
                    }
                  }),
                },
              },
            ]}
          />
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelPortalUserDuplicateResolution
