import type { LabOrderIframeUrl } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import AreaLoader from '../atoms/AreaLoader/AreaLoader'
import StackView from '../atoms/StackView/StackView'

const QUERY = gql`
  query LabOrderIframeUrl($patientId: String!, $encounterId: String) {
    labOrderIframeUrl(patientId: $patientId, encounterId: $encounterId)
  }
`

interface CreateExternalLabOrderProps {
  patientId: string
  encounterId?: string
}

const CreateExternalLabOrder: React.FC<CreateExternalLabOrderProps> = ({
  patientId,
  encounterId,
}) => {
  const { loading, error, data } = useQuery<LabOrderIframeUrl>(QUERY, {
    variables: { patientId, encounterId },
  })

  if (loading) return <AreaLoader />
  if (error) return <div>{error.message}</div>
  if (!data) return <div>no data</div>

  const iframeUrl = data.labOrderIframeUrl

  return (
    <StackView fullWidth className="h-full p-3">
      <iframe title="Lab Order" src={iframeUrl} width="100%" height="100%" />
    </StackView>
  )
}

export default CreateExternalLabOrder
