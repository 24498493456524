import { SelectFieldProps } from '@redwoodjs/forms/dist/SelectField'

import SelectField from 'src/components/atoms/SelectField/SelectField'
import { states } from 'src/data/states'

export const StateSelectField = (props: SelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={states.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default StateSelectField
