import type { InNetworkIndicator } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const inNetworkIndicatorDisplay: {
  [key in InNetworkIndicator]: string
} = {
  YES: 'In network',
  NO: 'Out of network',
  NOT_APPLICABLE_OR_NOT_INDICATED: 'Network not applicable',
}

export const inNetworkIndicators = nameValueArrayFromObject<InNetworkIndicator>(
  inNetworkIndicatorDisplay
)
