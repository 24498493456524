import { format, compareDesc } from 'date-fns'
import compact from 'lodash/compact'
import {
  ListPatientDocuments,
  ListPatientDocumentsVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import {
  TypeaheadField,
  TypeaheadProps,
} from 'src/components/molecules/Typeahead'

const QUERY = gql`
  query ListPatientDocuments($patientId: String!) {
    patient(id: $patientId) {
      id
      documents {
        id
        createdAt
        updatedAt
        authoredByReference
        authenticatedByReference
        encounterReference
        type {
          code
          system
          display
        }
        subType {
          code
          system
          display
        }
        title
        content {
          url
          contentType
        }
        docStatus
        textContent
      }
    }
  }
`

export type PatientDocumentsTypeaheadFieldProps = Omit<
  TypeaheadProps,
  'options'
> & { patientId: string }

export const PatientDocumentsTypeaheadField = ({
  patientId,
  ...rest
}: PatientDocumentsTypeaheadFieldProps) => {
  const { data } = useQuery<
    ListPatientDocuments,
    ListPatientDocumentsVariables
  >(QUERY, {
    variables: {
      patientId,
    },
  })

  const documents = [...(data?.patient?.documents ?? [])]
  const documentOptions = documents
    .sort((a, b) => calculateGroupMemberSortingOrder(a, b))
    .map((document) => ({
      label: format(new Date(document.createdAt), 'MM/dd/yyyy'),
      supportingLabel: compact([
        document.title,
        document.subType?.display,
      ]).join(' \u2022 '),
      value: document.id,
      headerKey: document.type?.code,
      headerLabel: document.type?.display,
    }))
    .sort((a, b) => a.headerLabel.localeCompare(b.headerLabel))

  return <TypeaheadField {...rest} options={documentOptions} />
}

const calculateGroupMemberSortingOrder = (a, b) =>
  [
    compareDesc(new Date(a.createdAt), new Date(b.createdAt)),
    a.subType?.code &&
      a.subType.code.toString().localeCompare(b.subType?.code?.toString()),
    a.title && a.title.toString().localeCompare(b.title?.toString()),
  ].find((value) => value !== 0) ?? 0
