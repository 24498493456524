import { useRef, useState } from 'react'

import { ColDef } from 'ag-grid-community'
import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import sumBy from 'lodash/sumBy'
import { match } from 'ts-pattern'
import {
  ListPaymentNotifications,
  ListPaymentNotificationsVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { BalanceDisplay } from 'src/components/molecules/BalanceDisplay/BalanceDisplay'
import { OutboundTextMessageStatusBadge } from 'src/components/molecules/OutboundTextMessageStatusBadge/OutboundTextMessageStatusBadge'
import Table from 'src/components/molecules/Table/Table'
import { formatPhoneNumber } from 'src/lib/formatters'

const QUERY = gql`
  query ListPaymentNotifications(
    $sentToContactInformationId: String!
    $pagination: OffsetPaginationInput
  ) {
    paymentNotifications(
      sentToContactInformationId: $sentToContactInformationId
      pagination: $pagination
    ) {
      count
      pageInfo {
        pageCount
        currentPage
        hasNextPage
        limit
      }
      paymentNotifications {
        id
        notificationType
        balances {
          id
          patientId
          balanceAtTimeOfNotificationCents
        }
        sentByUser {
          id
          givenName
          familyName
        }
        sentAutomatically
        outboundTextMessage {
          id
          finishedAt
          createdAt
          mobileNumber
          status
          errorCode
        }
        patient {
          id
          givenName
          familyName
        }
        sentToContactInformation {
          id
          patient {
            id
            givenName
            familyName
          }
          relatedPerson {
            id
            givenName
            familyName
          }
        }
      }
    }
  }
`

const defaultColDef: ColDef = {
  cellStyle: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    wordBreak: 'break-word',
  },
  headerClass: 'develo-ag-header',
  resizable: true,
  autoHeight: true,
  wrapText: true,
}

type RowData =
  ListPaymentNotifications['paymentNotifications']['paymentNotifications'][number]

interface ColumnDef extends ColDef<RowData> {
  cellRenderer?: (args: { data: RowData }) => React.ReactNode
}
const columnDefinitions: ColumnDef[] = [
  {
    colId: 'date',
    headerName: 'Date and time',
    minWidth: 220,
    cellRenderer: ({ data }) => {
      const sentAt =
        data.outboundTextMessage.finishedAt ??
        data.outboundTextMessage.createdAt
      return (
        <StackView>
          <Typography
            textStyle="interface-default-s"
            color="text-base-color-fg-muted"
          >
            {format(new Date(sentAt), "MM/dd/yyyy 'at' hh:mm aa")}
          </Typography>

          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {data.sentAutomatically
              ? 'Sent automatically'
              : data.sentByUser
              ? `Sent manually by ${formatDisplayName(data.sentByUser)}`
              : `Sent manually`}
          </Typography>
        </StackView>
      )
    },
  },
  {
    colId: 'recipient',
    headerName: 'Recipient',
    cellRenderer: ({ data }) => {
      const recipient =
        data.sentToContactInformation.relatedPerson ??
        data.sentToContactInformation.patient

      return (
        <StackView>
          <Typography
            textStyle="interface-default-s"
            color="text-base-color-fg-muted"
          >
            {formatDisplayName(recipient)}
          </Typography>

          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {formatPhoneNumber(data.outboundTextMessage.mobileNumber)}
          </Typography>
        </StackView>
      )
    },
  },
  {
    colId: 'reason',
    headerName: 'Reason',
    minWidth: 180,
    cellRenderer: ({ data }) =>
      match(data.notificationType)
        .with('GUARANTOR_BALANCE_REMINDER', () => (
          <Typography
            textStyle="interface-default-s"
            color="text-base-color-fg-muted"
          >
            Total family balance
          </Typography>
        ))
        .with('PATIENT_BALANCE_REMINDER', () => (
          <StackView>
            <Typography
              textStyle="interface-default-s"
              color="text-base-color-fg-muted"
            >
              Patient balance
            </Typography>

            <Typography
              textStyle="interface-default-xs"
              color="text-base-color-fg-subtle"
            >
              {formatDisplayName(data.patient)}
            </Typography>
          </StackView>
        ))
        .exhaustive(),
  },
  {
    colId: 'status',
    headerName: 'Status',
    maxWidth: 180,
    cellRenderer: ({ data }) => (
      <OutboundTextMessageStatusBadge
        outboundTextMessage={data.outboundTextMessage}
      />
    ),
  },
  {
    colId: 'balance',
    headerName: 'Balance',
    maxWidth: 140,
    cellRenderer: ({ data }) => {
      return match(data.notificationType)
        .with('GUARANTOR_BALANCE_REMINDER', () => (
          <BalanceDisplay>
            {sumBy(
              data.balances,
              (balance) => balance.balanceAtTimeOfNotificationCents
            )}
          </BalanceDisplay>
        ))
        .with('PATIENT_BALANCE_REMINDER', () => (
          <Typography
            textStyle="interface-default-s"
            color="text-base-color-fg-muted"
          >
            -
          </Typography>
        ))
        .exhaustive()
    },
  },
]

export const PaymentNotificationHistoryTable = ({
  contactInformationId,
}: {
  contactInformationId: string | undefined
}) => {
  const [page, setPage] = useState(1)

  const { data } = useQuery<
    ListPaymentNotifications,
    ListPaymentNotificationsVariables
  >(QUERY, {
    variables: {
      sentToContactInformationId: contactInformationId,
      pagination: {
        page,
      },
    },
    skip: !contactInformationId,
  })

  const gridRef = useRef()

  const rowData = data?.paymentNotifications?.paymentNotifications ?? []
  const pageInfo = data?.paymentNotifications?.pageInfo

  return (
    <Table
      innerRef={gridRef}
      rowData={rowData}
      columnDefs={columnDefinitions}
      rowHeight={72}
      headerHeight={36}
      defaultColDef={defaultColDef}
      page={page}
      totalPages={pageInfo?.pageCount}
      pagination
      onClickPaginationButton={(newPage) => {
        setPage(newPage + 1)
      }}
      useCustomPaginationPanel
    />
  )
}
