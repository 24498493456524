import InputField from 'src/components/atoms/InputField'
import { FieldLabel } from 'src/components/atoms/Label'
import StackView from 'src/components/atoms/StackView'
import FieldError from 'src/components/FieldError/FieldError'

const removeArrowsClassName =
  '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'

const FamilyMemberHistoryOccurrenceInputField = ({
  name,
}: {
  name: string
}) => {
  const yearOfDiagnosisName = `${name}.onsetYear`
  const ageInYearsName = `${name}.onsetAgeInYears`

  return (
    <StackView direction="row" space={50}>
      <StackView space={25}>
        <FieldLabel name={yearOfDiagnosisName}>Year of diagnosis</FieldLabel>
        <InputField
          name={yearOfDiagnosisName}
          type="number"
          className={removeArrowsClassName}
          errorClassName={removeArrowsClassName}
        />
        <FieldError name={yearOfDiagnosisName} />
      </StackView>
      <StackView space={25}>
        <FieldLabel name={ageInYearsName}>Age in years</FieldLabel>
        <InputField
          name={ageInYearsName}
          type="number"
          className={removeArrowsClassName}
          errorClassName={removeArrowsClassName}
        />

        <FieldError name={ageInYearsName} />
      </StackView>
    </StackView>
  )
}

export default FamilyMemberHistoryOccurrenceInputField
