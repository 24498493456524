import type { FindUserTypes } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import SelectField from 'src/components/atoms/SelectField/SelectField'

export const QUERY = gql`
  query FindUserTypes {
    userTypes {
      id
      name
    }
  }
`
export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({ userTypes }: CellSuccessProps<FindUserTypes>) => {
  return (
    <SelectField
      name="userTypeId"
      data-testid="usertypeid-field"
      emptyAs={null}
      options={[
        ...userTypes.map(({ id, name }) => {
          return {
            value: id,
            display: name,
          }
        }),
      ]}
    />
  )
}
