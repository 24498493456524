import {
  DetailedHTMLProps,
  ButtonHTMLAttributes,
  ReactElement,
  Ref,
} from 'react'

import clsx from 'clsx'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'

import { FloatingTooltipProvider } from './ButtonFloatingTooltip'

export type ButtonStyle = 'primary' | 'secondary' | 'ghost' | 'danger' | 'link'

type ButtonSize = 'xs' | 's' | 'm' | 'l' | 'xl'

export type TooltipPosition = 'top' | 'bottom' | 'left' | 'right'
type TooltipSizing = 0 | 25 | 50 | 75 | 100

interface DefaultBtnProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  type?: 'button' | 'submit'
  buttonStyle?: ButtonStyle
  buttonSize?: ButtonSize
  accessibilityText?: string
  innerRef?: Ref<HTMLButtonElement>
  testId?: string
  floatingTooltip?: string
  tooltip?: string
  tooltipPosition?: TooltipPosition
  tooltipIsMultiline?: boolean
  tooltipPadding?: TooltipSizing
  tooltipMargin?: TooltipSizing
  loading?: boolean
  justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly'
  truncate?: boolean
  maxTextWidth?: string
}

interface ButtonWithChildren extends DefaultBtnProps {
  text?: never
  icon?: never
  iconPlacement?: never
  iconColor?: never
}

interface Button extends DefaultBtnProps {
  children?: never
  text?: string
  icon?: React.FunctionComponent<React.ComponentProps<'svg'>>
  iconPlacement?: 'left' | 'right'
  iconColor?: string
}

export type ButtonProps = Button | ButtonWithChildren

type SubmitButtonProps = ButtonProps & { loading: boolean }

const getButtonClasses = (buttonStyle: ButtonStyle, buttonSize: ButtonSize) => {
  const classes = []

  classes.push(
    'inline-flex items-center justify-center whitespace-nowrap',
    'rounded-base-border-radius-selectable-s',
    'disabled:pointer-events-none',
    'focus:shadow-base-box-shadow-general-focus-border',
    'disabled:opacity-base-opacity-disabled'
  )

  switch (buttonStyle) {
    case 'primary':
      classes.push(
        'bg-comp-button-color-primary-enabled-bg',
        'border-base-border-width-selectable-s',
        'border-comp-button-color-primary-enabled-border',
        'text-comp-button-color-primary-enabled-fg',
        'shadow-base-box-shadow-selectable-s',

        'hover:bg-comp-button-color-primary-hover-bg',
        'hover:text-comp-button-color-primary-hover-fg',
        'hover:border-comp-button-color-primary-hover-border',

        'focus:bg-comp-button-color-primary-focused-bg',
        'focus:text-comp-button-color-primary-focused-fg',
        'focus:border-comp-button-color-primary-focused-border',

        'active:bg-comp-button-color-primary-pressed-bg',
        'active:text-comp-button-color-primary-pressed-fg',
        'active:border-comp-button-color-primary-pressed-border'
      )
      break
    case 'secondary':
      classes.push(
        'bg-comp-button-color-secondary-enabled-bg',
        'border-base-border-width-selectable-s',
        'border-comp-button-color-secondary-enabled-border',
        'text-comp-button-color-secondary-enabled-fg',
        'shadow-base-box-shadow-selectable-s',

        'hover:bg-comp-button-color-secondary-hover-bg',
        'hover:text-comp-button-color-secondary-hover-fg',
        'hover:border-comp-button-color-secondary-hover-border',

        'focus:bg-comp-button-color-secondary-focused-bg',
        'focus:text-comp-button-color-secondary-focused-fg',
        'focus:border-comp-button-color-secondary-focused-border',

        'active:bg-comp-button-color-secondary-pressed-bg',
        'active:text-comp-button-color-secondary-pressed-fg',
        'active:border-comp-button-color-secondary-pressed-border'
      )
      break
    case 'ghost':
      classes.push(
        'bg-comp-button-color-ghost-enabled-bg',
        'border-comp-button-color-ghost-enabled-border',
        'text-comp-button-color-ghost-enabled-fg',

        'hover:bg-comp-button-color-ghost-hover-bg',
        'hover:text-comp-button-color-ghost-hover-fg',
        'hover:border-comp-button-color-ghost-hover-border',

        'focus:bg-comp-button-color-ghost-focused-bg',
        'focus:text-comp-button-color-ghost-focused-fg',
        'focus:border-comp-button-color-ghost-focused-border',

        'active:bg-comp-button-color-ghost-pressed-bg',
        'active:text-comp-button-color-ghost-pressed-fg',
        'active:border-comp-button-color-ghost-pressed-border'
      )
      break
    case 'danger':
      classes.push(
        'bg-comp-button-color-danger-enabled-bg',
        'border-base-border-width-selectable-s',
        'border-comp-button-color-danger-enabled-border',
        'text-comp-button-color-danger-enabled-fg',
        'shadow-base-box-shadow-selectable-s',

        'hover:bg-comp-button-color-danger-hover-bg',
        'hover:text-comp-button-color-danger-hover-fg',
        'hover:border-comp-button-color-danger-hover-border',

        'focus:bg-comp-button-color-danger-focused-bg',
        'focus:text-comp-button-color-danger-focused-fg',
        'focus:border-comp-button-color-danger-focused-border',

        'active:bg-comp-button-color-danger-pressed-bg',
        'active:text-comp-button-color-danger-pressed-fg',
        'active:border-comp-button-color-danger-pressed-border'
      )
      break
    case 'link':
      classes.push(
        'text-comp-button-color-primary-enabled-bg',
        'hover:text-comp-button-color-primary-hover-bg',
        'focus:text-comp-button-color-primary-focused-bg',
        'active:text-comp-button-color-primary-pressed-bg',
        'disabled:text-comp-button-color-primary-disabled-bg'
      )
      break
  }

  switch (buttonSize) {
    case 'xs':
      classes.push(
        'h-base-size-selectable-xs',
        'py-base-space-selectable-inset-none',
        'px-base-space-selectable-inset-m',
        'font-base-typography-interface-default-xs-font-family',
        'text-base-typography-interface-default-xs-font-size',
        'font-base-typography-interface-default-xs-font-weight',
        'leading-base-typography-interface-default-xs-line-height'
      )
      break
    case 's':
      classes.push(
        'h-base-size-selectable-s',
        'py-base-space-selectable-inset-none',
        'px-base-space-selectable-inset-m',
        'font-base-typography-interface-default-xs-font-family',
        'text-base-typography-interface-default-xs-font-size',
        'font-base-typography-interface-default-xs-font-weight',
        'leading-base-typography-interface-default-xs-line-height'
      )
      break
    case 'm':
      classes.push(
        'h-base-size-selectable-m',
        'py-base-space-selectable-inset-none',
        'px-base-space-selectable-inset-m',
        'font-base-typography-interface-default-s-font-family',
        'text-base-typography-interface-default-s-font-size',
        'font-base-typography-interface-default-s-font-weight',
        'leading-base-typography-interface-default-s-line-height'
      )
      break
    case 'l':
      classes.push(
        'h-base-size-selectable-l',
        'py-base-space-selectable-inset-none',
        'px-base-space-selectable-inset-l',
        'font-base-typography-interface-default-m-font-family',
        'text-base-typography-interface-default-m-font-size',
        'font-base-typography-interface-default-m-font-weight',
        'leading-base-typography-interface-default-m-line-height'
      )
      break
    case 'xl':
      classes.push(
        'h-base-size-selectable-xl',
        'py-base-space-selectable-inset-none',
        'px-base-space-selectable-inset-l',
        'font-base-typography-interface-default-m-font-family',
        'text-base-typography-interface-default-m-font-size',
        'font-base-typography-interface-default-m-font-weight',
        'leading-base-typography-interface-default-m-line-height'
      )
      break
  }

  return clsx(...classes)
}

export const getTooltipClasses = ({
  tooltipPosition,
  tooltipIsMultiline,
  tooltipMargin,
  tooltipPadding,
  isFloatingTooltip = false,
}: {
  tooltipPosition: TooltipPosition
  tooltipIsMultiline: boolean
  tooltipMargin: TooltipSizing
  tooltipPadding: TooltipSizing
  isFloatingTooltip?: boolean
}) => {
  const classes = []

  const padding = {
    0: 'p-core-space-0',
    25: 'p-core-space-25',
    50: 'p-core-space-50',
    75: 'p-core-space-75',
    100: 'p-core-space-100',
  }

  const margin = {
    0: 'm-core-space-0',
    25: 'm-core-space-25',
    50: 'm-core-space-50',
    75: 'm-core-space-75',
    100: 'm-core-space-100',
  }

  classes.push(padding[tooltipPadding])
  classes.push(
    'rounded-base-border-radius-selectable-s',
    'shadow-base-box-shadow-selectable-s',
    'border-base-border-width-selectable-s',
    'font-base-typography-interface-default-xs-font-family',
    'text-base-typography-interface-default-xs-font-size',
    'font-base-typography-interface-default-xs-font-weight',
    'leading-base-typography-interface-default-xs-line-height'
  )

  if (!isFloatingTooltip) {
    classes.push(margin[tooltipMargin])
    switch (tooltipPosition) {
      case 'top':
        classes.push('bottom-full')
        break
      case 'bottom':
        classes.push('top-full')
        break
      case 'left':
        classes.push('right-full')
        break
      case 'right':
        classes.push('left-full')
        break
    }
  }

  classes.push(
    'bg-base-color-bg-subtle',
    'text-base-color-fg-subtle',
    'border-base-color-border-subtle'
  )

  if (tooltipIsMultiline) {
    classes.push('w-core-size-2400')
  } else {
    classes.push('whitespace-nowrap')
  }

  return clsx(...classes)
}

export const Submit = ({
  children,
  text,
  icon,
  iconPlacement,
  iconColor,
  ...rest
}: Omit<SubmitButtonProps, 'ref'>) => {
  if (children) {
    return (
      <Button type="submit" {...rest}>
        {children}
      </Button>
    )
  } else {
    return (
      <Button
        type="submit"
        text={text}
        icon={icon}
        iconPlacement={iconPlacement}
        iconColor={iconColor}
        {...rest}
      />
    )
  }
}

const Button = ({
  text,
  className,
  children,
  type = 'button',
  disabled,
  buttonStyle = 'primary',
  buttonSize = 'm',
  accessibilityText,
  icon: Icon,
  iconPlacement = 'left',
  iconColor,
  innerRef,
  testId,
  floatingTooltip,
  tooltip,
  tooltipPosition = 'top',
  tooltipIsMultiline = false,
  tooltipPadding = 75,
  tooltipMargin = 75,
  loading,
  justifyContent = 'center',
  truncate = false,
  maxTextWidth,
  ...rest
}: ButtonProps): ReactElement => {
  const shouldRenderIcon = !loading && !!Icon

  const iconWithTextSize = 'h-base-size-icon-xs w-base-size-icon-xs'
  let iconButtonSize
  if (!children && !text) {
    switch (buttonSize) {
      case 'xs':
        iconButtonSize =
          '!h-base-size-icon-xs !w-base-size-icon-xs !p-base-space-selectable-inset-none'
        break
      case 's':
        iconButtonSize =
          '!h-base-size-icon-s !w-base-size-icon-s !p-base-space-selectable-inset-xs'
        break
      case 'm':
        iconButtonSize =
          '!h-base-size-icon-m !w-base-size-icon-m !p-base-space-selectable-inset-xs'
        break
      case 'l':
        iconButtonSize =
          '!h-base-size-icon-l !w-base-size-icon-l !p-base-space-selectable-inset-xs'
        break
      case 'xl':
        iconButtonSize =
          '!h-base-size-icon-xl !w-base-size-icon-xl !p-base-space-selectable-inset-xs'
        break
    }
  }

  const ButtonElement = (
    <button
      ref={innerRef}
      type={type}
      className={clsx(
        getButtonClasses(buttonStyle, buttonSize),
        className,
        tooltip && 'has-tooltip',
        !children && !text && iconButtonSize
      )}
      disabled={disabled || loading}
      data-testid={testId}
      {...rest}
    >
      {!!accessibilityText && (
        <span className="sr-only">{accessibilityText}</span>
      )}

      <StackView
        direction="row"
        space={50}
        justifyContent={justifyContent}
        alignItems="center"
      >
        {loading && (
          <span className={children ? 'pr-base-space-selectable-inset-s' : ''}>
            <LoadingSpinner size="xs" />
          </span>
        )}

        {children ?? (
          <>
            {shouldRenderIcon && iconPlacement === 'left' && (
              <Icon
                className={clsx(
                  !children && !text ? 'h-full w-full' : iconWithTextSize,
                  iconColor
                )}
              />
            )}
            {text && (
              <span
                className={clsx([
                  'whitespace-nowrap',
                  truncate && 'truncate',
                  maxTextWidth,
                ])}
              >
                {text}
              </span>
            )}
            {shouldRenderIcon && iconPlacement === 'right' && (
              <Icon
                className={clsx(
                  !children && !text ? 'h-full w-full' : iconWithTextSize,
                  iconColor
                )}
              />
            )}
          </>
        )}
      </StackView>

      {tooltip && (
        <div
          role="tooltip"
          className={clsx(
            'tooltip',
            getTooltipClasses({
              tooltipPosition,
              tooltipIsMultiline,
              tooltipMargin,
              tooltipPadding,
            })
          )}
          dangerouslySetInnerHTML={{ __html: tooltip }}
        />
      )}
    </button>
  )

  if (floatingTooltip) {
    return (
      <FloatingTooltipProvider
        text={floatingTooltip}
        tooltipClassname={clsx(
          getTooltipClasses({
            isFloatingTooltip: true,
            tooltipPosition,
            tooltipIsMultiline,
            tooltipMargin,
            tooltipPadding,
          })
        )}
      >
        {ButtonElement}
      </FloatingTooltipProvider>
    )
  }

  return ButtonElement
}

export default Button
