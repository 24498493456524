export const FRAGMENT = gql`
  fragment InsuranceEligibilityFragment on InsuranceCoverageEligibility {
    id
    checkedAt
    appointmentId
    serviceType
    status
    insuranceCoverageEligibilityCategories {
      id
      inNetworkIndicator
      coverageLevel
      planStatus
      copay
      coinsurance
      deductibleTotal
      deductibleRemaining
      deductibleTimeQualifierCode
      outOfPocketMax
    }
  }
`
