import { useEffect, useState } from 'react'

import { observationConcepts } from 'common/cdr/concepts/observations/index'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import {
  FindPatientSocialHistory,
  UpsertSocialHistoryInput,
} from 'types/graphql'

import { RegisterOptions, useForm } from '@redwoodjs/forms'

import AlcoholUseStatusSelectField from 'src/components/atoms/AlcoholUseStatusSelectField/AlcoholUseStatusSelectField'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import StackView from 'src/components/atoms/StackView/StackView'
import TobaccoSmokelessStatusSelectField from 'src/components/atoms/TobaccoSmokelessStatusSelectField/TobaccoSmokelessStatusSelectField'
import TobaccoSmokingStatusSelectField from 'src/components/atoms/TobaccoSmokingStatusSelectField/TobaccoSmokingStatusSelectField'
import VapingStatusSelectField from 'src/components/atoms/VapingStatusSelectField/VapingStatusSelectField'
import WysiwygField from 'src/components/atoms/WysiwygField/WysiwygField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import MultiSelectComboBoxFieldArray from 'src/components/molecules/MultiSelectComboBoxFieldArray/MultiSelectComboBoxFieldArray'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { countries } from 'src/data/countries'
import {
  useSocialHistoryQuery,
  useUpsertSocialHistory,
} from 'src/pages/PatientChartsPage/PatientHistory/SocialHistory/useSocialHistory'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const DrugUseCheckboxFields = () => {
  return (
    <StackView space={25}>
      <CheckboxField
        name="drugUse.cannabisMarijuana"
        label={
          observationConcepts['DRUG_USE_STATUS_CANNABIS_MARIJUANA'].display
        }
      />
      <CheckboxField
        name="drugUse.cannabisSynthetic"
        label={
          observationConcepts['DRUG_USE_STATUS_CANNABIS_SYNTHETIC'].display
        }
      />
      <CheckboxField
        name="drugUse.methamphetamine"
        label={observationConcepts['DRUG_USE_STATUS_METHAMPHETAMINE'].display}
      />
      <CheckboxField
        name="drugUse.amphetamine"
        label={observationConcepts['DRUG_USE_STATUS_AMPHETAMINE'].display}
      />
      <CheckboxField
        name="drugUse.mdma"
        label={observationConcepts['DRUG_USE_STATUS_MDMA'].display}
      />
      <CheckboxField
        name="drugUse.barbiturates"
        label={observationConcepts['DRUG_USE_STATUS_BARBITURATES'].display}
      />
      <CheckboxField
        name="drugUse.benzodiazepines"
        label={observationConcepts['DRUG_USE_STATUS_BENZODIAZEPINES'].display}
      />
      <CheckboxField
        name="drugUse.opioids"
        label={observationConcepts['DRUG_USE_STATUS_OPIOIDS'].display}
      />
      <CheckboxField
        name="drugUse.hallucinogens"
        label={observationConcepts['DRUG_USE_STATUS_HALLUCINOGENS'].display}
      />
      <CheckboxField
        name="drugUse.psychedelics"
        label={observationConcepts['DRUG_USE_STATUS_PSYCHEDELICS'].display}
      />
      <CheckboxField
        name="drugUse.kava"
        label={observationConcepts['DRUG_USE_STATUS_KAVA'].display}
      />
      <CheckboxField
        name="drugUse.cocaine"
        label={observationConcepts['DRUG_USE_STATUS_COCAINE'].display}
      />
      <CheckboxField
        name="drugUse.crackCocaine"
        label={observationConcepts['DRUG_USE_STATUS_CRACK'].display}
      />
      <CheckboxField
        name="drugUse.other"
        label={observationConcepts['DRUG_USE_STATUS_OTHER'].display}
      />
    </StackView>
  )
}

export interface TravelHistoryEditProps {
  travelHistory: FindPatientSocialHistory['patient']['socialHistory']['travelHistory']
  validation?: RegisterOptions
}

const TravelHistoryEdit = () => {
  return (
    <StackView space={50} direction="col">
      <FormInputList
        items={[
          {
            name: `travelHistory`,
            label: '',
            direction: 'col',
            formInputComponent: MultiSelectComboBoxFieldArray,
            inputProps: {
              options: countries,
              addSelectionText: 'Add country',
            },
          },
        ]}
      />
    </StackView>
  )
}

const SidepanelSocialHistoryEdit = () => {
  const [existingFormValuesLoaded, setExistingFormValuesLoaded] =
    useState(false)
  const { patientId } = useParams()
  const { closeSidePanel } = useSidepanel()

  const { socialHistoryFormValues, socialHistory } =
    useSocialHistoryQuery(patientId)

  const [upsertSocialHistory, { loading: upserting }] = useUpsertSocialHistory()

  const onSubmit = async (data) => {
    const input = {
      patientId,
      ...data,
      travelHistory: data.travelHistory[0] === '' ? null : data.travelHistory,
    }

    void upsertSocialHistory({
      variables: { input },
      onCompleted: closeSidePanel,
    })
  }

  const formMethods = useForm<UpsertSocialHistoryInput>({
    defaultValues: socialHistoryFormValues,
  })

  useEffect(() => {
    if (isEmpty(socialHistoryFormValues)) return

    if (!existingFormValuesLoaded) {
      formMethods.reset(socialHistoryFormValues)
      setExistingFormValuesLoaded(true)
    }
  }, [socialHistoryFormValues, formMethods, existingFormValuesLoaded])

  return (
    <SidepanelPage
      testId={'patient-social-history-edit'}
      header="Edit social history"
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Update',
          submitting: upserting,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <FormInputList
          items={[
            {
              name: 'travelHistory',
              label: 'International travel history',
              direction: 'col',
              formInputComponent: TravelHistoryEdit,
              inputProps: {
                travelHistory: socialHistory?.travelHistory,
              },
            },
            {
              name: 'smokingTobaccoUse',
              label: 'Smoking status',
              direction: 'col',
              formInputComponent: TobaccoSmokingStatusSelectField,
            },
            {
              name: 'smokelessTobaccoUse',
              label: 'Smokeless tobacco status',
              direction: 'col',
              formInputComponent: TobaccoSmokelessStatusSelectField,
            },
            {
              name: 'vapingUse',
              label: 'E cigarette / vaping status',
              direction: 'col',
              formInputComponent: VapingStatusSelectField,
            },
            {
              name: 'alcoholUse',
              label: 'Alcohol use',
              direction: 'col',
              formInputComponent: AlcoholUseStatusSelectField,
            },
            {
              name: 'drugUse',
              label: 'Recreational and/or illicit drug use',
              direction: 'col',
              formInputComponent: DrugUseCheckboxFields,
            },
            {
              name: 'comment',
              label: 'Comment',
              direction: 'col',
              formInputComponent: WysiwygField,
              inputProps: {
                testId: 'social-history-comment',
                defaultValue: socialHistoryFormValues?.comment,
              },
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelSocialHistoryEdit
