import { FamilyMessageCaseSubject } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const familyMessageSubjectDisplay: {
  [Subject in FamilyMessageCaseSubject]: string
} = {
  GENERAL: 'General',
  SCHEDULING: 'Scheduling',
  MEDICATIONS: 'Clinical - Medication',
  ILLNESS: 'Clinical - Illness',
  CLINICAL_RESULTS: 'Clinical - Results',
  CLINICAL_OTHER: 'Clinical - Other',
  BILLING: 'Billing',
  OTHER: 'Other',
}

export const familyMessageSubjects =
  nameValueArrayFromObject<FamilyMessageCaseSubject>(
    familyMessageSubjectDisplay
  )

export const familyMessageSubjectDescription: {
  [Subject in FamilyMessageCaseSubject]: string
} = {
  GENERAL: "Any questions or comments regarding our practice's operations.",
  SCHEDULING:
    'Please provide details on the existing visit to be rescheduled, or the new visit that is needed.',
  MEDICATIONS:
    'When requesting a medication refill, please include medication name, dosage, and preferred pharmacy.',
  ILLNESS:
    'Please provide details regarding symptoms, their timing, and any active medications or treatment.',
  CLINICAL_RESULTS:
    'Please specify which lab, imaging, or referral results, and when the testing or visit was completed.',
  CLINICAL_OTHER:
    'Please provide details on any patient care or health-related topic.',
  BILLING:
    'Please specify which past visit(s) or charge(s) you are referencing.',
  OTHER: 'Any other practice-related questions or comments.',
}
