const FullPageLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <section
      aria-labelledby="primary-heading"
      className="flex min-w-0 flex-1 flex-col lg:order-last"
    >
      {children}
    </section>
  )
}

export default FullPageLayout
