import { parseISO } from 'date-fns'
import maxBy from 'lodash/maxBy'
import {
  FindPatientAllergies,
  SetNoKnownAllergies,
  UpsertPatientAllergy,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import { FRAGMENT as ALLERGEN_FRAGMENT } from 'src/pages/PatientChartsPage/PatientHistory/Allergies/fragments'

const ALLERGIES_QUERY = gql`
  query FindPatientAllergies($id: String!) {
    patient(id: $id) {
      id
      hasNoKnownAllergies
      allergies {
        id
        externalId
        patientReference
        allergen {
          ...AllergenFragment
        }
        severity
        manifestation {
          code
          display
          system
        }
        status
        verificationStatus
        recordedDate
        recorderReference
        updatedAt
      }
    }
  }
  ${ALLERGEN_FRAGMENT}
`

const UPSERT_ALLERGY_MUTATION = gql`
  mutation UpsertPatientAllergy($input: UpsertAllergyInput!) {
    upsertPatientAllergy(input: $input) {
      id
      hasNoKnownAllergies
      allergies {
        id
        externalId
        patientReference
        allergen {
          ...AllergenFragment
        }
        severity
        manifestation {
          code
          display
          system
        }
        status
        verificationStatus
        recordedDate
        recorderReference
        updatedAt
      }
    }
  }
  ${ALLERGEN_FRAGMENT}
`

const SET_NO_KNOWN_ALLERGIES_MUTATION = gql`
  mutation SetNoKnownAllergies($patientId: String!) {
    setNoKnownAllergies(patientId: $patientId) {
      id
      hasNoKnownAllergies
      allergies {
        id
        externalId
        patientReference
        allergen {
          ...AllergenFragment
        }
        severity
        manifestation {
          code
          display
          system
        }
        status
        verificationStatus
        recordedDate
        recorderReference
        updatedAt
      }
    }
  }
  ${ALLERGEN_FRAGMENT}
`

export const useAllergiesQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<FindPatientAllergies>(
    ALLERGIES_QUERY,
    {
      variables: { id: patientId },
    }
  )

  const allergies = data?.patient?.allergies
  const hasNoKnownAllergies = data?.patient?.hasNoKnownAllergies || false
  const allergiesLastUpdated =
    data?.patient?.allergies?.length > 0
      ? maxBy(data.patient.allergies, (a) => parseISO(a.updatedAt)).updatedAt
      : null

  return {
    allergies,
    allergiesLastUpdated,
    hasNoKnownAllergies,
    loading,
    error,
  }
}

export const useUpsertAllergy = () => {
  return useMutation<UpsertPatientAllergy>(UPSERT_ALLERGY_MUTATION, {
    onCompleted: () => {
      toast.success('Allergy saved')
    },
  })
}

export const useSetNoKnownAllergies = () => {
  return useMutation<SetNoKnownAllergies>(SET_NO_KNOWN_ALLERGIES_MUTATION, {
    onCompleted: () => {
      toast.success('Allergies updated')
    },
  })
}
