import { useParams } from 'react-router-dom'

import Box from 'src/components/atoms/Box'
import Typography from 'src/components/atoms/Typography'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import VisitTemplateOrderSidepanelForm from 'src/components/OrderSidepanelForm/VisitTemplateOrderSidepanelForm'
import { orderCategoryConfig } from 'src/data/orderCategories'

const SidepanelVisitTemplateOrderCreate = () => {
  const { category } = useParams()
  const config = orderCategoryConfig[category]

  if (!config || config.category.type === 'external') {
    return (
      <SidepanelPage
        testId="sidepanel-visit-template-order-create"
        header="Create order"
      >
        <Box padding={50}>
          <Typography textStyle="title">Invalid order category</Typography>
        </Box>
      </SidepanelPage>
    )
  }

  return (
    <SidepanelPage
      testId="sidepanel-visit-template-order-create"
      header={`Create ${config.display.toLowerCase()} order`}
    >
      <VisitTemplateOrderSidepanelForm category={config.category} />
    </SidepanelPage>
  )
}

export default SidepanelVisitTemplateOrderCreate
