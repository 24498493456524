import { ReactElement } from 'react'

import clsx from 'clsx'

import { Link as RWLink } from '@redwoodjs/router'

import StackView from '../StackView/StackView'
import Typography, { TextStyle } from '../Typography'

export type LinkStyleType =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'success'
  | 'warning'
type LinkSizeType = 's' | 'm' | 'l'

type LinkProps = LinkContentProps & {
  to: string
  linkStyle?: LinkStyleType
  testId?: string
}

type LinkContentProps = {
  text: string
  icon?: React.FunctionComponent<React.ComponentProps<'svg'>>
  iconPlacement?: 'left' | 'right'
  size?: LinkSizeType
}

export type AnchorLinkProps = LinkProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>

const linkSizeToTextStyleMapping: { [size in LinkSizeType]: TextStyle } = {
  s: 'interface-strong-xs',
  m: 'interface-strong-s',
  l: 'interface-strong-m',
}

const linkStyleToClassesMapping = {
  primary: 'text-base-color-fg-brand hover:text-base-color-fg-brand-emphasis',
  secondary: 'text-base-color-fg-muted hover:text-base-color-fg-default',
  danger: 'text-base-color-fg-danger hover:text-base-color-fg-danger-emphasis',
  success:
    'text-base-color-fg-success hover:text-base-color-fg-success-emphasis',
  warning:
    'text-base-color-fg-attention hover:text-base-color-fg-attention-emphasis',
}

const getUrl = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`
  }
  return url
}

const LinkContent = ({
  text,
  size,
  icon: Icon,
  iconPlacement,
}: LinkContentProps) => {
  return (
    <StackView
      direction="row"
      gap={50}
      alignItems="center"
      reverse={iconPlacement === 'right'}
    >
      {Icon && <Icon className="h-base-size-icon-xs w-base-size-icon-xs" />}
      <Typography textStyle={linkSizeToTextStyleMapping[size]}>
        {text}
      </Typography>
    </StackView>
  )
}

const Link = ({
  to,
  text,
  linkStyle = 'primary',
  size = 'm',
  icon,
  iconPlacement = 'left',
  testId,
  ...rest
}: LinkProps): ReactElement => {
  return (
    <RWLink
      to={to}
      data-testid={testId}
      className={clsx([
        'inline-flex whitespace-nowrap',
        linkStyleToClassesMapping[linkStyle],
      ])}
      {...rest}
    >
      <LinkContent
        text={text}
        icon={icon}
        iconPlacement={iconPlacement}
        size={size}
      />
    </RWLink>
  )
}

export const AnchorLink = ({
  to,
  text,
  linkStyle = 'primary',
  size = 'm',
  icon,
  iconPlacement = 'left',
  testId,
  className,
  ...rest
}: AnchorLinkProps): ReactElement => {
  return (
    <a
      href={getUrl(to)}
      data-testid={testId}
      className={clsx([
        'inline-flex whitespace-nowrap',
        linkStyleToClassesMapping[linkStyle],
        className,
      ])}
      {...rest}
    >
      <LinkContent
        text={text}
        icon={icon}
        iconPlacement={iconPlacement}
        size={size}
      />
    </a>
  )
}

export default Link
