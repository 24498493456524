import { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { DiagnosisCode, DiagnosisCodeFilter } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/dist/toast'

import { CheckboxField } from 'src/components/atoms/Checkbox'
import DestructiveAction from 'src/components/molecules/DestructiveAction/DestructiveAction'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import DiagnosisSearchField from 'src/components/organisms/DiagnosisSearchField/DiagnosisSearchField'
import useIsPatientVisit from 'src/hooks/useIsPatientVisit/useIsPatientVisit'
import {
  useDeleteConditionMutation,
  useUpdateVisitDiagnosisMutation,
  useVisitQuery,
} from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

type FormState = {
  isPrimary: boolean
  code: DiagnosisCode
  codeSearchFilter: DiagnosisCodeFilter[]
  codeSearchInput: string
}

const SidepanelVisitDiagnosisEdit = () => {
  const { encounterId, conditionId } = useParams()
  const { patientId, appointmentId } = useIsPatientVisit()
  const { closeSidePanel } = useSidepanel()

  const { visit } = useVisitQuery(appointmentId)
  const { condition, isPrimary } =
    conditionId === visit?.encounter?.primaryDiagnosis?.id
      ? { condition: visit?.encounter?.primaryDiagnosis, isPrimary: true }
      : {
          condition: visit?.encounter?.additionalDiagnoses?.find(
            (diagnosis) => diagnosis.id === conditionId
          ),
          isPrimary: false,
        }

  const formMethods = useForm<FormState>({
    defaultValues: {
      code: {
        code: condition?.code?.code,
        description: condition?.code?.display,
      },
      isPrimary,
    },
  })
  const searchFilter = formMethods.watch('codeSearchFilter')
  useEffect(() => {
    formMethods.reset({
      code: {
        code: condition?.code?.code,
        description: condition?.code?.display,
      },
      isPrimary,
      codeSearchFilter: searchFilter,
    })
    // only reset form when conditionId changes (someone clicks on a different row )
    // form input updates should result in regular form interaction updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionId])

  const [updateVisitDiagnosis, { loading: updatingVisitDiagnosis }] =
    useUpdateVisitDiagnosisMutation()
  const [deleteVisitDiagnosis, { loading: isDeletingVisitDiagnosis }] =
    useDeleteConditionMutation()

  const onSubmit = (data: FormState) => {
    updateVisitDiagnosis({
      variables: {
        encounterId,
        conditionId,
        diagnosisCodeId: data.code.id,
        isPrimary: data.isPrimary,
      },
      onCompleted: () => {
        toast.success('Visit diagnosis updated')
        closeSidePanel()
      },
      refetchQueries: ['FindPatientOrders'],
    })
  }

  return (
    <SidepanelPage header="Edit diagnosis">
      <SidepanelForm
        footerProps={{
          submitText: 'Save',
          cancelText: 'Cancel',
          submitting: updatingVisitDiagnosis,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <FormInputList
          items={[
            {
              name: 'code',
              label: 'Diagnosis code',
              required: true,
              direction: 'col',
              formInputComponent: DiagnosisSearchField,
              inputProps: {
                patientId,
                encounterId,
                searchSet: 'ALL',
              },
            },
            {
              name: 'isPrimary',
              label: '',
              hideLabel: true,
              direction: 'col',
              formInputComponent: CheckboxField,
              inputProps: {
                label:
                  'This is the primary diagnosis. If there is an existing primary diagnosis, it will be replaced by this one.',
              },
            },
          ]}
        />
        <DestructiveAction
          title="Destructive actions"
          description="These changes are permanent, use caution when removing."
          buttonText="Remove diagnosis"
          doDestructiveAction={async () => {
            await deleteVisitDiagnosis({
              variables: { conditionId },
              onCompleted: () => {
                toast.success('Visit diagnosis removed')
                closeSidePanel()
              },
            })
          }}
          destructiveActionIsLoading={isDeletingVisitDiagnosis}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelVisitDiagnosisEdit
