import { ArrowPathIcon } from '@heroicons/react/24/solid'
import capitalize from 'lodash/capitalize'
import { useParams } from 'react-router-dom'

import { navigate } from '@redwoodjs/router'

import { useGetPatientPaymentPlan } from 'src/hooks/usePatientPaymentPlans/usePatientPaymentPlans'
import { sidepanelRoute } from 'src/lib/routes'

import Box from '../atoms/Box'
import Button from '../atoms/Button'
import StackView from '../atoms/StackView'
import Typography from '../atoms/Typography'

const PatientPaymentPlanPaymentMethodCard = ({
  patientPaymentPlanId,
}: {
  patientPaymentPlanId: string
}) => {
  const params = useParams()
  const { patientPaymentPlan, loading } =
    useGetPatientPaymentPlan(patientPaymentPlanId)

  if (loading || !patientPaymentPlan?.subscriptionPaymentMethod) return null

  const brand = capitalize(patientPaymentPlan.subscriptionPaymentMethod.brand)
  const last4 = patientPaymentPlan.subscriptionPaymentMethod.last4
  const holderName = patientPaymentPlan.subscriptionPaymentMethod.holderName
  const expirationMonth =
    patientPaymentPlan.subscriptionPaymentMethod.expirationMonth
      ?.toString()
      .padStart(2, '0')
  const expirationYear =
    patientPaymentPlan.subscriptionPaymentMethod.expirationYear
      ?.toString()
      .slice(-2)

  return (
    <Box
      testId="payment-plan-payment-method-card"
      verticalPadding={150}
      horizontalPadding={100}
      rounded
      border
    >
      <StackView direction="row" alignItems="center" justifyContent="between">
        <StackView>
          <Typography textStyle="interface-strong-s">
            {brand} ending in {last4}
          </Typography>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {holderName} · {expirationMonth}/{expirationYear}
          </Typography>
        </StackView>
        <Button
          buttonStyle="secondary"
          text="Change"
          icon={ArrowPathIcon}
          onClick={() => {
            navigate(
              sidepanelRoute(
                {
                  route: `/patients/${patientPaymentPlan.patientId}/payment-plan/${patientPaymentPlan.id}/change-payment-method`,
                  overlay: true,
                },
                location,
                params
              )
            )
          }}
        />
      </StackView>
    </Box>
  )
}

export default PatientPaymentPlanPaymentMethodCard
