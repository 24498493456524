import { useEffect } from 'react'

import compact from 'lodash/compact'

import { useForm } from '@redwoodjs/forms'
import { useParams } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import { useEmrAuth } from 'src/auth'
import { DropdownField } from 'src/components/atoms/Dropdown'
import InputField from 'src/components/atoms/InputField'
import WysiwygField from 'src/components/atoms/WysiwygField/WysiwygField'
import FormInputList from 'src/components/molecules/FormInputList'
import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { organSystemDisplay, organSystems } from 'src/data/organSystems'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const GET_MACRO_PHRASE_BY_ID_QUERY = gql`
  query GetMacroPhraseById($id: String!) {
    macroPhrase(id: $id) {
      id
      title
      keyword
      phrase
      section
      subsection
    }
  }
`

const UPSERT_MACRO_PHRASE = gql`
  mutation UpsertMacroPhrase($id: String, $input: UpsertMacroPhraseInput!) {
    upsertMacroPhrase(id: $id, input: $input) {
      id
    }
  }
`

const SidepanelMacroPhrase = () => {
  const { currentUser } = useEmrAuth()
  const { glob: section } = useParams()
  const {
    closeSidePanel,
    sidepanelContext: { macroPhraseId: id },
  } = useSidepanel()

  const { data: macroPhrase, loading: loadingMacroPhrase } = useQuery(
    GET_MACRO_PHRASE_BY_ID_QUERY,
    {
      variables: {
        id,
      },
      skip: !id,
    }
  )

  const [upsertMacroPhrase, { loading: upsertingMacroPhrase }] = useMutation(
    UPSERT_MACRO_PHRASE,
    {
      onCompleted: () => {
        if (id) {
          toast.success(`Macro phrase updated`)
        } else {
          toast.success(`Macro phrase added`)
        }

        closeSidePanel()
      },
      refetchQueries: ['GetMacroPhrasesBySection'],
    }
  )

  let sectionValue: string
  switch (section) {
    case 'dev-surveillance':
      sectionValue = 'DEVELOPMENTAL_SURVEILLANCE'
      break
    case 'patient-education':
      sectionValue = 'PATIENT_EDUCATION'
      break
    case 'adolescent-confidential':
      sectionValue = 'ADOLESCENT_CONFIDENTIAL'
      break
    case 'physical-exam':
      sectionValue = 'PHYSICAL_EXAM'
      break
    case 'past-medical-history':
      sectionValue = 'PAST_MEDICAL_HISTORY'
      break
    default:
      sectionValue = section.toUpperCase()
      break
  }

  const formMethods = useForm({
    defaultValues: macroPhrase
      ? {
          title: macroPhrase.macroPhrase.title,
          keyword: macroPhrase.macroPhrase.keyword,
          phrase: macroPhrase.macroPhrase.phrase,
          section: macroPhrase.macroPhrase.section,
          subsection: macroPhrase.macroPhrase.subsection,
        }
      : {
          title: '',
          keyword: '',
          phrase: '',
          section: !macroPhrase ? sectionValue : null,
          subsection: null,
        },
  })

  const { setValue, watch } = formMethods
  const selectedSection = watch('section')

  const onSubmit = (data) => {
    void upsertMacroPhrase({
      variables: {
        id: id,
        input: {
          title: data.title,
          keyword: data.keyword,
          phrase: data.phrase,
          section: data.section,
          subsection: data.subsection,
        },
      },
    })
  }

  // Set form values when editing macroPhrase
  useEffect(() => {
    if (macroPhrase) {
      formMethods.reset(macroPhrase.macroPhrase)
    }
  }, [macroPhrase, formMethods])

  return (
    <SidepanelPage
      header={id ? 'Edit macro phrase' : 'Add macro phrase'}
      // subHeader="Nunc tortor eget volutpat egestas est malesuada donec ipsum."
      subHeaderTextStyle="description"
      loading={loadingMacroPhrase}
    >
      <SidepanelForm
        footerProps={{
          cancelText: 'Cancel',
          submitText: id ? 'Save' : 'Add macro phrase',
          submitting: upsertingMacroPhrase,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <FormInputList
          items={[
            {
              name: 'title',
              label: 'Macro title',
              required: true,
              formInputComponent: InputField,
              direction: 'col',
            },
            {
              name: 'keyword',
              label: 'Macro keyword',
              required: true,
              formInputComponent: InputField,
              direction: 'col',
              message: 'All macro phrase keywords will start with "/".',
              inputProps: {
                placeholder: '/phrase',
                onChange: (e) => {
                  // We should not allow the '/' character, so if you type a '/', remove it
                  if (e.target.value.includes('/')) {
                    setValue('keyword', e.target.value.replace('/', ''))
                  }
                },
              },
            },
            {
              name: 'phrase',
              label: 'Macro phrase text',
              required: true,
              formInputComponent: WysiwygField,
              direction: 'col',
              inputProps: {
                testId: 'macro-phrase-text',
                defaultValue: macroPhrase?.macroPhrase.phrase,
                validation: {
                  validate: (value) => {
                    return JSON.parse(value).root.children[0].children
                      .length === 0
                      ? 'Macro phrase text should not be empty'
                      : null
                  },
                },
              },
            },
            {
              name: 'section',
              label: 'Section',
              required: true,
              formInputComponent: MultiRadioButtonField,
              direction: 'col',
              message:
                'Select one section you want your macro phrase to be accessible from.',
              inputProps: {
                values: compact(
                  [
                    {
                      label:
                        'Interim history and concerns / History of present illness (HPI)',
                      value: 'HPI',
                    },
                    {
                      label: 'Developmental surveillance',
                      value: 'DEVELOPMENTAL_SURVEILLANCE',
                    },
                    { label: 'Physical exam', value: 'PHYSICAL_EXAM' },
                    {
                      label: 'Adolescent confidential',
                      value: 'ADOLESCENT_CONFIDENTIAL',
                    },
                    { label: 'Assessment', value: 'ASSESSMENT' },
                    { label: 'Plan', value: 'PLAN' },
                    { label: 'Patient education', value: 'PATIENT_EDUCATION' },
                    { label: 'Attestation', value: 'ATTESTATION' },
                    currentUser.featureFlags.includes('SIMPLIFIED_VISIT')
                      ? { label: 'Visit summary', value: 'VISIT_SUMMARY' }
                      : null,
                    {
                      label: 'Past medical & surgical history',
                      value: 'PAST_MEDICAL_HISTORY',
                    },
                    {
                      label: 'Documents',
                      value: 'DOCUMENTS',
                    },
                  ].filter(Boolean)
                ),
              },
            },
            {
              name: 'subsection',
              label: 'Sub-section',
              required: true,
              formInputComponent: DropdownField,
              direction: 'col',
              message:
                'Select which sub-section you want your macro phrase to be accessible from.',
              hide: selectedSection !== 'PHYSICAL_EXAM',
              inputProps: {
                options: organSystems.map((system) => {
                  return {
                    value: system,
                    name: organSystemDisplay[system],
                  }
                }),
              },
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelMacroPhrase
