import { jsDateToLocalDate } from 'common/data/date'
import {
  hasAccessToPortalForPatient,
  PatientPortalUserRelationship,
} from 'common/patientPortalAuth'

import { useMutation } from '@redwoodjs/web'

import Toggle from 'src/components/molecules/Toggle/Toggle'
import { calculateAge } from 'src/lib/formatters'

const TOGGLE_MUTATION = gql`
  mutation ToggleCaregiverPortalAccessForPatient($relationshipId: String!) {
    togglePortalAccessOverride(id: $relationshipId) {
      id
      relatedPersonPortalAccessOverride
    }
  }
`

export const CaregiverPortalAccessToggle = ({
  relationship,
}: {
  relationship: PatientPortalUserRelationship & { id: string }
}) => {
  const [toggleAccess, { loading }] = useMutation(TOGGLE_MUTATION)

  const hasAccess = hasAccessToPortalForPatient({
    user: {
      relationships: [relationship],
    },
    patientId: relationship.patient.id,
  })

  const age = calculateAge(
    jsDateToLocalDate(relationship.patient.birthDate).toString()
  )

  if (relationship.relationshipType === 'SELF') return null

  return (
    <Toggle
      testId="caregiver-portal-access-toggle"
      onChange={() =>
        void toggleAccess({ variables: { relationshipId: relationship.id } })
      }
      size="l"
      checked={hasAccess}
      disabled={
        loading ||
        age.years < 18 ||
        (relationship.guardianshipType === 'NON_GUARDIAN' &&
          !relationship.isGuarantor)
      }
    />
  )
}
