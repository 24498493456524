const wysiwygTheme = {
  ltr: 'ltr',
  rtl: 'rtl',
  text: {
    bold: 'font-bold',
    italic: 'italic',
    underline: 'underline',
    code: 'font-mono',
  },
  list: {
    nested: {
      listitem: 'text-sm',
    },
    ol: 'my-4 pdf-ol',
    ul: 'my-4 pdf-ul',
    listitem: 'text-sm pdf-list-item',
  },
  placeholder:
    'text-sm font-normal pointer-events-none absolute inset-3 text-gray-500 text-muted',
  paragraph: 'text-sm font-normal',
  quote: 'p-3 my-4 text-sm border-l-4',
  link: 'text-primary underline font-bold',
  heading: {
    h1: 'font-bold text-2xl my-2',
    h2: 'font-bold text-xl my-2',
    h3: 'font-bold tex-lg my-2',
  },
}

export default wysiwygTheme
