type ImageSource = {
  src: string
  x?: number | undefined
  y?: number | undefined
  opacity?: number | undefined
}

interface Options {
  /**
   * A DOMString indicating the image format.
   *
   * @default 'image/png'
   */
  format?: string | undefined

  /**
   * A number between `0` and `1` indicating image quality if the requested format is `'image/jpeg'` or `'image/webp'`.
   *
   * @default 0.92
   */
  quality?: number | undefined

  /**
   * The width in pixels the rendered image should be. Defaults to the width of the widest source image.
   *
   * @default undefined
   *
   * @example
   * mergeImages(['/body.png', '/eyes.png', '/mouth.png'], {
   *   width: 128,
   * })
   *   .then(b64 => ...);
   *   // data:image/png;base64,iVBORw0KGgoAA...
   */
  width?: number | undefined

  /**
   * The height in pixels the rendered image should be. Defaults to the height of the tallest source image.
   *
   * @default undefined
   *
   * @example
   * mergeImages(['/body.png', '/eyes.png', '/mouth.png'], {
   *   height: 128,
   * })
   *   .then(b64 => ...);
   *   // data:image/png;base64,iVBORw0KGgoAA...
   */
  height?: number | undefined

  /**
   * The `crossOrigin` attribute that `Image` instances should use. e.g `anonymous` to [support CORS-enabled images](https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image).
   *
   * @default undefined
   */
  crossOrigin?: 'anonymous' | 'use-credentials' | '' | undefined
}

const defaultOptions: Options = {
  format: 'image/png',
  quality: 0.92,
  width: undefined,
  height: undefined,
  crossOrigin: undefined,
}

const mergeImages = async (
  sources: ImageSource[] = [],
  options: Options = {}
) => {
  options = { ...defaultOptions, ...options }

  const canvas = window.document.createElement('canvas')
  const Image = window.Image

  // Load sources
  const images = await Promise.all(
    sources.map(
      (source) =>
        new Promise<ImageSource & { img: HTMLImageElement }>(
          (resolve, reject) => {
            // Resolve source and img when loaded
            const img = new Image()
            img.crossOrigin = options.crossOrigin
            img.onerror = () => reject(new Error("Couldn't load image"))
            img.onload = () => resolve({ ...source, img })
            img.src = source.src
          }
        )
    )
  )

  // Get canvas context
  const ctx = canvas.getContext('2d')

  // Set canvas dimensions
  const getSize = (dim: 'height' | 'width') =>
    options[dim] || Math.max(...images.map((image) => image.img[dim]))
  canvas.width = getSize('width')
  canvas.height = getSize('height')

  // Draw images to canvas
  images.forEach((image) => {
    ctx.globalAlpha = image.opacity ? image.opacity : 1
    ctx.drawImage(image.img, image.x || 0, image.y || 0)
  })

  // Resolve all other data URIs sync
  return canvas.toDataURL(options.format, options.quality)
}

export default mergeImages
