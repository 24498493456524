import { FindAllergyManifestations } from 'types/graphql'

import { SelectFieldProps } from '@redwoodjs/forms/dist/SelectField'
import { useQuery } from '@redwoodjs/web'

import SelectField from 'src/components/atoms/SelectField/SelectField'

const QUERY = gql`
  query FindAllergyManifestations {
    allergyManifestations {
      code
      display
    }
  }
`

export const AllergyManifestationSelectField = (props: SelectFieldProps) => {
  const { data, loading } = useQuery<FindAllergyManifestations>(QUERY)

  if (loading) return null

  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={data.allergyManifestations.map(({ code, display }) => ({
        value: code,
        display,
      }))}
    />
  )
}

export default AllergyManifestationSelectField
