import { useParams } from 'react-router-dom'
import { OrderWithObservationsFragment } from 'types/graphql'

import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import QuestionnaireOrderSidepanelView from 'src/components/Order/QuestionnaireOrderSidepanelView/QuestionnaireOrderSidepanelView'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import { usePatientOrdersWithObservationsQuery } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'
import SidepanelNotAvailable from 'src/pages/Sidepanel/SidepanelNotAvailable/SidepanelNotAvailable'

const SidepanelQuestionnaireOrderView = () => {
  const { patientId, isPatientChart } = useIsPatientChart()

  const { orderId, encounterId } = useParams()

  const { ordersWithObservations } =
    usePatientOrdersWithObservationsQuery(patientId)

  const order = ordersWithObservations?.find((order) => order.id === orderId)
  if (!isPatientChart || !order) {
    return <SidepanelNotAvailable />
  }

  const getQuestionnaireHeader = (order: OrderWithObservationsFragment) => {
    if (order.questionnaire) {
      return order.codeDisplay || order.questionnaires[0]?.['title']
    }

    if (order.questionnaires) {
      return order.questionnaires[0]?.['title'] // TODO: change [0] to [tabIndex] when second tab implemented
    }
  }

  return (
    <SidepanelPage
      testId="sidepanel-questionnaire-order-view"
      header={getQuestionnaireHeader(order)}
    >
      <QuestionnaireOrderSidepanelView
        key={order.id}
        patientId={patientId}
        encounterId={encounterId}
        order={order}
      />
    </SidepanelPage>
  )
}

export default SidepanelQuestionnaireOrderView
