const ItalicIcon = ({ className }) => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 11.25H2.5M2.5 11.25H4M2.5 11.25L5.5 0.75M4 0.75H5.5M5.5 0.75H7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ItalicIcon
