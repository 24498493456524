import { useParams } from 'react-router-dom'

import PatientFormEditCell from 'src/components/PatientFormEditCell'

const SidepanelPatientFormEdit = () => {
  const { patientFormId } = useParams()

  return <PatientFormEditCell id={patientFormId} />
}

export default SidepanelPatientFormEdit
