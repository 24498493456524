import { useEffect } from 'react'

import { ExtendSessionResponse } from 'types/graphql'
import { useIsMounted } from 'usehooks-ts'

import { useMutation } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'

const EXTEND_SESSION_MUTATION = gql`
  mutation extendSession {
    extendSession {
      sessionExpiresAt
    }
  }
`

export const useExtendSession = (periodMilliseconds: number) => {
  const { currentUser, reauthenticate } = useEmrAuth()
  const [extendSession] = useMutation<ExtendSessionResponse, void>(
    EXTEND_SESSION_MUTATION
  )
  const isMounted = useIsMounted()
  useEffect(() => {
    let id: NodeJS.Timeout

    if (currentUser) {
      id = setInterval(() => {
        if (isMounted()) {
          extendSession().catch(() => {
            reauthenticate()
          })
        }
      }, periodMilliseconds)
    }

    return () => {
      if (id) {
        clearInterval(id)
      }
    }
  }, [
    currentUser,
    extendSession,
    periodMilliseconds,
    reauthenticate,
    isMounted,
  ])
}
