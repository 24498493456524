import type { TargetTimeUnit } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const targetTimeUnitDisplay: {
  [key in TargetTimeUnit]: string
} = {
  DAY: 'day(s)',
  WEEK: 'week(s)',
  MONTH: 'month(s)',
  YEAR: 'year(s)',
  CUSTOM: '',
}

export const targetTimeUnitType = nameValueArrayFromObject<TargetTimeUnit>(
  targetTimeUnitDisplay
)
