import { GetVfcEligibility } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

const GET_VFC_ELIGIBILITY = gql`
  query GetVfcEligibility($patientId: String!) {
    patient(id: $patientId) {
      id
      vfcEligibility
    }
  }
`

export const usePatientVfcEligibility = (patientId: string) => {
  return useQuery<GetVfcEligibility>(GET_VFC_ELIGIBILITY, {
    variables: {
      patientId: patientId,
    },
  })
}
