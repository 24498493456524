import { ReactElement, ReactNode, useEffect, useState } from 'react'

import { Tab } from '@headlessui/react'
import clsx from 'clsx'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView/StackView'

interface TabProps {
  name: string
  key?: string
  content?: ReactNode
  icon?: ReactElement
}

interface StatelessTabsProps {
  tabs: TabProps[]
  hideContent?: boolean
  className?: string
  tabClassName?: string
  /** Whether or not the tabs should be contained within the size of the parent div */
  contained?: boolean
  style?: 'button' | 'border'
  growTabs?: boolean
  testId?: string
  selectedTabIndex: number
  onTabChange: (index: number) => void
}

type TabsProps = Omit<
  StatelessTabsProps,
  'selectedTabIndex' | 'onTabChange'
> & {
  initialSelectedTabIndex?: number
}

export const StatelessTabs: React.FC<
  React.PropsWithChildren<StatelessTabsProps>
> = ({
  tabs,
  hideContent,
  className,
  tabClassName,
  contained,
  style = 'border',
  children,
  growTabs = false,
  testId,
  selectedTabIndex: selectedIndex,
  onTabChange,
}) => {
  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
      <Tab.List
        data-testid={testId}
        className={clsx(
          'flex',
          !growTabs && 'flex-wrap',
          style === 'border' && '-mb-px border-b border-gray-200',
          !growTabs && style === 'border' && 'space-x-8',
          !growTabs && style === 'button' && 'space-x-4',
          className
        )}
      >
        {tabs.map((tab, index) => {
          if (style === 'border') {
            return (
              <Tab
                key={tab.key || tab.name}
                className={({ selected }) =>
                  clsx(
                    'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium focus-visible:outline-none',
                    !growTabs && contained && 'mx-4',
                    growTabs && contained && index === 0 && 'ml-4',
                    growTabs &&
                      contained &&
                      index === tabs.length - 1 &&
                      'mr-4',
                    selected
                      ? 'border-primary text-primary'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    growTabs && 'flex-grow',
                    tabClassName
                  )
                }
              >
                <StackView
                  direction="row"
                  space={50}
                  data-testid="tab-item"
                  justifyContent={growTabs ? 'center' : 'start'}
                >
                  {tab.icon}
                  <Box>{tab.name}</Box>
                </StackView>
              </Tab>
            )
          }

          if (style === 'button') {
            return (
              <Tab
                key={tab.key || tab.name}
                className={({ selected }) =>
                  clsx(
                    'whitespace-nowrap text-sm font-medium focus-visible:outline-none',
                    selected
                      ? 'bg-gray-100 !text-gray-900'
                      : 'border-transparent !text-gray-600 hover:text-gray-700',
                    growTabs && 'flex-grow',
                    tabClassName
                  )
                }
                as={Button}
                buttonStyle="ghost"
              >
                <StackView
                  direction="row"
                  space={50}
                  data-testid="tab-item"
                  justifyContent={growTabs ? 'center' : 'start'}
                >
                  {tab.icon}
                  <Box>{tab.name}</Box>
                </StackView>
              </Tab>
            )
          }
        })}
        {children}
      </Tab.List>
      <Tab.Panels
        className={clsx('mt-2', contained && 'flex flex-1 overflow-y-hidden')}
      >
        {tabs.map((tab, idx) => {
          if (hideContent) {
            return (
              <Box
                key={idx}
                className={clsx(idx !== selectedIndex && 'hidden')}
              >
                {tab.content}
              </Box>
            )
          }

          return (
            <Tab.Panel
              className={clsx(
                'pt-core-space-100',
                contained && 'flex flex-1 overflow-y-auto'
              )}
              key={tab.key || idx}
            >
              {tab.content}
            </Tab.Panel>
          )
        })}
      </Tab.Panels>
    </Tab.Group>
  )
}

const Tabs: React.FC<React.PropsWithChildren<TabsProps>> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(
    props.initialSelectedTabIndex ?? 0
  )

  const { tabs } = props

  useEffect(() => {
    if (selectedIndex >= tabs.length) {
      setSelectedIndex(tabs.length - 1)
    } else if (selectedIndex < 0 && tabs.length > 0) {
      setSelectedIndex(0)
    }
  }, [tabs, selectedIndex])

  return (
    <StatelessTabs
      {...props}
      selectedTabIndex={selectedIndex}
      onTabChange={setSelectedIndex}
    />
  )
}

export default Tabs
