import {
  ArrowTopRightOnSquareIcon,
  ExclamationCircleIcon,
  PencilIcon,
  PlusIcon,
} from '@heroicons/react/24/solid'
import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { compact } from 'lodash'
import { Link, useParams } from 'react-router-dom'
import {
  GetCaregiverForPortalAccount,
  GetCaregiverForPortalAccountVariables,
  ListCaregiverDuplicatePortalAccounts,
  ListCaregiverDuplicatePortalAccountsVariables,
} from 'types/graphql'

import {
  navigate,
  routes,
  useLocation,
  useParams as useRWParams,
} from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import Badge from 'src/components/atoms/Badge/Badge'
import Button from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import Alert from 'src/components/molecules/Alert/Alert'
import { PortalUserStatusBadge } from 'src/components/molecules/PortalUserStatusBadge/PortalUserStatusBadge'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import {
  genderIdentityAbbreviation,
  genderIdentityDisplay,
} from 'src/data/genderIdentities'
import { guardianshipTypeDisplay } from 'src/data/guardianshipTypes'
import { maritalStatusDisplay } from 'src/data/maritalStatuses'
import { sexAbbreviation } from 'src/data/sexes'
import {
  formatAddress,
  formatAddressLine1,
  formatAge,
  formatDateDisplay,
  formatPhoneNumber,
} from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'

const CAREGIVER_QUERY = gql`
  query GetCaregiverForPortalAccount($id: String!) {
    relatedPerson(id: $id) {
      id
      givenName
      familyName
      birthDate
      genderIdentity
      maritalStatus
      patientRelatedPersonRelationships {
        id
        relationshipType
        guardianshipType
        isGuarantor
        patient {
          id
          givenName
          familyName
          sexAtBirth
          birthDate
        }
      }
      contactInformation {
        id
        mobileNumber
        homeAddress {
          id
          line1
          line2
          city
          state
          postalCode
        }
      }
      portalUserStatus
    }
  }
`

const DUPLICATE_ACCOUNT_QUERY = gql`
  query ListCaregiverDuplicatePortalAccounts($mobileNumber: PhoneNumber!) {
    contactInformations(input: { mobileNumber: $mobileNumber }) {
      id
      mobileNumber
      homeAddress {
        id
        line1
        line2
        city
        state
        postalCode
      }
      patient {
        id
        givenName
        familyName
        sexAtBirth
        birthDate
        patientRelatedPersonRelationships {
          id
          relatedPerson {
            id
            givenName
            familyName
          }
        }
      }
      relatedPerson {
        id
        givenName
        familyName
        genderIdentity
        birthDate
        patientRelatedPersonRelationships {
          id
          patient {
            id
            givenName
            familyName
          }
        }
      }
      practitioner {
        id
      }
    }
  }
`

const SidepanelCaregiverFamilyPortalAccount = () => {
  const { relatedPersonId } = useParams()
  const location = useLocation()
  const params = useRWParams()

  const { query } = params

  const { data } = useQuery<
    GetCaregiverForPortalAccount,
    GetCaregiverForPortalAccountVariables
  >(CAREGIVER_QUERY, {
    variables: {
      id: relatedPersonId,
    },
  })

  const mobileNumber = data?.relatedPerson?.contactInformation?.mobileNumber
  const duplicateAccountsQuery = useQuery<
    ListCaregiverDuplicatePortalAccounts,
    ListCaregiverDuplicatePortalAccountsVariables
  >(DUPLICATE_ACCOUNT_QUERY, {
    variables: {
      mobileNumber,
    },
    skip: !mobileNumber,
  })

  if (!data?.relatedPerson) return

  const relatedPerson = data.relatedPerson

  const duplicateAccounts =
    duplicateAccountsQuery.data?.contactInformations?.filter(
      (contactInfo) =>
        !contactInfo.practitioner &&
        contactInfo.id !== relatedPerson.contactInformation.id
    ) ?? []

  const sections = [
    duplicateAccounts.length
      ? {
          warning:
            'There are other accounts with the same mobile phone number. You can resolve this error and set up the correct unique portal access by manually activating a single duplicate account and/or changing the mobile phone number for one or more of the duplicate accounts.',
          title: 'Duplicate account(s)',
          description:
            'The following accounts share the same mobile phone number as this account, please resolve.',
          action: (
            <Button
              icon={ExclamationCircleIcon}
              text="Resolve"
              onClick={() =>
                navigate(
                  sidepanelRoute(
                    {
                      route: `/family-portal-account/duplicate-resolution/${relatedPerson.contactInformation.id}`,
                      onCloseSidepanelGoToPreviousRoute: true,
                      width: 'medium',
                      overlay: true,
                    },
                    location,
                    params
                  )
                )
              }
            />
          ),
          content: (
            <StackView gap={25}>
              {duplicateAccounts.map((account) => {
                const person = account.relatedPerson ?? account.patient

                return (
                  <Link
                    key={account.id}
                    to={routes.familyPortalAdmin({
                      userType: 'caregiver',
                      query,
                      sidepanelContext: JSON.stringify({
                        route: `/family-portal-account/caregiver/${person.id}`,
                        width: 'medium',
                        overlay: true,
                      }),
                    })}
                    target="_blank"
                    className="hover:no-underline"
                  >
                    <Card
                      testId="duplicate-account-item"
                      className="hover:cursor-pointer hover:bg-base-color-bg-subtle"
                    >
                      <StackView className="px-core-space-100 py-core-space-75">
                        <StackView direction="row" alignItems="center">
                          <StackView gap={25}>
                            <StackView direction="row" gap={25}>
                              <Typography
                                textStyle="interface-strong-s"
                                color="text-base-color-fg-muted"
                              >
                                {formatDisplayName(person)}
                              </Typography>

                              <ExclamationCircleIcon className="h-base-size-icon-xs w-base-size-icon-xs text-danger" />
                            </StackView>

                            <Typography
                              textStyle="interface-default-xs"
                              color="text-base-color-fg-subtle"
                            >
                              {compact([
                                account.patient
                                  ? sexAbbreviation[account.patient.sexAtBirth]
                                  : genderIdentityAbbreviation[
                                      account.relatedPerson.genderIdentity
                                    ],
                                `${formatAge(
                                  person.birthDate
                                )} (${formatDateDisplay(person.birthDate)})`,
                                formatAddressLine1(account.homeAddress),
                                formatPhoneNumber(account.mobileNumber),
                                account.patient
                                  ? [
                                      'Caregiver(s)',
                                      account.patient.patientRelatedPersonRelationships
                                        .map((relationship) =>
                                          formatDisplayName(
                                            relationship.relatedPerson
                                          )
                                        )
                                        .join(', '),
                                    ].join(': ')
                                  : [
                                      'Patient(s)',
                                      account.relatedPerson.patientRelatedPersonRelationships
                                        .map((relationship) =>
                                          formatDisplayName(
                                            relationship.patient
                                          )
                                        )
                                        .join(', '),
                                    ].join(': '),
                              ]).join(' • ')}
                            </Typography>
                          </StackView>

                          <Badge
                            text={account.patient ? 'Patient' : 'Caregiver'}
                            color={account.patient ? 'red' : 'purple'}
                          />
                        </StackView>
                      </StackView>
                    </Card>
                  </Link>
                )
              })}
            </StackView>
          ),
        }
      : null,
    {
      title: 'Linked patient(s)',
      description:
        'All patients associated with this caregiver account. By default, a caregiver will only have portal access to patients age <18 for whom they are a guardian and/or guarantor.',
      action: (
        <Button
          testId="link-patients-btn"
          icon={PlusIcon}
          text="Add"
          buttonStyle="secondary"
          onClick={() =>
            navigate(
              sidepanelRoute(
                {
                  route: `/family-portal-account/caregiver/${relatedPerson.id}/link-patients`,
                  onCloseSidepanelGoToPreviousRoute: true,
                  width: 'medium',
                  overlay: true,
                },
                location,
                params
              )
            )
          }
        />
      ),
      content: (
        <StackView gap={25}>
          {relatedPerson.patientRelatedPersonRelationships.map(
            (relationship) => {
              const { patient } = relationship

              return (
                <Link
                  key={relationship.id}
                  to={routes.patientCharts({ id: patient.id })}
                  target="_blank"
                  className="hover:no-underline"
                >
                  <Card
                    testId="linked-patient-item"
                    className="text-base-color-fg-default hover:cursor-pointer hover:bg-base-color-bg-subtle"
                  >
                    <StackView
                      direction="row"
                      className="px-core-space-100 py-core-space-75"
                      justifyContent="between"
                      alignItems="center"
                    >
                      <StackView>
                        <Typography
                          textStyle="interface-strong-s"
                          color="text-base-color-fg-muted"
                        >
                          {formatDisplayName(patient)}
                        </Typography>

                        <Typography
                          textStyle="interface-default-xs"
                          color="text-base-color-fg-subtle"
                        >
                          {compact([
                            sexAbbreviation[patient.sexAtBirth],
                            `${formatAge(
                              patient.birthDate
                            )} (${formatDateDisplay(patient.birthDate)})`,
                            guardianshipTypeDisplay[
                              relationship.guardianshipType
                            ],
                            relationship.isGuarantor
                              ? 'Guarantor'
                              : 'Non-guarantor',
                            relationshipTypeDisplay[
                              relationship.relationshipType
                            ],
                          ]).join(' • ')}
                        </Typography>
                      </StackView>

                      <ArrowTopRightOnSquareIcon className="h-base-size-icon-s w-base-size-icon-s" />
                    </StackView>
                  </Card>
                </Link>
              )
            }
          )}
        </StackView>
      ),
    },
    {
      title: 'Mobile login details',
      description:
        'The mobile phone number below is what the caregiver should use to login to the family portal.',
      action: (
        <Button
          testId="edit-mobile-number-btn"
          buttonStyle="secondary"
          text="Edit"
          icon={PencilIcon}
          onClick={() =>
            navigate(
              sidepanelRoute(
                {
                  route: `/family-portal-account/login-details/edit/${relatedPerson.contactInformation.id}`,
                  onCloseSidepanelGoToPreviousRoute: true,
                },
                location,
                params
              )
            )
          }
        />
      ),
      content: (
        <StackView
          className="border-b-core-border-width-10 border-t-core-border-width-10 border-base-color-bg-subtle py-core-space-75"
          direction="row"
        >
          <Typography
            textStyle="interface-strong-s"
            color="text-base-color-fg-subtle"
            className="flex-1"
          >
            Mobile phone number
          </Typography>
          <Typography className="flex-1">
            {formatPhoneNumber(relatedPerson.contactInformation.mobileNumber)}
          </Typography>
        </StackView>
      ),
    },
    {
      title: 'Demographics',
      content: (
        <StackView
          className="border-b-core-border-width-10 border-t-core-border-width-10 border-base-color-bg-subtle"
          justifyContent="between"
          divider
        >
          {[
            ['Date of birth', formatDateDisplay(relatedPerson.birthDate)],
            ['Sex', genderIdentityDisplay[relatedPerson.genderIdentity]],
            [
              'Address',
              formatAddress(relatedPerson.contactInformation.homeAddress),
            ],
            [
              'Marital status',
              maritalStatusDisplay[relatedPerson.maritalStatus] ?? 'N/A',
            ],
          ].map(([key, value]) => {
            return (
              <StackView
                key={key}
                direction="row"
                className="py-core-space-75"
                alignItems="center"
              >
                <Typography
                  textStyle="interface-strong-s"
                  color="text-base-color-fg-subtle"
                  className="flex-1"
                >
                  {key}
                </Typography>
                <Typography color="text-base-color-fg-muted" className="flex-1">
                  {value}
                </Typography>
              </StackView>
            )
          })}
        </StackView>
      ),
    },
  ]

  return (
    <SidepanelPage
      header={
        <StackView gap={25} alignItems="start">
          <PortalUserStatusBadge
            status={relatedPerson.portalUserStatus}
            userType="relatedPerson"
          />
          <Typography textStyle="title-s">
            {formatDisplayName(relatedPerson)}
          </Typography>
          <Typography
            textStyle="interface-default-s"
            color="text-base-color-fg-muted"
          >
            {compact([
              genderIdentityAbbreviation[relatedPerson.genderIdentity],
              `${formatAge(relatedPerson.birthDate)} (${formatDateDisplay(
                relatedPerson.birthDate
              )})`,
            ]).join(' • ')}
          </Typography>
        </StackView>
      }
    >
      <StackView gap={150} className="px-core-space-150 py-core-space-100">
        {sections.filter(Boolean).map((section) => {
          return (
            <StackView key={section.title} gap={100}>
              {section.warning ? (
                <Alert title={section.warning} style="danger" />
              ) : null}
              <StackView
                direction="row"
                justifyContent="between"
                alignItems="center"
                gap={50}
              >
                <StackView gap={25}>
                  <Typography textStyle="title-xs">{section.title}</Typography>
                  {section.description ? (
                    <Typography
                      textStyle="interface-default-xs"
                      color="text-base-color-fg-muted"
                    >
                      {section.description}
                    </Typography>
                  ) : null}
                </StackView>

                {section.action}
              </StackView>

              {section.content}
            </StackView>
          )
        })}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelCaregiverFamilyPortalAccount
