import { useLocation, useParams } from '@redwoodjs/router'

const useIsPatientChart = () => {
  const { id } = useParams()
  const { pathname } = useLocation()

  const isPatientChart = pathname.includes('patient-charts')

  return { isPatientChart, patientId: isPatientChart ? id : null }
}

export default useIsPatientChart
