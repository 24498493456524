import { menstrualRegularityConceptCodings } from 'common/cdr/concepts/observations/menstrualHistories/index'
import { MenstrualRegularity } from 'types/graphql'

import { nameValueArrayFromObject } from 'src/data/utils'

export const menstrualRegularityDisplay: {
  [key in MenstrualRegularity]: string
} = {
  IRREGULAR: 'Irregular',
  REGULAR: 'Regular',
}

export const menstrualRegularityDisplayToEnum = Object.keys(
  menstrualRegularityDisplay
).reduce((acc, key) => {
  acc[menstrualRegularityDisplay[key]] = key
  return acc
}, {})

export const menstrualRegularityCodeToEnum =
  menstrualRegularityConceptCodings.reduce((acc, coding) => {
    if (menstrualRegularityDisplayToEnum[coding.display]) {
      acc[coding.code] = menstrualRegularityDisplayToEnum[coding.display]
    }
    return acc
  }, {})

export const menstrualRegularities =
  nameValueArrayFromObject<MenstrualRegularity>(menstrualRegularityDisplay)
