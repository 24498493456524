import { FeatureFlag } from 'types/graphql'

import { useEmrAuth } from 'src/auth'

export const useFeatureFlagIsEnabled = (flagName: FeatureFlag) => {
  const { currentUser } = useEmrAuth()
  const flags = currentUser?.featureFlags ?? []
  const isEnabled = flags.includes(flagName)
  return isEnabled
}

export default useFeatureFlagIsEnabled
