import { bloodTypeConceptCodings } from 'common/cdr/concepts/observations/birthHistories/index'
import { BloodType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const bloodTypeDisplay: {
  [key in BloodType]: string
} = {
  A_POSITIVE: 'A+',
  A_NEGATIVE: 'A-',
  B_POSITIVE: 'B+',
  B_NEGATIVE: 'B-',
  AB_POSITIVE: 'AB+',
  AB_NEGATIVE: 'AB-',
  O_POSITIVE: 'O+',
  O_NEGATIVE: 'O-',
}

export const bloodTypeDisplayToEnum = Object.keys(bloodTypeDisplay).reduce(
  (acc, key) => {
    acc[bloodTypeDisplay[key]] = key
    return acc
  },
  {}
)

export const bloodTypeCodeToEnum = bloodTypeConceptCodings.reduce(
  (acc, coding) => {
    if (bloodTypeDisplayToEnum[coding.display]) {
      acc[coding.code] = bloodTypeDisplayToEnum[coding.display]
    }
    return acc
  },
  {}
)

export const bloodTypes = nameValueArrayFromObject<BloodType>(bloodTypeDisplay)
