import { useFormContext } from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box'
import { InputFieldProps } from 'src/components/atoms/InputField'
import StackView from 'src/components/atoms/StackView'
import FieldError from 'src/components/FieldError/FieldError'
import {
  getUnitOptions,
  InputFieldWithSelectableUnit,
} from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import { validateVitalFn } from 'src/components/VisitIntakeVital'

export type BodyWeightInputProps = {
  allowPartialInput?: boolean
} & InputFieldProps

export const BodyWeightInput = ({
  name,
  validation,
  allowPartialInput = true,
  ...props
}: BodyWeightInputProps) => {
  const { register, watch, getValues } = useFormContext()
  const currentWeightWholeUnit = getValues(`${name}.wholeUnit.unit`)

  register(name, {
    ...validation,
    validate: (value) => {
      const isInputDirty = value.wholeUnit?.value || value.partialUnit?.value

      const isSplitInputPartiallyFilled =
        allowPartialInput &&
        value.wholeUnit?.unit === 'lbs' &&
        !(value.wholeUnit?.value && value.partialUnit?.value)

      if (validation.required) {
        if (!isInputDirty) {
          return validation.required as string // validation.required should house the error message to be displayed
        }

        if (isSplitInputPartiallyFilled) {
          return `Both ${value.wholeUnit.unit} and ${value.partialUnit.unit} are required`
        }
      }

      if (isInputDirty && isSplitInputPartiallyFilled) {
        return `Both ${value.wholeUnit.unit} and ${value.partialUnit.unit} are required`
      }
    },
  })

  const bodyWeightWholeUnit = watch('bodyWeight.wholeUnit.unit')
  const bodyWeightPartialUnit = watch('bodyWeight.partialUnit.unit')

  const bodyWeightWholeValue = watch('bodyWeight.wholeUnit.value')
  const bodyWeightPartialValue = watch('bodyWeight.partialUnit.value')

  return (
    <StackView id={name}>
      <StackView fullWidth direction="row" gap={50}>
        <Box fullWidth>
          <InputFieldWithSelectableUnit
            {...props}
            name={`${name}.wholeUnit`}
            unitOptions={getUnitOptions(['lbs', 'kg'])}
            validation={{
              validate: bodyWeightWholeValue
                ? validateVitalFn('bodyWeightWholeUnit', bodyWeightWholeUnit)
                : undefined,
              required: validation.required,
              shouldUnregister: true,
            }}
            setValueAsString
          />
          <FieldError name={`${name}.wholeUnit.value`} label="Weight" />
        </Box>
        {allowPartialInput && currentWeightWholeUnit === 'lbs' && (
          <>
            <Box fullWidth>
              <InputFieldWithSelectableUnit
                {...props}
                name={`${name}.partialUnit`}
                unitOptions={getUnitOptions(['oz'])}
                validation={{
                  validate: bodyWeightPartialValue
                    ? validateVitalFn(
                        'bodyWeightPartialUnit',
                        bodyWeightPartialUnit
                      )
                    : undefined,
                  required: validation.required,
                  shouldUnregister: true,
                }}
                setValueAsString
              />
              <FieldError name={`${name}.partialUnit.value`} label="Weight" />
            </Box>
          </>
        )}
      </StackView>
    </StackView>
  )
}
