import { useRef } from 'react'

import { PencilIcon } from '@heroicons/react/24/solid'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import clsx from 'clsx'
import { patientScoreHistoryActionsDisplay } from 'common/data/patientScoreHistoryActions'
import {
  patientScoreTypeToScoringSystem,
  patientScoreTypeToScoringSystemDiplay,
} from 'common/data/patientScoringSystems'
import { scoreDisplay } from 'common/data/scores'
import { format, parseISO } from 'date-fns'
import { useParams } from 'react-router-dom'
import { Score, StateTransition } from 'types/graphql'

import { useParams as useParamsRW, navigate } from '@redwoodjs/router'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import { DataDisplayList } from 'src/components/molecules/DataDisplayList'
import DestructiveAction from 'src/components/molecules/DestructiveAction/DestructiveAction'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import Table from 'src/components/molecules/Table/Table'
import { sidepanelRoute } from 'src/lib/routes'
import { useDeletePatientScore } from 'src/pages/PatientChartsPage/PatientHistory/PatientScores/useDeletePatientScore'
import { usePatientScoresQuery } from 'src/pages/PatientChartsPage/PatientHistory/PatientScores/usePatientScores'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const defaultColDef: ColDef = {
  cellStyle: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  cellClass: 'cursor-pointer',
  resizable: true,
  minWidth: 90,
}

const determineScoreColorClassname = ({
  previous,
  current,
}: {
  previous: number
  current: number
}): `text-base-color-fg-${'danger' | 'success' | 'muted'}` => {
  if (previous > current) return 'text-base-color-fg-danger'
  if (previous < current) return 'text-base-color-fg-success'
  return 'text-base-color-fg-muted'
}

const convertStateTransitionToComponent = (
  stateTransition: StateTransition
): JSX.Element => {
  if (!stateTransition) return null
  const { previous, current } = stateTransition
  return (
    <Typography
      className={clsx(
        determineScoreColorClassname({
          previous,
          current,
        })
      )}
    >
      {[previous !== current && previous, previous !== current && '->', current]
        .filter(Boolean)
        .join(' ')}
    </Typography>
  )
}

const columnDefs: ColDef[] = [
  {
    colId: 'action',
    headerName: 'Action',
    cellRenderer: ({ data }) => {
      return (
        <StackView className="h-full" justifyContent="center">
          <Typography
            textStyle="interface-strong-s"
            className="overflow-hidden overflow-ellipsis"
          >
            {patientScoreHistoryActionsDisplay[data.action]}
          </Typography>
        </StackView>
      )
    },
  },
  {
    colId: 'score',
    headerName: 'Score',
    cellRenderer: ({ data }) => {
      return (
        <StackView className="h-full" justifyContent="center" alignItems="end">
          {convertStateTransitionToComponent(data.stateTransition)}
        </StackView>
      )
    },
    maxWidth: 90,
  },
  {
    colId: 'user',
    headerName: 'User',
    cellRenderer: ({ data }) => {
      return (
        <StackView className="h-full" justifyContent="center">
          <Typography
            textStyle="interface-default-s"
            color="text-base-color-fg-muted"
            className="overflow-hidden overflow-ellipsis"
          >
            {data.user}
          </Typography>
        </StackView>
      )
    },
  },
  {
    colId: 'date',
    headerName: 'Date',
    cellRenderer: ({ data }) => {
      return (
        <StackView className="h-full" justifyContent="center">
          <Typography
            textStyle="interface-default-s"
            color="text-base-color-fg-muted"
            className="overflow-hidden overflow-ellipsis"
          >
            {format(parseISO(data.date), 'MM/dd/yyyy')}
          </Typography>
        </StackView>
      )
    },
  },
]

const PatientScoreHistoryTable = ({
  patientScore,
}: {
  patientScore: Score
}) => {
  const gridRef = useRef<AgGridReact>()

  const history = patientScore?.history

  if (!history.length) return
  return (
    <Table
      testId="patient-score-history-table"
      innerRef={gridRef}
      rowData={history}
      domLayout="autoHeight"
      rowHeight={50}
      headerHeight={36}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
    />
  )
}

const SidepanelPatientScoreView = () => {
  const { closeSidePanel } = useSidepanel()

  const params = useParamsRW()

  const { patientId, patientScoringSystemCode } = useParams()

  const { patientScores } = usePatientScoresQuery(patientId)

  const [deletePatientScore, { loading: isDeletingPatientScore }] =
    useDeletePatientScore()

  const patientScore = patientScores?.find(
    (score) =>
      patientScoreTypeToScoringSystem[score.__typename] ===
      patientScoringSystemCode
  )

  if (!patientScore) return null

  const header: JSX.Element = (
    <StackView alignItems="start" space={25}>
      <Typography textStyle="title-s">
        {`${patientScoreTypeToScoringSystemDiplay[patientScore.__typename]}: ${
          scoreDisplay[patientScore.score]
        }`}
      </Typography>
      <Typography textStyle="body-s">
        {patientScore?.updatedAt
          ? `Last updated on ${format(
              parseISO(patientScore.updatedAt),
              'MM/dd/yyyy'
            )}`
          : ''}
      </Typography>
      <Button
        text="Edit"
        buttonStyle="secondary"
        testId="edit-patient-score-button"
        icon={PencilIcon}
        onClick={() =>
          navigate(
            sidepanelRoute(
              {
                route: `/patients/${patientId}/scores/${
                  patientScoreTypeToScoringSystem[patientScore?.__typename]
                }/edit`,
              },
              location,
              params
            )
          )
        }
      />
    </StackView>
  )

  return (
    <SidepanelPage testId="sidepanel-patient-score-view" header={header}>
      <Box className="px-core-space-150">
        <StackView space={25}>
          <DataDisplayList
            key={patientScore.__typename}
            title={'Details'}
            leftColumnWidth="lg"
            data={[
              {
                label: 'Scoring system',
                value:
                  patientScoreTypeToScoringSystemDiplay[
                    patientScore.__typename
                  ],
              },
              {
                label: 'Score',
                value: scoreDisplay[patientScore.score],
              },
              {
                label: 'Comment',
                value: patientScore.comments,
              },
            ]}
          />
        </StackView>

        <StackView space={50}>
          <Typography textStyle="title-xs">History</Typography>
          <PatientScoreHistoryTable patientScore={patientScore} />
        </StackView>

        <DestructiveAction
          title="Destructive actions"
          description="These changes are permanent, use caution when removing."
          buttonText="Remove patient score"
          doDestructiveAction={async () => {
            void deletePatientScore({
              variables: {
                input: {
                  patientId,
                  scoringSystem: patientScoringSystemCode,
                },
              },
              onCompleted: () => {
                closeSidePanel()
              },
            })
          }}
          destructiveActionIsLoading={isDeletingPatientScore}
        />
      </Box>
    </SidepanelPage>
  )
}

export default SidepanelPatientScoreView
