import { useEffect, useState } from 'react'

import { formatDisplayName } from 'common/utils'
import { endOfDay, startOfDay } from 'date-fns'
import sortBy from 'lodash/sortBy'
import {
  GetPrintedScheduleData,
  GetPrintedScheduleDataVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useAppointmentScheduleQuery } from 'src/components/organisms/ScheduleTable/useAppointmentSchedule'
import { appointmentStatusConfiguration } from 'src/data/appointmentStatus'
import {
  appointmentStatusFilterOptions,
  useScheduleFilter,
} from 'src/hooks/useScheduleFilter/useScheduleFilter'
import { PrintSchedule } from 'src/pdf/components/PrintSchedule/PrintSchedule'
import { TENANT_LETTERHEAD_FRAGMENT } from 'src/pdf/fragments'
import { useGeneratePDF } from 'src/pdf/useGeneratePDF'

const QUERY = gql`
  query GetPrintedScheduleData($practitionerIds: [String]) {
    practitioners(practitionerIds: $practitionerIds) {
      id
      givenName
      familyName
    }
    locations {
      id
      name
    }
    tenant {
      ...TenantLetterheadFragment
    }
  }
  ${TENANT_LETTERHEAD_FRAGMENT}
`

const SidepanelPrintSchedule = () => {
  const { visitDate, locationIds, practitionerIds, includeStatuses } =
    useScheduleFilter()

  const { currentUser } = useEmrAuth()

  const filters = {
    locationIds,
    practitionerIds,
    startDate: startOfDay(visitDate).toISOString(),
    endDate: endOfDay(visitDate).toISOString(),
    includeStatuses,
  }

  const { data } = useAppointmentScheduleQuery(
    !filters.includeStatuses?.length ||
      filters.includeStatuses?.includes('CANCELLED')
      ? filters
      : {
          ...filters,
          excludeStatuses: ['CANCELLED'],
        },
    {
      shouldPoll: false,
    }
  )

  const supplementalQuery = useQuery<
    GetPrintedScheduleData,
    GetPrintedScheduleDataVariables
  >(QUERY, {
    variables: {
      practitionerIds,
    },
  })

  const generatePDF = useGeneratePDF()
  const [isGenerating, setIsGenerating] = useState(false)
  const [fileUrl, setFileUrl] = useState<URL | null>(null)

  useEffect(() => {
    if (isGenerating) return
    if (fileUrl) return
    if (!data || !supplementalQuery.data) return

    setIsGenerating(true)

    const sortedAppointments = sortBy(
      data?.appointments ?? [],
      (appointment) => new Date(appointment.start)
    )

    const pdfFilters = {
      practitionerNames: practitionerIds?.length
        ? practitionerIds.map((id) => {
            const practitioner = supplementalQuery.data.practitioners?.find(
              (practitioner) => id === practitioner.id
            )

            return formatDisplayName(practitioner)
          })
        : supplementalQuery.data.practitioners.map((practitioner) =>
            formatDisplayName(practitioner)
          ),
      locationNames: locationIds?.length
        ? locationIds.map((id) => {
            const location = supplementalQuery.data.locations?.find(
              (location) => id === location.id
            )

            return location?.name
          })
        : supplementalQuery.data.locations.map(({ name }) => name),
      appointmentStatusNames: includeStatuses?.length
        ? includeStatuses?.map(
            (status) => appointmentStatusConfiguration[status].display
          )
        : appointmentStatusFilterOptions.map((option) => option.name),
    }

    void generatePDF({
      component: (
        <PrintSchedule
          appointments={sortedAppointments}
          tenant={supplementalQuery.data.tenant}
          forDate={visitDate}
          currentUser={currentUser}
          filters={pdfFilters}
        />
      ),
      callback: (doc) => {
        setFileUrl(doc.output('bloburl'))
        setIsGenerating(false)
      },
    })
  }, [
    data,
    supplementalQuery.data,
    generatePDF,
    currentUser,
    includeStatuses,
    locationIds,
    practitionerIds,
    visitDate,
    fileUrl,
    isGenerating,
  ])

  return (
    <SidepanelPage header="Print schedule">
      <StackView className="h-full pt-4" space={50}>
        {!fileUrl ? (
          <LoadingSpinner />
        ) : (
          <FileDisplay
            name="Schedule"
            type="application/pdf"
            url={fileUrl}
            showDownloadLink={false}
          />
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPrintSchedule
