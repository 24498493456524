import isEmpty from 'lodash/isEmpty'
import { ObservationDefinition } from 'types/graphql'

import { DropdownField } from 'src/components/atoms/Dropdown'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

export const hearingScreeningCodes = [
  'HEARING_SCREENING_PURE_TONE',
  'HEARING_SCREENING_PURE_TONE_AUDIOMETRY',
  'HEARING_SCREENING_TYMPANOMETRY',
  'OAE',
  'OAE_NEWBORN',
  'HEARING_SCREENING_NEWBORN_AUDITORY_EVOKED_POTENTIALS',
  'HEARING_SCREENING_AUDITORY_EVOKED_POTENTIALS',
  'DISTORTION_PRODUCT_EVOKED_OAE_NEWBORN_LIMITED_EVAL',
  'DISTORTION_PRODUCT_EVOKED_OAE_LIMITED_EVAL',
  'DISTORTION_PRODUCT_EVOKED_OAE_NEWBORN_COMPREHENSIVE_EVAL',
  'DISTORTION_PRODUCT_EVOKED_OAE_COMPREHENSIVE_EVAL',
]

const frequencyToFieldSuffix: Record<number, string> = {
  125: 'OneHundredTwentyFive',
  250: 'TwoHundredFifty',
  500: 'FiveHundred',
  1000: 'OneThousand',
  2000: 'TwoThousand',
  3000: 'ThreeThousand',
  4000: 'FourThousand',
  5000: 'FiveThousand',
  6000: 'SixThousand',
  7000: 'SevenThousand',
  8000: 'EightThousand',
}

const HearingScreeningResultInput = ({ name, options }) => {
  if (isEmpty(options)) return null

  return (
    <StackView justifyContent="center" alignItems="center">
      <div className="w-16">
        <DropdownField
          options={options}
          name={name}
          itemsContainerClassName="justify-center"
          className="border-none pr-0 shadow-none drop-shadow-none"
        />
      </div>
    </StackView>
  )
}

const HearingScreeningTableRow = ({ frequency, options }) => {
  return (
    <tr>
      <td className="border-t border-gray-200 px-6 py-4">
        <Typography>{`${frequency} Hz`}</Typography>
      </td>
      <td className="border-l border-t border-gray-200">
        <HearingScreeningResultInput
          name={`findings.right${frequencyToFieldSuffix[frequency]}.answerCode`}
          options={options}
        />
      </td>
      <td className="border-l border-t border-gray-200">
        <HearingScreeningResultInput
          name={`findings.left${frequencyToFieldSuffix[frequency]}.answerCode`}
          options={options}
        />
      </td>
    </tr>
  )
}

export const HearingScreeningTable = ({
  observationDefinitions,
}: {
  observationDefinitions: ObservationDefinition[]
}) => {
  const resultOptions = [
    { name: '', value: null },

    // because the first observation definition is for overall finding (by convention)
    ...observationDefinitions[1].validCodedValueSet.map((coding) => {
      return { name: coding.display, value: coding.code }
    }),
  ]

  return (
    <table className="min-w-full border-separate border-spacing-0 rounded-md border text-center ">
      <thead>
        <tr className="rounded-tl-md">
          <th scope="col" className=" border-gray-200px-6 py-4 ">
            <Typography>Frequency</Typography>
          </th>
          <th scope="col" className="border-l border-gray-200 px-6 py-4">
            <Typography>Right ear</Typography>
          </th>
          <th scope="col" className="border-l border-gray-200 px-6 py-4">
            <Typography>Left ear</Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        <HearingScreeningTableRow frequency={125} options={resultOptions} />
        <HearingScreeningTableRow frequency={250} options={resultOptions} />
        <HearingScreeningTableRow frequency={500} options={resultOptions} />
        <HearingScreeningTableRow frequency={1000} options={resultOptions} />
        <HearingScreeningTableRow frequency={2000} options={resultOptions} />
        <HearingScreeningTableRow frequency={3000} options={resultOptions} />
        <HearingScreeningTableRow frequency={4000} options={resultOptions} />
        <HearingScreeningTableRow frequency={5000} options={resultOptions} />
        <HearingScreeningTableRow frequency={6000} options={resultOptions} />
        <HearingScreeningTableRow frequency={7000} options={resultOptions} />
        <HearingScreeningTableRow frequency={8000} options={resultOptions} />
      </tbody>
    </table>
  )
}
