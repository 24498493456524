import { useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { GetSigningPractitionerForPdf } from 'types/graphql'

import PrintDocumentCaseSignatureDocument from 'src/pdf/components/PrintDocumentCaseSignatureDocument'
import {
  SIGNING_PRACTITIONER_FRAGMENT,
  TENANT_LETTERHEAD_FRAGMENT,
} from 'src/pdf/fragments'
import { useGeneratePDF } from 'src/pdf/useGeneratePDF'

export type OriginalFaxType = {
  faxReceivedAt: Date
  faxNumber: string
}

export const GET_SIGNING_PRACTITIONER_FOR_PDF = gql`
  query GetSigningPractitionerForPdf($id: String!) {
    practitioner(id: $id) {
      ...SigningPractitionerFragment
      tenant {
        ...TenantLetterheadFragment
      }
    }
  }
  ${TENANT_LETTERHEAD_FRAGMENT}
  ${SIGNING_PRACTITIONER_FRAGMENT}
`

export const useCreateDocumentCaseSignaturePdf = () => {
  const generatePDF = useGeneratePDF()
  const [isGenerating, setIsGenerating] = useState<boolean>(false)

  const [getSigningPractitioner] = useLazyQuery<GetSigningPractitionerForPdf>(
    GET_SIGNING_PRACTITIONER_FOR_PDF
  )

  const generateDocumentCaseSignaturePdf = async ({
    signedByPractitionerId,
    comment,
    addSignature,
    originalFax,
  }: {
    signedByPractitionerId: string
    comment?: string
    addSignature?: boolean
    originalFax?: OriginalFaxType
  }) => {
    const { data } = await getSigningPractitioner({
      variables: {
        id: signedByPractitionerId,
      },
    })

    setIsGenerating(true)
    const generated: Promise<string> = new Promise((resolve) => {
      generatePDF({
        component: (
          <PrintDocumentCaseSignatureDocument
            tenant={data.practitioner.tenant}
            addSignature={addSignature}
            practitioner={data.practitioner}
            comment={comment}
            originalFax={originalFax}
          />
        ),
        footer: false,
        callback: async (doc) => {
          setIsGenerating(false)
          resolve(doc.output('datauristring').split('base64,')[1])
        },
      })
    })
    return generated
  }

  return { isGenerating, generateDocumentCaseSignaturePdf }
}
