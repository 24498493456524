import type { CreatePatientFormInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import FileInput from 'src/components/atoms/FileInput/FileInput'
import InputField from 'src/components/atoms/InputField'
import PatientFormTypeSelectField from 'src/components/atoms/PatientFormTypeSelectField/PatientFormTypeSelectField'
import FormInputList, {
  Item,
} from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

const CREATE_PATIENT_FORM_MUTATION = gql`
  mutation CreatePatientForm($input: CreatePatientFormInput!) {
    createPatientForm(input: $input) {
      id
    }
  }
`

export const patientFormInputListItems: (opts?: {
  type?: string
  patientId?: string
  isDocumentCase?: boolean
}) => Item[] = () => [
  {
    name: 'type',
    label: 'Document sub-type',
    required: true,
    formInputComponent: PatientFormTypeSelectField,
  },
  {
    name: 'title',
    label: 'Document title',
    formInputComponent: InputField,
    required: true,
  },
]

const CreatePatientForm = () => {
  const { closeSidePanel } = useSidepanel()
  const [createPatientForm, { loading: creatingPatientForm }] = useMutation(
    CREATE_PATIENT_FORM_MUTATION,
    {
      onCompleted: () => {
        toast.success('Patient form created')
        closeSidePanel()
      },
      refetchQueries: ['PatientFormsQuery'],
    }
  )
  const formMethods = useForm<CreatePatientFormInput>()

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Upload document',
        submitting: creatingPatientForm,
      }}
      formMethods={formMethods}
      onSubmit={async (value) => {
        const { binary: binaryInput, ...input } = value

        input['binary'] = {
          content: await base64EncodeFile(binaryInput[0]),
          contentType: binaryInput[0].type,
        }
        createPatientForm({ variables: { input } })
      }}
    >
      <FormInputList
        items={[
          ...patientFormInputListItems(),
          {
            name: 'binary',
            label: 'Document upload',
            required: true,
            formInputComponent: FileInput,
          },
        ]}
      />
    </SidepanelForm>
  )
}

export default CreatePatientForm
