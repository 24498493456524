import { useParams } from 'react-router-dom'

import ExternalRequestEditCell from 'src/components/ExternalRequestEditCell'

const SidepanelExternalRequestEdit = () => {
  const { externalRequestId } = useParams()

  return <ExternalRequestEditCell id={externalRequestId} />
}

export default SidepanelExternalRequestEdit
