import { useContext, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { GetTenantForPortal, GetTenantForPortalVariables } from 'types/graphql'

import { useParams } from '@redwoodjs/router'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage'
import { createNamedContext } from 'src/utils'

export const GET_TENANT = gql`
  query GetTenantForPortal($key: String!) {
    publicTenant(id: $key) {
      id
      portalKey
      primaryPhoneNumber
    }
  }
`

type Tenant = GetTenantForPortal['publicTenant']

const PatientPortalContext = createNamedContext<{
  tenant: Tenant
}>('PatientPortalContext')

type TenantState =
  | {
      type: 'success'
      tenant: Tenant
    }
  | {
      type: 'notFound'
    }
  | {
      type: 'loading'
    }

export const PatientPortalProvider = ({ children }) => {
  const { tenantPortalKey } = useParams()
  const [state, setState] = useState<TenantState>({ type: 'loading' })

  if (state.type === 'notFound') {
    return <NotFoundPage />
  }

  if (state.type === 'loading') {
    return <SetTenant portalKey={tenantPortalKey} setState={setState} />
  }

  return (
    <PatientPortalContext.Provider value={{ tenant: state.tenant }}>
      {children}
    </PatientPortalContext.Provider>
  )
}

const SetTenant = ({
  portalKey,
  setState,
}: {
  portalKey: string
  setState: (state: TenantState) => void
}) => {
  const [getTenant] = useLazyQuery<
    GetTenantForPortal,
    GetTenantForPortalVariables
  >(GET_TENANT)

  useEffect(() => {
    void getTenant({ variables: { key: portalKey } }).then((result) => {
      setState(
        result.data?.publicTenant
          ? {
              type: 'success',
              tenant: result.data.publicTenant,
            }
          : {
              type: 'notFound',
            }
      )
    })
  }, [portalKey, setState, getTenant])

  return <LoadingSpinner />
}

export const usePatientPortalContext = () => useContext(PatientPortalContext)
