import { useEffect } from 'react'

import { compact } from 'lodash'
import { useParams } from 'react-router-dom'
import { CreateChargeItemForEncounter, DiagnosisCode } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import ChargeItemModifierMultiSelectField from 'src/components/atoms/ChargeItemModifierMultiSelectField/ChargeItemModifierMultiSelectField'
import InputField from 'src/components/atoms/InputField/InputField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import GenericFormFieldArray from 'src/components/molecules/GenericFormFieldArray/GenericFormFieldArray'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import BillingCodeSearchField from 'src/components/organisms/BillingCodeSearchField/BillingCodeSearchField'
import DiagnosisSearchField, {
  SEARCH_DIAGNOSIS_CODES_QUERY,
} from 'src/components/organisms/DiagnosisSearchField/DiagnosisSearchField'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import { ENCOUNTER_BILLING_FRAGMENT } from 'src/pages/PatientChartsPage/PatientVisits/fragments'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const CREATE_CHARGE_ITEM_MUTATION = gql`
  mutation CreateChargeItemForEncounter(
    $encounterId: String!
    $input: CreateChargeItemInput!
  ) {
    createChargeItemForEncounter(encounterId: $encounterId, input: $input) {
      id
      ...EncounterBillingFragment
    }
  }
  ${ENCOUNTER_BILLING_FRAGMENT}
`

export const useCreateChargeItem = () => {
  return useMutation<CreateChargeItemForEncounter>(
    CREATE_CHARGE_ITEM_MUTATION,
    {
      refetchQueries: [
        'PatientBillingEncounters',
        'PatientCharges',
        'FindPatientVisit',
      ],
      onCompleted: () => {
        toast.success('Patient charge created')
      },
    }
  )
}

export const useEncounterDiagnosesQuery = (
  encounterId: string,
  dontSkip: boolean
) => {
  const runQuery = dontSkip && !!encounterId

  return useQuery(SEARCH_DIAGNOSIS_CODES_QUERY, {
    variables: {
      input: {
        searchSet: 'ALL',
        encounterId: encounterId,
      },
      filters: ['ENCOUNTER_ACTIVE'],
    },
    skip: !runQuery,
  })
}

const SidepanelChargeItemCreate = () => {
  const { encounterId } = useParams()
  const { patientId } = useIsPatientChart()
  const {
    sidepanelContext: { isVisitBillingCode },
  } = useSidepanel()
  const { data } = useEncounterDiagnosesQuery(
    encounterId,
    isVisitBillingCode as boolean
  )

  const { closeSidePanel } = useSidepanel()
  const formMethods = useForm()
  useEffect(() => {
    if (isVisitBillingCode) {
      formMethods.setValue(
        'diagnosisCodes',
        data?.searchDiagnosisCodes.map((diagnosisCode) => ({
          code: diagnosisCode,
        }))
      )
    } else {
      formMethods.setValue('diagnosisCodes', [])
    }
  }, [data, isVisitBillingCode, formMethods])
  if (!encounterId) {
    closeSidePanel()
  }
  if (!patientId) {
    closeSidePanel()
  }

  const [createChargeItem, { loading: creatingChargeItem }] =
    useCreateChargeItem()

  const onSubmit = async (data) => {
    const { billingCode, diagnosisCodes, modifiers, quantity } = data

    const diagnosisCodeIds = compact(
      diagnosisCodes.map((diagnosisCode) => diagnosisCode.code.id)
    )

    await createChargeItem({
      variables: {
        input: {
          diagnosisCodeIds,
          billingCodeId: billingCode.id,
          modifiers: compact(modifiers),
          quantity,
        },
        encounterId,
      },
    })
    closeSidePanel()
  }

  return (
    <SidepanelPage
      testId="sidepanel-charge-item-create"
      header={
        isVisitBillingCode
          ? 'Add visit type billing code'
          : 'Add additional billing code'
      }
      description={
        isVisitBillingCode
          ? 'This billing code is a primary billing code for the visit.'
          : 'These billing codes would be in addition to primary billing code(s) and order-linked billing code(s) already included for the visit.'
      }
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Add billing code',
          submitting: creatingChargeItem,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <FormInputList
          items={[
            {
              name: 'billingCode',
              label: 'Billing code',
              subtitle: isVisitBillingCode
                ? 'Only Well Child and E&M primary billing codes are available.'
                : undefined,
              required: true,
              direction: 'col',
              formInputComponent: BillingCodeSearchField,
              inputProps: {
                searchSet:
                  isVisitBillingCode === undefined
                    ? 'ALL'
                    : isVisitBillingCode
                    ? 'VISIT'
                    : 'NON_VISIT',
                encounterId,
                defaultSearching: true,
              },
            },
            {
              name: 'quantity',
              label: 'Billing code units',
              required: true,
              direction: 'col',
              formInputComponent: InputField,
              inputProps: {
                type: 'number',
                defaultValue: 1,
              },
            },
            {
              name: 'modifiers',
              label: 'Billing code modifier(s)',
              direction: 'col',
              formInputComponent: ChargeItemModifierMultiSelectField,
            },
            {
              name: 'diagnosisCodes',
              label: 'Diagnosis code(s)',
              required: true,
              formInputComponent: GenericFormFieldArray,
              direction: 'col',
              inputProps: {
                formInputComponent: DiagnosisSearchField,
                nestedName: 'code',
                addButtonLabel: 'Add additional diagnosis',
                inputProps: {
                  disableUserFilterSelection: true,
                  defaultFilters: ['ENCOUNTER_ACTIVE'],
                  patientId,
                  encounterId,
                  searchSet: 'ALL',
                  nestedValidation: {
                    validate: (value: DiagnosisCode) => {
                      return !value ? 'Diagnosis code is required' : true
                    },
                  },
                },
              },
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelChargeItemCreate
