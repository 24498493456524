import { SelectFieldProps } from '@redwoodjs/forms/dist/SelectField'

import SelectField from 'src/components/atoms/SelectField/SelectField'
import { patientOrderDocumentTypes } from 'src/data/patientOrderDocumentTypes'

export const PatientOrderDocumentTypeSelectField = (
  props: SelectFieldProps
) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={patientOrderDocumentTypes.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default PatientOrderDocumentTypeSelectField
