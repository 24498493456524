const RedoIcon = ({ className }) => {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.49985 5H12.2499V1.25M12.0318 11.2677C11.3329 12.3439 10.3075 13.1676 9.10593 13.6181C7.9044 14.0685 6.59002 14.1218 5.35593 13.7703C4.12184 13.4187 3.03318 12.681 2.24936 11.665C1.46555 10.6491 1.02819 9.40851 1.00132 8.12561C0.974445 6.84271 1.35927 5.58501 2.09986 4.53711C2.84044 3.48921 3.8975 2.7065 5.11579 2.30359C6.33408 1.90068 7.64957 1.89875 8.86891 2.29846C10.0882 2.69818 11.1473 3.47821 11.8907 4.52417"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RedoIcon
