import React, { useMemo } from 'react'

import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { LocalDate, ZoneOffset, ZoneId, convert } from '@js-joda/core'
import {
  EMPTY_VITAL_VALUE_DISPLAY,
  HEAD_CIRCUMFERENCE_REQUIRED_AGE_LIMIT,
} from 'common/cdr/concepts/observations/vitals/index'
import { isSplitUnit } from 'common/unitConverter/unitConverter'
import { parseISO } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import { useParams } from 'react-router-dom'
import {
  UpsertPatientVitalsInput,
  CreatePatientVitals,
  TemperatureSourceCodingKey,
  Unit,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useParams as useParamsRW } from '@redwoodjs/router'
import { navigate, useLocation } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import ButtonFooter from 'src/components/molecules/ButtonFooter/ButtonFooter'
import { DatePickerFieldThatWorks } from 'src/components/molecules/DatePicker'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { vitalLabels } from 'src/components/VisitIntakeVital'
import { BloodPressureInput } from 'src/components/VisitIntakeVital/BloodPressureInput'
import { BodyHeightInput } from 'src/components/VisitIntakeVital/BodyHeightInput'
import { BodyTemperatureInput } from 'src/components/VisitIntakeVital/BodyTemperatureInput'
import { BodyWeightInput } from 'src/components/VisitIntakeVital/BodyWeightInput'
import { FractionOfInspiredOxygenInput } from 'src/components/VisitIntakeVital/FractionOfInspiredOxygenInput'
import { HeadCircumferenceInput } from 'src/components/VisitIntakeVital/HeadCircumferenceInput'
import { HeartRateInput } from 'src/components/VisitIntakeVital/HeartRateInput'
import { PulseOximetryInput } from 'src/components/VisitIntakeVital/PulseOximetryInput'
import { RespiratoryRateInput } from 'src/components/VisitIntakeVital/RespiratoryRateInput'
import { calculateAge } from 'src/lib/formatters'
import { formatDateFieldValue } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'
import {
  OBSERVATION_FOR_VITAL_FRAGMENT,
  useVitalsQuery,
} from 'src/pages/PatientChartsPage/PatientResults/Vitals/useVitals'
import { useVisitQuery } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import {
  getVitalsForDate,
  accumulateUpsertVitalsInput,
} from './SidepanelPatientVitalsEdit'

export type VitalKey =
  | 'bodyHeight'
  | 'bodyWeight'
  | 'headCircumference'
  | 'bodyTemperature'
  | 'bloodPressure'
  | 'heartRate'
  | 'respiratoryRate'
  | 'pulseOximetry'
  | 'fractionOfInspiredOxygen'

export const buildEmptyVital = (
  unit: Unit
): {
  value: string
  unit: Unit
} => {
  return {
    value: EMPTY_VITAL_VALUE_DISPLAY,
    unit,
  }
}

export const buildEmptyBodyWeightVital = (): {
  wholeUnit: {
    value: string
    unit: Unit
  }
  partialUnit: {
    value: string
    unit: Unit
  }
} => {
  return {
    wholeUnit: buildEmptyVital('lbs'),
    partialUnit: buildEmptyVital('oz'),
  }
}

const buildEmptyBloodPressureVital = (): {
  systolic: {
    value: string
    unit: Unit
  }
  diastolic: {
    value: string
    unit: Unit
  }
} => {
  return {
    systolic: buildEmptyVital('mmHg'),
    diastolic: buildEmptyVital('mmHg'),
  }
}

export const buildEmptyVitals = (): {
  [key in VitalKey & { effectiveDateTime: Date }]?: {
    value: string
    unit: Unit
  }
} => {
  return {
    bodyHeight: buildEmptyVital('in'),
    bodyWeight: buildEmptyBodyWeightVital(),
    headCircumference: buildEmptyVital('in'),
    bodyTemperature: {
      ...buildEmptyVital('degF'),
      type: 'ORAL_TEMPERATURE' as TemperatureSourceCodingKey,
    },
    bloodPressure: buildEmptyBloodPressureVital(),
    heartRate: buildEmptyVital('BEATS_PER_MINUTE'),
    respiratoryRate: buildEmptyVital('BREATHS_PER_MINUTE'),
    pulseOximetry: buildEmptyVital('PERCENT'),
    fractionOfInspiredOxygen: buildEmptyVital('PERCENT'),
  }
}

const CREATE_PATIENT_VITALS = gql`
  mutation CreatePatientVitals($input: UpsertPatientVitalsInput!) {
    createPatientVitals(input: $input) {
      id
      birthDate
      vitals {
        ...ObservationForVitalFragment
      }
    }
  }
  ${OBSERVATION_FOR_VITAL_FRAGMENT}
`

export const useAddPatientVitalsMutation = () => {
  return useMutation<CreatePatientVitals>(CREATE_PATIENT_VITALS, {
    refetchQueries: ['FindPatientVitals', 'GetPatient'],
  })
}

export const SidepanelPatientVitalsAdd: React.FC = () => {
  const [createPatientVital, { loading: addingVital }] =
    useAddPatientVitalsMutation()

  const { appointmentId } = useParamsRW()
  const { visit } = useVisitQuery(appointmentId)

  const params = useParams()
  const location = useLocation()

  const { patientId } = params
  const { vitalsByDateAndCode } = useVitalsQuery(patientId)
  const { closeSidePanel } = useSidepanel()

  const patientAge = calculateAge(visit?.patient?.birthDate)

  const formMethods = useForm<Omit<UpsertPatientVitalsInput, 'patientId'>>({
    defaultValues: {
      ...buildEmptyVitals(),
      effectiveDateTime: formatDateFieldValue(new Date()),
    },
  })

  const selectedDate = formMethods.watch('effectiveDateTime')
  const vitalsForDate = getVitalsForDate(selectedDate, vitalsByDateAndCode)

  const persistedPatientVitals = useMemo(() => {
    if (vitalsForDate.length) {
      return getVitalsForDate(selectedDate, vitalsByDateAndCode).reduce(
        (vitals, vital) => accumulateUpsertVitalsInput(vitals, vital),
        {} as UpsertPatientVitalsInput
      )
    }
  }, [vitalsByDateAndCode, selectedDate, vitalsForDate.length])

  const vitalsForSelectedDate = vitalsByDateAndCode?.filter((vital) => {
    if (!selectedDate) return false
    return formatDateFieldValue(selectedDate) in vital.dataByDate
  })

  const isHeadCircumferenceRequired =
    patientAge && patientAge.years <= HEAD_CIRCUMFERENCE_REQUIRED_AGE_LIMIT

  const persistedBodyWeightPartialValue =
    persistedPatientVitals?.['bodyWeight']?.partialUnit?.value

  const isSplitBodyWeight =
    (!isEmpty(persistedBodyWeightPartialValue) &&
      persistedBodyWeightPartialValue !== '0') ||
    (visit?.encounter?.bodyWeight &&
      isSplitUnit(visit?.encounter?.bodyWeight?.value?.display)) ||
    isHeadCircumferenceRequired

  const onSubmit = async (
    input: Omit<UpsertPatientVitalsInput, 'patientId'>
  ) => {
    await createPatientVital({
      variables: {
        encounterId: visit?.encounter?.id,
        input: {
          ...input,
          patientId,
          effectiveDateTime: convert(
            LocalDate.parse(input.effectiveDateTime)
              .atStartOfDay()
              .atZone(ZoneId.SYSTEM)
              .withZoneSameInstant(ZoneOffset.UTC)
          ).toDate(),
        },
      },
      onCompleted: () => {
        toast.success('Vital added')
        closeSidePanel()
      },
    })
  }

  const previouslyEnteredVitalsExist = vitalsForSelectedDate?.length > 0
  return (
    <SidepanelForm
      footerElement={
        <ButtonFooter
          submitText={'Save'}
          submitButtonStyle={'primary'}
          disabled={addingVital || previouslyEnteredVitalsExist}
          submitting={addingVital}
          secondaryButton={{
            text: 'Discard Changes',
            onClick: closeSidePanel,
            position: 'right',
            buttonStyle: 'ghost',
          }}
          submitButtonTestId="sidepanel-confirm-btn"
        />
      }
      formMethods={formMethods}
      onSubmit={onSubmit}
    >
      <StackView
        data-testid="patient-vitals"
        className="base-space-container-inset-m h-full"
        space={50}
      >
        {selectedDate && previouslyEnteredVitalsExist ? (
          <StackView
            direction="row"
            className="base-space-container-inset-s base-color-border-danger-emphasis base-space-container-inside-s mt-core-space-150 rounded-base-border-radius-container-m border border-base-color-fg-danger p-core-space-100"
            space={50}
          >
            <ExclamationCircleIcon
              className="h-base-size-icon-s w-base-size-icon-s"
              color="red"
            />
            <StackView>
              <Typography
                textStyle="title-xs"
                color="text-base-color-fg-danger"
              >
                {`Vitals have been previously entered for ${
                  selectedDate.split('T')[0]
                }`}
              </Typography>
              <Typography textStyle="body-s" color="text-base-color-fg-default">
                {
                  'Please edit those vitals or change the date to resume. You will not be able to add additional vitals for this date.'
                }
              </Typography>
              <Button
                buttonStyle="secondary"
                text="Edit vitals"
                onClick={() => {
                  navigate(
                    sidepanelRoute(
                      {
                        route: `/results/${patientId}/vitals/${selectedDate}/edit`,
                      },
                      location,
                      params
                    )
                  )
                }}
              />
            </StackView>
          </StackView>
        ) : null}
        <Box className="h-full w-full">
          <FormInputList
            items={[
              {
                name: 'effectiveDateTime',
                label: 'Date',
                required: true,
                direction: 'col',
                inputProps: {
                  emptyAs: null,
                  validation: {
                    validate: (value) => {
                      if (parseISO(value) > new Date())
                        return 'Cannot add vitals for future dates.'
                      if (previouslyEnteredVitalsExist) {
                        return 'Vitals have been previously entered for this date.'
                      }
                    },
                  },
                },
                formInputComponent: DatePickerFieldThatWorks,
              },
              {
                hide: previouslyEnteredVitalsExist,
                name: 'bodyHeight',
                showFieldError: false,
                label: vitalLabels['bodyHeight'],
                direction: 'col',
                formInputComponent: BodyHeightInput,
                subtitleProps: {
                  className: 'text-sm',
                },
                inputProps: {
                  setValueAsString: true,
                },
              },
              {
                hide: previouslyEnteredVitalsExist,
                name: 'bodyWeight',
                showFieldError: false,
                label: vitalLabels['bodyWeight'],
                direction: 'col',
                formInputComponent: BodyWeightInput,
                inputProps: {
                  allowPartialInput: isSplitBodyWeight ?? false,
                },
              },
              {
                hide: previouslyEnteredVitalsExist,
                name: 'headCircumference',
                showFieldError: false,
                label: vitalLabels['headCircumference'],
                direction: 'col',
                formInputComponent: HeadCircumferenceInput,
              },
              {
                hide: previouslyEnteredVitalsExist,
                name: 'bodyTemperature',
                showFieldError: false,
                label: vitalLabels['bodyTemperature'],
                direction: 'col',
                formInputComponent: BodyTemperatureInput,
              },
              {
                hide: previouslyEnteredVitalsExist,
                name: 'bloodPressure',
                label: vitalLabels['bloodPressure'],
                direction: 'col',
                formInputComponent: BloodPressureInput,
              },
              {
                hide: previouslyEnteredVitalsExist,
                name: 'heartRate',
                showFieldError: false,
                label: vitalLabels['heartRate'],
                direction: 'col',
                formInputComponent: HeartRateInput,
              },
              {
                hide: previouslyEnteredVitalsExist,
                name: 'respiratoryRate',
                showFieldError: false,
                label: vitalLabels['respiratoryRate'],
                direction: 'col',
                formInputComponent: RespiratoryRateInput,
              },
              {
                hide: previouslyEnteredVitalsExist,
                name: 'pulseOximetry',
                showFieldError: false,
                label: vitalLabels['pulseOximetry'],
                direction: 'col',
                formInputComponent: PulseOximetryInput,
              },
              {
                hide: previouslyEnteredVitalsExist,
                name: 'fractionOfInspiredOxygen',
                showFieldError: false,
                label: vitalLabels['fractionOfInspiredOxygen'],
                direction: 'col',
                formInputComponent: FractionOfInspiredOxygenInput,
              },
            ]}
          />
        </Box>
      </StackView>
    </SidepanelForm>
  )
}
