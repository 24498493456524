import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import { SigningPractitionerFragment } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

import { LabeledValue } from './LabeledValue'
import { SubHeading } from './SubHeading'

export const Signature = ({
  signedAt,
  practitioner,
  attestationHTML,
  hideHeading = false,
  additionalHeadingText,
  comment,
  addSignature = true,
}: {
  signedAt: string
  practitioner: SigningPractitionerFragment
  attestationHTML?: string
  hideHeading?: boolean
  additionalHeadingText?: string
  comment?: string
  addSignature?: boolean
}) => {
  return (
    <StackView
      gap={50}
      className="grow border-t-core-border-width-20 border-base-color-border-subtle pt-core-space-100"
    >
      {!hideHeading && <SubHeading>Signature</SubHeading>}
      {additionalHeadingText && (
        <Typography textStyle="interface-strong-m">
          {additionalHeadingText}
        </Typography>
      )}
      <LabeledValue label="Name" value={formatDisplayName(practitioner)} />
      {practitioner.user.credentialsLabel && (
        <LabeledValue
          label="Credentials / title"
          value={practitioner.user.credentialsLabel}
        />
      )}
      {practitioner.practitionerClinicalDetail?.npi && (
        <LabeledValue
          label="NPI"
          value={practitioner.practitionerClinicalDetail.npi}
        />
      )}

      <LabeledValue
        label="Date"
        value={format(parseISO(signedAt), "MMMM d, yyyy 'at' h:mma")}
      />
      {attestationHTML && (
        <StackView direction="row" space={50}>
          <Typography
            color="text-base-color-fg-muted"
            className="w-20 shrink-0"
          >
            Attestation:
          </Typography>
          <div
            dangerouslySetInnerHTML={{
              __html: attestationHTML,
            }}
          ></div>
        </StackView>
      )}

      {addSignature && (
        <StackView direction="row" space={50} alignItems="start">
          <Typography
            color="text-base-color-fg-muted"
            className="w-32 shrink-0"
          >
            Signature:
          </Typography>
          <Typography
            className="!font-['Brush_Script_MT']"
            size="xxl"
            tracking="tracking-normal"
          >
            {[practitioner.givenName, practitioner.familyName].join(' ')}
          </Typography>
        </StackView>
      )}

      {comment && <LabeledValue label="Comment" value={comment} />}
    </StackView>
  )
}
