import {
  ResolveDocumentCaseMutation,
  ResolveDocumentCaseMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { RESOLVE_DOCUMENT_CASE_MUTATION } from 'src/pages/Sidepanel/SidepanelDocumentCaseResolution'

export const useCancelDocumentCase = ({
  documentCaseId,
  onCompleted,
}: {
  documentCaseId: string
  onCompleted: () => void
}) => {
  const [resolveDocumentCase, mutationResult] = useMutation<
    ResolveDocumentCaseMutation,
    ResolveDocumentCaseMutationVariables
  >(RESOLVE_DOCUMENT_CASE_MUTATION, {
    refetchQueries: ['FindDocumentCases', 'GetNotResolvedCasesCountByType'],
    onCompleted,
  })
  const resolveCase = () => {
    return resolveDocumentCase({
      variables: {
        documentCaseId,
        input: {
          cancelCase: true,
        },
      },
    })
  }

  return [resolveCase, mutationResult] as const
}
