import { formatDisplayName } from 'common/utils'

import { Avatar } from 'src/components/atoms/Avatar/Avatar'
import Card from 'src/components/atoms/Card'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { formatInitials } from 'src/lib/formatters'

export interface UserListItemProps {
  user: {
    givenName?: string
    familyName?: string
    userType?: {
      id: string
      name: string
    }
  }
}

export const UserListItem = ({ user }: UserListItemProps) => {
  return (
    <Card
      testId="task-user-pool-member-item"
      verticalPadding={100}
      horizontalPadding={75}
    >
      <StackView direction="row" alignItems="center">
        <StackView
          direction="row"
          space={150}
          fullWidth={false}
          className="flex-1"
          alignItems="center"
        >
          <Avatar text={formatInitials(user)} />

          <Typography>{formatDisplayName(user)}</Typography>
        </StackView>
        <StackView direction="row" fullWidth={false} className="flex-1">
          <Typography>{user.userType.name}</Typography>
        </StackView>
      </StackView>
    </Card>
  )
}
