import { vapingStatusConceptCodings } from 'common/cdr/concepts/observations/socialHistories/index'
import type { VapingStatus } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const vapingStatusDisplay: {
  [key in VapingStatus]: string
} = {
  NEVER: 'Never vaper',
  UNKNOWN_IF_EVER: 'Unknown if ever vaped',
  FORMER: 'Former vaper',
  CURRENT_EVERYDAY: 'Current every day vaper',
  CURRENT_SOMEDAY: 'Current some day vaper',
  UNKNOWN: 'Vaper, current status unknown',
  LIGHT: 'Light vaper',
  HEAVY: 'Heavy vaper',
}

export const vapingStatusDisplayToEnum = Object.keys(
  vapingStatusDisplay
).reduce((acc, key) => {
  acc[vapingStatusDisplay[key]] = key
  return acc
}, {})

export const vapingStatusCodeToEnum = vapingStatusConceptCodings.reduce(
  (acc, coding) => {
    if (vapingStatusDisplayToEnum[coding?.display]) {
      acc[coding?.code] = vapingStatusDisplayToEnum[coding?.display]
    }
    return acc
  },
  {}
)

export const vapingStatuses =
  nameValueArrayFromObject<VapingStatus>(vapingStatusDisplay)
