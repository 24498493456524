import { useEffect } from 'react'

import {
  patientScoreTypeToScoringSystem,
  patientScoringSystemsDisplay,
} from 'common/data/patientScoringSystems'
import { omit, compact, isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { ScoringSystem, UpsertPatientScoreInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'

import AcoScoreSelectField from 'src/components/atoms/AcoScoreSelectField/AcoScoreSelectField'
import PatientScoringSystemMultiRadioButtonField from 'src/components/atoms/PatientScoringSystemMultiRadioButtonField/PatientScoringSystemMultiRadioButtonField'
import StackView from 'src/components/atoms/StackView/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField'
import Typography from 'src/components/atoms/Typography/Typography'
import FormInputList, {
  InputProps as FormInputListProps,
} from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { usePatientScoresQuery } from 'src/pages/PatientChartsPage/PatientHistory/PatientScores/usePatientScores'
import { useUpsertPatientScore } from 'src/pages/PatientChartsPage/PatientHistory/PatientScores/useUpsertPatientScore'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const dropdownFields: {
  [key in ScoringSystem]: React.FC<FormInputListProps>
} = {
  ACO_1_TO_6: AcoScoreSelectField,
}

type PatientScoreFormProps = UpsertPatientScoreInput & {
  scoringSystem: ScoringSystem
}

const SidepanelPatientScoreUpsert = () => {
  const { closeSidePanel } = useSidepanel()

  const params = useParams()

  const { patientId, patientScoringSystemCode: scoringSystemURLParam } = params

  const { patientScores } = usePatientScoresQuery(patientId)

  const patientScore = omit(
    patientScores?.find(
      (score) =>
        patientScoreTypeToScoringSystem[score.__typename] ===
        scoringSystemURLParam
    ),
    'updatedAt',
    'history'
  )

  const [upsertPatientScore, { loading: upsertingPatientScore }] =
    useUpsertPatientScore()

  const onSubmit = async (data: PatientScoreFormProps) => {
    void upsertPatientScore({
      variables: { input: omit(data, 'scoringSystem') },
      onCompleted: closeSidePanel,
      refetchQueries: ['PatientScores'],
    })
  }

  const formMethods = useForm<PatientScoreFormProps>({
    defaultValues: {
      patientId,
    },
  })

  const selectedScoringSystem =
    formMethods.watch('scoringSystem') ?? scoringSystemURLParam

  useEffect(() => {
    if (scoringSystemURLParam !== selectedScoringSystem) {
      formMethods.resetField('patientScore')
    }
  }, [selectedScoringSystem, formMethods, scoringSystemURLParam])

  const hasPatientScore = !isEmpty(patientScore)

  return (
    <SidepanelPage
      testId="sidepanel-patient-score-upsert"
      header={
        hasPatientScore
          ? `Edit ${patientScoringSystemsDisplay[scoringSystemURLParam]}`
          : 'Add patient score'
      }
      description={
        hasPatientScore
          ? 'Please fill out all mandatory fields before saving.'
          : 'Fill out all required fields.'
      }
    >
      <StackView space={25} className="px-core-space-150 py-core-space-100">
        <Typography textStyle="title-xs">Details</Typography>
        <Typography color="text-base-color-fg-muted" textStyle="body-xs">
          Select the applicable scoring system and choosing a score. While only
          the latest score is shown, a history of score changes is available.
        </Typography>
      </StackView>
      <SidepanelForm
        footerProps={{
          submitText: 'Save and close',
          submitting: upsertingPatientScore,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <FormInputList
          items={compact([
            {
              name: 'scoringSystem',
              label: 'Scoring system',
              required: true,
              formInputComponent: PatientScoringSystemMultiRadioButtonField,
              direction: 'col',
              inputProps: {
                defaultValue: scoringSystemURLParam,
                disabled: !!scoringSystemURLParam,
              },
            },
            selectedScoringSystem
              ? {
                  name: `patientScore.${selectedScoringSystem}.score`,
                  label: 'Score',
                  required: true,
                  formInputComponent: dropdownFields[selectedScoringSystem],
                  direction: 'col',
                  inputProps: {
                    defaultValue: patientScore?.score,
                  },
                }
              : undefined,
            {
              label: 'Comments',
              name: `patientScore.${selectedScoringSystem}.comments`,
              direction: 'col',
              formInputComponent: TextAreaField,
              inputProps: {
                defaultValue: patientScore?.comments,
                emptyAs: '',
                rows: 5,
              },
            },
          ])}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelPatientScoreUpsert
