import { UserStatus } from 'types/graphql'

import {
  Private,
  Redirect,
  routes,
  useMatch,
  useLocation,
} from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'

type PrivateProps = React.ComponentProps<typeof Private>

type PrivateStatusProps = PrivateProps & {
  status: UserStatus
}

/**
 * An extension of the Private route that also checks the user's status
 */
const PrivateStatus = ({
  status,
  unauthenticated,
  children,
}: React.PropsWithChildren<PrivateStatusProps>) => {
  const { currentUser, loading } = useEmrAuth()
  const location = useLocation()
  const isOnboarding = useMatch(routes['onboarding']())
  const isResetPassword = useMatch(routes['resetPassword']())

  if (loading) return null

  if (currentUser?.status !== status) {
    if (isOnboarding.match || isResetPassword.match) {
      return <Redirect to={routes[unauthenticated]()} />
    }

    const currentLocation =
      location.pathname + encodeURIComponent(location.search)

    return (
      <Redirect
        to={`${routes[unauthenticated]()}?redirectTo=${currentLocation}`}
      />
    )
  }

  return <>{children}</>
}

export default PrivateStatus
