import Box from 'src/components/atoms/Box/Box'
import { TIFFViewer } from 'src/components/atoms/TiffViewer/TiffViewer'

interface Props {
  url: string | URL
  name?: string
  type: string
  showDownloadLink?: boolean
}
interface AlternateProps {
  /** base64 encoded data */
  data: string
  name?: string
  type: string
}

const FileDisplay = (props: Props | AlternateProps) => {
  const { name, type } = props
  let showDownloadLink, src, pdfSrc
  if ((props as AlternateProps).data) {
    src = `data:${type};base64,${(props as AlternateProps).data}`
    pdfSrc = src
    showDownloadLink = false
  } else {
    src = (props as Props).url
    pdfSrc = src + '#view=FitH'
    showDownloadLink = (props as Props).showDownloadLink ?? true
  }

  return (
    <>
      <Box
        border={type === 'image/tiff'}
        rounded={type === 'image/tiff'}
        padding={100}
        color="bg-base-color-bg-default"
        className="flex flex-1 flex-col overflow-y-auto"
      >
        {type.startsWith('image/') && (
          <>
            {type === 'image/tiff' ? (
              <TIFFViewer src={src} />
            ) : (
              <img
                data-testid="img-display"
                className="h-full object-contain"
                src={src}
                alt={name}
              />
            )}
          </>
        )}
        {type === 'application/pdf' && (
          <iframe
            title={name}
            data-testid="pdf-display"
            src={pdfSrc}
            allowFullScreen={true}
            frameBorder={0}
            seamless={true}
            className="h-full w-full"
          />
        )}
        {type === 'text/html' && (
          <iframe
            title={src}
            data-testid="html-display"
            src={src}
            seamless={true}
            className="h-full w-full"
          />
        )}
      </Box>
      {showDownloadLink && (
        <div>
          <a
            className="text-blue-500 underline"
            href={src}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name || 'Download'}
          </a>
        </div>
      )}
    </>
  )
}

export default FileDisplay
