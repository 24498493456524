import { ReactElement } from 'react'

const Divider = (): ReactElement => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-base-color-border-subtle" />
      </div>
    </div>
  )
}

export default Divider
