import type { CoverageLevel } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const coverageLevelDisplay: {
  [key in CoverageLevel]: string
} = {
  ECH: 'Employee Only',
  EMP: 'Employee and Children',
  ESP: 'Employee and Spouse',
  FAM: 'Family',
  IND: 'Individual',
  SPC: 'Spouse and Children',
}

export const coverageLevels =
  nameValueArrayFromObject<CoverageLevel>(coverageLevelDisplay)
