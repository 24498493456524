import {
  DiagnosisCodeFilter,
  DiagnosisCodeSearchSet,
  SearchDiagnosisCodes,
} from 'types/graphql'

import { RegisterOptions } from '@redwoodjs/forms'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { nameValueArrayFromObject } from 'src/data/utils'

import AdvancedSearchField from '../AdvancedSearchField/AdvancedSearchField'

export const SEARCH_DIAGNOSIS_CODES_QUERY = gql`
  query SearchDiagnosisCodes(
    $input: DiagnosisCodeSearchInput!
    $filters: [DiagnosisCodeFilter!]
  ) {
    searchDiagnosisCodes(input: $input, filters: $filters) {
      id
      code
      description
    }
  }
`

type SearchResult = SearchDiagnosisCodes['searchDiagnosisCodes'][0]

const filterOptionsDisplay: {
  [key in DiagnosisCodeFilter]: string
} = {
  USER_FAVORITE: 'Favorite diagnoses',
  PATIENT_ACTIVE: 'Patient active diagnoses',
  ENCOUNTER_ACTIVE: 'Active diagnoses for this visit',
  ENCOUNTER_COMMON: 'Common diagnoses for current visit type',
  FAMILY_HISTORY: 'Family history diagnoses',
}

const SelectedValue = (diagnosis: SearchResult) => (
  <StackView>
    <Typography textStyle="subtitle">{diagnosis.code}</Typography>
    <Typography size="s" color="text-base-color-fg-muted">
      {diagnosis.description}
    </Typography>
  </StackView>
)

const SearchResultItem = (diagnosis: SearchResult) => (
  <>
    <Typography className="shrink-0 basis-20" fontWeight="medium">
      {diagnosis.code}
    </Typography>
    <Typography textStyle="description">{diagnosis.description}</Typography>
  </>
)

export type DiagnosisSearchFieldProps = {
  name: string
  patientId: string
  searchSet: DiagnosisCodeSearchSet
  encounterId?: string
  validation?: RegisterOptions
  disableUserFilterSelection?: boolean
  disableFilters?: boolean
  defaultFilters?: DiagnosisCodeFilter[]
  defaultSearching?: boolean
}

const DiagnosisSearchField = ({
  name,
  patientId,
  searchSet,
  encounterId,
  validation,
  disableUserFilterSelection,
  disableFilters,
  defaultFilters,
  defaultSearching,
}: DiagnosisSearchFieldProps) => {
  const filterOptions = nameValueArrayFromObject(filterOptionsDisplay).filter(
    (option) => {
      if (option.value === 'FAMILY_HISTORY')
        return defaultFilters?.includes('FAMILY_HISTORY')

      if (!patientId) return ['USER_FAVORITE'].includes(option.value)

      if (encounterId) return true

      return !['ENCOUNTER_COMMON', 'ENCOUNTER_ACTIVE'].includes(option.value)
    }
  )
  const filtersAvailable = filterOptions.map((option) => option.value)

  const initialFilters = defaultFilters
    ? filtersAvailable.filter((v) => defaultFilters.includes(v))
    : filtersAvailable

  if (defaultFilters) {
    if (initialFilters.length !== defaultFilters.length) {
      // eslint-disable-next-line no-console
      console.warn(
        `Invalid defaultFilters provided to DiagnosisSearchField. Invalid values: ${defaultFilters.filter(
          (v) => !initialFilters.includes(v)
        )}`
      )
    }
  }

  return (
    <AdvancedSearchField
      name={name}
      placeholder={'Search and add a diagnosis code...'}
      query={SEARCH_DIAGNOSIS_CODES_QUERY}
      queryKey={'searchDiagnosisCodes'}
      queryInput={{
        patientId,
        encounterId,
        searchSet,
      }}
      searchPlaceholder={'Search by diagnosis code or keyword'}
      emptyResultMessage={'No diagnosis codes to display'}
      valueRenderer={SelectedValue}
      resultRenderer={SearchResultItem}
      //  This is a bit of a hack. Sending no filter options disables user-selection of the filter
      //  the defaultFilters is then used as the filter for the entire search experience
      filterOptions={
        disableUserFilterSelection || disableFilters ? [] : filterOptions
      }
      defaultFilters={disableFilters ? [] : initialFilters}
      validation={validation}
      defaultSearching={defaultSearching}
    />
  )
}

export default DiagnosisSearchField
