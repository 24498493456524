const ConstrainedPageLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <section
      aria-labelledby="primary-heading"
      className="flex min-w-0 flex-1 flex-col px-8 sm:px-16 md:px-24 md:pt-16 lg:order-last lg:max-w-5xl"
    >
      {children}
      <div className="pb-96" />
    </section>
  )
}

export default ConstrainedPageLayout
