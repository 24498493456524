import {
  FindPatientMenstrualHistory,
  UpsertMenstrualHistory,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import {
  FrontendConfiguration,
  getExistingValuesForFrontend,
  useGetFrontendMappings,
  deepOmit,
  getExistingFormValues,
} from 'src/data/utils'

import { MINIMAL_OBSERVATION_FRAGMENT } from '../../PatientVisits/fragments'

const MENSTRUAL_HISTORY_FRAGMENT = gql`
  fragment MenstrualHistoryFragment on MenstrualHistory {
    id
    ageAtMenarche {
      ...MinimalObservationFragment
    }
    lastMenstrualPeriodDate {
      ...MinimalObservationFragment
    }
    lastMenstrualPeriodDuration {
      ...MinimalObservationFragment
    }
    regularity {
      ...MinimalObservationFragment
    }
    clotsPresent {
      ...MinimalObservationFragment
    }
    menstrualFlow {
      ...MinimalObservationFragment
    }
    menstrualPain {
      ...MinimalObservationFragment
    }
    numberOfPadsUsed {
      ...MinimalObservationFragment
    }
    numberOfTamponsUsed {
      ...MinimalObservationFragment
    }
  }
  ${MINIMAL_OBSERVATION_FRAGMENT}
`

const MENSTRUAL_HISTORY_QUERY = gql`
  query FindPatientMenstrualHistory($id: String!) {
    patient(id: $id) {
      id
      sexAtBirth
      genderIdentity
      menstrualHistory {
        ...MenstrualHistoryFragment
      }
    }
  }
  ${MENSTRUAL_HISTORY_FRAGMENT}
`

const UPSERT_MENSTRUAL_HISTORY_MUTATION = gql`
  mutation UpsertMenstrualHistory($input: UpsertMenstrualHistoryInput!) {
    upsertMenstrualHistory(input: $input) {
      id
      menstrualHistory {
        ...MenstrualHistoryFragment
      }
    }
  }
  ${MENSTRUAL_HISTORY_FRAGMENT}
`

const getMenstrualHistoryForFrontend = (
  menstrualHistory,
  codeToEnumMap,
  enumToDisplayMap,
  config?: FrontendConfiguration
) => {
  return getExistingValuesForFrontend({
    obj: menstrualHistory,
    codeToEnumMap,
    enumToDisplayMap,
    config,
  })
}

export const useMenstrualHistoryQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<FindPatientMenstrualHistory>(
    MENSTRUAL_HISTORY_QUERY,
    {
      variables: { id: patientId },
    }
  )

  const menstrualHistory = data?.patient?.menstrualHistory

  const { codeToEnumMap, enumToDisplayMap } = useGetFrontendMappings(
    patientId,
    menstrualHistory?.__typename
  )

  if (!codeToEnumMap || !enumToDisplayMap) return {}

  const menstrualHistoryForFrontend = deepOmit(
    getMenstrualHistoryForFrontend(
      menstrualHistory,
      codeToEnumMap,
      enumToDisplayMap,
      {
        unitInputs: {
          ageAtMenarche: { unit: 'a', displayUnits: ['a'] },
          lastMenstrualPeriodDuration: {
            unit: 'd',
            displayUnits: ['d'],
          },
        },
      }
    ),
    ['id', '__typename']
    // TODO: type this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any

  const menstrualHistoryFormValues = getExistingFormValues(
    {},
    menstrualHistoryForFrontend
  )

  const hasMenstrualHistory =
    data?.patient?.sexAtBirth === 'FEMALE' ||
    data?.patient?.genderIdentity === 'FEMALE' ||
    data?.patient?.genderIdentity === 'MALE_TO_FEMALE'

  return {
    hasMenstrualHistory,
    menstrualHistory: menstrualHistoryForFrontend,
    menstrualHistoryFormValues,
    loading,
    error,
  }
}

export const useUpsertMenstrualHistory = () => {
  return useMutation<UpsertMenstrualHistory>(
    UPSERT_MENSTRUAL_HISTORY_MUTATION,
    {
      onCompleted: () => {
        toast.success('Menstrual history saved')
      },
    }
  )
}
