import { useParams } from 'react-router-dom'

import ScreeningToolEditCell from 'src/components/ScreeningToolEditCell'

const SidepanelScreeningToolEdit = () => {
  const { screeningToolId } = useParams()

  return <ScreeningToolEditCell id={screeningToolId} />
}

export default SidepanelScreeningToolEdit
