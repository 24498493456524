import InputField from 'src/components/atoms/InputField/InputField'
import LocationsSelectCell from 'src/components/Location/LocationsSelectCell'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import UserTypeSelectCell from 'src/components/UserType/UserTypeSelectCell'

const UserFormInputList = () => {
  return (
    <FormInputList
      items={[
        {
          name: 'givenName',
          label: 'First name',
          formInputComponent: InputField,
          required: true,
          inputProps: {
            testId: 'givenname-field',
          },
        },
        {
          name: 'familyName',
          label: 'Last name',
          formInputComponent: InputField,
          required: true,
          inputProps: {
            testId: 'familyname-field',
          },
        },
        {
          name: 'email',
          label: 'Email',
          formInputComponent: InputField,
          required: true,
          inputProps: {
            testId: 'email-field',
          },
        },
        {
          name: 'userTypeId',
          label: 'User type',
          formInputComponent: UserTypeSelectCell,
          required: true,
          inputProps: {
            testId: 'usertypeid-field',
          },
        },
        {
          name: 'credentialsLabel',
          label: 'Credentials / title',
          formInputComponent: InputField,
          inputProps: {
            testId: 'credentials-label-field',
          },
        },
        {
          name: 'primaryLocationId',
          inputProps: {
            additionalName: 'additionalLocationIds',
          },
          label: 'Locations',
          formInputComponent: LocationsSelectCell,
          required: true,
        },
      ]}
    />
  )
}

export default UserFormInputList
