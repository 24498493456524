import { useRef } from 'react'

import { DateTimeFormatter, LocalDate } from '@js-joda/core'
import { Locale } from '@js-joda/locale_en-us'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { formatMoneyInCents } from 'common/utils'
import { PatientCharges } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Badge, { BadgeColor } from 'src/components/atoms/Badge'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Table from 'src/components/molecules/Table/Table'
import Tabs from 'src/components/molecules/Tabs/Tabs'
import { usePatientChargesQuery } from 'src/hooks/usePatientCharges/usePatientCharges'
import { sidepanelRoute } from 'src/lib/routes'

export const CHARGE_TYPES: {
  [key in string]: { color: BadgeColor; label: string }
} = {
  OUTSTANDING: { color: 'red', label: 'Outstanding' },
  PAID: { color: 'green', label: 'Paid' },
}

const defaultColDef: ColDef = {
  cellStyle: { border: 'none', display: 'flex', alignItems: 'center' },
  cellClass: 'cursor-pointer',
  resizable: true,
}

const columnDefs: ColDef[] = [
  {
    colId: 'note',
    headerName: 'Payment note',
    cellRenderer: ({ data }) => {
      return (
        <StackView space={25}>
          <Typography
            textStyle="interface-strong-s"
            color="text-base-color-fg-muted"
          >
            {data.directPatientChargeBillingCode.description}
          </Typography>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {LocalDate.parse(data.dateOfService).format(
              DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(Locale.US)
            )}
          </Typography>
        </StackView>
      )
    },
    autoHeight: true,
  },
  {
    colId: 'amount',
    headerName: 'Amount',
    cellRenderer: ({ data }) => (
      <Typography
        textStyle="interface-default-s"
        color="text-base-color-fg-muted"
      >
        {formatMoneyInCents(data.chargeAmountCents)}
      </Typography>
    ),
    autoHeight: true,
    maxWidth: 100,
  },
  {
    colId: 'status',
    headerName: 'Status',
    cellRenderer: ({ data }) => {
      return (
        <Badge
          testId="charge-type-badge"
          color={CHARGE_TYPES[data.paymentStatus].color}
          text={CHARGE_TYPES[data.paymentStatus].label}
          showDot
        />
      )
    },
    autoHeight: true,
    maxWidth: 140,
  },
]

const ChargesTable = ({
  charges,
  patientId,
  paymentStatus,
}: {
  charges: PatientCharges['candidDirectPatientChargeEncountersByPatient'][0][]
  patientId: string
  paymentStatus: 'PAID' | 'OUTSTANDING'
}) => {
  const location = useLocation()
  const params = useParams()
  const gridRef = useRef<AgGridReact>()

  let filteredCharges: PatientCharges['candidDirectPatientChargeEncountersByPatient'][0][]
  if (paymentStatus === 'PAID') {
    filteredCharges =
      charges?.filter((charge) => charge.paymentStatus === 'PAID') ?? []
  } else {
    filteredCharges =
      charges?.filter((charge) => charge.paymentStatus !== 'PAID') ?? []
  }

  return (
    <Table
      testId="patient-charges-table"
      innerRef={gridRef}
      rowData={filteredCharges}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      domLayout="autoHeight"
      rowHeight={72}
      hideHeader
      context={{
        patientId,
      }}
      onRowClicked={({ data }) => {
        navigate(
          sidepanelRoute(
            {
              route: `/patients/${patientId}/charges/${data.id}`,
            },
            location,
            params
          )
        )
      }}
    />
  )
}

const HistoricalPatientChargesTable = ({
  patientId,
  appointmentId,
}: {
  patientId: string
  appointmentId?: string
}) => {
  const { charges, loading } = usePatientChargesQuery({
    patientId,
    appointmentId,
  })

  if (loading) return <LoadingSpinner />
  if (charges?.length === 0) return null

  if (charges?.some((charge) => charge.paymentStatus === 'PAID')) {
    return (
      <Tabs
        testId="patient-charges-tabs"
        tabs={[
          {
            name: 'Outstanding',
            content: (
              <ChargesTable
                charges={charges}
                patientId={patientId}
                paymentStatus="OUTSTANDING"
              />
            ),
          },
          {
            name: 'Paid',
            content: (
              <ChargesTable
                charges={charges}
                patientId={patientId}
                paymentStatus="PAID"
              />
            ),
          },
        ]}
      />
    )
  } else {
    return (
      <ChargesTable
        charges={charges}
        patientId={patientId}
        paymentStatus="OUTSTANDING"
      />
    )
  }
}

export default HistoricalPatientChargesTable
