const LineChartLegendIcon = ({ className }) => {
  return (
    <svg
      width="47"
      height="12"
      viewBox="0 0 47 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="6" cy="6" r="6" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.5L35 4.5L35 7.5L12 7.5L12 4.5Z"
        fill="currentColor"
      />
      <circle cx="41" cy="6" r="6" fill="currentColor" />
    </svg>
  )
}

export default LineChartLegendIcon
