import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { Duration } from '@js-joda/core'
import { GetTaskNotificationCount } from 'types/graphql'

const QUERY = gql`
  query GetTaskNotificationCount {
    profile {
      id
      openTaskCount
      pastDueTaskCount
    }
  }
`

export const usePollTaskNotificationCount = () => {
  const [count, setCount] = useState<number>(0)
  const { data, error, loading } = useQuery<GetTaskNotificationCount>(QUERY, {
    pollInterval: Duration.ofMinutes(10).toMillis(),
  })
  useEffect(() => {
    if (!error && !loading && data) {
      const openTaskCount = data.profile?.openTaskCount ?? 0
      const pastDueTaskCount = data.profile?.pastDueTaskCount ?? 0
      setCount(openTaskCount + pastDueTaskCount)
    }
  }, [data, error, loading])
  return count
}

export default usePollTaskNotificationCount
