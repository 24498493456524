import { useParams } from 'react-router-dom'

import PracticeAdminDocumentCell from 'src/components/PracticeAdminDocumentCell'

const SidepanelPracticeAdminDocument = () => {
  const { practiceAdminDocumentId } = useParams()

  return <PracticeAdminDocumentCell id={practiceAdminDocumentId} />
}

export default SidepanelPracticeAdminDocument
