import { ArrowPathIcon } from '@heroicons/react/20/solid'
import { format } from 'date-fns'
import { parsePhoneNumber } from 'libphonenumber-js'
import type {
  FindOutboundFaxQuery,
  FindOutboundFaxQueryVariables,
} from 'types/graphql'
import { outboundFaxErrorCodeDisplay } from 'web/src/data/outboundFaxErrorCodes'

import { navigate, useLocation, useParams } from '@redwoodjs/router'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import Button from 'src/components/atoms/Button/Button'
import OutboundFaxStatus from 'src/components/atoms/OutboundFaxStatus/OutboundFaxStatus'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import DataDisplayList from 'src/components/molecules/DataDisplayList/DataDisplayList'
import { useResendFax } from 'src/hooks/useResendFax/useResendFax'
import { sidepanelRoute } from 'src/lib/routes'

export const QUERY = gql`
  query FindOutboundFaxQuery($outboundFaxId: String!) {
    outboundFax(id: $outboundFaxId) {
      id
      createdAt
      archivedAt
      status
      pagesCount
      attemptsMade
      resultCode
      faxNumber
      recipient {
        id
        name
      }
      documentReferenceId
      outboundFaxRequest {
        id
        attentionTo
        user {
          id
          givenName
          familyName
        }
        documents {
          ... on Binary {
            id
          }
          ... on DiagnosticReport {
            id
          }
        }
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindOutboundFaxQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  outboundFax,
}: CellSuccessProps<FindOutboundFaxQuery, FindOutboundFaxQueryVariables>) =>
  outboundFax.status === 'FAILED' ? (
    <Failed outboundFax={outboundFax} />
  ) : (
    <Default outboundFax={outboundFax} />
  )

const Failed = ({
  outboundFax,
}: CellSuccessProps<FindOutboundFaxQuery, FindOutboundFaxQueryVariables>) => {
  const location = useLocation()
  const params = useParams()
  const [resendFax, { loading }] = useResendFax(outboundFax.id)

  const createdAtDate = new Date(outboundFax.createdAt)
  const createdAtString =
    format(createdAtDate, 'PP') + ' at ' + format(createdAtDate, 'p')
  return (
    <StackView space={100} className="py-3">
      <Typography textStyle="heading">Outbound fax details</Typography>
      <StackView
        space={75}
        direction="row"
        alignItems="center"
        justifyContent="between"
        fullWidth
      >
        <OutboundFaxStatus status={outboundFax.status} className="w-fit" />
        <Typography textStyle="description">{createdAtString}</Typography>
      </StackView>
      <StackView>
        <Typography>
          To:{' '}
          {`${outboundFax.recipient.name}: ${parsePhoneNumber(
            outboundFax.faxNumber
          ).formatNational()}`}
        </Typography>
        <Typography>
          From:{' '}
          {`${outboundFax.outboundFaxRequest.user.givenName} ${outboundFax.outboundFaxRequest.user.familyName}`}
        </Typography>
      </StackView>

      {!outboundFax.archivedAt ? (
        <Button
          buttonStyle="secondary"
          icon={ArrowPathIcon}
          text="Re-send"
          disabled={loading}
          className="w-fit"
          onClick={() => resendFax()}
        />
      ) : null}

      <DataDisplayList
        data={[
          outboundFax.status === 'FAILED'
            ? {
                label: 'Failure reason',
                value: outboundFaxErrorCodeDisplay[outboundFax.resultCode],
              }
            : null,
          {
            label: 'Attention to',
            value: outboundFax.outboundFaxRequest.attentionTo,
          },
          {
            label: 'Attempts made',
            value: outboundFax.attemptsMade,
          },
          {
            label: 'Attachments',
            value: (
              <StackView>
                {outboundFax.outboundFaxRequest.documents.map((b, idx) => (
                  <Button
                    key={b.id}
                    buttonStyle="ghost"
                    className="w-fit text-blue-600 underline"
                    onClick={() => {
                      navigate(
                        sidepanelRoute(
                          {
                            route:
                              b.__typename === 'Binary'
                                ? `/documents/binary/${b.id}`
                                : `/documents/diagnostic-report/${b.id}`,
                          },
                          location,
                          params
                        )
                      )
                    }}
                  >
                    Attachment {idx + 1}
                  </Button>
                ))}
              </StackView>
            ),
          },
        ].filter(Boolean)}
      />
    </StackView>
  )
}

const Default = ({
  outboundFax,
}: CellSuccessProps<FindOutboundFaxQuery, FindOutboundFaxQueryVariables>) => {
  const location = useLocation()
  const params = useParams()

  const createdAtDate = new Date(outboundFax.createdAt)
  const createdAtString =
    format(createdAtDate, 'PP') + ' at ' + format(createdAtDate, 'p')
  return (
    <StackView>
      <Typography textStyle="heading">Outbound fax details</Typography>
      <StackView space={75} className="py-6">
        <OutboundFaxStatus status={outboundFax.status} className="w-fit" />
        <Typography textStyle="description">{createdAtString}</Typography>
      </StackView>
      <DataDisplayList
        data={[
          {
            label: 'To',
            value: `${outboundFax.recipient.name}: ${parsePhoneNumber(
              outboundFax.faxNumber
            ).formatNational()}`,
          },
          {
            label: 'From',
            value: `${outboundFax.outboundFaxRequest.user.givenName} ${outboundFax.outboundFaxRequest.user.familyName}`,
          },
          {
            label: 'Attention to',
            value: outboundFax.outboundFaxRequest.attentionTo,
          },
          {
            label: 'Attachments',
            value: (
              <StackView>
                {outboundFax.outboundFaxRequest.documents.map(
                  (document, idx) => (
                    <Button
                      key={document.id}
                      buttonStyle="ghost"
                      className="w-fit text-blue-600 underline"
                      onClick={() => {
                        navigate(
                          sidepanelRoute(
                            {
                              route:
                                document.__typename === 'Binary'
                                  ? `/documents/binary/${document.id}`
                                  : `/documents/diagnostic-report/${document.id}`,
                            },
                            location,
                            params
                          )
                        )
                      }}
                    >
                      Attachment {idx + 1}
                    </Button>
                  )
                )}
              </StackView>
            ),
          },
          {
            label: 'Full Fax',
            value: (
              <StackView>
                {outboundFax.documentReferenceId ? (
                  <Button
                    key={outboundFax.documentReferenceId}
                    buttonStyle="ghost"
                    className="w-fit text-blue-600 underline"
                    onClick={() => {
                      navigate(
                        sidepanelRoute(
                          {
                            route: `/documents/binary/${outboundFax.documentReferenceId}`,
                          },
                          location,
                          params
                        )
                      )
                    }}
                  >
                    Full Fax
                  </Button>
                ) : null}
              </StackView>
            ),
          },
        ]}
      />
    </StackView>
  )
}
