import type { HealthPlanType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const healthPlanTypeDisplay: {
  [key in HealthPlanType]: string
} = {
  COMMERCIAL: 'Commercial',
  MEDICAID: 'Medicaid',
  MARKETPLACE: 'Marketplace qualified health plan',
  OTHER_MEDICAL: 'Other medical insurance',
  NON_MEDICAL: 'Non-medical insurance',
}

export const healthPlanTypes = nameValueArrayFromObject<HealthPlanType>(
  healthPlanTypeDisplay
)
