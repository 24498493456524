import { ReactElement, useCallback, useEffect, useState } from 'react'

import { LinkIcon } from '@heroicons/react/24/solid'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createQuoteNode } from '@lexical/rich-text'
import { $setBlocksType } from '@lexical/selection'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import clsx from 'clsx'
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical'

import { toast } from '@redwoodjs/web/dist/toast'

import BoldIcon from 'src/components/icons/BoldIcon/BoldIcon'
import BulletedListIcon from 'src/components/icons/BulletedListIcon/BulletedListIcon'
import ItalicIcon from 'src/components/icons/ItalicIcon/ItalicIcon'
import JustifyLeftIcon from 'src/components/icons/JustifyLeftIcon/JustifyLeftIcon'
import NumberedListIcon from 'src/components/icons/NumberedListIcon/NumberedListIcon'
import QuoteIcon from 'src/components/icons/QuoteIcon/QuoteIcon'
import RedoIcon from 'src/components/icons/RedoIcon/RedoIcon'
import UnderlineIcon from 'src/components/icons/UnderlineIcon/UnderlineIcon'
import UndoIcon from 'src/components/icons/UndoIcon/UndoIcon'
import PopoverButton from 'src/components/PopoverButton/PopoverButton'

import Box from '../../Box'
import Button from '../../Button'
import StackView from '../../StackView'
import Typography from '../../Typography/Typography'
import FloatingLinkEditor from '../ui/FloatingLinkEditor'
import { getSelectedNode } from '../utils/utils'

export type ToolbarButtonSize = 'xs' | 's'

const ToolbarButtonGroup = ({
  children,
  className,
  buttonSize,
}: {
  children: ReactElement[] | ReactElement
  className?: string
  buttonSize: ToolbarButtonSize
}) => {
  return (
    <StackView
      direction="row"
      space={25}
      fullWidth={false}
      className={clsx([
        'pl-core-space-50',
        buttonSize === 's' && 'h-core-size-250',
        className,
      ])}
      alignItems="center"
    >
      {children}
    </StackView>
  )
}

const ToolbarButton = ({
  children,
  className,
  disabled = false,
  onClick,
  active = false,
  icon: Icon,
  testId,
  buttonSize,
}: {
  children?: ReactElement
  className?: string
  disabled?: boolean
  onClick: () => void
  active?: boolean
  icon?: React.FunctionComponent<React.ComponentProps<'svg'>>
  testId: string
  buttonSize: ToolbarButtonSize
}) => {
  if (Icon) {
    return (
      <Button
        testId={testId}
        className={clsx(['!shadow-none', active && 'bg-gray-100', className])}
        disabled={disabled}
        buttonSize={buttonSize}
        buttonStyle="ghost"
        icon={Icon}
        tabIndex={-1}
        onClick={onClick}
      />
    )
  }

  return (
    <Button
      testId={testId}
      className={clsx(['!shadow-none', active && 'bg-gray-100', className])}
      disabled={disabled}
      buttonSize={buttonSize}
      buttonStyle="ghost"
      tabIndex={-1}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

const ToolbarDropdownItem = ({
  onClick,
  label,
  icon: Icon,
  active,
  testId,
}: {
  onClick: () => void
  label: string
  icon: React.FunctionComponent<React.ComponentProps<'svg'>>
  active: boolean
  testId: string
}) => {
  return (
    <Box
      className={clsx([active && 'bg-gray-100', 'hover:cursor-pointer'])}
      padding={50}
      onClick={onClick}
      data-testid={testId}
    >
      <StackView direction="row" space={75} alignItems="center">
        <Icon className="w-6" />
        <Typography className="whitespace-nowrap">{label}</Typography>
      </StackView>
    </Box>
  )
}

const ToolbarPlugin = ({
  disabled = false,
  buttonSize,
}: {
  disabled?: boolean
  buttonSize: ToolbarButtonSize
}) => {
  const [editor] = useLexicalComposerContext()
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isLink, setIsLink] = useState(false)
  const [blockType, setBlockType] = useState('paragraph')

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }

      // Set our blockType (i.e. 'paragraph', 'ol', 'ul', etc)
      const anchorNode = selection.anchor.getNode()
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow()
      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)
      if (elementDOM !== null) {
        let type
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          type = parentList ? parentList.getTag() : element.getTag()
        } else {
          type = element.getType()
        }
        setBlockType(type)
      }
    }
  }, [editor])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar()
          return false
        },
        1
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload)
          return false
        },
        1
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload)
          return false
        },
        1
      )
    )
  }, [editor, updateToolbar])

  return (
    <StackView
      direction="row"
      space={50}
      className="rounded-t-md border border-b-0 border-gray-300 px-2"
      divider
      fullWidth={false}
      alignItems="center"
      data-testid="toolbar"
    >
      <ToolbarButtonGroup buttonSize={buttonSize}>
        <ToolbarButton
          testId="undo-button"
          disabled={!canUndo || disabled}
          onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
          buttonSize={buttonSize}
        >
          <UndoIcon className="w-6" />
        </ToolbarButton>

        <ToolbarButton
          testId="redo-button"
          disabled={!canRedo || disabled}
          onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
          buttonSize={buttonSize}
        >
          <RedoIcon className="w-6" />
        </ToolbarButton>
      </ToolbarButtonGroup>

      <ToolbarButtonGroup buttonSize={buttonSize}>
        <ToolbarButton
          testId="bold-button"
          disabled={disabled}
          active={isBold}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
          buttonSize={buttonSize}
        >
          <BoldIcon className="w-6" />
        </ToolbarButton>

        <ToolbarButton
          testId="italic-button"
          disabled={disabled}
          active={isItalic}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
          buttonSize={buttonSize}
        >
          <ItalicIcon className="w-6" />
        </ToolbarButton>

        <ToolbarButton
          testId="underline-button"
          disabled={disabled}
          active={isUnderline}
          onClick={() =>
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
          }
          buttonSize={buttonSize}
        >
          <UnderlineIcon className="w-6" />
        </ToolbarButton>

        <ToolbarButton
          testId="link-button"
          disabled={disabled}
          icon={LinkIcon}
          className="w-8"
          active={isLink}
          onClick={() => {
            if (!isLink) {
              editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://')
            } else {
              editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
            }
          }}
          buttonSize={buttonSize}
        />
      </ToolbarButtonGroup>

      <ToolbarButtonGroup className="relative" buttonSize={buttonSize}>
        <PopoverButton
          panelXPosition="left"
          panelWidth="w-fit"
          buttonProps={{
            testId: 'block-type-dropdown',
            disabled: disabled,
            className: '!shadow-none',
            buttonStyle: 'ghost',
            icon:
              blockType === 'ul'
                ? BulletedListIcon
                : blockType === 'ol'
                ? NumberedListIcon
                : blockType === 'quote'
                ? QuoteIcon
                : JustifyLeftIcon,
            text: '\u2304',
          }}
          renderPanel={({ close }) => (
            <StackView divider fullWidth={false}>
              <ToolbarDropdownItem
                testId="normal-block-button"
                label="Normal"
                icon={JustifyLeftIcon}
                active={false}
                onClick={() => {
                  editor.update(() => {
                    const selection = $getSelection()
                    if ($isRangeSelection(selection)) {
                      $setBlocksType(selection, () => $createParagraphNode())
                    }
                    close()
                  })
                }}
              />

              <ToolbarDropdownItem
                testId="bullet-list-block-button"
                label="Bulleted List"
                icon={BulletedListIcon}
                active={blockType === 'ul'}
                onClick={() => {
                  editor.update(() => {
                    const selection = $getSelection()
                    if ($isRangeSelection(selection)) {
                      if (blockType !== 'ul') {
                        editor.dispatchCommand(
                          INSERT_UNORDERED_LIST_COMMAND,
                          undefined
                        )
                      } else {
                        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
                      }
                    } else {
                      toast.error(
                        'You must click inside the editor before adding a bulleted list'
                      )
                    }
                    close()
                  })
                }}
              />

              <ToolbarDropdownItem
                testId="number-list-block-button"
                label="Numbered List"
                icon={NumberedListIcon}
                active={blockType === 'ol'}
                onClick={() => {
                  editor.update(() => {
                    const selection = $getSelection()
                    if ($isRangeSelection(selection)) {
                      if (blockType !== 'ol') {
                        editor.dispatchCommand(
                          INSERT_ORDERED_LIST_COMMAND,
                          undefined
                        )
                      } else {
                        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
                      }
                    } else {
                      toast.error(
                        'You must click inside the editor before adding a numbered list'
                      )
                    }
                    close()
                  })
                }}
              />

              <ToolbarDropdownItem
                testId="quote-block-button"
                label="Quote"
                icon={QuoteIcon}
                active={blockType === 'quote'}
                onClick={() => {
                  editor.update(() => {
                    const selection = $getSelection()
                    if ($isRangeSelection(selection)) {
                      if (blockType !== 'quote') {
                        $setBlocksType(selection, () => $createQuoteNode())
                      } else {
                        $setBlocksType(selection, () => $createParagraphNode())
                      }
                    }
                    close()
                  })
                }}
              />
            </StackView>
          )}
        />
      </ToolbarButtonGroup>

      {isLink && <FloatingLinkEditor />}
    </StackView>
  )
}
export default ToolbarPlugin
