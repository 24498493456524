import type { MessageErrorCode } from 'types/graphql'

export const textMessageErrorCodeDisplay: {
  [key in MessageErrorCode]: {
    shortDescripton: string
    details: string
  }
} = {
  E21211_INVALID_TO_PHONE_NUMBER: {
    shortDescripton: 'Invalid Number',
    details: 'update to a valid mobile number.',
  },
  E21610_UNSUBSCRIBED_RECIPIENT: {
    shortDescripton: 'Unsubscribed individual',
    details: 'resubscribe by texting “START” to (000) 000-0000.',
  },
  E30003_UNREACHABLE_DESTINATION_HANDSET: {
    shortDescripton: 'Unreachable Mobile Phone',
    details: 'please retry at another time.',
  },
  E30005_UNKNOWN_DESTINATION_HANDSET: {
    shortDescripton: 'Unreachable Mobile Phone',
    details: 'please retry at another time.',
  },
  E30006_LANDLINE_OR_UNREACHABLE_CARRIER: {
    shortDescripton: 'Landline or unreachable carrier',
    details: 'update to a reachable mobile number.',
  },
  E30007_MESSAGE_FILTERED: {
    shortDescripton: 'Flagged as spam',
    details: 'carrier will need to update their settings.',
  },
  E30008_UNKNOWN_ERROR: {
    shortDescripton: 'Unknown failure',
    details: 'please retry or contact support if problem persists.',
  },
  E30024_NUMERIC_SENDER_ID_NOT_PROVISIONED_ON_CARRIER: {
    shortDescripton: 'Numerics Sender ID provisioning needed',
    details: 'contact support.',
  },
  E60005_DOWNSTREAM_CARRIER_ERROR: {
    shortDescripton: 'Carrier error',
    details: 'please retry at another time.',
  },
  OTHER: {
    shortDescripton: 'Unknown failure',
    details: 'please retry or contact support if problem persists.',
  },
}
