import { formatDisplayName } from 'common/utils'
import { orderBy } from 'lodash'
import type { SelectActivePractitioners, Practitioner } from 'types/graphql'

import SelectField, {
  SelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'

export type ActivePractitionerSelectFieldProps = {
  activePractitioners?: Practitioner[]
} & SelectFieldProps &
  SelectActivePractitioners

export const ActivePractitionerSelectField = ({
  activePractitioners,
  name,
  ...rest
}: ActivePractitionerSelectFieldProps) => {
  return (
    <SelectField
      name={name}
      emptyAs={null}
      includeEmptyOption={false}
      {...rest}
      options={orderBy(
        activePractitioners,
        [(practitioner) => formatDisplayName(practitioner).toLocaleLowerCase()],
        ['asc']
      ).map((practitioner) => {
        return {
          value: practitioner.id,
          externalId: practitioner.medplumId,
          display: formatDisplayName(practitioner),
        }
      })}
    />
  )
}

export default ActivePractitionerSelectField
