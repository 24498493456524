import { AppointmentAuditRecordAction } from 'types/graphql'

export const appointmentAuditRecordActionDisplay: {
  [key in AppointmentAuditRecordAction]: string
} = {
  SCHEDULED: 'Scheduled',
  ARRIVED: 'Arrived',
  CANCELLED: 'Cancelled',
  NO_SHOW: 'No show',
  CHECKED_IN: 'Checked in',
  WAITING_FOR_RN: 'Needs RN',
  WAITING_FOR_MA: 'Needs MA',
  WAITING_FOR_LABS: 'Needs labs',
  WAITING_FOR_PRACTITIONER: 'Needs practitioner',
  WAITING_FOR_VACCINATION: 'Needs vaccination',
  POST_IMMUNIZATION_WAIT: 'Post-immunization',
  SEEN: 'Being seen',
  READY_TO_BE_CHECKED_OUT: 'Check out ready',
  CHECKED_OUT: 'Checked out',
  STARTED: 'Started',
  CONFIRMED: 'Confirmed',
  UNCONFIRMED: 'Unconfirmed',
  UPDATED: 'Updated',
  WORKING_UP: 'Working Up',
  WITH_BILLING: 'With billing',
  WITH_RN_MA: 'With RN / MA',
  LABS_IN_PROGRESS: 'Labs in progress',
  LABS_COMPLETED: 'Labs completed',
}
