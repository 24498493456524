import omit from 'lodash/omit'
import { useParams } from 'react-router-dom'
import {
  BillingCode,
  CreateVisitTemplateOrderInput,
  DiagnosisCode,
  VisitTemplateSchedulingOrderInput,
  VisitTemplateSchedulingOrderLocationType,
  VisitTemplateSchedulingOrderPractitionerType,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'

import { useEmrAuth } from 'src/auth'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { InternalOrderCategories } from 'src/data/orderCategories'
import { useCreateVisitTemplateOrderMutation } from 'src/pages/VisitTemplatePage/useVisitTemplates'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import FormInputList from '../molecules/FormInputList'

import buildDefaultValues from './buildDefaultValues'
import buildFormInputList, {
  FormType,
  ProviderPreference,
} from './buildFormInputList'
import buildSubmitInput from './buildSubmitInput'
import {
  useOrderTypeOptions,
  useSetDefaultOrderDiagnosesAndBillingCodes,
} from './useOrderTypes'

export type VisitTemplateOrderSidepanelFormFormType =
  CreateVisitTemplateOrderInput & {
    orderInput: {
      diagnosisCodes: { code: DiagnosisCode }[]
      cptCode: { code: Omit<BillingCode, 'chargeItems' | 'group'> }
      providerPreference?: ProviderPreference
    }
  }
type Props = {
  category: InternalOrderCategories
}

const VisitTemplateOrderSidepanelForm = ({ category }: Props) => {
  const { templateId } = useParams()
  const { currentUser } = useEmrAuth()
  const { closeSidePanel } = useSidepanel()
  const [createVisitTemplateOrder, { loading: creatingOrder }] =
    useCreateVisitTemplateOrderMutation()

  const orderTypeOptions = useOrderTypeOptions(category)

  const formMethods = useForm<FormType>({
    defaultValues: buildDefaultValues({
      category,
    }),
  })

  const providerPreference = formMethods.watch('orderInput.providerPreference')
  const code = formMethods.watch('orderInput.code')
  const appointmentDefinitionIds = formMethods.watch(
    'schedulingOrderInput.appointmentDefinitionIds'
  )

  useSetDefaultOrderDiagnosesAndBillingCodes({
    category,
    code,
    formMethods,
  })

  const inputListItems = buildFormInputList({
    category,
    code,
    providerPreference,
    orderTypeOptions,
    appointmentDefinitionIds,
    isEdit: false,
    excludedFields: ['EXPIRES_AT', 'IS_URGENT', 'COMMENT'],
    formMethods,
    allowCurrentPractitionerAndLocation: true,
  })

  const onSubmit = async ({
    orderInput,
    schedulingOrderInput,
  }: VisitTemplateOrderSidepanelFormFormType) => {
    const input = buildSubmitInput({
      category,
      orderInput,
      schedulingOrderInput,
      currentUser,
    })

    const data: {
      visitTemplateId: string
      schedulingOrderInput?: VisitTemplateSchedulingOrderInput
    } = {
      ...omit(input, [
        'patientId',
        'encounterId',
        'schedulingOrderInput.intent',
      ]),
      visitTemplateId: templateId,
    }

    if (category === 'SCH') {
      data.schedulingOrderInput = {
        ...data.schedulingOrderInput,
        ...(category === 'SCH'
          ? {
              visitPractitionerId: ['CURRENT', 'PRIMARY'].includes(
                input.schedulingOrderInput.visitPractitionerId
              )
                ? null
                : input.schedulingOrderInput.visitPractitionerId,
              visitPractitionerType: ['CURRENT', 'PRIMARY'].includes(
                input.schedulingOrderInput.visitPractitionerId
              )
                ? (input.schedulingOrderInput
                    .visitPractitionerId as VisitTemplateSchedulingOrderPractitionerType)
                : 'SPECIFIC',
              locationId: ['CURRENT'].includes(
                input.schedulingOrderInput.locationId
              )
                ? null
                : input.schedulingOrderInput.locationId,
              locationType: ['CURRENT'].includes(
                input.schedulingOrderInput.locationId
              )
                ? (input.schedulingOrderInput
                    .locationId as VisitTemplateSchedulingOrderLocationType)
                : 'SPECIFIC',
              chiefComplaints:
                input.schedulingOrderInput.chiefComplaints?.filter(Boolean) ??
                [],
            }
          : undefined),
      }
    }

    void createVisitTemplateOrder({
      variables: {
        input: data,
      },
      onCompleted: closeSidePanel,
    })
  }

  return (
    <SidepanelForm
      onSubmit={onSubmit}
      formMethods={formMethods}
      footerProps={{
        submitText: 'Add order',
        submitting: creatingOrder,
      }}
    >
      <FormInputList items={inputListItems} />
    </SidepanelForm>
  )
}

export default VisitTemplateOrderSidepanelForm
