import { FindBinariesQuery } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { externalRequestTypeDisplay } from 'src/data/externalRequestTypes'
import { orderCategoryConfig } from 'src/data/orderCategories'
import { patientEducationDocumentTypeDisplay } from 'src/data/patientEducationDocumentTypes'
import { patientFormTypeDisplay } from 'src/data/patientFormTypes'
import { practiceAdminDocumentTypeDisplay } from 'src/data/practiceAdminDocumentTypes'
import { practiceFormTypeDisplay } from 'src/data/practiceformTypes'
import { practiceLetterTypeDisplay } from 'src/data/practiceLetterTypes'
import { screeningToolTypeDisplay } from 'src/data/screeningToolTypes'

const QUERY = gql`
  query FindBinariesQuery {
    faxablePracticeBinaries {
      id
      inboundFax {
        id
      }
      outboundFax {
        id
        createdAt
      }
      reportRequest {
        id
      }
      practiceForm {
        id
        version
        isCurrent
        practiceFormDefinition {
          id
          type
        }
        createdAt
      }
      practiceAdminDocument {
        id
        title
        type
        createdAt
      }
      patientEducationDocument {
        id
        title
        type
        createdAt
      }
      patientForm {
        id
        title
        type
        createdAt
      }
      externalRequest {
        id
        title
        type
        createdAt
      }
      screeningTool {
        id
        title
        type
        createdAt
      }
      practiceLetter {
        id
        title
        type
        createdAt
      }
      order {
        name
        category
        createdAt
      }
    }
  }
`

interface DocumentInformation {
  title?: string
  version?: number
  type: string
  subType?: string
  createdAt?: string
}

const getDocumentInformation = (
  binary: FindBinariesQuery['faxablePracticeBinaries'][0]
) => {
  let documentInformation: DocumentInformation
  if (binary.practiceForm) {
    documentInformation = {
      type: 'Practice form',
      version: binary.practiceForm.version,
      subType:
        practiceFormTypeDisplay[
          binary.practiceForm.practiceFormDefinition.type
        ],
      createdAt: binary.practiceForm.createdAt,
    }
  } else if (binary.practiceAdminDocument) {
    documentInformation = {
      type: 'Practice admin',
      subType:
        practiceAdminDocumentTypeDisplay[binary.practiceAdminDocument.type],
      title: binary.practiceAdminDocument.title,
      createdAt: binary.practiceAdminDocument.createdAt,
    }
  } else if (binary.patientEducationDocument) {
    documentInformation = {
      type: 'Patient education',
      subType:
        patientEducationDocumentTypeDisplay[
          binary.patientEducationDocument.type
        ],
      title: binary.patientEducationDocument.title,
      createdAt: binary.patientEducationDocument.createdAt,
    }
  } else if (binary.patientForm) {
    documentInformation = {
      type: 'Patient form',
      subType: patientFormTypeDisplay[binary.patientForm.type],
      title: binary.patientForm.title,
      createdAt: binary.patientForm.createdAt,
    }
  } else if (binary.externalRequest) {
    documentInformation = {
      type: 'External request',
      subType: externalRequestTypeDisplay[binary.externalRequest.type],
      title: binary.externalRequest.title,
      createdAt: binary.externalRequest.createdAt,
    }
  } else if (binary.screeningTool) {
    documentInformation = {
      type: 'Screening tool',
      subType: screeningToolTypeDisplay[binary.screeningTool.type],
      title: binary.screeningTool.title,
      createdAt: binary.screeningTool.createdAt,
    }
  } else if (binary.practiceLetter) {
    documentInformation = {
      type: 'Practice letter',
      subType: practiceLetterTypeDisplay[binary.practiceLetter.type],
      title: binary.practiceLetter.title,
      createdAt: binary.practiceLetter.createdAt,
    }
  } else if (binary.order) {
    documentInformation = {
      type: 'Order',
      subType: orderCategoryConfig[binary.order.category].display,
      title: binary.order.name,
      createdAt: binary.order.createdAt,
    }
  } else {
    documentInformation = {
      type: 'Unknown',
      title: binary.id,
      createdAt: null,
    }
  }
  return documentInformation
}

const useFaxablePracticeBinariesQuery = (opts?: { skip: boolean }) => {
  const { data, error, loading } = useQuery<FindBinariesQuery>(QUERY, {
    skip: opts?.skip,
  })

  return {
    binaries: data?.faxablePracticeBinaries?.map((binary) => {
      const documentInformation = getDocumentInformation(binary)
      return {
        ...binary,
        documentInformation,
      }
    }),
    error,
    loading,
  }
}

export default useFaxablePracticeBinariesQuery
