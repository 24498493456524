import { CheckIcon } from '@heroicons/react/24/solid'
import { dateTimeFormatter, jsDateToLocalDate } from 'common/data/date'
import { isAfter, parseISO, startOfToday } from 'date-fns'
import omit from 'lodash/omit'
import {
  CVXDetails,
  GetVaccineInventoryForVaccineInventorySelectField,
  NdcSaleDetails,
  VaccineFundingSource,
} from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import {
  fundingSourceColorMap,
  fundingSourceDescriptionMap,
} from 'src/pages/Sidepanel/SidepanelVaccineInventory/AddInventoryTable'

import Badge from '../Badge'
import { DropdownField, DropdownFieldProps } from '../Dropdown'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { Option } from '../Select/Select'
import StackView from '../StackView/StackView'
import Typography from '../Typography/Typography'

type DeletedInventory = {
  id: string
  lotNumber: string
  expirationDate: string
  location: {
    id: string
  }
  fundingSource: VaccineFundingSource
  currentDoses: number
  status: string
  ndcDetails: NdcSaleDetails
  cvxDetails?: CVXDetails
}

type NonDeletedSelection =
  GetVaccineInventoryForVaccineInventorySelectField['getVaccineInventory'][number]['vaccineInventoryLocations'][number] &
    Omit<
      GetVaccineInventoryForVaccineInventorySelectField['getVaccineInventory'][number],
      '__typename'
    >

export type VaccineInventorySelection = NonDeletedSelection | DeletedInventory

export type VaccineInventorySelectFieldProps = {
  cvxCode: string
  locationId: string
  onSelection?: (selection: VaccineInventorySelection) => void
  hideExpired?: boolean
  recreateDeletedInventory?: boolean
  deletedInventory?: DeletedInventory
  /** This option differs from the beforeChange from Dropdown because
   * the callback takes a `Selection` instead of a `string`. Which makes it more
   * convenient to access the data of the selected option.
   */
  beforeChange?: (
    selection: VaccineInventorySelection
  ) => ReturnType<DropdownFieldProps['beforeChange']>
} & Omit<DropdownFieldProps, 'options' | 'beforeChange' | 'onSelection'>

const GET_VACCINE_INVENTORY = gql`
  query GetVaccineInventoryForVaccineInventorySelectField {
    getVaccineInventory {
      id
      lotNumber
      expirationDate
      status
      comment
      ndcSale
      ndcDetails {
        cvxCode
        brandName
        manufacturer
        ndcSale
        ndcUse
        cvxCodeAndDescription
        defaultDoseAmount
        defaultDoseUnit
        defaultRoute
      }
      cvxDetails {
        visDate
      }
      vaccineInventoryLocations {
        id
        location {
          id
          name
        }
        fundingSource
        currentDoses
      }
    }
  }
`

export const VaccineInventorySelectField = ({
  name,
  cvxCode,
  locationId,
  onSelection,
  hideExpired,
  recreateDeletedInventory,
  deletedInventory,
  beforeChange,
  ...props
}: VaccineInventorySelectFieldProps) => {
  const { data, loading } =
    useQuery<GetVaccineInventoryForVaccineInventorySelectField>(
      GET_VACCINE_INVENTORY
    )

  const { watch } = useFormContext()
  const selectedId = watch(name)

  if (loading) return <LoadingSpinner />

  const untypedOptions: VaccineInventorySelection[] = []
  if (recreateDeletedInventory) {
    untypedOptions.push(deletedInventory)
  }

  data?.getVaccineInventory.forEach((inventory) => {
    inventory.vaccineInventoryLocations.forEach((vaccineInventoryLocation) => {
      untypedOptions.push({
        ...omit(inventory, '__typename'),
        ...vaccineInventoryLocation,
      })
    })
  })

  const options: (Option & { data: VaccineInventorySelection })[] =
    untypedOptions
      .filter((inventory) => inventory.ndcDetails.cvxCode === cvxCode)
      .filter((inventory) => inventory.location.id === locationId)
      .filter((inventory) => ['ACTIVE', 'DELETED'].includes(inventory.status))
      .filter(
        (inventory) => inventory.currentDoses > 0 || inventory.id === selectedId
      )
      .filter((inventory) =>
        hideExpired
          ? isAfter(parseISO(inventory.expirationDate), startOfToday())
          : true
      )
      .map((inventory) => {
        return {
          name: inventory.id,
          value: inventory.id,
          data: inventory,
          render: () => (
            <StackView gap={25}>
              <StackView gap={25} direction="row" alignItems="center">
                <Typography
                  color="text-base-color-fg-muted"
                  textStyle="interface-default-s"
                >
                  {inventory.lotNumber}
                </Typography>
              </StackView>
              <StackView gap={25} direction="row" alignItems="center">
                <Typography
                  color="text-base-color-fg-subtle"
                  textStyle="interface-default-s"
                >
                  {jsDateToLocalDate(inventory.expirationDate).format(
                    dateTimeFormatter('MM/dd/yyyy')
                  )}
                </Typography>
                <Typography
                  color="text-base-color-fg-subtle"
                  textStyle="interface-default-s"
                >
                  ·
                </Typography>
                <Badge
                  color={fundingSourceColorMap[inventory.fundingSource]}
                  size="s"
                  text={fundingSourceDescriptionMap[inventory.fundingSource]}
                />
                <Typography
                  color="text-base-color-fg-subtle"
                  textStyle="interface-default-s"
                >
                  ·
                </Typography>
                <Typography
                  color="text-base-color-fg-subtle"
                  textStyle="interface-default-s"
                >
                  {inventory.currentDoses} left
                </Typography>
              </StackView>
            </StackView>
          ),
          selectedRender: () => (
            <StackView gap={25} direction="row" alignItems="center">
              <Typography
                color="text-comp-textfield-color-clean-enabled-content"
                textStyle="interface-default-s"
              >
                {inventory.lotNumber}
              </Typography>
              <Typography
                color="text-comp-textfield-color-clean-enabled-placeholder"
                textStyle="interface-default-s"
              >
                {jsDateToLocalDate(inventory.expirationDate).format(
                  dateTimeFormatter('MM/dd/yyyy')
                )}
              </Typography>
              <Badge
                color={fundingSourceColorMap[inventory.fundingSource]}
                size="s"
                text={fundingSourceDescriptionMap[inventory.fundingSource]}
              />
            </StackView>
          ),
          selectedIcon: {
            value: (
              <CheckIcon className="h-base-size-icon-xs w-base-size-icon-xs" />
            ),
            class: 'text-comp-toggle-color-primary-selected-enabled-border',
          },
        }
      })

  return (
    <DropdownField
      name={name}
      {...props}
      beforeChange={async (value) => {
        if (!beforeChange) return
        return beforeChange(options.find((x) => x.value === value)?.data)
      }}
      options={options}
      onSelection={(value) => {
        onSelection &&
          onSelection(options.find((x) => x.value === value?.value)?.data)
      }}
    />
  )
}

export default VaccineInventorySelectField
