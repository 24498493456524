import { PatientDocumentSubType } from 'types/graphql'

import { patientEducationDocumentTypeDisplay } from 'src/data/patientEducationDocumentTypes'

import { clinicalResultTypeDisplay } from './clinicalResultTypes'
import { externalRequestTypeDisplay } from './externalRequestTypes'
import { medicalRecordTypeDisplay } from './medicalRecordTypes'
import { medicationDocumentTypeDisplay } from './medicationDocumentTypes'
import { patientCaregiverInformationDocumentTypeDisplay } from './patientCaregiverInformationDocumentTypes'
import { patientFormTypeDisplay } from './patientFormTypes'
import { patientOrderDocumentTypeDisplay } from './patientOrderDocumentTypes'
import { practiceFormTypeDisplay } from './practiceformTypes'
import { practiceLetterTypeDisplay } from './practiceLetterTypes'
import { screeningToolTypeDisplay } from './screeningToolTypes'
import { nameValueArrayFromObject } from './utils'

export const patientDocumentSubTypeDisplay: {
  [key in PatientDocumentSubType]: string
} = {
  ...patientFormTypeDisplay,
  ...externalRequestTypeDisplay,
  ...screeningToolTypeDisplay,
  ...practiceLetterTypeDisplay,
  ...medicalRecordTypeDisplay,
  ...medicationDocumentTypeDisplay,
  ...clinicalResultTypeDisplay,
  ...patientOrderDocumentTypeDisplay,
  ...patientCaregiverInformationDocumentTypeDisplay,
  ...practiceFormTypeDisplay,
  ...patientEducationDocumentTypeDisplay,
}

export const patientDocumentSubTypes =
  nameValueArrayFromObject<PatientDocumentSubType>(
    patientDocumentSubTypeDisplay
  )
