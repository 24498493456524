import type { DocumentCaseResolutionStatus } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const documentCaseStatusDisplay: {
  [key in DocumentCaseResolutionStatus]: string
} = {
  OPEN: 'Open',
  RESOLVED: 'Resolved',
  CANCELLED: 'Cancelled',
}

export const documentCaseStatusType =
  nameValueArrayFromObject<DocumentCaseResolutionStatus>(
    documentCaseStatusDisplay
  )
