import { useState } from 'react'

import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import StackView from '../StackView'

type NumberSpinnerProps = {
  callback: (value: string, operator: 'plus' | 'minus') => void
}

const NumberSpinner = ({ callback }: NumberSpinnerProps) => {
  const [value, setValue] = useState('')
  const handleClick = (operator) => {
    callback(value !== '' ? value : '1', operator) // if no value is input, default to 1
    setValue('')
  }

  const classes = [
    'text-center',
    'block w-full text-sm text-gray-900',
    'rounded-md shadow-sm border-gray-300',
    'focus:border-primary-dark focus:ring-primary',
    '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
  ]
  return (
    <StackView
      direction="row"
      space={25}
      alignItems="center"
      data-testid="number-spinner"
    >
      <MinusIcon
        className="h-6 w-6 hover:cursor-pointer hover:bg-red-100 hover:fill-red-600"
        onClick={() => handleClick('minus')}
        data-testid="number-spinner-minus"
      />

      <div className="w-14">
        <input
          type="number"
          className={clsx(classes)}
          onChange={(event) => setValue(event.target.value)}
          value={value}
          data-testid="number-spinner-input"
        />
      </div>

      <PlusIcon
        className="h-6 w-6 hover:cursor-pointer hover:bg-green-100 hover:fill-green-600"
        onClick={() => handleClick('plus')}
        data-testid="number-spinner-plus"
      />
    </StackView>
  )
}
export default NumberSpinner
