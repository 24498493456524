import { formatMoneyInCents } from 'common/utils'
import { format } from 'date-fns'
import { capitalize } from 'lodash'
import { useParams } from 'react-router-dom'

import Badge from 'src/components/atoms/Badge'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import DataDisplayList from 'src/components/molecules/DataDisplayList'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import {
  PAYMENT_STATUS,
  PAYMENT_TYPES,
} from 'src/components/PatientFinancials/components/HistoricalPatientPaymentsTable'
import { usePaymentPlanPaymentQuery } from 'src/hooks/usePatientPaymentPlans/usePatientPaymentPlans'

const SidepanelPaymentPlanPaymentView = () => {
  const params = useParams()
  const { paymentPlanPaymentId } = params

  const { paymentPlanPayment, loading } =
    usePaymentPlanPaymentQuery(paymentPlanPaymentId)

  return (
    <SidepanelPage
      testId="payment-plan-payment-view"
      header="Payment plan"
      loading={loading}
    >
      <StackView className="grow p-core-space-150" space={50}>
        {paymentPlanPayment && (
          <DataDisplayList
            title="Details"
            leftColumnWidth="lg"
            data={[
              {
                label: 'Amount',
                value: formatMoneyInCents(paymentPlanPayment.amountCents),
              },
              {
                label: 'Payment method',
                value: 'Pre-authorized credit',
              },
              {
                label: 'Type',
                value: (
                  <Badge
                    testId="payment-type-badge"
                    color={PAYMENT_TYPES['PAYMENT'].color}
                    text={PAYMENT_TYPES['PAYMENT'].label}
                  />
                ),
              },
              {
                label: 'Status',
                value: (
                  <Badge
                    testId="payment-status-badge"
                    color={PAYMENT_STATUS[paymentPlanPayment.status].color}
                    text={PAYMENT_STATUS[paymentPlanPayment.status].label}
                    showDot
                  />
                ),
              },
              {
                label: 'Processed by',
                value: (
                  <StackView>
                    <Typography
                      textStyle="body-s"
                      color="text-base-color-fg-muted"
                      className="!mb-0"
                    >
                      {paymentPlanPayment.paymentMethod.holderName}
                    </Typography>
                    <Typography
                      textStyle="interface-default-xs"
                      color="text-base-color-fg-subtle"
                    >
                      {capitalize(paymentPlanPayment.paymentMethod.brand)}{' '}
                      ending in {paymentPlanPayment.paymentMethod.last4}
                    </Typography>
                  </StackView>
                ),
              },
              {
                label: 'Processed date and time',
                value: format(
                  new Date(paymentPlanPayment.createdAt),
                  "MM/dd/yyyy 'at' hh:mma"
                ),
              },
            ]}
          />
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPaymentPlanPaymentView
