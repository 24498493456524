import { format, isDate, isToday, isValid, parse } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import { AppointmentStatus } from 'types/graphql'
import { useLocalStorage } from 'usehooks-ts'

import { useParams, navigate, useLocation } from '@redwoodjs/router'

import { checkedInStatuses } from 'src/data/appointmentStatus'

import { useSetUserSettingsMutation } from '../useUserSettings/useUserSettings'

const SCHEDULE_DATE_FILTER_KEY = 'scheduleDateFilter'
const SCHEDULE_DATE_FILTER_UPDATED_AT_KEY = 'scheduleDateFilterUpdatedAt'

export type ScheduleFilter =
  | 'schedulePractitionerFilter'
  | 'scheduleLocationFilter'
  | 'scheduleDateFilter'
  | 'scheduleViewType'
  | 'scheduleAppointmentStatusFilter'

export const appointmentStatusFilterOptions = [
  {
    name: 'Scheduled',
    value: 'SCHEDULED',
  },
  {
    name: 'At clinic',
    value: 'AT_CLINIC',
  },
  {
    name: 'Checked out',
    value: 'CHECKED_OUT',
  },
  {
    name: 'No show',
    value: 'NO_SHOW',
  },
  {
    name: 'Cancelled',
    value: 'CANCELLED',
  },
] as const

type AppointmentStatusFilterOption =
  (typeof appointmentStatusFilterOptions)[number]['value']

const parseIds = (filter?: string) => {
  if (isUndefined(filter)) {
    return undefined
  }

  if (isEmpty(filter)) {
    return []
  }

  return filter.split(',').filter((id) => id != '')
}

const parseAppointmentStatuses = (filter?: string) => {
  if (isUndefined(filter)) {
    return undefined
  }

  if (isEmpty(filter)) {
    return []
  }

  return filter
    .split(',')
    .filter((status: string) => status != '')
    .flatMap((status: AppointmentStatusFilterOption) =>
      status === 'AT_CLINIC'
        ? [...checkedInStatuses, 'ARRIVED' as AppointmentStatus]
        : (status as AppointmentStatus)
    )
}

const parseDate = (filter?: string) => {
  if (filter) {
    return parse(filter, 'yyyy-MM-dd', new Date())
  }
}

export const useScheduleFilter = () => {
  const params = useParams()

  const {
    schedulePractitionerFilter: practitionerIds,
    scheduleLocationFilter: locationIds,
    scheduleDateFilter: visitDate,
    scheduleViewType: viewType,
    scheduleAppointmentStatusFilter: includeStatuses,
  } = params

  return {
    visitDate: parseDate(visitDate),
    practitionerIds: parseIds(practitionerIds),
    locationIds: parseIds(locationIds),
    includeStatuses: parseAppointmentStatuses(includeStatuses),
    viewType,
  }
}

const getFilterValue = (filter: ScheduleFilter, value) => {
  if (filter === 'scheduleDateFilter' && value instanceof Date) {
    return format(value, 'yyyy-MM-dd')
  }

  if (
    filter === 'scheduleViewType' ||
    filter === 'scheduleAppointmentStatusFilter'
  ) {
    return value
  }

  if (Array.isArray(value)) {
    return value.join(',')
  }
}

export const useUpdateFilter = () => {
  const params = useParams()
  const location = useLocation()
  const [setScheduleUserSettings] = useSetUserSettingsMutation()
  const [_scheduleDateFilter, setScheduleDateFilter] = useLocalStorage(
    SCHEDULE_DATE_FILTER_KEY,
    null
  )
  const [_scheduleDateFilterUpdatedAt, setScheduleDateFilterUpdatedAt] =
    useLocalStorage(SCHEDULE_DATE_FILTER_UPDATED_AT_KEY, null)

  const paramsWithoutGlob = { ...params }
  if (paramsWithoutGlob.glob) {
    delete paramsWithoutGlob.glob
  }

  const saveSettings = ({
    filter,
    value,
  }: {
    filter: ScheduleFilter
    value: string | string[] | Date
  }) => {
    if (filter !== 'scheduleDateFilter') {
      void setScheduleUserSettings({
        variables: {
          input: {
            [filter]: getFilterValue(filter, value),
          },
        },
      })
    } else {
      setScheduleDateFilter(getFilterValue('scheduleDateFilter', value))
      setScheduleDateFilterUpdatedAt(new Date().toISOString())
    }
  }

  return (
    filter: ScheduleFilter,
    value: string[] | Date | string,
    opts?: { saveSettings: boolean }
  ) => {
    const shouldSaveSettings = opts?.saveSettings ?? false

    const newParams = new URLSearchParams({
      ...paramsWithoutGlob,
      [filter]: getFilterValue(filter, value),
    })

    if (shouldSaveSettings) {
      void saveSettings({
        filter,
        value,
      })
    }

    navigate(`${location.pathname}?${newParams.toString()}`)
  }
}

export const useLastSavedScheduleDateFilter = () => {
  const [scheduleDateFilter] = useLocalStorage(SCHEDULE_DATE_FILTER_KEY, null)
  const [scheduleDateFilterUpdatedAt] = useLocalStorage(
    SCHEDULE_DATE_FILTER_UPDATED_AT_KEY,
    null
  )

  if (!scheduleDateFilter || !scheduleDateFilterUpdatedAt) return null

  const scheduleDate = parseDate(scheduleDateFilter)

  if (!isDate(scheduleDate) || !isValid(scheduleDate)) return null

  const lastUpdatedAt = new Date(scheduleDateFilterUpdatedAt)
  if (
    !isDate(lastUpdatedAt) ||
    !isValid(lastUpdatedAt) ||
    !isToday(lastUpdatedAt)
  )
    return null

  return scheduleDate
}
