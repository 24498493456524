import { useContext, useState } from 'react'

import { createNamedContext } from 'src/utils'

const FullScreenContext = createNamedContext<{
  isFullScreen: boolean
  setFullScreen: (isFullScreen: boolean) => void
}>('FullScreenContext')

export function useFullScreenContext() {
  const context = useContext(FullScreenContext)
  return context
}

export function FullScreenContextProvider({ children }) {
  const [isFullScreen, setFullScreen] = useState<boolean>(false)
  return (
    <FullScreenContext.Provider value={{ isFullScreen, setFullScreen }}>
      {children}
    </FullScreenContext.Provider>
  )
}

export default FullScreenContext
