import { tobaccoSmokingStatusConceptCodings } from 'common/cdr/concepts/observations/socialHistories/index'
import type { TobaccoSmokingStatus } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const tobaccoSmokingStatusDisplay: {
  [key in TobaccoSmokingStatus]: string
} = {
  NEVER: 'Never smoker',
  UNKNOWN_IF_EVER: 'Unknown if ever smoked',
  FORMER: 'Former smoker',
  CURRENT_EVERYDAY: 'Current every day smoker',
  CURRENT_SOMEDAY: 'Current some day smoker',
  UNKNOWN: 'Smoker, current status unknown',
  LIGHT: 'Light tobacco smoker',
  HEAVY: 'Heavy tobacco smoker',
}

export const tobaccoSmokingStatusDisplayToEnum = Object.keys(
  tobaccoSmokingStatusDisplay
).reduce((acc, key) => {
  acc[tobaccoSmokingStatusDisplay[key]] = key
  return acc
}, {})

export const tobaccoSmokingStatusCodeToEnum =
  tobaccoSmokingStatusConceptCodings.reduce((acc, coding) => {
    if (tobaccoSmokingStatusDisplayToEnum[coding.display]) {
      acc[coding.code] = tobaccoSmokingStatusDisplayToEnum[coding.display]
    }
    return acc
  }, {})

export const tobaccoSmokingStatuses =
  nameValueArrayFromObject<TobaccoSmokingStatus>(tobaccoSmokingStatusDisplay)
