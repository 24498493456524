import { NoSymbolIcon } from '@heroicons/react/24/solid'

import Space from 'src/components/atoms/Space'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

const EmptyDataContent = ({
  message,
  details,
  variant = 'default',
}: {
  message: string
  details?: string
  variant?: 'default' | 'short'
}) => {
  return (
    <StackView
      justifyContent="center"
      alignItems="center"
      className={variant === 'default' ? 'h-56' : 'h-20 py-2'}
    >
      <NoSymbolIcon className="h-8 w-8 text-gray-400" />
      <Space space={50} />
      <Typography textStyle="subtitle" className="text-center">
        {message}
      </Typography>
      {details && (
        <Typography color="text-base-color-fg-muted" className="text-center">
          {details}
        </Typography>
      )}
    </StackView>
  )
}

export default EmptyDataContent
