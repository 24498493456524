import { useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { GetPatientForGenericPdf, Practitioner } from 'types/graphql'

import { useEmrAuth } from 'src/auth'
import { lexicalToHTML } from 'src/components/atoms/WysiwygField/utils/utils'
import { PATIENT_RELATED_PERSON_RELATIONSHIPS_FRAGMENT } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import PrintGenericPatientDocument from 'src/pdf/components/PrintGenericPatientDocument'
import {
  PATIENT_DETAILS_FRAGMENT,
  SIGNING_PRACTITIONER_FRAGMENT,
  TENANT_LETTERHEAD_FRAGMENT,
} from 'src/pdf/fragments'
import { useGeneratePDF } from 'src/pdf/useGeneratePDF'

const QUERY = gql`
  query GetPatientForGenericPdf($id: String!, $practitionerId: String!) {
    patient(id: $id) {
      id
      ...PatientDetailsFragment
      ...PatientRelatedPersonRelationshipsFragment
      primaryProvider {
        id
        givenName
        familyName
      }
      tenant {
        ...TenantLetterheadFragment
      }
    }
    practitioner(id: $practitionerId) {
      ...SigningPractitionerFragment
    }
  }
  ${PATIENT_DETAILS_FRAGMENT}
  ${PATIENT_RELATED_PERSON_RELATIONSHIPS_FRAGMENT}
  ${TENANT_LETTERHEAD_FRAGMENT}
  ${SIGNING_PRACTITIONER_FRAGMENT}
`

export const useCreateGenericPdf = () => {
  const { currentUser } = useEmrAuth()
  const generatePDF = useGeneratePDF()
  const [isGenerating, setIsGenerating] = useState<boolean>(false)

  const [getPatient] = useLazyQuery<GetPatientForGenericPdf>(QUERY)

  const generateGenericPdf = async ({
    patientId,
    title,
    content,
    addSignature,
    signedByPractitioner,
  }: {
    patientId: string
    title?: string
    content: string
    addSignature?: boolean
    signedByPractitioner?: Practitioner
  }) => {
    const contentHtml = await lexicalToHTML(content)

    const { data } = await getPatient({
      variables: {
        id: patientId,
        practitionerId: currentUser.practitionerId,
      },
    })

    setIsGenerating(true)
    const generated: Promise<string> = new Promise((resolve) => {
      void generatePDF({
        forPatient: data.patient,
        component: (
          <PrintGenericPatientDocument
            patient={data.patient}
            tenant={data.patient.tenant}
            title={title}
            content={contentHtml}
            addSignature={addSignature}
            practitioner={signedByPractitioner ?? data.practitioner}
          />
        ),
        callback: (doc) => {
          setIsGenerating(false)
          resolve(doc.output('datauristring'))
        },
      })
    })
    return generated
  }

  return { isGenerating, generateGenericPdf }
}
