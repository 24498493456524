import { useLocation } from '@redwoodjs/router'

import Box from 'src/components/atoms/Box'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import PatientLayout from 'src/layouts/PatientLayout/PatientLayout'
import { useSelfSchedulingState } from 'src/pages/PatientSelfScheduling/useSelfSchedulingState'

import NotFoundPage from '../NotFoundPage/NotFoundPage'

const getProgressPercentage = (pathname: string): number => {
  const [lastPath] = pathname.split('/').reverse()

  switch (lastPath) {
    case 'intro':
      return 0

    case 'select-time':
      return 50

    case 'confirmed':
      return 100
  }
}

const PatientSelfSchedulingLayout = ({ children }) => {
  const { data, loading } = useSelfSchedulingState()
  const { pathname } = useLocation()

  if (loading) {
    return (
      <Box className="flex h-screen items-center justify-center">
        <LoadingSpinner />
      </Box>
    )
  }

  if (!data?.selfSchedulingState?.schedulingCase) {
    return <NotFoundPage />
  }

  const schedulingCase = data.selfSchedulingState.schedulingCase

  return (
    <PatientLayout
      tenantId={schedulingCase.location.tenantId}
      showProgressBar
      progressBarPercentage={getProgressPercentage(pathname)}
    >
      <StackView space={75}>{children}</StackView>
    </PatientLayout>
  )
}
export default PatientSelfSchedulingLayout
