import { UserCircleIcon } from '@heroicons/react/20/solid'
import { formatDisplayName } from 'common/utils'

import MultiSelectDropdownField from 'src/components/molecules/MultiSelectDropdownField/MultiSelectDropdownField'
import { useUsers } from 'src/hooks/useUsers/useUsers'
import { compareDisplayNames } from 'src/utils/user'

export interface UserMultiSelectDropdownFieldProps {
  name: string
  testId?: string
}

export const UserMultiSelectDropdownField = ({
  name,
  testId,
}: UserMultiSelectDropdownFieldProps) => {
  const { data, loading } = useUsers()

  if (loading) return null

  const users = data?.users ?? []

  const sortedUsers = [...users].sort((a, b) => compareDisplayNames(a, b))

  return (
    <MultiSelectDropdownField
      testId={testId}
      name={name}
      options={sortedUsers.map((user) => ({
        value: user.id,
        name: formatDisplayName(user),
      }))}
      emptyDisplayText="No users"
      multiSelectDisplayText="users"
      icon={UserCircleIcon}
      hideClearMultiSelect
    />
  )
}
