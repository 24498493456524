import { useEffect, useState } from 'react'

import { NoSymbolIcon } from '@heroicons/react/24/solid'
import { CellStyle, RowNode } from 'ag-grid-community'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import clsx from 'clsx'

import AreaLoader from 'src/components/atoms/AreaLoader/AreaLoader'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import CustomPaginationPanel from './CustomPaginationPanel'
import './index.css'

export interface TableProps<T> extends AgGridReactProps<T> {
  innerRef: React.MutableRefObject<AgGridReact<T>>
  testId?: string
  cellStyle?: CellStyle
  pagination?: boolean
  paginationPageSize?: number
  selectedRowId?: string
  hideHeader?: boolean
  noMinHeight?: boolean
  noRowsMinHeight?: 300
  page?: number
  totalPages?: number
  onClickPaginationButton?: (page: number) => void
  useCustomPaginationPanel?: boolean
}

const NoRowsOverlay = ({ message }: { message: string }) => {
  return (
    <StackView
      justifyContent="center"
      alignItems="center"
      direction="row"
      space={50}
    >
      <NoSymbolIcon className="h-5 w-5 text-gray-400" />
      <Typography>{message}</Typography>
    </StackView>
  )
}

function Table<T>({
  innerRef,
  testId,
  cellStyle = {
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  pagination = true,
  paginationPageSize = 25,
  rowSelection = 'single',
  selectedRowId,
  rowData,
  hideHeader,
  noMinHeight,
  noRowsMinHeight,
  className,
  noRowsOverlayComponent,
  noRowsOverlayComponentParams,
  page,
  totalPages,
  onClickPaginationButton,
  useCustomPaginationPanel,
  domLayout,
  ...rest
}: TableProps<T>) {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [totalNumPages, setTotalNumPages] = useState<number>(totalPages ?? 0)

  useEffect(() => {
    if (selectedRowId && innerRef.current?.api) {
      innerRef.current.api.forEachNodeAfterFilter((node: RowNode) => {
        if (node.data.id === selectedRowId) {
          node.setSelected(true)
          innerRef.current.api.ensureIndexVisible(node.rowIndex)
        }
      })
    }

    if (useCustomPaginationPanel && pagination) {
      if (page) setCurrentPage(page - 1)
      if (totalPages) setTotalNumPages(totalPages)
    }
  }, [
    innerRef,
    selectedRowId,
    rowData,
    pagination,
    page,
    totalPages,
    useCustomPaginationPanel,
  ])

  const handleUpdatePagination = () => {
    if (pagination) {
      setCurrentPage(
        page ? page - 1 : innerRef.current?.api?.paginationGetCurrentPage()
      )
      setTotalNumPages(
        totalPages ?? innerRef.current?.api?.paginationGetTotalPages() ?? 0
      )
    }
  }

  const handleClickPaginationButton = (page: number) => {
    if (onClickPaginationButton) {
      onClickPaginationButton(page)
    } else {
      innerRef.current?.api?.paginationGoToPage(page)
    }
    setCurrentPage(page)
  }

  return (
    <div
      className={clsx(
        'ag-theme-alpine w-full',
        'relative',
        pagination && useCustomPaginationPanel && domLayout !== 'autoHeight'
          ? 'h-[calc(100%-100px)]'
          : 'h-full',
        hideHeader && 'hide-header',
        noMinHeight && 'no-min-height',
        rowData && rowData.length === 0 && 'no-rows',
        rowData?.length === 0 &&
          noRowsMinHeight &&
          `no-rows-min-height-${noRowsMinHeight}`,
        className
      )}
      data-testid={testId}
    >
      <AgGridReact
        defaultColDef={{
          resizable: true,
          autoHeight: true,
          suppressMovable: true,
          flex: 1,
          cellStyle: cellStyle,
          minWidth: 140,
          wrapText: true,
        }}
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        onGridSizeChanged={() => innerRef.current?.api?.sizeColumnsToFit()}
        onGridColumnsChanged={() => innerRef.current?.api?.sizeColumnsToFit()}
        onRowDataUpdated={() => {
          innerRef.current?.api?.sizeColumnsToFit()
          handleUpdatePagination()
        }}
        rowSelection={rowSelection}
        ref={innerRef}
        rowData={rowData}
        headerHeight={hideHeader ? 0 : rest.headerHeight}
        loadingOverlayComponent={AreaLoader}
        noRowsOverlayComponent={noRowsOverlayComponent ?? NoRowsOverlay}
        noRowsOverlayComponentParams={
          noRowsOverlayComponentParams ?? { message: 'No data to display' }
        }
        suppressPaginationPanel={!!useCustomPaginationPanel}
        domLayout={domLayout}
        {...rest}
      />

      {useCustomPaginationPanel && pagination && totalNumPages > 0 && (
        <CustomPaginationPanel
          page={currentPage}
          totalPages={totalNumPages}
          onClickPaginationButton={handleClickPaginationButton}
        />
      )}
    </div>
  )
}

export default Table
