import { useMutation } from '@redwoodjs/web'

import { OBSERVATION_FOR_VITAL_FRAGMENT } from 'src/pages/PatientChartsPage/PatientResults/Vitals/useVitals'

const DELETE_PATIENT_VITALS = gql`
  mutation DeletePatientVitals($input: DeletePatientVitalsInput!) {
    deletePatientVitals(input: $input) {
      id
      vitals {
        ...ObservationForVitalFragment
      }
    }
  }
  ${OBSERVATION_FOR_VITAL_FRAGMENT}
`

export const useDeletePatientVitals = () => {
  return useMutation(DELETE_PATIENT_VITALS)
}
