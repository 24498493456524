import { DocumentTypesConfig } from 'common/cdr/concepts/documents'
import type { MedicationDocumentType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const medicationDocumentTypeDisplay: {
  [key in MedicationDocumentType]: string
} = {
  ...DocumentTypesConfig['MEDICATION']['subTypes'],
}

export const medicationDocumentTypes =
  nameValueArrayFromObject<MedicationDocumentType>(
    medicationDocumentTypeDisplay
  )
