import { useRef } from 'react'

import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { formatDisplayName } from 'common/utils'
import {
  SearchPatientsForFamilyPortalAdmin,
  SearchPatientsForFamilyPortalAdminVariables,
} from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { PortalUserStatusBadge } from 'src/components/molecules/PortalUserStatusBadge/PortalUserStatusBadge'
import Table from 'src/components/molecules/Table/Table'
import { sexDisplay } from 'src/data/sexes'
import {
  formatAge,
  formatDateDisplay,
  formatPhoneNumber,
} from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'

export const SEARCH_PATIENT_QUERY = gql`
  query SearchPatientsForFamilyPortalAdmin($input: SearchPatientsInput!) {
    searchPatients(input: $input) {
      id
      mrn
      namePrefix
      givenName
      middleName
      familyName
      nameSuffix
      birthDate
      sexAtBirth
      active
      practiceComment
      contactInformation {
        id
        mobileNumber
        homeAddress {
          id
          line1
          line2
          city
          state
          postalCode
        }
      }
      primaryGuardian {
        id
        contactInformation {
          id
          homeAddress {
            id
            line1
            line2
            city
            state
            postalCode
          }
        }
      }
      patientRelatedPersonRelationships {
        id
        doesResideWith
        relationshipType
        relatedPerson {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
          contactInformation {
            id
            mobileNumber
          }
        }
      }
      portalUserStatus
      potentialDuplicatePortalUsers {
        id
      }
    }
  }
`

const defaultColDef: ColDef = {
  cellStyle: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  cellClass: 'cursor-pointer',
  resizable: true,
  autoHeight: true,
  wrapText: true,
  minWidth: 180,
}

const columnDefs: ColDef[] = [
  {
    colId: 'hasDuplicates',
    maxWidth: 50,
    cellRenderer: ({ data }) => {
      return data.potentialDuplicatePortalUsers.length ? (
        <ExclamationCircleIcon
          className="h-base-size-icon-xs w-base-size-icon-xs text-danger"
          data-testid="duplicate-indicator"
          title="This caregiver has duplicate family portal accounts"
        />
      ) : null
    },
  },
  {
    colId: 'nameAndSex',
    headerName: 'Name and sex',
    cellRenderer: ({ data }) => {
      return (
        <StackView gap={25}>
          <Typography textStyle="interface-strong-s">
            {formatDisplayName(data)}
          </Typography>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {sexDisplay[data.sexAtBirth]}
          </Typography>
        </StackView>
      )
    },
  },
  {
    colId: 'birthDate',
    headerName: 'Date of birth',
    cellRenderer: ({ data }) => {
      return (
        <StackView gap={25}>
          <Typography
            textStyle="interface-default-s"
            color="text-base-color-fg-muted"
          >
            {formatDateDisplay(data.birthDate)}
          </Typography>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {formatAge(data.birthDate)}
          </Typography>
        </StackView>
      )
    },
  },
  {
    colId: 'linkedCaregivers',
    headerName: 'Linked caregivers(s)',
    cellRenderer: ({ data }) => {
      return data.patientRelatedPersonRelationships.length
        ? data.patientRelatedPersonRelationships
            .map((relationship) =>
              formatDisplayName(relationship.relatedPerson)
            )
            .join(', ')
        : 'None'
    },
  },
  {
    colId: 'mobileNumber',
    headerName: 'Login mobile number',
    cellRenderer: ({ data }) => {
      return data.contactInformation?.mobileNumber
        ? formatPhoneNumber(data.contactInformation.mobileNumber)
        : 'N/A'
    },
  },
  {
    colId: 'status',
    headerName: 'Status',
    cellRenderer: ({ data }) => {
      return (
        <PortalUserStatusBadge
          userType="patient"
          status={data.portalUserStatus}
          testId="portal-user-status-badge"
        />
      )
    },
  },
]

export const PatientTable = () => {
  const location = useLocation()
  const params = useParams()
  const { query } = params

  const isQueryValid = query?.length >= 2

  const { data, loading } = useQuery<
    SearchPatientsForFamilyPortalAdmin,
    SearchPatientsForFamilyPortalAdminVariables
  >(SEARCH_PATIENT_QUERY, {
    variables: {
      input: {
        searchQuery: query,
      },
    },
    skip: !isQueryValid,
  })

  const gridRef = useRef<AgGridReact>()

  if (loading) return <LoadingSpinner />

  return (
    <Table
      testId="family-portal-admin-patient-table"
      innerRef={gridRef}
      rowData={data?.searchPatients ?? []}
      rowHeight={72}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      animateRows={true}
      onRowClicked={(event) =>
        navigate(
          sidepanelRoute(
            {
              route: `/family-portal-account/patient/${event.data.id}`,
              width: 'medium',
              overlay: true,
            },
            location,
            params
          )
        )
      }
    />
  )
}
