import { FaxResultCode } from '@prisma/client'
import { objectFlip } from 'common/data/utils'

export const outboundFaxErrorCodeDisplay: { [key in FaxResultCode]: string } = {
  OK: 'OK',
  FAX_PENDING: 'Fax pending',
  FAX_FAILED_4000: 'Fax failed',
  FAX_RESOURCE_ISSUE: 'Fax resource issue',
  FAX_REQUEST_BLOCKED: 'Fax request blocked',
  FAX_RENDERING_ISSUE: 'Fax rendering issue',
  FAX_CONNECT_FAILED: 'Fax connect failed',
  FAX_NUMBER_BUSY: 'Fax number busy',
  FAX_MACHINE_NOT_ANSWERING: 'Fax machine not answering',
  FAX_DETECTION_FAILED_6300: 'Fax detection failed',
  FAX_PICKUP_FAILED: 'Fax pickup failed',
  FAX_DETECTION_FAILED_6500: 'Fax detection failed',
  FAX_DETECTION_TIMEOUT: 'Fax detection timeout',
  FAX_NEGOTIATION_FAILED: 'Fax negotiation failed',
  FAX_TRAINING_FAILED: 'Fax training failed',
  FAX_TRANSMIT_FAILED: 'Fax transmit failed',
  FAX_FAILED_9000: 'Fax failed',
}

export const codeToEnum = objectFlip(outboundFaxErrorCodeDisplay)
