import { useEffect } from 'react'

import clsx from 'clsx'
import { isBefore } from 'date-fns'
import { parse } from 'date-fns'

import {
  get,
  RegisterOptions,
  useFormContext,
  useWatch,
} from '@redwoodjs/forms'

import StackView from '../../atoms/StackView/StackView'
import Typography from '../../atoms/Typography/Typography'
import { DatePickerField, UnregisterProps } from '../DatePicker/DatePicker'

interface TimeIntervalSelectProps extends UnregisterProps {
  id: string
  intervalType?: 'calendar' | 'time'
  leftPickerRules?: Omit<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'pattern'
  >
  rightPickerRules?: Omit<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'pattern'
  >
  rules?: Omit<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'pattern'
  >
  name: string
  label?: string
  maxTime?: string
  minTime?: string
  stepMinute?: number
  defaultValueStart?: string
  defaultValueEnd?: string
  space?: 0 | 25 | 50 | 75 | 100 | 125 | 150 | 175 | 200 | 2400
  onStartChange?: (start: string) => void
  onEndChange?: (end: string) => void
}

export const TimeIntervalSelect = ({
  name,
  intervalType = 'calendar',
  label,
  rules = {},
  leftPickerRules = {},
  rightPickerRules = {},
  id,
  maxTime,
  minTime,
  stepMinute = 15,
  defaultValueStart,
  defaultValueEnd,
  space = 100,
  onStartChange,
  onEndChange,
  keepDefaultValue,
  keepDirtyValues,
}: TimeIntervalSelectProps) => {
  const end = useWatch({
    name: name + '.end',
  })
  const start = useWatch({
    name: name + '.start',
  })
  useEffect(() => {
    if (onStartChange) onStartChange(start)
  }, [start, onStartChange])
  useEffect(() => {
    if (onEndChange) onEndChange(end)
  }, [end, onEndChange])
  // validations on timeInterval are based on the start date
  // this is to add the styles to the end input based on start error state
  const {
    formState: { errors },
  } = useFormContext()
  const hasError = Boolean(get(errors, name + '.start', false))
  return (
    <StackView
      direction="row"
      justifyContent="between"
      alignItems="start"
      space={space}
    >
      <DatePickerField
        mode="date"
        keepDefaultValue={keepDefaultValue}
        keepDirtyValues={keepDirtyValues}
        datePickerContainerProps={{ flex: '6/12' }}
        name={`${name}.start`}
        label={label}
        controls={[intervalType]}
        anchorId={id}
        validation={{
          ...leftPickerRules,
          shouldUnregister: true,
          validate: {
            isBefore: (value) => {
              // if both are empty we don't care about any validation.
              if ((!rules.required && !value && end) || (value && !end)) {
                if (!value) {
                  return 'This field is required'
                }
                return 'Please fill out the other field'
              }
              if (!rules.required && !value && !end) {
                return true
              }
              if (rules.required && !value) {
                return 'This field is required'
              }
              if (rules.required && !end) {
                return 'Please fill out the other field'
              }
              const parsedStart = parse(value, 'h:mm a', new Date())
              const parsedEnd = parse(end, 'h:mm a', new Date())
              const before = isBefore(parsedStart, parsedEnd)
              const endIsNextDay = end === '12:00 AM'
              if (endIsNextDay) {
                return true
              }
              const errorMessage =
                intervalType === 'time'
                  ? 'The start time should be before the end time'
                  : 'The start date should be before the end date'
              return before || errorMessage
            },
          },
        }}
        maxTime={maxTime}
        minTime={minTime}
        stepMinute={stepMinute}
        defaultValue={defaultValueStart}
      />
      <Typography className={label ? 'mt-8' : 'mt-2'}>to</Typography>
      <DatePickerField
        mode="date"
        anchorId={id}
        keepDirtyValues={keepDirtyValues}
        keepDefaultValue={keepDefaultValue}
        datePickerContainerProps={{
          className: clsx(
            !!label && 'mt-6',
            hasError && 'develo-datepicker-error'
          ),
          flex: '6/12',
        }}
        name={`${name}.end`}
        controls={[intervalType]}
        validation={{
          shouldUnregister: true,
          ...rules,
          ...rightPickerRules,
        }}
        helpText={
          end === '12:00 AM'
            ? 'This is the beginning of the following day'
            : undefined
        }
        maxTime={maxTime}
        minTime={minTime}
        stepMinute={stepMinute}
        defaultValue={defaultValueEnd}
      />
    </StackView>
  )
}
