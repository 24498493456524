import StackView from 'src/components/atoms/StackView/StackView'

export const PatientPortalMediumWidthContent = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <StackView className="max-w-[600px] p-0 md:p-core-space-150">
      {children}
    </StackView>
  )
}
