import { useEffect, useState } from 'react'

import { XMarkIcon } from '@heroicons/react/24/solid'
import { format, isAfter, isBefore, isValid, parseISO } from 'date-fns'
import {
  GetPatientForImmunizations,
  CreateImmunizationsMutation,
  CreateImmunizationsInput,
} from 'types/graphql'

import {
  get,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from '@redwoodjs/forms'
import { useParams } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import VaccineCVXSelectField from 'src/components/atoms/VaccineSelectField/VaccineCVXSelectField'
import { FormInputList } from 'src/components/molecules/FormInputList'
import MultiSelectComboBoxFieldArray from 'src/components/molecules/MultiSelectComboBoxFieldArray/MultiSelectComboBoxFieldArray'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { useEffectOnce } from 'src/utils'

const GET_PATIENT_IMMUNIZATIONS = gql`
  query GetPatientImmunizationsAdd($id: String!) {
    patient(id: $id) {
      id
      birthDate
      immunizations {
        occurredAt
      }
    }
  }
`

const CREATE_IMMUNIZATIONS = gql`
  mutation CreateImmunizationsMutation($input: CreateImmunizationsInput!) {
    createImmunizations(input: $input) {
      id
    }
  }
`

const ImmunizationBox = ({
  index,
  onRemoveImmunization,
  dateOptions = [],
  onDateOptionsUpdated,
}) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext()
  const cvxValue = useWatch({
    name: `immunizationsToAdd.${index}.cvx`,
  })

  return (
    <Box
      color="bg-base-color-bg-subtle"
      rounded={true}
      padding={50}
      className="my-2"
    >
      <StackView direction="row" space={75}>
        <FormInputList
          className="w-full"
          items={[
            {
              name: `immunizationsToAdd.${index}.cvx`,
              label: 'Vaccine name',
              required: true,
              formInputComponent: VaccineCVXSelectField,
              direction: 'col',
              inputProps: {
                onChange: (value) =>
                  setValue(`immunizationsToAdd.${index}.cvx`, value),
                value: cvxValue,
                hasError: !!get(errors, `immunizationsToAdd.${index}.cvx`),
                required: true,
              },
            },
          ]}
        />
        <FormInputList
          className="w-52"
          items={[
            {
              name: `immunizationsToAdd.${index}.doseDates`,
              label: 'Dose date',
              required: true,
              formInputComponent: MultiSelectComboBoxFieldArray,
              direction: 'col',
              inputProps: {
                options: dateOptions.map((date) => {
                  return {
                    name: date,
                    value: date,
                  }
                }),
                addSelectionText: 'Add dose date',
                allowAddingNewOptions: true,
                onOptionsUpdated: (options) => onDateOptionsUpdated(options),
                minimumValueLength: 0,
              },
            },
          ]}
        />
        <Button
          testId="cancel-adding-immunization"
          buttonStyle="ghost"
          onClick={() => onRemoveImmunization(index)}
        >
          <XMarkIcon className="h-4 w-4" />
        </Button>
      </StackView>
    </Box>
  )
}

const SidepanelImmunizationHistoryAdd = () => {
  const { closeSidePanel } = useSidepanel()

  const { id: patientId } = useParams()
  const [dateOptions, setDateOptions] = useState([])

  const {
    data: patientImmunizationData,
    loading: patientImmunizationsLoading,
  } = useQuery<GetPatientForImmunizations>(GET_PATIENT_IMMUNIZATIONS, {
    variables: {
      id: patientId,
    },
  })

  const [createImmunizations, { loading: creatingImmunizations }] =
    useMutation<CreateImmunizationsMutation>(CREATE_IMMUNIZATIONS, {
      onCompleted: () => {
        toast.success(`Immunizations added!`)
        closeSidePanel()
      },
      refetchQueries: ['GetPatientForImmunizations'],
    })

  const formMethods = useForm<CreateImmunizationsInput>()
  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: 'immunizationsToAdd',
  })

  // Create a set of unique dates based off of past dates that have administered immunizations
  useEffect(() => {
    const previousImmunizationDates = [
      ...new Set(
        patientImmunizationData?.patient?.immunizations.map((i) => {
          return format(parseISO(i.occurredAt), 'M/d/yy')
        })
      ),
    ]
    setDateOptions(previousImmunizationDates)
  }, [patientImmunizationData])

  // Add first record on page load
  useEffectOnce(() => {
    append({
      cvx: '',
      doseDates: [],
    })
  })

  // When date options are updated within a Combobox, we want to update the available dates within all date Comboboxes,
  // but only if the date supplied can be converted into an actual date and the date is between the patients
  // birthday and today
  const onDateOptionsUpdated = (options) => {
    const shouldUpdateOptions = options.reduce((acc, option) => {
      if (
        acc === true &&
        (!isValid(new Date(option.value)) ||
          isBefore(
            new Date(option.value),
            parseISO(patientImmunizationData.patient.birthDate)
          ) ||
          isAfter(new Date(option.value), new Date()))
      ) {
        acc = false
        return acc
      }
      return true
    }, true)
    if (shouldUpdateOptions) {
      setDateOptions(options.map((o) => o.value))
    } else {
      toast.error('The date you input is invalid')
    }
  }

  const onSubmit = (data: CreateImmunizationsInput) => {
    const input: CreateImmunizationsInput = {
      patientId,
      immunizationsToAdd: data.immunizationsToAdd.map((immunization) => {
        return {
          cvx: immunization.cvx,
          doseDates: immunization.doseDates.filter(Boolean),
        }
      }),
    }
    void createImmunizations({ variables: { input } })
  }

  if (patientImmunizationsLoading) return <LoadingSpinner />

  return (
    <SidepanelPage
      header="Add external immunization record"
      description="Add external vaccines and their corresponding dose dates. Select Save and close to store updates to the patient’s immunization record. Vaccines administered at your practice would be separately documented within the visit workflow."
    >
      <SidepanelForm
        footerProps={{
          cancelText: 'Discard changes',
          submitText: 'Save and close',
          submitting: creatingImmunizations,
        }}
        config={{ mode: 'onBlur' }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        {fields.map((immunizationField, index) => (
          <ImmunizationBox
            key={immunizationField.id}
            index={index}
            onRemoveImmunization={(index) => remove(index)}
            dateOptions={dateOptions}
            onDateOptionsUpdated={(options) => onDateOptionsUpdated(options)}
          />
        ))}

        <Button
          buttonStyle="secondary"
          className="my-4"
          onClick={() =>
            append({
              cvx: '',
              doseDates: [],
            })
          }
        >
          Add Record
        </Button>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelImmunizationHistoryAdd
