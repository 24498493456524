import { tobaccoSmokelessStatusConceptCodings } from 'common/cdr/concepts/observations/socialHistories/index'
import type { TobaccoSmokelessStatus } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const tobaccoSmokelessStatusDisplay: {
  [key in TobaccoSmokelessStatus]: string
} = {
  NEVER_CHEWED: 'Never chewed tobacco',
  DOES_NOT_CHEW: 'Does not chew tobacco',
  FORMER_TOBACCO_CHEWER: 'Former tobacco chewer',
  TOBACCO_CHEWER: 'Chews tobacco',
  CHEWS_PLUG_TOBACCO: 'Chews plug tobacco',
  CHEWS_TWIST_TOBACCO: 'Chews twist tobacco',
  CHEWS_LOOSELEAF_TOBACCO: 'Chews loose leaf tobacco',
  CHEWS_FINECUT_TOBACCO: 'Chews fine cut tobacco',
  CHEWS_PRODUCTS_CONTAINING_TOBACCO: 'Chews products containing tobacco',
}

export const tobaccoSmokelessStatusDisplayToEnum = Object.keys(
  tobaccoSmokelessStatusDisplay
).reduce((acc, key) => {
  acc[tobaccoSmokelessStatusDisplay[key]] = key
  return acc
}, {})

export const tobaccoSmokelessStatusCodeToEnum =
  tobaccoSmokelessStatusConceptCodings.reduce((acc, coding) => {
    if (tobaccoSmokelessStatusDisplayToEnum[coding.display]) {
      acc[coding.code] = tobaccoSmokelessStatusDisplayToEnum[coding.display]
    }
    return acc
  }, {})

export const tobaccoSmokelessStatuses =
  nameValueArrayFromObject<TobaccoSmokelessStatus>(
    tobaccoSmokelessStatusDisplay
  )
