import { AllergyIntoleranceSeverity } from 'types/graphql'

import { BadgeColor } from 'src/components/atoms/Badge/Badge'

import { nameValueArrayFromObject } from './utils'

export const allergySeverityDisplay: {
  [key in AllergyIntoleranceSeverity]: string
} = {
  mild: 'Mild',
  moderate: 'Moderate',
  severe: 'Severe',
}

export const allergySeverityBadgeColor: {
  [key in AllergyIntoleranceSeverity]: BadgeColor
} = {
  mild: 'yellow',
  moderate: 'orange',
  severe: 'red',
}

export const allergySeverities =
  nameValueArrayFromObject<AllergyIntoleranceSeverity>(allergySeverityDisplay)
