import { DocumentCaseDocumentSubType } from 'types/graphql'

import { clinicalResultTypeDisplay } from 'src/data/clinicalResultTypes'
import { externalRequestTypeDisplay } from 'src/data/externalRequestTypes'
import { medicalRecordTypeDisplay } from 'src/data/medicalRecordTypes'
import { medicationDocumentTypeDisplay } from 'src/data/medicationDocumentTypes'
import { patientCaregiverInformationDocumentTypeDisplay } from 'src/data/patientCaregiverInformationDocumentTypes'
import { patientDocumentSubTypeDisplay } from 'src/data/patientDocumentSubTypes'
import { patientEducationDocumentTypeDisplay } from 'src/data/patientEducationDocumentTypes'
import { patientFormTypeDisplay } from 'src/data/patientFormTypes'
import { patientOrderDocumentTypeDisplay } from 'src/data/patientOrderDocumentTypes'
import { practiceAdminDocumentTypeDisplay } from 'src/data/practiceAdminDocumentTypes'
import { practiceFormTypeDisplay } from 'src/data/practiceformTypes'
import { practiceLetterTypeDisplay } from 'src/data/practiceLetterTypes'
import { screeningToolTypeDisplay } from 'src/data/screeningToolTypes'

import { nameValueArrayFromObject } from './utils'

export const documentCaseDocumentSubtypeDisplay: {
  [key in DocumentCaseDocumentSubType]: string
} = {
  ...patientDocumentSubTypeDisplay,
  ...patientEducationDocumentTypeDisplay,
  ...practiceAdminDocumentTypeDisplay,
  ...practiceFormTypeDisplay,
  ...patientFormTypeDisplay,
  ...externalRequestTypeDisplay,
  ...screeningToolTypeDisplay,
  ...practiceLetterTypeDisplay,
  ...clinicalResultTypeDisplay,
  ...medicalRecordTypeDisplay,
  ...medicationDocumentTypeDisplay,
  ...patientCaregiverInformationDocumentTypeDisplay,
  ...patientOrderDocumentTypeDisplay,
}

export const documentCaseDocumentSubTypes =
  nameValueArrayFromObject<DocumentCaseDocumentSubType>(
    documentCaseDocumentSubtypeDisplay
  )
