import { useEffect, useState } from 'react'

import { TrashIcon } from '@heroicons/react/24/outline'
import {
  compareDesc,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  parseISO,
} from 'date-fns'
import groupBy from 'lodash/groupBy'
import uniq from 'lodash/uniq'
import { GetPatientImmunizationsEdit } from 'types/graphql'

import { useFieldArray, useForm, useFormContext } from '@redwoodjs/forms'
import { useParams } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import { FormInputList } from 'src/components/molecules/FormInputList'
import MultiSelectComboBoxFieldArray from 'src/components/molecules/MultiSelectComboBoxFieldArray/MultiSelectComboBoxFieldArray'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const GET_PATIENT_IMMUNIZATIONS = gql`
  query GetPatientImmunizationsEdit($id: String!) {
    patient(id: $id) {
      id
      birthDate
      immunizations {
        externalId
        code {
          code
          display
          system
        }
        occurredAt
        develoVaccineName
        possibleBrands
        administeredWithDevelo
      }
    }
  }
`

const EDIT_IMMUNIZATIONS = gql`
  mutation EditImmunizationsMutation($input: EditImmunizationsInput!) {
    editImmunizations(input: $input) {
      id
    }
  }
`

const ImmunizationBox = ({
  immunizationsField,
  immunizations,
  index,
  onRemoveImmunizationsWithCvx,
  dateOptions = [],
  onDateOptionsUpdated,
  onRemoveSingleImmunization,
  onUpdateImmunizationsToAdd,
  immunizationsToRemove,
}) => {
  const formContext = useFormContext()
  const onRemoveSelectedOption = (option) => {
    const cvxImmunizations = immunizations.filter(
      (i) => i.code.code === immunizationsField.cvx
    )
    const sameDayImmunization = cvxImmunizations.find(
      (i) =>
        isSameDay(parseISO(i.occurredAt), new Date(option)) &&
        !immunizationsToRemove.includes(i.externalId)
    )

    onRemoveSingleImmunization(sameDayImmunization?.externalId, {
      option,
      cvx: immunizationsField.cvx,
    })
  }

  const onAddSelectedOption = (option) => {
    const values = formContext.getValues(`editImmunizations.${index}.doseDates`)
    values.pop()
    formContext.setValue(`editImmunizations.${index}.doseDates`, [
      ...values,
      option,
    ])
    onUpdateImmunizationsToAdd({
      cvx: immunizationsField.cvx,
      doseDate: option,
    })
  }

  const immunization = immunizations.find(
    (i) => i.code.code === immunizationsField.cvx
  )

  return (
    <Box
      color="bg-base-color-bg-subtle"
      rounded={true}
      padding={50}
      className="my-2"
    >
      <StackView direction="row" space={75}>
        <FormInputList
          items={[
            {
              name: `editImmunizations.${index}.doseDates`,
              label: immunization.develoVaccineName,
              subtitle: immunization.possibleBrands?.join(' \u2022 '),
              direction: 'col',
              formInputComponent: MultiSelectComboBoxFieldArray,
              inputProps: {
                options: dateOptions.map((date) => {
                  return {
                    name: date,
                    value: date,
                  }
                }),
                addSelectionText: 'Add dose date',
                allowAddingNewOptions: true,
                onOptionsUpdated: (options) => onDateOptionsUpdated(options),
                onRemoveSelectedOption: (option) =>
                  onRemoveSelectedOption(option),
                cannotEditExistingValues: true,
                onChange: (option) => onAddSelectedOption(option),
              },
            },
          ]}
        />
        <Button
          testId="cancel-adding-immunization"
          buttonStyle="ghost"
          onClick={() => onRemoveImmunizationsWithCvx(immunizationsField.cvx)}
        >
          <TrashIcon className="h-4 w-4" />
        </Button>
      </StackView>
    </Box>
  )
}

const SidepanelImmunizationHistoryEdit = () => {
  const { closeSidePanel } = useSidepanel()

  const { id: patientId } = useParams()
  const [dateOptions, setDateOptions] = useState([])
  const [immunizationsToRemove, setImmunizationsToRemove] = useState([])
  const [immunizationsToAdd, setImmunizationsToAdd] = useState([])

  const {
    data: patientImmunizationData,
    loading: patientImmunizationsLoading,
  } = useQuery<GetPatientImmunizationsEdit>(GET_PATIENT_IMMUNIZATIONS, {
    variables: {
      id: patientId,
    },
  })

  const [editImmunizations, { loading: editingImmunizations }] = useMutation(
    EDIT_IMMUNIZATIONS,
    {
      onCompleted: () => {
        toast.success(`Immunizations edited`)
        closeSidePanel()
      },
      refetchQueries: ['GetPatientForImmunizations'],
    }
  )

  const formMethods = useForm()
  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: 'editImmunizations',
  })

  // Create a set of unique dates based off of past dates that have administered immunizations
  useEffect(() => {
    const previousImmunizationDates = [
      ...new Set(
        patientImmunizationData?.patient?.immunizations.map((i) => {
          return format(parseISO(i.occurredAt), 'M/d/yy')
        })
      ),
    ]
    setDateOptions(previousImmunizationDates)
  }, [patientImmunizationData])

  useEffect(() => {
    // If an immunization has been administered with develo, don't allow users to
    // edit/delete from this view
    const data = patientImmunizationData?.patient.immunizations.filter(
      (i) => !i.administeredWithDevelo
    )
    const groupedImmunizations = groupBy(
      data,
      (immunization) => immunization.code.code
    )

    const sortedCvxCodes = Object.keys(groupedImmunizations).sort((a, b) => {
      return compareDesc(
        groupedImmunizations[a]
          .map((x) => parseISO(x.occurredAt))
          .sort(compareDesc)[0],
        groupedImmunizations[b]
          .map((x) => parseISO(x.occurredAt))
          .sort(compareDesc)[0]
      )
    })

    sortedCvxCodes.map((cvx) => {
      if (!fields.find((x) => x['cvx'] === cvx)) {
        append({
          cvx: cvx,
          doseDates: groupedImmunizations[cvx]
            .sort((a, b) =>
              compareDesc(parseISO(a.occurredAt), parseISO(b.occurredAt))
            )
            .map((i) => format(parseISO(i.occurredAt), 'M/d/yy')),
        })
      }
    })
    // fields will re-render to infinity
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [append, patientImmunizationData])

  // When date options are updated within a Combobox, we want to update the available dates within all date Comboboxes,
  // but only if the date supplied can be converted into an actual date and the date is between the patients
  // birthday and today
  const onDateOptionsUpdated = (options) => {
    const shouldUpdateOptions = options.reduce((acc, option) => {
      if (
        acc === true &&
        (!isValid(new Date(option.value)) ||
          isBefore(
            new Date(option.value),
            parseISO(patientImmunizationData.patient.birthDate)
          ) ||
          isAfter(new Date(option.value), new Date()))
      ) {
        acc = false
        return acc
      }
      return true
    }, true)
    if (shouldUpdateOptions) {
      setDateOptions(options.map((o) => o.value))
    } else {
      toast.error('The date you input is invalid')
    }
  }

  // When removing immunizations, we want to keep track of all immunizations that we have removed of this cvx
  const onRemoveImmunizationsWithCvx = (cvx: string) => {
    const index = fields.findIndex((f) => f['cvx'] === cvx)
    remove(index)
    const immunizationsWithCvx = patientImmunizationData.patient.immunizations
      .filter((i) => i.code.code === cvx && !i.administeredWithDevelo)
      .map((i) => i['externalId'])

    setImmunizationsToRemove(
      uniq([...immunizationsToRemove, ...immunizationsWithCvx])
    )
  }

  const onRemoveSingleImmunization = (id: string, option) => {
    if (id) {
      setImmunizationsToRemove([...immunizationsToRemove, id])
    } else if (option) {
      setImmunizationsToAdd(
        immunizationsToAdd.filter(
          (x) => x.option !== option && x.cvx !== option.cvx
        )
      )
    }
  }

  const onSubmit = () => {
    const input = {
      patientId,
      immunizationsToRemove,
      immunizationsToAdd: immunizationsToAdd.filter(Boolean),
    }
    void editImmunizations({ variables: { input } })
  }

  if (patientImmunizationsLoading) return <LoadingSpinner />

  return (
    <SidepanelPage
      header="Edit external immunization record"
      description="Edit external vaccines and their corresponding dose dates where needed. Select Save and close to store updates to the patient’s immunization record. Vaccines administered at your practice would be separately documented, and can be accordingly edited within the visit workflow."
    >
      <SidepanelForm
        footerProps={{
          cancelText: 'Discard changes',
          submitText: 'Save and close',
          submitting: editingImmunizations,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        {fields.map((immunizationsField, index) => (
          <ImmunizationBox
            key={immunizationsField.id}
            immunizations={patientImmunizationData.patient.immunizations}
            index={index}
            immunizationsField={immunizationsField}
            onRemoveImmunizationsWithCvx={(cvx) =>
              onRemoveImmunizationsWithCvx(cvx)
            }
            dateOptions={dateOptions}
            onDateOptionsUpdated={(options) => onDateOptionsUpdated(options)}
            onRemoveSingleImmunization={(id, option) =>
              onRemoveSingleImmunization(id, option)
            }
            onUpdateImmunizationsToAdd={(immunization) =>
              setImmunizationsToAdd([...immunizationsToAdd, immunization])
            }
            immunizationsToRemove={immunizationsToRemove}
          />
        ))}
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelImmunizationHistoryEdit
