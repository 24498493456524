import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import LineChartLegendIcon from 'src/components/icons/LineChartLegendIcon/LineChartLegendIcon'
import LineChartLegendSquareIcon from 'src/components/icons/LineChartLegendSquareIcon/LineChartLegendSquareIcon'

import { GrowthChartDatasetType } from '../PatientGrowthCharts'

import GrowthChart from './GrowthChart'
import { GrowthChartProps } from './GrowthChart'
import GrowthTable, { GrowthTableProps } from './GrowthTable'

type ChartSectionProps = {
  title: string
  testId: string
  hideTables: boolean
  dataset: GrowthChartDatasetType
  isPdf: boolean
} & GrowthChartProps &
  GrowthTableProps

export const chartColors = {
  red: '#DC2626',
  redLight: '#FEE2E2',
  orange: '#FB923C',
  orangeLight: '#fdd3b1',
  yellow: '#FBBF24',
  yellowLight: '#F8D686',
  greenLight: '#C7F7DF',
  green: '#A7F3D0',
  greenDark: '#059669',
  black: '#000000',
  pink: '#BE185D',
  white: '#FFFFFF',
  blue: '#2563eb',
  lightBlue: '#93c5fd',
  magenta: '#ec4899',
  lightMagenta: '#f9a8d4',
}

const ChartSection = ({
  patient,
  chartData,
  title,
  testId,
  hideTables,
  showCorrectedAge,
  xAxisLabel,
  yAxisLabel,
  yAxisUnit,
  xMin = 'auto',
  xMax = 'auto',
  yMin = 'auto',
  yMax = 'auto',
  lineColors = [
    chartColors.red,
    chartColors.orange,
    chartColors.orange,
    chartColors.orange,
    chartColors.greenDark,
    chartColors.orange,
    chartColors.orange,
    chartColors.orange,
    chartColors.red,
    'transparent',
    'transparent',
  ],
  areaLayers = [
    {
      bottom: 'bottomAxis',
      top: 'bottomLine',
      color: chartColors.red,
      fillOpacity: 0.15,
    },
    {
      bottom: 'bottomLine',
      top: 'p10',
      color: chartColors.orange,
      fillOpacity: 0.5,
    },
    {
      bottom: 'p10',
      top: 'p25',
      color: chartColors.yellow,
      fillOpacity: 0.5,
    },
    { bottom: 'p25', top: 'p75', color: chartColors.green, fillOpacity: 0.5 },
    {
      bottom: 'p75',
      top: 'p90',
      color: chartColors.yellow,
      fillOpacity: 0.5,
    },
    {
      bottom: 'p90',
      top: 'topLine',
      color: chartColors.orange,
      fillOpacity: 0.5,
    },
    {
      bottom: 'topLine',
      top: 'topAxis',
      color: chartColors.red,
      fillOpacity: 0.15,
    },
  ],
  percentileLabels = [
    {
      dataKey: 'P2',
      alternateDataKey: 'P3',
      circleFill: chartColors.redLight,
      circleStroke: chartColors.red,
      labelFill: chartColors.red,
    },
    {
      dataKey: 'P5',
      circleFill: chartColors.orangeLight,
      circleStroke: chartColors.orange,
    },
    {
      dataKey: 'P10',
    },
    {
      dataKey: 'P25',
    },
    {
      dataKey: 'P50',
      circleFill: chartColors.greenLight,
      circleStroke: chartColors.greenDark,
      labelFill: chartColors.greenDark,
    },
    {
      dataKey: 'P75',
    },
    {
      dataKey: 'P85',
    },
    {
      dataKey: 'P90',
    },
    {
      dataKey: 'P95',
      circleFill: chartColors.orangeLight,
      circleStroke: chartColors.orange,
    },
    {
      dataKey: 'P98',
      alternateDataKey: 'P97',
      circleFill: chartColors.redLight,
      circleStroke: chartColors.red,
      labelFill: chartColors.red,
    },
  ],
  percentileLabelDistance = 4,
  todayLineX,
  tableRows,
  tableColorThreshold = [
    {
      range: {
        start: 0,
        end: 3,
      },
      color: chartColors.redLight,
    },
    {
      range: {
        start: 3,
        end: 10,
      },
      color: chartColors.orangeLight,
    },
    {
      range: {
        start: 10,
        end: 25,
      },
      color: chartColors.yellowLight,
    },
    {
      range: {
        start: 25,
        end: 75,
      },
      color: chartColors.greenLight,
    },
    {
      range: {
        start: 75,
        end: 90,
      },
      color: chartColors.yellowLight,
    },
    {
      range: {
        start: 90,
        end: 97,
      },
      color: chartColors.orangeLight,
    },
    {
      range: {
        start: 97,
        end: 100,
      },
      color: chartColors.redLight,
    },
  ],
  isInfantData,
  isWeightForHeightData = false,
  hasConnectedPoints = false,
  dataset,
  growthChartHeight,
  onGrowthChartFinishedRendering,
  isPdf,
}: ChartSectionProps) => {
  if (!chartData) return null

  if (dataset.includes('FEMALE')) {
    areaLayers[0].color = chartColors.magenta
    areaLayers[areaLayers.length - 1].color = chartColors.magenta
    tableColorThreshold[0].color = chartColors.lightMagenta
    tableColorThreshold[tableColorThreshold.length - 1].color =
      chartColors.lightMagenta
  } else if (dataset.includes('MALE')) {
    areaLayers[0].color = chartColors.lightBlue
    areaLayers[areaLayers.length - 1].color = chartColors.lightBlue
    tableColorThreshold[0].color = chartColors.lightBlue
    tableColorThreshold[tableColorThreshold.length - 1].color =
      chartColors.lightBlue
  }

  return (
    <div data-testid={testId}>
      <Typography textStyle="title">{title}</Typography>
      {showCorrectedAge && (
        <StackView
          space={25}
          direction="row"
          alignItems={isPdf ? 'end' : 'center'}
        >
          <Typography color="text-base-color-fg-muted">
            Chronological Age
          </Typography>
          <LineChartLegendIcon className="h-core-size-50 text-black" />
          <Typography color="text-base-color-fg-muted">
            Corrected Age
          </Typography>
          <LineChartLegendSquareIcon className="h-core-size-50 text-blue-600" />
        </StackView>
      )}
      <GrowthChart
        patient={patient}
        chartData={chartData}
        showCorrectedAge={showCorrectedAge}
        xAxisLabel={xAxisLabel}
        yAxisLabel={yAxisLabel}
        yAxisUnit={yAxisUnit}
        xMin={xMin}
        xMax={xMax}
        yMin={yMin}
        yMax={yMax}
        lineColors={lineColors}
        areaLayers={areaLayers}
        percentileLabels={percentileLabels}
        percentileLabelDistance={percentileLabelDistance}
        todayLineX={todayLineX}
        isInfantData={isInfantData}
        isWeightForHeightData={isWeightForHeightData}
        hasConnectedPoints={hasConnectedPoints}
        growthChartHeight={growthChartHeight}
        onGrowthChartFinishedRendering={onGrowthChartFinishedRendering}
      />

      {!hideTables && (
        <>
          <GrowthTable
            testId={`${testId}-chronological-table`}
            showCorrectedAge={showCorrectedAge}
            label="Chronological Age"
            patient={patient}
            tableRows={tableRows}
            tableColorThreshold={tableColorThreshold}
            isInfantData={isInfantData}
            isWeightForHeightData={isWeightForHeightData}
          />

          {showCorrectedAge && (
            <GrowthTable
              testId={`${testId}-corrected-table`}
              showCorrectedAge={showCorrectedAge}
              label="Corrected Age"
              patient={patient}
              tableRows={tableRows}
              tableColorThreshold={tableColorThreshold}
              isInfantData={isInfantData}
              isWeightForHeightData={isWeightForHeightData}
              isCorrectedAgeData={true}
            />
          )}
        </>
      )}
    </div>
  )
}

export default ChartSection
