import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { formatPhoneNumber } from 'react-phone-number-input'
import { PatientGuardianDetailsFragment } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

import { LabeledValue } from './LabeledValue'

export const RelatedPersonDetails = ({
  relationship,
}: {
  relationship: PatientGuardianDetailsFragment['patientRelatedPersonRelationships'][0]
}) => {
  const { contactInformation, ...relatedPerson } = relationship.relatedPerson

  return (
    <StackView className="w-1/2" gap={100}>
      <Typography textStyle="interface-default-l">
        {formatDisplayName(relatedPerson)}
      </Typography>
      <StackView>
        <LabeledValue
          label="Relation"
          value={relationshipTypeDisplay[relationship.relationshipType]}
        />
        <LabeledValue
          label="Mobile"
          value={formatPhoneNumber(contactInformation.mobileNumber)}
        />
        <LabeledValue
          label="Home"
          value={formatPhoneNumber(contactInformation.homeNumber)}
        />
        <LabeledValue label="Email" value={contactInformation.emailAddress} />
      </StackView>
    </StackView>
  )
}
