import { ArrowUturnRightIcon } from '@heroicons/react/20/solid'
import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import { TaskUserPool, User } from 'types/graphql'

import Box from 'src/components/atoms/Box/Box'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

interface RoutedToItemProps {
  createdBy: Partial<User>
  toUser?: Partial<User>
  toUserPool?: string
  toTaskUserPool?: Partial<TaskUserPool>
  createdAt: string
}

const RoutedToItem = ({
  createdBy,
  toUser,
  toTaskUserPool,
  createdAt,
}: RoutedToItemProps) => {
  return (
    <Box horizontalPadding={50} verticalPadding={75}>
      <StackView direction="row" space={75} alignItems="start">
        <Box flex={'1/12'}>
          <Box
            className="flex h-8 w-8 items-center justify-center rounded-full"
            color="bg-base-color-bg-subtle"
          >
            <ArrowUturnRightIcon className="h-5 w-5 text-gray-500" />
          </Box>
        </Box>
        <Box grow>
          <StackView space={25}>
            <StackView
              space={50}
              direction="row"
              alignItems="center"
              justifyContent="between"
            >
              <Typography>Task routed</Typography>
              <Typography color="text-base-color-fg-muted" size="s">
                {format(parseISO(createdAt), "MMM d, yyyy 'at' h:mm a")}
              </Typography>
            </StackView>
            <Typography color="text-base-color-fg-muted">
              {`Task routed to ${
                toUser ? formatDisplayName(toUser) : toTaskUserPool.displayName
              }`}
            </Typography>
            <Typography>{formatDisplayName(createdBy)}</Typography>
          </StackView>
        </Box>
      </StackView>
    </Box>
  )
}

export default RoutedToItem
