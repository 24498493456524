import { useEffect, useState } from 'react'

import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { useParams } from 'react-router-dom'
import { Allergen, UpsertAllergyInput, FindAllergens } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'

import AllergyManifestationsSelectField from 'src/components/atoms/AllergyManifestationSelectField/AllergyManifestationSelectField'
import AllergySeveritySelectField from 'src/components/atoms/AllergySeveritySelectField/AllergySeveritySelectField'
import AllergyStatusSelectField from 'src/components/atoms/AllergyStatusSelectField/AllergyStatusSelectField'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import QueryBackedSearchInputField from 'src/components/molecules/QueryBackedSearchInputField/QueryBackedSearchInputField'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { FRAGMENT as ALLERGEN_FRAGMENT } from 'src/pages/PatientChartsPage/PatientHistory/Allergies/fragments'
import {
  useAllergiesQuery,
  useSetNoKnownAllergies,
  useUpsertAllergy,
} from 'src/pages/PatientChartsPage/PatientHistory/Allergies/useAllergies'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const SEARCH_ALLERGENS_QUERY = gql`
  query FindAllergens($input: AllergenSearchInput) {
    allergens(input: $input) {
      ...AllergenFragment
    }
  }
  ${ALLERGEN_FRAGMENT}
`

type FormState = UpsertAllergyInput & {
  allergen: Allergen
  noKnownAllergies: boolean
}

const SidepanelAllergyCreate = () => {
  const [currentExistingAllergen, setCurrentExistingAllergen] =
    useState<Allergen | null>()
  const { patientId } = useParams()
  const { closeSidePanel } = useSidepanel()

  const { allergies, hasNoKnownAllergies } = useAllergiesQuery(patientId)
  const [upsertAllergy, { loading: upsertingAllergy }] = useUpsertAllergy()
  const [setNoKnownAllergies, { loading: settingNoKnownAllergies }] =
    useSetNoKnownAllergies()

  const formMethods = useForm<FormState>({
    defaultValues: {
      noKnownAllergies: hasNoKnownAllergies,
    },
  })

  const onSubmit = async (data: FormState) => {
    if (data.noKnownAllergies) {
      setNoKnownAllergies({
        variables: { patientId },
        onCompleted: closeSidePanel,
      })
    } else {
      const input = {
        patientId,
        allergenCode: data.allergen.code,
        system: data.allergen.system,
        name: data.allergen.name,
        doseSpotGenericDrugCode: data.allergen.doseSpotGenericDrugCode,
        ...omit(data, ['allergen', 'allergenDisplayText', 'noKnownAllergies']),
      }
      upsertAllergy({ variables: { input }, onCompleted: closeSidePanel })
    }
  }

  const noKnownAllergies = formMethods.watch('noKnownAllergies')

  // watch for udpates to the allergen field, if it's an existing allergen
  // then update the form with the existing values
  useEffect(() => {
    const { unsubscribe } = formMethods.watch(
      ({ allergen, status, severity, manifestation }) => {
        if (isEmpty(allergies) || isEmpty(allergen)) return

        const existingAllergy = allergies.find(
          (allergy) =>
            allergy.allergen.code === allergen.code &&
            allergy.allergen.system === allergen.system
        )

        if (!existingAllergy) {
          setCurrentExistingAllergen(null)
          return
        }

        if (
          existingAllergy.allergen.code !== currentExistingAllergen?.code ||
          existingAllergy.allergen.system !== currentExistingAllergen?.system
        ) {
          setCurrentExistingAllergen(existingAllergy.allergen)
          if (status !== existingAllergy.status)
            formMethods.setValue('status', existingAllergy.status)
          if (severity !== existingAllergy.severity)
            formMethods.setValue('severity', existingAllergy.severity)
          if (manifestation !== existingAllergy.manifestation?.code)
            formMethods.setValue(
              'manifestation',
              existingAllergy.manifestation?.code
            )
        }
      }
    )

    return () => unsubscribe()
  }, [
    allergies,
    currentExistingAllergen?.code,
    currentExistingAllergen?.system,
    formMethods,
  ])

  return (
    <SidepanelPage
      testId="sidepanel-allergy-create"
      header="Add or update an allergy"
      description="Select an allergen that already exists on the chart to update it. If the allergen is not already recorded, it will be added."
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Save and close',
          submitting: upsertingAllergy || settingNoKnownAllergies,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <FormInputList
          items={[
            {
              name: 'noKnownAllergies',
              label: 'No known allergies',
              message:
                'Select this if the patient has no known allergies. Saving this will resolve any existing allergies on the patient record.',
              formInputComponent: CheckboxField,
            },
          ]}
        />
        {!noKnownAllergies && (
          <FormInputList
            items={[
              {
                name: 'allergen',
                label: 'Allergen',
                required: true,
                formInputComponent: QueryBackedSearchInputField,
                inputProps: {
                  query: SEARCH_ALLERGENS_QUERY,
                  queryKey: 'allergens',
                  placeholder: 'Search for an allergen',
                  displayOptionFn: (allergen: FindAllergens['allergens'][0]) =>
                    `${allergen.name} (${allergen.category})`,
                },
              },
              {
                name: 'status',
                label: 'Status',
                required: true,
                inputProps: {
                  includeEmptyOption: false,
                },
                formInputComponent: AllergyStatusSelectField,
              },
              {
                name: 'severity',
                label: 'Severity',
                formInputComponent: AllergySeveritySelectField,
              },
              {
                name: 'manifestation',
                label: 'Manifestation',
                formInputComponent: AllergyManifestationsSelectField,
              },
            ]}
          />
        )}
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelAllergyCreate
