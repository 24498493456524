export const statusCodeToErrorMessage: Record<number, string> = {
  408: 'Request timed out. Please try again.',
  504: 'Request timed out. Please try again.',
}

export const retryIf =
  (mutationsToRetry: string[]) =>
  (error, operation): boolean => {
    if (!error || error.networkError?.statusCode in statusCodeToErrorMessage)
      return false

    const isMutation = operation.query?.definitions?.some((definition) => {
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'mutation'
      )
    })
    if (isMutation) {
      return mutationsToRetry.includes(operation.operationName)
    }
    return !!error
  }
