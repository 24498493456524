import clsx from 'clsx'
import { compareDesc, format, parseISO } from 'date-fns'
import { GetPatientImmunizationsToPrint } from 'types/graphql'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { immunizationRows } from 'src/pages/PatientChartsPage/PatientImmunizations/ImmunizationRecordTable/ImmunizationRows'

export const ImmunizationPageTitle = () => {
  return (
    <StackView space={50} data-testid="page-title">
      <Typography textStyle="heading">Immunizations</Typography>
      <Typography textStyle="description">
        This printout displays the entire documented vaccine record for the
        patient.
      </Typography>
    </StackView>
  )
}

export const ImmunizationTable = ({
  immunizations,
}: {
  immunizations: GetPatientImmunizationsToPrint['patient']['immunizations']
}) => {
  const rowsToDisplay = immunizationRows
    .filter((row) => {
      return immunizations.some((immunization) =>
        row.cvxCodes.includes(immunization.code.code)
      )
    })
    .map((row) => {
      return {
        ...row,
        immunizations: immunizations
          .filter((immunization) =>
            row.cvxCodes.includes(immunization.code.code)
          )
          .sort((a, b) => {
            return compareDesc(parseISO(a.occurredAt), parseISO(b.occurredAt))
          }),
      }
    })

  return (
    <Box rounded border data-testid="immunization-table">
      {rowsToDisplay.length > 0 ? (
        <>
          {rowsToDisplay.map((row, rowIndex) => (
            <StackView
              data-testid={`immunization-table-row-${rowIndex}`}
              direction="row"
              key={rowIndex}
              className={clsx([
                'p-2 pb-3 pt-0',
                rowIndex % 2 ? 'bg-gray-50' : 'bg-white',
                rowIndex === 0 && 'rounded-t-md',
                rowIndex === rowsToDisplay.length - 1
                  ? 'rounded-b-md'
                  : 'border-b',
              ])}
              space={100}
              alignItems="center"
            >
              <Box
                className="w-80"
                data-testid={`immunization-table-row-${rowIndex}-name`}
              >
                <Typography size="xs">{row.immunization}</Typography>
              </Box>

              <StackView direction="row" wrap reverse>
                {row.immunizations.map((immunization) => (
                  <Typography
                    key={immunization.externalId}
                    className="w-16 text-center"
                    size="xs"
                  >
                    {format(parseISO(immunization.occurredAt), 'MM/dd/yy')}
                  </Typography>
                ))}
              </StackView>
            </StackView>
          ))}
        </>
      ) : (
        <StackView className="pb-4 pt-1">
          <Typography className="text-center">
            No immunizations to display
          </Typography>
        </StackView>
      )}
    </Box>
  )
}
