import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import NewUser from 'src/components/User/NewUser/NewUser'

const SidepanelUserCreate = () => {
  return (
    <SidepanelPage
      header="Add Staff Member"
      subHeader="This process starts with user creation involving entry of foundational details by the practice owner, followed by generation of a practice staff member invite weblink (expires after 24 hours) that the practice owner would need to manually share. Once the practice staff member completes their user profile using the weblink, that individual will be active within the Develo EMR."
      subHeaderFontSize="s"
      subHeaderTextStyle="description"
    >
      <NewUser />
    </SidepanelPage>
  )
}

export default SidepanelUserCreate
