import { useParams } from 'react-router-dom'

import PatientDocumentEditCell from 'src/components/PatientDocumentEditCell'
const SidepanelPatientDocumentEdit = () => {
  const { patientDocumentId } = useParams()

  return <PatientDocumentEditCell id={patientDocumentId} />
}

export default SidepanelPatientDocumentEdit
