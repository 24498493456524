import { isEmpty } from '@medplum/core'
import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { compact, omit } from 'lodash'
import {
  ReadDiagnosticReportReviewCase,
  ReviewDiagnosticReportInput,
} from 'types/graphql'

import { useForm, useFormContext } from '@redwoodjs/forms'
import { useParams as useParamsRW } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import AppointmentDefinitionComboBoxCell from 'src/components/AppointmentDefinition/AppointmentDefinitionComboBoxCell'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import ChiefComplaintSelectField from 'src/components/atoms/ChiefComplaintSelectField/ChiefComplaintSelectField'
import Divider from 'src/components/atoms/Divider/Divider'
import { DropdownField } from 'src/components/atoms/Dropdown/Dropdown'
import Label from 'src/components/atoms/Label/Label'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import TargetDateField from 'src/components/atoms/TargetDateField/TargetDateField'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import Typography from 'src/components/atoms/Typography/Typography'
import FieldError from 'src/components/FieldError/FieldError'
import LocationDropdownCell from 'src/components/Location/LocationDropdownCell'
import DataRow from 'src/components/molecules/DataRow/DataRow'
import DestructiveAction from 'src/components/molecules/DestructiveAction/DestructiveAction'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import EnhancedPatientSearch from 'src/components/Patient/EnhancedPatientSearch/EnhancedPatientSearch'
import PractitionerDropdownCell from 'src/components/Practitioner/PractitionerDropdownCell'
import { useHandleCaseResolution } from 'src/hooks/useHandleCaseResolution/useHandleCaseResolution'
import { codeGeneration } from 'src/pages/CasesPage/utils/codeGeneration'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { useEffectOnce } from 'src/utils'

const DIAGNOSTIC_REPORT_REVIEW_CASE_FRAGMENT = gql`
  fragment DiagnosticReportReviewCaseFragment on DiagnosticReportReviewCase {
    id
    baseCaseId
    code
    diagnosticReport {
      id
      conclusion
      needsReview
      basedOnOrders {
        id
        requester {
          id
        }
      }
      patient {
        id
        givenName
        familyName
        contactInformation {
          id
          mobileNumber
          homeAddress {
            id
            line1
            line2
            city
            state
            postalCode
          }
        }
        patientRelatedPersonRelationships {
          id
          doesResideWith
          relationshipType
          relatedPerson {
            id
            namePrefix
            givenName
            middleName
            familyName
            nameSuffix
            contactInformation {
              id
              mobileNumber
              emailAddress
            }
          }
        }
      }
    }
  }
`

const READ_DIAGNOSTIC_REPORT_REVIEW_CASE = gql`
  query ReadDiagnosticReportReviewCase($id: String!) {
    diagnosticReportReviewCase(id: $id) {
      ...DiagnosticReportReviewCaseFragment
    }
  }
  ${DIAGNOSTIC_REPORT_REVIEW_CASE_FRAGMENT}
`

const REVIEW_DIAGNOSTIC_REPORT_MUTATION = gql`
  mutation ReviewDiagnosticReportMutation(
    $input: ReviewDiagnosticReportInput!
  ) {
    reviewDiagnosticReport(input: $input) {
      diagnosticReport {
        id
        conclusion
        reviews {
          id
        }
      }
      diagnosticReportReviewCase {
        ...DiagnosticReportReviewCaseFragment
      }
    }
  }
  ${DIAGNOSTIC_REPORT_REVIEW_CASE_FRAGMENT}
`

const MARK_DIAGNOSTIC_REPORT_ENTERED_IN_ERROR = gql`
  mutation MarkDiagnosticReportEnteredInError(
    $id: String!
    $resolvesCaseId: String
  ) {
    markDiagnosticReportEnteredInError(
      id: $id
      resolvesCaseId: $resolvesCaseId
    ) {
      diagnosticReport {
        id
      }
      diagnosticReportReviewCase {
        id
        baseCaseId
        resolvedStatus
        code
      }
    }
  }
`

const useMarkDiagnosticReportEnteredInErrorMutation = ({
  caseId,
}: {
  caseId: string
}) => {
  const [markDiagnosticReportEnteredInError, { loading }] = useMutation(
    MARK_DIAGNOSTIC_REPORT_ENTERED_IN_ERROR
  )
  const { closeSidePanel } = useSidepanel()

  return {
    markDiagnosticReportEnteredInError: (diagnosticReportId: string) =>
      markDiagnosticReportEnteredInError({
        variables: {
          id: diagnosticReportId,
          resolvesCaseId: caseId,
        },
        refetchQueries: [
          'FindSchedulingCases',
          'FindResultsReviewCases',
          'GetNotResolvedCasesCountByType',
          'GetActivityItems',
        ],
        onCompleted: () => {
          closeSidePanel()
          toast.success('Erroneous document removed')
        },
      }),
    loading,
  }
}

type FormData = Omit<
  ReviewDiagnosticReportInput,
  'caseId' | 'diagnosticReportId'
> & {
  resultsDiscussedWithCaregiver: boolean
  scheduleFollowupVisit: boolean
  visitType?: string[]
}

const useReviewDiagnosticReport = ({
  caseId,
  isResolving,
}: {
  caseId: string
  isResolving: boolean
}) => {
  const [reviewDiagnosticReport, { loading }] = useMutation(
    REVIEW_DIAGNOSTIC_REPORT_MUTATION
  )
  const { closeSidePanel } = useSidepanel()

  const { handleCaseResolution, getNextTaskId } = useHandleCaseResolution()

  return {
    reviewDiagnosticReport: async (
      input: ReviewDiagnosticReportInput,
      patientId: string
    ) => {
      const nextTaskId = await (input.caseResolution
        ? getNextTaskId({ taskId: input.caseResolution.caseId })
        : undefined)

      void reviewDiagnosticReport({
        variables: {
          input: {
            ...input,
            caseResolution: {
              caseId,
              ...input.caseResolution,
              resolvesCase: isResolving,
              followupVisit: !isEmpty(input.caseResolution.followupVisit)
                ? {
                    ...omit(
                      input.caseResolution.followupVisit,
                      'patientDisplayText'
                    ),
                    patientId,
                    intent: 'ORDER',
                  }
                : undefined,
            },
          },
        },
        refetchQueries: [
          'FindSchedulingCases',
          'FindResultsReviewCases',
          'GetNotResolvedCasesCountByType',
          'GetActivityItems',
        ],
        onCompleted: () => {
          if (input.caseResolution) {
            void handleCaseResolution({ nextTaskId })
          } else {
            closeSidePanel()
          }
        },
      })
    },
    loading,
  }
}

const SidepanelDiagnosticReportReviewCaseResolution = () => {
  const paramsRW = useParamsRW()
  const { glob } = paramsRW
  const caseId = glob?.split('/')[1]
  const {
    sidepanelContext: { route },
  } = useSidepanel()
  const pathSuffix = route?.split('/').reverse()[0]
  if (!['resolve', 'status-update'].includes(pathSuffix)) {
    throw new Error(`Invalid path suffix, ${pathSuffix}`)
  }
  const isResolving = pathSuffix === 'resolve'

  const formMethods = useForm<FormData>({
    defaultValues: {
      caseResolution: {
        discussedWithPatient: false,
      },
      resultsReviewed: !!isResolving,
    },
    shouldUnregister: true,
  })
  const { reviewDiagnosticReport, loading: resolvingResultsReviewCase } =
    useReviewDiagnosticReport({
      caseId,
      isResolving,
    })
  const { data, loading } = useQuery<ReadDiagnosticReportReviewCase>(
    READ_DIAGNOSTIC_REPORT_REVIEW_CASE,
    {
      variables: {
        id: caseId,
      },
      onCompleted: (data) => {
        if (data?.diagnosticReportReviewCase?.diagnosticReport?.conclusion) {
          formMethods.setValue(
            'conclusion',
            data.diagnosticReportReviewCase.diagnosticReport.conclusion
          )
        }
      },
    }
  )
  const {
    markDiagnosticReportEnteredInError,
    loading: markDiagnosticReportEnteredInErrorIsLoading,
  } = useMarkDiagnosticReportEnteredInErrorMutation({
    caseId,
  })

  if (!data || loading) {
    return null
  }
  const diagnosticReport = data.diagnosticReportReviewCase.diagnosticReport

  const onSubmit = (
    formData: FormData & { resultsDiscussedWithCaregiver: boolean }
  ) => {
    const dataWithoutFormOnlyFields = omit(
      formData,
      'resultsDiscussedWithCaregiver',
      'scheduleFollowupVisit',
      'visitType'
    )
    const withDiagnosticReportId = {
      ...dataWithoutFormOnlyFields,
      diagnosticReportId: diagnosticReport.id,
    }
    if (
      formData.conclusion ===
      data.diagnosticReportReviewCase?.diagnosticReport?.conclusion
    ) {
      // don't submit unchanged conclusion
      withDiagnosticReportId.conclusion = undefined
    }
    if (
      !formData.conclusion &&
      !data.diagnosticReportReviewCase?.diagnosticReport?.conclusion
    ) {
      // don't submit empty conclusion unless we're clearing an existing conclusion
      withDiagnosticReportId.conclusion = undefined
    }
    if (
      formData.caseResolution?.followupVisit?.appointmentDefinitionIds.every(
        (val) => val == ''
      )
    ) {
      // don't submit empty appointmentDefinitionIds
      // (workaround for being unable to unregister on unmount in MultiSelectComboBoxFieldArray.tsx)
      delete withDiagnosticReportId.caseResolution.followupVisit
        .appointmentDefinitionIds
    }
    void reviewDiagnosticReport(
      withDiagnosticReportId,
      diagnosticReport.patient.id
    )
  }

  const resultsDiscussedWithCaregiver = formMethods.watch(
    'resultsDiscussedWithCaregiver'
  )
  const scheduleFollowupVisit = formMethods.watch('scheduleFollowupVisit')
  const visitType = formMethods.watch(
    'caseResolution.followupVisit.appointmentDefinitionIds'
  )

  const options = (
    data?.diagnosticReportReviewCase?.diagnosticReport?.patient
      ?.patientRelatedPersonRelationships || []
  ).map(({ relatedPerson, relationshipType }) => {
    return {
      key: relatedPerson.id,
      id: relatedPerson.id,
      value: `${formatDisplayName(relatedPerson)} - ${
        relationshipTypeDisplay[relationshipType]
      }`,
      name: `${formatDisplayName(relatedPerson)} - ${
        relationshipTypeDisplay[relationshipType]
      }`,
    }
  })

  const needsReview = diagnosticReport.needsReview
  const resultsReviewed = formMethods.watch('resultsReviewed')

  return (
    <SidepanelPage
      header={
        isResolving
          ? 'Resolve task'
          : `Status update: ${codeGeneration(
              data.diagnosticReportReviewCase as Parameters<
                typeof codeGeneration
              >[0]
            )}`
      }
      description={
        isResolving
          ? 'Check the option(s) that apply before resolving the task.'
          : 'Check the option(s) that apply to your status update.'
      }
    >
      <SidepanelForm
        footerProps={{
          submitText: isResolving ? 'Resolve' : 'Submit update',
          cancelText: 'Cancel',
          submitting: resolvingResultsReviewCase,
          disabled: needsReview && isResolving && !resultsReviewed,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <StackView space={25} justifyContent="between" className="h-full">
          <StackView space={25}>
            <Space space={75} />
            <Typography textStyle="title" fontWeight="medium">
              {isResolving ? 'Resolution' : 'Interim'} status options
            </Typography>
            <Space space={75} />
            <Divider />
            <DataRow
              label={
                <StackView space={50}>
                  <Label>Check the option(s) that apply to the task:</Label>
                </StackView>
              }
              value={
                <StackView space={75}>
                  <CheckboxField
                    name="resultsReviewed"
                    label="Results reviewed"
                    validation={{
                      required: needsReview && isResolving,
                      validate: (value) => {
                        if (needsReview && isResolving && !value) {
                          return 'The results must be reviewed before resolving the task.'
                        }
                      },
                    }}
                  />
                  <CheckboxField
                    name="caseResolution.discussedWithPatient"
                    label="Discussed with patient"
                  />
                  <CheckboxField
                    name="resultsDiscussedWithCaregiver"
                    label="Discussed with caregiver"
                  />
                  {resultsDiscussedWithCaregiver && (
                    <>
                      <DropdownField
                        options={options}
                        name="caseResolution.discussedWithCaregiver"
                        validation={{
                          required: true,
                          shouldUnregister: true,
                        }}
                      />
                      <FieldError
                        name="caseResolution.discussedWithCaregiver"
                        defaultErrorMessage="This field is required"
                      />
                    </>
                  )}
                </StackView>
              }
            />

            <Divider />

            <FormInputList
              items={compact([
                !isResolving && {
                  name: 'caseResolution.routeToStaffAs',
                  label:
                    'If routing to practice staff, select the option that applies to the task:',
                  formInputComponent: MultiRadioButtonField,
                  inputProps: {
                    labelClassName: 'font-normal',
                    values: [
                      {
                        label:
                          'Results reviewed; route to practice staff to discuss NORMAL results with patient and/or caregiver(s)',
                        value: 'NORMAL',
                      },
                      {
                        label:
                          'Results reviewed; route to practice staff to discuss ABNORMAL results with patient and/or caregiver(s)',
                        value: 'ABNORMAL',
                      },
                    ],
                  },
                },
                {
                  label: 'Conclusion',
                  name: 'conclusion',
                  formInputComponent: TextAreaField,
                  inputProps: {
                    emptyAs: '',
                    rows: 5,
                  },
                },
                isResolving && {
                  label: 'Schedule follow-up visit',
                  name: 'scheduleFollowupVisit',
                  formInputComponent: CheckboxField,
                },
              ])}
            />

            {scheduleFollowupVisit && (
              <FollowupVisitInputs data={data} visitType={visitType} />
            )}
            <DestructiveAction
              title="Destructive actions"
              description="Select this to mark the result as entered in error. This will remove the document from the system and also resolve the current task. This action cannot be undone."
              buttonText="Remove erroneous document"
              doDestructiveAction={async () => {
                await markDiagnosticReportEnteredInError(diagnosticReport.id)
              }}
              destructiveActionIsLoading={
                markDiagnosticReportEnteredInErrorIsLoading
              }
            />
          </StackView>
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

interface FollowupVisitInputProps {
  data: ReadDiagnosticReportReviewCase
  visitType: string[]
}

const FollowupVisitInputs: React.FC<FollowupVisitInputProps> = ({
  data,
  visitType,
}) => {
  const formMethods = useFormContext()
  useEffectOnce(() => {
    formMethods.setValue('caseResolution.followupVisit.targetDate', {
      value: 1,
      unit: 'WEEK',
      prefix: 'AFTER',
    })
  })

  return (
    <>
      <Divider />
      <FormInputList
        title="Scheduling order"
        items={[
          {
            // THIS IS ONLY FOR DISPLAY PURPOSES
            // On submit, we grab the patient id from diagnosticReport
            label: 'Patient',
            name: 'caseResolution.followupVisit.patient',
            inputProps: {
              disabled: true,
              placeholder: formatDisplayName(
                data.diagnosticReportReviewCase.diagnosticReport.patient
              ),
              validation: {
                shouldUnregister: true,
              },
            },
            formInputComponent: EnhancedPatientSearch,
          },
          {
            label: 'Target visit timing',
            name: 'caseResolution.followupVisit.targetDate',
            formInputComponent: TargetDateField,
          },
          {
            label: 'Visit type',
            required: true,
            formInputComponent: AppointmentDefinitionComboBoxCell,
            name: 'caseResolution.followupVisit.appointmentDefinitionIds',
            inputProps: {
              validation: {
                shouldUnregister: true,
              },
            },
          },
          {
            label: 'Chief complaint',
            name: 'caseResolution.followupVisit.chiefComplaints',
            formInputComponent: ChiefComplaintSelectField,
            inputProps: {
              appointmentDefinitionId: visitType?.[0],
              validation: {
                shouldUnregister: true,
              },
            },
          },
          {
            label: 'Visit practitioner',
            name: 'caseResolution.followupVisit.visitPractitionerId',
            formInputComponent: PractitionerDropdownCell,
            required: true,
            inputProps: {
              validation: {
                shouldUnregister: true,
              },
            },
          },
          {
            label: 'Location',
            formInputComponent: LocationDropdownCell,
            name: 'caseResolution.followupVisit.locationId',
            required: true,
            inputProps: {
              validation: {
                shouldUnregister: true,
              },
            },
          },
          {
            label: 'Order comments',
            name: 'caseResolution.followupVisit.note',
            alignItems: 'start',
            inputProps: {
              rows: 5,
            },
            formInputComponent: TextAreaField,
          },
        ]}
      />
    </>
  )
}

export default SidepanelDiagnosticReportReviewCaseResolution
