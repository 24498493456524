import { getPatientStatus, patientStatusDisplay } from 'common/data/patient'
import { formatDisplayName } from 'common/utils'
import { GetPatientDemographicsForPrinting } from 'types/graphql'

import { languageDisplay } from 'src/data/languages'
import { patientLegalStatusDisplay } from 'src/data/patientLegalStatuses'
import { sexDisplay } from 'src/data/sexes'
import { formatDateDisplay } from 'src/lib/formatters'

import { DemographicsSection } from '../types'

import { formatAddress } from './formatAddress'
import { phoneNumberLine } from './phoneNumberLine'

export const createPatientSection = ({
  patient,
}: {
  patient: GetPatientDemographicsForPrinting['patient']
}): DemographicsSection => {
  const primaryGuardianRelationship =
    patient.patientRelatedPersonRelationships.find(
      (relationship) =>
        relationship.relatedPerson.id === patient.primaryGuardianId
    )
  const primaryGuardian = primaryGuardianRelationship?.relatedPerson
  const primaryGuarantorRelationship =
    patient.patientRelatedPersonRelationships.find(
      (relationship) =>
        relationship.relatedPerson.id === patient.primaryGuarantorId
    )
  const primaryGuarantor = primaryGuarantorRelationship?.relatedPerson

  const residesWithRelationship =
    patient.patientRelatedPersonRelationships.find(
      (relationship) => relationship.doesResideWith
    )
  const residesWith = residesWithRelationship?.relatedPerson

  const patientSection: DemographicsSection = {
    header: 'Patient',
    subHeader: formatDisplayName(patient),
    rows: [
      patient.legalStatus !== 'DEPENDENT'
        ? {
            title: 'Caregiver Opt-out',
            column: {
              left: [
                [
                  'Guardianship status',
                  patientLegalStatusDisplay[patient.legalStatus],
                ],
              ],
              right: [['Guarantor status', 'Self guarantor']],
            },
          }
        : null,
      {
        title: 'Practice details',
        column: {
          left: [
            ['MRN', patient.mrn],
            ['Patient status', patientStatusDisplay[getPatientStatus(patient)]],
          ],
          right: [
            [
              'Primary practitioner',
              patient.primaryProvider
                ? formatDisplayName(patient.primaryProvider)
                : null,
            ],
          ],
        },
      },
      {
        title: 'Core demographics',
        column: {
          left: [
            ['Prefix', patient.namePrefix],
            ['First name', patient.givenName],
            ['Middle name', patient.middleName],
            ['Last name', patient.familyName],
            ['Suffix', patient.nameSuffix],
          ],
          right: [
            ['Preferred name', patient.preferredName],
            ['Date of birth', formatDateDisplay(patient.birthDate)],
            ['Sex at birth', sexDisplay[patient.sexAtBirth]],
            [
              'Primary guardian',
              primaryGuardian ? formatDisplayName(primaryGuardian) : null,
            ],
            [
              'Primary guarantor',
              primaryGuarantor ? formatDisplayName(primaryGuarantor) : null,
            ],
          ],
        },
      },
      patient.contactInformation
        ? {
            title: 'Contact information',
            column: {
              left: [
                phoneNumberLine([
                  'Mobile number',
                  patient.contactInformation.mobileNumber,
                ]),
                phoneNumberLine([
                  'Home number',
                  patient.contactInformation.homeNumber,
                ]),
                ['Email address', patient.contactInformation.emailAddress],
                phoneNumberLine([
                  'Fax number',
                  patient.contactInformation.faxNumber,
                ]),
              ],
              right: [
                [
                  'Primary language',
                  languageDisplay[patient.contactInformation.primaryLanguage],
                ],
                [
                  'Secondary language',
                  languageDisplay[patient.contactInformation.secondaryLanguage],
                ],
              ],
            },
          }
        : null,
      patient.defaultPharmacy
        ? {
            title: 'Pharmacy',
            column: {
              left: [
                ['Name', patient.defaultPharmacy.name],
                phoneNumberLine(['Phone', patient.defaultPharmacy.phone]),
                phoneNumberLine(['Fax', patient.defaultPharmacy.fax]),
              ],
              right: [
                [
                  'Address',
                  formatAddress({
                    line1: patient.defaultPharmacy.address1,
                    line2: patient.defaultPharmacy.address2,
                    city: patient.defaultPharmacy.city,
                    state: patient.defaultPharmacy.state,
                    postalCode: patient.defaultPharmacy.postalCode,
                  }),
                ],
              ],
            },
          }
        : null,
      {
        title: 'Residence',
        column: {
          left: [
            [
              'Resides with',
              residesWith
                ? formatDisplayName(residesWith)
                : 'Does not live with caregiver',
            ],
            [
              'Home address',
              formatAddress(
                residesWith?.contactInformation?.homeAddress ??
                  patient.contactInformation?.homeAddress
              ),
            ],
          ],
          right: [
            [
              'Mailing address',
              formatAddress(
                residesWith?.contactInformation?.mailingAddress ??
                  patient.contactInformation?.mailingAddress
              ),
            ],
          ],
        },
      },
    ],
  }

  return patientSection
}
