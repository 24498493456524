import { dateTimeFormatter, jsDateToLocalDate } from 'common/data/date'
import { formatMoneyInCents } from 'common/utils'
import maxBy from 'lodash/maxBy'
import sumBy from 'lodash/sumBy'

import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { GuarantorCommentSection } from 'src/components/GuarantorCommentSection/GuarantorCommentSection'
import { BalanceDisplay } from 'src/components/molecules/BalanceDisplay/BalanceDisplay'
import { DataDisplayList } from 'src/components/molecules/DataDisplayList'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'

import { useSidepanelCaregiverFinancialsContext } from './SidepanelCaregiverFinancialsContext'

export const DetailsTab = () => {
  const { guarantor } = useSidepanelCaregiverFinancialsContext()

  const totalBalance = sumBy(guarantor.guarantees, (patient) =>
    Math.max(patient.totalOutstandingBalanceCents ?? 0, 0)
  )

  const paymentPlanBalance = sumBy(guarantor.guarantees, (patient) =>
    Math.max(patient.currentPaymentPlan?.remainingBalanceCents ?? 0, 0)
  )

  const patientWithMostRecentPayment = maxBy(guarantor.guarantees, (patient) =>
    patient.lastPayment?.date
      ? new Date(patient.lastPayment.date).getTime()
      : null
  )

  const lastPayment = patientWithMostRecentPayment?.lastPayment

  return (
    <StackView className="p-core-space-150" gap={200}>
      <StackView gap={100}>
        <SectionHeader
          title="Balance information"
          description="Summary details regarding the family outstanding balance for this primary guarantor."
        />

        <DataDisplayList
          data={[
            {
              label: 'Total family balance',
              value: (
                <BalanceDisplay textStyle="interface-default-s">
                  {totalBalance}
                </BalanceDisplay>
              ),
            },
            {
              label: 'Total family payment plan balance',
              value: (
                <BalanceDisplay textStyle="interface-default-s">
                  {paymentPlanBalance}
                </BalanceDisplay>
              ),
            },
            {
              label: 'Total family additional balance',
              value: (
                <BalanceDisplay textStyle="interface-default-s">
                  {totalBalance - paymentPlanBalance}
                </BalanceDisplay>
              ),
            },
            {
              label: 'Last payment',
              value: lastPayment ? (
                <StackView>
                  <Typography
                    textStyle="interface-default-s"
                    color="text-base-color-fg-muted"
                  >
                    {jsDateToLocalDate(lastPayment.date).format(
                      dateTimeFormatter('MM/dd/yyyy')
                    )}
                  </Typography>
                  <Typography
                    textStyle="interface-default-xs"
                    color="text-base-color-fg-subtle"
                  >
                    {formatMoneyInCents(lastPayment.amountCents ?? 0)}
                  </Typography>
                </StackView>
              ) : (
                <Typography
                  textStyle="interface-default-s"
                  color="text-base-color-fg-muted"
                >
                  N/A
                </Typography>
              ),
            },
          ]}
        />
      </StackView>

      <GuarantorCommentSection
        guarantor={{
          id: guarantor.id,
          guarantorType: 'RELATED_PERSON',
        }}
      />
    </StackView>
  )
}
