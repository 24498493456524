import { GetImmunizationToAdminister } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

const GET_IMMUNIZATION = gql`
  query GetImmunizationToAdminister($immunizationId: String!) {
    immunization(immunizationId: $immunizationId) {
      id
      verbalRefusalByPatient
      verbalRefusalByCaregiver
      refusalFormCompleted
      patientEligibilityForVFC
      verbalVaccineConsentObtained
      visProvided
      receivedCounseling
      patientWasHealthy
      vaccineConsentFormCompleted
      performerReference
      performerName
      locationId
      locationName
      occurredAt
      doseQuantity {
        value
        unit
      }
      route
      site
      fundingSource
      lotNumber
      expirationDate
      ndcDetails {
        ndcSale
        ndcUse
        brandName
        manufacturer
        cvxCode
        cvxCodeAndDescription
      }
      vaccineInventoryLocationId
      cvxDetails {
        visDate
      }
      visVersionDate
    }
  }
`

export const useGetImmunizationToAdminister = (immunizationId: string) => {
  return useQuery<GetImmunizationToAdminister>(GET_IMMUNIZATION, {
    variables: {
      immunizationId: immunizationId,
    },
    skip: !immunizationId,
  })
}
