import type {
  FindPracticeLetterQuery,
  FindPracticeLetterQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { practiceLetterTypeDisplay } from 'src/data/practiceLetterTypes'

import SidepanelDocumentPage from '../organisms/SidepanelDocumentPage/SidepanelDocumentPage'

export const QUERY = gql`
  query FindPracticeLetterQuery($id: String!) {
    practiceLetter: practiceLetter(id: $id) {
      id
      updatedAt
      updatedBy {
        id
        givenName
        familyName
      }
      type
      title
      binary {
        id
        content {
          url
          contentType
        }
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindPracticeLetterQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  practiceLetter,
}: CellSuccessProps<
  FindPracticeLetterQuery,
  FindPracticeLetterQueryVariables
>) => {
  return (
    <SidepanelDocumentPage
      header={`Practice letter: ${
        practiceLetterTypeDisplay[practiceLetter.type]
      }`}
      document={practiceLetter}
      testId="practice-letter-view"
      faxable
      editRoute={`/documents/practice-letter/${practiceLetter.id}/edit`}
    />
  )
}
