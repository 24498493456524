import type {
  FindExternalRequestQuery,
  FindExternalRequestQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { externalRequestTypeDisplay } from 'src/data/externalRequestTypes'

import SidepanelDocumentPage from '../organisms/SidepanelDocumentPage/SidepanelDocumentPage'

export const QUERY = gql`
  query FindExternalRequestQuery($id: String!) {
    externalRequest: externalRequest(id: $id) {
      id
      updatedAt
      updatedBy {
        id
        givenName
        familyName
      }
      type
      title
      binary {
        id
        content {
          url
          contentType
        }
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindExternalRequestQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  externalRequest,
}: CellSuccessProps<
  FindExternalRequestQuery,
  FindExternalRequestQueryVariables
>) => {
  return (
    <SidepanelDocumentPage
      header={`External request: ${
        externalRequestTypeDisplay[externalRequest.type]
      }`}
      document={externalRequest}
      testId="external-request-view"
      faxable
      editRoute={`/documents/external-request/${externalRequest.id}/edit`}
    />
  )
}
