import { SelectFieldProps } from '@redwoodjs/forms/dist/SelectField'

import SelectField from 'src/components/atoms/SelectField/SelectField'
import { deliveryMethods } from 'src/data/deliveryMethods'

export const DeliveryMethodSelectField = (props: SelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      includeEmptyOption={true}
      options={deliveryMethods.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default DeliveryMethodSelectField
