import type { ContactOrganizationType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const contactOrganizationTypeDisplay: {
  [key in ContactOrganizationType]: string
} = {
  OUTPATIENT: 'Healthcare provider- outpatient practice',
  HOSPITAL: 'Healthcare provider- hospital',
  OTHER_HEALTHCARE: 'Healthcare provider- other',
  PHARMACY: 'Pharmacy',
  LAB: 'Lab',
  DME_SUPPLIER: 'Durable medical equipment (DME) supplier',
  SCHOOL: 'School or college',
  CAMP: 'Camp',
  PAYER: 'Health insurance payer',
  GOVERNMENT: 'Government agency',
  LEGAL: 'Legal',
  OTHER: 'Other',
}

export const contactOrganizationTypes =
  nameValueArrayFromObject<ContactOrganizationType>(
    contactOrganizationTypeDisplay
  )
