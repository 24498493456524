import { toast } from '@redwoodjs/web/toast'

import PractitionerAvailabilityExceptionForm from 'src/components/AvailabilityException/PractitionerAvailabilityExceptionForm/PractitionerAvailabilityExceptionForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const SidepanelPracticeScheduleAddOverride = () => {
  const { sidepanelContext, closeSidePanel } = useSidepanel()

  const formDefaults = sidepanelContext.formDefaults
    ? JSON.parse(sidepanelContext.formDefaults as string)
    : undefined

  const onClose = () => {
    toast.success('Practitioner override created')
    closeSidePanel()
  }

  return (
    <SidepanelPage
      header="Add practitioner override"
      description="Create schedule overrides to make one-time and/or repeat modifications to practitioner availability."
    >
      <PractitionerAvailabilityExceptionForm
        mode="add"
        variant="sidepanel"
        onClose={onClose}
        groupedAvailabilityException={formDefaults}
      />
    </SidepanelPage>
  )
}

export default SidepanelPracticeScheduleAddOverride
