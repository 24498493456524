import { format } from 'date-fns'
import { isEmpty } from 'lodash'
import type {
  FindPatientDocument,
  FindPatientDocumentVariables,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import FileInput from 'src/components/atoms/FileInput/FileInput'
import InputField from 'src/components/atoms/InputField/InputField'
import PatientDocumentTypeSelectField from 'src/components/atoms/PatientDocumentTypeSelectField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { patientDocumentSubTypeDisplay } from 'src/data/patientDocumentSubTypes'
import { patientDocumentTypeDisplay } from 'src/data/patientDocumentTypes'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import { useUpdateDocument } from 'src/pages/PatientChartsPage/PatientDocuments/usePatientDocuments'
import { QUERY as FIND_PATIENT_DOCUMENT_QUERY } from 'src/pages/Sidepanel/SidepanelPatientDocument/SidepanelPatientDocument'
import { documentSubTypeSelectFieldConfig } from 'src/pages/Sidepanel/SidepanelPatientDocumentUpload/SidepanelPatientDocumentUpload'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

export const QUERY = FIND_PATIENT_DOCUMENT_QUERY

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindPatientDocumentVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

type FormData = {
  type?: string
  subType?: string
  title?: string
  /**
   * A list of files (max length 1) or undefined. Useful for the user to upload a single file.
   *
   * This matches the type used by `FileInput`
   */
  files?: FileList
}

export const Success = ({
  document,
}: CellSuccessProps<FindPatientDocument, FindPatientDocumentVariables>) => {
  const { closeSidePanel } = useSidepanel()
  const [
    updatePatientDocument,
    { loading: updatingPatientDocument, error: updatePatientDocumentError },
  ] = useUpdateDocument()

  const formMethods = useForm<FormData>({
    defaultValues: {
      type: document.type.code,
      subType: document.subType?.code,
      title: document.title,
    },
    shouldUnregister: true,
  })
  const {
    formState: { dirtyFields },
  } = formMethods

  const { isPatientChart, patientId } = useIsPatientChart()

  const type = formMethods.watch('type')
  const hasDirtyFields = !isEmpty(dirtyFields)

  const onSubmit = async (value: FormData) => {
    if (isPatientChart) {
      const { files, ...input } = value
      if (files !== undefined && files.length === 1) {
        input['binary'] = {
          content: await base64EncodeFile(files[0]),
          contentType: files[0].type,
        }
      }
      updatePatientDocument({
        variables: { id: document.id, input: { patientId, ...input } },
        onCompleted: () => {
          closeSidePanel()
        },
      })
    } else {
      formMethods.setError('files', {
        message:
          'Can only create documents when in the context of a patient chart',
      })
    }
  }

  return (
    <SidepanelPage
      testId="patient-document-edit"
      header={`${patientDocumentTypeDisplay[document.type.code]}${
        document.subType
          ? `: ${patientDocumentSubTypeDisplay[document.subType.code]}`
          : ''
      }`}
      subHeader={document.title}
      description={`Last modified on ${format(
        new Date(document.updatedAt),
        'PP'
      )}`}
    >
      <SidepanelForm
        footerProps={{
          disabled: !hasDirtyFields,
          submitText: 'Save',
          submitting: updatingPatientDocument,
        }}
        error={updatePatientDocumentError}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <FormInputList
          items={[
            {
              name: 'type',
              label: 'Document type',
              formInputComponent: PatientDocumentTypeSelectField,
              required: true,
            },
            {
              name: 'subType',
              label: 'Document sub-type',
              formInputComponent:
                documentSubTypeSelectFieldConfig[type]?.selectField,
              hide:
                isEmpty(type) ||
                !documentSubTypeSelectFieldConfig[type]?.selectField,
              required: true,
            },
            {
              name: 'title',
              label: 'Document title',
              hide: !documentSubTypeSelectFieldConfig[type]?.allowTitle,
              required: documentSubTypeSelectFieldConfig[type]?.titleRequired,
              formInputComponent: InputField,
            },
            {
              name: 'files',
              label: 'Document upload',
              formInputComponent: FileInput,
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}
