import Button, { ButtonStyle, Submit } from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'

interface ButtonConfig {
  text: string
  onClick: () => void
  position?: 'left' | 'right'
  buttonStyle?: ButtonStyle
  testId?: string
  icon?: React.FC<React.ComponentProps<'svg'>>
}

const ButtonFooter: React.FC<{
  submitText?: string
  disabled?: boolean
  submitting: boolean
  secondaryButton?: ButtonConfig
  editButton?: ButtonConfig
  testId?: string
  submitButtonTestId?: string
  submitButtonStyle?: ButtonStyle
}> = ({
  submitText,
  disabled,
  submitting,
  secondaryButton,
  editButton,
  testId = 'button-footer',
  submitButtonTestId,
  submitButtonStyle = 'primary',
}) => {
  return (
    <StackView
      space={50}
      direction="row"
      justifyContent={secondaryButton?.position === 'left' ? 'between' : 'end'}
      data-testid={testId}
    >
      {secondaryButton && (
        <Button
          data-testid={secondaryButton.testId ?? `${testId}-secondary-btn`}
          buttonStyle={secondaryButton.buttonStyle ?? 'secondary'}
          onClick={secondaryButton.onClick}
        >
          {secondaryButton.text}
        </Button>
      )}

      <StackView space={50} direction="row" fullWidth={false}>
        {editButton && (
          <Button
            disabled={submitting}
            data-testid={editButton.testId ?? `${testId}-edit-btn`}
            buttonStyle={editButton.buttonStyle ?? 'secondary'}
            icon={editButton.icon}
            onClick={editButton.onClick}
            text={editButton.text}
          />
        )}

        {submitText && (
          <Submit
            data-testid={submitButtonTestId ?? `${testId}-submit-btn`}
            buttonStyle={submitButtonStyle}
            disabled={disabled}
            loading={submitting}
          >
            {submitText}
          </Submit>
        )}
      </StackView>
    </StackView>
  )
}

export default ButtonFooter
