import type {
  FindPatientFormQuery,
  FindPatientFormQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { patientFormTypeDisplay } from 'src/data/patientFormTypes'

import SidepanelDocumentPage from '../organisms/SidepanelDocumentPage/SidepanelDocumentPage'

export const QUERY = gql`
  query FindPatientFormQuery($id: String!) {
    patientForm: patientForm(id: $id) {
      id
      updatedAt
      updatedBy {
        id
        givenName
        familyName
      }
      type
      title
      binary {
        id
        content {
          url
          contentType
        }
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindPatientFormQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  patientForm,
}: CellSuccessProps<FindPatientFormQuery, FindPatientFormQueryVariables>) => {
  return (
    <SidepanelDocumentPage
      header={`Patient form: ${patientFormTypeDisplay[patientForm.type]}`}
      document={patientForm}
      testId="patient-form-view"
      faxable
      editRoute={`/documents/patient-form/${patientForm.id}/edit`}
    />
  )
}
