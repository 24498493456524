import { useContext } from 'react'

import { useParams } from 'react-router-dom'
import { GetGuarantorInfo, GetGuarantorInfoVariables } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import { createNamedContext } from 'src/utils'

const QUERY = gql`
  query GetGuarantorInfo($relatedPersonId: String!) {
    relatedPerson(id: $relatedPersonId) {
      id
      namePrefix
      nameSuffix
      givenName
      middleName
      familyName
      contactInformationId
      portalUserStatus
      guarantees {
        id
        sexAtBirth
        birthDate
        namePrefix
        nameSuffix
        givenName
        middleName
        familyName
        patientRelatedPersonRelationships {
          id
          relationshipType
          guardianshipType
          isGuarantor
          relatedPerson {
            id
            namePrefix
            nameSuffix
            givenName
            middleName
            familyName
          }
        }
        totalOutstandingBalanceCents
        patientBalanceAge {
          lessThanThirtyDays
          thirtyToSixtyDays
          sixtyToNinetyDays
          moreThanNinetyDays
        }
        currentPaymentPlan {
          id
          remainingBalanceCents
        }
        lastPayment {
          amountCents
          date
        }
        latestPaymentNotification {
          id
          outboundTextMessage {
            id
            createdAt
          }
        }
      }
    }
  }
`
const SidepanelCaregiverFinancialsContext = createNamedContext<{
  guarantor: GetGuarantorInfo['relatedPerson']
}>('SidepanelCaregiverFinancialsContext')

export const useSidepanelCaregiverFinancialsContext = () => {
  const context = useContext(SidepanelCaregiverFinancialsContext)
  return context
}

export const SidepanelCaregiverFinancialsProvider = ({ children }) => {
  const { relatedPersonId } = useParams()
  const { data, loading } = useQuery<
    GetGuarantorInfo,
    GetGuarantorInfoVariables
  >(QUERY, {
    variables: {
      relatedPersonId,
    },
  })

  if (loading) return <LoadingSpinner />

  if (!data) return null

  return (
    <SidepanelCaregiverFinancialsContext.Provider
      value={{ guarantor: data.relatedPerson }}
    >
      {children}
    </SidepanelCaregiverFinancialsContext.Provider>
  )
}
