import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import { formatMoneyInCents } from 'common/utils'

import { useGetPatientPaymentPlan } from 'src/hooks/usePatientPaymentPlans/usePatientPaymentPlans'
import { formatNumberAsOrdinal } from 'src/lib/formatters'

import Box from '../atoms/Box/Box'
import Button from '../atoms/Button'
import LoadingSpinner from '../atoms/LoadingSpinner/LoadingSpinner'
import ProgressBar from '../atoms/ProgressBar/ProgressBar'
import StackView from '../atoms/StackView'
import Typography from '../atoms/Typography/Typography'
import { Tooltip } from '../molecules/Tooltip/Tooltip'

const PatientPaymentPlanBalanceCard = ({
  patientPaymentPlanId,
  border = true,
  cardTitle,
}: {
  patientPaymentPlanId: string
  border?: boolean
  cardTitle?: string
}) => {
  const { patientPaymentPlan, loading } =
    useGetPatientPaymentPlan(patientPaymentPlanId)

  const paymentsRemaining = patientPaymentPlan?.paymentsRemaining
  const percentagePaid = patientPaymentPlan?.percentagePaid
  const termMonths = patientPaymentPlan?.termMonths
  const billingDayOfMonth = patientPaymentPlan?.billingDayOfMonth

  const termAmountDollars = formatMoneyInCents(
    patientPaymentPlan?.termAmountCents
  )
  const totalPaidAmountDollars = formatMoneyInCents(
    patientPaymentPlan?.totalPaidAmountCents
  )
  const remainingBalanceDollars = formatMoneyInCents(
    patientPaymentPlan?.remainingBalanceCents
  )

  if (loading)
    return (
      <div className="p-core-space-75">
        <LoadingSpinner />
      </div>
    )

  return (
    <Box
      testId="payment-plan-balance-card"
      className="relative"
      padding={75}
      border={border}
      rounded
    >
      <div className="absolute right-core-space-75 top-core-space-75">
        <Tooltip
          title="Payment plan"
          text="The remaining balance of a payment plan can be paid or cancelled at any time."
          maxWidth={200}
        >
          <Button
            buttonSize="s"
            buttonStyle="ghost"
            icon={QuestionMarkCircleIcon}
          />
        </Tooltip>
      </div>
      <StackView>
        {cardTitle && (
          <Typography
            textStyle="interface-strong-xs"
            color="text-base-color-fg-subtle"
          >
            {cardTitle}
          </Typography>
        )}
        <Typography textStyle="interface-strong-l">
          {paymentsRemaining} payment{paymentsRemaining > 1 ? 's' : ''}{' '}
          remaining
        </Typography>
        <Typography
          textStyle="interface-strong-xs"
          color="text-base-color-fg-subtle"
        >
          Billed {termAmountDollars} on the{' '}
          {formatNumberAsOrdinal(billingDayOfMonth)} of each month ({termMonths}{' '}
          months)
        </Typography>
        <ProgressBar
          testId="payment-plan-progressbar"
          percent={percentagePaid}
          className="my-core-space-50"
        />
        <StackView direction="row" justifyContent="between">
          <Typography textStyle="interface-strong-xs">
            {totalPaidAmountDollars} paid to date
          </Typography>
          <Typography
            textStyle="interface-strong-xs"
            color="text-base-color-fg-danger"
          >
            {remainingBalanceDollars} remaining
          </Typography>
        </StackView>
      </StackView>
    </Box>
  )
}
export default PatientPaymentPlanBalanceCard
