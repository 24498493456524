const BoldIcon = ({ className }) => {
  return (
    <svg
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 6H4.375M1 6V0.75H4.375C5.82475 0.75 7 1.92525 7 3.375C7 4.82475 5.82475 6 4.375 6M1 6V11.25H5.125C6.57475 11.25 7.75 10.0747 7.75 8.625C7.75 7.17525 6.57475 6 5.125 6H4.375"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BoldIcon
