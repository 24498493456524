import { RegisterOptions } from '@redwoodjs/forms'

import CountrySelectField from 'src/components/atoms/CountrySelectField/CountrySelectField'
import InputField, { InputFieldSizeType } from 'src/components/atoms/InputField'
import StateSelectField from 'src/components/atoms/StateSelectField/StateSelectField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'

export interface AddressFormInputListProps {
  name: string
  validation?: RegisterOptions
  itemDirection?: 'row' | 'col'
  divider?: boolean
  inputSize?: InputFieldSizeType
}

const AddressFormInputList: React.FC<AddressFormInputListProps> = ({
  name,
  itemDirection: direction,
  divider,
  inputSize,
}: AddressFormInputListProps) => {
  return (
    <FormInputList
      className="py-0"
      divider={divider}
      items={[
        {
          name: `${name}.line1`,
          label: 'Street address',
          required: true,
          formInputComponent: InputField,
          direction,
          inputProps: {
            size: inputSize,
          },
        },
        {
          name: `${name}.line2`,
          label: 'Apt, suite, etc',
          formInputComponent: InputField,
          direction,
          inputProps: {
            size: inputSize,
          },
        },
        {
          name: `${name}.city`,
          label: 'City',
          required: true,
          formInputComponent: InputField,
          direction,
          inputProps: {
            size: inputSize,
          },
        },
        {
          name: `${name}.state`,
          label: 'State',
          required: true,
          formInputComponent: StateSelectField,
          direction,
        },
        {
          name: `${name}.postalCode`,
          label: 'Postal code',
          required: true,
          formInputComponent: InputField,
          direction,
          inputProps: {
            size: inputSize,
            validation: {
              required: true,
              pattern: {
                value: /^\d{5}$/,
                message: 'Invalid postal code: must be 5 digits',
              },
            },
          },
        },
        {
          name: `${name}.country`,
          label: 'Country',
          required: true,
          direction,
          formInputComponent: CountrySelectField,
        },
      ]}
    />
  )
}
export default AddressFormInputList
