import { useEffect, useState } from 'react'

import { observationConcepts } from 'common/cdr/concepts/observations/index'
import { isEmpty } from 'lodash'
import { isInteger, isNumeric } from 'mathjs'
import { useParams } from 'react-router-dom'
import { SexualActivityStatus, UpsertSexualHistoryInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import GenderIdentitySelectField from 'src/components/atoms/GenderIdentitySelectField/GenderIdentitySelectField'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import { SexualOrientationSelectField } from 'src/components/atoms/SexualOrientationSelectField'
import SexualStatusSelectField from 'src/components/atoms/SexualStatusSelectField/SexualStatusSelectField'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { InputFieldWithUnit } from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import {
  deepOmit,
  replaceEmptyObjectEntriesWithNull,
  compactObject,
} from 'src/data/utils'
import {
  useSexualHistoryQuery,
  useUpsertSexualHistory,
} from 'src/pages/PatientChartsPage/PatientHistory/SexualHistory/useSexualHistory'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const SexualIntercourseCheckboxFields = () => {
  return (
    <StackView space={25}>
      <CheckboxField
        name="sexualIntercourse.oral"
        label={observationConcepts['SEXUAL_INTERCOURSE_ORAL'].display}
      />
      <CheckboxField
        name="sexualIntercourse.vaginal"
        label={observationConcepts['SEXUAL_INTERCOURSE_VAGINAL'].display}
      />
      <CheckboxField
        name="sexualIntercourse.anal"
        label={observationConcepts['SEXUAL_INTERCOURSE_ANAL'].display}
      />
    </StackView>
  )
}

const BarrierCheckboxFields = () => {
  return (
    <StackView space={25}>
      <CheckboxField
        name="contraceptives.barrier.cervicalCap"
        label={
          observationConcepts['CONTRACEPTIVE_BARRIER_CERVICAL_CAP'].display
        }
      />
      <CheckboxField
        name="contraceptives.barrier.diaphragm"
        label={observationConcepts['CONTRACEPTIVE_BARRIER_DIAPHRAGM'].display}
      />
      <CheckboxField
        name="contraceptives.barrier.femaleCondom"
        label={
          observationConcepts['CONTRACEPTIVE_BARRIER_FEMALE_CONDOM'].display
        }
      />
      <CheckboxField
        name="contraceptives.barrier.maleCondom"
        label={observationConcepts['CONTRACEPTIVE_BARRIER_MALE_CONDOM'].display}
      />
      <CheckboxField
        name="contraceptives.barrier.spermicide"
        label={observationConcepts['CONTRACEPTIVE_BARRIER_SPERMICIDE'].display}
      />
      <CheckboxField
        name="contraceptives.barrier.sponge"
        label={observationConcepts['CONTRACEPTIVE_BARRIER_SPONGE'].display}
      />
      <CheckboxField
        name="contraceptives.barrier.vaginalGel"
        label={observationConcepts['CONTRACEPTIVE_BARRIER_VAGINAL_GEL'].display}
      />
    </StackView>
  )
}

const NonBarrierCheckboxFields = () => {
  return (
    <StackView space={25}>
      <CheckboxField
        name="contraceptives.nonBarrier.femaleSterilization"
        label={
          observationConcepts['CONTRACEPTIVE_NONBARRIER_FEMALE_STERILIZATION']
            .display
        }
      />
      <CheckboxField
        name="contraceptives.nonBarrier.maleSterilization"
        label={
          observationConcepts['CONTRACEPTIVE_NONBARRIER_MALE_STERILIZATION']
            .display
        }
      />
      <CheckboxField
        name="contraceptives.nonBarrier.implant"
        label={observationConcepts['CONTRACEPTIVE_NONBARRIER_IMPLANT'].display}
      />

      <CheckboxField
        name="contraceptives.nonBarrier.injection"
        label={
          observationConcepts['CONTRACEPTIVE_NONBARRIER_INJECTION'].display
        }
      />

      <CheckboxField
        name="contraceptives.nonBarrier.intrauterine"
        label={
          observationConcepts['CONTRACEPTIVE_NONBARRIER_INTRAUTERINE'].display
        }
      />

      <CheckboxField
        name="contraceptives.nonBarrier.oralPill"
        label={
          observationConcepts['CONTRACEPTIVE_NONBARRIER_ORAL_PILL'].display
        }
      />

      <CheckboxField
        name="contraceptives.nonBarrier.transdermal"
        label={
          observationConcepts['CONTRACEPTIVE_NONBARRIER_TRANSDERMAL'].display
        }
      />

      <CheckboxField
        name="contraceptives.nonBarrier.vaginalRing"
        label={
          observationConcepts['CONTRACEPTIVE_NONBARRIER_VAGINAL_RING'].display
        }
      />
    </StackView>
  )
}
const ContraceptiveCheckboxFields = ({ ...inputProps }) => {
  return (
    <StackView space={50} className="pl-2">
      <Typography size="s">Barrier</Typography>
      <BarrierCheckboxFields {...inputProps} />
      <Typography size="s">Non-barrier</Typography>
      <NonBarrierCheckboxFields {...inputProps} />
    </StackView>
  )
}

const getEmptyUpsertSexualHistoryInput = (
  patientId: string,
  status: SexualActivityStatus
) => {
  const emptyInput: UpsertSexualHistoryInput = {
    patientId,
    status,
    partners: {
      total: null,
      inLastThreeMonths: null,
      inLastTwelveMonths: null,
    },
    sexualOrientation: null,
    genderIdentity: null,
    contraceptives: { barrier: {}, nonBarrier: {} },
    sexualIntercourse: { oral: false, vaginal: false, anal: false },
  }

  return emptyInput
}

const SidepanelSexualHistoryEdit = () => {
  const [existingFormValuesLoaded, setExistingFormValuesLoaded] =
    useState(false)
  const { patientId } = useParams()
  const { closeSidePanel } = useSidepanel()

  const { sexualHistoryFormValues } = useSexualHistoryQuery(patientId)
  const [upsertSexualHistory, { loading: upserting }] = useUpsertSexualHistory()

  const formMethods = useForm<UpsertSexualHistoryInput>({
    defaultValues: sexualHistoryFormValues,
  })

  const sexualStatus = formMethods.watch('status')

  const onSubmit = async (data: UpsertSexualHistoryInput) => {
    let input = deepOmit(
      {
        patientId,
        ...replaceEmptyObjectEntriesWithNull(compactObject(data)),
      },
      ['display', 'valueType', 'unit']
    ) as UpsertSexualHistoryInput

    if (input.status !== 'EVER_ACTIVE') {
      input = getEmptyUpsertSexualHistoryInput(input.patientId, input.status)
    }

    upsertSexualHistory({ variables: { input }, onCompleted: closeSidePanel })
  }
  useEffect(() => {
    if (isEmpty(sexualHistoryFormValues)) return

    if (!existingFormValuesLoaded) {
      formMethods.reset(sexualHistoryFormValues)
      setExistingFormValuesLoaded(true)
    }
  }, [sexualHistoryFormValues, formMethods, existingFormValuesLoaded])

  if (!existingFormValuesLoaded)
    return (
      <Box className="flex h-screen items-center justify-center">
        <LoadingSpinner size="m" />
      </Box>
    )

  return (
    <SidepanelPage
      testId={'patient-sexual-history-edit'}
      header="Edit sexual history"
    >
      <SidepanelForm
        footerProps={{
          submitting: upserting,
          submitText: 'Update',
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <FormInputList
          items={[
            {
              name: 'status',
              label: 'Sexual status',
              required: true,
              direction: 'col',
              formInputComponent: SexualStatusSelectField,
            },
            {
              name: 'partners.total',
              label: 'Total partners',
              direction: 'col',
              hide: sexualStatus !== 'EVER_ACTIVE',
              formInputComponent: InputFieldWithUnit,
              inputProps: {
                unit: 'partner(s)',
                validation: {
                  validate: (value) => {
                    if (isEmpty(value)) {
                      return
                    }
                    if (!isNumeric(Number(value))) {
                      return 'Please enter whole numbers.'
                    }
                    if (Number(value) <= 0) {
                      return 'Please enter positive whole numbers.'
                    }
                    if (!isInteger(Number(value))) {
                      return 'Please enter whole numbers.'
                    }
                  },
                },
              },
            },
            {
              name: 'partners.inLastThreeMonths',
              label: 'Total partners in past 3 months',
              direction: 'col',
              hide: sexualStatus !== 'EVER_ACTIVE',
              formInputComponent: InputFieldWithUnit,
              inputProps: {
                unit: 'partner(s)',
                validation: {
                  validate: (value) => {
                    if (isEmpty(value)) {
                      return
                    }
                    if (!isNumeric(Number(value))) {
                      return 'Please enter whole numbers.'
                    }
                    if (Number(value) <= 0) {
                      return 'Please enter positive whole numbers.'
                    }
                    if (!isInteger(Number(value))) {
                      return 'Please enter whole numbers.'
                    }
                  },
                },
              },
            },
            {
              name: 'partners.inLastTwelveMonths',
              label: 'Total partners in past 12 months',
              direction: 'col',
              hide: sexualStatus !== 'EVER_ACTIVE',
              formInputComponent: InputFieldWithUnit,
              inputProps: {
                unit: 'partner(s)',
                validation: {
                  validate: (value) => {
                    if (isEmpty(value)) {
                      return
                    }
                    if (!isNumeric(Number(value))) {
                      return 'Please enter whole numbers.'
                    }
                    if (Number(value) <= 0) {
                      return 'Please enter positive whole numbers.'
                    }
                    if (!isInteger(Number(value))) {
                      return 'Please enter whole numbers.'
                    }
                  },
                },
              },
            },
            {
              name: 'sexualOrientation',
              label: 'Sexual orientation',
              direction: 'col',
              hide: sexualStatus !== 'EVER_ACTIVE',
              formInputComponent: SexualOrientationSelectField,
            },
            {
              name: 'genderIdentity',
              label: 'Gender identity',
              direction: 'col',
              hide: sexualStatus !== 'EVER_ACTIVE',
              formInputComponent: GenderIdentitySelectField,
            },
            {
              name: 'sexualIntercourse',
              label: 'Sexual intercourse',
              direction: 'col',
              hide: sexualStatus !== 'EVER_ACTIVE',
              formInputComponent: SexualIntercourseCheckboxFields,
            },
            {
              name: 'contraceptives',
              label: 'Contraceptives',
              direction: 'col',
              hide: sexualStatus !== 'EVER_ACTIVE',
              formInputComponent: ContraceptiveCheckboxFields,
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelSexualHistoryEdit
