import { differenceInYears } from 'date-fns'
import { FindPatientBirthHistory, UpsertBirthHistory } from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import {
  FrontendConfiguration,
  deepOmit,
  getExistingFormValues,
  getExistingValuesForFrontend,
  useGetFrontendMappings,
} from 'src/data/utils'

import { MINIMAL_OBSERVATION_FRAGMENT } from '../../PatientVisits/fragments'

const BIRTH_HISTORY_FRAGMENT = gql`
  fragment BirthHistoryFragment on BirthHistory {
    id
    gestationalAgeAtBirth {
      ...MinimalObservationFragment
    }
    birthWeight {
      ...MinimalObservationFragment
    }
    birthLength {
      ...MinimalObservationFragment
    }
    birthHeadCircumference {
      ...MinimalObservationFragment
    }
    bloodType {
      ...MinimalObservationFragment
    }
    deliveryMethod {
      ...MinimalObservationFragment
    }
    dischargeWeight {
      ...MinimalObservationFragment
    }
    dischargeDate {
      ...MinimalObservationFragment
    }
    lastTotalSerumBilirubin {
      ...MinimalObservationFragment
    }
    totalSerumBilirubinDate {
      ...MinimalObservationFragment
    }
    birthFacility {
      ...MinimalObservationFragment
    }
    newbornHearingScreeningResult {
      ...MinimalObservationFragment
    }
    naturalMotherHistory {
      bloodType {
        ...MinimalObservationFragment
      }
      ageAtPatientBirth {
        ...MinimalObservationFragment
      }
      hadRoutinePrenatalCare {
        ...MinimalObservationFragment
      }
      hadPregnancyComplications {
        ...MinimalObservationFragment
      }
      directAntiglobulinTestResult {
        ...MinimalObservationFragment
      }
      prenatalLabResults {
        streptococcusGroupB {
          ...MinimalObservationFragment
        }
        hiv {
          ...MinimalObservationFragment
        }
        chlamydia {
          ...MinimalObservationFragment
        }
        gonorrhoeae {
          ...MinimalObservationFragment
        }
        syphilis {
          ...MinimalObservationFragment
        }
        rubella {
          ...MinimalObservationFragment
        }
      }
    }
  }
  ${MINIMAL_OBSERVATION_FRAGMENT}
`

const BIRTH_HISTORY_QUERY = gql`
  query FindPatientBirthHistory($id: String!) {
    patient(id: $id) {
      id
      birthDate
      birthHistory {
        ...BirthHistoryFragment
      }
    }
  }
  ${BIRTH_HISTORY_FRAGMENT}
`

const UPSERT_BIRTH_HISTORY_MUTATION = gql`
  mutation UpsertBirthHistory($input: UpsertBirthHistoryInput!) {
    upsertBirthHistory(input: $input) {
      id
      birthHistory {
        ...BirthHistoryFragment
      }
    }
  }
  ${BIRTH_HISTORY_FRAGMENT}
`

const getBirthHistoryForFrontend = (
  birthHistory,
  codeToEnumMap,
  enumToDisplayMap,
  config: FrontendConfiguration
) => {
  return getExistingValuesForFrontend({
    obj: birthHistory,
    codeToEnumMap,
    enumToDisplayMap,
    config,
  })
}

export const useBirthHistoryQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<FindPatientBirthHistory>(
    BIRTH_HISTORY_QUERY,
    {
      variables: { id: patientId },
    }
  )

  const birthHistory = data?.patient?.birthHistory

  const { codeToEnumMap, enumToDisplayMap } = useGetFrontendMappings(
    patientId,
    birthHistory?.__typename
  )

  if (!codeToEnumMap || !enumToDisplayMap) return {}

  const birthHistoryForFrontend = deepOmit(
    getBirthHistoryForFrontend(birthHistory, codeToEnumMap, enumToDisplayMap, {
      unitInputs: {
        birthWeight: { unit: 'g', displayUnits: ['lbs', 'oz'] },
        birthLength: { unit: 'cm', displayUnits: ['in'] },
        birthHeadCircumference: { unit: 'cm', displayUnits: ['in'] },
        ageAtPatientBirth: {
          unit: 'a',
          displayUnits: ['a'],
        },
        gestationalAgeAtBirth: {
          unit: 'wk',
          displayUnits: ['wk'],
        },
        dischargeWeight: { unit: 'g', displayUnits: ['lbs', 'oz'] },
        lastTotalSerumBilirubin: {
          unit: 'MILLIGRAMS_PER_DECILITER',
          displayUnits: ['mg/dL'],
        },
      },
    }),
    ['id', '__typename']
  )

  const birthHistoryFormValues = getExistingFormValues(
    {},
    birthHistoryForFrontend
  )

  const hasRequiredBirthMeasurements = data?.patient?.birthDate
    ? differenceInYears(new Date(), new Date(data?.patient?.birthDate)) <= 2
    : false

  return {
    id: patientId,
    birthHistory: birthHistoryForFrontend,
    hasRequiredBirthMeasurements,
    birthHistoryFormValues,
    loading,
    error,
  }
}

export const useUpsertBirthHistory = () => {
  return useMutation<UpsertBirthHistory>(UPSERT_BIRTH_HISTORY_MUTATION, {
    onCompleted: () => {
      toast.success('Birth history saved')
    },
  })
}
