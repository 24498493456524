import StackView from 'src/components/atoms/StackView/StackView'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { ChangePasswordForm } from 'src/components/User/ChangePassword/ChangePasswordForm/ChangePasswordForm'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const SidepanelUserChangePassword = () => {
  const { closeSidePanel } = useSidepanel()
  return (
    <SidepanelPage header="Change Password">
      <StackView className="px-6">
        <ChangePasswordForm
          onCancel={closeSidePanel}
          onSuccess={closeSidePanel}
        />
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelUserChangePassword
