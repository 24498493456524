import { MbscCalendarEvent } from '@mobiscroll/react'
import type {
  GetLocationsOverridePage,
  Location,
  WeekdayKey,
} from 'types/graphql'

import { Control } from '@redwoodjs/forms'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { CheckboxField } from 'src/components/atoms/Checkbox/Checkbox'
import {
  AvailabilityExceptionLocationInputForm,
  Hours,
} from 'src/components/AvailabilityException/AvailabilityExceptionForm/AvailabilityExceptionForm'
import { PractitionerAvailabilityExceptionLocationInputForm } from 'src/components/AvailabilityException/PractitionerAvailabilityExceptionForm/PractitionerAvailabilityExceptionForm'
import { RadioButtonField } from 'src/components/molecules/RadioButton'

export const QUERY = gql`
  query GetLocationsOverridePage {
    locations {
      id
      name
      description
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<GetLocationsOverridePage>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export interface PractitionerAvailabilityInputForm {
  location: string
  time: Hours
  eventId?: string
  day?: WeekdayKey
  isUpdating?: boolean
  originalEvent?: MbscCalendarEvent
  availabilityType: string
}

interface LocationsCheckboxesProps {
  control?:
    | Control<AvailabilityExceptionLocationInputForm>
    | Control<PractitionerAvailabilityExceptionLocationInputForm>
    | Control<PractitionerAvailabilityInputForm>
  disabled?: boolean
  multiple?: boolean
}

export const Success = ({
  locations,
  multiple = true,
  disabled = false,
}: CellSuccessProps<GetLocationsOverridePage> & LocationsCheckboxesProps) => {
  if (!multiple) {
    return (
      <>
        {locations.map((location: Location) => {
          return (
            <RadioButtonField
              key={location.id}
              validation={{
                required: true,
              }}
              value={location.id}
              label={`${location.name} - ${location.description}`}
              name="location"
            />
          )
        })}
      </>
    )
  }
  return (
    <>
      {locations.map((location: Location) => {
        return (
          <CheckboxField
            key={location.id}
            disabled={disabled}
            validation={{
              required: true,
              validate: {
                noLocationSelected: (locationsObj) => {
                  return Object.values(locationsObj).filter(
                    (selected) => !!selected
                  ).length > 0
                    ? true
                    : 'You must select a location'
                },
              },
            }}
            value={location.id}
            label={`${location.name} - ${location.description}`}
            name="locations"
          />
        )
      })}
    </>
  )
}
