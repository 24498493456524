import React, { useEffect, useState } from 'react'

import { LocalDate, ZoneOffset, ZoneId, convert } from '@js-joda/core'
import { observationConcepts } from 'common/cdr/concepts/observations/index'
import {
  EMPTY_VITAL_VALUE_DISPLAY,
  HEAD_CIRCUMFERENCE_REQUIRED_AGE_LIMIT,
  temperatureTypeCodeToEnumMap,
  VITALS_ROUNDING_PRECISION,
} from 'common/cdr/concepts/observations/vitals/index'
import {
  Unit,
  isSplitUnit,
  ucumUnitToUnitEnumMap,
} from 'common/unitConverter/unitConverter'
import { formatDistance } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import { splitUnit, unit } from 'mathjs'
import { useParams } from 'react-router-dom'
import {
  FindPatientVisit,
  Observation,
  UpdateVisitIntakeVitals,
  UpsertIntakeVitalsSummaryInput,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useParams as useParamsRW } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/dist/toast'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import ButtonFooter from 'src/components/molecules/ButtonFooter/ButtonFooter'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { vitalLabels } from 'src/components/VisitIntakeVital'
import { BloodPressureInput } from 'src/components/VisitIntakeVital/BloodPressureInput'
import { BodyHeightInput } from 'src/components/VisitIntakeVital/BodyHeightInput'
import { BodyTemperatureInput } from 'src/components/VisitIntakeVital/BodyTemperatureInput'
import { BodyWeightInput } from 'src/components/VisitIntakeVital/BodyWeightInput'
import { FractionOfInspiredOxygenInput } from 'src/components/VisitIntakeVital/FractionOfInspiredOxygenInput'
import { HeadCircumferenceInput } from 'src/components/VisitIntakeVital/HeadCircumferenceInput'
import { HeartRateInput } from 'src/components/VisitIntakeVital/HeartRateInput'
import { PulseOximetryInput } from 'src/components/VisitIntakeVital/PulseOximetryInput'
import { RespiratoryRateInput } from 'src/components/VisitIntakeVital/RespiratoryRateInput'
import { formatDateFieldValue } from 'src/lib/formatters'
import { calculateAge } from 'src/lib/formatters'
import {
  getVitalsForDate,
  accumulateUpsertVitalsInput,
} from 'src/pages/PatientChartsPage/PatientResults/SidepanelPatientVitalsEdit'
import { useVitalsQuery } from 'src/pages/PatientChartsPage/PatientResults/Vitals/useVitals'
import {
  useUpdateVisitIntakeVitalsMutation,
  useVisit,
  useVisitQuery,
  useVisitVitalsQuery,
} from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

export const getVisitVitalsInputProps = ({
  visitType,
  patientAge,
  currentVitals,
}): {
  isRequired: { [key in keyof UpsertIntakeVitalsSummaryInput]?: boolean }
  formValidation: {
    [key in keyof UpsertIntakeVitalsSummaryInput]?: {
      required: string | boolean
    }
  }
} => {
  const isHeadCircumferenceRequired =
    patientAge && patientAge.years <= HEAD_CIRCUMFERENCE_REQUIRED_AGE_LIMIT
  return {
    isRequired: {
      bodyHeight: visitType === 'WELL_CHILD',
      bodyWeight: visitType === 'WELL_CHILD',
      headCircumference: isHeadCircumferenceRequired,
    },
    formValidation: {
      bodyHeight: {
        required: visitType === 'WELL_CHILD' ? 'Height is required' : false,
      },
      bodyWeight: {
        required:
          visitType === 'WELL_CHILD' ||
          !isEmpty(currentVitals.bodyWeight.partialUnit?.value) ||
          !isEmpty(currentVitals.bodyWeight.wholeUnit?.value)
            ? 'Weight is required'
            : false,
      },
      headCircumference: {
        required:
          visitType === 'WELL_CHILD' && isHeadCircumferenceRequired
            ? 'Head circumference is required'
            : false,
      },
    },
  }
}

const SidepanelVisitVitalsEdit: React.FC<Props> = ({ header }: Props) => {
  const [updateVisitIntakeVitals, { loading: updating }] =
    useUpdateVisitIntakeVitalsMutation()

  const { appointmentId } = useParamsRW()
  const { patientId } = useVisit()

  const {
    visit: visitFromAppointment,
    visitType: visitTypeFromAppointment,
    intakeVitalsLastUpdated: intakeVitalsLastUpdatedFromAppointment,
  } = useVisitQuery(appointmentId)

  const { encounterId } = useParams()

  const {
    visit: visitFromEncounter,
    visitType: visitTypeFromEncounter,
    intakeVitalsLastUpdated: intakeVitalsLastUpdatedFromEncounter,
  } = useVisitVitalsQuery(encounterId)

  const [persistedVitalsHaveLoaded, setPersistedVitalsHaveLoaded] =
    useState(false)

  const { vitalsByDateAndCode: patientVitalsByDateAndCode } =
    useVitalsQuery(patientId)
  const visit = visitFromAppointment ?? visitFromEncounter

  const patientVitalsForVisitDate = getVitalsForDate(
    visit?.start,
    patientVitalsByDateAndCode
  ).reduce(
    (vitals, vital) => accumulateUpsertVitalsInput(vitals, vital),
    {} as UpsertIntakeVitalsSummaryInput
  )

  const { closeSidePanel } = useSidepanel()

  const visitType = visitTypeFromAppointment ?? visitTypeFromEncounter

  const intakeVitalsLastUpdated =
    intakeVitalsLastUpdatedFromAppointment ??
    intakeVitalsLastUpdatedFromEncounter
  const patientAge = calculateAge(visit?.patient?.birthDate)
  const isHeadCircumferenceRequired =
    patientAge && patientAge.years <= HEAD_CIRCUMFERENCE_REQUIRED_AGE_LIMIT
  const isSplitBodyWeight =
    isHeadCircumferenceRequired ||
    (visit?.encounter?.bodyWeight &&
      isSplitUnit(visit?.encounter?.bodyWeight?.value?.display))

  const persistedVisitVitals = buildFormState(
    visit?.encounter,
    isSplitBodyWeight
  )

  const formMethods = useForm<
    Omit<UpsertIntakeVitalsSummaryInput, 'patientId'>
  >({
    defaultValues: {
      ...persistedVisitVitals,
      ...patientVitalsForVisitDate,
    },
  })

  const currentVitals = formMethods.watch()

  const {
    bodyWeight: {
      wholeUnit: { unit: currentWeightWholeUnit },
    },
  } = currentVitals

  useEffect(() => {
    // edge case: on refresh with sidepanel open
    // on first render, each vital field in formMethods.defaultValues is being set to
    // {
    //  value: EMPTY_VITAL_VALUE_DISPLAY,
    //  unit: ucumUnitToUnitEnumMap[defaultUnit] || defaultUnit,
    // }
    // // we get the persisted vital values as visit?.encounter is populated on subsequent renders

    if (currentWeightWholeUnit !== 'kg' && isSplitBodyWeight) {
      formMethods.resetField('bodyWeight.partialUnit.unit', {
        defaultValue: isSplitBodyWeight ? 'oz' : undefined,
      })
    }

    if (!persistedVitalsHaveLoaded && !areVitalsEmpty(persistedVisitVitals)) {
      formMethods.reset({
        ...persistedVisitVitals,
        ...patientVitalsForVisitDate,
      })
      setPersistedVitalsHaveLoaded(true)
    }
  }, [
    persistedVisitVitals,
    currentVitals,
    persistedVitalsHaveLoaded,
    formMethods,
    isSplitBodyWeight,
    currentWeightWholeUnit,
    patientVitalsForVisitDate,
  ])

  if (!visit?.encounter?.id) return null

  const { isRequired, formValidation } = getVisitVitalsInputProps({
    currentVitals,
    visitType,
    patientAge,
  })

  const formContents = (
    <Box className="h-full w-full">
      <FormInputList
        items={[
          {
            name: 'bodyHeight',
            showFieldError: false,
            label: vitalLabels['bodyHeight'],
            direction: 'col',
            formInputComponent: BodyHeightInput,
            subtitleProps: {
              className: 'text-sm',
            },
            inputProps: {
              setValueAsString: true,
              validation: formValidation.bodyHeight,
            },
            required: isRequired.bodyHeight,
          },
          {
            name: 'bodyWeight',
            showFieldError: false,
            label: vitalLabels['bodyWeight'],
            direction: 'col',
            formInputComponent: BodyWeightInput,
            inputProps: {
              allowPartialInput: isSplitBodyWeight ?? false,
              validation: formValidation.bodyWeight,
            },
            required: isRequired.bodyWeight,
          },
          {
            name: 'headCircumference',
            showFieldError: false,
            label: vitalLabels['headCircumference'],
            direction: 'col',
            formInputComponent: HeadCircumferenceInput,
            inputProps: {
              validation: formValidation.headCircumference,
            },
            required: isRequired.headCircumference,
          },
          {
            name: 'bodyTemperature',
            showFieldError: false,
            label: vitalLabels['bodyTemperature'],
            direction: 'col',
            formInputComponent: BodyTemperatureInput,
          },
          {
            name: 'bloodPressure',
            label: vitalLabels['bloodPressure'],
            direction: 'col',
            formInputComponent: BloodPressureInput,
          },
          {
            name: 'heartRate',
            showFieldError: false,
            label: vitalLabels['heartRate'],
            direction: 'col',
            formInputComponent: HeartRateInput,
          },
          {
            name: 'respiratoryRate',
            showFieldError: false,
            label: vitalLabels['respiratoryRate'],
            direction: 'col',
            formInputComponent: RespiratoryRateInput,
          },
          {
            name: 'pulseOximetry',
            showFieldError: false,
            label: vitalLabels['pulseOximetry'],
            direction: 'col',
            formInputComponent: PulseOximetryInput,
          },
          {
            name: 'fractionOfInspiredOxygen',
            showFieldError: false,
            label: vitalLabels['fractionOfInspiredOxygen'],
            direction: 'col',
            formInputComponent: FractionOfInspiredOxygenInput,
          },
        ]}
      />
    </Box>
  )

  const onSubmit = async (
    input: Omit<UpsertIntakeVitalsSummaryInput, 'patientId'>
  ) => {
    await updateVisitIntakeVitals({
      variables: {
        encounterId: visit?.encounter?.id || encounterId,
        input: {
          patientId,
          ...input,
          effectiveDateTime: convert(
            LocalDate.parse(formatDateFieldValue(visit.start))
              .atStartOfDay()
              .atZone(ZoneId.SYSTEM)
              .withZoneSameInstant(ZoneOffset.UTC)
          ).toDate(),
        },
      },
      refetchQueries: ['FindPatientVitals', 'GetPatient'],
      onCompleted: () => {
        toast.success('Vitals updated')
        closeSidePanel()
      },
    })
  }

  const HeaderComponent = () => {
    return (
      <StackView direction="row" justifyContent="between">
        <Typography textStyle={'title'} fontWeight={'semibold'}>
          {header ?? 'Vitals'}
        </Typography>
      </StackView>
    )
  }

  return (
    <StackView data-testid="patient-vitals" className="h-full" space={50}>
      {
        <SidepanelForm
          footerElement={
            <StackView justifyContent="between" direction="row">
              <StackView direction="row">
                {intakeVitalsLastUpdated && (
                  <StackView justifyContent="center">
                    <Typography
                      textStyle="normal"
                      color="text-base-color-fg-muted"
                    >
                      {`Last updated: ${formatDistance(
                        new Date(),
                        new Date(intakeVitalsLastUpdated),
                        { includeSeconds: true }
                      )} ago`}
                    </Typography>
                  </StackView>
                )}
              </StackView>
              <ButtonFooter
                submitText={'Save'}
                submitButtonStyle={'primary'}
                disabled={updating}
                submitting={updating}
                secondaryButton={{
                  text: 'Discard Changes',
                  onClick: closeSidePanel,
                  position: 'right',
                  buttonStyle: 'ghost',
                }}
                submitButtonTestId="sidepanel-confirm-btn"
              />
            </StackView>
          }
          formMethods={formMethods}
          onSubmit={onSubmit}
        >
          <Box verticalPadding={125}>
            <HeaderComponent />
            {formContents}
          </Box>
        </SidepanelForm>
      }
    </StackView>
  )
}

export default SidepanelVisitVitalsEdit

const formatVitalValue = (value: string | undefined) => {
  return parseFloat(Number(value).toFixed(VITALS_ROUNDING_PRECISION)).toString()
}

const buildFormState = (
  encounter:
    | FindPatientVisit['appointment']['encounter']
    | UpdateVisitIntakeVitals['updateVisitIntakeVitals'],
  isSplitBodyWeight: boolean
) => {
  return {
    bodyHeight: buildVitalValueFormState({
      value: encounter?.bodyHeight?.value,
      defaultUnit: 'in',
    }),
    bodyWeight: buildBodyWeightFormState(
      encounter?.bodyWeight?.value,
      isSplitBodyWeight
    ),
    headCircumference: buildVitalValueFormState({
      value: encounter?.headCircumference?.value,
      defaultUnit: 'in',
    }),
    bodyTemperature: {
      ...buildVitalValueFormState({
        value: encounter?.bodyTemperature?.value,
        defaultUnit: 'degF',
      }),
      type:
        temperatureTypeCodeToEnumMap[
          encounter?.bodyTemperature?.coding?.[1]?.code
        ] || 'ORAL_TEMPERATURE',
    },
    bloodPressure: buildBloodPressureFormState(encounter?.bloodPressure),
    heartRate: buildVitalValueFormState({
      value: encounter?.heartRate?.value,
      defaultUnit: '{Beats}/min',
    }),
    respiratoryRate: buildVitalValueFormState({
      value: encounter?.respiratoryRate?.value,
      defaultUnit: '{Breaths}/min',
    }),
    pulseOximetry: buildVitalValueFormState({
      value: encounter?.pulseOximetry?.value,
      defaultUnit: '%',
    }),
    fractionOfInspiredOxygen: buildVitalValueFormState({
      value: encounter?.fractionOfInspiredOxygen?.value,
      defaultUnit: '%',
    }),
  }
}

const buildBodyWeightFormState = (
  bodyWeightValue: Observation['value'],
  isSplitBodyWeight: boolean
) => {
  if (isSplitBodyWeight) {
    if (!bodyWeightValue) {
      return {
        wholeUnit: {
          value: EMPTY_VITAL_VALUE_DISPLAY,
          unit: 'lbs',
        },
        partialUnit: {
          value: EMPTY_VITAL_VALUE_DISPLAY,
          unit: 'oz',
        },
      }
    }

    const [whole, partial] = splitUnit(
      unit(bodyWeightValue.value, bodyWeightValue.unit),
      [unit('lbs'), unit('oz')]
    ).map((u) => u.format({ notation: 'fixed', precision: 1 }))
    const [wholeValue, wholeUnit] = whole.split(' ')
    const [partialValue, partialUnit] = partial.split(' ')

    return {
      wholeUnit: {
        value: wholeValue.split(' ')[0],
        unit: wholeUnit,
      },
      partialUnit: {
        value: partialValue.split(' ')[0],
        unit: partialUnit,
      },
    }
  }

  return {
    wholeUnit: buildVitalValueFormState({
      value: bodyWeightValue,
      defaultUnit: 'lbs',
    }),
    partialUnit: {
      value: EMPTY_VITAL_VALUE_DISPLAY,
      unit: 'oz',
    },
  }
}

const buildBloodPressureFormState = (
  bloodPressure: FindPatientVisit['appointment']['encounter']['bloodPressure']
) => {
  return {
    systolic: buildVitalValueFormState({
      value: bloodPressure?.components?.find(
        (component) =>
          component.code?.code ===
          observationConcepts['BLOOD_PRESSURE_SYSTOLIC'].code
      )?.value,
      defaultUnit: 'mm[Hg]',
    }),
    diastolic: buildVitalValueFormState({
      value: bloodPressure?.components?.find(
        (component) =>
          component.code?.code ===
          observationConcepts['BLOOD_PRESSURE_DIASTOLIC'].code
      )?.value,
      defaultUnit: 'mm[Hg]',
    }),
  }
}

const buildVitalValueFormState = ({
  value,
  defaultUnit,
}: {
  value?: Observation['value']
  defaultUnit?: Unit
}) => {
  if (!value) {
    return {
      value: EMPTY_VITAL_VALUE_DISPLAY,
      unit: ucumUnitToUnitEnumMap[defaultUnit] || defaultUnit,
    }
  }
  return {
    value: formatVitalValue(value.value),
    unit: ucumUnitToUnitEnumMap[value.unit] || value.unit || defaultUnit,
  }
}

type Props = {
  header?: string
  mode?: 'default' | 'sidepanel'
}

const areVitalsEmpty = (vitals) => {
  return isEmpty(
    Object.keys(vitals).filter((vital) => !isEmpty(vitals[vital].value))
  )
}
