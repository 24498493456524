import clsx from 'clsx'
import { parse } from 'date-fns'

import { FieldError, useWatch } from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box/Box'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import { DropdownField } from 'src/components/atoms/Dropdown/Dropdown'
import InputField from 'src/components/atoms/InputField'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import {
  DatePickerField,
  DEFAULT_DATE_FORMAT,
} from 'src/components/molecules/DatePicker/DatePicker'
import { RadioButtonField } from 'src/components/molecules/RadioButton/RadioButton'
import { REPEAT_OPTIONS } from 'src/data/repeatOptions'
import { formatDateFieldValue } from 'src/lib/formatters'
import getWeekdays from 'src/utils/getWeekdays'
import { getMonthlyRecurrenceOptions } from 'src/utils/recurrenceRulesHelpers'

const RepeatEventForm = () => {
  const [repeat, date, ends] = useWatch({
    name: ['recurrence.repeat', 'date', 'ends'],
  })
  let options
  if (repeat === 'monthly') {
    options = getMonthlyRecurrenceOptions(
      parse(date, DEFAULT_DATE_FORMAT, new Date())
    )
  }
  const weekdays = getWeekdays()
  return (
    <>
      <Space space={75} />
      <StackView direction="row">
        <Box flex="3/12">
          <Typography>Repeat every</Typography>
        </Box>
        <Box>
          <StackView direction="row" alignItems="center" space={50}>
            <InputField
              className="w-16"
              name="recurrence.interval"
              type="number"
              validation={{
                required: true,
              }}
            />
            <DropdownField
              name="recurrence.repeat"
              options={REPEAT_OPTIONS}
              mode="id"
            />
          </StackView>
        </Box>
      </StackView>
      <Box className={clsx(repeat === 'monthly' ? 'visible' : 'hidden')}>
        <StackView direction="row" className="mt-2.5">
          <Box flex="3/12">Repeats on</Box>
          <Box flex="5/12">
            <DropdownField
              mode="id"
              options={options || []}
              name="monthlyRecurrenceRule"
              validation={{
                required:
                  repeat === 'monthly' ? 'You need to pick an option' : false,
              }}
            />
            <FieldError
              className="mt-2 text-sm text-red-600"
              name="monthlyRecurrenceRule"
            />
          </Box>
        </StackView>
      </Box>
      <Box className={clsx(repeat === 'weekly' ? 'visible' : 'hidden')}>
        <Typography>Repeats on</Typography>
        <Space space={75} />
        <StackView direction="row" wrap justifyContent="start">
          {Object.keys(weekdays).map((dayKey: string) => {
            return (
              <Box className="mt-2.5 w-3/12" grow={false} key={dayKey}>
                <CheckboxField
                  key={dayKey}
                  validation={{
                    validate: {
                      noDayChecked: (locationsObj) => {
                        if (repeat !== 'weekly') {
                          return true
                        }
                        return Object.values(locationsObj).filter(
                          (selected) => selected
                        ).length > 0
                          ? true
                          : 'You must select at least 1 weekday'
                      },
                    },
                  }}
                  name="weekdaysObj"
                  value={dayKey}
                  label={weekdays[dayKey]}
                />
              </Box>
            )
          })}
        </StackView>
        <FieldError name="weekdaysObj" className="mt-2 text-sm text-red-600" />
      </Box>
      <Space space={75} />
      <Typography>Ends</Typography>
      <Space space={50} />
      <StackView space={50}>
        <RadioButtonField label="Never" value="never" name="ends" />
        <RadioButtonField
          label="On"
          value="on"
          name="ends"
          extraElement={
            <DatePickerField
              name="endsOn"
              grow={false}
              validation={{
                shouldUnregister: true,
                required: ends === 'on' ? true : false,
                setValueAs: (value) => formatDateFieldValue(value),
              }}
            />
          }
        />
        <RadioButtonField
          label="After"
          value="occurrences"
          name="ends"
          data-testid="ocurrences-radio-btn"
          extraElement={
            <Box className="mt-1 flex rounded-md shadow-sm" grow={false}>
              <InputField
                type="number"
                validation={{
                  shouldUnregister: true,
                  required: ends === 'ocurrences' ? 'Is required' : false,
                }}
                name="occurrences"
                id="occurrences"
                className="block w-16 flex-1 rounded-none rounded-l-md border-gray-300 px-3 py-2 focus:border-success focus:ring-success sm:text-sm"
                placeholder="1"
              />
              <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                occurrences
              </span>
            </Box>
          }
        />
      </StackView>
    </>
  )
}

export default RepeatEventForm
