import { useExtendSession } from 'src/hooks/useExtendSession/useExtendSession'
import { useLogOutOnIdle } from 'src/hooks/useLogOutOnIdle/useLogOutOnIdle'

const EXTEND_SESSION_INTERVAL_MINUTES = parseInt(
  process.env.EXTEND_SESSION_INTERVAL_MINUTES
)

const TimedSession = ({ children }) => {
  useExtendSession(EXTEND_SESSION_INTERVAL_MINUTES * 1000 * 60)
  useLogOutOnIdle()
  return <>{children}</>
}

export default TimedSession
