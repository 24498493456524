import { XMarkIcon, PlusIcon } from '@heroicons/react/20/solid'
import { isEmpty } from 'lodash'

import { get, RegisterOptions, useFormContext } from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import ChevronLink from 'src/components/atoms/ChevronLink/ChevronLink'
import {
  ComboboxInput,
  ComboboxOption,
} from 'src/components/atoms/ComboboxField/ComboboxField'
import StackView from 'src/components/atoms/StackView'
import { useEffectOnce } from 'src/utils'

export interface MultiSelectComboBoxFieldArrayProps {
  name: string
  addSelectionText: string
  options: ComboboxOption[]
  validation?: RegisterOptions
  onClickSelectedOption?: (option, originalValue) => void
  onRemoveSelectedOption?: (option) => void
  onChange?: (option?) => void
  cannotEditExistingValues?: boolean
  testId?: string
  addSelectionIsButton?: boolean
  disableAddSelectionButton?: boolean
  minimumValueLength?: number
  forceDisplayPlaceholder?: boolean
  disabled?: boolean
  limit?: number
  firstInputValue?: string | null
}

const MultiSelectComboBoxFieldArray: React.FC<
  MultiSelectComboBoxFieldArrayProps
> = ({
  name,
  options,
  addSelectionText,
  onClickSelectedOption,
  onRemoveSelectedOption,
  cannotEditExistingValues = false,
  testId,
  addSelectionIsButton = false,
  minimumValueLength = 1,
  disableAddSelectionButton = false,
  onChange,
  disabled = false,
  limit,
  firstInputValue = '',
  ...rest
}) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()
  const values = watch(name)

  const hasError = !!get(errors, name)

  useEffectOnce(() => {
    if (isEmpty(values)) {
      setValue(name, [firstInputValue])
    }
  })

  return (
    <StackView space={50} data-testid={testId}>
      {values &&
        values.map((value, index) => {
          const fullName = `${name}.${index}`
          return (
            <StackView
              key={index + value}
              direction="row"
              space={50}
              alignItems="center"
            >
              <Box grow>
                <ComboboxInput
                  value={value}
                  hasError={!value && hasError}
                  options={options}
                  name={fullName}
                  onChange={(v) => {
                    onChange && onChange(v)
                    onClickSelectedOption && onClickSelectedOption(v, value)
                    setValue(fullName, v, { shouldDirty: true })
                  }}
                  cannotEditExistingValues={
                    cannotEditExistingValues && values[index]
                  }
                  disabled={disabled}
                  limit={limit}
                  {...rest}
                />
              </Box>
              {((values[index] === '' &&
                values.length === minimumValueLength) ||
                (values[index] !== '' &&
                  values.length > minimumValueLength)) && (
                <Button
                  data-testid="remove-item-button"
                  buttonStyle="ghost"
                  icon={XMarkIcon}
                  onClick={() => {
                    if (values.length > minimumValueLength) {
                      onRemoveSelectedOption &&
                        onRemoveSelectedOption(values[index])
                    }
                    if (values.length > 1) {
                      const updated = values
                      updated.splice(index, 1)
                      setValue(name, updated, { shouldDirty: true })
                    } else {
                      setValue(fullName, '', { shouldDirty: true })
                    }
                  }}
                  className="!text-gray-400"
                  disabled={disabled}
                />
              )}
            </StackView>
          )
        })}
      {addSelectionIsButton ? (
        <Button
          onClick={() => {
            setValue(name, [...(values || []), ''], { shouldDirty: true })
          }}
          text={addSelectionText}
          testId="add-selection-button"
          buttonStyle="secondary"
          disabled={disableAddSelectionButton || disabled}
        />
      ) : (
        <ChevronLink
          onClick={() => {
            setValue(name, [...(values || []), ''], { shouldDirty: true })
          }}
          text={addSelectionText}
          icon={PlusIcon}
          underline
          testId="add-selection-text"
        />
      )}
    </StackView>
  )
}

export default MultiSelectComboBoxFieldArray
