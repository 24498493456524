import { formatDisplayName } from 'common/utils'

type FormatDisplayNameArgs = Parameters<typeof formatDisplayName>[0]

export const compareDisplayNames = (
  a: FormatDisplayNameArgs,
  b: FormatDisplayNameArgs
) => {
  const displayA = formatDisplayName(a)
  const displayB = formatDisplayName(b)

  return displayA.localeCompare(displayB)
}
