import {
  DetailedHTMLProps,
  HTMLAttributes,
  ReactElement,
  forwardRef,
} from 'react'

import clsx from 'clsx'
import { assertUnreachable } from 'common/utils'

export type FontWeightType =
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extrabold'
export type FontSizeType =
  | 'xs'
  | 's'
  | 'm'
  | 'l'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | 'xxxxl'
export type TextStyle =
  | 'heading'
  | 'title'
  | 'normal'
  | 'subtitle'
  | 'description'
  | 'body'
  | 'title-xs'
  | 'title-s'
  | 'title-m'
  | 'title-l'
  | 'title-xl'
  | 'body-xs'
  | 'body-s'
  | 'body-m'
  | 'body-l'
  | 'interface-strong-xs'
  | 'interface-strong-s'
  | 'interface-strong-m'
  | 'interface-strong-l'
  | 'interface-strong-xl'
  | 'interface-default-xs'
  | 'interface-default-s'
  | 'interface-default-m'
  | 'interface-default-l'
  | 'interface-default-xl'
export type TypographyColor =
  | 'text-base-color-fg-default'
  | 'text-base-color-fg-muted'
  | 'text-base-color-fg-brand'
  | 'text-base-color-fg-subtle'
  | 'text-base-color-fg-emphasis'
  | 'text-base-color-fg-danger'
  | 'text-base-color-fg-success'
  | 'text-base-color-fg-attention'
  | 'text-base-color-fg-accent'
  | 'text-comp-toggle-color-primary-unselected-enabled-primary-fg'
  | 'text-comp-toggle-color-primary-unselected-enabled-secondary-fg'
  | 'text-comp-textfield-color-clean-enabled-placeholder'
  | 'text-comp-textfield-color-clean-enabled-content'

export interface TypographyProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  textStyle?: TextStyle
  fontWeight?: FontWeightType
  size?: FontSizeType
  color?: TypographyColor
  className?: string
  tracking?: 'tracking-normal' | 'tracking-wide' | 'tracking-widest'
  noWrap?: boolean
  responsiveSize?: {
    sm?: FontSizeType
    md?: FontSizeType
    lg?: FontSizeType
    xl?: FontSizeType
    xxl?: FontSizeType
  }
  testId?: string
}

const fontSizeMap = {
  xs: 'text-base-font-size-xs',
  s: 'text-base-font-size-s',
  m: 'text-base-font-size-m',
  l: 'text-base-font-size-l',
  xl: 'text-base-font-size-xl',
  xxl: 'text-base-font-size-xxl',
  xxxl: 'text-base-font-size-xxxl',
  xxxxl: 'text-base-font-size-xxxxl',
}

const fontWeightMap = {
  normal: 'font-base-font-weight-400',
  medium: 'font-base-font-weight-500',
  semibold: 'font-base-font-weight-600',
  bold: 'font-base-font-weight-700',
  extrabold: 'font-base-font-weight-800',
}

const responsiveSizeMap = {
  sm: {
    xs: 'sm:text-base-font-size-xs',
    sm: 'sm:text-base-font-size-s',
    m: 'sm:text-base-font-size-m',
    l: 'sm:text-base-font-size-l',
    xl: 'sm:text-base-font-size-xl',
    xxl: 'sm:text-base-font-size-xxl',
    xxxl: 'sm:text-base-font-size-xxxl',
    xxxxl: 'sm:text-base-font-size-xxxl',
  },
  md: {
    xs: 'md:text-base-font-size-xs',
    sm: 'md:text-base-font-size-s',
    m: 'md:text-base-font-size-m',
    l: 'md:text-base-font-size-l',
    xl: 'md:text-base-font-size-xl',
    xxl: 'md:text-base-font-size-xxl',
    xxxl: 'md:text-base-font-size-xxxl',
    xxxxl: 'md:text-base-font-size-xxxl',
  },
  lg: {
    xs: 'lg:text-base-font-size-xs',
    sm: 'lg:text-base-font-size-s',
    m: 'lg:text-base-font-size-m',
    l: 'lg:text-base-font-size-l',
    xl: 'lg:text-base-font-size-xl',
    xxl: 'lg:text-base-font-size-xxl',
    xxxl: 'lg:text-base-font-size-xxxl',
    xxxxl: 'lg:text-base-font-size-xxxl',
  },
  xl: {
    xs: 'xl:text-base-font-size-xs',
    sm: 'xl:text-base-font-size-s',
    m: 'xl:text-base-font-size-m',
    l: 'xl:text-base-font-size-l',
    xl: 'xl:text-base-font-size-xl',
    xxl: 'xl:text-base-font-size-xxl',
    xxxl: 'xl:text-base-font-size-xxxl',
    xxxxl: 'xl:text-base-font-size-xxxl',
  },
  xxl: {
    xs: '2xltext-base-font-size-xs',
    sm: '2xl:text-base-font-size-s',
    m: '2xl:text-base-font-size-m',
    l: '2xl:text-base-font-size-l',
    xl: '2xl:text-base-font-size-xl',
    xxl: '2xl:text-base-font-size-xxl',
    xxxl: '2xl:text-base-font-size-xxxl',
    xxxxl: '2xl:text-base-font-size-xxxl',
  },
}

const Typography = forwardRef<HTMLSpanElement, TypographyProps>(
  (
    {
      textStyle = 'normal',
      fontWeight,
      color,
      className,
      size,
      children,
      tracking,
      noWrap,
      responsiveSize,
      testId,
      ...rest
    },
    ref
  ): ReactElement => {
    const classes = []
    switch (textStyle) {
      case 'description':
        classes.push([
          !size && 'text-base-typography-interface-default-xs-font-size',
          !fontWeight &&
            'font-base-typography-interface-default-xs-font-weight',
          'font-base-typography-interface-default-xs-font-family',
          'leading-base-typography-interface-default-xs-line-height',
          'mb-base-typography-interface-default-xs-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        color = 'text-base-color-fg-muted'
        break
      case 'title-xs':
      case 'subtitle':
        classes.push([
          !size && 'text-base-typography-title-xs-font-size',
          !fontWeight && 'font-base-typography-title-xs-font-weight',
          'font-base-typography-title-xs-font-family',
          'leading-base-typography-title-xs-line-height',
          'mb-base-typography-title-xs-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'title-s':
      case 'title':
        classes.push([
          !size && 'text-base-typography-title-s-font-size',
          !fontWeight && 'font-base-typography-title-s-font-weight',
          'font-base-typography-title-s-font-family',
          'leading-base-typography-title-s-line-height',
          'mb-base-typography-title-s-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'title-m':
        classes.push([
          !size && 'text-base-typography-title-m-font-size',
          !fontWeight && 'font-base-typography-title-m-font-weight',
          'font-base-typography-title-m-font-family',
          'leading-base-typography-title-m-line-height',
          'mb-base-typography-title-m-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'title-l':
      case 'heading':
        classes.push([
          !size && 'text-base-typography-title-l-font-size',
          !fontWeight && 'font-base-typography-title-l-font-weight',
          'font-base-typography-title-l-font-family',
          'leading-base-typography-title-l-line-height',
          'mb-base-typography-title-l-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'title-xl':
        classes.push([
          !size && 'text-base-typography-title-xl-font-size',
          !fontWeight && 'font-base-typography-title-xl-font-weight',
          'font-base-typography-title-xl-font-family',
          'leading-base-typography-title-xl-line-height',
          'mb-base-typography-title-xl-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'body-xs':
        classes.push([
          !size && 'text-base-typography-body-xs-font-size',
          !fontWeight && 'font-base-typography-body-xs-font-weight',
          'font-base-typography-body-xs-font-family',
          'leading-base-typography-body-xs-line-height',
          'mb-base-typography-body-xs-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'body-s':
      case 'body':
        classes.push([
          !size && 'text-base-typography-body-s-font-size',
          !fontWeight && 'font-base-typography-body-s-font-weight',
          'font-base-typography-body-s-font-family',
          'leading-base-typography-body-s-line-height',
          'mb-base-typography-body-s-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'body-m':
        classes.push([
          !size && 'text-base-typography-body-m-font-size',
          !fontWeight && 'font-base-typography-body-m-font-weight',
          'font-base-typography-body-m-font-family',
          'leading-base-typography-body-m-line-height',
          'mb-base-typography-body-m-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'body-l':
        classes.push([
          !size && 'text-base-typography-body-l-font-size',
          !fontWeight && 'font-base-typography-body-l-font-weight',
          'font-base-typography-body-l-font-family',
          'leading-base-typography-body-l-line-height',
          'mb-base-typography-body-l-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'interface-strong-xs':
        classes.push([
          !size && 'text-base-typography-interface-strong-xs-font-size',
          !fontWeight && 'font-base-typography-interface-strong-xs-font-weight',
          'font-base-typography-interface-strong-xs-font-family',
          'leading-base-typography-interface-strong-xs-line-height',
          'mb-base-typography-interface-strong-xs-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'interface-strong-s':
        classes.push([
          !size && 'text-base-typography-interface-strong-s-font-size',
          !fontWeight && 'font-base-typography-interface-strong-s-font-weight',
          'font-base-typography-interface-strong-s-font-family',
          'leading-base-typography-interface-strong-s-line-height',
          'mb-base-typography-interface-strong-s-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'interface-strong-m':
        classes.push([
          !size && 'text-base-typography-interface-strong-m-font-size',
          !fontWeight && 'font-base-typography-interface-strong-m-font-weight',
          'font-base-typography-interface-strong-m-font-family',
          'leading-base-typography-interface-strong-m-line-height',
          'mb-base-typography-interface-strong-m-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'interface-strong-l':
        classes.push([
          !size && 'text-base-typography-interface-strong-l-font-size',
          !fontWeight && 'font-base-typography-interface-strong-l-font-weight',
          'font-base-typography-interface-strong-l-font-family',
          'leading-base-typography-interface-strong-l-line-height',
          'mb-base-typography-interface-strong-l-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'interface-strong-xl':
        classes.push([
          !size && 'text-base-typography-interface-strong-xl-font-size',
          !fontWeight && 'font-base-typography-interface-strong-xl-font-weight',
          'font-base-typography-interface-strong-xl-font-family',
          'leading-base-typography-interface-strong-xl-line-height',
          'mb-base-typography-interface-strong-xl-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'interface-default-xs':
        classes.push([
          !size && 'text-base-typography-interface-default-xs-font-size',
          !fontWeight &&
            'font-base-typography-interface-default-xs-font-weight',
          'font-base-typography-interface-default-xs-font-family',
          'leading-base-typography-interface-default-xs-line-height',
          'mb-base-typography-interface-default-xs-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'interface-default-s':
      case 'normal':
        classes.push([
          !size && 'text-base-typography-interface-default-s-font-size',
          !fontWeight && 'font-base-typography-interface-default-s-font-weight',
          'font-base-typography-interface-default-s-font-family',
          'leading-base-typography-interface-default-s-line-height',
          'mb-base-typography-interface-default-s-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'interface-default-m':
        classes.push([
          !size && 'text-base-typography-interface-default-m-font-size',
          !fontWeight && 'font-base-typography-interface-default-m-font-weight',
          'font-base-typography-interface-default-m-font-family',
          'leading-base-typography-interface-default-m-line-height',
          'mb-base-typography-interface-default-m-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'interface-default-l':
        classes.push([
          !size && 'text-base-typography-interface-default-l-font-size',
          !fontWeight && 'font-base-typography-interface-default-l-font-weight',
          'font-base-typography-interface-default-l-font-family',
          'leading-base-typography-interface-default-l-line-height',
          'mb-base-typography-interface-default-l-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      case 'interface-default-xl':
        classes.push([
          !size && 'text-base-typography-interface-default-xl-font-size',
          !fontWeight &&
            'font-base-typography-interface-default-xl-font-weight',
          'font-base-typography-interface-default-xl-font-family',
          'leading-base-typography-interface-default-xl-line-height',
          'mb-base-typography-interface-default-xl-paragraph-spacing',
          'last:mb-core-space-0',
        ])
        break
      default:
        assertUnreachable(textStyle)
    }

    classes.push([
      'typography',
      size && fontSizeMap[size],
      responsiveSize?.sm && responsiveSizeMap.sm[responsiveSize.sm],
      responsiveSize?.md && responsiveSizeMap.md[responsiveSize.md],
      responsiveSize?.lg && responsiveSizeMap.lg[responsiveSize.lg],
      responsiveSize?.xl && responsiveSizeMap.xl[responsiveSize.xl],
      responsiveSize?.xxl && responsiveSizeMap.xxl[responsiveSize.xxl],
      fontWeight && fontWeightMap[fontWeight],
      noWrap && 'whitespace-nowrap',
      color,
      tracking,
      className,
    ])

    return (
      <span className={clsx(classes)} data-testid={testId} {...rest} ref={ref}>
        {children}
      </span>
    )
  }
)

export default Typography
