import type { InsuranceCoverageStatus } from 'types/graphql'

import { BadgeColor } from 'src/components/atoms/Badge'

import { nameValueArrayFromObject } from './utils'

export const insuranceCoverageStatusDisplay: {
  [key in InsuranceCoverageStatus]: string
} = {
  ACTIVE: 'Active',
  CANCELLED: 'Inactive',
  ENTERED_IN_ERROR: 'Entered in Error',
}

export const insuranceCoverageStatusBadgeColors: {
  [key in InsuranceCoverageStatus]: BadgeColor
} = {
  ACTIVE: 'green',
  CANCELLED: 'red',
  ENTERED_IN_ERROR: 'light-gray',
}

export const insuranceCoverageStatuses =
  nameValueArrayFromObject<InsuranceCoverageStatus>(
    insuranceCoverageStatusDisplay
  )
