import { Redirect, routes, useParams } from '@redwoodjs/router'

import { usePatientPortalAuth } from 'src/auth'

export const PrivatePatientPortalRoute = ({ children }) => {
  const { tenantPortalKey, loginMobileNumber } = useParams()
  const { isAuthenticated, loading } = usePatientPortalAuth()

  if (loading) return null

  const searchParamsWithoutLoginMobileNumber = new URLSearchParams(
    globalThis.location.search
  )
  searchParamsWithoutLoginMobileNumber.delete('loginMobileNumber')

  if (!isAuthenticated) {
    const queryStringWithoutQuestionMark =
      searchParamsWithoutLoginMobileNumber.toString()
    const queryString = queryStringWithoutQuestionMark
      ? `?${queryStringWithoutQuestionMark}`
      : ''
    const currentLocation = globalThis.location.pathname + queryString

    return (
      <Redirect
        to={routes.patientPortalLogin({
          tenantPortalKey,
          redirectTo: currentLocation,
          ...(loginMobileNumber ? { loginMobileNumber } : {}),
        })}
        options={{
          replace: true,
        }}
      />
    )
  }

  return <>{children}</>
}
