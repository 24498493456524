import { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { DiagnosisCode, DiagnosisCodeFilter } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/dist/toast'

import { CheckboxField } from 'src/components/atoms/Checkbox'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import DiagnosisSearchField from 'src/components/organisms/DiagnosisSearchField/DiagnosisSearchField'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import { useAddVisitDiagnosisMutation } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useGetVisitDiagnoses } from 'src/pages/Sidepanel/SidepanelVisitDiagnosisCreate/useGetVisitDiagnoses'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

type FormState = {
  isPrimary: boolean
  code: DiagnosisCode
  codeSearchFilter: DiagnosisCodeFilter[]
  codeSearchInput: string
}

const SidepanelVisitDiagnosisCreate = () => {
  const { encounterId } = useParams()
  const { patientId } = useIsPatientChart()
  const { closeSidePanel } = useSidepanel()

  const { primaryDiagnosis, additionalDiagnoses } =
    useGetVisitDiagnoses(encounterId)

  const formMethods = useForm<FormState>({
    defaultValues: {
      isPrimary:
        !primaryDiagnosis && !additionalDiagnoses?.length ? true : false,
    },
  })
  const [addVisitDiagnosis, { loading: addingVisitDiagnosis }] =
    useAddVisitDiagnosisMutation()

  const onSubmit = (data: FormState) => {
    addVisitDiagnosis({
      variables: {
        encounterId,
        diagnosisCodeId: data.code.id,
        isPrimary: data.isPrimary,
      },
      onCompleted: () => {
        toast.success('Visit diagnosis added')
        closeSidePanel()
      },
      refetchQueries: ['FindPatientOrders'],
    })
  }

  useEffect(() => {
    if (primaryDiagnosis || additionalDiagnoses?.length) {
      formMethods.setValue('isPrimary', false)
    }
  }, [primaryDiagnosis, additionalDiagnoses, formMethods])

  return (
    <SidepanelPage header="Add visit diagnosis">
      <SidepanelForm
        footerProps={{
          submitText: 'Add diagnosis',
          submitting: addingVisitDiagnosis,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <FormInputList
          items={[
            {
              name: 'code',
              label: 'Diagnosis code',
              required: true,
              direction: 'col',
              formInputComponent: DiagnosisSearchField,
              inputProps: {
                patientId,
                encounterId,
                searchSet: 'ALL',
                defaultSearching: true,
              },
            },
            {
              name: 'isPrimary',
              label: '',
              hideLabel: true,
              direction: 'col',
              formInputComponent: CheckboxField,
              inputProps: {
                label:
                  'This is the primary diagnosis. If there is an existing primary diagnosis, it will be replaced.',
              },
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelVisitDiagnosisCreate
