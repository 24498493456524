import { useLazyQuery } from '@apollo/client'
import {
  GetPreferredAppointmentType,
  GetPreferredAppointmentTypeVariables,
} from 'types/graphql'

const GET_PREFERRED_APPOINTMENT_TYPE = gql`
  query GetPreferredAppointmentType(
    $input: GetPractitionerPreferredAppointmentTypeDatesInput
  ) {
    practitionerPreferredAppointmentTypeDates(input: $input) {
      id
      date
      appointmentType
      practitionerId
    }
    appointmentDefinitions {
      id
      type
    }
  }
`

export const useLazyGetPractitionerPreferredAppointmentType = () =>
  useLazyQuery<
    GetPreferredAppointmentType,
    GetPreferredAppointmentTypeVariables
  >(GET_PREFERRED_APPOINTMENT_TYPE)
