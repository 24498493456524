import StackView from 'src/components/atoms/StackView/StackView'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'
import { PaymentNotificationHistoryTable } from 'src/components/organisms/PaymentNotificationHistoryTable/PaymentNotificationHistoryTable'

import { useSidepanelPatientFinancialsContext } from './SidepanelPatientFinancialsContext'

export const HistoryTab = () => {
  const { patient } = useSidepanelPatientFinancialsContext()

  return (
    <StackView className="px-core-space-100 pb-core-space-500 pt-core-space-100">
      <SectionHeader
        title="Payment reminder history"
        description="Details on payment reminder text messages sent to this patient."
      />

      <PaymentNotificationHistoryTable
        contactInformationId={patient.contactInformationId}
      />
    </StackView>
  )
}
