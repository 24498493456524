import { useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { MbscRecurrenceRule } from '@mobiscroll/react'
import { parse } from 'date-fns'
import { Options, RRule } from 'rrule'
import { GroupedAvailabilityException, WeekdayKey } from 'types/graphql'

import { Form, get, useForm, UseFormReturn, useWatch } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import Box from 'src/components/atoms/Box/Box'
import Button, { Submit } from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import Divider from 'src/components/atoms/Divider/Divider'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import FieldError from 'src/components/FieldError/FieldError'
import AvailabilityExceptionLocationCell from 'src/components/Location/AvailabilityExceptionLocationCell'
import {
  DEFAULT_DATE_FORMAT,
  DatePickerField,
} from 'src/components/molecules/DatePicker/DatePicker'
import Modal from 'src/components/molecules/Modal/Modal'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { TimeIntervalSelect } from 'src/components/molecules/TimeIntervalSelect/TimeIntervalSelect'
import { ToggleField } from 'src/components/molecules/Toggle/Toggle'
import { formatDateFieldValue } from 'src/lib/formatters'
import {
  format12HoursTo24,
  format24HoursTo12,
  getEndRecurrenceValues,
  getEndsRecurrenceOptions,
  getMonthlyRecurrenceValue,
  getRecurrenceRule,
  getWeekdaysRecurrenceRule,
  getWeeklyRecurrenceValue,
  RecurrenceRuleType,
} from 'src/utils/recurrenceRulesHelpers'

import {
  CREATE_AVAILABILITY_EXCEPTION_MUTATION,
  UPDATE_AVAILABILITY_EXCEPTION_MUTATION,
} from '../PractitionerAvailabilityExceptionForm/PractitionerAvailabilityExceptionForm'
import RepeatEventForm from '../RepeatEventForm/RepeatEventForm'

export type Hours = {
  start: string
  end: string
}

export interface Configuration {
  closed?: boolean
  visitHours?: Hours
  afterHours?: [Hours, Hours]
  repeatActive?: boolean
  afterHoursActive?: boolean
  recurrenceRule?: RecurrenceRuleType
}

export type DayType = {
  [key in WeekdayKey]: boolean
}

export type LocationsType = {
  [locationId: string]: boolean
}

export interface AvailabilityExceptionLocationInputForm {
  visitHoursActive: boolean
  afterHoursActive: boolean
  repeatActive: boolean
  recurrence: MbscRecurrenceRule
  locations: LocationsType
  date: string
  weekdaysObj: DayType
  monthlyRecurrenceRule?: string
  everyCount: string
  endsOn?: string
  occurrences?: number
  days: DayType
  ends: 'never' | 'on' | 'occurrences'
  configuration: Configuration
}

export interface WithConfig {
  configuration: Configuration
}

export type GroupedExceptionWithConfig = Omit<
  GroupedAvailabilityException,
  'configuration'
> &
  WithConfig

interface AvailabilityExceptionFormProps {
  onClose: () => void
  groupedAvailabilityException?: GroupedExceptionWithConfig
  variant?: 'sidepanel'
  mode?: 'add' | 'edit'
}

export const CHECK_APPOINTMENTS_TO_CANCEL = gql`
  query GetAppointmentsToCancel($input: AppointmentsToCancelInput!) {
    appointmentsToCancel(input: $input) {
      id
      locationId
    }
  }
`

const FormContainer = ({
  variant,
  formMethods,
  onSubmit,
  children,
  disabled,
  submitting,
}: {
  variant?: 'sidepanel'
  formMethods: UseFormReturn<AvailabilityExceptionLocationInputForm>
  onSubmit: (data: AvailabilityExceptionLocationInputForm) => void
  children: React.ReactNode
  disabled: boolean
  submitting: boolean
}) => {
  if (variant === 'sidepanel') {
    return (
      <SidepanelForm
        formMethods={formMethods}
        onSubmit={onSubmit}
        footerProps={{
          disabled,
          submitting,
          submitText: 'Save',
        }}
      >
        {children}
      </SidepanelForm>
    )
  }

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      {children}
    </Form>
  )
}

const AvailabilityExceptionForm = ({
  onClose,
  groupedAvailabilityException = undefined,
  variant,
  mode,
}: AvailabilityExceptionFormProps) => {
  const [createGroupedAvailabilityException, { loading: creating }] =
    useMutation(CREATE_AVAILABILITY_EXCEPTION_MUTATION)
  const [updateGroupedAvailabilityException, { loading: updating }] =
    useMutation(UPDATE_AVAILABILITY_EXCEPTION_MUTATION)
  const [appointmentsToCancel, setAppointmentsToCancel] = useState<number>(0)

  const [checkAppointmentsToCancel, { loading: checking }] = useLazyQuery(
    CHECK_APPOINTMENTS_TO_CANCEL
  )
  let options: Partial<Options>

  if (groupedAvailabilityException?.configuration?.recurrenceRule?.rule) {
    const { origOptions } = RRule.fromString(
      groupedAvailabilityException?.configuration?.recurrenceRule.rule
    )
    options = origOptions
  }

  const date =
    groupedAvailabilityException?.date ?? formatDateFieldValue(new Date())
  const frequency = RRule.FREQUENCIES[options?.freq]?.toLowerCase()
  const { ends, endsOn, occurrences } = getEndRecurrenceValues(
    groupedAvailabilityException?.configuration?.recurrenceRule?.rule
  )
  const formMethods = useForm<AvailabilityExceptionLocationInputForm>({
    mode: 'onSubmit',
    defaultValues: {
      visitHoursActive:
        !groupedAvailabilityException?.configuration?.closed || false,
      afterHoursActive:
        groupedAvailabilityException?.configuration?.afterHoursActive || false,
      repeatActive:
        groupedAvailabilityException?.configuration?.repeatActive || false,
      date,
      locations:
        groupedAvailabilityException?.locationIds?.reduce((acc, locationId) => {
          acc[locationId] = true
          return acc
        }, {}) || {},
      weekdaysObj: getWeeklyRecurrenceValue(
        groupedAvailabilityException?.configuration?.recurrenceRule?.rule
      ),
      monthlyRecurrenceRule: getMonthlyRecurrenceValue(
        groupedAvailabilityException?.configuration?.recurrenceRule?.rule
      ),
      recurrence: {
        repeat:
          (frequency as 'yearly' | 'daily' | 'monthly' | 'weekly') || 'weekly',
        interval: options?.interval || 1,
      },
      configuration: {
        visitHours: {
          start:
            format24HoursTo12(
              groupedAvailabilityException?.configuration?.visitHours?.start
            ) || '',
          end:
            format24HoursTo12(
              groupedAvailabilityException?.configuration?.visitHours?.end
            ) || '',
        },
        afterHours: [
          {
            start: format24HoursTo12(
              get(
                groupedAvailabilityException,
                'configuration.afterHours.0.start',
                ''
              )
            ),
            end: format24HoursTo12(
              get(
                groupedAvailabilityException,
                'configuration.afterHours.0.end',
                ''
              )
            ),
          },
          {
            start: format24HoursTo12(
              get(
                groupedAvailabilityException,
                'configuration.afterHours.1.start',
                ''
              )
            ),
            end: format24HoursTo12(
              get(
                groupedAvailabilityException,
                'configuration.afterHours.1.end',
                ''
              )
            ),
          },
        ],
      },
      occurrences,
      ends,
      endsOn,
    },
  })

  const [visitHoursActive, afterHoursActive, repeatActive] = useWatch({
    control: formMethods.control,
    name: ['visitHoursActive', 'afterHoursActive', 'repeatActive'],
  })

  const getInputData = (data) => {
    const recurrenceRule = getRecurrenceRule(
      parse(data.date, DEFAULT_DATE_FORMAT, new Date()),
      !data.visitHoursActive,
      data?.configuration?.visitHours?.start,
      data?.configuration?.visitHours?.end,
      data.recurrence.repeat,
      data.recurrence.interval,
      getWeekdaysRecurrenceRule(data.weekdaysObj),
      data?.monthlyRecurrenceRule,
      getEndsRecurrenceOptions(
        data.ends,
        parse(data.endsOn, DEFAULT_DATE_FORMAT, new Date()),
        data.occurrences
      ),
      data.repeatActive
    )
    const configuration = {
      closed: !data.visitHoursActive,
      visitHours: {
        start: format12HoursTo24(data?.configuration?.visitHours?.start),
        end: format12HoursTo24(data?.configuration?.visitHours?.end),
      },
      afterHoursActive: data.afterHoursActive,
      afterHours: data.afterHoursActive
        ? data.configuration.afterHours.map((obj) => {
            return {
              ...obj,
              start: format12HoursTo24(obj.start),
              end: format12HoursTo24(obj.end),
            }
          })
        : [],
      repeatActive: data.repeatActive,
      recurrenceRule,
    }

    const input = {
      configuration,
      date: data.date,
      locationIds: Object.entries(data.locations)
        .filter(([_key, val]) => !!val)
        .map(([key, _val]) => key),
      practitionerId: null,
    }
    return { recurrenceRule, configuration, input }
  }

  const updateException = (data) => {
    const { input, configuration } = getInputData(data)
    input['appointmentsToCancel'] = appointmentsToCancel
    updateGroupedAvailabilityException({
      variables: {
        input,
        referenceId: groupedAvailabilityException.id,
      },
      optimisticResponse: {
        updateGroupedAvailabilityException: {
          id: groupedAvailabilityException.id,
          date: data.date,
          __typename: 'GroupedAvailabilityException',
          practitionerId: null,
          configuration,
          locationIds: Object.keys(data.locations),
        },
      },
      onCompleted: () => onClose(),
    })
  }
  const createException = (data) => {
    const { input } = getInputData(data)
    input['appointmentsToCancel'] = appointmentsToCancel
    createGroupedAvailabilityException({
      variables: {
        input,
      },
      onCompleted: () => onClose(),
      update: (cache, { data: { createGroupedAvailabilityException } }) => {
        cache.modify({
          fields: {
            groupedAvailabilityExceptions: (exceptionList = []) => {
              const newGroupedAvailabilityException = cache.writeFragment({
                data: createGroupedAvailabilityException,
                fragment: gql`
                  fragment NewGroupedException on GroupedAvailabilityException {
                    id
                    date
                    practitionerId
                    __typename
                    configuration
                    locationIds
                  }
                `,
              })
              return [...exceptionList, newGroupedAvailabilityException]
            },
          },
        })
      },
    })
  }

  const onSubmit = (data: AvailabilityExceptionLocationInputForm) => {
    const { recurrenceRule, configuration, input } = getInputData(data)
    const checkInput = {
      date: data.date,
      recurrenceRule: recurrenceRule.rule,
      locationIds: Object.keys(data.locations),
      startTime: configuration.closed
        ? '00:00'
        : input.configuration.visitHours.start,
      endTime: configuration.closed
        ? '23:59'
        : input.configuration.visitHours.end,
      closed: configuration.closed,
    }
    checkAppointmentsToCancel({
      variables: {
        input: checkInput,
      },
      onCompleted(response) {
        if (response.appointmentsToCancel.length > 0) {
          setAppointmentsToCancel(response.appointmentsToCancel.length)
        } else {
          if (mode === 'add') {
            createException(data)
          } else if (mode === 'edit') {
            updateException(data)
          } else if (groupedAvailabilityException) {
            updateException(data)
          } else {
            createException(data)
          }
        }
      },
    })
  }

  const Container = variant === 'sidepanel' ? React.Fragment : Card

  return (
    <Container>
      <FormContainer
        variant={variant}
        formMethods={formMethods}
        onSubmit={onSubmit}
        disabled={creating || updating || checking}
        submitting={creating || updating}
      >
        <Box
          horizontalPadding={variant === 'sidepanel' ? 0 : 100}
          verticalPadding={100}
        >
          <Typography textStyle="subtitle">Details</Typography>
          <Space space={100} />
          <DatePickerField
            name="date"
            mode="date"
            label="Start date"
            controls={['calendar']}
            validation={{
              required: 'This field is required',
              setValueAs: (value) => formatDateFieldValue(value),
            }}
          />
          <Space space={75} />
          <Typography>Practice location</Typography>
          <Space space={50} />
          <StackView space={50}>
            <AvailabilityExceptionLocationCell />
            <FieldError name="locations" />
          </StackView>
          <Space space={125} />
          <Divider />
          <Space space={125} />
          <StackView
            direction="row"
            alignItems="center"
            justifyContent="between"
          >
            <Typography size="m">Visit hours</Typography>
            <ToggleField
              id={`toggle-visit-hours`}
              name="visitHoursActive"
              activeText="Open"
              inactiveText="Closed"
            />
          </StackView>
          <Space space={100} />
          {visitHoursActive && (
            <>
              <TimeIntervalSelect
                keepDefaultValue
                keepDirtyValues
                id="visit-hours"
                intervalType="time"
                stepMinute={15}
                rules={{
                  required: visitHoursActive ? 'This field is required' : false,
                }}
                name="configuration.visitHours"
              />
              <FieldError name="configuration.visitHours.start" />
            </>
          )}
          <Space space={125} />
          <Divider />
          <Space space={125} />
          <StackView
            direction="row"
            alignItems="center"
            justifyContent="between"
          >
            <Typography size="m">After hours</Typography>
            <ToggleField
              id={`toggle-after-hours`}
              name="afterHoursActive"
              activeText="Open"
              inactiveText="Closed"
            />
          </StackView>
          {afterHoursActive && (
            <>
              <Space space={100} />
              <TimeIntervalSelect
                keepDefaultValue
                keepDirtyValues
                id="after-hours-am"
                label="Before visit hours"
                name="configuration.afterHours.0"
                intervalType="time"
                stepMinute={15}
              />
              <FieldError name="configuration.afterHours.0.start" />
              <Space space={75} />
              <TimeIntervalSelect
                keepDefaultValue
                keepDirtyValues
                id="after-hours-pm"
                label="After visit hours"
                name="configuration.afterHours.1"
                intervalType="time"
                stepMinute={15}
              />
              <FieldError name="configuration.afterHours.1.start" />
            </>
          )}
          <Space space={125} />
          <Divider />
          <Space space={125} />
          <StackView
            direction="row"
            alignItems="center"
            justifyContent="between"
          >
            <Typography size="m">Repeat</Typography>
            <ToggleField
              id={`toggle-repeat`}
              activeText="Repeats"
              inactiveText="Does not repeat"
              name="repeatActive"
            />
          </StackView>
          {repeatActive && <RepeatEventForm />}
        </Box>
        <Space space={75} />
        {variant !== 'sidepanel' ? (
          <Box
            inverse
            verticalPadding={75}
            horizontalPadding={100}
            className="rounded-b-base-border-radius-container-l"
          >
            <StackView
              direction="row"
              justifyContent="end"
              alignItems="center"
              space={50}
            >
              <Button buttonStyle="secondary" text="Cancel" onClick={onClose} />
              <Submit
                buttonStyle="primary"
                text="Save"
                disabled={creating || updating || checking}
                loading={creating || updating}
              />
            </StackView>
          </Box>
        ) : null}
      </FormContainer>
      <Modal
        isOpen={appointmentsToCancel > 0}
        title={`${appointmentsToCancel} visits need to be rescheduled`}
        content={`By saving these changes, you will need to have ${appointmentsToCancel} visits rescheduled due to the new conflict. Are you sure you would like to save and continue?`}
        modalStyle="danger"
        primaryButton={{
          text: 'Save and continue',
          onClick: () => {
            const data = formMethods.getValues()
            if (groupedAvailabilityException) {
              updateException(data)
            } else {
              createException(data)
            }
          },
        }}
        setIsOpen={(isOpen) => {
          if (!isOpen) {
            setAppointmentsToCancel(0)
          }
        }}
      />
    </Container>
  )
}

export default AvailabilityExceptionForm
