import { Interpretation } from 'types/graphql'

import { SelectFieldProps } from '@redwoodjs/forms/dist/SelectField'

import SelectField from 'src/components/atoms/SelectField/SelectField'
import { interpretations } from 'src/data/interpretations'

const applicableInterpretations: Interpretation[] = [
  'NORMAL',
  'ABNORMAL',
  'INCONCLUSIVE',
]

export const NewbornHearingScreeningResultSelectField = (
  props: SelectFieldProps
) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      includeEmptyOption={true}
      options={interpretations
        .filter(({ value }) => applicableInterpretations.includes(value))
        .map(({ name, value }) => ({
          value,
          display: name,
        }))}
    />
  )
}

export default NewbornHearingScreeningResultSelectField
