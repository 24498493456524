import { ExclamationCircleIcon } from '@heroicons/react/24/solid'

import { useFormContext, get } from '@redwoodjs/forms'
import { FieldErrorProps } from '@redwoodjs/forms/dist/FieldError'

import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'

const DEFAULT_MESSAGES = {
  required: 'is required',
  pattern: 'is not formatted correctly',
  minLength: 'is too short',
  maxLength: 'is too long',
  min: 'is too low',
  max: 'is too high',
  validate: 'is not valid',
}

const FieldError = ({
  name,
  label,
  defaultErrorMessage = `${label || name} has an error`,
  className,
  testId,
}: FieldErrorProps & {
  label?: string
  defaultErrorMessage?: string
  testId?: string
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  const validationError = get(errors, name)

  const errorMessage =
    (validationError &&
      (validationError.message ||
        (validationError.type &&
          `${label || name} ${
            DEFAULT_MESSAGES[
              validationError.type as keyof typeof DEFAULT_MESSAGES
            ]
          }`))) ||
    defaultErrorMessage
  return validationError ? (
    <StackView direction="row" alignItems="center" space={25}>
      <ExclamationCircleIcon className="h-base-size-icon-xs w-base-size-icon-xs fill-base-color-bg-danger-emphasis" />
      <Typography
        textStyle="body-xs"
        color="text-base-color-fg-danger"
        className={className}
        data-testid={testId}
      >
        {errorMessage}
      </Typography>
    </StackView>
  ) : null
}

export default FieldError
