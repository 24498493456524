import { Link } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/dist/toast'

import Card from 'src/components/atoms/Card/Card'
import StackView from 'src/components/atoms/StackView/StackView'

const AuthPageLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <section
      aria-labelledby="primary-heading"
      className="lg:max-w-8xl flex min-h-screen min-w-0 flex-1 flex-col bg-slate-50 px-8 pt-8 sm:px-12 md:px-16 md:pt-12 lg:order-last"
    >
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
      <StackView className="grow md:py-4 lg:py-8" space={125}>
        <StackView
          direction="col"
          gap={100}
          alignItems="center"
          justifyContent="between"
          wrap
        >
          <StackView space={50} className="max-w-lg">
            <Link to="/">
              <img
                className=" mx-auto mb-6 h-10 w-auto"
                src="/img/develo-logo-square.svg"
                alt="Develo"
              />
            </Link>
            <Card className="p-6">{children}</Card>
          </StackView>
        </StackView>
      </StackView>
    </section>
  )
}

export default AuthPageLayout
