import { useEffect } from 'react'

import { useFormContext } from '@redwoodjs/forms'

import { useEmrAuth } from 'src/auth'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import { FieldLabel } from 'src/components/atoms/Label'
import StackView from 'src/components/atoms/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import Typography from 'src/components/atoms/Typography'
import { FormInputList } from 'src/components/molecules/FormInputList'
import PractitionerSelectCell from 'src/components/Practitioner/PractitionerSelectCell'

export const AppendSignaturePageInput = () => {
  const formMethods = useFormContext()

  const { currentUser } = useEmrAuth()

  const checkboxName = 'shouldAddSignature'
  const shouldAddSignature = formMethods.watch(checkboxName)
  const signedByPractitionerId = formMethods.watch('signedByPractitionerId')

  useEffect(() => {
    if (
      shouldAddSignature &&
      !signedByPractitionerId &&
      currentUser.roles.includes('PRACTITIONER')
    ) {
      formMethods.setValue('signedByPractitionerId', currentUser.practitionerId)
    }
  }, [
    currentUser.practitionerId,
    currentUser.roles,
    formMethods,
    shouldAddSignature,
    signedByPractitionerId,
  ])

  return (
    <StackView space={100}>
      <StackView space={25}>
        <Typography textStyle="title-xs">Appended page</Typography>
        <Typography textStyle="body-s">
          Select the additional optional sections that you would like to append
          to the document prior to task resolution. Do not do this if the
          document has already been annotated with a signature or other details.
        </Typography>
      </StackView>
      <StackView space={50}>
        <StackView direction="row" alignItems="start">
          <CheckboxField name={checkboxName} />
          <StackView space={25}>
            <FieldLabel name={checkboxName}>Signature</FieldLabel>
            <Typography
              textStyle="interface-default-xs"
              color="text-base-color-fg-muted"
            >
              Add a section to the document that contains your signature with
              your credentials, date and practice name.
            </Typography>
          </StackView>
        </StackView>

        {shouldAddSignature && (
          <FormInputList
            items={[
              {
                name: 'signedByPractitionerId',
                label: 'Signing individual',
                formInputComponent: PractitionerSelectCell,
                direction: 'col',
                inputProps: {
                  validation: {
                    shouldUnregister: true,
                  },
                },
                required: true,
              },
              {
                name: 'comment',
                label: 'Comment',
                formInputComponent: TextAreaField,
                direction: 'col',
                inputProps: {
                  validation: {
                    shouldUnregister: true,
                  },
                },
              },
            ]}
          />
        )}
      </StackView>
    </StackView>
  )
}
