import { useRef } from 'react'

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { assertUnreachable } from 'common/utils'
import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import { FetchPatientPayment } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import Table from 'src/components/molecules/Table'

const PAYMENT_EVENTS = {
  CREATED: 'Created',
  UPDATED: 'Updated',
  DELETED: 'Deleted',
  REFUNDED: 'Refunded',
}

const TeamMemberRenderer = ({
  paymentEvent,
}: {
  paymentEvent: FetchPatientPayment['patientPayment']['candidPatientPaymentEvents'][0]
}) => {
  switch (paymentEvent.occurredByUserType) {
    case 'USER':
      return (
        <StackView>
          <Typography
            color="text-base-color-fg-muted"
            textStyle="interface-default-s"
          >
            {formatDisplayName(paymentEvent.occurredBy)}
          </Typography>
          <Typography
            color="text-base-color-fg-subtle"
            textStyle="interface-default-xs"
          >
            {paymentEvent.occurredBy?.userType.name}
          </Typography>
        </StackView>
      )
    case 'TILLED':
      return (
        <Typography
          color="text-base-color-fg-muted"
          textStyle="interface-default-s"
        >
          Paid by patient or caregiver via web link or portal
        </Typography>
      )
    default:
      assertUnreachable(paymentEvent.occurredByUserType)
  }
}

const defaultColDef: ColDef = {
  cellStyle: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  headerClass: 'develo-ag-header',
  resizable: false,
  autoHeight: true,
  wrapText: true,
}

const columnDefs: ColDef[] = [
  {
    colId: 'action',
    headerName: 'Action',
    cellRenderer: ({ data }) => {
      return (
        <Typography
          color="text-base-color-fg-muted"
          textStyle="interface-default-s"
        >
          {PAYMENT_EVENTS[data.event]}
        </Typography>
      )
    },
    autoHeight: true,
    width: 100,
  },
  {
    colId: 'teamMember',
    headerName: 'Team member',
    cellRenderer: ({ data }) => <TeamMemberRenderer paymentEvent={data} />,
    autoHeight: true,
    width: 145,
  },
  {
    colId: 'time',
    headerName: 'Date and time',
    cellRenderer: ({ data }) => {
      return (
        <Typography
          color="text-base-color-fg-muted"
          textStyle="interface-default-s"
        >
          {format(new Date(data.occurredAt), "MM/dd/yyyy 'at' h:mma")}
        </Typography>
      )
    },
    autoHeight: true,
  },
]

const PatientPaymentEventsTable = ({
  payment,
}: {
  payment: FetchPatientPayment['patientPayment']
}) => {
  const gridRef = useRef<AgGridReact>()
  return (
    <Table
      testId="patient-charges-table"
      innerRef={gridRef}
      rowData={payment.candidPatientPaymentEvents}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      domLayout="autoHeight"
      rowHeight={72}
      pagination={false}
    />
  )
}

export default PatientPaymentEventsTable
