import { ResolveStaffMessageCaseMutation } from 'types/graphql'

import { useParams } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useHandleCaseResolution } from 'src/hooks/useHandleCaseResolution/useHandleCaseResolution'

export const SOLVE_STAFF_MESSAGE_CASE_MUTATION = gql`
  mutation ResolveStaffMessageCaseMutation(
    $staffMessageCaseId: String!
    $input: ResolveStaffMessageCaseInput!
  ) {
    resolveStaffMessageCase(
      staffMessageCaseId: $staffMessageCaseId
      input: $input
    ) {
      id
      resolvedAt
      baseCase {
        id
        updatedAt
      }
      resolvedBy {
        id
        familyName
        givenName
      }
      resolvedStatus
    }
  }
`

const SidepanelStaffMessageCaseResolution = () => {
  const { glob } = useParams()
  const { handleCaseResolution, getNextTaskId } = useHandleCaseResolution()
  const [resolveStaffMessageCase, { loading: resolvingStaffMessageCase }] =
    useMutation<ResolveStaffMessageCaseMutation>(
      SOLVE_STAFF_MESSAGE_CASE_MUTATION
    )
  const resolveCase = async (cancel) => {
    const staffMessageCaseId: string = glob?.split('/')[1]

    const nextTaskId = await getNextTaskId({ taskId: staffMessageCaseId })
    resolveStaffMessageCase({
      variables: {
        input: {
          cancelCase: cancel,
        },
        staffMessageCaseId,
      },
      refetchQueries: [
        'FindStaffMessageCases',
        'GetNotResolvedCasesCountByType',
      ],
      onCompleted: () => {
        handleCaseResolution({ nextTaskId })
      },
    })
  }
  return (
    <SidepanelPage
      header="Resolve task"
      description="Resolve or cancel this task"
    >
      <SidepanelForm
        footerProps={{
          submitting: resolvingStaffMessageCase,
          onCancel: () => {
            resolveCase(true)
          },
          submitText: 'Resolve task',
          cancelText: 'Cancel task',
        }}
        onSubmit={() => {
          resolveCase(false)
        }}
      />
    </SidepanelPage>
  )
}

export default SidepanelStaffMessageCaseResolution
