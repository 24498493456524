import { useIntersectionObserver } from 'usehooks-ts'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner'

export type InfiniteScrollProps = {
  listId: string
  fetchMore: () => void
  loading: boolean
  hasNextPage: boolean
}

const InfiniteScroll = ({
  listId,
  fetchMore,
  loading,
  hasNextPage,
}: InfiniteScrollProps) => {
  const { ref } = useIntersectionObserver({
    root: document.getElementById(listId),
    threshold: 0.5,
    onChange: (isIntersecting) => {
      if (!hasNextPage) return
      if (!isIntersecting) return
      if (loading) return

      fetchMore()
    },
  })

  return (
    <>
      {hasNextPage ? (
        <div
          ref={ref}
          className="h-core-space-25 w-core-space-25 flex-shrink-0"
        />
      ) : null}
      {loading ? (
        <div className="p-core-space-100">
          <LoadingSpinner />
        </div>
      ) : null}
    </>
  )
}

export default InfiniteScroll
