import { DocumentTypesConfig } from 'common/cdr/concepts/documents'
import type { PatientOrderDocumentType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const patientOrderDocumentTypeDisplay: {
  [key in PatientOrderDocumentType]: string
} = {
  ...DocumentTypesConfig['PATIENT_ORDER']['subTypes'],
}

export const patientOrderDocumentTypes =
  nameValueArrayFromObject<PatientOrderDocumentType>(
    patientOrderDocumentTypeDisplay
  )
