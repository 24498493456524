import { menstrualFlowConceptCodings } from 'common/cdr/concepts/observations/menstrualHistories/index'
import { MenstrualFlow } from 'types/graphql'

import { nameValueArrayFromObject } from 'src/data/utils'

export const menstrualFlowDisplay: {
  [key in MenstrualFlow]: string
} = {
  LIGHT: 'Light',
  MEDIUM: 'Medium',
  HEAVY: 'Heavy',
}

export const menstrualFlowDisplayToEnum = Object.keys(
  menstrualFlowDisplay
).reduce((acc, key) => {
  acc[menstrualFlowDisplay[key]] = key
  return acc
}, {})

export const menstrualFlowCodeToEnum = menstrualFlowConceptCodings.reduce(
  (acc, coding) => {
    if (menstrualFlowDisplayToEnum[coding.display]) {
      acc[coding.code] = menstrualFlowDisplayToEnum[coding.display]
    }
    return acc
  },
  {}
)

export const menstrualFlows =
  nameValueArrayFromObject<MenstrualFlow>(menstrualFlowDisplay)
