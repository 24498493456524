import { OrganSystem } from './organSystems'

export const rosSystemFindings: {
  [key in Exclude<OrganSystem, 'GU'> | 'GU_F' | 'GU_M']: string[]
} = {
  CON: [
    'Fever',
    'Chills',
    'Body aches',
    'General malaise',
    'Fatigue / lethargy',
    'Significant weight gain',
    'Significant weight loss',
    'Night sweats',
    'Tremors',
    'Colic',
    'Difficulties with self-soothing',
  ],
  HEAD: [
    'Asymmetric head',
    'Flat head (plagiocephalic or brachycephalic)',
    'Head injury / trauma',
    'Head lesions',
  ],
  EYES: [
    'Pain in or around eyes',
    'Red or swollen eyes',
    'Eye drainage',
    'Photophobia',
    'Double vision',
    'Floaters or blind spots',
    'Visual acuity changes',
    'Visual field changes',
    'Color perception changes',
  ],
  EARS: [
    'Dizziness with exertion',
    'Spinning sensation / vertigo',
    'Tinnitus',
    'External ear pain',
    'Internal ear pain',
    'Recurring ear infections',
    'Hearing difficulties',
    'Drainage from ear',
    'Sensitivity to loud noises',
  ],
  NOSE: [
    'Nasal congestion',
    'Allergic rhinitis',
    'Rhinorrhea',
    'Noisy breathing',
    'Loss of smell',
  ],
  MOUTH: [
    'Dry mouth',
    'Intraoral lesions or ulcers',
    'Bleeding gums',
    'Caries / cavities',
    'Tooth pain',
    'Dental issues',
    'Loss of taste',
    'Lip swelling',
    'White patches on tongue',
    'Excessive drooling',
  ],
  THROAT: [
    'Sore throat',
    'Pain with swallowing',
    'Difficulty swallowing',
    'Bolus sensation in throat',
  ],
  NECK: [
    'Neck stiffness',
    'Neck mass',
    'Enlarged lymph nodes',
    'Nuchal rigidity',
  ],
  PULM: [
    'Cough',
    'Chronic cough',
    'Daytime cough',
    'Nighttime cough',
    'Productive cough',
    'Wheezing',
    'Shortness of breath at rest',
    'Shortness of breath with exertion',
    'Difficulty breathing',
    'Chest tightness',
    'Asthma',
    'Asthma with ≥2 per week use of rescue medications',
    'Asthma with recurring exacerbation events',
    'Asthma with recurring urgent care or ER visits',
    'Exposure to secondhand smoke',
    'Exposure to pollen, dust, or other environmental agents',
    'Exposure to mold',
    'Exposure to animal dander',
    'Snoring',
    'Tachypnea',
    'Increased work of breathing',
  ],
  CV: [
    'Heart palpitations',
    'Chest pain',
    'Chest pain with exertion',
    'Syncope',
    'Lightheadedness during exercise',
    'Exercise intolerance',
    'Swellling in extremities',
    'Sweating with feeds',
  ],
  GI: [
    'Change in appetite',
    'Nausea',
    'Vomiting',
    'Hematemesis',
    'Diarrhea',
    'Abdominal pain',
    'Localized abdominal pain',
    'Abdominal pain radiating to shoulder or back',
    'Abdominal cramping',
    'Appropriate diet for age (in content/volume)',
    'Poor food tolerance',
    'Frequent burping',
    'Heartburn',
    'Bloody stools',
    'Mucous in stool',
    'Constipation',
    'Abnormal stool quality',
    'Difficulty with latching',
    'Difficulty with breastfeeding',
    'Difficulty with bottle feeding',
    'Irritability associated with feeding',
    'Fecal incontinence',
    'Withholding stool',
    'Enuresis',
    'Gassiness',
    'Excessive spit up',
    'Difficulty with latch',
    'Difficulty with breastfeeding',
    'Difficulty with bottle feeding',
    'Irritability with feeding',
    'Abnormal stool quality',
  ],
  GU_M: [
    'Polyuria',
    'Erectile dysfunction',
    'Penile discharge',
    'Testicular swelling',
    'Testicular pain',
    'Flank pain',
    'Dysuria',
    'Dyspareunia',
    'Urinary urgency',
    'Urinary incontinence',
    'Dark or bloody urine',
    'Abnormal voiding pattern for age',
    'Circumcision complication',
  ],
  GU_F: [
    'Breast enlargement',
    'Foul-smelling urine',
    'Polyuria',
    'Menorrhagia',
    'Dysmenorrhea',
    'Irregular periods',
    'Period-associated pain',
    'Spotting',
    'Vaginal dryness',
    'Vaginal itching',
    'Abnormal vaginal discharge',
    'Dyspareunia',
    'Flank pain',
    'Vaginal pain',
    'Dysuria',
    'Urinary urgency',
    'Foul-smelling urine',
    'Urinary incontinence',
    'Dark or bloody urine',
    'Recurring urinary tract infections (UTIs)',
    'Abnormal voiding pattern for age',
    'Breast tenderness',
    'Breast nodule or lump',
    'Nipple changes',
    'Nipple drainage',
    'Pregnancy',
  ],
  MSK: [
    'Muscle aches / myalgias',
    'Joint pain with swelling & redness',
    'Joint pain w/o swelling & redness',
    'Joint dislocation or laxity',
    'Joint swelling or effusion',
    'Pain in upper extremity',
    'Pain in lower extremity',
    'Elbow pain',
    'Wrist pain',
    'Pain in fingers or toes',
    'Back pain',
    'Shoulder pain',
    'Hip pain',
    'Morning joint stiffness',
    'Weakness in extremity',
    'Cramping in extremities',
    'Limited range of motion',
    'Limb deformity',
    'Refusal to walk',
  ],
  DERM: [
    'Jaundice',
    'Pruritus',
    'Hives',
    'Dry skin',
    'Eczema',
    'Rash',
    'Lesions or ulcers',
    'Skin texture changes',
    'Skin pigmentation changes',
    'Increased acne',
    'Hair loss',
    'Cyanosis or pallor',
    'Cysts',
    'Abnormal growth on skin',
    'Acanthosis nigricans',
    'Increased facial hair (hirsutism)',
    'Change in mole appearance',
    'Umbilical cord complications',
  ],
  NEURO: [
    'Headaches',
    'Headaches with increasing severity or frequency',
    'Headaches that are worse in the morning',
    'Loss of consciousness',
    'Seizures',
    'Gait changes',
    'Speech difficulties',
    'Short-term memory changes',
    'Difficulties with fine motor coordination',
    'Difficulties with gross motor coordination',
    'Numbness or tingling in extremities',
    'Burning sensation in extremities',
    'Tremors',
    'Confusion or disorientation',
    'Loss of tone or muscle strength',
    'Rigidity or increased tone',
    'Loss of sensation',
  ],
  PSYCH: [
    'Substance abuse',
    'Decrease in school or work performance',
    'Feeling sad or hopeless',
    'Sense of worthlessness',
    'Feeling lethargic or listless',
    'Suicidal ideation without plan',
    'Suicidal ideation with plan',
    'Thoughts of self-harm',
    'Self-injurious behaviors',
    'Homicidal ideation',
    'Sleep disturbance',
    'Increased irritability',
    'Anhedonia',
    'Racing thoughts',
    'Poor attention span',
    'Difficulties with concentration',
    'Feeling easily distracted',
    'Disruptive behaviors',
    'Feeling anxious',
    'Impulsive behaviors',
    'Difficulties with social interactions',
    'Visual hallucinations',
    'Auditory hallucinations',
    'Mood swings / lability',
    'Hyperactivity',
    'Paranoid thoughts',
    'Emotional or verbal abuse',
    'Domestic violence',
    'Sexual abuse',
    'Physical abuse',
    'Frequent nightmares',
    'Sleepwalking',
  ],
  HLI: [
    'Easy bruising',
    'Easy bleeding',
    'Epistaxis',
    'Recurring epistaxis',
    'Prolonged bleeding',
    'Allergic reaction',
    'Anaphylaxis',
    'Recurrent infections',
    'Facial swelling',
    'Seasonal allergies',
  ],
  ENDO: [
    'Polydipsia',
    'Polyphagia',
    'Cold intolerance',
    'Heat intolerance',
    'Excessive sweating',
    'Decreased energy',
  ],
}
