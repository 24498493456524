import { isEmpty } from 'lodash'
import type {
  FindContactQuery,
  FindContactQueryVariables,
  UpdateContactInput,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import InheritedAddressField from 'src/components/Address/InheritedAddressField/InheritedAddressField'
import InputField from 'src/components/atoms/InputField/InputField'
import PhoneInputField from 'src/components/atoms/PhoneInputField/PhoneInputField'
import ContactOrganizationSelectFieldCell from 'src/components/molecules/ContactOrganizationSelectFieldCell'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { CONTACT_FRAGMENT } from 'src/pages/ContactsPage/ContactOrganizationsCell'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import getDirtyFields from 'src/utils/getDirtyFields'

export const QUERY = gql`
  query FindContactQuery($id: String!) {
    contact(id: $id) {
      ...ContactFragment
    }
  }
  ${CONTACT_FRAGMENT}
`

const UPDATE_CONTACT_MUTATION = gql`
  mutation UpdateContact($id: String!, $input: UpdateContactInput!) {
    updateContact(id: $id, input: $input) {
      ...ContactFragment
    }
  }
  ${CONTACT_FRAGMENT}
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindContactQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  contact,
}: CellSuccessProps<FindContactQuery, FindContactQueryVariables>) => {
  const { closeSidePanel } = useSidepanel()
  const [
    updateContact,
    { loading: updatingContact, error: updateContactError },
  ] = useMutation(UPDATE_CONTACT_MUTATION, {
    onCompleted: () => {
      toast.success('Contact updated')
      closeSidePanel()
    },
  })
  const formMethods = useForm<UpdateContactInput>({
    shouldUnregister: true,
    defaultValues: contact,
  })

  const {
    formState: { dirtyFields },
  } = formMethods

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Save',
        disabled: isEmpty(dirtyFields),
        submitting: updatingContact,
      }}
      formMethods={formMethods}
      error={updateContactError}
      onSubmit={async (value) => {
        const toSubmit: UpdateContactInput = getDirtyFields(dirtyFields, value)

        let refetchQueries: string[]
        if (toSubmit.contactOrganizationId) {
          // if the contact changes organizations, need to refresh page query so that
          // organizations can update.
          // otherwise changes are limited to the contact itself, so no need to refetch
          refetchQueries = ['ContactOrganizationsQuery']
        }
        updateContact({
          variables: { id: contact.id, input: toSubmit },
          refetchQueries,
        })
      }}
    >
      <FormInputList
        items={[
          {
            name: 'name',
            label: 'Full name',
            formInputComponent: InputField,
          },
          {
            name: 'contactOrganizationId',
            label: 'Organization',
            formInputComponent: ContactOrganizationSelectFieldCell,
          },
          {
            name: 'address',
            label: 'Address',
            formInputComponent: InheritedAddressField,
            inputProps: {
              useInheritedAddressName: 'useOrganizationAddress',
              useInheritedAddressDescription: 'Same as organization address',
            },
          },
          {
            name: 'faxNumber',
            label: 'Fax number',
            formInputComponent: PhoneInputField,
          },
          {
            name: 'emailAddress',
            label: 'Email address',
            formInputComponent: InputField,
          },
          {
            name: 'mobileNumber',
            label: 'Mobile number',
            formInputComponent: PhoneInputField,
          },
          {
            name: 'homeNumber',
            label: 'Home number',
            formInputComponent: PhoneInputField,
          },
          {
            name: 'workNumber',
            label: 'Work number',
            formInputComponent: PhoneInputField,
          },
        ]}
      />
    </SidepanelForm>
  )
}
