import { useEffect } from 'react'

import {
  familyMemberHistoryRelationshipTypes,
  familyMemberHistoryRelationshipTypeDisplay,
} from 'common/data/relationshipTypes'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import {
  FamilyMemberHistory,
  CreateFamilyMemberHistoryInput,
  UpsertOccurrencesInput,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/dist/toast'

import { CheckboxField } from 'src/components/atoms/Checkbox'
import InputField from 'src/components/atoms/InputField'
import RelationshipTypeSelectField from 'src/components/atoms/RelationshipTypeSelectField/RelationshipTypeSelectField'
import FamilyMemberHistoryOccurrenceInputField from 'src/components/molecules/FamilyMemberHistoryOccurrenceInputField/FamilyMemberHistoryOccurrenceInputField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import GenericFormFieldArray from 'src/components/molecules/GenericFormFieldArray/GenericFormFieldArray'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import {
  useFamilyHistoryQuery,
  useUpdateFamilyHistory,
} from 'src/pages/PatientChartsPage/PatientHistory/FamilyHistory/useFamilyHistory'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const familyHistoryRelationshipTypeOptions =
  familyMemberHistoryRelationshipTypes.map(({ name, value }) => ({
    name,
    value,
    display: name,
  }))

const SidepanelFamilyMemberHistoryEdit = () => {
  const { patientId, familyHistoryId } = useParams()

  const { familyMemberHistoriesGroupedById } = useFamilyHistoryQuery(patientId)

  const existingFamilyMemberHistory: FamilyMemberHistory =
    familyMemberHistoriesGroupedById?.[familyHistoryId]

  const { closeSidePanel } = useSidepanel()

  const [updateFamilyHistory, { loading: updating }] = useUpdateFamilyHistory()

  const onSubmit = async (data) => {
    const input: CreateFamilyMemberHistoryInput = {
      patientId,
      icd10Code: data.icd10Code,
      relationshipType: data.relationshipType,
      occurrences: data.occurrences,
      enteredInError: data.enteredInError,
    }

    updateFamilyHistory({
      variables: { input },
      onCompleted: () => {
        toast.success(
          `Family history updated for ${
            familyMemberHistoryRelationshipTypeDisplay[input?.relationshipType]
          } (${input.icd10Code})`
        )
        closeSidePanel()
      },
    })
  }

  const formMethods = useForm<CreateFamilyMemberHistoryInput>({
    defaultValues: {
      icd10Code: existingFamilyMemberHistory?.conditions?.[0]?.icd10Code,
      relationshipType: existingFamilyMemberHistory?.relationshipType,
    },
  })

  useEffect(() => {
    if (
      isEmpty(existingFamilyMemberHistory) ||
      isEmpty(existingFamilyMemberHistory.conditions)
    )
      return

    const existingOccurrenceInputs: UpsertOccurrencesInput[] =
      existingFamilyMemberHistory.conditions.map((condition) => {
        return {
          onsetAgeInYears: condition.onsetAgeInYears,
          onsetYear: condition.onsetYear,
        }
      })

    formMethods.resetField('occurrences', {
      defaultValue: existingOccurrenceInputs,
    })

    formMethods.resetField('icd10Code', {
      defaultValue: existingFamilyMemberHistory.conditions[0].icd10Code,
    })

    formMethods.resetField('relationshipType', {
      defaultValue: existingFamilyMemberHistory.relationshipType,
    })
  }, [existingFamilyMemberHistory, formMethods])

  if (isEmpty(existingFamilyMemberHistory)) {
    return null
  }

  return (
    <SidepanelPage
      testId={'patient-family-history-edit'}
      header="Edit family history"
    >
      <SidepanelForm
        footerProps={{
          submitting: updating,
          submitText: 'Update',
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <FormInputList
          items={[
            {
              name: 'icd10Code',
              label: 'Family history code',
              required: true,
              direction: 'col',
              inputProps: {
                disabled: true,
              },
              formInputComponent: InputField,
            },
            {
              name: 'relationshipType',
              label: 'Relationship to patient',
              required: true,
              inputProps: {
                disabled: true,
                filterOptions: familyHistoryRelationshipTypeOptions,
              },
              formInputComponent: RelationshipTypeSelectField,
            },
            {
              name: 'occurrences',
              label: 'Past occurrence(s)',
              subtitle: `Click on 'Add occurrence' to add multiple occurrences
                of the same condition`,
              direction: 'col',
              formInputComponent: GenericFormFieldArray,
              inputProps: {
                formInputComponent: FamilyMemberHistoryOccurrenceInputField,
                addButtonLabel: 'Add occurrence',
                addNewDefault: {
                  onsetAgeInYears: null,
                  onsetYear: null,
                },
                showItemErrors: false,
              },
            },
            {
              name: 'enteredInError',
              label: 'Mark this entry as entered in error',
              formInputComponent: CheckboxField,
              message:
                'Marking this family history as entered in error will archive and remove it from the chart.',
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelFamilyMemberHistoryEdit
