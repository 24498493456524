import { jsDateToLocalDate, dateTimeFormatter } from 'common/data/date'
import { formatDisplayName } from 'common/utils'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

export type PatientMetadata = Partial<{
  birthDate: string
  namePrefix: string
  givenName: string
  middleName: string
  familyName: string
  nameSuffix: string
  credentials: string
}>

export type FooterMetadata = Partial<{
  patient: PatientMetadata
}>

const NameAndBirthDateDisplay = ({
  patient,
}: {
  patient: FooterMetadata['patient']
}): JSX.Element => {
  if (!patient) return

  const nameDisplay = formatDisplayName(patient)
  const birthDateDisplay = jsDateToLocalDate(patient.birthDate).format(
    dateTimeFormatter('MM/dd/yyyy')
  )

  if (!nameDisplay && !birthDateDisplay) return null

  return (
    <Typography
      textStyle="interface-default-xs"
      color="text-base-color-fg-muted"
    >
      {nameDisplay && birthDateDisplay
        ? `${nameDisplay} | DOB: ${birthDateDisplay}`
        : nameDisplay ?? birthDateDisplay}
    </Typography>
  )
}

export const Footer = ({
  footerMetadata,
  pageNumber,
  totalPages,
}: {
  footerMetadata?: FooterMetadata
  pageNumber: number
  totalPages: number
}) => {
  return (
    <StackView
      direction="row"
      justifyContent={footerMetadata ? 'between' : 'end'}
      className="border-t-core-border-width-20 border-base-color-border-default pt-core-space-100"
    >
      {footerMetadata ? (
        <NameAndBirthDateDisplay patient={footerMetadata.patient} />
      ) : null}
      <Typography
        textStyle="interface-default-xs"
        color="text-base-color-fg-muted"
      >
        Page {pageNumber} of {totalPages}
      </Typography>
    </StackView>
  )
}
