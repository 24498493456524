import { appointmentTypes } from 'common/data/appointmentTypes'
import { FeatureFlag } from 'types/graphql'

import { Option } from 'src/components/atoms/Select/Select'

export const VISIT_AVAILABILITY = 'visit-availability'
export const OFFICE_TIME = 'office-time'
export const WELL_CHILD_AVAILABILITY = 'well-child-availability'
export const EM_AVAILABILITY = 'em-availability'

const AVAILABILITY_TYPES = {
  [VISIT_AVAILABILITY]: {
    value: VISIT_AVAILABILITY,
    name: 'General availability',
  },
  [WELL_CHILD_AVAILABILITY]: {
    value: WELL_CHILD_AVAILABILITY,
    name: 'Well child availability',
  },
  [EM_AVAILABILITY]: {
    value: EM_AVAILABILITY,
    name: 'E&M availability',
  },
  [OFFICE_TIME]: {
    value: OFFICE_TIME,
    name: 'Office time',
  },
}

export const availabilityTypesLookup = (_user: {
  featureFlags: FeatureFlag[]
}) => AVAILABILITY_TYPES

export const availabilityTypeOptions = (user: {
  featureFlags: FeatureFlag[]
}): Option[] =>
  user.featureFlags.includes('AVAILABILITY_TIME_APPOINTMENT_TYPE_V2')
    ? [
        {
          value: VISIT_AVAILABILITY,
          name: 'General availability',
        },
        ...appointmentTypes,
        {
          value: OFFICE_TIME,
          name: 'Office time',
        },
      ]
    : Object.values(availabilityTypesLookup(user))
