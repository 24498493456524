import { useParams } from 'react-router-dom'

import { useConditionsQuery } from 'src/pages/PatientChartsPage/PatientHistory/Diagnoses/useConditions'
import SidepanelConditionEdit from 'src/pages/Sidepanel/SidepanelConditionEdit/SidepanelConditionEdit'

const SidepanelDiagnosisEdit = () => {
  const { patientId } = useParams()
  const { conditionsGroupedByCode } = useConditionsQuery(patientId, 'DIAGNOSIS')

  return (
    <SidepanelConditionEdit
      name="diagnosis"
      nameDisplay="diagnosis"
      existingConditions={conditionsGroupedByCode}
    />
  )
}

export default SidepanelDiagnosisEdit
