import clsx from 'clsx'

import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

interface MultiSelectHoverBarProps<T> {
  itemName: string
  /**
   * Useful if the item has a non-standard pluralization (e.g. "radius"/"radii")
   *
   * If not provided, add an "s" to pluralize
   */
  itemNamePlural?: string
  selected: T[]
  deselectAll: () => void
  actions: {
    key: string
    icon: React.FC<React.SVGProps<SVGSVGElement>>
    text: string
    onClick: (selected: T[]) => void
  }[]
}

// Typing generic react functional components is painful. Not using an arrow function because
// React.FC doesn't play nicely with generics.
// reference: https://stackoverflow.com/a/68757483
function MultiSelectHoverBar<T>({
  itemNamePlural,
  itemName,
  selected,
  deselectAll,
  actions,
}: React.PropsWithChildren<MultiSelectHoverBarProps<T>>) {
  const plural = itemNamePlural ?? itemName + 's'

  // requires parent to have `"relative"` className
  // styling reference: https://stackoverflow.com/questions/65749715/div-on-top-of-another-with-tailwind-css
  return (
    <StackView
      space={100}
      justifyContent="end"
      className="pointer-events-none absolute inset-0 z-10 !items-center p-10"
    >
      <StackView
        direction="row"
        space={100}
        alignItems="center"
        justifyContent="between"
        className={clsx([
          'radius-base-border-radius-container-s pointer-events-auto max-w-3xl',
          'border-base-border-width-container-s border-base-color-border-subtle bg-base-color-bg-default',
          'px-base-space-container-inset-s py-base-space-container-inset-xs',
          'shadow-base-box-shadow-container-l',
        ])}
      >
        <Typography
          textStyle="interface-default-s"
          className="whitespace-nowrap"
        >
          {selected.length} {selected.length === 1 ? itemName : plural} selected
        </Typography>

        <StackView direction="row" space={50} className="justify-end">
          {actions.map(({ key, icon: Icon, text, onClick }) => (
            <Button
              key={key}
              buttonStyle="secondary"
              onClick={() => onClick(selected)}
              icon={Icon}
              text={text}
            />
          ))}
          <Button buttonStyle="ghost" onClick={deselectAll}>
            Cancel
          </Button>
        </StackView>
      </StackView>
    </StackView>
  )
}

export default MultiSelectHoverBar
