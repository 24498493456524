import type { GuardianshipType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const guardianshipTypeDisplay: {
  [key in GuardianshipType]: string
} = {
  NATURAL: 'Natural',
  LEGAL: 'Legal',
  NON_GUARDIAN: 'Non-guardian',
}

export const guardianshipTypes = nameValueArrayFromObject<GuardianshipType>(
  guardianshipTypeDisplay
)
