import type {
  FindScreeningToolQuery,
  FindScreeningToolQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { screeningToolTypeDisplay } from 'src/data/screeningToolTypes'

import SidepanelDocumentPage from '../organisms/SidepanelDocumentPage/SidepanelDocumentPage'

export const QUERY = gql`
  query FindScreeningToolQuery($id: String!) {
    screeningTool: screeningTool(id: $id) {
      id
      updatedAt
      updatedBy {
        id
        givenName
        familyName
      }
      type
      title
      binary {
        id
        content {
          url
          contentType
        }
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindScreeningToolQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  screeningTool,
}: CellSuccessProps<
  FindScreeningToolQuery,
  FindScreeningToolQueryVariables
>) => {
  return (
    <SidepanelDocumentPage
      header={`Screening tool: ${screeningToolTypeDisplay[screeningTool.type]}`}
      document={screeningTool}
      testId="screening-tool-view"
      faxable
      editRoute={`/documents/screening-tool/${screeningTool.id}/edit`}
    />
  )
}
