import {
  GetAllPossibleImmunizationsForVaccineCVXSelectField,
  GetOrderTypesForCategory,
  GetOrderTypesForCategoryVariables,
  OrderCategory,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

const ORDER_TYPES_QUERY = gql`
  query GetOrderTypesForCategory($category: OrderCategory!) {
    orderTypes(category: $category) {
      id
      code
      display
      synonyms
      defaultICDCode
      defaultICD {
        id
        code
        description
      }
      defaultCPT {
        id
        code
        description
      }
    }
  }
`
export const useOrderTypes = (category: OrderCategory) => {
  return useQuery<GetOrderTypesForCategory, GetOrderTypesForCategoryVariables>(
    ORDER_TYPES_QUERY,
    {
      variables: {
        category,
      },
    }
  )
}

const GET_POSSIBLE_IMMUNIZATIONS = gql`
  query GetAllPossibleImmunizationsForVaccineCVXSelectField {
    possibleImmunizations {
      cvxCode
      develoVaccineName
      brands
      cvxDescription
    }
  }
`

export const usePossibleImmunizations = () =>
  useQuery<GetAllPossibleImmunizationsForVaccineCVXSelectField>(
    GET_POSSIBLE_IMMUNIZATIONS
  )
