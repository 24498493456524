import { formatDisplayName } from 'common/utils'
import type { SelectPractitioners } from 'types/graphql'

import SelectField, {
  SelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'

export type PractitionerSelectFieldProps = {
  practitioners: SelectPractitioners['practitioners']
  includeUnassigned?: boolean
} & SelectFieldProps

export const PractitionerSelectField = ({
  practitioners,
  name,
  includeUnassigned,
  ...rest
}: PractitionerSelectFieldProps) => {
  return (
    <SelectField
      name={name}
      emptyAs={null}
      {...rest}
      options={[
        ...(includeUnassigned
          ? [{ value: 'Unassigned', display: 'Unassigned' }]
          : []),
        ...practitioners.map((practitioner) => {
          return {
            value: practitioner.id,
            display: formatDisplayName(practitioner),
          }
        }),
      ]}
    />
  )
}

export default PractitionerSelectField
