const colorTokens = require('web/tokens/color.json')
const fontFamilyTokens = require('web/tokens/fontFamilies.json')
const fontSizeTokens = require('web/tokens/fontSizes.json')
const fontWeightTokens = require('web/tokens/fontWeights.json')

export type TilledFieldOptionsType = {
  fieldOptions?: {
    styles?: {
      base?: {
        fontFamily?: string
        color?: string
        fontWeight?: string
        fontSize?: string
      }
    }
  }
  onFocus?(field: { element: Element }): void
  onBlur?(field: { element: Element; empty: boolean }): void
  onError?(field: { element: Element }): void
}

export const tilledFieldOptions = {
  fieldOptions: {
    styles: {
      base: {
        fontFamily: fontFamilyTokens['base-font-family-body'],
        color: colorTokens['base-color-fg-success'],
        fontWeight:
          fontWeightTokens[
            'base-typography-interface-default-s-font-weight'
          ].toString(),
        fontSize:
          fontSizeTokens['base-typography-interface-default-s-font-size'],
      },
      invalid: {
        color: colorTokens['base-color-fg-danger'],
      },
      valid: {
        color: colorTokens['comp-textfield-color-clean-enabled-content'],
      },
    },
  },
}
