import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/20/solid'
import { format, parseISO } from 'date-fns'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

interface PatientMessageItemProps {
  text: string
  createdAt: string
  type: 'INBOUND' | 'OUTBOUND'
}

export const PatientMessageItem = ({
  text,
  createdAt,
  type,
}: PatientMessageItemProps) => {
  const iconClasses = 'h-5 w-5 text-base-color-fg-subtle'
  return (
    <Box
      horizontalPadding={50}
      verticalPadding={75}
      testId="case-patient-message-item"
    >
      <StackView direction="row" space={75} alignItems="start">
        <Box flex="1/12">
          <Box
            className="flex h-8 w-8 items-center justify-center rounded-full"
            color="bg-base-color-bg-subtle"
          >
            {type === 'INBOUND' ? (
              <BarsArrowDownIcon className={iconClasses} />
            ) : (
              <BarsArrowUpIcon className={iconClasses} />
            )}
          </Box>
        </Box>
        <Box grow>
          <StackView direction="col">
            <Typography textStyle="interface-strong-s">
              {type === 'INBOUND' ? 'Inbound response' : 'Outbound message'}
            </Typography>
            <StackView space={50} direction="row" alignItems="center" wrap>
              <Typography>{text}</Typography>
              <Typography>·</Typography>
              <Typography color="text-base-color-fg-muted" size="s">
                {format(parseISO(createdAt), "MMM d, yyyy 'at' h:mm a")}
              </Typography>
            </StackView>
          </StackView>
        </Box>
      </StackView>
    </Box>
  )
}
