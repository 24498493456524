import { Fragment } from 'react'

import clsx from 'clsx'
import { format } from 'date-fns'
import { OnDemandDemographicsFragment } from 'types/graphql'

import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

import { LabeledValue, Section, SubHeading } from '../common'
import { LetterHead } from '../common'

import { createCaregiverSection } from './helpers/createCaregiverSection'
import { createInsuranceSections } from './helpers/createInsuranceSections'
import { createPatientSection } from './helpers/createPatientSection'

export const PrintPatientDemographics = ({
  patient,
}: {
  patient: OnDemandDemographicsFragment
}) => {
  const primaryGuardianRelationship =
    patient.patientRelatedPersonRelationships.find(
      (relationship) =>
        relationship.relatedPerson.id === patient.primaryGuardianId
    )
  const primaryGuardian = primaryGuardianRelationship?.relatedPerson
  const primaryGuarantorRelationship =
    patient.patientRelatedPersonRelationships.find(
      (relationship) =>
        relationship.relatedPerson.id === patient.primaryGuarantorId
    )
  const primaryGuarantor = primaryGuarantorRelationship?.relatedPerson

  const primaryGuardianAndGuarantorAreSamePerson =
    primaryGuardian &&
    primaryGuarantor &&
    primaryGuardian.id === primaryGuarantor.id

  const patientSection = createPatientSection({ patient })

  const caregiverSections =
    patient.legalStatus !== 'DEPENDENT'
      ? []
      : primaryGuardianAndGuarantorAreSamePerson
      ? [
          createCaregiverSection({
            header: 'Primary guardian and guarantor',
            relationship: primaryGuardianRelationship,
          }),
        ]
      : [
          createCaregiverSection({
            header: 'Primary guardian',
            relationship: primaryGuardianRelationship,
          }),
          createCaregiverSection({
            header: 'Primary guarantor',
            relationship: primaryGuarantorRelationship,
          }),
        ]

  const insuranceSections = createInsuranceSections({ patient })

  const sections = [
    patientSection,
    ...caregiverSections,
    ...insuranceSections,
  ].map((section) => {
    return {
      ...section,
      rows: section.rows.flatMap((row) => {
        if (!row) return []

        const leftColumn = row.column.left.filter(([_key, value]) => !!value)
        const rightColumn = row.column.right.filter(([_key, value]) => !!value)

        if (!leftColumn.length && !rightColumn.length) return []

        return [
          {
            ...row,
            column: {
              left: leftColumn,
              right: rightColumn,
            },
          },
        ]
      }),
    }
  })

  return (
    <StackView gap={100}>
      <LetterHead tenant={patient.tenant} />
      <StackView gap={50}>
        <Typography textStyle="title-m">
          Demographics and insurance overview
        </Typography>
        <Typography>Generated on {format(new Date(), 'MM/dd/yyyy')}</Typography>
      </StackView>

      <div className="border-base-color-subtle border-t-core-border-width-40" />

      <StackView>
        <StackView>
          {sections.map((section, i) => {
            const isLastSection = i === sections.length - 1
            return (
              <Fragment key={section.header}>
                <Section header={section.header} subHeader={section.subHeader}>
                  {section.rows.map((row, j) => {
                    const isLastRow = j === section.rows.length - 1

                    return (
                      <StackView
                        gap={25}
                        key={row.title}
                        className={clsx(
                          'p-core-space-75',
                          !isLastRow ? 'border-b' : null
                        )}
                      >
                        <SubHeading>{row.title}</SubHeading>
                        <StackView
                          direction="row"
                          justifyContent="between"
                          fullWidth={false}
                        >
                          <StackView gap={25}>
                            {row.column?.left.map(([key, value]) => (
                              <LabeledValue
                                key={key}
                                label={key}
                                value={value}
                              />
                            ))}
                          </StackView>

                          <StackView gap={25}>
                            {row.column?.right.map(([key, value]) => (
                              <LabeledValue
                                key={key}
                                label={key}
                                value={value}
                              />
                            ))}
                          </StackView>
                        </StackView>

                        <Space space={75} />
                      </StackView>
                    )
                  })}
                </Section>
                {!isLastSection ? <Space space={75} /> : null}
              </Fragment>
            )
          })}
        </StackView>
      </StackView>
    </StackView>
  )
}
