import {
  ComboboxField,
  ComboboxFieldProps,
} from 'src/components/atoms/ComboboxField'
import { orderCategoryConfig } from 'src/data/orderCategories'
import { formatDateDisplay } from 'src/lib/formatters'
import { usePatientOrdersQuery } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'

export const PatientOrdersComboboxField = ({
  patientId,
  ...rest
}: Omit<ComboboxFieldProps, 'options'> & {
  patientId: string
}) => {
  const { activeOrders } = usePatientOrdersQuery(patientId)

  const options =
    activeOrders?.map((order) => {
      return {
        value: order.id,
        name: `${orderCategoryConfig[order.category].display}: ${
          order.codeDisplay
        } (${formatDateDisplay(order.createdAt)})`,
      }
    }) || []

  return <ComboboxField options={options} {...rest} />
}
