import { PaperAirplaneIcon } from '@heroicons/react/20/solid'
import { assertUnreachable } from 'common/utils'
import { format } from 'date-fns'
import {
  AppointmentPatientCheckInQuery,
  SendAppointmentTaskReminder,
  SendAppointmentTaskReminderVariables,
} from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import { useEmrAuth } from 'src/auth'
import Badge from 'src/components/atoms/Badge/Badge'
import Button from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import Divider from 'src/components/atoms/Divider/Divider'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'
import { formatPhoneNumber } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'

export const SEND_TASK_REMINDER = gql`
  mutation SendAppointmentTaskReminder(
    $appointmentId: String!
    $input: SendAppointmentTaskReminderInput
  ) {
    sendAppointmentPatientTaskReminder(
      appointmentId: $appointmentId
      input: $input
    ) {
      id
    }
  }
`

type Task =
  AppointmentPatientCheckInQuery['appointment']['patientFacingTasks'][number]

const getTaskTitle = (task: Task): string => {
  switch (task.__typename) {
    case 'PatientCheckInTask':
      return 'Patient check-in'
    case 'PatientRegistrationTask':
      return 'Patient registration'
    case 'PatientScreeningTask':
      return `${task.questionnaireName} questionnaire`
    default:
      assertUnreachable(task)
  }
}

export interface PatientVisitTasksSectionProps {
  appointment: {
    id: string
    patientFacingTasks: Task[]
  }
}

export const PatientVisitTasksSection = ({
  appointment,
}: PatientVisitTasksSectionProps) => {
  const [sendTaskReminder, { loading }] = useMutation<
    SendAppointmentTaskReminder,
    SendAppointmentTaskReminderVariables
  >(SEND_TASK_REMINDER)

  const { currentUser } = useEmrAuth()

  const location = useLocation()
  const params = useParams()

  return (
    <StackView space={75}>
      <StackView>
        <SectionHeader
          title="Tasks"
          actions={
            <Button
              text="Send"
              buttonStyle="secondary"
              icon={PaperAirplaneIcon}
              loading={loading}
              onClick={() =>
                currentUser.featureFlags.includes(
                  'APPOINTMENT_TASKS_REMINDER_SPECIFIC_CONTACT'
                )
                  ? navigate(
                      sidepanelRoute(
                        {
                          route: `/appointments/${appointment.id}/sendTasksReminder`,
                          onCloseSidepanelGoToPreviousRoute: true,
                        },
                        location,
                        params
                      )
                    )
                  : sendTaskReminder({
                      variables: {
                        appointmentId: appointment.id,
                      },
                      onCompleted: () => {
                        toast.success('Sent task to patient or caregiver')
                      },
                    })
              }
            />
          }
        />
        <Divider />
        <Space space={100} />
        <StackView gap={25}>
          {appointment.patientFacingTasks.map((task) => (
            <Card
              key={task.id}
              horizontalPadding={100}
              verticalPadding={75}
              testId="patient-task-card"
            >
              <StackView
                direction="row"
                justifyContent="between"
                alignItems="center"
              >
                <StackView>
                  <Typography
                    textStyle="interface-strong-s"
                    color="text-base-color-fg-muted"
                  >
                    {getTaskTitle(task)}
                  </Typography>
                  <Typography
                    textStyle="interface-default-xs"
                    color="text-base-color-fg-subtle"
                  >
                    {task.completedAt
                      ? `Completed by the patient or their caregiver (${formatPhoneNumber(
                          task.lastSentToNumber
                        )}) on ${format(
                          new Date(task.completedAt),
                          'MMM d, yyyy'
                        )}`
                      : task.lastSentAt
                      ? `Last sent to the patient or their caregiver (${formatPhoneNumber(
                          task.lastSentToNumber
                        )}) on ${format(
                          new Date(task.lastSentAt),
                          'MMM d, yyyy'
                        )}`
                      : 'Not yet sent to the patient or caregiver'}
                  </Typography>
                </StackView>

                <Badge
                  testId="task-status-badge"
                  text={task.isComplete ? 'Complete' : 'Incomplete'}
                  color={task.isComplete ? 'green' : 'red'}
                  showDot
                />
              </StackView>
            </Card>
          ))}
        </StackView>
      </StackView>
    </StackView>
  )
}
