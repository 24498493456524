export const vfcOptions = [
  {
    value: 'V01',
    name: 'Not VFC eligible [V01]',
  },
  {
    value: 'V02',
    name: 'Medicaid eligible and/or enrolled [V02]',
  },
  {
    value: 'V03',
    name: 'Uninsured [V03]',
  },
  {
    value: 'V04',
    name: 'American Indian or Alaska Native [V04]',
  },
  {
    value: 'V05',
    name: 'Federally qualified health center (FQHC) under-insured patient [V05]',
  },
  {
    value: 'V22',
    name: 'Children’s health insurance plan (CHIP) [V22]',
  },
  {
    value: 'V23',
    name: '317 funding [V23]',
  },
  {
    value: 'V25',
    name: 'State program eligibility [V25]',
  },
  {
    value: 'V07',
    name: 'Local program eligibility [V07]',
  },
  {
    value: 'V24',
    name: 'Medicare [V24]',
  },
  {
    value: 'V00',
    name: 'VFC eligibility not determined / unknown [V00]',
  },
]
