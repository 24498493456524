import { parseISO } from 'date-fns'
import mapValues from 'lodash/mapValues'
import maxBy from 'lodash/maxBy'
import {
  ConditionClinicalStatus,
  ConditionFilter,
  ConditionFragment,
  FindPatientConditions,
  UpsertPatientCondition,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

export type GroupedCondition = {
  display: string
  code: string
  lastOnsetAt: Date
  lastClinicalStatus: ConditionClinicalStatus
  lastConditionId: string
  conditions: FindPatientConditions['patient']['conditions']
}

const CONDITION_FRAGMENT = gql`
  fragment ConditionFragment on Condition {
    id
    externalId
    patientReference
    code {
      code
      display
      system
    }
    clinicalStatus
    verificationStatus
    onsetAt
    abatedAt
    updatedAt
  }
`

export const PATIENT_CONDITIONS_QUERY = gql`
  query FindPatientConditions($id: String!, $type: ConditionFilter!) {
    patient(id: $id) {
      id
      conditions(filter: $type) {
        ...ConditionFragment
      }
    }
  }
  ${CONDITION_FRAGMENT}
`

export const UPSERT_PATIENT_CONDITION_MUTATION = gql`
  mutation UpsertPatientCondition(
    $input: UpsertPatientConditionInput!
    $zCode: ConditionFilter = Z_CODE
    $diagnosis: ConditionFilter = DIAGNOSIS
  ) {
    upsertPatientDiagnosis(input: $input) {
      id
      zCodes: conditions(filter: $zCode) {
        ...ConditionFragment
      }
      diagnoses: conditions(filter: $diagnosis) {
        ...ConditionFragment
      }
    }
  }
  ${CONDITION_FRAGMENT}
`

const processConditions = (conditions: ConditionFragment[] | undefined) => {
  const conditionsLastUpdated =
    conditions?.length > 0
      ? maxBy(conditions, (a) => parseISO(a.updatedAt)).updatedAt
      : null

  const conditionsGroupedByCode = mapValues(
    conditions?.reduce((acc, condition) => {
      const { code } = condition

      if (!acc[code.code]) {
        acc[code.code] = {
          display: code.display,
          code: code.code,
          lastOnsetAt: parseISO(condition.onsetAt),
          lastClinicalStatus: condition.clinicalStatus,
          lastConditionId: condition.id,
          conditions: [condition],
        }
      } else {
        acc[code.code].conditions.push(condition)
        if (parseISO(condition.onsetAt) > acc[code.code].lastOnsetAt) {
          acc[code.code].lastOnsetAt = parseISO(condition.onsetAt)
          acc[code.code].lastClinicalStatus = condition.clinicalStatus
          acc[code.code].lastConditionId = condition.id
        }
      }

      return acc
    }, {} as Record<string, GroupedCondition>),
    (condition) => ({
      ...condition,
      conditions: condition.conditions.sort((a, b) => {
        return parseISO(b.onsetAt).getTime() - parseISO(a.onsetAt).getTime()
      }),
    })
  )

  return {
    conditions,
    conditionsLastUpdated,
    conditionsGroupedByCode,
  }
}

export const useConditionsQuery = (
  patientId: string,
  type: ConditionFilter
) => {
  const { data, loading, error } = useQuery<FindPatientConditions>(
    PATIENT_CONDITIONS_QUERY,
    { variables: { id: patientId, type } }
  )

  return {
    ...processConditions(data?.patient?.conditions),
    loading,
    error,
  }
}

export const useUpsertCondition = () => {
  return useMutation<UpsertPatientCondition>(
    UPSERT_PATIENT_CONDITION_MUTATION,
    {
      onCompleted: () => {
        toast.success('Condition saved')
      },
    }
  )
}
