import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import { pick, isEmpty } from 'lodash'
import type {
  FindExternalRequestQuery,
  FindExternalRequestQueryVariables,
  ExternalRequestType,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { CellSuccessProps, CellFailureProps, useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import ExternalRequestTypeSelectField from 'src/components/atoms/ExternalRequestTypeSelectField'
import FileInput from 'src/components/atoms/FileInput/FileInput'
import InputField from 'src/components/atoms/InputField/InputField'
import { QUERY as FIND_EXTERNAL_REQUESTS_QUERY } from 'src/components/ExternalRequestCell'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { externalRequestTypeDisplay } from 'src/data/externalRequestTypes'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

import SidepanelForm from '../molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from '../molecules/SidepanelPage/SidepanelPage'

export const QUERY = FIND_EXTERNAL_REQUESTS_QUERY

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindExternalRequestQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

const UPDATE_EXTERNAL_REQUEST_MUTATION = gql`
  mutation UpdateExternalRequest(
    $id: String!
    $input: UpdateExternalRequestInput!
  ) {
    updateExternalRequest(id: $id, input: $input) {
      id
      updatedAt
      updatedBy {
        id
        givenName
        familyName
      }
      type
      title
      binary {
        id
        content {
          url
          contentType
        }
      }
    }
  }
`

type FormData = {
  type?: ExternalRequestType
  title?: string
  /**
   * A list of files (max length 1) or undefined. Useful for the user to upload a single file.
   *
   * This matches the type used by `FileInput`
   */
  files?: FileList
}

export const Success = ({
  externalRequest,
}: CellSuccessProps<
  FindExternalRequestQuery,
  FindExternalRequestQueryVariables
>) => {
  const { closeSidePanel } = useSidepanel()
  const [updateExternalRequest, { loading: updatingExternalRequest }] =
    useMutation(UPDATE_EXTERNAL_REQUEST_MUTATION, {
      onCompleted: () => {
        toast.success(`External request updated`)
        closeSidePanel()
      },
      refetchQueries: ['ExternalRequestsQuery'],
    })
  const formMethods = useForm<FormData>({
    defaultValues: pick(externalRequest, 'type', 'title'),
    shouldUnregister: true,
  })
  const {
    formState: { dirtyFields },
  } = formMethods

  const hasDirtyFields = !isEmpty(dirtyFields)

  const onSubmit = async (value: FormData) => {
    const dirtyValues: FormData = Object.fromEntries(
      Object.entries(value).filter(([key, _]) => dirtyFields[key])
    )
    const { files, ...input } = dirtyValues
    if (files !== undefined && files.length === 1) {
      input['binary'] = {
        content: await base64EncodeFile(files[0]),
        contentType: files[0].type,
      }
    }

    updateExternalRequest({
      variables: { input, id: externalRequest.id },
    })
  }

  return (
    <SidepanelPage
      testId="external-request-edit"
      header={`External request: ${
        externalRequestTypeDisplay[externalRequest.type]
      }`}
      subHeader={externalRequest.title}
      description={`Last modified on ${format(
        new Date(externalRequest.updatedAt),
        'PP'
      )} · Last modified by ${formatDisplayName(externalRequest.updatedBy)}`}
    >
      <SidepanelForm
        footerProps={{
          disabled: !hasDirtyFields,
          submitText: 'Save',
          submitting: updatingExternalRequest,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <FormInputList
          items={[
            {
              name: 'type',
              label: 'Document sub-type',
              formInputComponent: ExternalRequestTypeSelectField,
            },
            {
              name: 'title',
              label: 'Document title',
              formInputComponent: InputField,
              required: false,
            },
            {
              name: 'files',
              label: 'Document upload',
              formInputComponent: FileInput,
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}
