import { PencilIcon } from '@heroicons/react/24/solid'

import { ButtonStyle } from 'src/components/atoms/Button'
import ButtonFooter from 'src/components/molecules/ButtonFooter/ButtonFooter'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

export type SidepanelButtonFooterProps = {
  onEdit?: () => void
  editText?: string
  hideEditIcon?: boolean
  submitText?: string
  disabled?: boolean
  submitting: boolean
  cancelText?: string
  onCancel?: () => void
  submitButtonStyle?: ButtonStyle
}

const SidepanelButtonFooter: React.FC<SidepanelButtonFooterProps> = ({
  onEdit,
  editText = 'Edit',
  hideEditIcon,
  submitText,
  disabled,
  submitting,
  cancelText = 'Cancel',
  onCancel,
  submitButtonStyle,
}) => {
  const { closeSidePanel } = useSidepanel()
  return (
    <ButtonFooter
      submitText={submitText}
      submitButtonStyle={submitButtonStyle}
      disabled={disabled}
      submitting={submitting}
      editButton={
        onEdit && {
          text: editText,
          testId: 'sidepanel-edit-btn',
          onClick: onEdit,
          position: 'right',
          icon: hideEditIcon ? undefined : PencilIcon,
        }
      }
      secondaryButton={{
        text: cancelText,
        testId: 'sidepanel-cancel-btn',
        onClick: onCancel ?? closeSidePanel,
        position: onCancel ? 'left' : 'right',
        buttonStyle: onCancel ? 'danger' : 'ghost',
      }}
      testId="sidepanel-button-footer"
      submitButtonTestId="sidepanel-confirm-btn"
    />
  )
}

export default SidepanelButtonFooter
