import { useParams } from 'react-router-dom'

import { useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'

import { CheckboxField } from 'src/components/atoms/Checkbox'
import StackView from 'src/components/atoms/StackView/StackView'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { orderCategoryConfig } from 'src/data/orderCategories'
import { formatDateDisplay } from 'src/lib/formatters'
import {
  ORDER_FRAGMENT,
  usePatientOrdersQuery,
} from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const QUERY = gql`
  query FetchResultsReviewCase($resultsReviewCaseId: String!) {
    resultsReviewCase(id: $resultsReviewCaseId) {
      id
      baseCaseId
      orders {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`

const RESOLVE_RESULTS_REVIEW_CASE_MUTATION = gql`
  mutation UpdateResultsReviewLinkedOrders(
    $resultsReviewCaseId: String!
    $serviceRequestIds: [String!]!
  ) {
    updateResultsReviewLinkedOrders(
      resultsReviewCaseId: $resultsReviewCaseId
      serviceRequestIds: $serviceRequestIds
    ) {
      id
      orders {
        ...OrderFragment
        patient {
          id
          givenName
          familyName
        }
      }
    }
  }
  ${ORDER_FRAGMENT}
`

const SidepanelResultsReviewEditOrders = () => {
  const { patientId, resultsReviewCaseId } = useParams()
  const { closeSidePanel } = useSidepanel()
  const { activeOrders, loading: isLoadingOrders } =
    usePatientOrdersQuery(patientId)
  const { data, loading: isLoadingCase } = useQuery(QUERY, {
    variables: {
      resultsReviewCaseId,
    },
  })
  const [updateLinkedOrders, { loading: updatingLinkedOrders }] = useMutation(
    RESOLVE_RESULTS_REVIEW_CASE_MUTATION
  )
  const formMethods = useForm<Record<string, boolean>>({
    defaultValues: Object.fromEntries(
      data?.resultsReviewCase?.orders?.map((order) => [order.id, true]) ?? []
    ),
  })

  const onSubmit = async (input: Record<string, boolean>) => {
    await updateLinkedOrders({
      variables: {
        resultsReviewCaseId,
        serviceRequestIds: Object.keys(input).filter(
          (orderId) => input[orderId]
        ),
      },
      onCompleted: () => {
        closeSidePanel()
      },
    })
  }

  return (
    <SidepanelPage
      header="Linked orders"
      description="Check the option(s) that apply."
      loading={isLoadingOrders || isLoadingCase}
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Save',
          cancelText: 'Cancel',
          submitting: updatingLinkedOrders,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <StackView space={25}>
          <FormInputList
            title="Orders"
            subtitle="Select all linked orders"
            divider={false}
            items={
              activeOrders?.map((order) => ({
                name: order.id,
                hideLabel: true,
                label: order.name,
                formInputComponent: CheckboxField,
                inputProps: {
                  label: `${orderCategoryConfig[order.category].display}: ${
                    order.codeDisplay
                  } (${formatDateDisplay(order.createdAt)})`,
                },
                direction: 'col',
              })) ?? []
            }
          />
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelResultsReviewEditOrders
