import { AllergyIntoleranceStatus } from 'types/graphql'

import { BadgeColor } from 'src/components/atoms/Badge/Badge'

import { nameValueArrayFromObject } from './utils'

export const allergyStatusDisplay: {
  [key in AllergyIntoleranceStatus]: string
} = {
  active: 'Active',
  inactive: 'Inactive',
  resolved: 'Resolved',
}

export const allergyStatusBadgeColor: {
  [key in AllergyIntoleranceStatus]: BadgeColor
} = {
  active: 'yellow',
  inactive: 'dark-gray',
  resolved: 'green',
}

export const allergyStatuses =
  nameValueArrayFromObject<AllergyIntoleranceStatus>(allergyStatusDisplay)
