import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import { FRAGMENT } from 'src/components/InsuranceEligibility/fragments'

const CREATE_INSURANCE_ELIGIBILITY_FOR_INSURANCE_COVERAGE = gql`
  mutation CreateInsuranceEligibilityForInsuranceCoverage(
    $id: String!
    $input: CreateInsuranceEligibilityInput!
    $appointmentId: String
  ) {
    createInsuranceEligibilityForInsuranceCoverage(id: $id, input: $input) {
      id
      mostRecentEligibility(appointmentId: $appointmentId) {
        ...InsuranceEligibilityFragment
      }
    }
  }
  ${FRAGMENT}
`

export const useCreateInsuranceEligibilityForInsuranceCoverage = ({
  afterCompleted,
}: {
  afterCompleted?: () => void
}) => {
  return useMutation(CREATE_INSURANCE_ELIGIBILITY_FOR_INSURANCE_COVERAGE, {
    onCompleted: () => {
      toast.success('Insurance eligibility created')
      if (afterCompleted) {
        afterCompleted()
      }
    },
    refetchQueries: ['FindPatientDemographicsQuery'],
  })
}

const CREATE_INSURANCE_ELIGIBILITY_FOR_PATIENT_REGISTRATION_INTENT = gql`
  mutation CreateInsuranceEligibilityForPatientRegistrationIntent(
    $id: String!
    $input: CreateInsuranceEligibilityInput!
    $appointmentId: String
  ) {
    createInsuranceEligibilityForPatientRegistrationIntent(
      id: $id
      input: $input
    ) {
      id
      mostRecentEligibility(appointmentId: $appointmentId) {
        ...InsuranceEligibilityFragment
      }
    }
  }
  ${FRAGMENT}
`

export const useCreateInsuranceEligibilityForPatientRegistrationIntent = ({
  afterCompleted,
}: {
  afterCompleted?: () => void
}) => {
  return useMutation(
    CREATE_INSURANCE_ELIGIBILITY_FOR_PATIENT_REGISTRATION_INTENT,
    {
      onCompleted: () => {
        toast.success('Eligibility created')
        if (afterCompleted) {
          afterCompleted()
        }
      },
    }
  )
}
