import { FC } from 'react'

import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import { navigate } from '@redwoodjs/router'
import { MetaTags } from '@redwoodjs/web'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

type PaddingType = 0 | 25 | 50 | 75 | 100 | 125 | 150 | 175 | 200
interface PageProps {
  tag?: {
    title: string
    description?: string
  }
  title: string
  description?: string
  details?: string
  actions?: React.ReactNode[]
  padding?: PaddingType
  navigateBack?: {
    to: string
    label: string
  }
}

const paddingMapping = {
  0: 'p-core-space-0',
  25: 'p-core-space-25',
  50: 'p-core-space-50',
  75: 'p-core-space-75',
  100: 'p-core-space-100',
  125: 'p-core-space-125',
  150: 'p-core-space-150',
  175: 'p-core-space-175',
  200: 'p-core-space-200',
}

const Page: FC<React.PropsWithChildren<PageProps>> = ({
  children,
  tag,
  title,
  description,
  details,
  actions,
  padding = 0,
  navigateBack,
}) => {
  return (
    <>
      {tag && <MetaTags title={tag.title} description={tag.description} />}
      <StackView space={125} className="grow">
        <StackView
          direction="row"
          gap={100}
          alignItems="center"
          justifyContent="between"
          className={clsx([paddingMapping[padding], 'pb-core-space-0'])}
        >
          <StackView space={50}>
            {navigateBack && (
              <Box>
                <Button
                  buttonStyle="ghost"
                  icon={ArrowLeftIcon}
                  text={navigateBack.label}
                  onClick={() => {
                    navigate(navigateBack.to)
                  }}
                />
              </Box>
            )}
            <Typography textStyle="title-l">{title}</Typography>
            {description && (
              <Typography textStyle="body-m" color="text-base-color-fg-muted">
                {description}
              </Typography>
            )}
          </StackView>
          {actions && !details && (
            <StackView direction="row" space={100} className="w-min">
              {actions}
            </StackView>
          )}
        </StackView>
        <Divider />
        {details && (
          <StackView
            direction="row"
            gap={100}
            alignItems="center"
            justifyContent="between"
            className={clsx([paddingMapping[padding], 'py-core-space-0'])}
          >
            <StackView>
              <Typography textStyle="body-s" color="text-base-color-fg-muted">
                {details}
              </Typography>
            </StackView>
            <StackView direction="row" space={100} className="w-min">
              {actions}
            </StackView>
          </StackView>
        )}
        <StackView
          className={clsx([
            paddingMapping[padding],
            'grow md:pb-core-space-100 lg:pb-core-space-200',
          ])}
          space={125}
        >
          {children}
        </StackView>
      </StackView>
    </>
  )
}

export default Page
