import { useEffect } from 'react'

import { navigate, useLocation } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/dist/toast'

const useQueryParamToast = () => {
  const { pathname, search, hash } = useLocation()
  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const toastMessage = searchParams.get('toastMessage')
    const toastStyle = searchParams.get('toastStyle') ?? 'success'

    if (toastMessage) {
      switch (toastStyle) {
        case 'success':
          toast.success(toastMessage)
          break
        case 'error':
          toast.error(toastMessage)
          break
        default:
          toast(toastMessage)
          break
      }

      searchParams.delete('toastMessage')
      searchParams.delete('toastStyle')

      navigate(`${pathname}?${searchParams.toString()}${hash}`)
    }
  }, [pathname, search, hash])
}

export default useQueryParamToast
