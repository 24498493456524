import { SelectFieldProps } from '@redwoodjs/forms/dist/SelectField'

import SelectField from 'src/components/atoms/SelectField/SelectField'
import { externalRequestTypes } from 'src/data/externalRequestTypes'

export const ExternalRequestTypeSelectField = (props: SelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={externalRequestTypes.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default ExternalRequestTypeSelectField
