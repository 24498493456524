import { SelectFieldProps } from '@redwoodjs/forms/dist/SelectField'

import SelectField from 'src/components/atoms/SelectField/SelectField'
import { presenceIndicators } from 'src/data/presenceIndicators'

export const MenstrualClotPresenceIndicatorSelectField = (
  props: SelectFieldProps
) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      includeEmptyOption={true}
      options={presenceIndicators
        .filter(({ value }) => value !== 'UNDETERMINED')
        .map(({ name, value }) => ({
          value,
          display: name,
        }))}
    />
  )
}

export default MenstrualClotPresenceIndicatorSelectField
