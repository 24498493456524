import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { FindPatientDemographicsQuery } from 'types/graphql'

import Badge from 'src/components/atoms/Badge'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { guardianshipTypeDisplay } from 'src/data/guardianshipTypes'

export const SiblingCaregiverInfo = ({
  siblingCaregiverRelationship,
  sharesWithPatient,
}: {
  siblingCaregiverRelationship: FindPatientDemographicsQuery['patient']['siblings'][0]['patientRelatedPersonRelationships'][0]
  sharesWithPatient?: boolean
}) => {
  return (
    <StackView
      className="rounded border px-core-space-75 py-core-space-100"
      direction="row"
      space={25}
      justifyContent="between"
      alignItems="center"
    >
      <StackView direction="col">
        <Typography
          textStyle="interface-default-s"
          color="text-base-color-fg-muted"
        >
          {siblingCaregiverRelationship.relatedPerson.preferredName
            ? `${formatDisplayName(
                siblingCaregiverRelationship.relatedPerson
              )} (${siblingCaregiverRelationship.relatedPerson.preferredName})`
            : formatDisplayName(siblingCaregiverRelationship.relatedPerson)}
        </Typography>
        <StackView direction="row" justifyContent="center" alignItems="center">
          <StackView direction="row" space={25}>
            <Typography textStyle="interface-default-xs">
              {siblingCaregiverRelationship.guardianshipType === 'NON_GUARDIAN'
                ? guardianshipTypeDisplay[
                    siblingCaregiverRelationship.guardianshipType
                  ]
                : `${
                    guardianshipTypeDisplay[
                      siblingCaregiverRelationship.guardianshipType
                    ]
                  } guardian`}
            </Typography>
            <Typography textStyle="interface-default-xs">&bull;</Typography>
            {siblingCaregiverRelationship.isGuarantor ? (
              <>
                <Typography textStyle="interface-default-xs">
                  Guarantor
                </Typography>
                <Typography textStyle="interface-default-xs">&bull;</Typography>
              </>
            ) : null}

            <Typography textStyle="interface-default-xs">
              {
                relationshipTypeDisplay[
                  siblingCaregiverRelationship.relationshipType
                ]
              }
            </Typography>
          </StackView>
        </StackView>
      </StackView>
      {sharesWithPatient ? (
        <Badge color="purple" size="l" text="Shared" />
      ) : null}
    </StackView>
  )
}
