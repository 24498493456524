import { useCallback, useContext, useEffect, useState } from 'react'

import { Eventcalendar, MbscCalendarEvent } from '@mobiscroll/react'
import { formatDisplayName } from 'common/utils'
import { addMinutes, format, isSameWeek, parse, parseISO } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import { Appointment } from 'types/graphql'

import { useForm, UseFormReturn, UseFormSetValue } from '@redwoodjs/forms'
import { navigate, useLocation, useParams } from '@redwoodjs/router'

import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import {
  useScheduleFilter,
  useUpdateFilter,
} from 'src/hooks/useScheduleFilter/useScheduleFilter'
import { formatDateDisplay, formatDateFieldValue } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'
import { NewAppointmentFormData } from 'src/pages/Sidepanel/SidepanelNewAppointment/SidepanelNewAppointment'
import { ResolveSchedulingCaseData } from 'src/pages/Sidepanel/SidepanelSchedulingCaseResolution/SidepanelSchedulingCaseResolution'
import { createNamedContext } from 'src/utils'

import { useSidepanel } from './SidepanelContext'

type CancellationType = 'now' | 'later' | 'never'
export type AppointmentToCancel = {
  appointmentId: string
  cancellationType: CancellationType
  cancelAppointmentCallback: () => void
}
interface State {
  templateEvent?: MbscCalendarEvent
  initiateAppointmentCreation: (
    event: MbscCalendarEvent,
    appointment?: Appointment
  ) => void
  updateTemplateEvent: (newValue: Partial<MbscCalendarEvent>) => void

  formMethods: UseFormReturn<NewAppointmentFormData | ResolveSchedulingCaseData>
  setNewAppointmentFormValue: UseFormSetValue<
    NewAppointmentFormData | ResolveSchedulingCaseData
  >

  appointmentToCancel?: AppointmentToCancel
  setAppointmentToCancel: (appointmentToCancel: AppointmentToCancel) => void
  resetAppointmentToCancel: () => void

  selectAppointment: (event: MbscCalendarEvent) => void
  selectedAppointmentEvent?: MbscCalendarEvent
  hasOverlapWithInvalid: (event: MbscCalendarEvent) => boolean
  setInstance: (instance: Eventcalendar) => void
  calendarInstance?: Eventcalendar
  appointmentDefinitionDurations: Record<string, number>
  setAppointmentDefinitionDurations: (
    appointmentDefinitionDurations: Record<string, number>
  ) => void

  isNewPatient?: boolean
  setIsNewPatient?: React.Dispatch<React.SetStateAction<boolean>>
  allowRescheduleNow: boolean
}

const AppointmentManagementContext = createNamedContext<State>(
  'AppointmentManagementContext'
)

export function useAppointmentManager() {
  const context = useContext(AppointmentManagementContext)
  return context
}

export function AppointmentManagementContextProvider({ children }) {
  const formMethods = useForm<
    NewAppointmentFormData | ResolveSchedulingCaseData
  >({
    shouldUnregister: true,
  })
  const { visitDate, locationIds, practitionerIds } = useScheduleFilter()
  const updateFilter = useUpdateFilter()
  const [isNewPatient, setIsNewPatient] = useState(false)
  const [calendarInstance, setInstance] = useState(undefined)
  const { isSidepanelOpen, sidepanelContext } = useSidepanel()
  const params = useParams()
  const location = useLocation()
  const { isPatientChart } = useIsPatientChart()

  const [selectedAppointmentEvent, setSelectedAppointmentEvent] =
    useState<MbscCalendarEvent>()
  const [templateEvent, setTemplateEvent] = useState<MbscCalendarEvent>()
  const [appointmentToCancel, setAppointmentToCancel] = useState<
    AppointmentToCancel | undefined
  >(undefined)
  const [appointmentDefinitionDurations, setAppointmentDefinitionDurations] =
    useState<Record<string, number>>()

  const updateTemplateEvent = useCallback(
    (newValue: Partial<MbscCalendarEvent>) => {
      if (!templateEvent) return

      setTemplateEvent((prev) => ({ ...prev, ...newValue }))
    },
    [templateEvent]
  )

  const hasOverlapWithInvalid = React.useCallback(
    (ev) => {
      const events = calendarInstance
        .getInvalids(ev.start, ev.end)
        .filter((e) => {
          const start =
            e.start instanceof Date ? e.start.toISOString() : e.start
          const end = e.end instanceof Date ? e.end.toISOString() : e.end

          return (
            e.resource === ev.resource &&
            e.type !== 'office-time' &&
            start !== end
          )
        })

      return events.length > 0
    },
    [calendarInstance]
  )

  const resetAppointmentToCancel = () => {
    setAppointmentToCancel(undefined)
  }

  const { setValue, watch } = formMethods

  const selectAppointment = (event: MbscCalendarEvent) => {
    if (templateEvent || formMethods.formState.isDirty) {
      resetAppointmentCreation()
    }

    setSelectedAppointmentEvent(event)
    navigate(
      sidepanelRoute(
        {
          route: `/appointments/${event.id}/visit`,
        },
        location,
        params
      )
    )
  }

  const resetAppointmentCreation = () => {
    setTemplateEvent(undefined)
    formMethods.reset(undefined, { keepDefaultValues: true })
  }
  const initiateAppointmentCreation = (
    event: MbscCalendarEvent,
    appointment
  ) => {
    if (selectedAppointmentEvent) setSelectedAppointmentEvent(undefined)
    if (templateEvent || formMethods.formState.isDirty) {
      resetAppointmentCreation()
    }
    // This means that we are rescheduling from table view
    if (!event && appointment) {
      event = {
        start: parseISO(appointment.start),
        end: parseISO(appointment.end),
        resource: `${appointment.practitionerId}-${appointment.locationId}`,
        appointmentObject: {
          ...appointment,
        },
      }
    }
    setTemplateEvent({
      title: 'New appointment',
      id: 'creating-appointment',
      start: event.start as Date,
      end: event.end as Date,
      cssClass:
        'pointer-events-none border-yellow-300 border-2 rounded-md bg-gray-100',
      resource: event.resource,
      color: '#E5E7EB',
    })

    const [practitionerId, locationId] = (event.resource as string).split('-')

    formMethods.setValue('date', event.start as Date)
    formMethods.setValue('location', locationId)
    formMethods.setValue('timeStart', format(event.start as Date, 'h:mm a'))
    formMethods.setValue('practitioner', practitionerId)
    formMethods.setValue('reason', {
      visitComment: event.appointmentObject?.visitComment,
      chiefComplaints: event.appointmentObject?.chiefComplaints,
      visitType: event.appointmentObject?.appointmentDefinitions.map(
        ({ id }) => id
      ) ?? [''],
    })

    if (event?.appointmentObject?.patientRegistrationIntent?.id) {
      formMethods.setValue(
        'registrationIntent',
        pick(event.appointmentObject.patientRegistrationIntent, [
          'givenName',
          'familyName',
          'birthDate',
          'phoneNumber',
          'email',
          'sexAtBirth',
        ])
      )
      setIsNewPatient(true)
    }

    if (event.appointmentObject?.patient) {
      formMethods.setValue('patient', event.appointmentObject.patient)
      formMethods.setValue(
        'patientDisplayText',
        `${formatDisplayName(
          event.appointmentObject.patient
        )} - ${formatDateDisplay(event.appointmentObject.patient.birthDate)}`
      )
      formMethods.setValue('patientId', event.appointmentObject.patient.id)
    }
    const isCaseResolution = location.pathname.startsWith('/cases/schedule/')
    const schedulingCaseId = location.pathname.split('/')[3]
    navigate(
      sidepanelRoute(
        {
          route: isCaseResolution
            ? `/scheduling-case/${schedulingCaseId}/resolve`
            : '/appointments/new',
        },
        location,
        {
          ...params,
          scheduleViewType: 'CALENDAR',
        }
      )
    )
  }

  useEffect(() => {
    const { unsubscribe } = watch(
      ({
        date,
        practitioner,
        location,
        reason,
        timeStart,
        patient,
        registrationIntent,
      }) => {
        if (
          date &&
          reason?.visitType &&
          timeStart &&
          appointmentDefinitionDurations &&
          templateEvent
        ) {
          const startTime = parse(
            `${format(date, 'yyyy-MM-dd')} ${timeStart}`,
            'yyyy-MM-dd h:mm a',
            new Date()
          )
          const duration = (reason.visitType || []).reduce(
            (acc, appointmentDefinitionId) => {
              if (!appointmentDefinitionId) {
                return acc
              }
              const result =
                acc + appointmentDefinitionDurations[appointmentDefinitionId]
              return result
            },
            0
          )
          const endTime = addMinutes(startTime, duration == 0 ? 10 : duration)
          const resource = `${practitioner}-${location}`
          const patientName = formatDisplayName(
            isNewPatient ? registrationIntent : patient
          )
          const title = `New appointment${
            patientName ? ` for ${patientName}` : ''
          }`

          if (
            title !== templateEvent.title ||
            startTime.toString() !== templateEvent.start?.toString() ||
            endTime.toString() !== templateEvent.end?.toString() ||
            resource !== templateEvent.resource
          ) {
            updateTemplateEvent({
              title,
              start: startTime,
              end: endTime,
              resource,
            })
          }
        }
      }
    )

    return () => unsubscribe()
  }, [
    watch,
    templateEvent,
    appointmentDefinitionDurations,
    updateTemplateEvent,
    isNewPatient,
  ])

  useEffect(() => {
    const { unsubscribe } = watch(({ date, practitioner, location }) => {
      if (!isSidepanelOpen) return

      const singleResourceDisplayed =
        practitionerIds?.length == 1 && locationIds?.length == 1

      if (
        date &&
        ((!singleResourceDisplayed &&
          formatDateFieldValue(date) !== formatDateFieldValue(visitDate)) ||
          (singleResourceDisplayed && !isSameWeek(date, visitDate)))
      ) {
        updateFilter('scheduleDateFilter', date, { saveSettings: false })
      }

      if (
        practitioner &&
        !isEmpty(practitionerIds) &&
        !practitionerIds.includes(practitioner)
      ) {
        updateFilter('schedulePractitionerFilter', [practitioner], {
          saveSettings: true,
        })
      }

      if (
        location &&
        !isEmpty(locationIds) &&
        !locationIds.includes(location)
      ) {
        updateFilter('scheduleLocationFilter', [location], {
          saveSettings: true,
        })
      }
    })

    return () => unsubscribe()
  }, [
    isSidepanelOpen,
    locationIds,
    practitionerIds,
    updateFilter,
    visitDate,
    watch,
  ])

  useEffect(() => {
    if (
      (!isSidepanelOpen ||
        (sidepanelContext.route !== '/appointments/new' &&
          !sidepanelContext.route.startsWith('/scheduling-case'))) &&
      templateEvent
    ) {
      resetAppointmentCreation()
    }
    // reacting to templateEvent will cause infinite re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidepanelOpen])

  return (
    <AppointmentManagementContext.Provider
      value={{
        initiateAppointmentCreation,
        templateEvent,
        updateTemplateEvent,

        formMethods,
        setNewAppointmentFormValue: setValue,

        appointmentToCancel,
        setAppointmentToCancel,
        resetAppointmentToCancel,

        selectAppointment,
        selectedAppointmentEvent,

        setAppointmentDefinitionDurations,
        appointmentDefinitionDurations,
        hasOverlapWithInvalid,
        setInstance,
        calendarInstance,

        isNewPatient,
        setIsNewPatient,
        allowRescheduleNow: !isPatientChart,
      }}
    >
      {children}
    </AppointmentManagementContext.Provider>
  )
}

export default AppointmentManagementContext
