import { useState, useContext, PropsWithChildren, useEffect } from 'react'

import { navigate, useLocation } from '@redwoodjs/router'

import useWindowDimensions from 'src/hooks/useWindowDimensions/useWindowDimensions'
import { createNamedContext } from 'src/utils'
export type { SidepanelWidth, SidepanelRouteContext } from 'src/utils/sidepanel'
import {
  setSidepanelContextDefaults,
  SidepanelPortalContext,
  SidepanelRouteContext,
} from 'src/utils/sidepanel'

export const SIDEPANEL_QUERY_PARAM_KEY = 'sidepanelContext'

interface SidepanelProps {
  isSidepanelOpen: boolean
  isSidepanelAvailable: boolean
  sidepanelContext: SidepanelRouteContext | SidepanelPortalContext
}

const SidepanelContext = createNamedContext<
  SidepanelProps & {
    closeSidePanel: () => void
    afterSidepanelFormSubmit?: (value) => void
    setSidepanelContext: (
      context: SidepanelRouteContext | SidepanelPortalContext
    ) => void
  }
>('Sidepanel')

export function useSidepanel() {
  const context = useContext(SidepanelContext)
  return context
}

export function SidepanelProvider({
  children,
}: PropsWithChildren<Partial<SidepanelProps>>) {
  const windowDimensions = useWindowDimensions()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const contextString = params.get(SIDEPANEL_QUERY_PARAM_KEY)

  const [isSidepanelOpen, setIsSidepanelOpen] = useState(false)
  const [isSidepanelAvailable, setIsSidepanelAvailable] = useState(
    windowDimensions.width > 1024
  )
  const [sidepanelContextRaw, setSidepanelContext] = useState<
    SidepanelRouteContext | SidepanelPortalContext
  >(contextString ? JSON.parse(contextString) : { route: '' })

  const [previousRoute, setPreviousRoute] = useState<string>(null)
  const [_currentRoute, setCurrentRoute] = useState<string>(null)

  useEffect(() => {
    if (windowDimensions.width > 1024) {
      setIsSidepanelAvailable(true)
    } else {
      setIsSidepanelAvailable(false)
      setIsSidepanelOpen(false)
    }
  }, [windowDimensions.width])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const contextString = params.get(SIDEPANEL_QUERY_PARAM_KEY)

    if (!contextString) {
      clearSidepanel()
      return
    }

    setIsSidepanelOpen(isSidepanelAvailable)
    setSidepanelContext(JSON.parse(contextString))
  }, [isSidepanelAvailable, location])

  useEffect(() => {
    setCurrentRoute((previous) => {
      const route = `${location.pathname}${location.search}`
      if (previous !== route) {
        setPreviousRoute(previous)
      }
      return route
    })
  }, [location, contextString])

  const clearSidepanel = () => {
    setIsSidepanelOpen(false)
    setSidepanelContext({ route: '' })
  }

  const closeSidePanel = () => {
    if (
      sidepanelContextRaw.closePortal &&
      typeof sidepanelContextRaw.closePortal === 'function'
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sidepanelContextRaw.closePortal()
      setSidepanelContextDefaults({ route: '' })
    } else {
      // If previous route is set, go to that route when closing the sidepanel
      if (
        previousRoute &&
        sidepanelContextRaw.onCloseSidepanelGoToPreviousRoute
      ) {
        navigate(previousRoute)
      } else if (params.get(SIDEPANEL_QUERY_PARAM_KEY)) {
        const params = new URLSearchParams(location.search)
        params.delete(SIDEPANEL_QUERY_PARAM_KEY)
        const stringParams = params.toString()
        navigate(
          `${location.pathname}${stringParams ? '?' : ''}${stringParams}`
        )
      } else {
        clearSidepanel()
      }
    }
  }

  const afterSidepanelFormSubmit = (value) => {
    if (typeof sidepanelContextRaw.afterSidepanelFormSubmit === 'function') {
      sidepanelContextRaw.afterSidepanelFormSubmit?.(value)
    }
  }

  const sidepanelContext = setSidepanelContextDefaults(sidepanelContextRaw)

  return (
    <SidepanelContext.Provider
      value={{
        isSidepanelOpen,
        isSidepanelAvailable,
        sidepanelContext,
        closeSidePanel,
        afterSidepanelFormSubmit,
        setSidepanelContext,
      }}
    >
      {children}
    </SidepanelContext.Provider>
  )
}

export default SidepanelContext
