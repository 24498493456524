import { formatPhoneNumber } from 'react-phone-number-input'
import {
  Address,
  BillingStatementTenantLetterheadFragment,
  TenantLetterheadFragment,
} from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { formatAddressLine1, formatAddressLine2 } from 'src/lib/formatters'

import { SubHeading } from './SubHeading'

export const LetterHead = ({
  tenant,
}: {
  tenant: TenantLetterheadFragment | BillingStatementTenantLetterheadFragment
}) => {
  return (
    <StackView
      direction="row"
      className="border-b-core-border-width-20 border-x-base-color-border-default pb-core-space-200"
      gap={100}
      justifyContent="between"
    >
      <StackView gap={50} className="max-w-[250px]" justifyContent="center">
        <div>
          <img
            className="max-h-[80px] w-auto object-contain"
            src={`/img/logos/${tenant.id}.png`}
            alt={tenant.name}
          />
        </div>
        <Typography>{tenant.websiteUrl}</Typography>
      </StackView>
      <StackView direction="row" gap={50} className="grow" reverse>
        {tenant.locations
          .filter((location) => !location.isExternalToPractice)
          .map((location) => (
            <StackView space={25} key={location.id} fullWidth={false}>
              <SubHeading>{location.name}</SubHeading>
              <Typography className="leading-4">
                {formatAddressLine1(location.address as Address)}
              </Typography>
              <Typography className="leading-4">
                {formatAddressLine2(location.address as Address)}
              </Typography>
              {location.phoneNumber && (
                <Typography className="leading-4">
                  P: {formatPhoneNumber(location.phoneNumber)}
                </Typography>
              )}
              {location.faxNumber && (
                <Typography className="leading-4">
                  F: {formatPhoneNumber(location.faxNumber.number)}
                </Typography>
              )}
            </StackView>
          ))}
      </StackView>
    </StackView>
  )
}
