import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { PencilIcon } from '@heroicons/react/24/solid'
import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import { isNil } from 'lodash'
import { FindDocumentCaseDetails } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button'
import Comment from 'src/components/atoms/Comment/Comment'
import Divider from 'src/components/atoms/Divider/Divider'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { AccordionItem } from 'src/components/molecules/Accordion/Accordion'
import DataDisplayList from 'src/components/molecules/DataDisplayList/DataDisplayList'
import { documentCaseDocumentSubtypeDisplay } from 'src/data/documentCaseDocumentSubTypes'
import { documentCaseDocumentTypeDisplay } from 'src/data/DocumentCaseDocumentTypes'
import { externalRequestTypeDisplay } from 'src/data/externalRequestTypes'
import { orderCategoryConfig } from 'src/data/orderCategories'
import { patientEducationDocumentTypeDisplay } from 'src/data/patientEducationDocumentTypes'
import { patientFormTypeDisplay } from 'src/data/patientFormTypes'
import { practiceAdminDocumentTypeDisplay } from 'src/data/practiceAdminDocumentTypes'
import { practiceFormTypeDisplay } from 'src/data/practiceformTypes'
import { practiceLetterTypeDisplay } from 'src/data/practiceLetterTypes'
import { screeningToolTypeDisplay } from 'src/data/screeningToolTypes'
import { usePDFAnnotationContext } from 'src/providers/context/PDFAnnotationContext'

const CREATE_NOTE_MUTATION = gql`
  mutation CreateDocunentCaseNote($input: CreateCaseNoteInput!) {
    createCaseNote(input: $input) {
      id
      text
      createdAt
      createdBy {
        id
        givenName
        familyName
      }
    }
  }
`

const QUERY_NOTE = gql`
  query LastNoteFromDocumentCase($baseCaseId: String!) {
    lastCaseNote(baseCaseId: $baseCaseId) {
      id
      text
      createdAt
      createdBy {
        id
        givenName
        familyName
      }
    }
  }
`

interface CaseNoteForm {
  caseNote: string
}

const DocumentCaseTitle = ({
  documentCase,
}: {
  documentCase: FindDocumentCaseDetails['documentCase']
}): React.JSX.Element => {
  const { setisAnnotatingPDF } = usePDFAnnotationContext()
  const canAnnotatePDF =
    documentCase?.binary?.content?.contentType === 'application/pdf'

  return (
    <StackView space={50} direction="row" justifyContent="between">
      <StackView space={25}>
        <Typography textStyle="title-s">
          {documentCase.title ?? 'Untitled document'}
        </Typography>
        {canAnnotatePDF ? (
          <Typography textStyle="body-xs" color="text-base-color-fg-muted">
            Saving changes on a directly edited document cannot be undone.
          </Typography>
        ) : null}
      </StackView>
      {canAnnotatePDF ? (
        <Button
          text="Sign"
          buttonStyle="secondary"
          icon={PencilIcon}
          onClick={() => setisAnnotatingPDF(true)}
        />
      ) : null}
    </StackView>
  )
}

const UnresolvedDocumentCaseDetails = ({
  data,
}: {
  data: FindDocumentCaseDetails
}) => {
  const {
    type,
    subType,
    baseCase: { updatedAt, createdAt },
  } = data.documentCase

  if (!type && !subType)
    return (
      <StackView space={75}>
        <DocumentCaseTitle documentCase={data?.documentCase} />
        <Divider />
        <DataDisplayList
          data={[
            {
              label: 'Created',
              value: createdAt
                ? format(new Date(createdAt), "MM/dd/yyyy 'at' hh:mm a")
                : undefined,
            },
          ]}
        />
      </StackView>
    )

  return (
    <StackView space={75}>
      <DocumentCaseTitle documentCase={data?.documentCase} />
      <Divider />
      <DataDisplayList
        data={[
          type
            ? {
                label: 'Type',
                value: documentCaseDocumentTypeDisplay[type],
              }
            : null,
          subType
            ? {
                label: 'Sub-type',
                value: documentCaseDocumentSubtypeDisplay[subType],
              }
            : null,
          updatedAt
            ? {
                label: 'Updated',
                value: updatedAt
                  ? format(new Date(updatedAt), "MM/dd/yyyy 'at' hh:mm a")
                  : undefined,
              }
            : null,
          {
            label: 'Created',
            value: createdAt
              ? format(new Date(createdAt), "MM/dd/yyyy 'at' hh:mm a")
              : undefined,
          },
        ].filter(Boolean)}
      />
    </StackView>
  )
}

const DocumentCaseDetails = ({
  documentCase,
}: {
  documentCase: FindDocumentCaseDetails['documentCase']
}) => {
  if (!documentCase) return null

  const {
    title,
    type,
    subType,
    baseCase: { createdAt },
    resolvedAt,
    resolvedBy,
  } = documentCase
  return (
    <>
      {title ? (
        <Box className="border-b border-base-color-border-subtle">
          <Typography textStyle="title-s">{title}</Typography>
        </Box>
      ) : null}
      <DataDisplayList
        dataDisplay="stacked"
        data={[
          type
            ? {
                label: 'Type',
                value: documentCaseDocumentTypeDisplay[type],
              }
            : null,
          subType
            ? {
                label: 'Sub-type',
                value: documentCaseDocumentSubtypeDisplay[subType],
              }
            : null,
          {
            label: 'Created on',
            value: createdAt
              ? format(new Date(createdAt), "MMMM d yyyy 'at' hh:mm a")
              : undefined,
          },
          resolvedAt
            ? {
                label: 'Resolved on',
                value: resolvedAt
                  ? `${format(
                      new Date(resolvedAt),
                      "MMMM d yyyy 'at' hh:mm a"
                    )} by ${formatDisplayName(resolvedBy)}`
                  : undefined,
              }
            : null,
        ].filter(Boolean)}
      />
    </>
  )
}

const ResolvedDocumentCaseDetails = ({
  data,
}: {
  data: FindDocumentCaseDetails
}) => {
  if (!data.documentCase) return null

  const {
    binary: {
      practiceForm,
      externalRequest,
      screeningTool,
      practiceLetter,
      patientEducationDocument,
      patientForm,
      practiceAdminDocument,
      clinicalResultsDocument,
    },
  } = data.documentCase

  if (
    [
      practiceForm,
      externalRequest,
      screeningTool,
      practiceLetter,
      patientEducationDocument,
      patientForm,
      practiceAdminDocument,
      clinicalResultsDocument,
    ].every((value) => isNil(value))
  ) {
    return <DocumentCaseDetails documentCase={data.documentCase} />
  }

  if (clinicalResultsDocument) {
    return (
      <>
        {clinicalResultsDocument.orders.map((order) => (
          <AccordionItem
            key={order.id}
            title={
              <StackView>
                <Typography>{order.name}</Typography>
                <Typography textStyle="description" size="m">
                  {order.code === 'OTHER' && order.category === 'REF'
                    ? `Other: ${order.freeTextCode}`
                    : order.category === 'SCH'
                    ? `Scheduling`
                    : order.codeDisplay}
                </Typography>
              </StackView>
            }
            bordered
          >
            <Box className="rounded-b-lg border-b border-l border-r p-6">
              <DataDisplayList
                dataDisplay="stacked"
                data={[
                  {
                    label: 'Ordering practitioner',
                    value: formatDisplayName(order.requester),
                  },
                  {
                    label: 'Patient',
                    value: formatDisplayName(order.patient),
                  },
                  {
                    label: 'Order start date',
                    value: format(parseISO(order.createdAt), 'MMM d, yyyy'),
                  },
                  {
                    label: 'Order category',
                    value: orderCategoryConfig[order.category].display,
                  },
                  {
                    label: 'Order reason',
                    value: order.reason,
                  },
                  {
                    label: 'Order comments',
                    value: order.comments,
                  },
                ]}
              />
            </Box>
          </AccordionItem>
        ))}
      </>
    )
  }

  if (data.documentCase.signedPracticeForm) {
    const { signedByPatient, signedByRelatedPerson } =
      data.documentCase.signedPracticeForm
    const signedBy = signedByPatient
      ? formatDisplayName(signedByPatient)
      : `${formatDisplayName(signedByRelatedPerson)} - ${
          relationshipTypeDisplay[
            signedByRelatedPerson.patientRelatedPersonRelationships[0]
              .relationshipType
          ]
        }`
    return (
      <DataDisplayList
        data={[
          {
            label: 'Document type',
            value:
              practiceFormTypeDisplay[
                data.documentCase.signedPracticeForm.practiceForm
                  .practiceFormDefinition.type
              ],
          },
          {
            label: 'Version',
            value: data.documentCase.signedPracticeForm.practiceForm.version,
          },
          {
            label: 'Signed at',
            value: parseISO(data.documentCase.signedPracticeForm.signedAt),
          },
          {
            label: 'Signed by',
            value: signedBy,
          },
        ]}
      />
    )
  }
  if (practiceForm) {
    return (
      <>
        <DataDisplayList
          data={[
            {
              label: 'Document type',
              value: 'Practice form',
            },
            {
              label: 'Document sub-type',
              value:
                practiceFormTypeDisplay[
                  practiceForm.practiceFormDefinition.type
                ],
            },
            {
              label: 'Template version',
              value: practiceForm.version,
            },
            {
              label: 'Check-in requirement',
              value: practiceForm.practiceFormDefinition.isRequired,
            },
            {
              label: 'Is active',
              value: practiceForm.isCurrent,
            },
            {
              label: 'Resolved on',
              value: parseISO(practiceForm.createdAt),
            },
          ]}
        />
      </>
    )
  }
  if (externalRequest) {
    return (
      <>
        <Typography textStyle="title">{externalRequest.title}</Typography>
        <DataDisplayList
          data={[
            {
              label: 'Document type',
              value: 'External request',
            },
            {
              label: 'Document sub-type',
              value: externalRequestTypeDisplay[externalRequest.type],
            },
            {
              label: 'Resolved on',
              value: parseISO(externalRequest.createdAt),
            },
          ]}
        />
      </>
    )
  }
  if (screeningTool) {
    return (
      <>
        <Typography textStyle="title">{screeningTool.title}</Typography>
        <DataDisplayList
          data={[
            {
              label: 'Document type',
              value: 'Screening tool',
            },
            {
              label: 'Document sub-type',
              value: screeningToolTypeDisplay[screeningTool.type],
            },
            {
              label: 'Resolved on',
              value: parseISO(screeningTool.createdAt),
            },
          ]}
        />
      </>
    )
  }
  if (practiceLetter) {
    return (
      <>
        <Typography textStyle="title">{practiceLetter.title}</Typography>
        <DataDisplayList
          data={[
            {
              label: 'Document type',
              value: 'Practice letter',
            },
            {
              label: 'Document sub-type',
              value: practiceLetterTypeDisplay[practiceLetter.type],
            },
            {
              label: 'Resolved on',
              value: parseISO(practiceLetter.createdAt),
            },
          ]}
        />
      </>
    )
  }
  if (patientEducationDocument) {
    return (
      <>
        <Typography textStyle="title">
          {patientEducationDocument.title}
        </Typography>
        <DataDisplayList
          data={[
            {
              label: 'Document type',
              value: 'Patient education',
            },
            {
              label: 'Document sub-type',
              value:
                patientEducationDocumentTypeDisplay[
                  patientEducationDocument.type
                ],
            },
            {
              label: 'Resolved on',
              value: parseISO(patientEducationDocument.createdAt),
            },
          ]}
        />
      </>
    )
  }
  if (practiceAdminDocument) {
    return (
      <>
        <Typography textStyle="title">
          {practiceAdminDocument.title || 'Practice admin document'}
        </Typography>
        <DataDisplayList
          data={[
            {
              label: 'Document type',
              value: 'Practice admin',
            },
            {
              label: 'Document sub-type',
              value:
                practiceAdminDocumentTypeDisplay[practiceAdminDocument.type],
            },
            {
              label: 'Resolved on',
              value: parseISO(practiceAdminDocument.createdAt),
            },
          ]}
        />
      </>
    )
  }
  if (patientForm) {
    return (
      <>
        <Typography textStyle="title">{patientForm.title}</Typography>
        <DataDisplayList
          data={[
            {
              label: 'Document type',
              value: 'Patient form',
            },
            {
              label: 'Document sub-type',
              value: patientFormTypeDisplay[patientForm.type],
            },
            { label: 'Resolved on', value: parseISO(patientForm.createdAt) },
          ]}
        />
      </>
    )
  }
  if (data.documentCase.resolvedStatus === 'CANCELLED') {
    return (
      <>
        <Typography textStyle="title">Untitled document</Typography>
        <Box
          rounded
          className="border-2 border-dashed text-center"
          padding={75}
        >
          <Typography color="text-base-color-fg-muted">
            {`This task has been cancelled by ${formatDisplayName(
              data.documentCase.resolvedBy
            )}`}
          </Typography>
        </Box>
      </>
    )
  }
  return null
}

const DetailsTab = ({ data }: { data: FindDocumentCaseDetails }) => {
  const formMethodsNote = useForm<CaseNoteForm>({
    defaultValues: {
      caseNote: '',
    },
  })
  const { data: lastNoteData } = useQuery(QUERY_NOTE, {
    variables: {
      baseCaseId: data.documentCase.baseCaseId,
    },
    onCompleted: (dataLastNote) => {
      formMethodsNote.setValue('caseNote', dataLastNote?.lastCaseNote?.text)
    },
  })
  const watchedLastNoteText = formMethodsNote.watch('caseNote')
  const [createCaseNote, { loading: creating }] =
    useMutation(CREATE_NOTE_MUTATION)

  return (
    <StackView
      className="h-full overflow-y-auto px-base-space-container-inset-s py-base-space-container-inset-m"
      justifyContent="between"
    >
      <Box padding={50}>
        <StackView space={50}>
          {data.documentCase.resolvedAt ? (
            <ResolvedDocumentCaseDetails data={data} />
          ) : (
            <>
              <UnresolvedDocumentCaseDetails data={data} />
              <Space space={125} />
              <StackView
                className="rounded-lg border bg-base-color-bg-accent p-core-space-75 text-start"
                direction="row"
                space={50}
              >
                <InformationCircleIcon height={24} width={24} color="#004EEB" />
                <StackView>
                  <Typography className="text-blue-700" textStyle="body-s">
                    Select Resolve on the top right to enter document details
                    and resolve the task, or cancel the task if it was made in
                    error. Status update can be used to enter document details
                    without directly resolving the task.
                  </Typography>
                </StackView>
              </StackView>
            </>
          )}
        </StackView>
      </Box>
      <StackView>
        <Divider />
        <Box padding={75}>
          <StackView space={75}>
            <Typography textStyle="title">Task notes</Typography>
            <Form
              formMethods={formMethodsNote}
              onSubmit={({ caseNote }) => {
                void createCaseNote({
                  refetchQueries: [
                    'GetActivityItems',
                    'LastNoteFromDocumentCase',
                  ],
                  variables: {
                    input: {
                      baseCaseId: data.documentCase.baseCaseId,
                      text: caseNote,
                    },
                  },
                })
              }}
            >
              <Comment
                disabled={
                  creating ||
                  (lastNoteData &&
                    lastNoteData?.lastCaseNote?.text === watchedLastNoteText)
                }
                name="caseNote"
                placeholder="Add your note..."
                submitButtonText={
                  lastNoteData?.lastCaseNote?.text ? 'Update note' : 'Add note'
                }
              />
            </Form>
          </StackView>
        </Box>
      </StackView>
    </StackView>
  )
}

export default DetailsTab
