import { useEffect } from 'react'

import clsx from 'clsx'
import {
  TargetDateRangeType,
  TargetTimePrefix,
  TargetTimeUnit,
} from 'types/graphql'

import { RegisterOptions, useFormContext } from '@redwoodjs/forms'

import { DatePickerFieldThatWorks } from 'src/components/molecules/DatePicker'
import { formatDateFieldValue } from 'src/lib/formatters'

import Box from '../Box'
import { DropdownField } from '../Dropdown'
import InputField from '../InputField'
import StackView from '../StackView'
import Typography from '../Typography/Typography'

const PREFIX_OPTIONS = [
  { name: 'Is after', value: 'AFTER' },
  { name: 'Is before', value: 'BEFORE' },
  { name: 'Is on', value: 'ON' },
  { name: 'Is between', value: 'BETWEEN' },
]

const UNIT_OPTIONS = [
  { name: 'Days', value: 'DAY' },
  { name: 'Weeks', value: 'WEEK' },
  { name: 'Months', value: 'MONTH' },
  { name: 'Years', value: 'YEAR' },
  { name: 'Date', value: 'CUSTOM' },
]

const RANGE_TYPE_OPTIONS = [
  { name: 'Date', value: 'DATE' },
  { name: 'Age', value: 'AGE' },
]

const PrefixDropdown = ({
  name,
  fieldName = 'prefix',
  required,
  className,
}: {
  name: string
  fieldName?: string
  required: boolean
  className?: string
}) => {
  return (
    <Box>
      <DropdownField
        data-testid={`target-date-field-${fieldName}`}
        name={`${name}.${fieldName}`}
        options={PREFIX_OPTIONS}
        className={clsx(['!border-0 !shadow-none', className])}
        showChevron={false}
        validation={{
          required,
          shouldUnregister: true,
        }}
      />
    </Box>
  )
}

const RangeTypeDropdown = ({
  name,
  fieldName = 'rangeType',
  required,
  className,
}: {
  name: string
  fieldName?: string
  required: boolean
  className?: string
}) => {
  return (
    <Box>
      <DropdownField
        data-testid={`target-date-field-${fieldName}`}
        name={`${name}.rangeType`}
        options={RANGE_TYPE_OPTIONS}
        className={clsx(['!border-0 !shadow-none', className])}
        showChevron={false}
        validation={{
          required,
          shouldUnregister: true,
        }}
      />
    </Box>
  )
}

const UnitDropdown = ({
  name,
  fieldName = 'unit',
  required,
  options,
  className,
}: {
  name: string
  fieldName?: string
  required: boolean
  options?: {
    value: string
    name: string
  }[]
  className?: string
}) => {
  return (
    <Box>
      <DropdownField
        data-testid={`target-date-field-${fieldName}`}
        name={`${name}.${fieldName}`}
        options={options ?? UNIT_OPTIONS}
        className={clsx(['!border-0 !shadow-none', className])}
        showChevron={false}
        validation={{
          required,
          shouldUnregister: true,
        }}
      />
    </Box>
  )
}

const NumberInput = ({
  name,
  fieldName = 'value',
  required,
  className,
}: {
  name: string
  fieldName?: string
  required: boolean
  className?: string
}) => {
  return (
    <InputField
      testId={`target-date-field-${fieldName}`}
      emptyAs={null}
      fullWidth
      name={`${name}.${fieldName}`}
      className={clsx([
        'rounded-none !border-0 !shadow-none',
        '[appearance:textfield]',
        '[&::-webkit-inner-spin-button]:appearance-none',
        '[&::-webkit-outer-spin-button]:appearance-none',
        className,
      ])}
      placeholder="0"
      type="number"
      validation={{
        required,
        shouldUnregister: true,
      }}
    />
  )
}

const SingleDateInput = ({
  name,
  fieldName = 'date',
  required,
}: {
  name: string
  fieldName?: string
  required: boolean
}) => {
  return (
    <DatePickerFieldThatWorks
      className="last-calendar"
      name={`${name}.${fieldName}`}
      mode="date"
      controls={['calendar']}
      validation={{
        setValueAs: (value) => formatDateFieldValue(value),
        required,
        shouldUnregister: true,
      }}
      placeholder="Date"
      showIcon
      grow
    />
  )
}

const RangeDateInput = ({
  name,
  required,
}: {
  name: string
  required: boolean
}) => {
  return (
    <StackView direction="row" space={50} alignItems="center">
      <DatePickerFieldThatWorks
        className="rounded-none"
        name={`${name}.date`}
        mode="date"
        controls={['calendar']}
        validation={{
          setValueAs: (value) => formatDateFieldValue(value),
          required,
          shouldUnregister: true,
        }}
        placeholder="Date"
        showIcon
        grow
      />

      <Typography className="grow text-center">to</Typography>

      <DatePickerFieldThatWorks
        className="last-calendar"
        name={`${name}.dateEnd`}
        mode="date"
        controls={['calendar']}
        validation={{
          setValueAs: (value) => formatDateFieldValue(value),
          required,
          shouldUnregister: true,
        }}
        placeholder="Date"
        showIcon
        grow
      />
    </StackView>
  )
}

const RangeUnitInput = ({
  name,
  required,
}: {
  name: string
  required: boolean
}) => {
  return (
    <StackView direction="row" space={25} alignItems="center">
      <NumberInput
        name={name}
        fieldName="value"
        required={required}
        className="px-1 text-center"
      />
      <UnitDropdown
        name={name}
        fieldName="unit"
        required={required}
        options={UNIT_OPTIONS.filter((o) => o.value !== 'CUSTOM')}
        className="!px-0"
      />

      <Typography className="px-3 text-center">to</Typography>

      <NumberInput
        name={name}
        fieldName="valueEnd"
        required={required}
        className="px-1 text-center"
      />
      <UnitDropdown
        name={name}
        fieldName="unitEnd"
        required={required}
        options={UNIT_OPTIONS.filter((o) => o.value !== 'CUSTOM')}
        className="!px-0 !pr-2"
      />
    </StackView>
  )
}

const DefaultRender = ({
  name,
  unit,
  required,
}: {
  name: string
  unit: TargetTimeUnit
  required: boolean
}) => {
  return (
    <>
      {unit !== 'CUSTOM' && <NumberInput name={name} required={required} />}
      <UnitDropdown name={name} required={required} />
      {unit === 'CUSTOM' && <SingleDateInput name={name} required={required} />}
    </>
  )
}

const ComponentChooser = ({
  name,
  prefix,
  unit,
  required,
}: {
  name: string
  prefix: TargetTimePrefix
  unit: TargetTimeUnit
  required: boolean
}) => {
  const { watch } = useFormContext()
  const rangeType = watch(`${name}.rangeType`)

  switch (prefix as TargetTimePrefix) {
    case 'AFTER':
      return <DefaultRender name={name} unit={unit} required={required} />
    case 'BEFORE':
      return <DefaultRender name={name} unit={unit} required={required} />
    case 'ON':
      return <SingleDateInput name={name} required={required} />
    case 'BETWEEN':
      return (
        <>
          <RangeTypeDropdown name={name} required={required} />
          {rangeType === 'DATE' ? (
            <RangeDateInput name={name} required={required} />
          ) : (
            <RangeUnitInput name={name} required={required} />
          )}
        </>
      )
    default:
      return <DefaultRender name={name} unit={unit} required={required} />
  }
}

const TargetDateField = ({
  name,
  validation,
}: {
  name: string
  validation?: RegisterOptions
}) => {
  const { watch, setValue } = useFormContext()
  const prefix = watch(`${name}.prefix`)
  const unit = watch(`${name}.unit`)
  const rangeType = watch(`${name}.rangeType`)
  const required = !!validation?.required

  useEffect(() => {
    if (!prefix) {
      setValue(`${name}.prefix`, 'AFTER' as TargetTimePrefix)
    }
    if (!unit) {
      setValue(`${name}.unit`, 'DAY' as TargetTimeUnit)
    }

    switch (prefix) {
      case 'AFTER':
        setValue(`${name}.date`, undefined)
        setValue(`${name}.dateEnd`, undefined)
        setValue(`${name}.valueEnd`, undefined)
        setValue(`${name}.unitEnd`, undefined)
        setValue(`${name}.rangeType`, undefined)
        break
      case 'BEFORE':
        setValue(`${name}.date`, undefined)
        setValue(`${name}.dateEnd`, undefined)
        setValue(`${name}.valueEnd`, undefined)
        setValue(`${name}.unitEnd`, undefined)
        setValue(`${name}.rangeType`, undefined)
        break
      case 'ON':
        setValue(`${name}.unit`, 'CUSTOM' as TargetTimeUnit)
        setValue(`${name}.dateEnd`, undefined)
        setValue(`${name}.value`, undefined)
        setValue(`${name}.valueEnd`, undefined)
        setValue(`${name}.unitEnd`, undefined)
        setValue(`${name}.rangeType`, undefined)
        break
      case 'BETWEEN':
        if (!rangeType) {
          setValue(`${name}.rangeType`, 'DATE' as TargetDateRangeType)
        }

        if (rangeType === 'DATE') {
          setValue(`${name}.value`, undefined)
          setValue(`${name}.valueEnd`, undefined)
          setValue(`${name}.unit`, 'CUSTOM' as TargetTimeUnit)
          setValue(`${name}.unitEnd`, 'CUSTOM' as TargetTimeUnit)
        } else if (rangeType === 'AGE') {
          setValue(`${name}.date`, undefined)
          setValue(`${name}.dateEnd`, undefined)

          if (!unit || unit === 'CUSTOM') {
            setValue(`${name}.unit`, 'MONTH' as TargetTimeUnit)
            setValue(`${name}.unitEnd`, 'MONTH' as TargetTimeUnit)
          }
        }
        break
    }
  }, [name, prefix, unit, rangeType, setValue])

  return (
    <Box className="target-date-field rounded-md border shadow-sm">
      <StackView direction="row" alignItems="center" divider>
        <PrefixDropdown name={name} required={required} />
        <ComponentChooser
          name={name}
          prefix={prefix}
          unit={unit}
          required={required}
        />
      </StackView>
    </Box>
  )
}

export default TargetDateField
