import { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import { NdcSaleDetails } from 'types/graphql'

import InputField from 'src/components/atoms/InputField'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import DataDisplayList from 'src/components/molecules/DataDisplayList/DataDisplayList'
import FormInputList from 'src/components/molecules/FormInputList'

type NdcDisplayProps = {
  data: NdcSaleDetails
}

type NdcSearchDisplayProps = {
  defaultNdc?: string
  onNdcDataReceived?: (ndcData: NdcSaleDetails) => void
}

const NDC_QUERY = gql`
  query NDCLookup($ndcSale: String!) {
    ndcLookup(ndcSale: $ndcSale) {
      id
      ndcSale
      ndcUse
      saleProprietaryName
      cvxCode
      cvxDescription
      brandName
      manufacturer
      cvxCodeAndDescription
      defaultCPT1
      defaultCPT2
      billingCode1
      billingCode1Description
      billingCode2
      billingCode2Description
      defaultDoseAmount
      defaultDoseUnit
      defaultRoute
      vaccineGroupCodes
      vaccineGroupDescription
    }
  }
`

const NdcDisplay = ({ data }: NdcDisplayProps) => {
  return (
    <>
      <DataDisplayList
        title="Sale NDC details"
        grayedOut={!data}
        data={[
          {
            label: 'Brand Name',
            value: <Typography>{data?.brandName || '-'}</Typography>,
          },
          {
            label: 'Manufacturer',
            value: <Typography>{data?.manufacturer || '-'}</Typography>,
          },
          {
            label: 'National Drug Code, use',
            value: <Typography>{data?.ndcUse.join(', ') || '-'}</Typography>,
          },
          {
            label: 'CVX code & description',
            value: (
              <Typography>{data?.cvxCodeAndDescription || '-'}</Typography>
            ),
          },
        ]}
      />

      <DataDisplayList
        title="Additional vaccine information"
        grayedOut={!data}
        data={[
          {
            label: 'Default dose amount',
            value: <Typography>{data?.defaultDoseAmount || '-'}</Typography>,
          },
          {
            label: 'Default dose unit',
            value: <Typography>{data?.defaultDoseUnit || '-'}</Typography>,
          },
          {
            label: 'Default route',
            value: <Typography>{data?.defaultRoute || '-'}</Typography>,
          },
          {
            label: 'Default CPT code(s)',
            value: (
              <StackView space={50}>
                <Typography>{data?.defaultCPT1 || '-'}</Typography>
                {data?.defaultCPT2 && data?.defaultCPT2 !== '-' && (
                  <Typography>{data?.defaultCPT2}</Typography>
                )}
              </StackView>
            ),
          },
        ]}
      />
    </>
  )
}

const NdcSearchDisplay = ({
  defaultNdc,
  onNdcDataReceived,
}: NdcSearchDisplayProps) => {
  const [ndcLookup, { data: ndcData }] = useLazyQuery(NDC_QUERY)

  const handleNdcLookup = (event) => {
    ndcLookup({ variables: { ndcSale: event.target.value } })
  }

  useEffect(() => {
    if (defaultNdc) {
      ndcLookup({ variables: { ndcSale: defaultNdc } })
    }
  }, [defaultNdc, ndcLookup])

  useEffect(() => {
    onNdcDataReceived(ndcData?.ndcLookup)
  }, [ndcData, onNdcDataReceived])

  return (
    <StackView space={75}>
      <Typography textStyle="title">NDC overview</Typography>

      <FormInputList
        items={[
          {
            name: `ndcSale`,
            label: 'National Drug Code, sale',
            required: true,
            formInputComponent: InputField,
            inputProps: {
              placeholder: '#####-####-##',
              onChange: handleNdcLookup,
            },
          },
        ]}
      />
      <NdcDisplay data={ndcData?.ndcLookup} />
    </StackView>
  )
}

export default NdcSearchDisplay
