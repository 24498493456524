import { Fragment } from 'react'

import clsx from 'clsx'
import { formatMoneyInCents } from 'common/utils'
import type {
  BillingStatementClaim,
  PatientDetailedBillingStatement,
} from 'types/graphql'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography, { TypographyColor } from 'src/components/atoms/Typography'
import { formatNumberAsOrdinal } from 'src/lib/formatters'

import { LetterHead } from './common'

const FIRST_COLUMN_TABLE_CELL_WIDTH_CLASS = '!w-[40%]'
const TABLE_CELL_WIDTH_CLASS = '!w-[12%]'

const MessageAndSummarySection = ({
  patientBalanceCents,
  guarantorName,
  charges,
  credits,
  hasPaymentPlan,
  promptCopy,
}: {
  patientBalanceCents: number
  guarantorName: string
  charges: number
  credits: number
  hasPaymentPlan: boolean
  promptCopy: string
}) => {
  let message: string
  let description: string
  if (patientBalanceCents > 0) {
    message = `Hello ${guarantorName}, your balance of ${formatMoneyInCents(
      patientBalanceCents
    )} is due.`
    description = promptCopy
  } else {
    message = `Hello ${guarantorName}, you have no balance due.`
    description = 'Thank you for paying your statement.'
  }

  return (
    <StackView space={150} direction="row" alignItems="center">
      <Box flex="7/12">
        <StackView space={50}>
          <Typography textStyle="title-l">{message}</Typography>
          <Typography textStyle="body-m" color="text-base-color-fg-muted">
            {description}
          </Typography>
        </StackView>
      </Box>

      <SummarySection
        charges={charges}
        credits={credits}
        patientBalanceCents={patientBalanceCents}
        hasPaymentPlan={hasPaymentPlan}
      />
    </StackView>
  )
}

const SummarySectionRow = ({
  title,
  value,
  color = 'text-base-color-fg-muted',
  testId,
}: {
  title: string
  value: number
  color?: TypographyColor
  testId?: string
}) => {
  return (
    <StackView
      direction="row"
      className="p-core-space-75"
      justifyContent="between"
    >
      <Typography
        textStyle="interface-default-s"
        color="text-base-color-fg-muted"
      >
        {title}
      </Typography>
      <Typography
        textStyle="interface-default-s"
        color={color}
        data-testid={testId}
      >
        {formatMoneyInCents(value)}
      </Typography>
    </StackView>
  )
}

export const SummarySection = ({
  charges,
  credits,
  patientBalanceCents,
  hasPaymentPlan,
}: {
  charges: number
  credits: number
  patientBalanceCents: number
  hasPaymentPlan: boolean
}) => {
  return (
    <Box flex="5/12">
      <Box rounded border>
        <Box
          color="bg-base-color-bg-subtle"
          horizontalPadding={75}
          verticalPadding={50}
        >
          <Typography
            textStyle="interface-strong-xs"
            color="text-base-color-fg-muted"
          >
            Summary{hasPaymentPlan && ' excluding payment plan'}
          </Typography>
        </Box>
        <StackView divider>
          <SummarySectionRow title="Charges" value={charges} testId="charges" />
          <SummarySectionRow
            title="Payments and credits"
            value={credits}
            color={
              credits < 0
                ? 'text-base-color-fg-success'
                : 'text-base-color-fg-muted'
            }
            testId="credits"
          />
          <SummarySectionRow
            title="Amount due"
            value={patientBalanceCents}
            color={
              patientBalanceCents <= 0
                ? 'text-base-color-fg-success'
                : 'text-base-color-fg-danger'
            }
            testId="amount-due"
          />
        </StackView>
      </Box>
    </Box>
  )
}

const VisitSectionTableHeaderCell = ({
  text,
  firstColumn = false,
  indent = false,
}: {
  text: string
  firstColumn?: boolean
  indent?: boolean
}) => {
  const textDirection = firstColumn ? 'text-left' : 'text-right'
  return (
    <Typography
      textStyle="interface-strong-xs"
      color="text-base-color-fg-muted"
      className={clsx(
        textDirection,
        indent && 'pl-core-space-100',
        firstColumn
          ? FIRST_COLUMN_TABLE_CELL_WIDTH_CLASS
          : TABLE_CELL_WIDTH_CLASS,
        'uppercase'
      )}
    >
      {text}
    </Typography>
  )
}

const TableRowCell = ({
  text,
  subtext,
  color = 'text-base-color-fg-muted',
  firstColumn = false,
  indent = false,
  bold = false,
  testId,
}: {
  text: string
  subtext?: string
  color?: TypographyColor
  firstColumn?: boolean
  indent?: boolean
  bold?: boolean
  testId?: string
}) => {
  const textDirection = firstColumn ? 'text-left' : 'text-right'
  return (
    <StackView
      className={
        firstColumn
          ? FIRST_COLUMN_TABLE_CELL_WIDTH_CLASS
          : TABLE_CELL_WIDTH_CLASS
      }
      data-testid={testId}
    >
      <Typography
        textStyle={bold ? 'interface-strong-s' : 'interface-default-s'}
        color={color}
        className={clsx(textDirection, indent && 'pl-core-space-100')}
      >
        {text}
      </Typography>
      {subtext && (
        <Typography
          textStyle="interface-default-xs"
          color="text-base-color-fg-subtle"
          className={clsx(textDirection, indent && 'pl-core-space-100')}
        >
          {subtext}
        </Typography>
      )}
    </StackView>
  )
}

const VisitSectionRow = ({
  serviceDescription,
  serviceSubDescription,
  initialCost,
  adjustments,
  insurancePaid,
  youPaid,
  youOwe,
  testIdPrefix,
  isPrimaryService = false,
  showAdjustmentAsterisk = false,
}: {
  serviceDescription: string
  serviceSubDescription: string
  initialCost: number
  adjustments: number
  insurancePaid: number
  youPaid: number
  youOwe: number
  testIdPrefix: string
  isPrimaryService?: boolean
  showAdjustmentAsterisk?: boolean
}) => {
  const adjustmentsDisplay = -adjustments
  const insurancePaidDisplay = -insurancePaid
  const youPaidDisplay = -youPaid
  return (
    <StackView
      direction="row"
      className="p-core-space-75"
      justifyContent="between"
      alignItems="start"
    >
      <TableRowCell
        text={serviceDescription}
        subtext={serviceSubDescription}
        firstColumn
        indent={!isPrimaryService}
        bold={isPrimaryService}
        testId={`${testIdPrefix}-service`}
      />
      <TableRowCell
        text={formatMoneyInCents(initialCost)}
        testId={`${testIdPrefix}-initial-cost`}
      />
      <TableRowCell
        text={`${formatMoneyInCents(adjustmentsDisplay, { zeroValue: '-' })}${
          showAdjustmentAsterisk ? '*' : ''
        }`}
        color={
          adjustmentsDisplay < 0
            ? 'text-base-color-fg-success'
            : 'text-base-color-fg-muted'
        }
        testId={`${testIdPrefix}-adjustments`}
      />
      <TableRowCell
        text={formatMoneyInCents(insurancePaidDisplay, { zeroValue: '-' })}
        color={
          insurancePaidDisplay < 0
            ? 'text-base-color-fg-success'
            : 'text-base-color-fg-muted'
        }
        testId={`${testIdPrefix}-insurance-paid`}
      />
      <TableRowCell
        text={formatMoneyInCents(youPaidDisplay, { zeroValue: '-' })}
        color={
          youPaidDisplay < 0
            ? 'text-base-color-fg-success'
            : 'text-base-color-fg-muted'
        }
        testId={`${testIdPrefix}-you-paid`}
      />
      <TableRowCell
        text={formatMoneyInCents(youOwe)}
        color={
          youOwe > 0
            ? 'text-base-color-fg-danger'
            : 'text-base-color-fg-success'
        }
        testId={`${testIdPrefix}-you-owe`}
      />
    </StackView>
  )
}

const VisitSection = ({
  encounter,
  index,
}: {
  encounter: PatientDetailedBillingStatement['patient']['billingStatement']['billingStatementEncounters'][number]
  index: number
}) => {
  return (
    <StackView space={50}>
      <Typography
        textStyle="interface-strong-s"
        color="text-base-color-fg-default"
        testId={`visit-section-${index}-description`}
      >
        {encounter.visitDescription}
      </Typography>
      <Box rounded border data-testid="visit-section">
        <Box
          color="bg-base-color-bg-subtle"
          horizontalPadding={75}
          verticalPadding={50}
          data-testid={`visit-section-${index}-header`}
        >
          <StackView
            direction="row"
            justifyContent="between"
            alignItems="center"
          >
            <VisitSectionTableHeaderCell text="Service type" firstColumn />
            <VisitSectionTableHeaderCell text="Cost" />
            <VisitSectionTableHeaderCell text="Adj." />
            <VisitSectionTableHeaderCell text="Ins. paid" />
            <VisitSectionTableHeaderCell text="You paid" />
            <VisitSectionTableHeaderCell text="You owe" />
          </StackView>
        </Box>
        <StackView divider>
          <VisitSectionRow
            serviceDescription={encounter.primaryServiceDescription}
            serviceSubDescription={encounter.primaryServiceSubDescription}
            initialCost={encounter.claims[0].chargeAmountCents}
            adjustments={encounter.claims[0].adjustmentsColumnAmountCents}
            insurancePaid={encounter.claims[0].paidAmountCents}
            youPaid={encounter.patientPaidAmountCents}
            youOwe={encounter.claims[0].patientOwesAmountCents}
            testIdPrefix={`visit-section-${index}`}
            showAdjustmentAsterisk={
              encounter.claims[0].visitRemainderIsGreaterThanWhatPatientOwes
            }
            isPrimaryService
          />
          {encounter.claims[0].serviceLines.length > 0 && (
            <>
              <Box
                color="bg-base-color-bg-subtle"
                horizontalPadding={75}
                verticalPadding={50}
                data-testid={`visit-section-${index}-billing-codes-header`}
              >
                <StackView
                  direction="row"
                  justifyContent="between"
                  alignItems="center"
                >
                  <VisitSectionTableHeaderCell
                    text="Billing codes"
                    firstColumn
                    indent
                  />
                </StackView>
              </Box>
              {encounter.claims[0].serviceLines.map(
                (serviceLine, serviceLineIndex) => (
                  <VisitSectionRow
                    key={serviceLineIndex}
                    serviceDescription={serviceLine.billingCode}
                    serviceSubDescription={serviceLine.description}
                    initialCost={serviceLine.chargeAmountCents}
                    adjustments={serviceLine.adjustmentAmountCents}
                    insurancePaid={serviceLine.paidAmountCents}
                    youPaid={serviceLine.patientPaidAmountCents}
                    youOwe={serviceLine.patientOwesAmountCents}
                    testIdPrefix={`visit-section-${index}-service-line-${serviceLineIndex}`}
                  />
                )
              )}
            </>
          )}
        </StackView>
      </Box>
    </StackView>
  )
}

const FootnoteSection = ({ tenantName }: { tenantName: string }) => {
  return (
    <Typography
      textStyle="interface-default-xs"
      color="text-base-color-fg-muted"
    >
      * The displayed amount does not include manual adjustments made by{' '}
      {tenantName}.
    </Typography>
  )
}

const PaymentPlanSection = ({
  paymentPlan,
}: {
  paymentPlan: PatientDetailedBillingStatement['patient']['billingStatement']['currentPaymentPlan']
}) => {
  return (
    <StackView space={100}>
      <StackView>
        <Typography textStyle="title-xs">Payment plan</Typography>
        <Typography textStyle="body-s" color="text-base-color-fg-muted">
          Billed {formatMoneyInCents(paymentPlan.termAmountCents)} on the{' '}
          {formatNumberAsOrdinal(paymentPlan.billingDayOfMonth)} of each month.
        </Typography>
      </StackView>
      <Box rounded border data-testid="payment-plan-section">
        <Box
          color="bg-base-color-bg-subtle"
          horizontalPadding={75}
          verticalPadding={50}
          data-testid="payment-plan-section-header"
        >
          <Typography
            textStyle="interface-strong-xs"
            color="text-base-color-fg-muted"
          >
            {paymentPlan.termMonths}-month payment plan
          </Typography>
        </Box>
        <StackView divider>
          <StackView
            direction="row"
            className="p-core-space-75"
            alignItems="center"
            justifyContent="between"
          >
            <TableRowCell text="Total balance" firstColumn />
            <TableRowCell
              text={formatMoneyInCents(paymentPlan.totalBalanceCents)}
            />
          </StackView>
          <StackView
            direction="row"
            className="p-core-space-75"
            alignItems="center"
            justifyContent="between"
          >
            <TableRowCell
              text="Paid to date"
              subtext={`${
                paymentPlan.successfulPaymentPlanPayments.length
              } payment${
                paymentPlan.successfulPaymentPlanPayments.length === 1
                  ? ''
                  : 's'
              } of ${formatMoneyInCents(
                paymentPlan.termAmountCents
              )} completed`}
              firstColumn
            />
            <TableRowCell
              text={`-${formatMoneyInCents(paymentPlan.totalPaidAmountCents)}`}
              color="text-base-color-fg-success"
            />
          </StackView>
          <StackView
            direction="row"
            className="p-core-space-75"
            alignItems="center"
            justifyContent="between"
          >
            <TableRowCell
              text="Amount remaining"
              subtext={`${paymentPlan.paymentsRemaining} payment${
                paymentPlan.paymentsRemaining === 1 ? '' : 's'
              } of ${formatMoneyInCents(
                paymentPlan.termAmountCents
              )} remaining`}
              firstColumn
            />
            <TableRowCell
              text={formatMoneyInCents(paymentPlan.remainingBalanceCents)}
              color="text-base-color-fg-danger"
            />
          </StackView>
        </StackView>
      </Box>
    </StackView>
  )
}

const PrintPatientDetailedBillingStatement = ({
  billingStatement,
  testId,
}: {
  billingStatement: PatientDetailedBillingStatement['patient']['billingStatement']
  testId?: string
}) => {
  return (
    <StackView space={150} data-testid={testId}>
      <LetterHead tenant={billingStatement.tenant} />
      <MessageAndSummarySection
        patientBalanceCents={billingStatement.summaryBalanceCents}
        guarantorName={billingStatement.guarantorName}
        charges={billingStatement.summaryChargeCents}
        credits={-billingStatement.summaryCreditCents}
        hasPaymentPlan={!!billingStatement.currentPaymentPlan}
        promptCopy={billingStatement.promptCopy}
      />
      {billingStatement.billingStatementEncounters.length > 0 ||
      billingStatement.currentPaymentPlan ? (
        <>
          {billingStatement.currentPaymentPlan && (
            <PaymentPlanSection
              paymentPlan={billingStatement.currentPaymentPlan}
            />
          )}
          {billingStatement.billingStatementEncounters.length > 0 && (
            <StackView space={100}>
              <StackView>
                <Typography textStyle="title-xs">Charges</Typography>
                <Typography textStyle="body-s" color="text-base-color-fg-muted">
                  Balance accumulated through visits and standalone charges.
                  Partial payments are only reflected at the service level and
                  not against individual billing codes.
                </Typography>
              </StackView>
              {billingStatement.billingStatementEncounters.map(
                (encounter, index) => (
                  <Fragment key={encounter.id}>
                    {encounter.claims.length > 0 && (
                      <VisitSection
                        key={encounter.id}
                        index={index}
                        encounter={encounter}
                      />
                    )}
                  </Fragment>
                )
              )}
            </StackView>
          )}
          {billingStatement.billingStatementEncounters.some((encounter) =>
            encounter.claims.some(
              (claim: BillingStatementClaim) =>
                claim.visitRemainderIsGreaterThanWhatPatientOwes
            )
          ) && <FootnoteSection tenantName={billingStatement.tenant.name} />}
        </>
      ) : (
        <Box className="pb-core-space-100" rounded border>
          <StackView direction="row" justifyContent="center">
            <Typography textStyle="body-s" color="text-base-color-fg-muted">
              No balance history available
            </Typography>
          </StackView>
        </Box>
      )}
    </StackView>
  )
}

export default PrintPatientDetailedBillingStatement
