import { SelectFieldProps } from '@redwoodjs/forms/dist/SelectField'

import SelectField from 'src/components/atoms/SelectField/SelectField'
import { tobaccoSmokingStatuses } from 'src/data/tobaccoSmokingStatuses'

export const TobaccoSmokingStatusSelectField = (props: SelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      includeEmptyOption={true}
      options={tobaccoSmokingStatuses.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default TobaccoSmokingStatusSelectField
