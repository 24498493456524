import { TestResult } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const labTestResultDisplay: {
  [key in TestResult]: string
} = {
  POSITIVE: 'Positive',
  NEGATIVE: 'Negative',
  INCONCLUSIVE: 'Inconclusive',
  NOT_APPLICABLE: 'N/A',
}

export const labTestResults =
  nameValueArrayFromObject<TestResult>(labTestResultDisplay)
