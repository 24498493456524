import { useEffect, useMemo } from 'react'

import { isEmpty } from '@medplum/core'
import { observationConcepts } from 'common/cdr/concepts/observations/index'
import {
  unitDisplay,
  unitEnumToUCUMUnitMap,
} from 'common/unitConverter/unitConverter'
import { compact, omit } from 'lodash'
import {
  Finding,
  InHouseTestOrderType,
  ObservationDefinition,
  OrderWithObservationsFragment,
} from 'types/graphql'

import {
  UseFormRegisterReturn,
  useFieldArray,
  useForm,
  useFormContext,
} from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/dist/toast'

import Button from 'src/components/atoms/Button'
import Divider from 'src/components/atoms/Divider'
import { FieldLabel } from 'src/components/atoms/Label'
import StackView from 'src/components/atoms/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import Typography from 'src/components/atoms/Typography/Typography'
import FieldError from 'src/components/FieldError/FieldError'
import { InputFieldWithSelectableUnit } from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import {
  OrderRefusal,
  OverallFindingInput,
} from 'src/components/Order/CompleteScreeningOrderSidepanelForm'
import { useCompleteInHouseTestOrderMutation } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const INTERNAL_URINALYSIS_CODES = new Set<InHouseTestOrderType>([
  'URINALYSIS_NON_AUTOMATED_WITHOUT_MICROSCOPY',
  'URINALYSIS_AUTOMATED_WITHOUT_MICROSCOPY',
])

const FindingInput = ({
  name,
  observationDefinition,
  hideLabel,
  disabled,
}: {
  name: string
  observationDefinition: ObservationDefinition
  hideLabel?: boolean
  disabled?: boolean
}) => {
  if (isEmpty(observationDefinition)) return null

  const { permittedDataType, permittedUnit, label } = observationDefinition

  return (
    <StackView space={50}>
      {!hideLabel && label && (
        <FieldLabel name={`${name}`} className="font-semibold">
          <Typography textStyle="subtitle" fontWeight="semibold">
            {label}
          </Typography>
        </FieldLabel>
      )}
      {permittedDataType === 'Quantity' && permittedUnit && (
        <>
          <InputFieldWithSelectableUnit
            disabled={disabled}
            name={`${name}.unitInput`}
            unitOptions={[
              {
                name:
                  unitDisplay[unitEnumToUCUMUnitMap[permittedUnit]] ??
                  unitEnumToUCUMUnitMap[permittedUnit],
                value: permittedUnit,
              },
            ]}
            setValueAsString
          />
          <FieldError
            name={`${name}.unitInput.value`}
            defaultErrorMessage={`${label} has an error`}
          />
        </>
      )}
      {permittedDataType === 'CodeableConcept' && (
        <MultiRadioButtonField
          disabled={disabled}
          labelClassName="font-normal"
          name={`${name}.answerCode`}
          values={observationDefinition.validCodedValueSet.map((coding) => {
            return { label: coding.display, value: coding.code }
          })}
        />
      )}
    </StackView>
  )
}

const FindingsInputs = ({
  observationDefinitions,
  registerFindingFn,
}: {
  observationDefinitions: ObservationDefinition[]
  registerFindingFn: ({
    findingsName,
    fieldArrayIndex,
  }: {
    findingsName: string
    fieldArrayIndex: string
  }) => UseFormRegisterReturn
}) => {
  return (
    <>
      {observationDefinitions.map(
        (observationDefinition: ObservationDefinition, index) => (
          <>
            <FindingInput
              key={observationDefinition.id}
              name={observationDefinition.questionCode}
              {...registerFindingFn({
                findingsName: 'findings',
                fieldArrayIndex: index.toString(),
              })}
              observationDefinition={observationDefinition}
            />
            <Divider />
          </>
        )
      )}
    </>
  )
}

const InHouseTestFindings = ({ order }) => {
  const formMethods = useFormContext()

  const { register } = formMethods

  const registerFinding = useMemo(() => {
    const registerFindingMemo = ({
      findingsName,
      fieldArrayIndex,
    }: {
      findingsName: string
      fieldArrayIndex: string
    }): UseFormRegisterReturn | undefined => {
      return register(`${findingsName}.${fieldArrayIndex}`)
    }
    return registerFindingMemo
  }, [register])

  useFieldArray({
    name: 'findings',
  })

  if (isEmpty(order) || isEmpty(order.observationDefinitions)) return null

  const { observationDefinitions } = order

  return (
    <StackView space={75}>
      <StackView direction="row" justifyContent="between">
        <Typography textStyle="title" fontWeight="semibold">
          Findings
        </Typography>

        {INTERNAL_URINALYSIS_CODES.has(order.code) && (
          <Button
            disabled={order.doNotPerform}
            buttonStyle="secondary"
            onClick={() => {
              formMethods.reset({
                refusalReasons: order.refusalReasons,
                notes: order.observations?.[0]?.notes,
                // observations[0] will contain the parent code observation if a 'panel'
                // (i.e. more than one observation definition)
                // otherwise it will contain the question code observation
                findings:
                  order.observationDefinitions.length > 1
                    ? buildNegativeFindings({
                        order: order,
                        orderCode: order.code,
                      })
                    : undefined,
                overallFinding:
                  order.observationDefinitions.length === 1
                    ? buildNegativeFindings({
                        order: order,
                        orderCode: order.code,
                      })[0]
                    : undefined,
              })
            }}
          >
            Mark all as negative
          </Button>
        )}
      </StackView>
      <StackView space={100}>
        {observationDefinitions.length === 1 ? ( // Single answer - Overall Finding
          <>
            <OverallFindingInput
              observationDefinition={observationDefinitions[0]}
              disabled={order.doNotPerform}
            />
            <Divider />
          </>
        ) : observationDefinitions.length > 1 ? (
          <FindingsInputs
            observationDefinitions={observationDefinitions}
            registerFindingFn={registerFinding}
          />
        ) : (
          'No definitions for this observation'
        )}
      </StackView>
    </StackView>
  )
}

const InHouseTestNotes = ({ order }) => {
  return (
    <StackView className="pb-4" space={50}>
      <FieldLabel name="notes">
        <Typography textStyle="subtitle">Notes</Typography>
      </FieldLabel>
      <TextAreaField disabled={order.doNotPerform} name="notes" rows={5} />
    </StackView>
  )
}

const buildQuantityFinding = ({
  observationDefinition,
  value,
}: {
  observationDefinition: ObservationDefinition
  value: number | string
}): Finding => {
  if (isEmpty(observationDefinition)) return

  return {
    questionCode: observationDefinition.questionCode,
    unitInput: {
      value: value?.toString(),
      unit: observationDefinition.permittedUnit,
    },
  }
}

const buildCodeableConceptFinding = ({
  observationDefinition,
  value,
}: {
  observationDefinition: ObservationDefinition
  value: number | string
}): Finding => {
  if (isEmpty(observationDefinition)) return

  return {
    questionCode: observationDefinition.questionCode,
    answerCode: value?.toString(),
  }
}

const buildFinding = ({
  observationDefinition,
  value,
}: {
  observationDefinition: ObservationDefinition
  value: number | string
}): Finding => {
  if (observationDefinition.permittedDataType === 'Quantity') {
    return buildQuantityFinding({ observationDefinition, value })
  } else if (observationDefinition.permittedDataType === 'CodeableConcept') {
    return buildCodeableConceptFinding({ observationDefinition, value })
  }
  return { questionCode: observationDefinition.questionCode }
}

const buildDefaultFinding = ({
  observationDefinition,
  observationValue,
}: {
  observationDefinition: ObservationDefinition
  observationValue: number | string
}): Finding => {
  return buildFinding({
    observationDefinition,
    value: observationValue ?? '',
  })
}

export const buildDefaultFindings = ({
  order,
  orderCode,
}: {
  order: OrderWithObservationsFragment
  orderCode?: string
}) => {
  if (isEmpty(order.observationDefinitions)) return []

  // for each observation definition on the order:
  // find the observation on the order following that definition
  // and retrieve its value to build the appropriate Finding
  return compact(
    order.observationDefinitions.map((observationDefinition) => {
      return {
        orderCode,
        ...buildDefaultFinding({
          observationDefinition: observationDefinition,
          observationValue: order.observations.filter((observation) => {
            return (
              observation.coding[0].code === observationDefinition.questionCode
            )
          })[0]?.value?.value,
        }),
      }
    })
  )
}

const buildNegativeFindings = ({
  order,
  orderCode,
}: {
  order: OrderWithObservationsFragment
  orderCode?: string
}) => {
  if (isEmpty(order.observationDefinitions)) return []
  return order.observationDefinitions.map((observationDefinition) => {
    return {
      orderCode,
      ...(observationDefinition.permittedDataType === 'Quantity'
        ? buildQuantityFinding({
            observationDefinition: observationDefinition,
            value: order.observations.filter((observation) => {
              return (
                observation.coding[0].code ===
                observationDefinition.questionCode
              )
            })[0]?.value?.value,
          })
        : buildCodeableConceptFinding({
            observationDefinition: observationDefinition,
            value: observationConcepts['INTERPRETATION_NEGATIVE'].code,
          })),
    }
  })
}

export const CompleteInHouseTestOrderSidepanelForm = ({
  patientId,
  encounterId,
  order,
}: {
  patientId: string
  encounterId: string
  order: OrderWithObservationsFragment
}) => {
  const [completeInHouseTestOrder, { loading: completingOrder }] =
    useCompleteInHouseTestOrderMutation()

  const { closeSidePanel } = useSidepanel()

  const defaultFormValues = useMemo(() => {
    return {
      notes: order.observations?.[0]?.notes || '',
      findings:
        order.observationDefinitions.length > 1
          ? buildDefaultFindings({
              order: order,
              orderCode: order.code,
            })
          : undefined,
      overallFinding:
        order.observationDefinitions.length === 1
          ? buildDefaultFindings({
              order: order,
              orderCode: order.code,
            })[0]
          : undefined,
      refusalReasons: order.refusalReasons || {
        refusalByPatient: false,
        refusalByCaregiver: false,
        uncooperativePatient: false,
        contraindicatedForPatient: false,
        underCareOfAnotherProvider: false,
      },
    }
  }, [order])

  const formMethods = useForm({
    defaultValues: defaultFormValues,
  })

  useEffect(() => {
    formMethods.reset(defaultFormValues)
  }, [defaultFormValues, formMethods])

  const refusalReasons = formMethods.watch('refusalReasons')

  const doNotPerform = Object.values(omit(refusalReasons, '__typename')).some(
    Boolean
  )

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Save',
        cancelText: order.doNotPerform ? 'Close' : 'Discard changes',
        disabled: order.doNotPerform,
        submitting: completingOrder,
      }}
      formMethods={formMethods}
      onSubmit={async (input) => {
        await completeInHouseTestOrder({
          variables: {
            input: {
              ...input,
              overallFinding: doNotPerform ? null : input.overallFinding,
              orderId: order.id,
              patientId,
              encounterId,
              refusalReasons: omit(input.refusalReasons, '__typename'),
            },
          },
          onCompleted: () => {
            toast.success('In-house results updated')
            closeSidePanel()
          },
        })
      }}
    >
      <StackView space={100}>
        <OrderRefusal order={order} description="In-house test refusal" />
        {!doNotPerform ? (
          <>
            <InHouseTestFindings order={order} />
            <InHouseTestNotes order={order} />
          </>
        ) : null}
      </StackView>
    </SidepanelForm>
  )
}

export default CompleteInHouseTestOrderSidepanelForm
