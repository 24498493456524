import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

export const LabeledValue = ({
  label,
  value,
}: {
  label: string
  value: string
}) => {
  return (
    <StackView direction="row" space={50}>
      <Typography color="text-base-color-fg-muted" className="w-32 shrink-0">
        {label}:
      </Typography>
      <Typography className="inline-block whitespace-pre-wrap">
        {value}
      </Typography>
    </StackView>
  )
}
