import { ReactNode, useState } from 'react'

import {
  ReferenceType,
  offset,
  shift,
  flip,
  useFloating,
  autoUpdate,
  useInteractions,
  useHover,
  useFocus,
  useDismiss,
  useRole,
} from '@floating-ui/react'
import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import { isEmpty } from 'lodash'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

export type TooltipPlacement = 'top' | 'bottom' | 'left' | 'right'
export type TooltipSizing = 0 | 25 | 50 | 75 | 100

const getTooltipClasses = ({
  tooltipPadding,
}: {
  tooltipPadding: TooltipSizing
}) => {
  const classes = []

  const padding = {
    0: 'p-core-space-0',
    25: 'p-core-space-25',
    50: 'p-core-space-50',
    75: 'p-core-space-75',
    100: 'p-core-space-100',
  }

  classes.push(padding[tooltipPadding])
  classes.push(
    'rounded-base-border-radius-container-s',
    'shadow-base-box-shadow-container-s',
    'border-base-border-width-container-s',
    'font-base-typography-interface-default-xs-font-family',
    'text-base-typography-interface-default-xs-font-size',
    'font-base-typography-interface-default-xs-font-weight',
    'leading-base-typography-interface-default-xs-line-height',
    'bg-base-color-bg-subtle',
    'text-base-color-fg-subtle',
    'border-base-color-border-default'
  )

  return clsx(...classes)
}

export interface RenderTriggerProps extends Record<string, unknown> {
  ref: (node: ReferenceType | null) => void
}

export interface TooltipProps {
  /** The text to show in the tooltip
   *
   * If an array is provided, each element is shown on a new line.
   *
   * If no text is provided, render the children without a tooltip.
   */
  text: string | string[]
  /** children may be a react node or a function
   *
   * If a function is provided it should be able to render with or without props.
   * The props provide a reference to the tooltip if a tooltip is rendered.
   */
  children: ReactNode | ((props?: RenderTriggerProps) => ReactNode)
  placement?: TooltipPlacement
  padding?: TooltipSizing
  title?: string
  maxWidth?: number
  testId?: string
}

export const Tooltip = (tooltipProps: TooltipProps) => {
  const { text } = tooltipProps
  const doNotShowTooltip = isEmpty(text)
  if (doNotShowTooltip) {
    if (typeof tooltipProps.children === 'function') {
      return tooltipProps.children()
    } else {
      return <>{tooltipProps.children}</>
    }
  }

  return <TooltipHelper {...tooltipProps} />
}

const TooltipHelper = ({
  testId,
  text,
  children,
  placement = 'top',
  padding = 75,
  title,
  maxWidth,
}: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const {
    refs: { setReference, setFloating },
    floatingStyles,
    context,
  } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    middleware: [offset(12), flip(), shift()],
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
  })

  const hover = useHover(context, {
    move: false,
    delay: { open: 150, close: 0 },
  })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ])

  const classes = getTooltipClasses({
    tooltipPadding: padding,
  })

  const textAsArray = Array.isArray(text) ? text : [text]

  return (
    <>
      {typeof children === 'function' ? (
        children({ ref: setReference, ...getReferenceProps() })
      ) : (
        <div ref={setReference} {...getReferenceProps()}>
          {children}
        </div>
      )}
      <Transition
        show={isOpen}
        enter="transition-opacity ease-linear duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        ref={setFloating}
        style={{
          ...floatingStyles,
          zIndex: 100,
          maxWidth: maxWidth ?? 'auto',
        }}
        className={classes}
        {...getFloatingProps()}
      >
        <StackView space={25} testId={testId}>
          {title && (
            <Typography
              textStyle="interface-strong-xs"
              color="text-base-color-fg-default"
            >
              {title}
            </Typography>
          )}
          {textAsArray.map((line) => (
            <Typography
              key={line}
              textStyle="interface-default-xs"
              color="text-base-color-fg-muted"
              className="leading-base-line-height-title"
            >
              {line}
            </Typography>
          ))}
        </StackView>
      </Transition>
    </>
  )
}
