import { useParams } from 'react-router-dom'

import ScreeningToolCell from 'src/components/ScreeningToolCell'

const SidepanelScreeningTool = () => {
  const { screeningToolId } = useParams()

  return <ScreeningToolCell id={screeningToolId} />
}

export default SidepanelScreeningTool
