import { useState, useContext, PropsWithChildren } from 'react'

import merge from 'lodash/merge'

import { storeSettings } from 'src/lib/settings'
import { createNamedContext } from 'src/utils'

export enum SIDEBAR_SIZE {
  DEFAULT = 'DEFAULT',
  SMALL = 'SMALL',
}

export enum PATIENT_HEADER_SIZE {
  DEFAULT = 'DEFAULT',
  SMALL = 'SMALL',
}

export enum VISIT_STEPPER_SIZE {
  DEFAULT = 'DEFAULT',
  SMALL = 'SMALL',
}

export interface LocalSettings {
  sidebarSize: SIDEBAR_SIZE
  patientHeaderSize: PATIENT_HEADER_SIZE
  visitStepperSize: VISIT_STEPPER_SIZE
  lastUsedTerminalId?: string
}

const defaultSettings = {
  sidebarSize: SIDEBAR_SIZE.DEFAULT,
  patientHeaderSize: PATIENT_HEADER_SIZE.DEFAULT,
  visitStepperSize: VISIT_STEPPER_SIZE.DEFAULT,
  lastUsedTerminalId: undefined,
}

const LocalSettingsContext = createNamedContext<
  LocalSettings & { saveSettings: (settings: Partial<LocalSettings>) => void }
>('LocalSettings')

export function useLocalSettings() {
  const context = useContext(LocalSettingsContext)

  return context
}

export function LocalSettingsProvider({
  settings,
  children,
}: PropsWithChildren<{ settings: LocalSettings }>) {
  const [currentSettings, setCurrentSettings] = useState<LocalSettings>(
    settings || defaultSettings
  )

  const handleSaveSettings = (updatedSettings: Partial<LocalSettings>) => {
    const mergedSettings = merge({}, currentSettings, updatedSettings)

    setCurrentSettings(mergedSettings)
    storeSettings(mergedSettings)
  }

  return (
    <LocalSettingsContext.Provider
      value={{
        ...currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </LocalSettingsContext.Provider>
  )
}

export default LocalSettingsContext
