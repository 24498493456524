import clsx from 'clsx'
import { isPossibleNumber } from 'libphonenumber-js'
import get from 'lodash/get'
import PhoneInput from 'react-phone-number-input/react-hook-form-input'

import { RegisterOptions, useFormContext } from '@redwoodjs/forms'

import {
  InputFieldSizeType,
  getInputFieldClasses,
  getInputFieldContainerClasses,
  inputFieldErrorClasses,
} from '../InputField'

export interface PhoneInputFieldProps {
  name: string
  testId?: string
  className?: string
  errorClassName?: string
  disabled?: boolean
  emptyAs?: null | undefined
  validation: RegisterOptions
  size?: InputFieldSizeType
  defaultValue?: string
}

const PhoneInputField = ({
  name,
  testId,
  disabled = false,
  className,
  validation,
  size = 'm',
  ...rest
}: PhoneInputFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const hasError = !!get(errors, name)

  return (
    <div>
      <div className={clsx(getInputFieldContainerClasses({ disabled }))}>
        <PhoneInput
          id={name}
          control={control}
          name={name}
          data-testid={testId}
          disabled={disabled}
          className={clsx(
            getInputFieldClasses({
              size,
              hasIconLeft: false,
              hasIconRight: false,
            }),
            hasError && inputFieldErrorClasses,
            className
          )}
          defaultCountry="US"
          rules={{
            validate: (value) => !value || isPossibleNumber(value),
            ...validation,
          }}
          {...rest}
        />
      </div>
    </div>
  )
}

export default PhoneInputField
