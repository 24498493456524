import { useEffect, useState, useCallback } from 'react'

import Box from 'src/components/atoms/Box/Box'
import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import StackView from 'src/components/atoms/StackView/StackView'
import PDFEditor from 'src/pages/CasesPage/DocumentCasesTab/DocumentCase/TabsLayout/PDFEditor'
import useSaveDocumentCaseAnnotatedDocumentPdf from 'src/pages/CasesPage/DocumentCasesTab/DocumentCase/TabsLayout/useSaveAnnotatedPdf'
import { useFullScreenContext } from 'src/providers/context/FullScreenContext'
import { usePDFAnnotationContext } from 'src/providers/context/PDFAnnotationContext'

const TabsLayout = ({ children, documentCase }) => {
  const { isFullScreen } = useFullScreenContext()
  const [, updateState] = useState({})
  const forceUpdate = useCallback(() => updateState({}), [])

  const [saveDocumentCaseAnnotatedDocumentPdf] =
    useSaveDocumentCaseAnnotatedDocumentPdf()

  const { isAnnotatingPDF, setisAnnotatingPDF } = usePDFAnnotationContext()

  const tabNavDetailsHeight = document.getElementById(
    'tabnav-document-case-details'
  )?.offsetHeight
  const caseHeaderDetailsHeight = document.getElementById(
    'case-details-header'
  )?.offsetHeight
  const controlsHeader = document.getElementById(
    'full-screen-controls'
  )?.offsetHeight
  const casesPageHeader = isFullScreen
    ? 0
    : document.getElementById('case-page-header')?.offsetHeight

  useEffect(() => {
    if (!isFullScreen) {
      if (
        !tabNavDetailsHeight ||
        !caseHeaderDetailsHeight ||
        !casesPageHeader ||
        !controlsHeader
      ) {
        forceUpdate()
      }
    } else {
      if (!tabNavDetailsHeight || !caseHeaderDetailsHeight || !controlsHeader) {
        forceUpdate()
      }
    }
  })

  return (
    <StackView
      direction="row"
      className="relative h-full max-h-full"
      style={{
        maxHeight: `calc(100vh - ${
          tabNavDetailsHeight * (isFullScreen ? 1 : 2) +
          caseHeaderDetailsHeight +
          casesPageHeader +
          controlsHeader
        }px)`,
      }}
    >
      <Box
        flex="4/12"
        className="relative h-full max-h-full max-w-md overflow-y-auto border-r"
      >
        {children}
      </Box>
      <Box
        grow
        className="relative flex h-full flex-col bg-gray-200"
        padding={125}
      >
        {isAnnotatingPDF ? (
          <PDFEditor
            url={documentCase.binary.content.url}
            onSave={async (store) => {
              const url = await store.toPDFDataURL({})
              const base64 = url.split('base64,')[1]
              await saveDocumentCaseAnnotatedDocumentPdf({
                variables: {
                  input: {
                    annotatedDocumentPdfBinary: {
                      content: base64,
                      contentType: 'application/pdf',
                    },
                    documentCaseId: documentCase.id,
                  },
                },
              })
              setisAnnotatingPDF(false)
            }}
          />
        ) : (
          <FileDisplay
            url={documentCase.binary.content.url}
            type={documentCase.binary.content.contentType}
          />
        )}
      </Box>
    </StackView>
  )
}

export default TabsLayout
