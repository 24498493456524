import { nameValueArrayFromObject } from './utils'

export const organSystems = [
  'CON',
  'HEAD',
  'EYES',
  'EARS',
  'NOSE',
  'MOUTH',
  'THROAT',
  'NECK',
  'PULM',
  'CV',
  'GI',
  'GU',
  'MSK',
  'DERM',
  'NEURO',
  'PSYCH',
  'HLI',
  'ENDO',
] as const

export type OrganSystem = (typeof organSystems)[number]

const sexBasedOrganSystems = ['GU_M', 'GU_F']

export const completeOrganSystems = [
  ...organSystems,
  ...sexBasedOrganSystems,
] as const

export type CompleteOrganSystem = (typeof completeOrganSystems)[number]

export const completeOrganSystemDisplay: {
  [key in CompleteOrganSystem]: string
} = {
  CON: 'Constitutional',
  HEAD: 'Head',
  EYES: 'Eyes',
  EARS: 'Ears',
  NOSE: 'Nose',
  MOUTH: 'Mouth',
  THROAT: 'Throat',
  NECK: 'Neck',
  PULM: 'Pulmonary',
  CV: 'Cardiovascular',
  GI: 'Gastrointestinal',
  GU: 'Genitourinary',
  GU_M: 'Genitourinary Male',
  GU_F: 'Genitourinary Female',
  MSK: 'Musculoskeletal',
  DERM: 'Dermatologic',
  NEURO: 'Neurologic',
  PSYCH: 'Psychiatric',
  HLI: 'Hematologic, Lymphatic, lmmunologic',
  ENDO: 'Endocrine',
} as const

const buildOrganSystemDisplay: () => {
  [key in OrganSystem]: string
} = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { GU_M, GU_F, ...rest } = completeOrganSystemDisplay
  return rest as { [key in OrganSystem]: string }
}

export const organSystemDisplay = {
  ...buildOrganSystemDisplay(),
}

export const organSystemsNameValueArray =
  nameValueArrayFromObject<OrganSystem>(organSystemDisplay)

export const completeOrganSystemsNameValueArray =
  nameValueArrayFromObject<CompleteOrganSystem>(completeOrganSystemDisplay)
