import { DocumentTypesConfig } from 'common/cdr/concepts/documents'
import type { ExternalRequestType } from 'types/graphql'

import { nameValueArrayFromObject } from 'src/data/utils'

export const externalRequestTypeDisplay: {
  [key in ExternalRequestType]: string
} = {
  ...DocumentTypesConfig['EXTERNAL_REQUEST']['subTypes'],
}

export const externalRequestTypes =
  nameValueArrayFromObject<ExternalRequestType>(externalRequestTypeDisplay)
