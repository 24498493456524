import { assertUnreachable } from 'common/utils'
import { formatISO } from 'date-fns'
import compact from 'lodash/compact'
import { DiagnosisCode, OrderCategory } from 'types/graphql'

import { UseFormReturn } from '@redwoodjs/forms'

import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'
import { TypeaheadField } from 'src/components/molecules/Typeahead'
import { useContactsQueryAsTypeaheadOptions } from 'src/hooks/useContactsQuery/useContactsQuery'

import AppointmentDefinitionComboBoxCell from '../AppointmentDefinition/AppointmentDefinitionComboBoxCell'
import { CheckboxField } from '../atoms/Checkbox'
import ChiefComplaintSelectField from '../atoms/ChiefComplaintSelectField/ChiefComplaintSelectField'
import { ComboboxField, ComboboxOption } from '../atoms/ComboboxField'
import InputField from '../atoms/InputField'
import TargetDateField from '../atoms/TargetDateField/TargetDateField'
import TextAreaField from '../atoms/TextAreaField'
import VaccineCVXSelectField from '../atoms/VaccineSelectField/VaccineCVXSelectField'
import LocationDropdownCell from '../Location/LocationDropdownCell'
import { DatePickerField } from '../molecules/DatePicker'
import { Item } from '../molecules/FormInputList'
import GenericFormFieldArray from '../molecules/GenericFormFieldArray/GenericFormFieldArray'
import BillingCodeSearchField from '../organisms/BillingCodeSearchField/BillingCodeSearchField'
import DiagnosisSearchField from '../organisms/DiagnosisSearchField/DiagnosisSearchField'
import PractitionerDropdownCell from '../Practitioner/PractitionerDropdownCell'

import { OrderSidepanelFormFormType } from './OrderSidepanelForm'
import { VisitTemplateOrderSidepanelFormFormType } from './VisitTemplateOrderSidepanelForm'

export type ProviderPreference = 'any' | 'custom' | 'existingContact'

type ExcludedField =
  | 'COMMON'
  | 'REFERRAL'
  | 'CODE'
  | 'FREE_TEXT_CODE'
  | 'TARGET_TIME'
  | 'VISIT_TYPE'
  | 'CHIEF_COMPLAINTS'
  | 'VISIT_PRACTITIONER'
  | 'LOCATION'
  | 'COMMENT'
  | 'BILLING_CODE'
  | 'DIAGNOSIS_CODES'
  | 'EXPIRES_AT'
  | 'IS_URGENT'
  | 'REASON'
  | 'PROVIDER_PREFERENCE'
  | 'PERFORMER'

export type FormType =
  | OrderSidepanelFormFormType
  | VisitTemplateOrderSidepanelFormFormType

const commonInputFields = ({
  patientId,
  encounterId,
  isEdit,
  excludedFields,
}: {
  patientId?: string
  encounterId?: string
  isEdit?: boolean
  excludedFields: ExcludedField[]
}): Item[] =>
  !excludedFields.includes('COMMON')
    ? compact([
        !excludedFields.includes('BILLING_CODE') && !isEdit
          ? {
              name: 'orderInput.cptCode',
              label: 'Billing code',
              formInputComponent: BillingCodeSearchField,
              direction: 'col',
              required: true,
              inputProps: {
                patientId,
                encounterId,
                searchSet: 'ALL',
              },
            }
          : undefined,
        !excludedFields.includes('DIAGNOSIS_CODES')
          ? {
              name: 'orderInput.diagnosisCodes',
              label: 'Linked diagnosis code(s)',
              formInputComponent: GenericFormFieldArray,
              direction: 'col',
              inputProps: {
                formInputComponent: DiagnosisSearchField,
                nestedName: 'code',
                addButtonLabel: 'Add additional diagnosis',
                inputProps: {
                  patientId,
                  encounterId,
                  searchSet: 'ALL',
                  nestedValidation: {
                    validate: (value: DiagnosisCode) => {
                      return !value ? 'Diagnosis code is required' : true
                    },
                  },
                },
              },
            }
          : undefined,
        !excludedFields.includes('EXPIRES_AT')
          ? {
              name: 'orderInput.expiresAt',
              label: 'Order expiration',
              formInputComponent: DatePickerField,
              direction: 'col',
              inputProps: {
                validation: {
                  setValueAs: (value: Date) =>
                    value ? formatISO(value) : null,
                },
              },
            }
          : undefined,
        !excludedFields.includes('IS_URGENT')
          ? {
              name: 'orderInput.isUrgent',
              hideLabel: true,
              label: '',
              formInputComponent: CheckboxField,
              inputProps: {
                label: 'Mark order as urgent',
              },
              direction: 'col',
            }
          : undefined,
        !excludedFields.includes('COMMENT')
          ? {
              name: 'orderInput.comments',
              label: 'Order comments',
              formInputComponent: TextAreaField,
              direction: 'col',
            }
          : undefined,
      ])
    : []

const referralInputFields = ({
  providerPreference,
  excludedFields,
}: {
  providerPreference: ProviderPreference
  excludedFields: ExcludedField[]
}): Item[] => {
  const { contactOptions } = useContactsQueryAsTypeaheadOptions()

  return !excludedFields.includes('REFERRAL')
    ? compact([
        !excludedFields.includes('REASON')
          ? {
              name: 'orderInput.reason',
              label: 'Referral reason',
              required: true,
              formInputComponent: TextAreaField,
              direction: 'col',
            }
          : undefined,
        !excludedFields.includes('PROVIDER_PREFERENCE')
          ? {
              name: 'orderInput.providerPreference',
              label: 'Referred provider preference',
              required: true,
              formInputComponent: MultiRadioButtonField,
              direction: 'col',
              inputProps: {
                radioStyle: 'card',
                radioPosition: 'right',
                values: [
                  {
                    value: 'any',
                    label: 'Any provider',
                    description:
                      'Place a referral order without noting a specific provider.',
                  },
                  {
                    value: 'custom',
                    label: 'Custom provider',
                    description: "Enter the referring provider's name.",
                  },
                  {
                    value: 'existingContact',
                    label: 'Select an existing contact',
                    description:
                      'Select a provider from your existing contacts list.',
                  },
                ],
              },
            }
          : undefined,
        !excludedFields.includes('PERFORMER')
          ? {
              name: 'orderInput.performer',
              label: 'Provider name',
              required: providerPreference === 'custom',
              hide: providerPreference !== 'custom',
              formInputComponent: InputField,
              direction: 'col',
            }
          : undefined,
        !excludedFields.includes('PERFORMER')
          ? {
              name: 'orderInput.existingContactPerformer.contactId',
              label: 'Existing contact',
              required: providerPreference === 'existingContact',
              hide: providerPreference !== 'existingContact',
              formInputComponent: TypeaheadField,
              direction: 'col',
              inputProps: {
                multiOptionSelect: false,
                options: contactOptions,
                placeholder: 'Search for contacts',
              },
            }
          : undefined,
      ])
    : []
}

const buildFormInputList = ({
  category,
  code,
  providerPreference,
  orderTypeOptions,
  patientId,
  encounterId,
  appointmentDefinitionIds,
  isEdit,
  excludedFields = [],
  formMethods,
  allowCurrentPractitionerAndLocation = false,
}: {
  category: Exclude<OrderCategory, 'HG_LAB'>
  code: string
  providerPreference: ProviderPreference
  orderTypeOptions: ComboboxOption[]
  patientId?: string
  encounterId?: string
  appointmentDefinitionIds?: string[]
  isEdit?: boolean
  excludedFields?: ExcludedField[]
  formMethods: UseFormReturn<FormType>
  allowCurrentPractitionerAndLocation?: boolean
}): Item[] => {
  const { setValue } = formMethods
  switch (category) {
    case 'RAD':
      excludedFields.push('BILLING_CODE')
      return compact([
        !excludedFields.includes('CODE')
          ? {
              name: 'orderInput.code',
              label: 'Imaging study',
              required: true,
              formInputComponent: ComboboxField,
              direction: 'col',
              inputProps: {
                options: orderTypeOptions,
                onChange: (value: string) => {
                  setValue('orderInput.code', value)
                },
              },
            }
          : undefined,
        ...referralInputFields({ providerPreference, excludedFields }),
        ...commonInputFields({
          patientId,
          encounterId,
          isEdit,
          excludedFields,
        }),
      ])
    case 'REF':
      excludedFields.push('BILLING_CODE')
      return compact([
        !excludedFields.includes('CODE')
          ? {
              name: 'orderInput.code',
              label: 'Specialty or procedure',
              required: true,
              formInputComponent: ComboboxField,
              direction: 'col',
              inputProps: {
                limit: orderTypeOptions.length,
                options: orderTypeOptions,
                onChange: (value: string) => {
                  setValue('orderInput.code', value)
                },
              },
            }
          : undefined,
        !excludedFields.includes('FREE_TEXT_CODE')
          ? {
              name: 'orderInput.freeTextCode',
              label: 'Free text specialty',
              required: true,
              hide: code !== 'OTHER',
              formInputComponent: InputField,
              direction: 'col',
            }
          : undefined,
        ...referralInputFields({ providerPreference, excludedFields }),
        ...commonInputFields({
          patientId,
          encounterId,
          isEdit,
          excludedFields,
        }),
      ])
    case 'DME':
      excludedFields.push('BILLING_CODE')
      return compact([
        !excludedFields.includes('CODE')
          ? {
              name: 'orderInput.code',
              label: 'Order type',
              required: true,
              formInputComponent: ComboboxField,
              direction: 'col',
              inputProps: {
                options: orderTypeOptions,
                onChange: (value: string) => {
                  setValue('orderInput.code', value)
                },
              },
            }
          : undefined,
        ...commonInputFields({
          patientId,
          encounterId,
          isEdit,
          excludedFields,
        }),
      ])
    case 'LAB':
      excludedFields.push('BILLING_CODE')
      return compact([
        !excludedFields.includes('CODE')
          ? {
              name: 'orderInput.code',
              label: 'Order type',
              required: true,
              formInputComponent: ComboboxField,
              direction: 'col',
              inputProps: {
                options: orderTypeOptions,
                onChange: (value: string) => {
                  setValue('orderInput.code', value)
                },
              },
            }
          : undefined,
        ...commonInputFields({
          patientId,
          encounterId,
          isEdit,
          excludedFields,
        }),
      ])
    case 'IHT':
      return compact([
        !excludedFields.includes('CODE')
          ? {
              name: 'orderInput.code',
              label: 'Test type',
              required: true,
              formInputComponent: ComboboxField,
              direction: 'col',
              inputProps: {
                options: orderTypeOptions,
                onChange: (value: string) => {
                  setValue('orderInput.code', value)
                },
              },
            }
          : undefined,
        ...commonInputFields({
          patientId,
          encounterId,
          isEdit,
          excludedFields,
        }),
      ])
    case 'PRO':
      return compact([
        !excludedFields.includes('CODE')
          ? {
              name: 'orderInput.code',
              label: 'Order type',
              required: true,
              formInputComponent: ComboboxField,
              direction: 'col',
              inputProps: {
                options: orderTypeOptions,
                onChange: (value: string) => {
                  setValue('orderInput.code', value)
                },
                limit: orderTypeOptions.length,
              },
            }
          : undefined,
        ...commonInputFields({
          patientId,
          encounterId,
          isEdit,
          excludedFields,
        }),
      ])
    case 'SCN':
      return compact([
        !excludedFields.includes('CODE')
          ? {
              name: 'orderInput.code',
              label: 'Order type',
              required: true,
              formInputComponent: ComboboxField,
              direction: 'col',
              inputProps: {
                options: orderTypeOptions,
                onChange: (value: string) => {
                  setValue('orderInput.code', value)
                },
                limit: orderTypeOptions.length,
              },
            }
          : undefined,
        ...commonInputFields({
          patientId,
          encounterId,
          isEdit,
          excludedFields,
        }),
      ])
    case 'VAX':
      excludedFields.push('BILLING_CODE')
      return compact([
        !excludedFields.includes('CODE')
          ? {
              name: 'orderInput.code',
              label: 'Vaccine',
              direction: 'col',
              required: true,
              formInputComponent: VaccineCVXSelectField,
              inputProps: {
                showCvxDescription: true,
              },
            }
          : undefined,
        ...commonInputFields({
          patientId,
          encounterId,
          isEdit,
          excludedFields,
        }),
      ])
    case 'SCH':
      return compact([
        !excludedFields.includes('TARGET_TIME')
          ? {
              label: 'Target visit timing',
              name: 'schedulingOrderInput.targetDate',
              required: true,
              formInputComponent: TargetDateField,
              direction: 'col',
            }
          : undefined,
        !excludedFields.includes('VISIT_TYPE')
          ? {
              label: 'Visit type',
              name: 'schedulingOrderInput.appointmentDefinitionIds',
              required: true,
              formInputComponent: AppointmentDefinitionComboBoxCell,
              direction: 'col',
            }
          : undefined,
        !excludedFields.includes('CHIEF_COMPLAINTS')
          ? {
              label: 'Chief complaints',
              name: 'schedulingOrderInput.chiefComplaints',
              formInputComponent: ChiefComplaintSelectField,
              direction: 'col',
              inputProps: {
                appointmentDefinitionId: appointmentDefinitionIds?.[0],
              },
            }
          : undefined,
        !excludedFields.includes('VISIT_PRACTITIONER')
          ? {
              label: 'Visit practitioner',
              name: 'schedulingOrderInput.visitPractitionerId',
              formInputComponent: PractitionerDropdownCell,
              direction: 'col',
              required: true,
              inputProps: {
                validation: {
                  shouldUnregister: true,
                },
                prependedOptions: allowCurrentPractitionerAndLocation
                  ? [
                      {
                        name: 'Current practitioner',
                        value: 'CURRENT',
                      },
                      {
                        name: 'Primary practitioner',
                        value: 'PRIMARY',
                      },
                    ]
                  : undefined,
              },
            }
          : undefined,
        !excludedFields.includes('LOCATION')
          ? {
              label: 'Location',
              name: 'schedulingOrderInput.locationId',
              formInputComponent: LocationDropdownCell,
              direction: 'col',
              required: true,
              inputProps: {
                validation: {
                  shouldUnregister: true,
                },
                prependedOptions: allowCurrentPractitionerAndLocation
                  ? [
                      {
                        name: 'Current location',
                        value: 'CURRENT',
                      },
                    ]
                  : undefined,
              },
            }
          : undefined,
        !excludedFields.includes('COMMENT')
          ? {
              label: 'Order comments',
              name: 'schedulingOrderInput.note',
              alignItems: 'start' as Item['alignItems'],
              inputProps: {
                rows: 5,
              },
              formInputComponent: TextAreaField,
              direction: 'col',
            }
          : undefined,
      ])
    case 'OTHER':
      return [
        {
          name: 'orderInput.code',
          label: 'Order type',
          required: true,
          formInputComponent: ComboboxField,
          direction: 'col',
          inputProps: {
            options: orderTypeOptions,
            onChange: (value: string) => {
              setValue('orderInput.code', value)
            },
          },
        },
        ...commonInputFields({
          patientId,
          encounterId,
          isEdit,
          excludedFields,
        }).filter((input) => input.name !== 'orderInput.cptCode'),
      ]
    default:
      assertUnreachable(category)
  }
}

export default buildFormInputList
