import { DocumentTypesConfig } from 'common/cdr/concepts/documents'
import type { PracticeFormType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const practiceFormTypeDisplay: {
  [key in PracticeFormType]: string
} = {
  ...DocumentTypesConfig['PRACTICE_FORM']['subTypes'],
}

export const practiceFormTypes = nameValueArrayFromObject<PracticeFormType>(
  practiceFormTypeDisplay
)
