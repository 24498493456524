import { BellIcon } from '@heroicons/react/20/solid'
import { formatDisplayName } from 'common/utils'
import sumBy from 'lodash/sumBy'
import { useParams } from 'react-router-dom'

import {
  navigate,
  useLocation,
  useParams as useRWParams,
} from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/dist/toast'

import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { BalanceDisplay } from 'src/components/molecules/BalanceDisplay/BalanceDisplay'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { StatelessTabs } from 'src/components/molecules/Tabs'
import { sidepanelRoute } from 'src/lib/routes'
import { useSendPaymentReminders } from 'src/pages/PracticeFinancialsPage/useSendPaymentReminders'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import { DetailsTab } from './DetailsTab'
import { HistoryTab } from './HistoryTab'
import { PatientsTab } from './PatientsTab'
import {
  SidepanelCaregiverFinancialsProvider,
  useSidepanelCaregiverFinancialsContext,
} from './SidepanelCaregiverFinancialsContext'
import { StatementTab } from './StatementTab'

const tabs = [
  {
    key: 'details',
    name: 'Details',
    content: <DetailsTab />,
  },
  {
    key: 'patients',
    name: 'Patient(s)',
    content: <PatientsTab />,
  },
  {
    key: 'statement',
    name: 'Statement',
    content: <StatementTab />,
  },
  {
    key: 'history',
    name: 'History',
    content: <HistoryTab />,
  },
]

const SidepanelCaregiverFinancialsHelper = () => {
  const { guarantor } = useSidepanelCaregiverFinancialsContext()
  const { relatedPersonId, ...params } = useParams()
  const { sidepanelContext } = useSidepanel()
  const location = useLocation()
  const rwParams = useRWParams()

  const selectedTab = params?.tab ?? 'details'

  const totalBalance = sumBy(guarantor.guarantees, (patient) =>
    Math.max(patient.totalOutstandingBalanceCents ?? 0, 0)
  )

  const [sendPaymentReminders, { loading }] = useSendPaymentReminders()

  return (
    <SidepanelPage
      header={
        <StackView gap={100}>
          <StackView gap={25}>
            <Typography textStyle="title-s">
              {formatDisplayName(guarantor)}
            </Typography>

            <StackView direction="row" alignItems="center" gap={25}>
              <Typography
                textStyle="interface-strong-xs"
                color="text-base-color-fg-muted"
              >
                Total family balance:
              </Typography>

              <BalanceDisplay textStyle="interface-strong-xs">
                {totalBalance}
              </BalanceDisplay>
            </StackView>
          </StackView>
          <StackView alignItems="start" gap={25}>
            <Button
              icon={BellIcon}
              text="Send reminder"
              loading={loading}
              disabled={loading || guarantor.portalUserStatus !== 'ACTIVE'}
              onClick={() => {
                void sendPaymentReminders({
                  variables: {
                    guarantors: [
                      {
                        guarantorType: 'RELATED_PERSON',
                        id: guarantor.id,
                      },
                    ],
                  },
                  onCompleted: (result) => {
                    if (
                      result.sendFamilyBalanceReminders.unsentMessages?.length
                    ) {
                      const unsentMessage =
                        result.sendFamilyBalanceReminders.unsentMessages[0]
                      toast.error(
                        `Failed to send payment reminder. Reason: ${unsentMessage.reason}`
                      )
                    } else {
                      toast.success('Sent payment reminder')
                    }
                  },
                })
              }}
            />

            {guarantor.portalUserStatus !== 'ACTIVE' ? (
              <Typography
                textStyle="interface-default-xs"
                color="text-base-color-fg-subtle"
              >
                The guarantor must have an active family portal account to send
                payment reminders.
              </Typography>
            ) : null}
          </StackView>
        </StackView>
      }
    >
      <StackView className="h-full">
        <StatelessTabs
          contained
          className="px-core-space-150"
          selectedTabIndex={tabs.findIndex((tab) => tab.key === selectedTab)}
          onTabChange={(index) => {
            const key = tabs[index]?.key

            navigate(
              sidepanelRoute(
                {
                  ...sidepanelContext,
                  route: `/caregiver/${relatedPersonId}/financials/${key}`,
                },
                location,
                rwParams
              )
            )
          }}
          tabs={tabs}
        />
      </StackView>
    </SidepanelPage>
  )
}

const SidepanelCaregiverFinancials = () => (
  <SidepanelCaregiverFinancialsProvider>
    <SidepanelCaregiverFinancialsHelper />
  </SidepanelCaregiverFinancialsProvider>
)

export default SidepanelCaregiverFinancials
