import { useLazyQuery } from '@apollo/client'
import { Questionnaire } from 'types/graphql'

const QUESTIONNAIRE_QUERY = gql`
  query Questionnaire($questionnaireCode: String!) {
    questionnaire(questionnaireCode: $questionnaireCode)
  }
`
export const useFetchQuestionnaire = () => {
  return useLazyQuery<Questionnaire>(QUESTIONNAIRE_QUERY)
}
