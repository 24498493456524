import clsx from 'clsx'
import { format } from 'date-fns'
import Linkify from 'linkify-react'
import { BinaryAttachment } from 'types/graphql'

import Box from 'src/components/atoms/Box/Box'
import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import { AnchorLink } from 'src/components/atoms/Link/Link'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography, {
  TypographyColor,
} from 'src/components/atoms/Typography/Typography'

export interface ChatMessageProps {
  senderName: string
  senderTitle?: string
  text: string
  createdAt: Date
  color: 'white' | 'gray' | 'purple'
  attachment?: BinaryAttachment
  actions?: React.ReactNode
  testId?: string
}

export const ChatMessage = ({
  senderName,
  senderTitle,
  text,
  createdAt,
  color,
  attachment,
  actions,
  testId,
}: ChatMessageProps) => {
  const colorConfig = colorToConfig[color]

  return (
    <StackView
      gap={50}
      className={clsx(
        'flex-shrink-1 group relative rounded-base-border-radius-selectable-s border-core-border-width-10 px-core-space-100 py-core-space-75',
        colorConfig.backgroundColor,
        colorConfig.borderColor
      )}
      alignItems="start"
      testId={testId}
    >
      <StackView
        direction="row"
        justifyContent="between"
        alignItems="start"
        gap={100}
      >
        <StackView direction="row" gap={25}>
          {[
            {
              text: senderName,
              textStyle: 'interface-strong-xs' as const,
              color: colorConfig.senderName,
            },
            { text: senderTitle, color: colorConfig.senderTitle },
            { text: '•', color: colorConfig.senderTitle },
            {
              text: format(createdAt, "LLL d, yyyy 'at' h:mm aa"),
              color: colorConfig.createdAt,
            },
          ]
            .filter((item) => item.text)
            .map((item, i) => (
              <Typography
                key={i}
                textStyle={item.textStyle ?? 'interface-default-xs'}
                color={item.color}
              >
                {item.text}
              </Typography>
            ))}
        </StackView>

        {actions ? (
          <Box className="invisible group-hover:visible">{actions}</Box>
        ) : null}
      </StackView>

      <Linkify
        options={{
          defaultProtocol: 'https',
          render: ({ content, attributes }) => {
            return (
              <AnchorLink
                to={attributes.href}
                text={content}
                size="m"
                className={colorConfig.linkColor}
                target="_blank"
                rel="noopener noreferrer"
              />
            )
          },
        }}
      >
        <Typography
          textStyle="interface-default-s"
          color={colorConfig.text}
          className="whitespace-pre-line break-words"
        >
          {text}
        </Typography>
      </Linkify>

      {attachment ? (
        <a
          href={attachment.url}
          rel="noopener noreferrer"
          target="_blank"
          className="text-base-color-fg-default hover:text-base-color-fg-default"
        >
          <Box className="pointer-events-none h-core-space-800 w-core-space-800 overflow-clip rounded-core-border-radius-25">
            <FileDisplay
              url={attachment.url}
              type={attachment.contentType}
              showDownloadLink={false}
            />
          </Box>
        </a>
      ) : null}
    </StackView>
  )
}

const colorToConfig: {
  [Color in ChatMessageProps['color']]: {
    senderName: TypographyColor
    senderTitle: TypographyColor
    createdAt: TypographyColor
    text: TypographyColor
    borderColor: string | null
    backgroundColor: string
    linkColor: string | null
  }
} = {
  gray: {
    senderName: 'text-base-color-fg-default',
    senderTitle: 'text-base-color-fg-default',
    createdAt: 'text-base-color-fg-subtle',
    text: 'text-base-color-fg-muted',
    linkColor: null,
    borderColor: 'border-base-color-bg-subtle',
    backgroundColor: 'bg-core-color-neutral-70',
  },
  white: {
    senderName: 'text-base-color-fg-default',
    senderTitle: 'text-base-color-fg-default',
    createdAt: 'text-base-color-fg-subtle',
    text: 'text-base-color-fg-muted',
    linkColor: null,
    borderColor: 'border-base-color-bg-subtle',
    backgroundColor: 'bg-white',
  },
  purple: {
    senderName: 'text-base-color-fg-emphasis',
    senderTitle: 'text-base-color-fg-emphasis',
    createdAt: 'text-base-color-fg-emphasis',
    text: 'text-base-color-fg-emphasis',
    linkColor: 'text-base-color-fg-emphasis',
    borderColor: null,
    backgroundColor: 'bg-core-color-primary-40',
  },
}
