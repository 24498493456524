import { ExclamationCircleIcon, PencilIcon } from '@heroicons/react/24/solid'
import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { useParams } from 'react-router-dom'
import {
  GetPatientForPortalAccount,
  GetPatientForPortalAccountVariables,
} from 'types/graphql'

import {
  navigate,
  useLocation,
  useParams as useRWParams,
} from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import Badge from 'src/components/atoms/Badge/Badge'
import Button from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import Alert from 'src/components/molecules/Alert/Alert'
import { PortalUserStatusBadge } from 'src/components/molecules/PortalUserStatusBadge/PortalUserStatusBadge'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { genderIdentityAbbreviation } from 'src/data/genderIdentities'
import { guardianshipTypeDisplay } from 'src/data/guardianshipTypes'
import { sexAbbreviation, sexDisplay } from 'src/data/sexes'
import {
  formatAddress,
  formatAddressLine1,
  formatAge,
  formatDateDisplay,
  formatPhoneNumber,
} from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'
import { PATIENT_STATUS_FRAGMENT } from 'src/pages/PatientChartsPage/PatientDemographics/fragments'

import { CaregiverPortalAccessToggle } from './CaregiverPortalAccessToggle'

const PATIENT_QUERY = gql`
  query GetPatientForPortalAccount($id: String!) {
    patient(id: $id) {
      id
      ...PatientStatusFragment
      givenName
      familyName
      birthDate
      sexAtBirth
      maritalStatus
      patientRelatedPersonRelationships {
        id
        relationshipType
        guardianshipType
        isGuarantor
        doesResideWith
        relatedPersonPortalAccessOverride
        patient {
          id
          ...PatientStatusFragment
          birthDate
          givenName
          familyName
        }
        relatedPerson {
          id
          givenName
          familyName
          genderIdentity
          birthDate
          portalUserStatus
          contactInformation {
            id
            homeAddress {
              id
              line1
              line2
              city
              state
              postalCode
            }
          }
        }
      }
      contactInformation {
        id
        mobileNumber
        homeAddress {
          id
          line1
          line2
          city
          state
          postalCode
        }
      }
      portalUserStatus
      potentialDuplicatePortalUsers {
        id
        mobileNumber
        homeAddress {
          id
          line1
          line2
          city
          state
          postalCode
        }
        patient {
          id
          givenName
          familyName
          sexAtBirth
          birthDate
          patientRelatedPersonRelationships {
            id
            relatedPerson {
              id
              givenName
              familyName
            }
          }
        }
        relatedPerson {
          id
          givenName
          familyName
          genderIdentity
          birthDate
          patientRelatedPersonRelationships {
            id
            patient {
              id
              givenName
              familyName
            }
          }
        }
        practitioner {
          id
        }
      }
    }
  }
  ${PATIENT_STATUS_FRAGMENT}
`

const SidepanelPatientFamilyPortalAccount = () => {
  const { currentUser } = useEmrAuth()
  const { patientId } = useParams()
  const location = useLocation()
  const params = useRWParams()

  const { data } = useQuery<
    GetPatientForPortalAccount,
    GetPatientForPortalAccountVariables
  >(PATIENT_QUERY, {
    variables: {
      id: patientId,
    },
  })

  if (!data?.patient) return

  const patient = data.patient

  const duplicateAccounts = data.patient.potentialDuplicatePortalUsers ?? []

  const homeAddress =
    patient.contactInformation?.homeAddress ??
    patient.patientRelatedPersonRelationships.find(
      (relationship) => relationship.doesResideWith
    )?.relatedPerson?.contactInformation?.homeAddress

  const sections = [
    duplicateAccounts.length
      ? {
          warning:
            'There are other accounts with the same mobile phone number. You can resolve this error and set up the correct unique portal access by manually activating a single duplicate account and/or changing the mobile phone number for one or more of the duplicate accounts.',
          title: 'Duplicate account(s)',
          description:
            'The following accounts share the same mobile phone number as this account, please resolve.',
          action: (
            <Button
              icon={ExclamationCircleIcon}
              text="Resolve"
              onClick={() =>
                navigate(
                  sidepanelRoute(
                    {
                      route: `/family-portal-account/duplicate-resolution/${patient.contactInformation.id}`,
                      width: 'medium',
                      overlay: true,
                    },
                    location,
                    params
                  )
                )
              }
            />
          ),
          content: (
            <StackView>
              {duplicateAccounts.map((account) => {
                const person = account.relatedPerson ?? account.patient

                return (
                  <Card key={account.id} testId="duplicate-account-item">
                    <StackView className="px-core-space-100 py-core-space-75">
                      <StackView direction="row" alignItems="center">
                        <StackView gap={25}>
                          <StackView direction="row" gap={25}>
                            <Typography
                              textStyle="interface-strong-s"
                              color="text-base-color-fg-muted"
                            >
                              {formatDisplayName(person)}
                            </Typography>

                            <ExclamationCircleIcon className="h-base-size-icon-xs w-base-size-icon-xs text-danger" />
                          </StackView>

                          <Typography
                            textStyle="interface-default-xs"
                            color="text-base-color-fg-subtle"
                          >
                            {[
                              account.patient
                                ? sexAbbreviation[account.patient.sexAtBirth]
                                : genderIdentityAbbreviation[
                                    account.relatedPerson.genderIdentity
                                  ],
                              `${formatAge(
                                person.birthDate
                              )} (${formatDateDisplay(person.birthDate)})`,
                              formatAddressLine1(account.homeAddress),
                              formatPhoneNumber(account.mobileNumber),
                              account.patient
                                ? [
                                    'Caregiver(s)',
                                    account.patient.patientRelatedPersonRelationships
                                      .map((relationship) =>
                                        formatDisplayName(
                                          relationship.relatedPerson
                                        )
                                      )
                                      .join(', '),
                                  ].join(': ')
                                : [
                                    'Patient(s)',
                                    account.relatedPerson.patientRelatedPersonRelationships
                                      .map((relationship) =>
                                        formatDisplayName(relationship.patient)
                                      )
                                      .join(', '),
                                  ].join(': '),
                            ].join(' • ')}
                          </Typography>
                        </StackView>

                        <Badge
                          text={account.patient ? 'Patient' : 'Caregiver'}
                          color={account.patient ? 'red' : 'purple'}
                        />
                      </StackView>
                    </StackView>
                  </Card>
                )
              })}
            </StackView>
          ),
        }
      : null,
    {
      title: 'Linked caregivers',
      description:
        'Only caregivers with an active portal account can access the family portal. Use the toggle to manage access to age 18+ patients.',
      content: (
        <StackView gap={25}>
          {patient.patientRelatedPersonRelationships.map((relationship) => {
            const { relatedPerson } = relationship

            return (
              <Card key={relationship.id} testId="linked-caregiver-item">
                <StackView
                  className="px-core-space-100 py-core-space-75"
                  direction="row"
                  justifyContent="between"
                  alignItems="center"
                >
                  <StackView>
                    <Typography
                      textStyle="interface-strong-s"
                      color="text-base-color-fg-muted"
                    >
                      {formatDisplayName(relatedPerson)}
                    </Typography>

                    <Typography
                      textStyle="interface-default-xs"
                      color="text-base-color-fg-subtle"
                    >
                      {[
                        genderIdentityAbbreviation[
                          relatedPerson.genderIdentity
                        ],
                        `${formatAge(
                          relatedPerson.birthDate
                        )} (${formatDateDisplay(relatedPerson.birthDate)})`,
                        guardianshipTypeDisplay[relationship.guardianshipType],
                        relationship.isGuarantor
                          ? 'Guarantor'
                          : 'Non-guarantor',
                        relationshipTypeDisplay[relationship.relationshipType],
                      ].join(' • ')}
                    </Typography>
                  </StackView>

                  <StackView direction="row" gap={100} fullWidth={false}>
                    <PortalUserStatusBadge
                      status={relationship.relatedPerson.portalUserStatus}
                      userType="relatedPerson"
                    />
                    <CaregiverPortalAccessToggle relationship={relationship} />
                  </StackView>
                </StackView>
              </Card>
            )
          })}
        </StackView>
      ),
    },
    {
      title: 'Mobile login details',
      description:
        'The mobile phone number below is what the patient should use to login to the family portal.',
      action: patient.contactInformation ? (
        <Button
          testId="edit-mobile-number-btn"
          buttonStyle="secondary"
          text="Edit"
          icon={PencilIcon}
          onClick={() =>
            navigate(
              sidepanelRoute(
                {
                  route: `/family-portal-account/login-details/edit/${patient.contactInformation.id}`,
                },
                location,
                params
              )
            )
          }
        />
      ) : currentUser.featureFlags.includes('ADD_PORTAL_USER_MOBILE_LOGIN') &&
        patient.portalUserStatus !== 'INELIGIBLE' ? (
        <Button
          testId="add-mobile-number-btn"
          buttonStyle="secondary"
          text="Add"
          icon={PencilIcon}
          onClick={() =>
            navigate(
              sidepanelRoute(
                {
                  route: `/family-portal-account/login-details/add/${patientId}`,
                },
                location,
                params
              )
            )
          }
        />
      ) : null,
      content: (
        <StackView
          className="border-b-core-border-width-10 border-t-core-border-width-10 border-base-color-bg-subtle py-core-space-75"
          direction="row"
        >
          <Typography
            textStyle="interface-strong-s"
            color="text-base-color-fg-subtle"
            className="flex-1"
          >
            Mobile phone number
          </Typography>
          <Typography className="flex-1">
            {patient.contactInformation?.mobileNumber
              ? formatPhoneNumber(patient.contactInformation.mobileNumber)
              : 'N/A'}
          </Typography>
        </StackView>
      ),
    },
    {
      title: 'Demographics',
      content: (
        <StackView
          className="border-b-core-border-width-10 border-t-core-border-width-10 border-base-color-bg-subtle"
          justifyContent="between"
          divider
        >
          {[
            ['Date of birth', formatDateDisplay(patient.birthDate)],
            ['Sex', sexDisplay[patient.sexAtBirth]],
            ['Address', homeAddress ? formatAddress(homeAddress) : 'N/A'],
          ].map(([key, value]) => {
            return (
              <StackView
                key={key}
                direction="row"
                className="py-core-space-75"
                alignItems="center"
              >
                <Typography
                  textStyle="interface-strong-s"
                  color="text-base-color-fg-subtle"
                  className="flex-1"
                >
                  {key}
                </Typography>
                <Typography color="text-base-color-fg-muted" className="flex-1">
                  {value}
                </Typography>
              </StackView>
            )
          })}
        </StackView>
      ),
    },
  ]

  return (
    <SidepanelPage
      header={
        <StackView gap={25} alignItems="start">
          <PortalUserStatusBadge
            status={patient.portalUserStatus}
            userType="patient"
          />
          <Typography textStyle="title-s">
            {formatDisplayName(patient)}
          </Typography>
          <Typography
            textStyle="interface-default-s"
            color="text-base-color-fg-muted"
          >
            {[
              sexDisplay[patient.sexAtBirth],
              `${formatAge(patient.birthDate)} (${formatDateDisplay(
                patient.birthDate
              )})`,
            ].join(' • ')}
          </Typography>
        </StackView>
      }
    >
      <StackView gap={150} className="px-core-space-150 py-core-space-100">
        {sections.filter(Boolean).map((section) => {
          return (
            <StackView key={section.title} gap={100}>
              {section.warning ? (
                <Alert title={section.warning} style="danger" />
              ) : null}
              <StackView
                direction="row"
                justifyContent="between"
                alignItems="center"
                gap={50}
              >
                <StackView gap={25}>
                  <Typography textStyle="title-xs">{section.title}</Typography>
                  {section.description ? (
                    <Typography
                      textStyle="interface-default-xs"
                      color="text-base-color-fg-muted"
                    >
                      {section.description}
                    </Typography>
                  ) : null}
                </StackView>

                {section.action}
              </StackView>

              {section.content}
            </StackView>
          )
        })}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPatientFamilyPortalAccount
