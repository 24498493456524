export const routeOptions = [
  {
    code: 'IM',
    display: 'Intramuscular (IM)',
  },
  {
    code: 'SQ',
    display: 'Subcutaneous (SQ)',
  },
  {
    code: 'PO',
    display: 'Oral (PO)',
  },
  {
    code: 'NASINHLC',
    display: 'Nasal (NI)',
  },
  {
    code: 'IDINJ',
    display: 'Intradermal (ID)',
  },
  {
    code: 'TRNSDERM',
    display: 'Transdermal (TD)',
  },
  {
    code: 'IVINJ',
    display: 'Intravenous (IV)',
  },
]
