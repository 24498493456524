import { useParams } from 'react-router-dom'
import { TextItemWithBulletPoints } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { CATEGORY_INFO_BY_CODE } from 'src/pages/ChargeCaptureSettingsPage/ruleCategoryDefinitions'

const GET_AUTOMATION_RULE = gql`
  query GetAutomationRule($id: AutomationRuleCode!) {
    automationRule(id: $id) {
      id
      title
      category
      triggerDescription {
        text
        bulletPoints
      }
      automationActionDescriptions {
        text
        bulletPoints
      }
    }
  }
`
const useChargeCaptureRuleQuery = (ruleId) => {
  const { data, loading } = useQuery(GET_AUTOMATION_RULE, {
    variables: { id: ruleId },
  })

  return { rule: data?.automationRule, loading }
}

type SectionContents = {
  title: string
  body: string | TextItemWithBulletPoints | TextItemWithBulletPoints[]
}

const Section = ({ title, body }: SectionContents) => {
  const bodyIsArray = Array.isArray(body)
  const includeBulletPointsAtTopLevel = bodyIsArray
  const bodyAsTextItemWithBulletPoints = bodyIsArray
    ? body
    : typeof body === 'string'
    ? [{ text: body, bulletPoints: [] }]
    : [body]

  return (
    <StackView space={50} className="py-4">
      <Typography textStyle="subtitle" className="text-black">
        {title}
      </Typography>
      {bodyAsTextItemWithBulletPoints.map((item) => (
        <>
          <StackView key={item.text} direction="row" space={25}>
            {includeBulletPointsAtTopLevel && (
              <Typography textStyle="normal" color="text-base-color-fg-muted">
                •
              </Typography>
            )}
            <Typography textStyle="normal" color="text-base-color-fg-muted">
              {item.text}
            </Typography>
          </StackView>
          {item.bulletPoints && (
            <StackView className="pl-4">
              {item.bulletPoints.map((bulletPoint) => (
                <Typography
                  key={bulletPoint}
                  textStyle="normal"
                  color="text-base-color-fg-muted"
                >
                  • {bulletPoint}
                </Typography>
              ))}
            </StackView>
          )}
        </>
      ))}
    </StackView>
  )
}

const SidepanelChargeCaptureRuleView = () => {
  const { ruleId } = useParams()
  const { rule, loading } = useChargeCaptureRuleQuery(ruleId)

  return (
    <SidepanelPage
      testId="sidepanel-charge-capture-rule-view"
      header={rule?.title}
      loading={loading}
    >
      <StackView className="px-6 py-4" space={50} divider>
        <Section
          title="Category"
          body={CATEGORY_INFO_BY_CODE[rule?.category]?.title}
        />
        <Section title="Trigger" body={rule?.triggerDescription} />
        <Section
          title="Automation action"
          body={rule?.automationActionDescriptions}
        />
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelChargeCaptureRuleView
