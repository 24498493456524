import type { FindBinaryQuery, FindBinaryQueryVariables } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'

export const QUERY = gql`
  query FindBinaryQuery($id: String!) {
    binary(id: $id) {
      id
      content {
        url
        contentType
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindBinaryQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  binary,
}: CellSuccessProps<FindBinaryQuery, FindBinaryQueryVariables>) => {
  return (
    <FileDisplay url={binary.content.url} type={binary.content.contentType} />
  )
}
