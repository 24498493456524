import { Fragment } from 'react'

import clsx from 'clsx'
import { assertUnreachable } from 'common/utils'

import Box from 'src/components/atoms/Box/Box'
import StackView from 'src/components/atoms/StackView/StackView'
import { stringToHash } from 'src/utils'

export type AvatarSize = 'xs' | 's' | 'm' | 'l'

export const AVATAR_COLORS = [
  'purple',
  'teal',
  'red',
  'blue',
  'green',
  'dark-gray',
  'cyan',
  'violet',
  'rose',
  'orange',
  'yellow',
  'light-gray',
] as const

export type AvatarColor = (typeof AVATAR_COLORS)[number]

export interface AvatarProps {
  color?: AvatarColor
  text: string
  size?: AvatarSize
  className?: string
}

const getDefaultColor = (text: string): AvatarColor => {
  const hash = stringToHash(text)

  const index = hash % AVATAR_COLORS.length

  return AVATAR_COLORS[index]
}

export const Avatar = ({
  color: colorProp,
  text,
  size = 'm',
  className,
}: AvatarProps) => {
  const color = colorProp ?? getDefaultColor(text)

  return (
    <Box
      className={clsx([
        sizeToClassName(size),
        colorToClassName(color),
        'rounded-full',
        'flex',
        'flex-col',
        'align-center',
        'justify-center',
        'text-center',
        'text-base-color-fg-emphasis',
        'tracking-tighter',
        className,
      ])}
    >
      {text}
    </Box>
  )
}

export interface AvatarGroupProps {
  maxItems?: number
  avatars: Pick<AvatarProps, 'text'>[]
  size?: AvatarSize
}

export const AvatarGroup = ({ maxItems, avatars, size }: AvatarGroupProps) => {
  const shouldRenderExtraBadge = avatars.length > maxItems

  const className = 'border-core-border-width-10 border-base-color-fg-emphasis'

  return (
    <StackView direction="row" alignItems="center" fullWidth={false}>
      {avatars.slice(0, maxItems ?? avatars.length).map((avatar, index) => {
        return (
          <Fragment key={`${avatar.text}-${index}`}>
            <Avatar
              key={`${avatar.text}-${index}`}
              text={avatar.text}
              size={size}
              className={className}
            />
            {avatars.length !== 1 ? (
              <Box className="-ml-core-space-37" />
            ) : null}
          </Fragment>
        )
      })}
      {shouldRenderExtraBadge && (
        <Avatar
          size={size}
          text={`+${avatars.length - maxItems}`}
          color="light-gray"
          className={className}
        />
      )}
    </StackView>
  )
}

const colorToClassName = (color: AvatarColor): string => {
  switch (color) {
    case 'light-gray':
      return 'bg-comp-badge-light-gray-fg'
    case 'dark-gray':
      return 'bg-comp-badge-dark-gray-fg'
    case 'green':
      return 'bg-comp-badge-green-fg'
    case 'teal':
      return 'bg-comp-badge-teal-fg'
    case 'cyan':
      return 'bg-comp-badge-cyan-fg'
    case 'blue':
      return 'bg-comp-badge-blue-fg'
    case 'purple':
      return 'bg-comp-badge-purple-fg'
    case 'violet':
      return 'bg-comp-badge-violet-fg'
    case 'rose':
      return 'bg-comp-badge-rose-fg'
    case 'red':
      return 'bg-comp-badge-red-fg'
    case 'orange':
      return 'bg-comp-badge-orange-fg'
    case 'yellow':
      return 'bg-comp-badge-yellow-fg'
    default:
      assertUnreachable(color)
  }
}

const sizeToClassName = (size: AvatarSize): string => {
  switch (size) {
    case 'xs':
      return 'w-core-size-100 h-core-size-100 text-core-font-size-50'
    case 's':
      return 'w-core-size-125 h-core-size-125 text-core-font-size-62'
    case 'm':
      return 'w-core-size-175 h-core-size-175 text-core-font-size-75'
    case 'l':
      return 'w-core-size-250 h-core-size-250 text-core-font-size-100'
  }
}
