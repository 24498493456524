import '@js-joda/timezone'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { PlusIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { LocalDate, LocalTime, ZoneId, convert } from '@js-joda/core'
import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { enumToCode } from 'common/data/timezones'
import { lastSchedulableDate } from 'common/scheduling'
import { formatDisplayName } from 'common/utils'
import { addMinutes, isAfter, parse, parseISO } from 'date-fns'
import {
  ChiefComplaint,
  SearchPatients,
  Sex,
  GetDefaultPatient,
  GetLocation,
  GetLocationVariables,
} from 'types/graphql'

import { Form, useWatch } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'

import {
  AppointmentBookingsWarningProvider,
  useAppointmentBookingWarningsContext,
} from 'src/components/AppointmentBookingWarningModals/AppointmentBookingWarningModals'
import AppointmentDefinitionComboBoxCell from 'src/components/AppointmentDefinition/AppointmentDefinitionComboBoxCell'
import Box from 'src/components/atoms/Box/Box'
import Button, { Submit } from 'src/components/atoms/Button/Button'
import ChiefComplaintSelectField from 'src/components/atoms/ChiefComplaintSelectField/ChiefComplaintSelectField'
import Divider from 'src/components/atoms/Divider/Divider'
import InputField from 'src/components/atoms/InputField/InputField'
import { FieldLabel } from 'src/components/atoms/Label/Label'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import PhoneInputField from 'src/components/atoms/PhoneInputField/PhoneInputField'
import SexSelectField from 'src/components/atoms/SexSelectField/SexSelectField'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import Typography from 'src/components/atoms/Typography/Typography'
import { useGetLexicalHtml } from 'src/components/atoms/WysiwygField/hooks/useGetLexicalHtml'
import FieldError from 'src/components/FieldError/FieldError'
import LocationDropdownCell from 'src/components/Location/LocationDropdownCell'
import DataDisplayList from 'src/components/molecules/DataDisplayList/DataDisplayList'
import DataRow from 'src/components/molecules/DataRow/DataRow'
import { DatePickerField } from 'src/components/molecules/DatePicker/DatePicker'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import EnhancedPatientSearch, {
  GET_PATIENT,
} from 'src/components/Patient/EnhancedPatientSearch/EnhancedPatientSearch'
import PractitionerDropdownCell from 'src/components/Practitioner/PractitionerDropdownCell'
import {
  formatAddress,
  formatDateDisplay,
  formatDateFieldValue,
  formatPhoneNumber,
} from 'src/lib/formatters'
import { useAppointmentManager } from 'src/providers/context/AppointmentsManagementContext'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const GET_LOCATION_QUERY = gql`
  query GetLocation($id: String!) {
    location(id: $id) {
      id
      name
      timezone
    }
  }
`
interface ReasonForVisit {
  visitType: string[]
  visitComment: string
  chiefComplaints?: ChiefComplaint[]
}

interface PatientRegistrationIntent {
  familyName: string
  givenName: string
  birthDate: string
  phoneNumber: string
  email: string
  sexAtBirth: Sex
}
interface ContactInformation {
  mobileNumber: string
  homeAddress: {
    line1: string
    line2?: string
    city: string
    state: string
    postalCode: string
  }
}
interface PatientResult {
  id: string
  namePrefix?: string
  nameSuffix?: string
  familyName: string
  givenName: string
  birthDate: string
  sexAtBirth: string
  active: boolean
  contactInformation: ContactInformation
}
export interface NewAppointmentFormData {
  date: Date
  timeStart: string
  practitioner: string
  location: string
  reason: ReasonForVisit
  patientDisplayText: string
  patientId: string
  patient: PatientResult | GetDefaultPatient['patient']
  registrationIntent?: PatientRegistrationIntent
}

const CREATE_APPOINTMENT_MUTATION = gql`
  mutation CreateAppointmentMutation($input: CreateAppointmentInput!) {
    createAppointment(input: $input) {
      id
      start
      end
      practitioner {
        id
      }
      status
      confirmedAt
      statusUpdatedAt
      chiefComplaints
      chartingStatus
      location {
        id
        name
      }
      appointmentDefinitions {
        id
        name
        type
        code
      }
      patient {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
        birthDate
        sexAtBirth
        active
        contactInformation {
          id
          mobileNumber
          homeAddress {
            id
            line1
            line2
            city
            state
            postalCode
          }
        }
        patientRelatedPersonRelationships {
          id
          doesResideWith
          relationshipType
          relatedPerson {
            id
            namePrefix
            givenName
            middleName
            familyName
            nameSuffix
            contactInformation {
              id
              mobileNumber
            }
          }
        }
      }
    }
  }
`

const ExistingPatient = () => {
  const selectedPatientForAppointment = useWatch({
    name: 'patient',
  })

  const practiceCommentHtml = useGetLexicalHtml(
    selectedPatientForAppointment?.practiceComment
  )

  if (!selectedPatientForAppointment) {
    return null
  }

  return (
    <DataDisplayList
      title="Contact details"
      subtitle="This summarizes important details on a caregiver for the patient."
      data={[
        {
          label: 'Date of birth',
          value: formatDateDisplay(selectedPatientForAppointment?.birthDate),
        },
        {
          label: 'Patient mobile phone number',
          value: formatPhoneNumber(
            selectedPatientForAppointment?.contactInformation?.mobileNumber
          ),
        },
        {
          label: 'Caregiver mobile phone number',
          value: (
            <StackView>
              {(
                selectedPatientForAppointment as SearchPatients['searchPatients'][0]
              )?.patientRelatedPersonRelationships.reduce(
                (acc, { relatedPerson, relationshipType }) => {
                  acc.push(
                    <StackView
                      space={25}
                      key={relatedPerson?.contactInformation?.id}
                    >
                      <Typography>
                        {`${formatDisplayName(relatedPerson)} (${
                          relationshipTypeDisplay[relationshipType]
                        }):`}
                      </Typography>
                      <Typography>
                        {formatPhoneNumber(
                          relatedPerson?.contactInformation?.mobileNumber
                        )}
                      </Typography>
                    </StackView>
                  )
                  return acc
                },
                []
              )}
            </StackView>
          ),
        },
        {
          label: 'Address',
          value: formatAddress(
            selectedPatientForAppointment?.contactInformation?.homeAddress ??
              selectedPatientForAppointment?.primaryGuardian?.contactInformation
                ?.homeAddress
          ),
        },
        {
          label: 'Practice comment',
          value: practiceCommentHtml,
        },
      ]}
    />
  )
}

const NewPatient = () => {
  return (
    <FormInputList
      items={[
        {
          name: 'registrationIntent.givenName',
          label: 'First name',
          required: true,
          formInputComponent: InputField,
        },
        {
          name: 'registrationIntent.familyName',
          label: 'Last name',
          required: true,
          formInputComponent: InputField,
        },
        {
          name: 'registrationIntent.birthDate',
          label: 'Date of birth',
          required: true,
          inputProps: {
            validation: {
              setValueAs: (value) => formatDateFieldValue(value),
              validate: (value) => {
                return parseISO(value) > new Date()
                  ? 'Date of birth must be in the past'
                  : null
              },
            },
          },
          formInputComponent: DatePickerField,
        },
        {
          name: 'registrationIntent.phoneNumber',
          label: 'Phone number',
          required: true,
          formInputComponent: PhoneInputField,
        },
        {
          name: 'registrationIntent.email',
          label: 'Email',
          inputProps: {
            type: 'email',
            emptyAs: null,
          },
          formInputComponent: InputField,
        },
        {
          name: 'registrationIntent.sexAtBirth',
          label: 'Sex at birth',
          required: true,
          formInputComponent: SexSelectField,
        },
      ]}
    />
  )
}

const SidepanelNewAppointmentHelper = () => {
  const [getLocation] = useLazyQuery<GetLocation, GetLocationVariables>(
    GET_LOCATION_QUERY
  )
  const { checkForAppointmentBookingWarnings } =
    useAppointmentBookingWarningsContext()
  const {
    closeSidePanel,
    isSidepanelOpen,
    sidepanelContext: { patientId },
  } = useSidepanel()
  const {
    formMethods,
    hasOverlapWithInvalid,
    appointmentDefinitionDurations,
    isNewPatient,
    setIsNewPatient,
  } = useAppointmentManager()
  const [defaultPatient, setDefaultPatient] =
    useState<GetDefaultPatient['patient']>()
  const patientHasBeenDefaulted = useRef(false)

  const { data, loading: defaultPatientLoading } = useQuery<GetDefaultPatient>(
    GET_PATIENT,
    {
      skip: !patientId,
      variables: { id: patientId },
    }
  )

  useEffect(() => {
    if (patientHasBeenDefaulted.current) return
    if (data?.patient) {
      formMethods.setValue('patient', data.patient)
      formMethods.setValue('patientId', data.patient.id)
      setDefaultPatient(data.patient)
      patientHasBeenDefaulted.current = true
    }
  }, [data, formMethods])

  const cleanUp = useCallback(() => {
    closeSidePanel()
    formMethods.resetField('reason.visitComment')
    formMethods.resetField('reason.chiefComplaints')
    formMethods.resetField('patientId')
    formMethods.resetField('patientDisplayText')
    formMethods.resetField('patient')
    setIsNewPatient(false)
  }, [closeSidePanel, formMethods, setIsNewPatient])
  useEffect(() => {
    if (!isSidepanelOpen) {
      cleanUp()
    }
  }, [isSidepanelOpen, formMethods, setIsNewPatient, cleanUp, closeSidePanel])
  const [createAppointment, { loading: creating }] = useMutation(
    CREATE_APPOINTMENT_MUTATION
  )
  const visitTypes = formMethods.watch('reason.visitType')

  const onSubmit = async (newAppointmentData: NewAppointmentFormData) => {
    formMethods.clearErrors('date')
    const duration = (newAppointmentData.reason.visitType || []).reduce(
      (acc, appointmentDefinitionId) => {
        if (!appointmentDefinitionId) {
          return acc
        }
        const result =
          acc + appointmentDefinitionDurations[appointmentDefinitionId]
        return result
      },
      0
    )

    const locationResult = await getLocation({
      variables: {
        id: newAppointmentData.location,
      },
    })
    const { location } = locationResult.data

    const [dateString] = newAppointmentData.date.toISOString().split('T')
    const appointmentDate = LocalDate.parse(dateString)
    const startOfAppointment = parse(
      newAppointmentData.timeStart,
      'h:mm a',
      newAppointmentData.date
    )
    const appointmentTime = LocalTime.of(
      startOfAppointment.getHours(),
      startOfAppointment.getMinutes()
    )

    const locationTimezone = ZoneId.of(enumToCode[location.timezone])
    const start = appointmentDate
      .atTime(appointmentTime)
      .atZone(locationTimezone)

    if (
      hasOverlapWithInvalid({
        start: startOfAppointment,
        end: addMinutes(startOfAppointment, duration),
        resource: `${newAppointmentData.practitioner}-${newAppointmentData.location}`,
      })
    ) {
      return formMethods.setError(
        'date',
        {
          message:
            'The practitioner is not available at the selected timeslot.',
        },
        {
          shouldFocus: true,
        }
      )
    }

    if (isAfter(newAppointmentData.date, lastSchedulableDate())) {
      return formMethods.setError('date', {
        message:
          'Visits can not be scheduled more than 2 years in advance from today’s date',
      })
    }

    // if there is an undefined visit type
    if (newAppointmentData.reason.visitType.some((value) => !value)) {
      // we should show an error message
      return formMethods.setError('reason.visitType', {
        message: 'Please select at least one visit type',
      })
    }

    const { confirmed } = await checkForAppointmentBookingWarnings({
      startTime: newAppointmentData.timeStart,
      date: newAppointmentData.date,
      visitTypes: newAppointmentData.reason.visitType,
      locationId: newAppointmentData.location,
      practitionerId: newAppointmentData.practitioner,
    })
    if (!confirmed) return

    void createAppointment({
      variables: {
        input: {
          startTime: convert(start).toDate(),
          practitionerId: newAppointmentData.practitioner,
          locationId: newAppointmentData.location,
          appointmentDefinitionIds: newAppointmentData.reason.visitType,
          chiefComplaints:
            newAppointmentData.reason.chiefComplaints?.filter(Boolean) ?? [],
          visitComment: newAppointmentData.reason.visitComment,
          patientId: isNewPatient ? undefined : newAppointmentData.patientId,
          patientRegistrationIntent: isNewPatient
            ? newAppointmentData.registrationIntent
            : undefined,
        },
      },
      refetchQueries: ['FindPractitionersAndAppointments'],
      onCompleted: () => {
        cleanUp()
      },
    })
  }

  if (patientId && defaultPatientLoading) {
    return <LoadingSpinner />
  }

  return (
    <StackView>
      <Box className="max-w-3xl" grow>
        <Form autoComplete="off" formMethods={formMethods} onSubmit={onSubmit}>
          <StackView>
            <Box verticalPadding={75} horizontalPadding={100}>
              <StackView space={25}>
                <Typography textStyle="heading">New visit</Typography>
                <Typography color="text-base-color-fg-muted">
                  Schedule a new visit for a patient.
                </Typography>
              </StackView>
            </Box>
            <Divider />
            <Box horizontalPadding={100} verticalPadding={100}>
              <StackView space={25}>
                <Typography textStyle="title" fontWeight="medium">
                  Visit details
                </Typography>
                <Typography
                  color="text-base-color-fg-muted"
                  fontWeight="normal"
                >
                  Complete these mandatory details.
                </Typography>
                <Space space={75} />
                <Divider />
                <FormInputList
                  items={[
                    {
                      name: 'date',
                      label: 'Date',
                      required: true,
                      formInputComponent: DatePickerField,
                    },
                    {
                      name: 'timeStart',
                      label: 'Start time',
                      required: true,
                      inputProps: {
                        // not sure why this field isn't getting picked up, need to revisit InputProps definition
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        controls: ['time'],
                        stepMinute: 5,
                        emptyAs: null,
                        validation: {
                          required: true,
                        },
                        afterChange: () => {
                          const newEventElement = document.querySelector(
                            '[data-id="creating-appointment"]'
                          )
                          if (newEventElement) {
                            newEventElement.scrollIntoView({
                              block: 'center',
                              inline: 'center',
                            })
                          }
                        },
                      },
                      formInputComponent: DatePickerField,
                    },
                    {
                      name: 'practitioner',
                      label: 'Practitioner',
                      formInputComponent: PractitionerDropdownCell,
                      required: true,
                      inputProps: {
                        onChange: () =>
                          formMethods.clearErrors('reason.visitType'),
                      },
                    },
                    {
                      name: 'location',
                      label: 'Location',
                      formInputComponent: LocationDropdownCell,
                      required: true,
                    },
                  ]}
                />
                <Divider />
                <Space space={125} />
                <StackView space={25}>
                  <FormInputList
                    title="Reason for visit"
                    subtitle="Complete these mandatory details."
                    items={[
                      {
                        name: 'reason.visitType',
                        label: 'Visit type(s)',
                        alignItems: 'start',
                        formInputComponent: AppointmentDefinitionComboBoxCell,
                        required: true,
                        inputProps: {
                          onChange: () =>
                            formMethods.clearErrors('reason.visitType'),
                        },
                      },
                      {
                        label: 'Chief complaints',
                        name: 'reason.chiefComplaints',
                        formInputComponent: ChiefComplaintSelectField,
                        inputProps: {
                          appointmentDefinitionId: visitTypes?.[0],
                          onChange: () =>
                            formMethods.clearErrors('reason.chiefComplaints'),
                        },
                      },
                      {
                        name: 'reason.visitComment',
                        label: 'Visit comments',
                        alignItems: 'start',
                        formInputComponent: TextAreaField,
                        inputProps: {
                          rows: 6,
                        },
                      },
                    ]}
                  />
                </StackView>
                <Divider />
                <Space space={125} />
                <StackView
                  direction="row"
                  className="pb-3"
                  space={75}
                  justifyContent="between"
                >
                  <Box flex="8/12">
                    <StackView space={25}>
                      <Typography textStyle="title">Patient details</Typography>
                      {!defaultPatient && (
                        <Typography color="text-base-color-fg-muted">
                          Search or add a patient. If adding a new patient we
                          will add them to the visit and create their file on
                          the day of their visit.
                        </Typography>
                      )}
                    </StackView>
                  </Box>
                  {!defaultPatient && (
                    <Box>
                      <Button
                        buttonStyle="secondary"
                        onClick={() => setIsNewPatient((prev) => !prev)}
                        icon={isNewPatient ? MagnifyingGlassIcon : PlusIcon}
                        text={isNewPatient ? 'Search patient' : 'New patient'}
                      />
                    </Box>
                  )}
                </StackView>
                <Divider />
                {isNewPatient ? (
                  <NewPatient />
                ) : (
                  <>
                    <DataRow
                      label={
                        <FieldLabel name={'patient'} required={true}>
                          Patient name
                        </FieldLabel>
                      }
                      value={
                        <StackView space={50}>
                          <EnhancedPatientSearch
                            name="patient"
                            validation={{ required: true }}
                            disabled={!!defaultPatient}
                            defaultPatientId={defaultPatient?.id}
                            showIcon={!defaultPatient}
                          />
                          <FieldError name="patientDisplayText" />
                        </StackView>
                      }
                    />

                    <ExistingPatient />
                  </>
                )}
              </StackView>
            </Box>
            <Space space={2400} />
          </StackView>
          <Box
            inverse
            verticalPadding={100}
            horizontalPadding={125}
            className="sticky bottom-0 z-50 border-l-0"
          >
            <StackView direction="row" justifyContent="end" space={75}>
              <Button
                text="Cancel"
                buttonStyle="secondary"
                onClick={() => {
                  cleanUp()
                }}
              />
              <Submit
                data-testid="schedule-new-appointment-btn"
                text="Schedule"
                buttonStyle="primary"
                disabled={formMethods.formState.isSubmitting || creating}
                loading={formMethods.formState.isSubmitting || creating}
              />
            </StackView>

            <StackView alignItems="end">
              <FieldError name="date" className="pt-2" />
            </StackView>
          </Box>
        </Form>
      </Box>
    </StackView>
  )
}

const SidepanelNewAppointment = () => (
  <AppointmentBookingsWarningProvider>
    <SidepanelNewAppointmentHelper />
  </AppointmentBookingsWarningProvider>
)

export default SidepanelNewAppointment
