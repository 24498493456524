import { Role } from 'types/graphql'

import { PrivateSet, Redirect, routes } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'

type PrivateProps = React.ComponentProps<typeof PrivateSet>

type PrivateStatusProps = PrivateProps & {
  roles: Role[]
}

/**
 * An extension of the Private route that also checks the user's roles
 */
const PrivateRoles = ({
  roles,
  children,
}: React.PropsWithChildren<PrivateStatusProps>) => {
  const { loading, hasRole } = useEmrAuth()

  if (loading) return null

  if (!hasRole(roles)) {
    return <Redirect to={routes.home()} />
  }

  return <>{children}</>
}

export default PrivateRoles
