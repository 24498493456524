import {
  CreatePatientPaymentPlan,
  GetCurrentPatientPaymentPlan,
  PatientPaymentPlanOptionsQuery,
  GetPatientPaymentPlan,
  CancelPatientPaymentPlan,
  GetPatientPaymentPlanConfig,
  UpdatePatientPaymentPlanPaymentMethod,
  GetPaymentPlanPayment,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import { paymentRefetchQueries } from '../usePatientPayments/usePatientPayments'

const PATIENT_PAYMENT_PLAN_OPTIONS_QUERY = gql`
  query PatientPaymentPlanOptionsQuery($id: String!) {
    patientPaymentPlanOptions(patientId: $id) {
      id
      tilledAccountId
      termOptions {
        months
        amountCents
      }
    }
  }
`

const CREATE_PATIENT_PAYMENT_PLAN_MUTATION = gql`
  mutation CreatePatientPaymentPlan($input: CreatePatientPaymentPlanInput!) {
    createPatientPaymentPlan(input: $input) {
      id
    }
  }
`

const GET_CURRENT_PATIENT_PAYMENT_PLAN_QUERY = gql`
  query GetCurrentPatientPaymentPlan($id: String!) {
    patient(id: $id) {
      id
      currentPaymentPlan {
        id
      }
    }
  }
`

const GET_PATIENT_PAYMENT_PLAN_QUERY = gql`
  query GetPatientPaymentPlan($id: String!) {
    patientPaymentPlan(id: $id) {
      id
      patientId
      termMonths
      termAmountCents
      totalBalanceCents
      paymentsRemaining
      totalPaidAmountCents
      remainingBalanceCents
      percentagePaid
      billingDayOfMonth
      paymentPlanPayments {
        id
        amountCents
        createdAt
        status
        paymentMethod {
          id
          brand
          holderName
          last4
          expirationMonth
          expirationYear
        }
      }
      upcomingPaymentPlanPayments {
        amountCents
        dueAt
        paymentMethod {
          id
          brand
          holderName
          last4
          expirationMonth
          expirationYear
        }
      }
      subscriptionPaymentMethod {
        id
        brand
        holderName
        last4
        expirationMonth
        expirationYear
      }
    }
  }
`

const CANCEL_PAYMENT_PLAN_MUTATION = gql`
  mutation CancelPatientPaymentPlan($id: String!) {
    cancelPatientPaymentPlan(id: $id) {
      id
    }
  }
`

const GET_PATIENT_PAYMENT_PLAN_CONFIG_QUERY = gql`
  query GetPatientPaymentPlanConfig {
    patientPaymentPlanConfig {
      id
      paymentPlanCancellationDirectPatientChargeBillingCodeId
    }
  }
`

const UPDATE_PAYMENT_PLAN_PAYMENT_METHOD_MUTATION = gql`
  mutation UpdatePatientPaymentPlanPaymentMethod(
    $input: UpdatePaymentPlanPaymentMethodInput!
  ) {
    updatePatientPaymentPlanPaymentMethod(input: $input) {
      id
    }
  }
`

const GET_PAYMENT_PLAN_PAYMENT_QUERY = gql`
  query GetPaymentPlanPayment($id: String!) {
    paymentPlanPayment(id: $id) {
      id
      amountCents
      createdAt
      status
      paymentMethod {
        id
        holderName
        brand
        last4
      }
    }
  }
`

export const usePatientPaymentPlanOptionsQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<PatientPaymentPlanOptionsQuery>(
    PATIENT_PAYMENT_PLAN_OPTIONS_QUERY,
    {
      variables: { id: patientId },
      skip: !patientId,
    }
  )

  const patientPaymentPlanOptions = data?.patientPaymentPlanOptions

  return {
    patientPaymentPlanOptions,
    loading,
    error,
  }
}

export const useCreatePatientPaymentPlan = () => {
  const [createPatientPaymentPlan, { data, loading, error }] =
    useMutation<CreatePatientPaymentPlan>(
      CREATE_PATIENT_PAYMENT_PLAN_MUTATION,
      {
        refetchQueries: paymentRefetchQueries,
      }
    )

  const patientPaymentPlan = data?.createPatientPaymentPlan

  return {
    createPatientPaymentPlan,
    patientPaymentPlan,
    loading,
    error,
  }
}

export const useGetCurrentPaymentPlan = (patientId: string) => {
  const { data, loading, error } = useQuery<GetCurrentPatientPaymentPlan>(
    GET_CURRENT_PATIENT_PAYMENT_PLAN_QUERY,
    {
      variables: { id: patientId },
      skip: !patientId,
    }
  )

  const currentPaymentPlan = data?.patient.currentPaymentPlan

  return {
    currentPaymentPlan,
    loading,
    error,
  }
}

export const useGetPatientPaymentPlan = (paymentPlanId: string) => {
  const { data, loading, error } = useQuery<GetPatientPaymentPlan>(
    GET_PATIENT_PAYMENT_PLAN_QUERY,
    {
      variables: { id: paymentPlanId },
      skip: !paymentPlanId,
    }
  )

  const patientPaymentPlan = data?.patientPaymentPlan

  return {
    patientPaymentPlan,
    loading,
    error,
  }
}

export const useCancelPatientPaymentPlan = (paymentPlanId: string) => {
  const [cancelPaymentPlan, { loading, error }] =
    useMutation<CancelPatientPaymentPlan>(CANCEL_PAYMENT_PLAN_MUTATION, {
      variables: { id: paymentPlanId },
      skip: !paymentPlanId,
      refetchQueries: paymentRefetchQueries,
    })

  return {
    cancelPaymentPlan,
    loading,
    error,
  }
}

export const useGetPatientPaymentPlanConfig = () => {
  const { data, ...rest } = useQuery<GetPatientPaymentPlanConfig>(
    GET_PATIENT_PAYMENT_PLAN_CONFIG_QUERY
  )

  const paymentPlanCancellationDirectPatientChargeBillingCodeId =
    data?.patientPaymentPlanConfig
      ?.paymentPlanCancellationDirectPatientChargeBillingCodeId

  return {
    paymentPlanCancellationDirectPatientChargeBillingCodeId,
    ...rest,
  }
}

export const useUpdatePaymentPlanPaymentMethod = () => {
  const [updatePaymentPlanPaymentMethod, { ...rest }] =
    useMutation<UpdatePatientPaymentPlanPaymentMethod>(
      UPDATE_PAYMENT_PLAN_PAYMENT_METHOD_MUTATION
    )

  return {
    updatePaymentPlanPaymentMethod,
    ...rest,
  }
}

export const usePaymentPlanPaymentQuery = (paymentPlanPaymentId) => {
  const { data, ...rest } = useQuery<GetPaymentPlanPayment>(
    GET_PAYMENT_PLAN_PAYMENT_QUERY,
    {
      variables: { id: paymentPlanPaymentId },
    }
  )

  const paymentPlanPayment = data?.paymentPlanPayment

  return {
    paymentPlanPayment,
    ...rest,
  }
}
