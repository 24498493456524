import { useMemo } from 'react'

import { isEmpty } from '@medplum/core'
import {
  Questionnaire as FHIRQuestionnaire,
  QuestionnaireItem,
  QuestionnaireItemEnableWhen,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemAnswer,
} from '@medplum/fhirtypes'
import SYSTEMS from 'common/cdr/concepts/systems'
import { cloneDeep, isNumber } from 'lodash'
import { evaluate } from 'mathjs'

import { UseFormReturn, useFormContext } from '@redwoodjs/forms'

import { CheckboxField } from 'src/components/atoms/Checkbox'
import Divider from 'src/components/atoms/Divider'
import StackView from 'src/components/atoms/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField'
import Typography, {
  FontSizeType,
  FontWeightType,
  TextStyle,
  TypographyColor,
} from 'src/components/atoms/Typography'
import { InputFieldWithUnit } from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import { RadioButtonField } from 'src/components/molecules/RadioButton/RadioButton'
import { useEffectOnce } from 'src/utils'

import { getItemDisplayCategory } from '../QuestionnaireOrderSidepanelView/QuestionnaireOrderSidepanelView'

const YES_ANSWER_OPTION = {
  valueCoding: {
    system: SYSTEMS.QUESTIONNAIRE_RESPONSE,
    code: 'YES',
    display: 'Yes',
  },
}

const NO_ANSWER_OPTION = {
  valueCoding: {
    system: SYSTEMS.QUESTIONNAIRE_RESPONSE,
    code: 'NO',
    display: 'No',
  },
}

// we should get this from the backend
export const questionnaireCodes = [
  'EPDS',
  'PHQ_2',
  'PHQ_9_A',
  'GAD_7',
  'PSC_17',
  'PSC_17_Y',
  'ACT',
  'CRAFFT',
  'NICHQ_VANDERBILT_PARENT_TEACHER_INFORMANT',
  'ASQ_3',
  'M_CHAT_R',
]

const QuestionnaireItemText = ({
  name,
  questionnaireItem,
  index,
  isPatientFacing,
}: {
  name: string
  questionnaireItem: QuestionnaireItem
  index: number
  isPatientFacing?: boolean
}) => {
  const formMethods = useFormContext()
  return (
    <QuestionnaireItemMetadataProvider
      key={questionnaireItem.linkId}
      questionnaireItem={questionnaireItem}
      index={index}
      isPatientFacing={isPatientFacing}
    >
      <TextAreaField
        validation={{
          validate: (value) => !isEmpty(value),
        }}
        placeholder="Please provide an explanation..."
        name={`${name}.answer.0.valueString`}
        onChange={(e) => {
          formMethods.setValue(`${name}.answer.0.valueString`, e.target.value)
        }}
        rows={3}
      />
    </QuestionnaireItemMetadataProvider>
  )
}

const QuestionnaireItemChoiceCheckbox = ({ name }: { name: string }) => {
  const { getValues, setValue } = useFormContext()

  const currentValue = getValueFromAnswer(getValues(`${name}`)?.answer?.[0])

  const pathToCheckboxAnswerCode = `${name}.answer.0.valueCoding.code`

  return (
    <CheckboxField
      name={pathToCheckboxAnswerCode}
      checked={currentValue === YES_ANSWER_OPTION.valueCoding.code}
      onChange={() => {
        if (currentValue === YES_ANSWER_OPTION.valueCoding.code) {
          setValue(pathToCheckboxAnswerCode, NO_ANSWER_OPTION.valueCoding.code)
        } else {
          setValue(pathToCheckboxAnswerCode, YES_ANSWER_OPTION.valueCoding.code)
        }
      }}
    />
  )
}

const QuestionnaireItemChoice = ({
  name,
  questionnaireItem,
  index,
  isPatientFacing,
}: {
  name: string
  questionnaireItem: QuestionnaireItem
  index: number
  isPatientFacing?: boolean
}) => {
  const imageValueAttachment = questionnaireItem.extension?.[0]?.valueAttachment

  const isAsqAdditionalInformationQuestionItem =
    questionnaireItem.linkId.includes('additionalinformation') &&
    questionnaireItem.answerOption

  return (
    <QuestionnaireItemMetadataProvider
      key={questionnaireItem.linkId}
      questionnaireItem={questionnaireItem}
      index={index}
      itemText={
        isAsqAdditionalInformationQuestionItem ? questionnaireItem.text : ''
      }
      renderInstructionsBeforeChildren={true}
      isPatientFacing={isPatientFacing}
    >
      {imageValueAttachment && (
        <img className="h-fit w-fit" src={imageValueAttachment.url} alt="" />
      )}
      {isAsqAdditionalInformationQuestionItem ? (
        <QuestionnaireItemChoiceCheckbox name={name} />
      ) : (
        <ObjectRadioSelectField
          questionnaireItem={questionnaireItem}
          name={name}
        />
      )}
    </QuestionnaireItemMetadataProvider>
  )
}

const ObjectRadioSelectField = ({ questionnaireItem, name }) => {
  const formMethods = useFormContext()

  return (
    <StackView space={25} direction="col">
      {questionnaireItem.answerOption?.map((choice, index) => {
        return (
          <RadioButtonField
            key={`${index}.${questionnaireItem.linkId}`}
            // validation={{ required: questionnaireItem.required }} -- needs to be styled
            label={choice.valueCoding.display}
            name={`${name}.answer.0.valueCoding.code`} // assumes radio buttons have valueCodings
            value={choice.valueCoding.code}
            onChange={() => {
              formMethods.setValue(`${name}.answer.0`, choice)
            }}
          />
        )
      })}
    </StackView>
  )
}

export const QuestionnaireItemInstructions = ({
  questionnaireItem,
  textStyle = 'normal',
  fontWeight = 'normal',
  size = 's',
  color = 'text-base-color-fg-muted',
}: {
  questionnaireItem: QuestionnaireItem
  fontWeight?: FontWeightType
  size?: FontSizeType
  textStyle?: TextStyle
  color: TypographyColor
}) => {
  const instructions = questionnaireItem.extension?.filter(
    (extension) =>
      extension.url === SYSTEMS.QUESTIONNAIRE_ADDITIONAL_INSTRUCTION
  )

  if (isEmpty(instructions)) return null

  if (instructions.length === 1) {
    return (
      <Typography
        color={color}
        size={size}
        fontWeight={fontWeight}
        textStyle={textStyle}
      >
        {instructions[0].valueString}
      </Typography>
    )
  }

  return (
    <ul className="list-disc pl-5 text-gray-500">
      {instructions.map((instruction, i) => {
        return (
          <li key={instruction.id + i.toString()}>
            <Typography
              color={color}
              size={size}
              fontWeight={fontWeight}
              textStyle={textStyle}
            >
              {instruction.valueString}
            </Typography>
          </li>
        )
      })}
    </ul>
  )
}

type StyleConfig = {
  size?: FontSizeType
  textStyle?: TextStyle
  fontWeight?: FontWeightType
  color?: TypographyColor
}

export const QuestionnaireItemMetadataProvider = ({
  questionnaireItem,
  index,
  itemText,
  questionConfig = {
    size: 's',
    fontWeight: 'medium',
    color: 'text-base-color-fg-default',
  },
  instructionsConfig = {
    size: 'xs',
    textStyle: 'subtitle',
    fontWeight: 'normal',
  },
  renderInstructionsBeforeChildren = false,
  renderQuestionTextBeforeChildren = true,
  children,
  direction = 'col',
  hideInstructions,
  isPatientFacing,
}: {
  questionnaireItem?: QuestionnaireItem
  questionnaireResponseItem?: QuestionnaireResponseItem
  index?: number
  itemText?: string
  questionConfig?: StyleConfig
  instructionsConfig?: StyleConfig
  renderInstructionsBeforeChildren?: boolean
  renderQuestionTextBeforeChildren?: boolean
  children?: JSX.Element | JSX.Element[]
  hideInstructions?: boolean
  direction?: 'row' | 'col'
  isPatientFacing?: boolean
}): JSX.Element => {
  const itemDisplayCategory = getItemDisplayCategory(questionnaireItem)
  const isSection = itemDisplayCategory === 'section'

  const isAsqAdditionalInformationQuestionItem =
    questionnaireItem.linkId.includes('additionalinformation') &&
    questionnaireItem.answerOption

  const isExplanation = questionnaireItem.linkId.includes('explanation')

  const itemDisplay = itemText
    ? itemText
    : questionnaireItem.prefix && questionnaireItem.text
    ? `${questionnaireItem.prefix} ${questionnaireItem.text}`
    : questionnaireItem.prefix
    ? questionnaireItem.prefix
    : !isSection &&
      !isAsqAdditionalInformationQuestionItem &&
      index >= 0 &&
      questionnaireItem.text
    ? `${index + 1}. ${questionnaireItem.text}`
    : questionnaireItem.text

  const instructions = (
    <QuestionnaireItemInstructions
      key={questionnaireItem.linkId}
      questionnaireItem={questionnaireItem}
      fontWeight={instructionsConfig.fontWeight}
      textStyle={instructionsConfig.textStyle}
      size={instructionsConfig.size}
      color={instructionsConfig.color}
    />
  )

  const questionText = (
    <Typography
      size={questionConfig.size}
      textStyle={questionConfig.textStyle}
      fontWeight={questionConfig.fontWeight}
      color={questionConfig.color}
    >
      {itemDisplay}
    </Typography>
  )

  const isAsqAdditionalInformationItem = questionnaireItem.linkId.includes(
    'additionalinformation'
  )

  const isAsqAdditionalInformationExplanation =
    questionnaireItem.linkId.includes('additionalinformation') &&
    questionnaireItem.linkId.includes('explanation')

  if (isAsqAdditionalInformationItem) {
    return (
      <StackView space={75} direction={'col'}>
        {!isAsqAdditionalInformationExplanation &&
          !isAsqAdditionalInformationQuestionItem && (
            <StackView direction="col" space={50}>
              {questionText}
              {children}
              {instructions}
            </StackView>
          )}

        {isAsqAdditionalInformationQuestionItem && (
          <StackView direction="row">
            {children}
            {questionText}
            {instructions}
          </StackView>
        )}

        {isAsqAdditionalInformationExplanation && (
          <StackView direction="col" space={75}>
            {instructions}
            {children}
          </StackView>
        )}
      </StackView>
    )
  } else {
    return (
      <StackView space={50} direction={direction} className="pb-2">
        {!isExplanation && !isPatientFacing && <Divider />}
        {renderQuestionTextBeforeChildren && questionText}
        {!hideInstructions && renderInstructionsBeforeChildren && (
          <>{instructions}</>
        )}

        {children}

        {!renderQuestionTextBeforeChildren && questionText}

        {!hideInstructions && !renderInstructionsBeforeChildren && instructions}
      </StackView>
    )
  }
}

const QuestionnaireQuantityInput = ({
  questionnaireItem,
  name,
  index,
  isPatientFacing,
}: {
  questionnaireItem: QuestionnaireItem
  name: string
  index: number
  isPatientFacing?: boolean
}): JSX.Element => {
  const formMethods = useFormContext()

  const itemUnitCoding = questionnaireItem?.extension?.find(
    (extension) => extension.url === SYSTEMS.QUESTIONNAIRE_ITEM_UNIT
  )?.valueCoding

  if (!itemUnitCoding) return null

  return (
    <QuestionnaireItemMetadataProvider
      questionnaireItem={questionnaireItem}
      index={index}
      questionConfig={{
        size: 's',
        fontWeight: 'semibold',
      }}
      instructionsConfig={{
        size: 'xs',
        fontWeight: 'semibold',
      }}
      isPatientFacing={isPatientFacing}
    >
      <StackView space={50}>
        <InputFieldWithUnit
          unit={itemUnitCoding.code}
          name={`${name}.answer.0.valueInteger`}
          // required={questionnaireItem.required} -- needs to be styled
          validation={{ required: questionnaireItem.required }}
          type="number"
          className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          // className="arrow-hide"  doesn't seem to work
          onChange={(e) => {
            formMethods.setValue(
              `${name}.answer.0.valueInteger`,
              e.target.value
            )
          }}
        />
      </StackView>
    </QuestionnaireItemMetadataProvider>
  )
}

const QuestionnaireItemComponent = ({
  index,
  groupName,
  questionnaireItem,
  isPatientFacing,
}: {
  index: number
  groupName?: string
  questionnaireItem: QuestionnaireItem
  isPatientFacing?: boolean
}): JSX.Element => {
  const pathToItem = groupName
    ? `${groupName}.item.${questionnaireItem.linkId}`
    : `questionnaireResponse.item.${questionnaireItem.linkId}`

  const { setValue } = useFormContext()
  // 'inject' linkIDs into formState (questionnaireResponseItems) from the questionnaireItem
  useEffectOnce(() => {
    setValue(`${pathToItem}.linkId`, questionnaireItem.linkId)
  })

  switch (questionnaireItem.type) {
    case 'integer':
      return (
        <QuestionnaireQuantityInput
          name={pathToItem}
          index={index}
          questionnaireItem={questionnaireItem}
          isPatientFacing={isPatientFacing}
        />
      )
    case 'choice':
      return (
        <QuestionnaireItemChoice
          name={pathToItem}
          index={index}
          questionnaireItem={questionnaireItem}
          isPatientFacing={isPatientFacing}
        />
      )

    case 'group':
      return (
        <QuestionnaireItemGroup
          name={pathToItem}
          index={index}
          questionnaireItem={questionnaireItem}
          isPatientFacing={isPatientFacing}
        />
      )

    case 'text':
      return (
        <QuestionnaireItemText
          name={pathToItem}
          index={index}
          questionnaireItem={questionnaireItem}
          isPatientFacing={isPatientFacing}
        />
      )
  }

  return null
}

const QuestionnaireItemGroup = ({
  index,
  questionnaireItem,
  name,
  isPatientFacing,
}: {
  index: number
  questionnaireItem: QuestionnaireItem
  name: string
  isPatientFacing?: boolean
}): JSX.Element => {
  if (!questionnaireItem) return null
  if (questionnaireItem.type !== 'group') return null

  const itemDisplayCategory = getItemDisplayCategory(questionnaireItem)

  const isSection = itemDisplayCategory === 'section'

  const isAsqAdditionalInformationSection = questionnaireItem.linkId.includes(
    'additionalinformation'
  )

  if (isAsqAdditionalInformationSection && isPatientFacing) return null

  return (
    <QuestionnaireItemMetadataProvider
      questionnaireItem={questionnaireItem}
      index={index}
      questionConfig={{
        size: isSection ? 'xl' : 's',
        fontWeight: 'medium',
      }}
      instructionsConfig={{
        size: 's',
        fontWeight: 'normal',
      }}
      itemText={
        isAsqAdditionalInformationSection ? questionnaireItem.text : undefined
      }
      renderInstructionsBeforeChildren={true}
      isPatientFacing={isPatientFacing}
    >
      <StackView space={75}>
        {questionnaireItem.item?.map((sectionItem, sectionItemIndex) => {
          return (
            <StackView
              key={`${sectionItemIndex}.${sectionItem.linkId}`}
              space={75}
              className={isSection ? '' : 'pl-6'}
            >
              <QuestionnaireItemComponent
                index={sectionItemIndex}
                groupName={name}
                questionnaireItem={sectionItem}
              />
            </StackView>
          )
        })}
      </StackView>
    </QuestionnaireItemMetadataProvider>
  )
}

const getValueFromAnswer = (
  answer: QuestionnaireResponseItemAnswer
): string | number | undefined => {
  if (!answer) return
  // ! check would fail for valueInteger = 0
  if (answer.valueInteger !== undefined) {
    return answer.valueInteger
  }

  if (answer.valueCoding) {
    return answer.valueCoding.code
  }
}

const getAnswerFromEnableWhenCondition = (
  condition: QuestionnaireItemEnableWhen
): string | number | undefined => {
  if (!condition) return
  if (condition.answerInteger) {
    return condition.answerInteger
  }

  if (condition.answerCoding) {
    return condition.answerCoding.code
  }
}

const getAnswerFromQuestionnaireResponseItem = (
  responseItem: QuestionnaireResponseItem
): QuestionnaireResponseItemAnswer | undefined => {
  if (!responseItem || !responseItem.answer) return
  return responseItem.answer[0]
}

const getQuestionnaireResponseItemByLinkId = ({
  linkId,
  formMethods,
  path = ['questionnaireResponse', 'item'],
}: {
  linkId: string
  formMethods
  path?: string[]
}): QuestionnaireResponseItem | undefined => {
  if (!linkId) return

  const responseItems = formMethods.watch(`${path.join('.')}`)

  if (isEmpty(responseItems)) return

  for (const [index, responseItem] of Object.entries(responseItems)) {
    // undefined responseItem possible when enabling / disabling questions
    // we 'clean' these undefined response items from the input on submit

    if (!responseItem) continue

    if (responseItem['item']) {
      const foundResponseItem = getQuestionnaireResponseItemByLinkId({
        linkId,
        formMethods,
        path: [...path, index, 'item'],
      })

      if (foundResponseItem) return foundResponseItem
    }

    if (responseItem['answer'] && responseItem['linkId'] === linkId) {
      return responseItem
    }
  }

  return
}

export const isQuestionnaireItemEnabled = ({
  questionnaireItem,
  formMethods,
}: {
  questionnaireItem: QuestionnaireItem
  formMethods: UseFormReturn<RecursiveCompleteQuestionnaireOrderFormState>
}): boolean => {
  if (
    !questionnaireItem &&
    !questionnaireItem.item &&
    !questionnaireItem.enableWhen
  ) {
    return true
  }

  if (questionnaireItem.item) {
    const childrenEnableResults = questionnaireItem.item.map((item) =>
      isQuestionnaireItemEnabled({ questionnaireItem: item, formMethods })
    )
    // if some child item is enabled this parent item should be enabled
    if (childrenEnableResults.some((result) => result === true)) {
      return true
    }
  }

  const conditionSatisfactionResults = questionnaireItem.enableWhen?.map(
    (enableWhenCondition) => {
      const currentEnableWhenValue = getValueFromAnswer(
        getAnswerFromQuestionnaireResponseItem(
          getQuestionnaireResponseItemByLinkId({
            linkId: enableWhenCondition.question,
            formMethods,
          })
        )
      )

      if (!currentEnableWhenValue) return false

      const enableWhenConditionAnswer =
        getAnswerFromEnableWhenCondition(enableWhenCondition)

      if (isNumber(currentEnableWhenValue)) {
        return evaluate(
          `${currentEnableWhenValue} ${enableWhenCondition.operator} ${enableWhenConditionAnswer}`
        )
      } else if (enableWhenCondition.operator === '=') {
        return currentEnableWhenValue === enableWhenConditionAnswer
      }

      return false
    }
  )

  if (!conditionSatisfactionResults) return true // no enableWhen

  if (questionnaireItem.enableBehavior === 'all') {
    return conditionSatisfactionResults.every((result) => result === true)
  } else if (questionnaireItem.enableBehavior === 'any') {
    return conditionSatisfactionResults.some((result) => result === true)
  }
}

export const getEnabledQuestionnaireItems = (
  questionnaireItems: QuestionnaireItem[],
  formMethods: UseFormReturn<RecursiveCompleteQuestionnaireOrderFormState>
): QuestionnaireItem[] => {
  if (isEmpty(questionnaireItems)) return []
  const enabledQuestionnaireItems = cloneDeep(questionnaireItems)
  for (const [key, questionnaireItem] of questionnaireItems.entries()) {
    if (questionnaireItem.item) {
      const enabledChildren = getEnabledQuestionnaireItems(
        questionnaireItem.item,
        formMethods
      )
      enabledQuestionnaireItems[key].item = enabledChildren
    }
  }
  return enabledQuestionnaireItems.filter((item) =>
    isQuestionnaireItemEnabled({ questionnaireItem: item, formMethods })
  )
}
export enum QuestionnaireResultType {
  DETAILED = 'DETAILED',
  OVERALL_FINDING = 'OVERALL_FINDING',
}

export type RecursiveCompleteQuestionnaireOrderFormState = {
  authorId: string
  notes: string
  refusalReasons: {
    refusalByPatient?: boolean
    refusalByCaregiver?: boolean
    uncooperativePatient?: boolean
    contraindicatedForPatient?: boolean
    underCareOfAnotherProvider?: boolean
  }
  questionnaireResponse: {
    resourceType: string
    source: {
      reference: string
    }
    item: unknown
  }
  overallFinding: {
    orderCode: string
    questionCode: string
    answerCode?: string
  }
  questionnaireSelection: string
  questionnaireResultType: QuestionnaireResultType
}

export const getEnabledQuestionnaireItemLinkIds = (
  questionnaireItems: QuestionnaireItem[],
  formMethods: UseFormReturn<RecursiveCompleteQuestionnaireOrderFormState>
): string[] => {
  const _getEnabledQuestionnaireItemLinkIds = (
    questionnaireItems: QuestionnaireItem[],
    formMethods: UseFormReturn<RecursiveCompleteQuestionnaireOrderFormState>,
    enabledItemLinkIds = []
  ) => {
    if (isEmpty(questionnaireItems)) return []

    for (const questionnaireItem of questionnaireItems) {
      if (questionnaireItem.item) {
        _getEnabledQuestionnaireItemLinkIds(
          questionnaireItem.item,
          formMethods,
          enabledItemLinkIds
        )
      }
      if (isQuestionnaireItemEnabled({ questionnaireItem, formMethods })) {
        enabledItemLinkIds.push(questionnaireItem.linkId)
      }
    }
    return enabledItemLinkIds
  }
  return _getEnabledQuestionnaireItemLinkIds(questionnaireItems, formMethods)
}

export const Questionnaire = ({
  questionnaire,
  isPatientFacing = false,
}: {
  questionnaire: FHIRQuestionnaire
  isPatientFacing?: boolean
}): JSX.Element | null => {
  const formMethods: UseFormReturn<RecursiveCompleteQuestionnaireOrderFormState> =
    useFormContext()

  const enabledItems = useMemo(
    () => getEnabledQuestionnaireItems(questionnaire?.item, formMethods),
    [questionnaire, formMethods]
  )

  if (!questionnaire) return null

  return (
    // spacing between sections
    <StackView space={100} direction="col">
      {enabledItems.map((questionnaireItem, index) => {
        return (
          <QuestionnaireItemComponent
            key={questionnaireItem.linkId}
            index={index}
            questionnaireItem={questionnaireItem}
            isPatientFacing={isPatientFacing}
          />
        )
      })}
    </StackView>
  )
}
