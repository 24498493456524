import { CheckIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'

import Box from '../atoms/Box/Box'
import StackView from '../atoms/StackView/StackView'
import Typography from '../atoms/Typography/Typography'
import {
  StatusIndicator,
  StatusIndicatorColor,
} from '../StatusIndicator/StatusIndicator'

export interface DropdownMenuItemProps {
  selected?: boolean
  displaySelected?: 'checkbox' | 'checkmark'
  direction?: 'row' | 'col'
  label: string
  description?: string
  icon?: React.FunctionComponent<React.ComponentProps<'svg'>>
  statusIndicator?: {
    color: StatusIndicatorColor
  }
  onClick: (selected: boolean) => void
}

export const DropdownMenuItem = ({
  selected,
  displaySelected,
  direction = 'row',
  label,
  description,
  icon: Icon,
  statusIndicator,
  onClick,
}: DropdownMenuItemProps) => {
  return (
    <label
      className={clsx(
        'block',
        'rounded-base-border-radius-selectable-s',
        'border-core-border-width-10',
        'bg-comp-button-color-ghost-enabled-bg',
        'border-comp-button-color-ghost-enabled-border',
        'text-comp-button-color-ghost-enabled-fg',

        'hover:bg-base-color-bg-subtle',
        'hover:text-comp-button-color-ghost-hover-fg',
        'hover:border-comp-button-color-ghost-hover-border',

        'focus:bg-comp-button-color-ghost-focused-bg',
        'focus:text-comp-button-color-ghost-focused-fg',
        'focus:border-comp-button-color-ghost-focused-border',

        'focus-within:bg-base-color-bg-subtle',
        'focus-within:text-comp-button-color-ghost-focused-fg',
        'focus-within:border-comp-toggle-color-primary-selected-enabled-border',

        'active:bg-comp-button-color-ghost-pressed-bg',
        'active:text-comp-button-color-ghost-pressed-fg',
        'active:border-comp-button-color-ghost-pressed-border',

        'cursor-pointer'
      )}
    >
      <StackView direction="row" className="p-core-space-50">
        <StackView direction="row" gap={50}>
          <input
            type="checkbox"
            checked={selected}
            className={clsx(
              'h-4 w-4',
              'rounded',
              'border-gray-300',
              'text-primary',
              'focus:ring-primary',
              displaySelected !== 'checkbox'
                ? 'pointer-events-none absolute opacity-0'
                : null
            )}
            onChange={(e) => onClick(e.target.checked)}
          />

          {Icon ? (
            <Icon className="h-base-size-icon-xs w-base-size-icon-xs" />
          ) : null}

          {statusIndicator ? (
            <Box className="pt-core-space-25">
              <StatusIndicator color={statusIndicator.color} />
            </Box>
          ) : null}

          <StackView direction={direction} gap={25}>
            <Typography
              textStyle="interface-default-s"
              color="text-base-color-fg-muted"
            >
              {label}
            </Typography>

            {description ? (
              <Typography
                textStyle="interface-default-s"
                color="text-base-color-fg-subtle"
              >
                {description}
              </Typography>
            ) : null}
          </StackView>
        </StackView>

        {selected && displaySelected === 'checkmark' ? (
          <CheckIcon className="h-base-size-icon-xs w-base-size-icon-xs text-primary" />
        ) : null}
      </StackView>
    </label>
  )
}
