import { InputFieldProps } from 'src/components/atoms/InputField'
import { getUnitOptions } from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import { VitalInput } from 'src/components/VisitIntakeVital'

export const RespiratoryRateInput = ({
  name,
  validation,
  ...props
}: InputFieldProps) => {
  return (
    <VitalInput
      name={name}
      unitOptions={getUnitOptions(['{Breaths}/min'])}
      validation={validation}
      {...props}
    />
  )
}
