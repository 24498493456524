import { AttachmentFragment } from 'types/graphql'

import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'

export const ATTACHMENT_FRAGMENT = gql`
  fragment AttachmentFragment on Attachment {
    url
    contentType
    data
    title
  }
`

const AttachmentDisplay: React.FC<{ attachment: AttachmentFragment }> = ({
  attachment,
}) => {
  return (
    <FileDisplay
      url={attachment.url}
      data={attachment.data}
      type={attachment.contentType}
      name={attachment.title}
    />
  )
}

export default AttachmentDisplay
