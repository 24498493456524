import { alcoholUseStatusConceptCodings } from 'common/cdr/concepts/observations/socialHistories/index'
import type { AlcoholUseStatus } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const alcoholUseStatusDisplay: {
  [key in AlcoholUseStatus]: string
} = {
  NON_DRINKER: 'Non-drinker',
  EX_DRINKER: 'Ex-drinker',
  CURRENT: 'Current drinker',
  SOCIAL: 'Social drinker',
  LIGHT: 'Light drinker: 1-2 units/day',
  MODERATE: 'Moderate drinker: 3-6 units/day',
  HEAVY: 'Heavy drinker: 7-9 units/day',
  VERY_HEAVY: 'Very heavy drinker: > 9 units/day',
}

export const alcoholUseStatusDisplayToEnum = Object.keys(
  alcoholUseStatusDisplay
).reduce((acc, key) => {
  acc[alcoholUseStatusDisplay[key]] = key
  return acc
}, {})

export const alcoholUseStatusCodeToEnum = alcoholUseStatusConceptCodings.reduce(
  (acc, coding) => {
    if (alcoholUseStatusDisplayToEnum[coding.display]) {
      acc[coding.code] = alcoholUseStatusDisplayToEnum[coding.display]
    }
    return acc
  },
  {}
)

export const alcoholUseStatuses = nameValueArrayFromObject<AlcoholUseStatus>(
  alcoholUseStatusDisplay
)
