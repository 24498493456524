import { ReactElement } from 'react'

import clsx from 'clsx'
interface SpaceProps {
  space?: 0 | 25 | 50 | 75 | 100 | 125 | 150 | 175 | 200 | 2400
  vertical?: boolean
}

const sizeMapping = {
  w: {
    0: 'w-core-size-0',
    25: 'w-core-size-25',
    50: 'w-core-size-50',
    75: 'w-core-size-75',
    100: 'w-core-size-100',
    125: 'w-core-size-125',
    150: 'w-core-size-150',
    175: 'w-core-size-175',
    200: 'w-core-size-200',
    2400: 'w-core-size-2400',
  },
  h: {
    0: 'h-core-size-0',
    25: 'h-core-size-25',
    50: 'h-core-size-50',
    75: 'h-core-size-75',
    100: 'h-core-size-100',
    125: 'h-core-size-125',
    150: 'h-core-size-150',
    175: 'h-core-size-175',
    200: 'h-core-size-200',
    2400: 'h-core-size-2400',
  },
}

const Space = ({ space, vertical = false }: SpaceProps): ReactElement => {
  return (
    <div
      className={clsx(vertical ? sizeMapping.w[space] : sizeMapping.h[space])}
    />
  )
}

export default Space
