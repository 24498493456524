import { useEffect } from 'react'

import { ChevronLeftIcon } from '@heroicons/react/24/solid'

import { useLocation } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/dist/toast'

import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import ProgressBar from 'src/components/atoms/ProgressBar/ProgressBar'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

const PatientLayout = ({
  children,
  tenantId,
  headerTitle,
  showExitButton,
  exitButtonOnClick,
  showProgressBar,
  progressBarPercentage,
  navTitle,
  showBackButton,
  backButtonOnClick,
}: {
  children?: React.ReactNode
  tenantId?: string
  headerTitle?: string
  showExitButton?: boolean
  exitButtonOnClick?: () => void
  showProgressBar?: boolean
  progressBarPercentage?: number
  navTitle?: string
  showBackButton?: boolean
  backButtonOnClick?: () => void
}) => {
  const { pathname } = useLocation()

  // Scroll to top of page when loading page
  useEffect(() => {
    document.getElementById('patient-layout-content')?.scrollTo({ top: 0 })
  }, [pathname])

  return (
    <StackView className="h-[100dvh]">
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />

      <StackView
        className="sticky top-0 border-b border-b-base-color-border-subtle p-core-space-100"
        direction="row"
        alignItems="center"
        justifyContent="between"
        space={100}
      >
        {showBackButton || navTitle ? (
          <StackView direction="row" alignItems="center" space={25}>
            {showBackButton && (
              <Button
                buttonStyle="ghost"
                icon={ChevronLeftIcon}
                onClick={backButtonOnClick}
                aria-label="Go back"
              />
            )}
            {navTitle && (
              <Typography textStyle="interface-strong-s">{navTitle}</Typography>
            )}
          </StackView>
        ) : null}

        {tenantId && (
          <img
            data-testid="practice-logo"
            className="h-auto max-h-[48px] object-contain lg:max-h-[72px]"
            src={`/img/logos/${tenantId}.png`}
            alt="Practice logo"
          />
        )}

        {headerTitle && (
          <Typography fontWeight="semibold" className="flex-grow">
            {headerTitle}
          </Typography>
        )}

        {showExitButton && (
          <Button
            testId="exit-button"
            buttonStyle="ghost"
            text="Exit"
            className="w-min text-base-color-fg-subtle"
            onClick={exitButtonOnClick}
          />
        )}
      </StackView>

      {showProgressBar && (
        <ProgressBar testId="progress-bar" percent={progressBarPercentage} />
      )}

      <StackView
        id="patient-layout-content"
        className="flex-grow overflow-auto"
        direction="row"
        justifyContent="center"
      >
        <Box className="h-screen w-full max-w-[540px] sm:h-auto">
          <Box className="px-core-space-100 py-core-space-150  sm:py-core-space-250">
            {children}
          </Box>
        </Box>
      </StackView>

      <StackView
        className="sticky bottom-0 border-t border-t-base-color-border-subtle bg-base-color-bg-default p-core-space-100"
        direction="row"
      >
        <img src="/img/logo-grayscale.svg" alt="Develo" />
      </StackView>
    </StackView>
  )
}
export default PatientLayout
