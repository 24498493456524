import mergeImages from './mergeImages'

const MAX_WIDTH = 1500
const MAX_HEIGHT = 1500

const ensureImageDimensions = (
  imageSrc: string
): Promise<{ width: number; height: number; src: string }> => {
  return new Promise(function (resolve, reject) {
    const img = new Image()
    img.onload = function () {
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }
      }

      if (width === img.width && height === img.height) {
        resolve({ width, height, src: imageSrc })
      } else {
        const canvas = window.document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)
        const src = canvas.toDataURL()
        resolve({ width, height, src })
      }
    }
    img.onerror = function () {
      reject()
    }
    img.src = imageSrc
  })
}

export const combineImagesInStack = async ({
  imageUrls,
}: {
  imageUrls: string[]
}) => {
  const imagesWithDimensions = await Promise.all(
    imageUrls.map(async (url) => {
      const dimensions = await ensureImageDimensions(url)

      return { ...dimensions }
    })
  )

  const initialValue: {
    src: string
    width: number
    height: number
    x: number
    y: number
  }[] = []

  const imagesWithLayoutCoordinates = imagesWithDimensions.reduce(
    (acc, image) => {
      if (!acc.length) return [{ ...image, x: 0, y: 0 }]

      const [previous] = acc.slice(-1)

      return [...acc, { ...image, x: previous.x + previous.width, y: 0 }]
    },
    initialValue
  )

  const totalHeight = Math.max(
    ...imagesWithLayoutCoordinates.map((image) => image.height)
  )
  const totalWidth = imagesWithLayoutCoordinates.reduce(
    (acc, image) => acc + image.width,
    0
  )

  const outputFormat = 'image/jpeg'
  const newImage = await mergeImages(imagesWithLayoutCoordinates, {
    width: totalWidth,
    height: totalHeight,
    format: outputFormat,
  })

  const content = newImage.split(',')[1]

  return {
    content,
    contentType: outputFormat,
  }
}
