import type { CreateUserInput, User } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import type { RWGqlError } from '@redwoodjs/forms'

import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import UserFormInputList from 'src/components/User/UserFormInputList/UserFormInputList'

type NewFormUser = NonNullable<User>
interface NewUserFormProps {
  onSave: (data: CreateUserInput) => void
  error: RWGqlError
  loading: boolean
}

const NewUserForm = (props: NewUserFormProps) => {
  const onSubmit = (data: NewFormUser) => {
    props.onSave({
      ...data,
    })
  }

  const formMethods = useForm<User>()

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Create',
        submitting: props.loading,
      }}
      formMethods={formMethods}
      onSubmit={onSubmit}
      error={props.error}
    >
      <UserFormInputList />
    </SidepanelForm>
  )
}

export default NewUserForm
