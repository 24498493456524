import type { CoordinationOfBenefitsType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const coordinationOfBenefitTypeDisplay: {
  [key in CoordinationOfBenefitsType]: string
} = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  TERTIARY: 'Tertiary',
}

export const coordinationOfBenefitTypeDescription: {
  [key in CoordinationOfBenefitsType]: string
} = {
  PRIMARY: 'First health plan that is used to cover healthcare services.',
  SECONDARY: 'Second health plan that is used to cover healthcare services.',
  TERTIARY: 'Additional health plan that is used to cover healthcare services.',
}

export const coordinationOfBenefitTypes =
  nameValueArrayFromObject<CoordinationOfBenefitsType>(
    coordinationOfBenefitTypeDisplay
  )
