const applications = ['emr', 'patientPortal'] as const

export type AppType = (typeof applications)[number]

const isAppType = (str: string): str is AppType =>
  applications.map(String).includes(str)

const makeAppType = (): AppType => {
  const { DEVELO_APPLICATION } = process.env

  if (!DEVELO_APPLICATION) return 'emr'

  if (!isAppType(DEVELO_APPLICATION)) {
    throw new Error(
      `Expected DEVELO_APPLICATION to be one of: ${applications.join(', ')}`
    )
  }

  return DEVELO_APPLICATION
}

export const appType = makeAppType()
