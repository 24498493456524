import { useParams } from '@redwoodjs/router'

import useIsPatientChart from '../useIsPatientChart/useIsPatientChart'

const useIsPatientVisit = () => {
  const { glob } = useParams()
  const { isPatientChart, patientId } = useIsPatientChart()

  const isPatientVisit = isPatientChart && glob.includes('visits')
  const appointmentId = isPatientVisit ? glob.split('/')[1] : null

  return {
    isPatientVisit,
    patientId,
    appointmentId,
  }
}

export default useIsPatientVisit
