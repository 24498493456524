import { RegisterOptions, useFormContext } from '@redwoodjs/forms'

import {
  CheckboxField,
  FormControlledCheckboxProps,
} from 'src/components/atoms/Checkbox'
import { FieldLabel } from 'src/components/atoms/Label/Label'
import StackView from 'src/components/atoms/StackView/StackView'
import DataRow from 'src/components/molecules/DataRow/DataRow'

import AddressFormInputList, {
  AddressFormInputListProps,
} from '../AddressFormInputList/AddressFormInputList'

export interface InheritedAddressFieldProps extends AddressFormInputListProps {
  name: string
  useInheritedAddressName: string
  useInheritedAddressDescription: string
  inputProps: FormControlledCheckboxProps
  validation?: RegisterOptions
}

export const InheritedAddressField: React.FC<InheritedAddressFieldProps> = ({
  name,
  useInheritedAddressName,
  useInheritedAddressDescription,
  ...rest
}) => {
  const { watch } = useFormContext()
  const useInheritedAddress = watch(useInheritedAddressName)

  return (
    <>
      <StackView direction="row" space={50} alignItems="center">
        <DataRow
          value={
            <>
              <CheckboxField name={useInheritedAddressName} />
              <FieldLabel name={useInheritedAddressName}>
                {useInheritedAddressDescription}
              </FieldLabel>
            </>
          }
          label={null}
        />
      </StackView>
      {!useInheritedAddress && <AddressFormInputList name={name} {...rest} />}
    </>
  )
}
export default InheritedAddressField
