import { useIdleTimer } from 'react-idle-timer'

import { toast } from '@redwoodjs/web/toast'

import { useEmrAuth } from 'src/auth'
import { AUTH_TIMEOUTS } from 'src/lib/constants'

export const useLogOutOnIdle = () => {
  const { logOut } = useEmrAuth()

  return useIdleTimer({
    timeout: AUTH_TIMEOUTS.IDLE_AFTER_MILLISECONDS,
    onIdle: () => {
      logOut()
      // eslint-disable-next-line no-console
      console.warn('idle timeout: logged out due to inactivity')
      toast.error('logged out due to inactivity')
    },
    crossTab: true,
    syncTimers: 500,
  })
}
