import { useEffect } from 'react'

import { CalendarDaysIcon } from '@heroicons/react/24/solid'
import { Datepicker, MbscDatepickerOptions } from '@mobiscroll/react'
import clsx from 'clsx'

import {
  Controller,
  RegisterOptions,
  get,
  useErrorStyles,
  useFormContext,
  useWatch,
} from '@redwoodjs/forms'

import Box, { BoxProps } from 'src/components/atoms/Box/Box'
import Label from 'src/components/atoms/Label/Label'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

export interface UnregisterProps {
  keepDirtyValues?: boolean
  keepDefaultValue?: boolean
}

interface DatePickerProps extends MbscDatepickerOptions {
  id: string
  hasError?: boolean
  anchorId?: string
  grow?: boolean
  value?: Date
  helpText?: string
  valueFormat?: string
  setValue?: (Date) => void
  datePickerContainerProps?: Omit<BoxProps, 'children'>
  className?: string
  showIcon?: boolean
}

export interface FormControlledDatePickerProps
  extends Omit<DatePickerProps, 'id' | 'hasError'> {
  name: string
  validation?: RegisterOptions
  datePickerContainerProps?: Omit<BoxProps, 'children'>
  afterChange?: (date: Date | string) => void
}
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'

const DatePicker = ({
  id,
  controls = ['calendar'],
  value,
  setValue,
  grow = true,
  datePickerContainerProps,
  hasError,
  helpText,
  label,
  anchorId = '',
  className,
  showIcon = false,
  ...rest
}: DatePickerProps) => {
  const wrapperId = `wrapper-${id}${anchorId ? `-${anchorId}` : ''}`
  return (
    <Box
      id={wrapperId}
      data-testid={wrapperId}
      grow={grow}
      {...datePickerContainerProps}
    >
      {label && <Label htmlFor={id}>{label}</Label>}
      <Box
        className={clsx(
          'develo-datepicker relative',
          className ?? 'rounded-md shadow-sm',
          hasError && 'develo-datepicker-error',
          !!label && 'mt-1'
        )}
      >
        <Datepicker
          animation={false}
          focusOnClose={false}
          returnFormat="jsdate"
          touchUi={false}
          theme="windows"
          anchor={document.getElementById(wrapperId)}
          inputProps={{ id }}
          anchorAlign="start"
          display="anchored"
          value={value}
          onChange={(e) => {
            if (controls.includes('time')) {
              return setValue(e.valueText)
            }
            return setValue(e.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
          themeVariant={'light'}
          controls={controls}
          cssClass={className}
          {...rest}
        />
        {showIcon && (
          <CalendarDaysIcon className="pointer-events-none absolute inset-auto inset-y-1/2 right-2 h-5 w-5 -translate-y-1/2 text-gray-500" />
        )}
      </Box>
      {helpText && (
        <Typography color="text-base-color-fg-muted">{helpText}</Typography>
      )}
    </Box>
  )
}

export const DatePickerField = ({
  name,
  validation,
  datePickerContainerProps,
  anchorId,
  controls = ['calendar'],
  keepDefaultValue = false,
  keepDirtyValues = false,
  afterChange = () => {},
  ...rest
}: FormControlledDatePickerProps & UnregisterProps) => {
  const {
    setValue,
    register,
    unregister,
    formState: { errors },
  } = useFormContext()

  register(name, validation)

  useEffect(() => {
    return () => {
      validation?.shouldUnregister &&
        unregister(name, {
          keepDefaultValue,
          keepDirtyValues,
        })
    }
  }, [
    name,
    unregister,
    validation?.shouldUnregister,
    keepDefaultValue,
    keepDirtyValues,
  ])

  const hasError = !!get(errors, name)
  const fieldValue = useWatch({ name })

  return (
    <DatePicker
      anchorId={anchorId}
      id={name}
      controls={controls}
      datePickerContainerProps={datePickerContainerProps}
      value={fieldValue}
      hasError={hasError}
      setValue={(date) => {
        if (controls.includes('calendar')) {
          setValue(name, date as Date)
        } else {
          setValue(name, date as string)
        }
        afterChange(date)
      }}
      {...rest}
    />
  )
}

export const DatePickerFieldThatWorks = ({
  name,
  validation,
  datePickerContainerProps,
  anchorId,
  controls = ['calendar'],
  defaultValue,
  label,
  style,
  helpText,
  grow,
  className,
  showIcon = false,
  ...rest
}: Omit<FormControlledDatePickerProps, 'value' | 'defaultValue'> &
  UnregisterProps & { value?: string; defaultValue?: string }) => {
  const baseClassNames = clsx(
    'develo-datepicker relative',
    className ?? 'rounded-md shadow-sm',
    !!label && 'mt-1'
  )

  const { className: componentClassName, style: componentStyle } =
    useErrorStyles({
      className: baseClassNames,
      errorClassName: `${baseClassNames} develo-datepicker-error`,
      name: name,
    })

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={validation}
      render={({ field: { onChange, value, name } }) => {
        const wrapperId = `wrapper-${name}${anchorId ? `-${anchorId}` : ''}`

        return (
          <Box
            id={wrapperId}
            data-testid={wrapperId}
            grow={grow}
            {...datePickerContainerProps}
          >
            {label && <Label htmlFor={name}>{label}</Label>}
            <Box
              className={componentClassName}
              style={{ ...componentStyle, ...style }}
            >
              <Datepicker
                animation={false}
                focusOnClose={false}
                returnFormat="iso8601"
                touchUi={false}
                theme="windows"
                anchor={document.getElementById(wrapperId)}
                inputProps={{ id: name }}
                anchorAlign="start"
                display="anchored"
                value={value}
                onChange={(e) => {
                  if (controls.includes('time')) {
                    return onChange(e.valueText)
                  }
                  return onChange(e.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                  }
                }}
                themeVariant={'light'}
                controls={controls}
                cssClass={className}
                {...rest}
              />
              {showIcon && (
                <Box className="absolute inset-y-0 right-0 h-full w-6 rounded-md bg-white">
                  <CalendarDaysIcon className="pointer-events-none absolute inset-auto inset-y-1/2 right-1 h-5 w-5 -translate-y-1/2 text-gray-500" />
                </Box>
              )}
            </Box>
            {helpText && (
              <Typography color="text-base-color-fg-muted">
                {helpText}
              </Typography>
            )}
          </Box>
        )
      }}
    />
  )
}

interface DateRangeFieldProps {
  label?: string
  nameStart: string
  placeholderStart?: string
  nameEnd: string
  placeholderEnd?: string
  validationStart?: RegisterOptions
  validationEnd?: RegisterOptions
  validation?: RegisterOptions
}

export const DateRangeField = ({
  label,
  nameStart,
  placeholderStart,
  nameEnd,
  placeholderEnd,
  validationStart,
  validationEnd,
  validation,
}: DateRangeFieldProps) => {
  return (
    <StackView>
      {label && <Label text={label} />}
      <StackView alignItems="center" space={50} direction="row">
        <DatePickerFieldThatWorks
          name={nameStart}
          placeholder={placeholderStart}
          validation={{
            required: validationStart?.required ?? validation?.required,
            ...(validationStart ?? {}),
          }}
        />
        <Typography>to</Typography>
        <DatePickerFieldThatWorks
          name={nameEnd}
          placeholder={placeholderEnd}
          validation={{
            required: validationEnd?.required ?? validation?.required,
            ...(validationEnd ?? {}),
          }}
        />
      </StackView>
    </StackView>
  )
}

export default DatePicker
