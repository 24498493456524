import { practiceAdminDocumentTypes } from 'src/data/practiceAdminDocumentTypes'

import { DropdownField, DropdownFieldProps } from '../Dropdown/Dropdown'

export const PracticeAdminDocumentTypeSelectField = (
  props: DropdownFieldProps
) => {
  return (
    <DropdownField
      options={practiceAdminDocumentTypes.map(({ name, value }) => ({
        value,
        name,
      }))}
      {...props}
    />
  )
}

export default PracticeAdminDocumentTypeSelectField
