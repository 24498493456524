import { omit } from 'lodash'
import { useParams } from 'react-router-dom'
import {
  DocumentType,
  FindDocumentCaseDetails,
  PatientDocumentType,
} from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import Divider from 'src/components/atoms/Divider'
import DocumentTypeSelectField from 'src/components/atoms/DocumentTypeSelectField'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import {
  getDocumentResolutionType,
  getIsPatientDocument,
  documentCaseResolutionConfig,
} from 'src/components/DocumentCaseResolution/helpers'
import FormInputList from 'src/components/molecules/FormInputList'
import SidepanelButtonFooter from 'src/components/molecules/SidepanelButtonFooter'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { FIND_DOCUMENT_CASE_DETAILS_QUERY } from 'src/pages/CasesPage/DocumentCasesTab/DocumentCase/DocumentCase'
import { AppendSignaturePageInput } from 'src/pages/Sidepanel/SidepanelDocumentCase/AppendSignaturePageInput'
import { EfaxWhenResolvingTaskInput } from 'src/pages/Sidepanel/SidepanelDocumentCase/EfaxWhenResolvingTaskInput'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const DOCUMENT_CASE_STATUS_UPDATE_FRAGMENT = gql`
  fragment DocumentCaseStatusUpdateFragment on DocumentCase {
    id
    type
    subType
    title
    typeSpecificInputs
    comment
    signedByPractitionerId
    baseCaseId
    contacts {
      id
    }
  }
`

export const CREATE_DOCUMENT_CASE_STATUS_UPDATE_MUTATION = gql`
  mutation CreateDocumentCaseStatusUpdate(
    $input: CreateDocumentCaseStatusUpdateInput!
    $documentCaseId: String!
  ) {
    createDocumentCaseStatusUpdate(
      input: $input
      documentCaseId: $documentCaseId
    ) {
      ...DocumentCaseStatusUpdateFragment
    }
  }
  ${DOCUMENT_CASE_STATUS_UPDATE_FRAGMENT}
`

export const DocumentCaseStatusUpdateForm = ({
  documentType,
  documentCase,
}: {
  documentType?: DocumentType | PatientDocumentType
  documentCase: FindDocumentCaseDetails['documentCase']
}) => {
  const { closeSidePanel } = useSidepanel()

  const [
    createDocumentCaseStatusUpdate,
    { loading: creatingDocumentCaseStatusUpdate },
  ] = useMutation(CREATE_DOCUMENT_CASE_STATUS_UPDATE_MUTATION, {
    onCompleted: () => {
      toast.success('Updated document case status')
      closeSidePanel()
    },
    refetchQueries: ['FindDocumentCaseDetails'],
  })

  const isPatientDocument = getIsPatientDocument(documentType)

  // Untyped form state for now -- dynamic due to form building based on document type leads to complex typing here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formMethods = useForm<any>({
    defaultValues: {
      documentType: documentCase.type,
      // HACK: Patient document form / mutation resolver uses a different key compared practice documents
      // Ideally for the current way that forms are build, we should have a consistent key for subtype
      ...(isPatientDocument
        ? { subType: documentCase.subType }
        : { type: documentCase.subType }),
      title: documentCase.title,
      comment: documentCase.comment,
      signedByPractitionerId: documentCase?.signedByPractitionerId,
      ...(documentCase.typeSpecificInputs ?? {}),
    },
    shouldUnregister: true,
  })

  const { patientId, shouldEfax, shouldAddSignature } = formMethods.watch()

  const onCaseStatusUpdate = (input) => {
    const commonInputs = {
      type: documentType,
      // HACK: Patient document form / mutation resolver requires type to be passed in which is not the case
      // for practice documents since they have their own resolver per type
      subType: isPatientDocument ? input.subType : input.type,
      title: input.title,
      comment: shouldAddSignature ? input.comment : null,
      signedByPractitionerId: shouldAddSignature
        ? input.signedByPractitionerId
        : null,
      faxRecipientContactIds: shouldEfax ? input.faxRecipientContactIds : [],
    }

    void createDocumentCaseStatusUpdate({
      variables: {
        documentCaseId: documentCase.id,
        input: {
          ...commonInputs,
          typeSpecificInputs: omit(input, [
            ...Object.keys(commonInputs),
            'patient', // pulled in by patient search input -- do not want to store in db
          ]),
        },
      },
    })
  }

  if (!documentType) {
    return (
      <SidepanelForm
        footerElement={
          <SidepanelButtonFooter
            disabled
            submitText="Submit update"
            submitting={false}
          />
        }
      />
    )
  }

  const resolutionType = getDocumentResolutionType(documentType)
  const { getFormInputListItems } = documentCaseResolutionConfig[resolutionType]
  const originalSenderContact = documentCase.inboundFax?.from
  const originalSenderFaxNumber = documentCase.inboundFax?.faxedFrom
  const defaultSelectedContactIds = documentCase.contacts?.map(({ id }) => id)

  return (
    <SidepanelForm
      formMethods={formMethods}
      onSubmit={async (input) => {
        onCaseStatusUpdate(input)
      }}
      footerElement={
        <SidepanelButtonFooter
          submitting={creatingDocumentCaseStatusUpdate}
          submitText="Submit update"
          cancelText="Cancel"
        />
      }
    >
      <StackView space={100}>
        <FormInputList
          items={getFormInputListItems({
            type: documentType,
            patientId,
            defaultPatientId: documentCase?.patient?.id,
            isDocumentCase: true,
          })}
        />
        <Divider />
        <AppendSignaturePageInput />
        <Divider />
        <EfaxWhenResolvingTaskInput
          name="faxRecipientContactIds"
          originalSenderContact={originalSenderContact}
          originalSenderFaxNumber={originalSenderFaxNumber}
          defaultSelectedContactIds={defaultSelectedContactIds}
        />
      </StackView>
    </SidepanelForm>
  )
}

export const DocumentCaseStatusUpdate = () => {
  const { documentCaseId } = useParams()
  const { data, loading } = useQuery(FIND_DOCUMENT_CASE_DETAILS_QUERY, {
    variables: {
      documentCaseId,
    },
    fetchPolicy: 'cache-first',
  })
  const formMethods = useForm<{ documentType: DocumentType }>({
    defaultValues: {
      documentType: data?.documentCase.type,
    },
  })

  if (loading || !data?.documentCase) {
    return (
      <StackView className="flex h-full">
        <LoadingSpinner />
      </StackView>
    )
  }

  const documentType = formMethods.watch('documentType')
  const documentCase = data.documentCase

  return (
    <StackView className="flex h-full">
      <Form className="flex-0 px-6" formMethods={formMethods}>
        <FormInputList
          title="Document Details"
          items={[
            {
              name: 'documentType',
              label: 'Document type',
              formInputComponent: DocumentTypeSelectField,
              inputProps: {
                patient: true,
                clinic: true,
              },
              required: true,
            },
          ]}
        />
        <Divider />
      </Form>
      <DocumentCaseStatusUpdateForm
        documentType={documentType}
        documentCase={documentCase}
      />
    </StackView>
  )
}
