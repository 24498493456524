import { useParams } from 'react-router-dom'

import { useConditionsQuery } from 'src/pages/PatientChartsPage/PatientHistory/Diagnoses/useConditions'
import SidepanelConditionCreate from 'src/pages/Sidepanel/SidepanelConditionCreate/SidepanelConditionCreate'

const SidepanelZcodeCreate = () => {
  const { patientId } = useParams()
  const { conditionsGroupedByCode } = useConditionsQuery(patientId, 'Z_CODE')

  return (
    <SidepanelConditionCreate
      name="zCode"
      nameDisplay="Z code"
      existingConditions={conditionsGroupedByCode}
      searchLabel="Z code"
    />
  )
}

export default SidepanelZcodeCreate
