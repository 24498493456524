const UndoIcon = ({ className }) => {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.5 5H0.75V1.25M0.968262 11.2677C1.66713 12.3439 2.69261 13.1676 3.89414 13.6181C5.09567 14.0685 6.41025 14.1218 7.64434 13.7703C8.87843 13.4187 9.96699 12.681 10.7508 11.665C11.5346 10.6491 11.9723 9.40851 11.9991 8.12561C12.026 6.84271 11.6409 5.58501 10.9003 4.53711C10.1597 3.48921 9.10244 2.7065 7.88415 2.30359C6.66587 1.90068 5.35056 1.89875 4.13123 2.29846C2.91189 2.69818 1.85294 3.47821 1.10962 4.52417"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UndoIcon
