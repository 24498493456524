import { ResolveStaffMessageCaseMutation } from 'types/graphql'

import { useParams } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/atoms/Button/Button'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useHandleCaseResolution } from 'src/hooks/useHandleCaseResolution/useHandleCaseResolution'

export const RESOLVE_MUTATION = gql`
  mutation ResolveFamilyMessageCaseMutation($familyMessageCaseId: String!) {
    resolveFamilyMessageCase(familyMessageCaseId: $familyMessageCaseId) {
      id
      resolvedAt
      baseCase {
        id
        updatedAt
      }
      resolvedBy {
        id
        familyName
        givenName
      }
      resolvedStatus
    }
  }
`

const SidepanelFamilyMessageCaseResolution = () => {
  const { glob } = useParams()
  const { handleCaseResolution, getNextTaskId } = useHandleCaseResolution()
  const [resolveStaffMessageCase, { loading: resolvingTask }] =
    useMutation<ResolveStaffMessageCaseMutation>(RESOLVE_MUTATION)
  const resolveCase = async () => {
    const familyMessageCaseId: string = glob?.split('/')[1]

    const nextTaskId = await getNextTaskId({ taskId: familyMessageCaseId })
    resolveStaffMessageCase({
      variables: {
        familyMessageCaseId,
      },
      refetchQueries: [
        'FindFamilyMessageCases',
        'GetNotResolvedCasesCountByType',
      ],
      onCompleted: () => {
        handleCaseResolution({ nextTaskId })
      },
    })
  }
  return (
    <SidepanelPage header="Resolve task">
      <SidepanelForm
        footerElement={
          <Button type="submit" text="Resolve task" loading={resolvingTask} />
        }
        onSubmit={() => {
          resolveCase()
        }}
      />
    </SidepanelPage>
  )
}

export default SidepanelFamilyMessageCaseResolution
