import { useState, useEffect, useRef } from 'react'

import { getBase64ContentFromDataUri } from 'common/utils'

import { navigate, useParams } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import Button from 'src/components/atoms/Button'
import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import EFaxIcon from 'src/components/icons/EFaxIcon/EFaxIcon'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { sidepanelRoute } from 'src/lib/routes'
import { GrowthChartDatasetType } from 'src/pages/PatientChartsPage/PatientGrowthCharts/PatientGrowthCharts'
import { useGrowthChartQuery } from 'src/pages/PatientChartsPage/PatientGrowthCharts/useGrowthCharts'
import PrintPatientGrowthChartsDocument from 'src/pdf/components/PrintPatientGrowthChartsDocument'
import { useGeneratePDF } from 'src/pdf/useGeneratePDF'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const UPLOAD_GROWTH_CHART_BINARY_MUTATION = gql`
  mutation UploadGrowthChartBinary($binary: CreateBinaryInput!) {
    uploadGrowthChartBinary(binary: $binary) {
      id
    }
  }
`

const SidepanelPrintGrowthCharts = () => {
  const params = useParams()
  const patientId = params.id
  const selectedDataset = params.selectedDataset as GrowthChartDatasetType

  const {
    sidepanelContext: {
      showCorrectedAge: showCorrectedAgeParameter,
      connectPoints: connectPointsParameter,
      zoomToToday: zoomToTodayParameter,
    },
  } = useSidepanel()

  const showCorrectedAgeSetting = showCorrectedAgeParameter === 'true'
  const connectPoints = connectPointsParameter === 'true'
  const zoomToToday = zoomToTodayParameter === 'true'

  const [isGenerating, setIsGenerating] = useState<boolean>(false)
  const [showCharts, setShowCharts] = useState<boolean>(true)
  const [showCorrectedAge, setShowCorrectedAge] = useState<boolean>(false)
  const [userSettingsLoaded, setUserSettingsLoaded] = useState<boolean>(false)
  const [generated, setGenerated] = useState<URL>()
  const [binary, setBinary] = useState<string>()
  const [allGrowthChartsRendered, setAllGrowthChartsRendered] =
    useState<boolean>(false)

  const {
    transformedGrowthChartDatasets,
    patient,
    todayLineX,
    queriedDataset,
    showGestationalAgeAtBirth,
  } = useGrowthChartQuery({
    patientId,
    growthChartDatasetType: selectedDataset,
  })

  useEffect(() => {
    if (showGestationalAgeAtBirth) {
      setShowCorrectedAge(showCorrectedAgeSetting)
    }
    setUserSettingsLoaded(true)
  }, [showCorrectedAgeSetting, showGestationalAgeAtBirth])

  const generatePDF = useGeneratePDF()

  const [uploadGrowthChartBinary, { loading: uploadingGrowthChartBinary }] =
    useMutation(UPLOAD_GROWTH_CHART_BINARY_MUTATION)

  const printPatientGrowthCharts = useRef<HTMLDivElement>()

  useEffect(() => {
    if (
      patient &&
      printPatientGrowthCharts?.current &&
      allGrowthChartsRendered &&
      userSettingsLoaded
    ) {
      setIsGenerating(true)
      setShowCharts(false)
      void generatePDF({
        forPatient: patient,
        component: printPatientGrowthCharts.current.innerHTML,
        callback: async (doc) => {
          setIsGenerating(false)
          setGenerated(doc.output('bloburl'))
          setBinary(getBase64ContentFromDataUri(doc.output('datauristring')))
        },
        useHtml2Canvas: true,
      })
    }
  }, [generatePDF, patient, allGrowthChartsRendered, userSettingsLoaded])

  if (!patient && !selectedDataset) return null

  return (
    <SidepanelPage header="Print growth charts">
      <StackView className="h-full pt-core-space-100" space={50}>
        {binary && (
          <StackView className="px-core-space-100">
            <Button
              className="w-min"
              text="E-fax"
              icon={EFaxIcon}
              buttonStyle="secondary"
              loading={uploadingGrowthChartBinary}
              onClick={async () => {
                const uploadedGrowthChartBinary = await uploadGrowthChartBinary(
                  {
                    variables: {
                      binary: {
                        content: binary,
                        contentType: 'application/pdf',
                        filename: 'growth-charts.pdf',
                      },
                    },
                  }
                )
                if (
                  !uploadedGrowthChartBinary?.data?.uploadGrowthChartBinary?.id
                ) {
                  toast.error(
                    'Failed to upload growth chart before faxing. Please try again later.'
                  )
                } else {
                  navigate(
                    sidepanelRoute(
                      {
                        route: `/send-fax`,
                        binaryIds: JSON.stringify([
                          uploadedGrowthChartBinary.data.uploadGrowthChartBinary
                            .id,
                        ]),
                        primaryDocument: JSON.stringify({
                          name: 'growth charts',
                          binaryId:
                            uploadedGrowthChartBinary.data
                              .uploadGrowthChartBinary.id,
                          documentType: 'BINARY',
                        }),
                      },
                      location,
                      params
                    )
                  )
                }
              }}
            />
          </StackView>
        )}
        {showCharts && (
          <div className="opacity-0">
            <div ref={printPatientGrowthCharts}>
              <PrintPatientGrowthChartsDocument
                patient={patient}
                transformedGrowthChartDatasets={transformedGrowthChartDatasets}
                showCorrectedAge={showCorrectedAge}
                zoomToToday={zoomToToday}
                connectPoints={connectPoints}
                todayLineX={todayLineX}
                queriedDataset={queriedDataset}
                onAllGrowthChartsRendered={() =>
                  setAllGrowthChartsRendered(true)
                }
              />
            </div>
          </div>
        )}

        {isGenerating ? (
          <LoadingSpinner />
        ) : !isGenerating &&
          params.testing &&
          process.env.NODE_ENV === 'development' ? (
          // This should only display when we are testing the page on dev
          <PrintPatientGrowthChartsDocument
            patient={patient}
            transformedGrowthChartDatasets={transformedGrowthChartDatasets}
            showCorrectedAge={showCorrectedAge}
            zoomToToday={zoomToToday}
            connectPoints={connectPoints}
            todayLineX={todayLineX}
            queriedDataset={queriedDataset}
            testId="print-growth-charts"
          />
        ) : (
          <FileDisplay
            type="application/pdf"
            url={generated}
            showDownloadLink={false}
          />
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPrintGrowthCharts
