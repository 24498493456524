import { SelectFieldProps } from '@redwoodjs/forms/dist/SelectField'

import SelectField from 'src/components/atoms/SelectField/SelectField'
import { menstrualFlows } from 'src/data/menstrualFlows'

export const MenstrualFlowSelectField = (props: SelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      includeEmptyOption={true}
      options={menstrualFlows.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default MenstrualFlowSelectField
