import { useRef } from 'react'

import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { formatDisplayName } from 'common/utils'
import {
  SearchCaregiversForFamilyPortalAdmin,
  SearchCaregiversForFamilyPortalAdminVariables,
} from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { PortalUserStatusBadge } from 'src/components/molecules/PortalUserStatusBadge/PortalUserStatusBadge'
import Table from 'src/components/molecules/Table/Table'
import { genderIdentityDisplay } from 'src/data/genderIdentities'
import {
  formatAge,
  formatDateDisplay,
  formatPhoneNumber,
} from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'

const SEARCH_CAREGIVERS_QUERY = gql`
  query SearchCaregiversForFamilyPortalAdmin(
    $input: RelatedPersonSearchInput!
  ) {
    searchCaregivers(input: $input) {
      id
      givenName
      familyName
      birthDate
      genderIdentity
      contactInformation {
        id
        mobileNumber
        homeAddress {
          id
          line1
          line2
          city
          postalCode
          state
          country
        }
        mailingAddress {
          id
          line1
          line2
          city
          postalCode
          state
          country
        }
      }
      patientRelatedPersonRelationships {
        id
        patient {
          id
          givenName
          familyName
        }
      }
      portalUserStatus
      potentialDuplicatePortalUsers {
        id
      }
    }
  }
`

const defaultColDef: ColDef = {
  cellStyle: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  cellClass: 'cursor-pointer',
  resizable: true,
  autoHeight: true,
  wrapText: true,
  minWidth: 180,
}

const columnDefs: ColDef[] = [
  {
    colId: 'hasDuplicates',
    maxWidth: 50,
    cellRenderer: ({ data }) => {
      return data.potentialDuplicatePortalUsers.length ? (
        <ExclamationCircleIcon
          className="h-base-size-icon-xs w-base-size-icon-xs text-danger"
          data-testid="duplicate-indicator"
          title="This caregiver has duplicate family portal accounts"
        />
      ) : null
    },
  },
  {
    colId: 'nameAndSex',
    headerName: 'Name and sex',
    cellRenderer: ({ data }) => {
      return (
        <StackView gap={25}>
          <Typography textStyle="interface-strong-s">
            {formatDisplayName(data)}
          </Typography>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {genderIdentityDisplay[data.genderIdentity]}
          </Typography>
        </StackView>
      )
    },
  },
  {
    colId: 'birthDate',
    headerName: 'Date of birth',
    cellRenderer: ({ data }) => {
      return (
        <StackView gap={25}>
          <Typography
            textStyle="interface-default-s"
            color="text-base-color-fg-muted"
          >
            {formatDateDisplay(data.birthDate)}
          </Typography>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {formatAge(data.birthDate)}
          </Typography>
        </StackView>
      )
    },
  },
  {
    colId: 'linkedPatients',
    headerName: 'Linked patient(s)',
    cellRenderer: ({ data }) => {
      return data.patientRelatedPersonRelationships.length
        ? data.patientRelatedPersonRelationships
            .map((relationship) => formatDisplayName(relationship.patient))
            .join(', ')
        : 'None'
    },
  },
  {
    colId: 'mobileNumber',
    headerName: 'Login mobile number',
    cellRenderer: ({ data }) => {
      return formatPhoneNumber(data.contactInformation.mobileNumber)
    },
  },
  {
    colId: 'status',
    headerName: 'Status',
    cellRenderer: ({ data }) => {
      return (
        <PortalUserStatusBadge
          userType="relatedPerson"
          status={data.portalUserStatus}
          testId="portal-user-status-badge"
        />
      )
    },
  },
]

export const CaregiverTable = () => {
  const params = useParams()
  const location = useLocation()

  const { query } = params

  const isQueryValid = query?.length >= 2

  const { data, loading } = useQuery<
    SearchCaregiversForFamilyPortalAdmin,
    SearchCaregiversForFamilyPortalAdminVariables
  >(SEARCH_CAREGIVERS_QUERY, {
    variables: {
      input: {
        searchQuery: query,
      },
    },
    skip: !isQueryValid,
  })

  const gridRef = useRef<AgGridReact>()

  if (loading) return <LoadingSpinner />

  return (
    <Table
      testId="family-portal-admin-caregiver-table"
      innerRef={gridRef}
      rowData={data?.searchCaregivers ?? []}
      rowHeight={72}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      animateRows={true}
      onRowClicked={(event) =>
        navigate(
          sidepanelRoute(
            {
              route: `/family-portal-account/caregiver/${event.data.id}`,
              width: 'medium',
              overlay: true,
            },
            location,
            params
          )
        )
      }
    />
  )
}
