import { useParams } from 'react-router-dom'

import PracticeLetterCell from 'src/components/PracticeLetterCell'

const SidepanelPracticeLetter = () => {
  const { practiceLetterId } = useParams()

  return <PracticeLetterCell id={practiceLetterId} />
}

export default SidepanelPracticeLetter
