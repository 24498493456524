import { ReactElement, useRef } from 'react'

import clsx from 'clsx'

import { useFormContext } from '@redwoodjs/forms'

import { tilledFieldOptions } from 'src/hooks/useTilled/tilledFieldOptions'
import useTilled, {
  TilledCardDetailsRefType,
} from 'src/hooks/useTilled/useTilled'

import { getInputFieldClasses } from '../atoms/InputField'
import { FieldLabel } from '../atoms/Label'
import StackView from '../atoms/StackView'

export const useTilledCardDetailsRef = (): TilledCardDetailsRefType => {
  return useRef({
    type: 'card',
    fields: {
      cardNumber: '#card-number-element',
      cardExpiry: '#card-expiration-element',
      cardCvv: '#card-cvv-element',
    },
    tilled: null,
    form: null,
    initiated: false,
  })
}

const TilledCardDetails = ({
  tilledAccountId,
  tilledCardDetailsRef,
}: {
  tilledAccountId: string
  tilledCardDetailsRef: TilledCardDetailsRefType
}): ReactElement => {
  const { register } = useFormContext()

  useTilled(tilledAccountId, tilledCardDetailsRef, tilledFieldOptions)

  return (
    <>
      <StackView space={25}>
        <FieldLabel name="cardNumber" required>
          Card number
        </FieldLabel>
        <div
          id="card-number-element"
          className={clsx(
            ...getInputFieldClasses({
              size: 'm',
            })
          )}
          {...register('cardNumber')}
        />
      </StackView>

      <StackView direction="row" space={100} className="pt-core-space-50">
        <StackView space={25}>
          <FieldLabel name="expiration" required>
            Expiration
          </FieldLabel>
          <div
            id="card-expiration-element"
            className={clsx(
              ...getInputFieldClasses({
                size: 'm',
              })
            )}
            {...register('expiration')}
          />
        </StackView>
        <StackView space={25}>
          <FieldLabel name="cvv" required>
            CVV
          </FieldLabel>
          <div
            id="card-cvv-element"
            className={clsx(
              ...getInputFieldClasses({
                size: 'm',
              })
            )}
            {...register('cvv')}
          />
        </StackView>
      </StackView>
    </>
  )
}

export default TilledCardDetails
