import { DocumentTypesConfig } from 'common/cdr/concepts/documents'
import type { ClinicalResultType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const clinicalResultTypeDisplay: {
  [key in ClinicalResultType]: string
} = {
  ...DocumentTypesConfig['CLINICAL_RESULT']['subTypes'],
}

export const clinicalResultTypes = nameValueArrayFromObject<ClinicalResultType>(
  clinicalResultTypeDisplay
)
