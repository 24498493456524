import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { dateTimeFormatter, jsDateToLocalDate } from 'common/data/date'
import { formatDisplayName, formatMoneyInCents } from 'common/utils'

import { routes, useLocation, useParams } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button/Button'
import { AnchorLink } from 'src/components/atoms/Link/Link'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { GuarantorCommentSection } from 'src/components/GuarantorCommentSection/GuarantorCommentSection'
import { BalanceDisplay } from 'src/components/molecules/BalanceDisplay/BalanceDisplay'
import { DataDisplayList } from 'src/components/molecules/DataDisplayList'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'
import PatientPaymentPlanBalanceCard from 'src/components/PatientPaymentPlanBalanceCard/PatientPaymentPlanBalanceCard'
import { sidepanelRoute } from 'src/lib/routes'
import { openNewTab } from 'src/utils'

import { useSidepanelPatientFinancialsContext } from './SidepanelPatientFinancialsContext'

export const DetailsTab = () => {
  const location = useLocation()
  const params = useParams()
  const { patient } = useSidepanelPatientFinancialsContext()

  const primaryGuarantorRelationship =
    patient.patientRelatedPersonRelationships.find(
      (relationship) =>
        relationship.relatedPerson.id === patient.primaryGuarantorId
    )
  const primaryGuarantor =
    primaryGuarantorRelationship?.relatedPerson ?? patient

  return (
    <StackView className="p-core-space-150" gap={200}>
      <StackView gap={100}>
        <SectionHeader
          title="Balance information"
          description="Summary details regarding the patient's outstanding balance."
        />

        <DataDisplayList
          data={[
            {
              label: 'Total outstanding balance',
              value: (
                <BalanceDisplay textStyle="interface-default-s">
                  {patient.totalOutstandingBalanceCents}
                </BalanceDisplay>
              ),
            },
            patient.currentPaymentPlan
              ? {
                  label: 'Payment plan balance',
                  value: (
                    <BalanceDisplay textStyle="interface-default-s">
                      {patient.currentPaymentPlan.remainingBalanceCents}
                    </BalanceDisplay>
                  ),
                }
              : null,
            patient.currentPaymentPlan
              ? {
                  label: 'Additional balance',
                  value: (
                    <BalanceDisplay textStyle="interface-default-s">
                      {patient.totalOutstandingBalanceCents -
                        patient.currentPaymentPlan.remainingBalanceCents}
                    </BalanceDisplay>
                  ),
                }
              : null,
            {
              label: 'Last payment',
              value: patient.lastPayment ? (
                <StackView>
                  <Typography
                    textStyle="interface-default-s"
                    color="text-base-color-fg-muted"
                  >
                    {jsDateToLocalDate(patient.lastPayment.date).format(
                      dateTimeFormatter('MM/dd/yyyy')
                    )}
                  </Typography>
                  <Typography
                    textStyle="interface-default-xs"
                    color="text-base-color-fg-subtle"
                  >
                    {formatMoneyInCents(patient.lastPayment.amountCents ?? 0)}
                  </Typography>
                </StackView>
              ) : (
                <Typography
                  textStyle="interface-default-s"
                  color="text-base-color-fg-muted"
                >
                  N/A
                </Typography>
              ),
            },
            {
              label: 'Primary guarantor',
              value: (
                <AnchorLink
                  to={`${window.location.origin}${sidepanelRoute(
                    {
                      route: `/caregiver/${primaryGuarantor.id}/financials`,
                      overlay: true,
                      width: 'medium',
                    },
                    location,
                    {
                      ...params,
                      tab: 'family',
                    }
                  )}`}
                  text={formatDisplayName(primaryGuarantor)}
                  rel="noopener noreferrer"
                  target="_blank"
                />
              ),
            },
            {
              label: 'Additional guarantor(s)',
              value:
                patient.patientRelatedPersonRelationships
                  .filter(
                    (relationship) =>
                      relationship.isGuarantor &&
                      relationship.relatedPerson.id !==
                        patient.primaryGuarantorId
                  )
                  .map((relationship) =>
                    formatDisplayName(relationship.relatedPerson)
                  )
                  .join(', ') || 'N/A',
            },
          ].filter(Boolean)}
        />
      </StackView>

      {patient.currentPaymentPlan ? (
        <StackView gap={100}>
          <SectionHeader
            title="Payment plan information"
            description="Summary details regarding the patient's active payment plan."
            actions={
              <Button
                text="View"
                icon={ArrowTopRightOnSquareIcon}
                buttonStyle="secondary"
                onClick={() =>
                  openNewTab(
                    routes.patientChartsGlob({
                      id: patient.id,
                      glob: 'financials',
                    })
                  )
                }
              />
            }
          />
          <PatientPaymentPlanBalanceCard
            patientPaymentPlanId={patient.currentPaymentPlan.id}
          />
        </StackView>
      ) : null}

      {!patient.primaryGuarantorId ? (
        <GuarantorCommentSection
          guarantor={{
            id: patient.id,
            guarantorType: 'PATIENT',
          }}
        />
      ) : null}
    </StackView>
  )
}
