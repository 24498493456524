import { useMemo } from 'react'

import {
  FamilyMemberHistory,
  FindPatientFamilyMemberHistories,
  RelationshipType,
  CreateFamilyMemberHistory,
  UpdateFamilyMemberHistory,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

export const FAMILY_MEMBER_HISTORY_FRAGMENT = gql`
  fragment FamilyMemberHistoryFragment on FamilyMemberHistory {
    id
    relationshipType
    status
    conditions {
      id
      icd10Code
      icd10Description
      onsetAgeInYears
      onsetYear
    }
    updatedAt
  }
`

const FAMILY_MEMBER_HISTORIES_QUERY = gql`
  query FindPatientFamilyMemberHistories($id: String!) {
    patient(id: $id) {
      id
      familyMemberHistories {
        ...FamilyMemberHistoryFragment
      }
    }
  }
  ${FAMILY_MEMBER_HISTORY_FRAGMENT}
`

const CREATE_FAMILY_HISTORY_MUTATION = gql`
  mutation CreateFamilyMemberHistory($input: CreateFamilyMemberHistoryInput!) {
    createFamilyMemberHistory(input: $input) {
      id
      familyMemberHistories {
        ...FamilyMemberHistoryFragment
      }
    }
  }
  ${FAMILY_MEMBER_HISTORY_FRAGMENT}
`

const UPDATE_FAMILY_HISTORY_MUTATION = gql`
  mutation UpdateFamilyMemberHistory($input: UpdateFamilyMemberHistoryInput!) {
    updateFamilyMemberHistory(input: $input) {
      id
      familyMemberHistories {
        ...FamilyMemberHistoryFragment
      }
    }
  }
  ${FAMILY_MEMBER_HISTORY_FRAGMENT}
`

export const useFamilyHistoryQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<FindPatientFamilyMemberHistories>(
    FAMILY_MEMBER_HISTORIES_QUERY,
    {
      variables: { id: patientId },
    }
  )

  const familyMemberHistories = useMemo(
    () => data?.patient?.familyMemberHistories,
    [data?.patient?.familyMemberHistories]
  )

  const familyMemberHistoriesGroupedById = useMemo(() => {
    return data?.patient?.familyMemberHistories.reduce(
      (familyMemberHistoriesGroupedById, familyMemberHistory) => {
        const { id } = familyMemberHistory
        if (!familyMemberHistoriesGroupedById[id]) {
          familyMemberHistoriesGroupedById[id] = familyMemberHistory
        }
        return familyMemberHistoriesGroupedById
      },
      {} as Record<RelationshipType, FamilyMemberHistory>
    )
  }, [data?.patient?.familyMemberHistories])

  return {
    familyMemberHistories,
    familyMemberHistoriesGroupedById,
    loading,
    error,
  }
}

export const useCreateFamilyHistory = () => {
  return useMutation<CreateFamilyMemberHistory>(
    CREATE_FAMILY_HISTORY_MUTATION,
    {
      onCompleted: () => {
        toast.success('Family history created')
      },
    }
  )
}

export const useUpdateFamilyHistory = () => {
  return useMutation<UpdateFamilyMemberHistory>(
    UPDATE_FAMILY_HISTORY_MUTATION,
    {
      onCompleted: () => {
        toast.success('Family history updated')
      },
    }
  )
}
