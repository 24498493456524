import {
  ReactElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'

import { TRANSFORMERS } from '@lexical/markdown'
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
import clsx from 'clsx'
import {
  convertParagraphToLexical,
  initialLexicalEditorStateValue,
} from 'common/lexical/lexical'
import { EditorState } from 'lexical'
import { MacroPhraseSection, MacroPhraseSubSection } from 'types/graphql'

import { RegisterOptions, get, useFormContext } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/dist/toast'

import { getInputFieldClasses, inputFieldErrorClasses } from '../InputField'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import StackView from '../StackView'
import Typography from '../Typography/Typography'

import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin'
import MacroPhrasePlugin from './plugins/MacroPhrasePlugin'
import ToolbarPlugin, { ToolbarButtonSize } from './plugins/ToolbarPlugin'
import { lexicalConfig } from './utils/utils'

export type WysiwygFieldProps = {
  placeholder?: string
  disabled?: boolean
  defaultValue?: string
  onChange?: (editor: string) => void
  validation?: RegisterOptions
  name: string
  testId?: string
  macroPhraseSection?: MacroPhraseSection
  macroPhraseSubsection?: MacroPhraseSubSection
  loading?: boolean
  macroPhraseTypeaheadPosition?: 'above' | 'below'
  className?: string
  buttonSize?: ToolbarButtonSize
}

export type WysiwygFieldRef = {
  refreshContent: () => void
}

const onError = (error) => {
  toast.error('There was an error. Please reload the page to continue')
  // eslint-disable-next-line no-console
  console.error(error)
}

const Placeholder = ({ text }: { text: string }) => {
  return (
    <Typography
      className="pointer-events-none absolute inset-3"
      color="text-base-color-fg-muted"
    >
      {text}
    </Typography>
  )
}

const OnMountPlugin = ({ defaultValue }: { defaultValue?: string }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    // On mount, set the default value
    editor.setEditorState(
      editor.parseEditorState(convertParagraphToLexical(defaultValue))
    )
    // On dismount, unset the editor value
    return () => {
      editor.setEditorState(
        editor.parseEditorState(
          convertParagraphToLexical(
            JSON.stringify(initialLexicalEditorStateValue)
          )
        )
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}

type RefreshContentPluginProps = {
  defaultValue?: string
}

type RefreshContentPluginRef = {
  refreshContent: () => void
}

const RefreshContentPlugin = forwardRef<
  RefreshContentPluginRef,
  RefreshContentPluginProps
>(({ defaultValue }, ref): ReactElement => {
  const [editor] = useLexicalComposerContext()

  useImperativeHandle(ref, () => ({
    refreshContent: () => {
      editor.setEditorState(
        editor.parseEditorState(convertParagraphToLexical(defaultValue))
      )
    },
  }))
  return null
})

const WysiwygField = forwardRef<WysiwygFieldRef, WysiwygFieldProps>(
  (
    {
      name,
      testId,
      placeholder,
      disabled = false,
      defaultValue = null,
      onChange,
      validation,
      macroPhraseSection,
      macroPhraseSubsection,
      loading = false,
      macroPhraseTypeaheadPosition = 'below',
      buttonSize = 's',
      className,
    },
    ref
  ): ReactElement => {
    const {
      setValue,
      register,
      formState: { errors },
    } = useFormContext()

    const refreshContentRef = useRef(null)

    useImperativeHandle(ref, () => ({
      refreshContent: () => {
        refreshContentRef.current?.refreshContent()
      },
    }))

    const hasError = !!get(errors, name)

    useEffect(() => {
      register(name, validation)
    })

    const initialConfig: InitialConfigType = {
      ...lexicalConfig,
      editable: !disabled,
      namespace: `${name}-WysiwygField`,
      onError,
    }

    if (loading) return <LoadingSpinner />

    return (
      <StackView className="wysiwyg-field relative" data-testid={testId}>
        <LexicalComposer initialConfig={initialConfig}>
          <ToolbarPlugin disabled={disabled} buttonSize={buttonSize} />
          <div className="relative">
            <RichTextPlugin
              contentEditable={
                <ContentEditable
                  data-testid={`${testId}-content-editable`}
                  className={clsx([
                    ...getInputFieldClasses({ size: 'm' }),
                    hasError && inputFieldErrorClasses,
                    disabled && 'bg-gray-50',
                    '!h-auto min-h-32 py-core-space-50',
                    'rounded-b-md rounded-t-none border',
                    'outline-0',
                    className,
                  ])}
                />
              }
              placeholder={<Placeholder text={placeholder} />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <OnChangePlugin
              onChange={(editor: EditorState) => {
                editor.read(() => {
                  if (!disabled) {
                    setValue(name, JSON.stringify(editor), {
                      shouldDirty: true,
                    })
                    // If we are mounting the component, we do not want to trigger the onChage of the
                    // parent component. What this conditional does is check to make sure that the editor
                    // does not have the same value as the default value and that the editor also
                    // is not the same value of our initialEditorState
                    if (
                      JSON.stringify(editor) !== defaultValue &&
                      JSON.stringify(editor) !==
                        JSON.stringify(initialLexicalEditorStateValue)
                    ) {
                      onChange && onChange(JSON.stringify(editor))
                    }
                  }
                })
              }}
            />
            <HistoryPlugin />
            <ListPlugin />
            <LinkPlugin />
            <TabIndentationPlugin />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            <MacroPhrasePlugin
              section={macroPhraseSection}
              subsection={macroPhraseSubsection}
              typeaheadPosition={macroPhraseTypeaheadPosition}
            />
            <ListMaxIndentLevelPlugin maxDepth={1} />
            <OnMountPlugin defaultValue={defaultValue} />
            <RefreshContentPlugin
              ref={refreshContentRef}
              defaultValue={defaultValue}
            />
          </div>
        </LexicalComposer>
      </StackView>
    )
  }
)

export default WysiwygField
