import { useState, useMemo } from 'react'

import { PlusIcon, BoltIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import { caseStatusFilterOptions } from 'common/data/baseCases'
import { formatDisplayName } from 'common/utils'
import { useParams as useSidepanelParams } from 'react-router-dom'
import { CaseStatusFilter, GetPractitionersAndUserPools } from 'types/graphql'

import { Form } from '@redwoodjs/forms'
import { useParams, navigate, useLocation } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import MultiSelectDropdownField from 'src/components/molecules/MultiSelectDropdownField'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import PatientTaskListCell from 'src/components/PatientTaskListCell'
import { sidepanelRoute } from 'src/lib/routes'
import { parseTaskAssignee, serializeTaskAssignee } from 'src/utils/task'

const PRACTITIONERS_QUERY = gql`
  query GetPractitionersAndUserPools {
    taskUserPools {
      id
      displayName
    }
    users {
      id
      givenName
      familyName
    }
  }
`

const SidepanelPatientTasks = () => {
  const location = useLocation()
  const params = useParams()

  const { patientId } = useSidepanelParams()
  const [isStatusFilterOpen, setStatusFilterOpen] = useState(false)
  const [selectedStatuses, setSelectedStatuses] = useState<CaseStatusFilter[]>([
    'DUE',
    'OVERDUE',
    'UPCOMING',
  ])

  const [isUserFilterOpen, setUserFilterOpen] = useState(false)

  const { data: usersData } =
    useQuery<GetPractitionersAndUserPools>(PRACTITIONERS_QUERY)

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
  const [selectedUserPoolIds, setSelectedUserPoolIds] = useState<string[]>([])

  const practitionerAndUserPoolsData = useMemo(() => {
    return [
      { name: 'User pools', value: 'user-pools', disabled: true },
      ...(usersData?.taskUserPools ?? []).map((pool) => ({
        name: pool.displayName,
        value: serializeTaskAssignee({ type: 'pool', id: pool.id }),
      })),
    ]
      .concat([{ name: 'Users', value: 'users', disabled: true }])
      .concat(
        (usersData?.users || []).map(
          ({ id: userId, givenName, familyName }) => {
            return {
              name: formatDisplayName({ givenName, familyName }),
              value: serializeTaskAssignee({ type: 'user', id: userId }),
            }
          }
        )
      )
  }, [usersData?.users, usersData?.taskUserPools])

  return (
    <SidepanelPage
      header="Patient tasks"
      description="Overview of all selected tasks for the patient."
    >
      <StackView
        direction="col"
        space={100}
        className="px-core-space-150 py-core-space-100 "
      >
        <StackView direction="row" justifyContent="end" gap={50} wrap>
          <Form>
            <MultiSelectDropdownField
              testId="task-status-filter"
              name="caseStatusFilter"
              icon={BoltIcon}
              urlParamName="caseStatuses"
              emptyDisplayText="Case Status"
              options={caseStatusFilterOptions}
              defaultValue={selectedStatuses}
              open={isStatusFilterOpen}
              onToggle={setStatusFilterOpen}
              onToggleFilter={(values) =>
                setSelectedStatuses(values as CaseStatusFilter[])
              }
              onClearFilter={() => setSelectedStatuses([])}
              optionsClassName="w-core-space-1000"
            />
          </Form>
          <Form>
            <MultiSelectDropdownField
              testId="user-user-pool-filter"
              open={isUserFilterOpen}
              onToggle={setUserFilterOpen}
              name="userPoolOrUserIdFilter"
              icon={UserCircleIcon}
              urlParamName="userUserPoolId"
              emptyDisplayText="User or Pool"
              options={practitionerAndUserPoolsData}
              defaultValue={[]}
              onToggleFilter={(values) => {
                const userIds = values.flatMap((value) => {
                  const assignee = parseTaskAssignee(value)

                  return assignee?.type === 'user' ? [assignee.id] : []
                })

                const poolIds = values.flatMap((value) => {
                  const assignee = parseTaskAssignee(value)

                  return assignee?.type === 'pool' ? [assignee.id] : []
                })

                setSelectedUserIds(userIds)
                setSelectedUserPoolIds(poolIds)
              }}
              onClearFilter={() => {
                setSelectedUserIds([])
                setSelectedUserPoolIds([])
              }}
            />
          </Form>
          <Button
            testId="create-case-btn"
            text="Create task"
            icon={PlusIcon}
            buttonStyle="secondary"
            onClick={() =>
              navigate(
                sidepanelRoute(
                  {
                    route: '/task/create',
                    patientId,
                  },
                  location,
                  params
                )
              )
            }
          />
        </StackView>
        <PatientTaskListCell
          filters={{
            patientId,
            statuses: selectedStatuses,
            userIds: selectedUserIds,
            taskUserPoolIds: selectedUserPoolIds,
          }}
        />
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPatientTasks
