const UnderlineIcon = ({ className }) => {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.5 11.25H9.5M2 0.75V5.25C2 6.90685 3.34315 8.25 5 8.25C6.65685 8.25 8 6.90685 8 5.25V0.75"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UnderlineIcon
