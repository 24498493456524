import { isEmpty } from '@medplum/core'
import isEqual from 'lodash/isEqual'
import { ObservationDefinition } from 'types/graphql'

import { DropdownField } from 'src/components/atoms/Dropdown'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

export const visionScreeningCodes = [
  'VISION_SCREENING_QUANTITATIVE_BILATERAL',
  'VISION_SCREENING_INSTRUMENT_BASED_BILATERAL_REMOTE_ANALYSIS',
  'VISION_SCREENING_INSTRUMENT_BASED_BILATERAL_ON_SITE_ANALYSIS',
]

const VisionScreeningResultInput = ({ name, options }) => {
  return (
    <StackView justifyContent="center" alignItems="center">
      <div className="w-28">
        <DropdownField
          options={options}
          name={name}
          itemsContainerClassName="justify-center"
          className="border-none pr-0 shadow-none drop-shadow-none"
        />
      </div>
    </StackView>
  )
}

export const VisionScreeningTable = ({
  observationDefinitions,
}: {
  observationDefinitions: ObservationDefinition[]
}) => {
  if (isEmpty(observationDefinitions)) return null

  const optionsFromDefinition = observationDefinitions
    .find(
      (definition) =>
        !isEqual(
          definition?.validCodedValueSet,
          observationDefinitions[0]?.validCodedValueSet // first definition is always for OverallFinding
        )
    )
    ?.validCodedValueSet?.map((coding) => {
      return { name: coding.display, value: coding.code }
    })
  const resultOptions = [
    { name: '', value: null },
    ...(optionsFromDefinition ?? []),
  ]

  return (
    <table className="min-w-full border-separate border-spacing-0 rounded-md border text-center ">
      <thead>
        <tr className="rounded-tl-md">
          <th scope="col" className=" border-gray-200px-6 py-4 ">
            <Typography>Eye</Typography>
          </th>
          <th scope="col" className="border-l border-gray-200 px-6 py-4">
            <Typography>Without correction</Typography>
          </th>
          <th scope="col" className="border-l border-gray-200 px-6 py-4">
            <Typography>Correction</Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="border-t border-gray-200 px-6 py-4">
            <Typography>Left</Typography>
          </td>
          <td className="border-l border-t border-gray-200">
            <VisionScreeningResultInput
              name="findings.leftEye.answerCode"
              options={resultOptions}
            />
          </td>
          <td className="border-l border-t border-gray-200">
            <VisionScreeningResultInput
              name="findings.leftEyeCorrected.answerCode"
              options={resultOptions}
            />
          </td>
        </tr>
        <tr>
          <td className="border-t px-6 py-4">
            <Typography>Right</Typography>
          </td>
          <td className="border-l border-t border-gray-200">
            <VisionScreeningResultInput
              name="findings.rightEye.answerCode"
              options={resultOptions}
            />
          </td>
          <td className="border-l border-t border-gray-200">
            <VisionScreeningResultInput
              name="findings.rightEyeCorrected.answerCode"
              options={resultOptions}
            />
          </td>
        </tr>
        <tr>
          <td className="border-t px-6 py-4 ">
            <Typography>Both</Typography>
          </td>
          <td className="border-l border-t border-gray-200">
            <VisionScreeningResultInput
              name="findings.bothEyes.answerCode"
              options={resultOptions}
            />
          </td>
          <td className="border-l border-t border-gray-200">
            <VisionScreeningResultInput
              name="findings.bothEyesCorrected.answerCode"
              options={resultOptions}
            />
          </td>
        </tr>
      </tbody>
    </table>
  )
}
