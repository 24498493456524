import { formatDisplayName } from 'common/utils'
import { formatPhoneNumber } from 'react-phone-number-input'
import { PatientGuardianDetailsFragment } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { languageDisplay } from 'src/data/languages'
import { sexDisplay } from 'src/data/sexes'
import { formatDateDisplay, formatAddress } from 'src/lib/formatters'

import { LabeledValue } from './LabeledValue'
import { RelatedPersonDetails } from './RelatedPersonDetails'
import { SubSection } from './SubSection'

export const PatientAndGuardians = ({
  patient,
}: {
  patient: PatientGuardianDetailsFragment
}) => {
  const guardianRelationships =
    patient.patientRelatedPersonRelationships?.filter((relationship) => {
      return ['NATURAL', 'LEGAL'].includes(relationship.guardianshipType)
    })

  const primaryInsurance = patient.insuranceCoverages?.find(
    (coverage) =>
      coverage.status === 'ACTIVE' &&
      coverage.coordinationOfBenefitsType === 'PRIMARY'
  )

  return (
    <SubSection header="Patient and guardian(s)">
      <StackView space={50}>
        <Typography textStyle="interface-default-l">
          {formatDisplayName(patient)}
        </Typography>
        <StackView direction="row" space={100}>
          <StackView className="w-1/2">
            <LabeledValue
              label="DOB"
              value={formatDateDisplay(patient.birthDate)}
            />
            <LabeledValue label="Sex" value={sexDisplay[patient.sexAtBirth]} />
            <LabeledValue
              label="Language"
              value={
                languageDisplay[
                  patient.contactInformation?.primaryLanguage ||
                    patient.primaryGuardian?.contactInformation?.primaryLanguage
                ]
              }
            />
            {primaryInsurance && (
              <>
                <LabeledValue
                  label="Insurance"
                  value={primaryInsurance.payer.displayName}
                />
                <LabeledValue
                  label="Member ID"
                  value={primaryInsurance.planMemberIdentifier}
                />
              </>
            )}
          </StackView>
          <StackView className="w-1/2">
            <LabeledValue
              label="Address"
              value={formatAddress(
                patient.contactInformation?.homeAddress ||
                  patient.primaryGuardian.contactInformation.homeAddress
              )}
            />
            <LabeledValue
              label="Email"
              value={patient.contactInformation?.emailAddress}
            />
            <LabeledValue
              label="Mobile"
              value={formatPhoneNumber(
                patient.contactInformation?.mobileNumber
              )}
            />
            <LabeledValue
              label="Home"
              value={formatPhoneNumber(patient.contactInformation?.homeNumber)}
            />
          </StackView>
        </StackView>
        <StackView direction="row" className="pt-core-space-50" wrap>
          {guardianRelationships.map((relationship) => (
            <RelatedPersonDetails
              key={relationship.id}
              relationship={relationship}
            />
          ))}
        </StackView>
      </StackView>
    </SubSection>
  )
}
