import { omit } from 'lodash'
import { compact } from 'lodash'

import { useFormContext } from '@redwoodjs/forms'

import Button from 'src/components/atoms/Button/Button'
import InsuranceCoverageLevelSelectField from 'src/components/atoms/InsuranceCoverageLevelSelectField/InsuranceCoverageLevelSelectField'
import InsuranceInNetworkIndicatorSelectField from 'src/components/atoms/InsuranceInNetworkIndicatorSelectField/InsuranceInNetworkIndicatorSelectField'
import InsurancePlanStatusSelectField from 'src/components/atoms/InsurancePlanStatusSelectField/InsurancePlanStatusSelectField'
import InsuranceTimeQualifierSelectField from 'src/components/atoms/InsuranceTimeQualifierSelectField/InsuranceTimeQualifierSelectField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { FormInputList } from 'src/components/molecules/FormInputList'
import { coverageLevelDisplay } from 'src/data/insuranceCoverageLevel'
import { inNetworkIndicatorDisplay } from 'src/data/insuranceInNetworkIndicators'
import { insuranceTimeQualifiersDisplay } from 'src/data/insuranceTimeQualifiers'

import InputField from '../atoms/InputField/InputField'

const InsuranceCoverageEligibilityCategoryInput = ({
  item,
  prefix,
  removeSelf,
}) => {
  const formMethods = useFormContext()
  const deductibleTimeQualifierCode = formMethods.watch(
    `${prefix}.deductibleTimeQualifierCode`
  )
  const coverageLevel = formMethods.watch(`${prefix}.coverageLevel`)
  const inNetworkIndicator = formMethods.watch(`${prefix}.inNetworkIndicator`)
  const titleElements = compact([
    coverageLevelDisplay[coverageLevel],
    omit(inNetworkIndicatorDisplay, 'NOT_APPLICABLE_OR_NOT_INDICATED')[
      inNetworkIndicator
    ],
  ])
  const title = titleElements.length > 0 ? titleElements.join(', ') : undefined

  return (
    <StackView key={item.id} space={75} divider>
      <StackView direction="row" className="mt-4" justifyContent="between">
        <Typography textStyle="heading">
          {title || 'Eligibility section'}
        </Typography>
        <Button buttonStyle="danger" onClick={removeSelf}>
          remove
        </Button>
      </StackView>
      <FormInputList
        items={[
          {
            name: `${prefix}.inNetworkIndicator`,
            label: 'In Network Indicator',
            required: true,
            formInputComponent: InsuranceInNetworkIndicatorSelectField,
          },
          {
            name: `${prefix}.coverageLevel`,
            label: 'Coverage Level',
            required: true,
            formInputComponent: InsuranceCoverageLevelSelectField,
          },
          {
            name: `${prefix}.planStatus`,
            label: 'Plan Status',
            required: true,
            formInputComponent: InsurancePlanStatusSelectField,
          },
          {
            name: `${prefix}.copay`,
            label: 'Co-pay: $ amount per visit',
            formInputComponent: InputField,
            inputProps: {
              type: 'number',
              step: 0.01,
            },
          },
          {
            name: `${prefix}.coinsurance`,
            label: 'Co-insurance: % share of billed amount',
            formInputComponent: InputField,
            inputProps: {
              type: 'number',
            },
          },
          {
            name: `${prefix}.deductibleTimeQualifierCode`,
            label: 'Deductible Time Qualifier',
            formInputComponent: InsuranceTimeQualifierSelectField,
          },
          {
            name: `${prefix}.deductibleTotal`,
            label: `Deductible: total $ per ${insuranceTimeQualifiersDisplay[
              deductibleTimeQualifierCode
            ]?.toLowerCase()}`,
            formInputComponent: InputField,
            inputProps: {
              type: 'number',
              step: 0.01,
            },
          },
          {
            name: `${prefix}.deductibleRemaining`,
            label: 'Deductible: $ amount remaining',
            formInputComponent: InputField,
            inputProps: { type: 'number', step: 0.01 },
          },
          {
            name: `${prefix}.outOfPocketMax`,
            label: `Out of pocket max: $ max per ${insuranceTimeQualifiersDisplay[
              deductibleTimeQualifierCode
            ]?.toLowerCase()}`,
            formInputComponent: InputField,
            inputProps: { type: 'number', step: 0.01 },
          },
        ]}
      />
    </StackView>
  )
}

export default InsuranceCoverageEligibilityCategoryInput
