import SelectField, {
  SelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { familyMessageSubjects } from 'src/data/familyMessages'

export const FamilyMessageSubjectSelect = (props: SelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={familyMessageSubjects.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}
