import clsx from 'clsx'
import { assertUnreachable } from 'common/utils'

import { StatusIndicator } from 'src/components/StatusIndicator/StatusIndicator'

import StackView from '../StackView'

export const BADGE_COLORS = [
  'light-gray',
  'dark-gray',
  'green',
  'teal',
  'cyan',
  'blue',
  'purple',
  'violet',
  'rose',
  'red',
  'orange',
  'yellow',
] as const

export type BadgeColor = (typeof BADGE_COLORS)[number]

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  color?: BadgeColor
  size?: 's' | 'l'
  testId?: string
  showDot?: boolean
}

interface BadgeWithChildren extends Props {
  text?: never
  icon?: never
  children: React.ReactNode
}

interface BadgeWithText extends Props {
  text?: string | number
  icon?: React.FunctionComponent<React.ComponentProps<'svg'>>
  children?: never
}

type BadgeProps = BadgeWithChildren | BadgeWithText

const Badge = ({
  text,
  color = 'light-gray',
  size = 's',
  className,
  children,
  testId,
  icon: Icon,
  showDot = false,
}: BadgeProps) => {
  const classes = [
    'inline-flex items-center whitespace-nowrap',
    'py-base-space-selectable-inset-xs',
    'rounded-base-border-radius-selectable-pill',
    'border-base-border-width-selectable-s',
  ]

  switch (size) {
    case 's':
      classes.push(
        'px-base-space-selectable-inset-m',
        'font-base-typography-interface-strong-xs-font-family',
        'text-base-typography-interface-strong-xs-font-size',
        'font-base-typography-interface-strong-xs-font-weight',
        'leading-base-typography-interface-strong-xs-line-height'
      )
      break
    case 'l':
      classes.push(
        'px-base-space-selectable-inset-m',
        'font-base-typography-interface-strong-s-font-family',
        'text-base-typography-interface-strong-s-font-size',
        'font-base-typography-interface-strong-s-font-weight',
        'leading-base-typography-interface-strong-s-line-height'
      )
      break
    default:
      assertUnreachable(size)
  }

  switch (color) {
    case 'light-gray':
      classes.push(
        'border-comp-badge-light-gray-border',
        'bg-comp-badge-light-gray-bg',
        'text-comp-badge-light-gray-fg'
      )
      break
    case 'dark-gray':
      classes.push(
        'border-comp-badge-dark-gray-border',
        'bg-comp-badge-dark-gray-bg',
        'text-comp-badge-dark-gray-fg'
      )
      break
    case 'green':
      classes.push(
        'border-comp-badge-green-border',
        'bg-comp-badge-green-bg',
        'text-comp-badge-green-fg'
      )
      break
    case 'teal':
      classes.push(
        'border-comp-badge-teal-border',
        'bg-comp-badge-teal-bg',
        'text-comp-badge-teal-fg'
      )
      break
    case 'cyan':
      classes.push(
        'border-comp-badge-cyan-border',
        'bg-comp-badge-cyan-bg',
        'text-comp-badge-cyan-fg'
      )
      break
    case 'blue':
      classes.push(
        'border-comp-badge-blue-border',
        'bg-comp-badge-blue-bg',
        'text-comp-badge-blue-fg'
      )
      break
    case 'purple':
      classes.push(
        'border-comp-badge-purple-border',
        'bg-comp-badge-purple-bg',
        'text-comp-badge-purple-fg'
      )
      break
    case 'violet':
      classes.push(
        'border-comp-badge-violet-border',
        'bg-comp-badge-violet-bg',
        'text-comp-badge-violet-fg'
      )
      break
    case 'rose':
      classes.push(
        'border-comp-badge-rose-border',
        'bg-comp-badge-rose-bg',
        'text-comp-badge-rose-fg'
      )
      break
    case 'red':
      classes.push(
        'border-comp-badge-red-border',
        'bg-comp-badge-red-bg',
        'text-comp-badge-red-fg'
      )
      break
    case 'orange':
      classes.push(
        'border-comp-badge-orange-border',
        'bg-comp-badge-orange-bg',
        'text-comp-badge-orange-fg'
      )
      break
    case 'yellow':
      classes.push(
        'border-comp-badge-yellow-border',
        'bg-comp-badge-yellow-bg',
        'text-comp-badge-yellow-fg'
      )
      break
    default:
      assertUnreachable(color)
  }

  return (
    <span data-testid={testId} className={clsx(classes, className)}>
      <StackView direction="row" alignItems="center" gap={25}>
        {showDot && <StatusIndicator color={color} />}
        {Icon && <Icon className="h-core-size-75 w-core-size-75" />}
        {children ?? text}
      </StackView>
    </span>
  )
}

export default Badge
