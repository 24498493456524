import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { useParams } from 'react-router-dom'

import { useForm } from '@redwoodjs/forms'
import { useParams as useParamsRW } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import Box from 'src/components/atoms/Box/Box'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import Divider from 'src/components/atoms/Divider/Divider'
import { DropdownField } from 'src/components/atoms/Dropdown/Dropdown'
import Label from 'src/components/atoms/Label/Label'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import FieldError from 'src/components/FieldError/FieldError'
import DataRow from 'src/components/molecules/DataRow/DataRow'
import InputDropdown from 'src/components/molecules/InputDropdownField/InputDropdownField'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { targetTimeUnitDisplay } from 'src/data/targetTimeUnitDisplay'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const CREATE_CASE_STATUS_UPDATE_QUERY = gql`
  mutation CreateCaseStatusUpdate($input: CreateCaseStatusUpdateInput!) {
    createCaseStatusUpdate(input: $input) {
      id
      baseCaseId
      text
      baseCase {
        id
        updatedAt
        dueAt
        schedulingCase {
          id
          schedulingLaterTime
          schedulingLaterTimeUnit
        }
      }
    }
  }
`

const READ_PATIENT = gql`
  query ReadPatient($id: String!, $schedulingCaseId: String!) {
    schedulingCase(id: $schedulingCaseId) {
      id
      baseCaseId
    }
    patient(id: $id) {
      id
      givenName
      familyName
      contactInformation {
        id
        mobileNumber
        homeAddress {
          id
          line1
          line2
          city
          state
          postalCode
        }
      }
      patientRelatedPersonRelationships {
        id
        doesResideWith
        relationshipType
        relatedPerson {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
          contactInformation {
            id
            mobileNumber
            emailAddress
          }
        }
      }
    }
  }
`

interface FormProps {
  voiceMessageWithPatient: boolean
  voiceMessageWithCaregiver: boolean
  caregiver?: string
  delayVisitScheduling: boolean
  targetTime?: string
  targetTimeOption?: string
}

const SidepanelSchedulingCaseStatusUpdate = () => {
  const { patientId, patientRegistrationIntentId } = useParams()
  const paramsRW = useParamsRW()
  const { glob } = paramsRW
  const schedulingCaseId = glob.split('/')[1]
  const { data } = useQuery(READ_PATIENT, {
    variables: {
      id: patientId || patientRegistrationIntentId,
      schedulingCaseId: schedulingCaseId,
    },
    fetchPolicy: 'cache-first',
  })
  const [createCaseStatusUpdate, { loading: creating }] = useMutation(
    CREATE_CASE_STATUS_UPDATE_QUERY
  )
  const { closeSidePanel } = useSidepanel()
  const formMethods = useForm<FormProps>({
    defaultValues: {
      targetTime: '1',
      targetTimeOption: 'DAY',
      voiceMessageWithPatient: false,
      voiceMessageWithCaregiver: false,
      delayVisitScheduling: false,
    },
  })
  const options = (data?.patient?.patientRelatedPersonRelationships || []).map(
    ({ relatedPerson, relationshipType }) => {
      return {
        key: relatedPerson.id,
        id: relatedPerson.id,
        value: `${formatDisplayName(relatedPerson)} - ${
          relationshipTypeDisplay[relationshipType]
        }`,
        name: `${formatDisplayName(relatedPerson)} - ${
          relationshipTypeDisplay[relationshipType]
        }`,
      }
    }
  )
  const [
    delayVisitScheduling,
    voiceMessageWithCaregiver,
    voiceMessageWithPatient,
  ] = formMethods.watch([
    'delayVisitScheduling',
    'voiceMessageWithCaregiver',
    'voiceMessageWithPatient',
  ])
  const onSubmit = (formData: FormProps) => {
    if (formData.voiceMessageWithPatient) {
      createCaseStatusUpdate({
        variables: {
          input: {
            type: 'SCHEDULING',
            baseCaseId: data.schedulingCase.baseCaseId,
            text: 'Left voicemail with patient',
            icon: 'USER_CIRCLE',
          },
        },
        refetchQueries: ['GetActivityItems'],
      })
    }
    if (formData.voiceMessageWithCaregiver) {
      createCaseStatusUpdate({
        variables: {
          input: {
            type: 'SCHEDULING',
            baseCaseId: data.schedulingCase.baseCaseId,
            icon: 'USERS',
            text: `Left voicemail with caregiver: ${formData.caregiver}`,
          },
        },
        refetchQueries: ['GetActivityItems'],
      })
    }

    if (formData.delayVisitScheduling) {
      createCaseStatusUpdate({
        variables: {
          input: {
            type: 'SCHEDULING',
            baseCaseId: data.schedulingCase.baseCaseId,
            text: `Visit scheduling needed later in ${formData.targetTime} ${
              targetTimeUnitDisplay[formData.targetTimeOption]
            }`,
            icon: 'CALENDAR',
            targetTime: parseInt(formData.targetTime),
            targetTimeUnit: formData.targetTimeOption,
          },
        },
        refetchQueries: ['GetActivityItems'],
      })
    }
    closeSidePanel()
  }

  const showCaregiverOptions = voiceMessageWithCaregiver && data?.patient

  return (
    <SidepanelPage
      header="Status update"
      description="Check the option(s) that apply to your status update."
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Submit update',
          disabled:
            creating ||
            (!delayVisitScheduling &&
              !voiceMessageWithCaregiver &&
              !voiceMessageWithPatient),
          submitting: creating,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
        title="Status update"
        autoComplete="off"
      >
        <StackView>
          <Box horizontalPadding={100} verticalPadding={100}>
            <StackView space={25}>
              <Typography textStyle="title" fontWeight="medium">
                Interim status options
              </Typography>
              <Space space={75} />
              <Divider />
              <DataRow
                label={
                  <StackView space={50}>
                    <Label>Check the option(s) that apply to the task:</Label>
                  </StackView>
                }
                value={
                  <StackView space={75}>
                    <CheckboxField
                      name="voiceMessageWithPatient"
                      label="Left voicemail with patient"
                    />
                    <CheckboxField
                      name="voiceMessageWithCaregiver"
                      label="Left voicemail with caregiver"
                    />
                    {showCaregiverOptions && (
                      <>
                        <DropdownField
                          options={options}
                          name="caregiver"
                          validation={{
                            required: showCaregiverOptions,
                            shouldUnregister: true,
                          }}
                        />
                        <FieldError
                          name="caregiver"
                          defaultErrorMessage="This field is required"
                        />
                      </>
                    )}
                    <CheckboxField
                      name="delayVisitScheduling"
                      label="Visit scheduling needed later"
                    />
                    {delayVisitScheduling && (
                      <InputDropdown
                        name="targetTime"
                        options={[
                          { id: 'DAY', name: 'day(s)', value: 'DAY' },
                          { id: 'WEEK', name: 'week(s)', value: 'WEEK' },
                          { id: 'MONTH', name: 'month(s)', value: 'MONTH' },
                        ]}
                      />
                    )}
                  </StackView>
                }
              />
            </StackView>
          </Box>
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelSchedulingCaseStatusUpdate
