import { PatientDocumentType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const patientDocumentTypeDisplay: {
  [key in PatientDocumentType]: string
} = {
  SIGNED_PRACTICE_FORM: 'Signed practice form',
  COMPLETED_PATIENT_FORM: 'Completed patient form',
  COMPLETED_EXTERNAL_REQUEST: 'Completed external request',
  COMPLETED_SCREENING_TOOL: 'Completed screening tool',
  COMPLETED_PRACTICE_LETTER: 'Completed practice letter',
  CLINICAL_RESULT: 'Clinical result',
  MEDICAL_RECORD: 'Medical record',
  MEDICATION: 'Medication',
  PATIENT_OR_CAREGIVER_INFORMATION: 'Patient or caregiver information',
  PATIENT_ORDER: 'Patient order',
  OTHER: 'Other',
}

export const patientDocumentTypes =
  nameValueArrayFromObject<PatientDocumentType>(patientDocumentTypeDisplay)
