import type { CreatePracticeAdminDocumentInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import FileInput from 'src/components/atoms/FileInput/FileInput'
import InputField from 'src/components/atoms/InputField'
import PracticeAdminDocumentTypeSelectField from 'src/components/atoms/PracticeAdminDocumentTypeSelectField/PracticeAdminDocumentTypeSelectField'
import FormInputList, {
  Item,
} from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

const CREATE_PRACTICE_ADMIN_DOCUMENT_MUTATION = gql`
  mutation CreatePracticeAdminDocument(
    $input: CreatePracticeAdminDocumentInput!
  ) {
    createPracticeAdminDocument(input: $input) {
      id
    }
  }
`

export const practiceAdminInputListItems: (opts?: {
  type?: string
  patientId?: string
  isDocumentCase?: boolean
}) => Item[] = ({ type }) => [
  {
    name: 'type',
    label: 'Document sub-type',
    required: true,
    formInputComponent: PracticeAdminDocumentTypeSelectField,
  },
  {
    name: 'title',
    label: 'Document title',
    formInputComponent: InputField,
    required: type === 'OTHER',
  },
]

const CreatePracticeAdminDocument = () => {
  const { closeSidePanel } = useSidepanel()
  const [
    createPracticeAdminDocument,
    { loading: creatingPracticeAdminDocument },
  ] = useMutation(CREATE_PRACTICE_ADMIN_DOCUMENT_MUTATION, {
    onCompleted: () => {
      toast.success('Practice admin document created')
      closeSidePanel()
    },
    refetchQueries: ['PracticeAdminDocumentsQuery'],
  })

  const formMethods = useForm<CreatePracticeAdminDocumentInput>()
  const type = formMethods.watch('type')

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Upload document',
        submitting: creatingPracticeAdminDocument,
      }}
      formMethods={formMethods}
      onSubmit={async (value) => {
        const { binary: binaryInput, ...input } = value

        input['binary'] = {
          content: await base64EncodeFile(binaryInput[0]),
          contentType: binaryInput[0].type,
        }
        createPracticeAdminDocument({ variables: { input } })
      }}
    >
      <FormInputList
        items={[
          ...practiceAdminInputListItems({ type }),
          {
            name: 'binary',
            label: 'Document upload',
            required: true,
            formInputComponent: FileInput,
          },
        ]}
      />
    </SidepanelForm>
  )
}

export default CreatePracticeAdminDocument
