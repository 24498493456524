import clsx from 'clsx'

type LoadingSpinnerProps = {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  testId?: string
  color?: 'brand' | 'subtle'
}

const sizeMapping = {
  xs: 'h-base-size-icon-xs w-base-size-icon-xs',
  s: 'h-base-size-icon-s w-base-size-icon-s',
  m: 'h-base-size-icon-m w-base-size-icon-m',
  l: 'h-base-size-icon-l w-base-size-icon-l',
  xl: 'h-base-size-icon-xl w-base-size-icon-xl',
}

const colorMapping = {
  brand: 'border-base-color-fg-brand',
  subtle: 'border-base-color-fg-subtle',
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 's',
  testId,
  color = 'subtle',
}) => {
  return (
    <div className="flex items-center justify-center" data-testid={testId}>
      <div
        style={{ borderTopColor: 'transparent' }}
        className={clsx([
          sizeMapping[size],
          'animate-spin rounded-full border-2',
          colorMapping[color],
        ])}
      ></div>
    </div>
  )
}

export default LoadingSpinner
