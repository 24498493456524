import { assertUnreachable } from 'common/utils'
import { FaxJobStatus } from 'types/graphql'

import Badge from 'src/components/atoms/Badge/Badge'

interface OutboundFaxStatusProps {
  status: FaxJobStatus
  className?: string | undefined
}

const OutboundFaxStatus: React.FC<OutboundFaxStatusProps> = ({
  status,
  className,
}) => {
  switch (status) {
    case 'PENDING':
    case 'PROCESSING':
    case 'SUBMITTED_TO_DOCUMO':
      return (
        <Badge color="yellow" className={className} showDot>
          In Progress
        </Badge>
      )
    case 'FAILED':
      return (
        <Badge color="red" className={className} showDot>
          Failed
        </Badge>
      )
    case 'SUCCEEDED':
      return (
        <Badge color="green" className={className} showDot>
          Successful
        </Badge>
      )
    default:
      assertUnreachable(status)
  }
}

export default OutboundFaxStatus
