import { FaxDocumentInput } from 'types/graphql'

const SEPARATOR = ':'

export const faxDocumentToKey = (doc: FaxDocumentInput): string =>
  [doc.documentType, doc.documentId].join(SEPARATOR)

export const faxDocumentFromKey = (key: string): FaxDocumentInput => {
  const [type, id] = key.split(SEPARATOR)

  return {
    documentType: type as FaxDocumentInput['documentType'],
    documentId: id,
  }
}
