import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import some from 'lodash/some'
import type { SidepanelPatientDetailsQuery } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import DataDisplayList from 'src/components/molecules/DataDisplayList/DataDisplayList'
import { languageDisplay } from 'src/data/languages'
import {
  formatAddress,
  formatContactInformationPhoneNumbers,
} from 'src/lib/formatters'

import StackView from '../atoms/StackView/StackView'
import { useGetLexicalHtml } from '../atoms/WysiwygField/hooks/useGetLexicalHtml'

export const QUERY = gql`
  query SidepanelPatientDetailsQuery($appointmentId: String!) {
    appointment: appointment(id: $appointmentId) {
      id
      chiefComplaints
      visitComment
      patient {
        id
        mrn
        practiceComment
        patientRelatedPersonRelationships {
          id
          doesResideWith
          relationshipType
          guardianshipType
          relatedPerson {
            id
            namePrefix
            givenName
            middleName
            familyName
            nameSuffix
            contactInformation {
              id
              homeAddress {
                id
                line1
                line2
                city
                postalCode
                state
              }
            }
          }
        }
        primaryProvider {
          id
          givenName
          familyName
        }
        contactInformation {
          id
          emailAddress
          mobileNumber
          homeNumber
          workNumber
          primaryLanguage
          secondaryLanguage
          homeAddress {
            id
            line1
            line2
            city
            postalCode
            state
          }
        }
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  appointment,
}: CellSuccessProps<SidepanelPatientDetailsQuery>) => {
  const {
    patient: {
      mrn,
      practiceComment,
      primaryProvider,
      patientRelatedPersonRelationships,
      contactInformation,
    },
  } = appointment

  const residesWithCaregiver = some(
    patientRelatedPersonRelationships,
    (relationship) => relationship.doesResideWith
  )

  const practiceCommentHtml = useGetLexicalHtml(practiceComment)

  return (
    <StackView space={125}>
      <DataDisplayList
        title="Clinical details"
        subtitle="This section includes core clinical details regarding the patient."
        data={[
          {
            label: 'MRN',
            value: mrn,
          },
          {
            label: 'Primary practitioner',
            value: primaryProvider
              ? formatDisplayName(primaryProvider)
              : 'Unassigned',
          },
          {
            label: 'Practice comment',
            value: practiceCommentHtml,
          },
        ]}
      />

      {contactInformation && (
        <DataDisplayList
          title="Contact details"
          subtitle="This section includes details on how to contact the patient."
          data={[
            {
              label: 'Phone number(s)',
              value: formatContactInformationPhoneNumbers(contactInformation),
            },
            {
              label: 'Email address',
              value: contactInformation.emailAddress,
            },
            {
              label: 'Primary language',
              value: languageDisplay[contactInformation.primaryLanguage],
            },
            {
              label: 'Secondary language',
              value: languageDisplay[contactInformation.secondaryLanguage],
            },
          ]}
        />
      )}

      {residesWithCaregiver ? (
        <DataDisplayList
          title="Residence"
          subtitle="This section includes details on with whom and where the patient lives."
          data={[
            {
              label: 'Caregiver(s)',
              value: patientRelatedPersonRelationships
                .map(
                  (relationship) =>
                    `${formatDisplayName(relationship.relatedPerson)} (${
                      relationshipTypeDisplay[relationship.relationshipType]
                    })`
                )
                .join(', '),
            },
            {
              label: 'Address',
              value: formatAddress(
                patientRelatedPersonRelationships.find(
                  (relationship) => relationship.doesResideWith
                ).relatedPerson.contactInformation.homeAddress
              ),
            },
          ]}
        />
      ) : (
        <DataDisplayList
          title="Residence"
          subtitle="This section includes details on with whom and where the patient lives."
          data={[
            {
              label: 'Caregiver(s)',
              value: 'Does not live with caregiver',
            },
            {
              label: 'Address',
              value: formatAddress(contactInformation?.homeAddress),
            },
          ]}
        />
      )}
    </StackView>
  )
}
