import type { ChargeItemModifier } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const chargeItemModifierDisplay: {
  [key in ChargeItemModifier]: string
} = {
  MODIFIER_22: 'Increased procedural services',
  MODIFIER_24:
    'Unrelated E&M service by the same physician or other qualified health care professional during a postoperative period',
  MODIFIER_25:
    'Significant, separately identifiable E&M service by the same physician or other qualified health care professional on the same day of the procedure or other service',
  MODIFIER_26: 'Professional component',
  MODIFIER_32: 'Mandated services',
  MODIFIER_33: 'Preventative services',
  MODIFIER_50: 'Bilateral procedure',
  MODIFIER_51: 'Multiple procedures',
  MODIFIER_52: 'Reduced services',
  MODIFIER_53: 'Discontinued procedure',
  MODIFIER_55: 'Postoperative management only',
  MODIFIER_56: 'Preoperative management only',
  MODIFIER_57: 'Decision for surgery',
  MODIFIER_59: 'Distinct procedural service',
  MODIFIER_76:
    'Repeat procedure or service by same physician or other qualified health care professional',
  MODIFIER_77:
    'Repeat procedure or service by another physician or other qualified health care professional',
  MODIFIER_91: 'Repeat clinical diagnostic laboratory test',
  MODIFIER_92: 'Alternative laboratory platform testing',
  MODIFIER_93:
    'Synchronous telemedicine service rendered via telephone or other real-time interactive audio-only telecommunications system ',
  MODIFIER_95:
    'Synchronous telemedicine service rendered via a real-time interactive audio and video telecommunications system',
  MODIFIER_96: 'Habilitative services',
  MODIFIER_97: 'Rehabilitative services',
  MODIFIER_99: 'Multiple modifiers',
  MODIFIER_DA:
    'Oral health assessment by a licensed health professional other than a dentist',
  MODIFIER_E1: 'Upper left, eyelid',
  MODIFIER_E2: 'Lower left, eyelid',
  MODIFIER_E3: 'Upper right, eyelid',
  MODIFIER_E4: 'Lower right, eyelid',
  MODIFIER_EP:
    'Service provided as part of Medicaid Early and Periodic Screening, Diagnostic, and Treatment (EPSDT) program',
  MODIFIER_F1: 'Left hand, second digit',
  MODIFIER_F2: 'Left hand, third digit',
  MODIFIER_F3: 'Left hand, fourth digit',
  MODIFIER_F4: 'Left hand, fifth digit',
  MODIFIER_F5: 'Right hand, thumb',
  MODIFIER_F6: 'Right hand, second digit',
  MODIFIER_F7: 'Right hand, third digit',
  MODIFIER_F8: 'Right hand, fourth digit',
  MODIFIER_F9: 'Right hand, fifth digit',
  MODIFIER_FA: 'Left hand, thumb',
  MODIFIER_GA:
    'Waiver of liability statement issued as required by payer policy, individual case',
  MODIFIER_GC:
    'This service has been performed in part by a resident under the direction of a teaching physician',
  MODIFIER_GQ: 'Via asynchronous telecommunications system',
  MODIFIER_GT: 'Via interactive audio and video telecommunication systems',
  MODIFIER_GU:
    'Waiver of liability statement issued as required by payer policy, routine notice',
  MODIFIER_GX: 'Notice of liability issued, voluntary under payer policy',
  MODIFIER_JW: 'Drug amount discarded/not administered to any patient',
  MODIFIER_LT: 'Left side',
  MODIFIER_QW: 'Clinical Laboratory Improvement Amendments (CLIA)-waived tests',
  MODIFIER_RT: 'Right side',
  MODIFIER_SA:
    'Nurse practitioner rendering service in collaboration with a physician',
  MODIFIER_SC: 'Medically necessary service or supply',
  MODIFIER_SE: 'State and/or federally-funded programs/services',
  MODIFIER_SL: 'State-supplied vaccine',
  MODIFIER_T1: 'Left foot, second digit',
  MODIFIER_T2: 'Left foot, third digit',
  MODIFIER_T3: 'Left foot, fourth digit',
  MODIFIER_T4: 'Left foot, fifth digit',
  MODIFIER_T5: 'Right foot, great toe',
  MODIFIER_T6: 'Right foot, second digit',
  MODIFIER_T7: 'Right foot, third digit',
  MODIFIER_T8: 'Right foot, fourth digit',
  MODIFIER_T9: 'Right foot, fifth digit',
  MODIFIER_TA: 'Left foot, great toe',
  MODIFIER_U1: 'Medicaid level of care 1, as defined by each state',
  MODIFIER_U2: 'Medicaid level of care 2, as defined by each state',
  MODIFIER_U3: 'Medicaid level of care 3, as defined by each state',
  MODIFIER_U4: 'Medicaid level of care 4, as defined by each state',
  MODIFIER_U5: 'Medicaid level of care 5, as defined by each state',
  MODIFIER_U6: 'Medicaid level of care 6, as defined by each state',
  MODIFIER_U7: 'Medicaid level of care 7, as defined by each state',
  MODIFIER_U8: 'Medicaid level of care 8, as defined by each state',
  MODIFIER_U9: 'Medicaid level of care 9, as defined by each state',
  MODIFIER_UA: 'Medicaid level of care 10, as defined by each state',
  MODIFIER_UB: 'Medicaid level of care 11, as defined by each state',
  MODIFIER_UC: 'Medicaid level of care 12, as defined by each state',
  MODIFIER_UD: 'Medicaid level of care 13, as defined by each state',
}

export const chargeItemModifiers = nameValueArrayFromObject<ChargeItemModifier>(
  chargeItemModifierDisplay
)
