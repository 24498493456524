import { useState } from 'react'

export type Confirm = () => Promise<{ confirmed: boolean }>

export interface ConfirmationState {
  isConfirming: boolean
  confirm: () => void
  cancel: () => void
}

const initialState: ConfirmationState = {
  isConfirming: false,
  confirm: () => {},
  cancel: () => {},
}

export const useConfirmation = (args?: {
  onConfirm?: () => void
  onCancel?: () => void
}): [ConfirmationState, Confirm, () => void, () => void] => {
  const updatedInitialState = {
    ...initialState,
    confirm: args?.onConfirm ?? initialState.confirm,
    cancel: args?.onCancel ?? initialState.cancel,
  }

  const [state, setState] = useState(updatedInitialState)

  const waitForConfirmation = () => {
    return new Promise<{ confirmed: boolean }>((resolve) => {
      setState({
        isConfirming: true,
        confirm: () => confirm().then((res) => resolve(res)),
        cancel: () => cancel().then((res) => resolve(res)),
      })
    })
  }

  const confirm = () => {
    return new Promise<{ confirmed: boolean }>((resolve) => {
      updatedInitialState.confirm()
      setState(updatedInitialState)
      resolve({ confirmed: true })
    })
  }
  const cancel = () => {
    return new Promise<{ confirmed: boolean }>((resolve) => {
      updatedInitialState.cancel()
      setState(updatedInitialState)
      resolve({ confirmed: false })
    })
  }

  return [state, waitForConfirmation, confirm, cancel]
}
