import { useParams } from 'react-router-dom'

import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import CompleteScreeningOrderSidepanelForm from 'src/components/Order/CompleteScreeningOrderSidepanelForm/CompleteScreeningOrderSidepanelForm'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import { usePatientOrdersWithObservationsQuery } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

const SidepanelCompleteScreeningOrderEdit = () => {
  const { patientId, isPatientChart } = useIsPatientChart()
  const { encounterId, orderId } = useParams()

  const { ordersWithObservations } =
    usePatientOrdersWithObservationsQuery(patientId)
  const order = ordersWithObservations?.find((order) => order.id === orderId)
  if (!isPatientChart || !order) {
    return <SidepanelNotAvailable />
  }

  const orderIsCompleted = order.status === 'COMPLETED'
  return (
    <SidepanelPage
      testId="sidepanel-screening-order-complete"
      header={`${order.codeDisplay}`}
      description={`${
        orderIsCompleted ? 'Update' : 'Input'
      } results for this screening`}
    >
      <CompleteScreeningOrderSidepanelForm
        patientId={patientId}
        encounterId={encounterId === 'undefined' ? undefined : encounterId}
        order={order}
      />
    </SidepanelPage>
  )
}

export default SidepanelCompleteScreeningOrderEdit
