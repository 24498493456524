import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import compact from 'lodash/compact'
import startCase from 'lodash/startCase'
import { useParams } from 'react-router-dom'
import { FindDiagnosticReport } from 'types/graphql'

import {
  useLocation,
  useParams as useRedwoodParams,
  navigate,
} from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import AttachmentDisplay, {
  ATTACHMENT_FRAGMENT,
} from 'src/components/atoms/AttachmentDisplay/AttachmentDisplay'
import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView'
import EFaxIcon from 'src/components/icons/EFaxIcon/EFaxIcon'
import { DataDisplayList } from 'src/components/molecules/DataDisplayList'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { sidepanelRoute } from 'src/lib/routes'

export const QUERY = gql`
  query FindDiagnosticReport($id: String!) {
    diagnosticReport(id: $id) {
      id
      patientId
      healthGorillaId
      issuedAt
      updatedAt
      performer
      encounterId
      name
      conclusion
      resultsInterpreter {
        id
        givenName
        familyName
      }
      category
      presentedForm {
        ...AttachmentFragment
      }
    }
  }
  ${ATTACHMENT_FRAGMENT}
`

const SidepanelPatientDiagnosticReport = () => {
  const { diagnosticReportId } = useParams()
  const { data, loading, error } = useQuery<FindDiagnosticReport>(QUERY, {
    variables: { id: diagnosticReportId },
  })
  const params = useRedwoodParams()
  const location = useLocation()

  if (loading || error) return null
  const diagnosticReport = data.diagnosticReport

  return (
    <SidepanelPage
      header={`Clinical Result: ${
        diagnosticReport.category ? startCase(diagnosticReport.category) : 'Lab'
      }`}
      testId="sidepanel-patient-diagnostic-report"
      subHeader={diagnosticReport.name}
      description={`Last modified on ${format(
        new Date(diagnosticReport.updatedAt),
        'PP'
      )}`}
    >
      <StackView className="grow p-8" space={75}>
        <StackView direction="row" space={50}>
          <Button
            buttonStyle="ghost"
            icon={EFaxIcon}
            text="E-fax"
            onClick={() =>
              navigate(
                sidepanelRoute(
                  {
                    route: '/send-fax',
                    documents: JSON.stringify([
                      {
                        documentId: diagnosticReportId,
                        documentType: 'DIAGNOSTIC_REPORT',
                      },
                    ]),
                  },
                  location,
                  params
                )
              )
            }
          />
        </StackView>
        <DataDisplayList
          data={compact([
            diagnosticReport.performer && {
              label: 'Performed by',
              value:
                diagnosticReport.performer +
                (diagnosticReport.healthGorillaId
                  ? ` (via Health Gorilla)`
                  : ''),
            },
            {
              label: 'Issued on',
              value: format(new Date(diagnosticReport.issuedAt), 'PP'),
            },
            {
              label: 'Interpreted by',
              value: formatDisplayName(diagnosticReport.resultsInterpreter),
            },
            {
              label: 'Conclusion',
              value: diagnosticReport.conclusion,
            },
          ])}
        />
        {diagnosticReport.presentedForm && (
          <AttachmentDisplay attachment={diagnosticReport.presentedForm} />
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPatientDiagnosticReport
