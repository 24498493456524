import type { TimeQualifier } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const insuranceTimeQualifiersDisplay: {
  [key in TimeQualifier]: string
} = {
  DAY: 'Day',
  SERVICE_YEAR: 'Service Year',
  CALENDAR_YEAR: 'Calendar Year',
  YEAR_TO_DATE: 'Year to Date',
  CONTRACT: 'Contract',
  EPISODE: 'Episode',
  VISIT: 'Visit',
  REMAINING: 'Remaining',
  LIFETIME: 'Lifetime',
  LIFETIME_REMAINING: 'Lifetime Remaining',
  ADMISSION: 'Admission',
}

export const insuranceTimeQualifiers = nameValueArrayFromObject<TimeQualifier>(
  insuranceTimeQualifiersDisplay
)
