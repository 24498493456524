import { documentTypes } from 'common/data/documentTypes'
import compact from 'lodash/compact'

import { patientDocumentTypes } from 'src/data/patientDocumentTypes'

import { DropdownField, DropdownFieldProps } from '../Dropdown/Dropdown'

const clinicDocumentTypeOptions = documentTypes.map(({ name, value }) => ({
  value,
  name,
}))

const patientDocumentTypeOptions = patientDocumentTypes.map(
  ({ name, value }) => ({
    value,
    name,
  })
)

export type DocumentTypeSelectFieldProps = DropdownFieldProps & {
  clinic?: boolean
  patient?: boolean
}

const sectionHeader = (name: string) => ({
  value: name.toLowerCase().replace(' ', '-'),
  name,
  disabled: true,
})

export const DocumentTypeSelectField = ({
  clinic = false,
  patient = false,
  ...rest
}: DocumentTypeSelectFieldProps) => {
  const breakOptionsIntoSections = clinic && patient

  const options = compact([
    breakOptionsIntoSections ? sectionHeader('Patient Document') : null,
    ...(patient ? patientDocumentTypeOptions : []),

    breakOptionsIntoSections ? sectionHeader('Clinic Resource') : null,
    ...(clinic ? clinicDocumentTypeOptions : []),
  ])

  return <DropdownField {...rest} options={options} />
}

export default DocumentTypeSelectField
