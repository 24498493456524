import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'

const SidepanelNotAvailable = () => {
  return (
    <SidepanelPage header="Whoops, this is not available">
      <StackView className="p-6">
        <Typography>
          It looks like you are trying to access content that is not available.
        </Typography>
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelNotAvailable
