import { formatDisplayName } from 'common/utils'
import {
  CreateGuarantorComment,
  CreateGuarantorCommentVariables,
  GuarantorIdInput,
  ListGuarantorComments,
  ListGuarantorCommentsVariables,
} from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'

import Button from '../atoms/Button/Button'
import LoadingSpinner from '../atoms/LoadingSpinner/LoadingSpinner'
import StackView from '../atoms/StackView/StackView'
import TextAreaField from '../atoms/TextAreaField/TextAreaField'
import { ChatMessage } from '../molecules/ChatMessage/ChatMessage'
import FeatureFlagged from '../molecules/FeatureFlagged/FeatureFlagged'
import InfiniteScroll from '../molecules/InfiniteScroll/InfiniteScroll'
import { SectionHeader } from '../molecules/SectionHeader/SectionHeader'

const QUERY = gql`
  query ListGuarantorComments(
    $guarantor: GuarantorIdInput!
    $pagination: CursorPaginationInput
  ) {
    guarantorComments(guarantor: $guarantor, pagination: $pagination) {
      pageInfo {
        endCursor
        hasNextPage
      }
      comments {
        id
        text
        createdByUser {
          id
          givenName
          familyName
        }
        createdAt
      }
    }
  }
`

const CREATE_COMMENT = gql`
  mutation CreateGuarantorComment(
    $text: String!
    $guarantor: GuarantorIdInput!
  ) {
    createGuarantorComment(text: $text, guarantor: $guarantor) {
      id
      text
      createdByUser {
        id
        givenName
        familyName
      }
      createdAt
    }
  }
`

const GuarantorCommentSectionHelper = ({
  guarantor,
}: {
  guarantor: GuarantorIdInput
}) => {
  const { data, fetchMore, loading } = useQuery<
    ListGuarantorComments,
    ListGuarantorCommentsVariables
  >(QUERY, {
    variables: {
      guarantor,
    },
  })

  const [createComment, { loading: creatingComment }] = useMutation<
    CreateGuarantorComment,
    CreateGuarantorCommentVariables
  >(CREATE_COMMENT)

  const comments = data?.guarantorComments?.comments ?? []

  const formMethods = useForm({
    defaultValues: {
      text: '',
    },
  })

  const onSubmit = (data) => {
    void createComment({
      variables: {
        text: data.text,
        guarantor,
      },
      refetchQueries: ['ListGuarantorComments'],
    })

    formMethods.reset()
  }

  return (
    <StackView gap={100}>
      <SectionHeader title="Comments" size="s" />

      <StackView
        id="guarantor-comment-list"
        className="max-h-core-space-1200 flex-col-reverse overflow-auto"
        gap={50}
      >
        {loading && !data ? <LoadingSpinner /> : null}
        {comments.map((comment) => {
          return (
            <ChatMessage
              testId="guarantor-comment"
              key={comment.id}
              text={comment.text}
              senderName={formatDisplayName(comment.createdByUser)}
              createdAt={new Date(comment.createdAt)}
              color="white"
            />
          )
        })}
        <InfiniteScroll
          listId="guarantor-comment-list"
          fetchMore={() =>
            fetchMore({
              variables: {
                pagination: {
                  after: data?.guarantorComments?.pageInfo?.endCursor,
                },
              },
              updateQuery: (existing, { fetchMoreResult }) => {
                return {
                  ...existing,
                  guarantorComments: {
                    ...fetchMoreResult.guarantorComments,
                    comments: [
                      ...existing.guarantorComments.comments,
                      ...fetchMoreResult.guarantorComments.comments,
                    ],
                  },
                }
              },
            })
          }
          hasNextPage={data?.guarantorComments?.pageInfo?.hasNextPage}
          loading={loading}
        />
      </StackView>

      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <div className="relative">
          <TextAreaField
            testId="guarantor-comment-input"
            name="text"
            placeholder="Leave a comment..."
            required
            className="pr-core-space-600"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.shiftKey === false) {
                e.preventDefault()
                onSubmit(formMethods.getValues())
              }
            }}
          />

          <Button
            type="submit"
            text="Comment"
            disabled={!formMethods.formState.isValid || creatingComment}
            loading={creatingComment}
            className="absolute bottom-core-space-50 right-core-space-50"
          />
        </div>
      </Form>
    </StackView>
  )
}

export const GuarantorCommentSection = (props) => (
  <FeatureFlagged flagName="GUARANTOR_COMMENTS">
    <GuarantorCommentSectionHelper {...props} />
  </FeatureFlagged>
)
