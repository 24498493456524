import clsx from 'clsx'
import { assertUnreachable } from 'common/utils'

export const STATUS_INDICATOR_COLORS = [
  'light-gray',
  'dark-gray',
  'green',
  'teal',
  'cyan',
  'blue',
  'purple',
  'violet',
  'rose',
  'red',
  'orange',
  'yellow',
] as const

export type StatusIndicatorColor = (typeof STATUS_INDICATOR_COLORS)[number]

export interface StatusIndicatorProps {
  color: StatusIndicatorColor
  testId?: string
}

const colorToClassName = (color: StatusIndicatorColor): string => {
  switch (color) {
    case 'light-gray':
      return 'bg-comp-badge-light-gray-fg'
    case 'dark-gray':
      return 'bg-comp-badge-dark-gray-fg'
    case 'green':
      return 'bg-comp-badge-green-fg'
    case 'teal':
      return 'bg-comp-badge-teal-fg'
    case 'cyan':
      return 'bg-comp-badge-cyan-fg'
    case 'blue':
      return 'bg-comp-badge-blue-fg'
    case 'purple':
      return 'bg-comp-badge-purple-fg'
    case 'violet':
      return 'bg-comp-badge-violet-fg'
    case 'rose':
      return 'bg-comp-badge-rose-fg'
    case 'red':
      return 'bg-comp-badge-red-fg'
    case 'orange':
      return 'bg-comp-badge-orange-fg'
    case 'yellow':
      return 'bg-comp-badge-yellow-fg'
    default:
      assertUnreachable(color)
  }
}

export const StatusIndicator = ({ color, testId }: StatusIndicatorProps) => {
  return (
    <div
      data-testid={testId}
      className={clsx([
        colorToClassName(color),
        'h-core-size-37 w-core-size-37 rounded-full',
      ])}
    />
  )
}
