import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { GetPatientDemographicsForPrinting } from 'types/graphql'

import { guardianshipTypeDisplay } from 'src/data/guardianshipTypes'
import { languageDisplay } from 'src/data/languages'
import { maritalStatusDisplay } from 'src/data/maritalStatuses'
import { formatDateDisplay } from 'src/lib/formatters'

import { DemographicsSection } from '../types'

import { formatAddress } from './formatAddress'
import { phoneNumberLine } from './phoneNumberLine'

export const createCaregiverSection = ({
  header,
  relationship,
}: {
  header: string
  relationship: GetPatientDemographicsForPrinting['patient']['patientRelatedPersonRelationships'][number]
}): DemographicsSection => {
  const { relatedPerson } = relationship

  return {
    header,
    subHeader: formatDisplayName(relatedPerson),
    rows: [
      {
        title: 'Caregiver relationship',
        column: {
          left: [
            [
              'Guardianship status',
              guardianshipTypeDisplay[relationship.guardianshipType],
            ],
            ['Guarantor status', relationship.isGuarantor ? 'Yes' : 'No'],
          ],
          right: [
            [
              'Relationship to patient',
              relationshipTypeDisplay[relationship.relationshipType],
            ],
          ],
        },
      },
      {
        title: 'Contact information',
        column: {
          left: [
            phoneNumberLine([
              'Mobile number',
              relatedPerson.contactInformation.mobileNumber,
            ]),
            phoneNumberLine([
              'Home number',
              relatedPerson.contactInformation.homeNumber,
            ]),
            ['Email address', relatedPerson.contactInformation.emailAddress],
            phoneNumberLine([
              'Fax number',
              relatedPerson.contactInformation.faxNumber,
            ]),
            [
              'Primary language',
              languageDisplay[relatedPerson.contactInformation.primaryLanguage],
            ],
          ],
          right: [
            [
              'Secondary language',
              languageDisplay[
                relatedPerson.contactInformation.secondaryLanguage
              ],
            ],
            [
              'Home address',
              formatAddress(relatedPerson.contactInformation.homeAddress),
            ],
            [
              'Mailing address',
              formatAddress(relatedPerson.contactInformation.mailingAddress),
            ],
          ],
        },
      },
      {
        title: 'Core demographics',
        column: {
          left: [
            ['Prefix', relatedPerson.namePrefix],
            ['First name', relatedPerson.givenName],
            ['Middle name', relatedPerson.middleName],
            ['Last name', relatedPerson.familyName],
            ['Suffix', relatedPerson.nameSuffix],
          ],
          right: [
            ['Preferred name', relatedPerson.preferredName],
            ['Date of birth', formatDateDisplay(relatedPerson.birthDate)],
            [
              'Marital status',
              maritalStatusDisplay[relatedPerson.maritalStatus],
            ],
            ['Occupation', relatedPerson.occupation],
            ['Current employer', relatedPerson.employer],
          ],
        },
      },
    ],
  }
}
