import { ConditionClinicalStatus } from 'types/graphql'

import { BadgeColor } from 'src/components/atoms/Badge/Badge'

import { nameValueArrayFromObject } from './utils'

export const conditionStatusDisplay: {
  [key in ConditionClinicalStatus]: string
} = {
  active: 'Active',
  inactive: 'Historical',
  resolved: 'Resolved',
}

export const conditionStatusBadgeColor: {
  [key in ConditionClinicalStatus]: BadgeColor
} = {
  active: 'yellow',
  inactive: 'blue',
  resolved: 'dark-gray',
}

export const conditionStatuses =
  nameValueArrayFromObject<ConditionClinicalStatus>(conditionStatusDisplay)
