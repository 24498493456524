import { formatDate } from '@mobiscroll/react'
import { differenceInMonths, intervalToDuration, parse } from 'date-fns'
import humanize from 'humanize-string'
import { parsePhoneNumber } from 'libphonenumber-js/min'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import { Address } from 'types/graphql'

const MAX_STRING_LENGTH = 150
export const DATE_FIELD_VALUE_FORMAT = 'YYYY-MM-DD'

export const formatEnum = (values: string | string[] | null | undefined) => {
  let output = ''

  if (Array.isArray(values)) {
    const humanizedValues = values.map((value) => humanize(value))
    output = humanizedValues.join(', ')
  } else if (typeof values === 'string') {
    output = humanize(values)
  }

  return output
}

export const truncate = (value: string | number) => {
  let output = value?.toString() ?? ''

  if (output.length > MAX_STRING_LENGTH) {
    output = output.substring(0, MAX_STRING_LENGTH) + '...'
  }

  return output
}

export const timeTag = (dateTime?: string) => {
  let output: string | JSX.Element = ''

  if (dateTime) {
    output = (
      <time dateTime={dateTime} title={dateTime}>
        {new Date(dateTime).toUTCString()}
      </time>
    )
  }

  return output
}

export const checkboxInputTag = (checked: boolean) => {
  return <input type="checkbox" checked={checked} disabled />
}

export const formatInitials = ({
  givenName = '',
  familyName = '',
}: {
  givenName?: string
  familyName?: string
}) => [givenName[0], familyName[0]].filter(Boolean).join('').toUpperCase()

export const formatPhoneNumber = (phoneString: string | undefined) => {
  if (isEmpty(phoneString)) {
    return null
  }

  return parsePhoneNumber(phoneString, 'US').formatNational()
}

export const formatContactInformationPhoneNumbers = (contactInformation: {
  homeNumber?: string
  mobileNumber: string
  workNumber?: string
}) => {
  if (isEmpty(contactInformation)) return ''

  const res = []
  if (contactInformation.homeNumber) {
    res.push(`H ${formatPhoneNumber(contactInformation.homeNumber)}`)
  }
  if (contactInformation.mobileNumber) {
    res.push(`M ${formatPhoneNumber(contactInformation.mobileNumber)}`)
  }
  if (contactInformation.workNumber) {
    res.push(`W ${formatPhoneNumber(contactInformation.workNumber)}`)
  }
  return res.join(' \u2022 ')
}

export enum AddressJoinOption {
  COMMA,
  NEWLINE,
}

export const formatAddress = (
  address:
    | Partial<
        Pick<
          Address,
          'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country'
        >
      >
    | undefined,
  joinWith: AddressJoinOption = AddressJoinOption.NEWLINE
) => {
  if (isEmpty(address)) return ''

  return compact([
    address.line1,
    address.line2,
    address.city,
    `${address.state} ${address.postalCode}`,
    address.country,
  ]).join(
    {
      [AddressJoinOption.COMMA]: ', ',
      [AddressJoinOption.NEWLINE]: '\n',
    }[joinWith]
  )
}

export const formatAddressLine1 = (address: Partial<Address> | undefined) => {
  if (isEmpty(address)) return ''

  return compact([address.line1, address.line2]).join(', ')
}

export const formatAddressLine2 = (address: Partial<Address> | undefined) => {
  if (isEmpty(address)) return ''

  return compact([
    `${address.city}, ${address.state}`,
    address.postalCode,
  ]).join(' ')
}

export const calculateAge = (birthDateStr: string) => {
  if (!birthDateStr) return
  const start = new Date(birthDateStr)
  start.setUTCHours(0, 0, 0, 0)
  const end = new Date()
  end.setUTCHours(0, 0, 0, 0)

  return intervalToDuration({ start, end })
}

export const calculateMonthsSinceBirth = (
  birthDateStr: string,
  endDate: string
) => {
  const start = new Date(birthDateStr)
  start.setUTCHours(0, 0, 0, 0)
  const end = new Date(endDate)
  end.setUTCHours(0, 0, 0, 0)

  const { days } = intervalToDuration({ start, end })
  const diffMonths = differenceInMonths(end, start)
  return diffMonths + days / 31
}

export const formatAge = (birthDateStr: string) => {
  const { years, months, days } = calculateAge(birthDateStr)

  if (years > 4) {
    return `${years} years`
  } else if (years) {
    return `${years} year${years > 1 ? 's' : ''}${
      months ? `, ${months} month${months > 1 ? 's' : ''}` : ''
    }`
  } else if (months) {
    return `${months} month${months > 1 ? 's' : ''}${
      days ? `, ${days} day${days > 1 ? 's' : ''}` : ''
    }`
  } else {
    return `${days} day${days > 1 ? 's' : ''}`
  }
}

export const formatDateFieldValue = (value: string | Date | undefined) => {
  if (!value) {
    return ''
  }

  if (typeof value == 'string') {
    const match = value.match(
      /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
    )
    return isEmpty(match) ? '' : match[0]
  } else {
    return formatDate(DATE_FIELD_VALUE_FORMAT, value)
  }
}

export const formatDateDisplay = (dateString: string | undefined) => {
  if (!dateString) return ''
  const match = dateString?.match(
    /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
  )

  if (isEmpty(match)) return ''

  const [date] = match
  if (date) {
    const [year, month, day] = date.split('-')
    return `${month}/${day}/${year}`
  }
}

export const dateTimeWithoutTimezone = (
  dateString: string | undefined
): Date => {
  if (!dateString) return

  const match = dateString.match(
    /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))|(\d{2}:\d{2})/g
  )

  if (isEmpty(match)) return

  const [date, time] = match
  return parse(`${date} ${time}`, 'yyyy-MM-dd HH:mm', new Date())
}

export const toDecimal = (value: number | string, precision = 2) => {
  if (!Number(value)) return null
  return Number(Number(value).toFixed(precision)) // Outside Number() allows us to only show only as many zeros as needed
}

export const emptyPlaceholder = '\u2014'

export const formatTime = (secondsToFormat: number) => {
  const minutes = Math.floor(secondsToFormat / 60)
  const seconds = Math.floor(secondsToFormat % 60)

  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`
}

export const formatNumberAsOrdinal = (number: number) => {
  const j = number % 10,
    k = number % 100
  if (j === 1 && k !== 11) {
    return number + 'st'
  }
  if (j === 2 && k !== 12) {
    return number + 'nd'
  }
  if (j === 3 && k !== 13) {
    return number + 'rd'
  }
  return number + 'th'
}
