import { useEffect, useState, useRef } from 'react'

import axios from 'axios'
import UTIF from 'utif'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'

export const TIFFViewer = ({ src }: { src: string }) => {
  const [loading, setLoading] = useState(false)

  const canvasRef = useRef(null)

  useEffect(() => {
    async function displayTIFF(src: string) {
      setLoading(true)
      const response = await axios.get(src, {
        responseType: 'arraybuffer',
      })

      const ifds = UTIF.decode(response.data)
      ifds.forEach((ifd) => {
        UTIF.decodeImage(response.data, ifd)
        const rgba = UTIF.toRGBA8(ifd)
        const canvas = document.createElement('canvas')
        canvas.classList.add('max-w-full', 'w-fit', 'object-contain')
        canvas.width = ifd.width
        canvas.height = ifd.height
        const ctx = canvas.getContext('2d')
        const img = ctx.createImageData(ifd.width, ifd.height)
        img.data.set(rgba)
        ctx.putImageData(img, 0, 0)
        canvasRef.current.appendChild(canvas)
      })

      setLoading(false)
    }
    displayTIFF(src)
  }, [src])

  return (
    <StackView direction="col" gap={50}>
      {loading && <LoadingSpinner size="l" />}

      <div ref={canvasRef} />
    </StackView>
  )
}
