import { useMemo } from 'react'

import { match } from 'ts-pattern'
import { Role } from 'types/graphql'

import { createDbAuthClient, createAuth } from '@redwoodjs/auth-dbauth-web'

import { CurrentUser } from './lib/auth'
import { appType } from './utils/appType'

const emrDbAuthClient = createDbAuthClient({
  fetchConfig: { credentials: 'include' },
})

const EmrAuth = createAuth(emrDbAuthClient)

const patientPortalAuthClient = createDbAuthClient({
  dbAuthUrl: `${process.env.REDWOOD_API_URL}/patientPortalAuth`,
  fetchConfig: { credentials: 'include' },
})

const PatientPortalAuth = createAuth(patientPortalAuthClient)

export const AuthProvider = match(appType)
  .with('emr', () => EmrAuth.AuthProvider)
  .with('patientPortal', () => PatientPortalAuth.AuthProvider)
  .exhaustive()

export const useAuth = match(appType)
  .with('emr', () => EmrAuth.useAuth)
  .with('patientPortal', () => PatientPortalAuth.useAuth)
  .exhaustive()

export const useEmrAuth = () => {
  const authResult = useAuth()

  const emrAuth = useMemo(() => {
    const currentUser = authResult.currentUser as CurrentUser

    const emrUser =
      currentUser?.application === 'emr' ? currentUser.emrUser : null

    const hasRole = (roleArg: Role | Role[]) => {
      if (!emrUser) return false

      const roles = typeof roleArg === 'string' ? [roleArg] : roleArg

      return roles.every((role) => emrUser.roles.includes(role))
    }

    const getCurrentUser = () =>
      authResult.getCurrentUser().then((user: CurrentUser) => {
        if (user?.application !== 'emr') return null

        return user.emrUser
      })

    return {
      ...authResult,
      hasRole,
      currentUser: emrUser,
      getCurrentUser,
    }
  }, [authResult])

  return emrAuth
}

const patientPortalHasRole = () => false

export const usePatientPortalAuth = () => {
  const authResult = useAuth()

  const patientPortalAuth = useMemo(() => {
    const currentUser = authResult.currentUser as CurrentUser

    const portalUser =
      currentUser?.application === 'patientPortal'
        ? currentUser.portalUser
        : null

    const getCurrentUser = () =>
      authResult.getCurrentUser().then((user: CurrentUser) => {
        if (user?.application !== 'patientPortal') return null

        return user.portalUser
      })

    return {
      ...authResult,
      hasRole: patientPortalHasRole,
      currentUser: portalUser,
      getCurrentUser,
    }
  }, [authResult])

  return patientPortalAuth
}
