import clsx from 'clsx'

const ProgressBar = ({
  percent,
  bg = 'bg-base-color-bg-muted',
  color = 'bg-base-color-fg-brand',
  testId,
  className,
}: {
  percent: number
  bg?: string
  color?: string
  testId?: string
  className?: string
}) => {
  return (
    <div
      className={clsx(['h-core-size-37', 'w-full', bg, className])}
      data-testid={testId}
    >
      <div
        data-testid={`${testId}-foreground`}
        className={clsx([
          'h-core-size-37',
          color,
          'transition-all duration-1000',
        ])}
        style={{ width: `${percent}%` }}
      ></div>
    </div>
  )
}

export default ProgressBar
