import type { Language } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const languageDisplay: {
  [key in Language]: string
} = {
  EN_US: 'English (United States)',
  ES: 'Spanish',
  AR: 'Arabic',
  BN: 'Bengali',
  CS: 'Czech',
  DA: 'Danish',
  DE: 'German',
  DE_AT: 'German (Austria)',
  DE_CH: 'German (Switzerland)',
  DE_DE: 'German (Germany)',
  EL: 'Greek',
  EN: 'English',
  EN_AU: 'English (Australia)',
  EN_CA: 'English (Canada)',
  EN_GB: 'English (Great Britain)',
  EN_IN: 'English (India)',
  EN_NZ: 'English (New Zealand)',
  EN_SG: 'English (Singapore)',
  ES_AR: 'Spanish (Argentina)',
  ES_ES: 'Spanish (Spain)',
  ES_UY: 'Spanish (Uruguay)',
  FI: 'Finnish',
  FR: 'French',
  FR_BE: 'French (Belgium)',
  FR_CH: 'French (Switzerland)',
  FR_FR: 'French (France)',
  FY: 'Frysian',
  FY_NL: 'Frysian (Netherlands)',
  HI: 'Hindi',
  HR: 'Croatian',
  IT: 'Italian',
  IT_CH: 'Italian (Switzerland)',
  IT_IT: 'Italian (Italy)',
  JA: 'Japanese',
  KO: 'Korean',
  NL: 'Dutch',
  NL_BE: 'Dutch (Belgium)',
  NL_NL: 'Dutch (Netherlands)',
  NO: 'Norwegian',
  NO_NO: 'Norwegian (Norway)',
  PA: 'Punjabi',
  PL: 'Polish',
  PT: 'Portuguese',
  PT_BR: 'Portuguese (Brazil)',
  RU: 'Russian',
  RU_RU: 'Russian (Russia)',
  SR: 'Serbian',
  SR_RS: 'Serbian (Serbia)',
  SV: 'Swedish',
  SV_SE: 'Swedish (Sweden)',
  TE: 'Telegu',
  ZH: 'Chinese',
  ZH_CN: 'Chinese (China)',
  ZH_HK: 'Chinese (Hong Kong)',
  ZH_SG: 'Chinese (Singapore)',
  ZH_TW: 'Chinese (Taiwan)',
}

export const languages = nameValueArrayFromObject<Language>(languageDisplay)
