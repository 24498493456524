import { ReactElement } from 'react'

import { Role } from 'types/graphql'

import { useEmrAuth } from 'src/auth'
import SidepanelForbidden from 'src/pages/Sidepanel/SidepanelForbidden/SidepanelForbidden'

type PrivateSidepanelRoute = { roles: string[]; children: ReactElement }

/**
 * This is a wrapper for sidepanel views that require certain roles
 */
const PrivateSidepanelRoute = ({ roles, children }: PrivateSidepanelRoute) => {
  const { currentUser } = useEmrAuth()

  const hasRole = () => {
    return roles.some((r: Role) => currentUser.roles.includes(r))
  }

  if (hasRole()) {
    return children
  }
  return <SidepanelForbidden />
}

export default PrivateSidepanelRoute
