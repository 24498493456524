import { useFormContext } from '@redwoodjs/forms'

import InputField from 'src/components/atoms/InputField/InputField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'

interface ChangePasswordFormInputListProps {
  hideCurrentPassword: boolean
  name: string
  disabled?: boolean
}

const ChangePasswordFormInputList = ({
  hideCurrentPassword,
  name = '',
  disabled = false,
}: ChangePasswordFormInputListProps) => {
  const formContext = useFormContext()
  return (
    <FormInputList
      testId="change-password-form-input-list"
      items={[
        {
          name: 'currentPassword',
          label: 'Current password',
          required: true,
          formInputComponent: InputField,
          inputProps: {
            type: 'password',
            disabled: disabled,
            testId: 'currentPassword-field',
          },
          hide: hideCurrentPassword,
        },
        {
          name: name,
          label: 'New password',
          required: true,
          formInputComponent: InputField,
          inputProps: {
            type: 'password',
            disabled: disabled,
            validation: {
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters long',
              },
              validate: (value) =>
                value.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/g)
                  ? true
                  : 'Password must be at least 8 characters long and must contain at least 1 uppercase character, 1 lowercase character, and 1 number',
            },
            testId: `${name}-field`,
          },
          message:
            'Password must be at least 8 characters long and must contain at least 1 uppercase character, 1 lowercase character, and 1 number',
        },
        {
          name: 'confirmPassword',
          label: 'Confirm password',
          required: true,
          formInputComponent: InputField,
          inputProps: {
            type: 'password',
            disabled: disabled,
            validation: {
              required: 'You must confirm your password',
              validate: (value) => {
                return (
                  value === formContext.getValues(name) ||
                  'Passwords must match'
                )
              },
            },
            testId: 'confirmPassword-field',
          },
        },
      ]}
    />
  )
}

export default ChangePasswordFormInputList
