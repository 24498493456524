import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import type { AppointmentCaregiverDetailsQuery } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import DataDisplayList from 'src/components/molecules/DataDisplayList/DataDisplayList'
import { guardianshipTypeDisplay } from 'src/data/guardianshipTypes'
import { languageDisplay } from 'src/data/languages'
import { formatContactInformationPhoneNumbers } from 'src/lib/formatters'

import StackView from '../atoms/StackView/StackView'

export const QUERY = gql`
  query AppointmentCaregiverDetailsQuery($appointmentId: String!) {
    appointment: appointment(id: $appointmentId) {
      id
      patient {
        id
        patientRelatedPersonRelationships {
          id
          doesResideWith
          relationshipType
          guardianshipType
          isGuarantor
          relatedPerson {
            id
            namePrefix
            givenName
            middleName
            familyName
            nameSuffix
            contactInformation {
              id
              primaryLanguage
              secondaryLanguage
              emailAddress
              mobileNumber
              homeNumber
              workNumber
              homeAddress {
                id
                line1
                line2
                city
                postalCode
                state
              }
            }
          }
        }
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  appointment,
}: CellSuccessProps<AppointmentCaregiverDetailsQuery>) => {
  const {
    patient: { patientRelatedPersonRelationships },
  } = appointment

  return (
    <StackView space={125}>
      {patientRelatedPersonRelationships.map((relationship) => {
        const { contactInformation, ...relatedPerson } =
          relationship.relatedPerson

        return (
          <DataDisplayList
            key={relationship.id}
            title={formatDisplayName(relatedPerson)}
            subtitle="This summarizes important details on a caregiver for the patient."
            data={[
              {
                label: 'Full name',
                value: formatDisplayName(relatedPerson),
              },
              {
                label: 'Relationship to patient',
                value: relationshipTypeDisplay[relationship.relationshipType],
              },
              {
                label: 'Guardianship status',
                value: guardianshipTypeDisplay[relationship.guardianshipType],
              },
              {
                label: 'Guarantor status',
                value: relationship.isGuarantor,
              },
              {
                label: 'Phone number(s)',
                value: formatContactInformationPhoneNumbers(contactInformation),
              },
              {
                label: 'Email address',
                value: contactInformation.emailAddress,
              },
              {
                label: 'Primary language',
                value: languageDisplay[contactInformation.primaryLanguage],
              },
              {
                label: 'Secondary language',
                value: languageDisplay[contactInformation.secondaryLanguage],
              },
            ]}
          />
        )
      })}
    </StackView>
  )
}
