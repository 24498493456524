import { useRef } from 'react'

import {
  QuestionnaireItem,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemAnswer,
} from '@medplum/fhirtypes'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import SYSTEMS from 'common/cdr/concepts/systems'
import { unitDisplay } from 'common/unitConverter/unitConverter'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import { Interpretation, OrderWithObservationsFragment } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Badge from 'src/components/atoms/Badge'
import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { AccordionItem } from 'src/components/molecules/Accordion/Accordion'
import SidepanelButtonFooter from 'src/components/molecules/SidepanelButtonFooter'
import Table from 'src/components/molecules/Table'
import Tabs from 'src/components/molecules/Tabs/Tabs'
import { QuestionnaireItemMetadataProvider } from 'src/components/Order/CompleteQuestionnaireOrderSidepanelForm/Questionnaire'
import {
  interpretationDisplay,
  interpretationToColor,
} from 'src/data/interpretations'
import { sidepanelRoute } from 'src/lib/routes'

export const QuestionnaireScoringDetails = ({
  order,
}: {
  order: OrderWithObservationsFragment
}) => {
  if (
    !order.questionnaireResults?.develoQuestionnaire?.['scoringDetails'] &&
    !order.questionnaireScoringDetails &&
    !order.scoringDetailsForQuestionnaires
  )
    return null

  const scoringDetailsForQuestionnaire =
    order.questionnaireResults?.develoQuestionnaire?.['scoringDetails'] ??
    order.questionnaireScoringDetails

  if (scoringDetailsForQuestionnaire) {
    return (
      <AccordionItem bordered title={'Scoring Details'}>
        <StackView className="rounded-b-md border px-6 py-3" space={75}>
          {scoringDetailsForQuestionnaire.map((detail) => (
            <StackView space={50} key={detail.header}>
              <Typography fontWeight="semibold">{detail.header}</Typography>
              <ul className="list-disc pl-6">
                {detail.instructions.map((instruction) => (
                  <li key={instruction}>
                    <Typography>{instruction}</Typography>
                  </li>
                ))}
              </ul>
            </StackView>
          ))}
        </StackView>
      </AccordionItem>
    )
  } else if (order.scoringDetailsForQuestionnaires) {
    return (
      <>
        {order.scoringDetailsForQuestionnaires.map((scoringDetails, index) => {
          return (
            <AccordionItem key={index} bordered title={'Scoring Details'}>
              <StackView className="rounded-b-md border px-6 py-3" space={75}>
                {scoringDetails.map((detail) => (
                  <StackView space={50} key={detail.header}>
                    <Typography fontWeight="semibold">
                      {detail.header}
                    </Typography>
                    <ul className="list-disc pl-6">
                      {detail.instructions.map((instruction) => (
                        <li key={instruction}>
                          <Typography>{instruction}</Typography>
                        </li>
                      ))}
                    </ul>
                  </StackView>
                ))}
              </StackView>
            </AccordionItem>
          )
        })}
      </>
    )
  }
}

export const Comments = ({
  order,
}: {
  order: OrderWithObservationsFragment
}) => {
  if (!order.questionnaireResults || !order.questionnaireResults.notes)
    return null

  return (
    <AccordionItem bordered defaultOpen title={'Comments'}>
      <Box className="rounded-b-md border px-6 py-3">
        <Typography>{order.questionnaireResults.notes}</Typography>
      </Box>
    </AccordionItem>
  )
}

const vanderbiltScreeningCodes = [
  'NICHQ_VANDERBILT_PARENT_TEACHER_INFORMANT',
  'NICHQ_VANDERBILT_FOLLOW_UP_PARENT_TEACHER_INFORMANT',
]

export const QuestionnaireOverallResults = ({
  order,
}: {
  order: OrderWithObservationsFragment
}) => {
  if (!order.questionnaireResults && !order.resultsForQuestionnaires)
    return null

  if (order.questionnaireResults) {
    if (order.code.includes('ASQ') || order.code.includes('PHQ_9_A')) {
      const resultsWithAnswerDisplayAndInterpretation =
        order.questionnaireResults?.overall.filter((result) => {
          return result.answerDisplay && result.interpretation
        })

      const resultsWithScoreAndInterpretation =
        order.questionnaireResults?.overall.filter((result) => {
          return (
            !result.answerDisplay &&
            result.score !== undefined &&
            result.interpretation
          )
        }) // explicit check for undefined since score can be 0

      return (
        <>
          <AccordionItem bordered defaultOpen title={'Overall Results'}>
            <StackView className="rounded-b-md border px-6 py-3" space={75}>
              {!isEmpty(resultsWithScoreAndInterpretation) && (
                <ScreeningScoreAndInterpretationResultsTable
                  testId="score-and-interpretation-table"
                  encounterId={order.encounterId}
                  results={resultsWithScoreAndInterpretation}
                />
              )}

              {!isEmpty(resultsWithAnswerDisplayAndInterpretation) && (
                <ScreeningAnswerAndInterpretationResultsTable
                  testId="answer-and-interpretation-table"
                  encounterId={order.encounterId}
                  results={resultsWithAnswerDisplayAndInterpretation}
                />
              )}
            </StackView>
          </AccordionItem>
        </>
      )
    }

    return (
      <AccordionItem bordered defaultOpen title={'Overall Results'}>
        <StackView className="rounded-b-md border px-6 py-3" space={75}>
          <OverallResultsTable
            testId="questionnaire-overall-results-table"
            encounterId={order.encounterId}
            emptyMessage="No overall results"
            results={order.questionnaireResults?.overall}
          />
        </StackView>
      </AccordionItem>
    )
  } else if (order.resultsForQuestionnaires) {
    return (
      <>
        {order.resultsForQuestionnaires.map((result, index) => {
          const resultsWithScoresOnly = order.resultsForQuestionnaires[
            index
          ]?.overall.filter(
            (result) => result.score >= 0 && isEmpty(result.interpretation)
          )

          const resultsWithInterpretationsOnly = order.resultsForQuestionnaires[
            index
          ]?.overall.filter(
            (result) => isEmpty(result.score) && !isEmpty(result.interpretation)
          )

          return vanderbiltScreeningCodes.includes(order.code) ? (
            <AccordionItem
              key={index}
              bordered
              defaultOpen
              title={'Parent Evaluation Results'} // TODO: Switch 'Parent' to 'Teacher' depending on tab
            >
              <StackView className="rounded-b-md border px-6 py-3" space={75}>
                <>
                  {resultsWithInterpretationsOnly.length > 0 && (
                    <ScreeningInterpretationResultsTable
                      testId="questionnaire-screening-interpretations-table"
                      encounterId={order.encounterId}
                      results={resultsWithInterpretationsOnly}
                    />
                  )}
                  {resultsWithScoresOnly.length > 0 && (
                    <ScreeningScoresTable
                      testId="questionnaire-screening-scores-table"
                      encounterId={order.encounterId}
                      results={resultsWithScoresOnly}
                    />
                  )}
                </>
              </StackView>
            </AccordionItem>
          ) : (
            <AccordionItem
              key={index}
              bordered
              defaultOpen
              title={'Overall Results'}
            >
              <StackView className="rounded-b-md border px-6 py-3" space={75}>
                <OverallResultsTable // Score + Interpretation table
                  testId="questionnaire-overall-results-table"
                  encounterId={order.encounterId}
                  emptyMessage="No overall results"
                  results={order.resultsForQuestionnaires[index]?.overall}
                />
              </StackView>
            </AccordionItem>
          )
        })}
      </>
    )
  }
}

const LOWEST_POSSIBLE_SCORE_DISPLAY = 0

const ScreeningScoresTable = ({
  encounterId,
  testId,
  results,
}: {
  encounterId: string
  testId: string
  results: {
    header: string
    score?: number
  }[]
}) => {
  const gridRef = useRef<AgGridReact>()

  const defaultColDef: ColDef = {
    cellStyle: { display: 'flex' },
    resizable: true,
    autoHeight: true,
    wrapText: true,
  }

  const columnDefs: ColDef[] = [
    {
      colId: 'header',
      headerName: '',
      cellRenderer: ({ data }: { data }) => {
        return (
          data.header && (
            <Typography fontWeight="semibold">{data.header}</Typography>
          )
        )
      },
    },
    {
      colId: 'score',
      headerName: 'Score',
      cellRenderer: ({ data }: { data; context: Record<string, string> }) => {
        return (
          // data.score can have value 0
          <Typography>{data.score || LOWEST_POSSIBLE_SCORE_DISPLAY}</Typography>
        )
      },
    },
  ]

  return (
    <Table
      testId={testId ?? 'questionnaire-screening-scores-table'}
      innerRef={gridRef}
      rowData={results}
      domLayout="autoHeight"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      pagination={false}
      context={{ encounterId }}
      noMinHeight
    />
  )
}

const ScreeningScoreAndInterpretationResultsTable = ({
  encounterId,
  testId,
  results,
}: {
  encounterId: string
  testId: string
  results: {
    header: string
    interpretation?: Interpretation
  }[]
}) => {
  const gridRef = useRef<AgGridReact>()

  const defaultColDef: ColDef = {
    cellStyle: { display: 'flex' },
    resizable: true,
    autoHeight: true,
    wrapText: true,
  }

  const columnDefs: ColDef[] = [
    {
      colId: 'header',
      headerName: '',
      cellRenderer: ({ data }: { data }) => {
        return (
          data.header && (
            <Typography fontWeight="semibold">{data.header}</Typography>
          )
        )
      },
    },
    {
      colId: 'score',
      headerName: 'Score',

      cellRenderer: ({ data }: { data }) => {
        return <Typography size="xs">{data.score}</Typography>
      },
    },
    {
      colId: 'interpretation',
      headerName: 'Interpretation',

      cellRenderer: ({ data }: { data }) => {
        return (
          data.interpretation && (
            <Badge color={interpretationToColor[data.interpretation] ?? 'gray'}>
              {interpretationDisplay[data.interpretation]}
            </Badge>
          )
        )
      },
    },
  ]

  return (
    <Table
      testId={testId ?? 'questionnaire-screening-interpretations-table'}
      innerRef={gridRef}
      rowData={results}
      domLayout="autoHeight"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      pagination={false}
      context={{ encounterId }}
      noMinHeight
    />
  )
}

const ScreeningAnswerAndInterpretationResultsTable = ({
  encounterId,
  testId,
  results,
}: {
  encounterId: string
  testId: string
  results: {
    header: string
    interpretation?: Interpretation
  }[]
}) => {
  const gridRef = useRef<AgGridReact>()

  const defaultColDef: ColDef = {
    cellStyle: { display: 'flex' },
    resizable: true,
    autoHeight: true,
    wrapText: true,
  }

  const columnDefs: ColDef[] = [
    {
      colId: 'header',
      headerName: '',
      cellRenderer: ({ data }: { data }) => {
        return (
          data.header && (
            <Typography fontWeight="semibold">{data.header}</Typography>
          )
        )
      },
    },
    {
      colId: 'answerDisplay',
      headerName: 'Answer',

      cellRenderer: ({ data }: { data }) => {
        return <Typography size="xs">{data.answerDisplay}</Typography>
      },
    },
    {
      colId: 'interpretation',
      headerName: 'Interpretation',

      cellRenderer: ({ data }: { data }) => {
        return (
          data.interpretation && (
            <Badge color={interpretationToColor[data.interpretation] ?? 'gray'}>
              {interpretationDisplay[data.interpretation]}
            </Badge>
          )
        )
      },
    },
  ]

  return (
    <Table
      testId={testId ?? 'questionnaire-screening-interpretations-table'}
      innerRef={gridRef}
      rowData={results}
      domLayout="autoHeight"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      pagination={false}
      context={{ encounterId }}
      noMinHeight
    />
  )
}

const ScreeningInterpretationResultsTable = ({
  encounterId,
  testId,
  results,
}: {
  encounterId: string
  testId: string
  results: {
    header: string
    interpretation?: Interpretation
  }[]
}) => {
  const gridRef = useRef<AgGridReact>()

  const defaultColDef: ColDef = {
    cellStyle: { display: 'flex' },
    resizable: true,
    autoHeight: true,
    wrapText: true,
  }

  const columnDefs: ColDef[] = [
    {
      colId: 'header',
      headerName: '',
      cellRenderer: ({ data }: { data }) => {
        return (
          data.header && (
            <Typography fontWeight="semibold">{data.header}</Typography>
          )
        )
      },
    },
    {
      colId: 'screeningResult',
      headerName: 'Screening Result',

      cellRenderer: ({ data }: { data }) => {
        return (
          data.interpretation && (
            <Badge color={interpretationToColor[data.interpretation] ?? 'gray'}>
              {interpretationDisplay[data.interpretation]}
            </Badge>
          )
        )
      },
    },
  ]

  return (
    <Table
      testId={testId ?? 'questionnaire-screening-interpretations-table'}
      innerRef={gridRef}
      rowData={results}
      domLayout="autoHeight"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      pagination={false}
      context={{ encounterId }}
      noMinHeight
    />
  )
}

const OverallResultsTable = ({
  encounterId,
  testId,
  results,
  emptyMessage,
}: {
  encounterId: string
  testId: string
  results: {
    header: string
    score?: number
    interpretation?: Interpretation
  }[]
  emptyMessage: string
}) => {
  const gridRef = useRef<AgGridReact>()

  const defaultColDef: ColDef = {
    cellStyle: { display: 'flex' },
    resizable: true,
    autoHeight: true,
    wrapText: true,
  }

  const columnDefs: ColDef[] = [
    {
      colId: 'header',
      headerName: '',
      cellRenderer: ({ data }: { data }) => {
        return (
          data.header && (
            <Typography className="whitespace-normal" fontWeight="semibold">
              {data.header}
            </Typography>
          )
        )
      },
      maxWidth: 180,
    },
    {
      colId: 'score',
      headerName: 'Score',
      cellRenderer: ({ data }: { data; context: Record<string, string> }) => {
        return <Typography>{data.score}</Typography>
      },
      maxWidth: 100,
    },
    {
      colId: 'interpretation',
      headerName: 'interpretation',

      cellRenderer: ({ data }: { data }) => {
        return (
          data.interpretation && (
            <Badge color={interpretationToColor[data.interpretation] ?? 'gray'}>
              {interpretationDisplay[data.interpretation]}
            </Badge>
          )
        )
      },
    },
  ]

  return (
    <Table
      testId={testId ?? 'questionnaire-overall-results-table'}
      innerRef={gridRef}
      rowData={results}
      domLayout="autoHeight"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      pagination={false}
      noRowsOverlayComponentParams={{ message: emptyMessage }}
      context={{ encounterId }}
      noMinHeight
    />
  )
}

const getDisplayValueFromAnswer = (answer: QuestionnaireResponseItemAnswer) => {
  if (answer.valueCoding) {
    return answer.valueCoding.display
  }

  if (answer.valueString) {
    return answer.valueString
  }
}

const getAnswerDisplayComponent = (
  questionnaireItem: QuestionnaireItem,
  answer: QuestionnaireResponseItemAnswer
) => {
  if (answer.valueCoding) {
    return (
      <Typography
        key={questionnaireItem.linkId}
        fontWeight="bold"
        color="text-base-color-fg-default"
      >
        {answer.valueCoding.display}
      </Typography>
    )
  }

  // explicitly check for undefined since value can be 0
  if (answer.valueQuantity?.value !== undefined) {
    // get the unit to display from the questionnaireItem
    const unitExtension = questionnaireItem.extension?.find(
      (extension) => extension.url === SYSTEMS.QUESTIONNAIRE_ITEM_UNIT
    )

    return (
      <Typography
        key={questionnaireItem.linkId}
        fontWeight="bold"
        color="text-base-color-fg-default"
      >
        {`${answer.valueQuantity.value} ${
          unitDisplay[unitExtension.valueCoding.code] ?? ''
        } `}
      </Typography>
    )
  }

  if (answer.valueString) {
    return (
      <Typography
        fontWeight="bold"
        color="text-base-color-fg-default"
        key={questionnaireItem.linkId}
      >
        {answer.valueString}
      </Typography>
    )
  }
}

const renderQuestionnaireResultDisplay = (
  questionnaireItem: QuestionnaireItem,
  questionnaireResponseItemAnswers: QuestionnaireResponseItemAnswer[]
) => {
  if (!questionnaireResponseItemAnswers) return null

  return questionnaireResponseItemAnswers.map((answer) =>
    getAnswerDisplayComponent(questionnaireItem, answer)
  )
}

const findQuestionnaireItemExtensionByUrl = (
  questionnaireItem: QuestionnaireItem,
  searchUrl: string
) => {
  if (!questionnaireItem) return
  for (const extension of questionnaireItem.extension ?? []) {
    if (extension.url === searchUrl) {
      return extension
    }
  }
}

export const getItemDisplayCategory = (
  questionnaireItem: QuestionnaireItem
) => {
  const itemDisplayCategoryExtension = findQuestionnaireItemExtensionByUrl(
    questionnaireItem,
    SYSTEMS.QUESTIONNAIRE_ITEM_DISPLAY_CATEGORY_URL
  )

  const itemDisplayCategory =
    itemDisplayCategoryExtension?.valueCodeableConcept?.coding?.[0]?.code

  return itemDisplayCategory
}

const buildResultDisplayComponents = (
  questionnaireItems: QuestionnaireItem[],
  responseItems: QuestionnaireResponseItem[]
) => {
  if (isEmpty(responseItems)) return null
  return compact(
    responseItems.map((responseItem, index) => {
      const itemDisplayCategory = getItemDisplayCategory(
        questionnaireItems?.[index]
      )

      const questionnaireItem = questionnaireItems.find(
        (item) => item.linkId === responseItem.linkId
      )

      if (!questionnaireItem) return

      const isSection = itemDisplayCategory === 'section'

      const defaultProviderProps = {
        index,
        questionnaireItem,
        renderInstructionsBeforeChildren: true,
      }

      const itemText = questionnaireItem.prefix
        ? `${questionnaireItem.prefix} ${questionnaireItem.text}`
        : questionnaireItem.text

      if (responseItem.item) {
        return (
          <QuestionnaireItemMetadataProvider
            {...defaultProviderProps}
            key={responseItem.linkId}
            questionConfig={{
              size: isSection ? 'xl' : 's',
              fontWeight: isSection ? 'bold' : 'semibold',
              color: isSection
                ? 'text-base-color-fg-default'
                : 'text-base-color-fg-muted',
            }}
            instructionsConfig={{
              size: 's',
              fontWeight: 'semibold',
            }}
            itemText={itemText ?? ''}
          >
            <StackView className="pl-6">
              {buildResultDisplayComponents(
                questionnaireItem.item,
                responseItem.item
              )}
            </StackView>
          </QuestionnaireItemMetadataProvider>
        )
      }

      return (
        <>
          <StackView space={75} key={responseItem.linkId}>
            <QuestionnaireItemMetadataProvider
              {...defaultProviderProps}
              questionConfig={{
                size: 's',
                fontWeight: 'semibold',
              }}
              instructionsConfig={{
                size: 'xs',
                fontWeight: 'normal',
              }}
            >
              <>
                {renderQuestionnaireResultDisplay(
                  questionnaireItem,
                  responseItem.answer
                )}
              </>
            </QuestionnaireItemMetadataProvider>
          </StackView>
        </>
      )
    })
  )
}

export const QuestionnaireDetailedResults = ({
  order,
}: {
  order: Partial<OrderWithObservationsFragment>
}) => {
  if (!order.questionnaireResults && !order.resultsForQuestionnaires)
    return null

  if (order.questionnaireResults) {
    const isAsqQuestionnaire = order.code.includes('ASQ')

    const allResponseItems = order.questionnaireResults
      ?.detailed as unknown as QuestionnaireResponseItem[]

    return (
      <AccordionItem bordered defaultOpen title={'Detailed Results'}>
        <StackView
          data-testid="questionnaire-detailed-results"
          className="rounded-b-md border px-6 py-3"
          space={75}
        >
          {buildResultDisplayComponents(
            order.questionnaireResults?.develoQuestionnaire?.[
              'questionnaire'
            ]?.['item'] ?? order.questionnaire['item'],
            isAsqQuestionnaire
              ? allResponseItems.filter(
                  (responseItem) =>
                    !responseItem.linkId.includes('additionalinformation') ||
                    responseItem.linkId.includes('explanation')
                )
              : allResponseItems
          )}
        </StackView>
      </AccordionItem>
    )
  } else if (order.resultsForQuestionnaires) {
    return (
      <>
        {order.resultsForQuestionnaires.map((result, index) => {
          return (
            <AccordionItem
              key={index}
              bordered
              defaultOpen
              title={'Detailed Results'}
            >
              <StackView
                data-testid="questionnaire-detailed-results"
                className="rounded-b-md border px-6 py-3"
                space={75}
              >
                {buildResultDisplayComponents(
                  order.questionnaires[index]?.['item'],
                  order.resultsForQuestionnaires[index]
                    ?.detailed as unknown as QuestionnaireResponseItem[]
                )}
              </StackView>
            </AccordionItem>
          )
        })}
      </>
    )
  }
}

const AsqAdditionalInformation = ({ order }) => {
  const allResponseItems = order.questionnaireResults
    ?.detailed as unknown as QuestionnaireResponseItem[]

  if (!allResponseItems) return null

  const additionalInformationResponseItems = allResponseItems
    .find(
      (responseItem) => responseItem?.linkId?.includes('additionalinformation') // asq3-<x>-additionalinformation (group item)
    )
    ?.item?.find(
      (responseItem) => responseItem?.linkId?.includes('additionalinformation') // additionalinformation-q1
    )?.item // asq3-<x>-additionalinformation-q1a, asq3-<x>-additionalinformation-q1b,...

  if (isEmpty(additionalInformationResponseItems)) return null

  const additionalInformationExplanationItems =
    additionalInformationResponseItems?.filter((responseItem) =>
      responseItem.linkId.includes('explanation')
    )

  const additionalInformationAnswerItems =
    additionalInformationResponseItems?.filter(
      (responseItem) => !responseItem.linkId.includes('explanation')
    )

  if (isEmpty(additionalInformationAnswerItems)) return null

  const questionnaireToUse = order.questionnaireResults?.develoQuestionnaire
    ?.questionnaire
    ? order.questionnaireResults?.develoQuestionnaire?.questionnaire
    : order.questionnaire

  const additionalInformationQuestionnaireItems = questionnaireToUse?.item
    ?.find((item) => item.linkId.includes('additionalinformation'))
    .item?.find((item) => item.linkId.includes('additionalinformation'))?.item

  const linkIdToDisplayComponent = {}

  for (const answerItem of additionalInformationAnswerItems) {
    const foundQuestionnaireItem = additionalInformationQuestionnaireItems.find(
      (questionnaireItem) => questionnaireItem.linkId === answerItem.linkId
    )

    if (!foundQuestionnaireItem) continue

    const foundExplanation = additionalInformationExplanationItems.find(
      (explanationItem) => explanationItem.linkId.includes(answerItem.linkId)
    )

    if (!foundExplanation) continue

    linkIdToDisplayComponent[answerItem.linkId] = (
      <StackView space={25}>
        <Typography>{foundQuestionnaireItem.text}</Typography>
        <StackView space={25} direction="row">
          <Typography>{'Reason: '}</Typography>
          <Typography>
            {getDisplayValueFromAnswer(foundExplanation.answer[0])}
          </Typography>
        </StackView>
      </StackView>
    )
  }

  if (isEmpty(linkIdToDisplayComponent)) return null
  return (
    <AccordionItem bordered defaultOpen title="Additional Information">
      <StackView
        data-testid="questionnaire-additional-information"
        className="rounded-b-md border px-6 py-3"
        space={100}
      >
        <StackView direction="col" space={75}>
          <Typography fontWeight="semibold">Follow-up Action Taken</Typography>
          {Object.keys(linkIdToDisplayComponent).map((linkId) => {
            return linkIdToDisplayComponent[linkId]
          })}
        </StackView>

        {order.questionnaireResults?.notes && (
          <StackView direction="col" space={50}>
            <Typography fontWeight="semibold">Comments</Typography>
            <Typography>{order.questionnaireResults?.notes}</Typography>
          </StackView>
        )}
      </StackView>
    </AccordionItem>
  )
}

export const QuestionnaireOrderSidepanelView = ({
  order,
  encounterId,
}: {
  patientId: string
  encounterId: string
  order: OrderWithObservationsFragment
}): JSX.Element => {
  const params = useParams()
  const location = useLocation()

  const isAsqQuestionnaire =
    order.questionnaire?.['code']?.[0]?.['code']?.includes('ASQ')

  if (order.questionnaire) {
    return (
      <>
        <StackView className="w-full flex-1 overflow-y-auto p-6" space={50}>
          {isAsqQuestionnaire && <AsqAdditionalInformation order={order} />}
          {!isAsqQuestionnaire && <Comments order={order} />}
          <QuestionnaireOverallResults order={order} />
          <>
            <QuestionnaireScoringDetails order={order} />
          </>
          <QuestionnaireDetailedResults order={order} />
        </StackView>
        <Box
          inverse
          verticalPadding={100}
          horizontalPadding={100}
          className="border-l-0"
        >
          <SidepanelButtonFooter
            submitting={false}
            editText="Edit"
            onEdit={() =>
              navigate(
                sidepanelRoute(
                  {
                    route: `/encounters/${encounterId}/questionnaires/${order.id}/edit`,
                  },
                  location,
                  params
                )
              )
            }
          />
        </Box>
      </>
    )
  }

  if (order.questionnaires) {
    return (
      <Tabs
        className="p-6"
        tabs={order.questionnaires.map((questionnaire, index) => {
          return {
            name: questionnaire['title'],
            key: index.toString(),
            content: (
              <>
                <StackView className="flex-1 overflow-y-auto p-6">
                  <Comments order={order} />
                  <QuestionnaireOverallResults order={order} />
                  <>
                    <QuestionnaireScoringDetails order={order} />
                  </>
                  <QuestionnaireDetailedResults order={order} />
                </StackView>
                <Box
                  inverse
                  verticalPadding={100}
                  horizontalPadding={100}
                  className="border-l-0"
                >
                  <SidepanelButtonFooter
                    submitting={false}
                    editText="Edit"
                    onEdit={() =>
                      navigate(
                        sidepanelRoute(
                          {
                            route: `/encounters/${encounterId}/questionnaires/${order.id}/edit`,
                          },
                          location,
                          params
                        )
                      )
                    }
                  />
                </Box>
              </>
            ),
          }
        })}
      />
    )
  }
}
export default QuestionnaireOrderSidepanelView
