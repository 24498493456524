import { FRAGMENT as CURRENT_INSURANCE_ELIGIBILITY_FRAGMENT } from 'src/components/InsuranceEligibility/fragments'
import { SIBLING_DEMOGRAPHICS_FRAGMENT } from 'src/components/organisms/PatientSiblings/SiblingInfoCard'
import { PHARMACY_FRAGMENT as PHARMACY_FRAGMENT } from 'src/hooks/useRefetchPatientPharmacy/useRefetchPatientPharmacy'
import {
  PATIENT_RELATED_PERSON_RELATIONSHIP_WITH_FULL_DETAILS_FRAGMENT,
  PATIENT_STATUS_FRAGMENT,
} from 'src/pages/PatientChartsPage/PatientDemographics/fragments'

const PATIENT_VERIFICATION_FRAGMENT = gql`
  fragment PatientVerificationFragment on Patient {
    coreDemographicsVerifiedAt
    additionalDemographicsVerifiedAt
    residenceVerifiedAt
    insuranceOptOutVerifiedAt
    pharmacyVerifiedAt
  }
`

export const INSURANCE_COVERAGE_FRAGMENT = gql`
  fragment InsuranceCoverageFragment on InsuranceCoverage {
    id
    statusVerifiedAt
    beneficiaryVerifiedAt
    subscriberVerifiedAt
    planInformationVerifiedAt
    beneficiaryIsSubscriber
    status
    planName
    planType
    planWebsite
    planMemberIdentifier
    planGroupIdentifier
    coordinationOfBenefitsType
    startDate
    endDate
    payerId
    payerAddressId
    payer {
      id
      displayName
    }
    subscriber {
      id
      birthDate
      namePrefix
      nameSuffix
      givenName
      middleName
      familyName
    }
    payerAddress {
      id
      purpose
      line1
      line2
      city
      country
      district
      postalCode
      state
      zipPlusFour
    }
  }
`

export const APPOINTMENT_PATIENT_QUERY = gql`
  query AppointmentPatientCheckInQuery($appointmentId: String!) {
    appointment: appointment(id: $appointmentId) {
      id
      start
      status
      visitComment
      chiefComplaints
      encounterId
      afterVisitSummaryFhirId
      afterVisitSummaryWithAdolescentConfidentialInfoFhirId
      afterVisitSummaryWithoutAdolescentConfidentialInfoFhirId
      documentationAdolescentConfidential
      patientEducationAdolescentConfidential
      appointmentDefinitions {
        id
        name
        type
        code
        duration
      }
      patientCheckInCompletedAt
      patientSelfRegistrationCompletedAt
      patient {
        id
        mrn
        ...PatientStatusFragment
        birthDate
        primaryGuardianId
        primaryGuarantorId
        legalStatus
        practiceComment
        namePrefix
        nameSuffix
        givenName
        middleName
        familyName
        preferredName
        sexAtBirth
        genderIdentity
        preferredPronouns
        race
        ethnicity
        socialSecurityNumber
        sexualOrientation
        caregiverOptedOutAt
        insuranceOptOutStatus
        insuranceOptedOutAt
        copayCents
        ...PatientVerificationFragment
        defaultPharmacy {
          ...PharmacyFragment
        }
        doseSpotURL
        contactInformation {
          id
          verifiedAt
          emailAddress
          primaryLanguage
          secondaryLanguage
          faxNumber
          mobileNumber
          homeNumber
          primaryPreferredCommunication
          secondaryPreferredCommunication
          homeAddress {
            id
            line1
            line2
            city
            postalCode
            state
            country
          }
          mailingAddress {
            id
            line1
            line2
            city
            postalCode
            state
            country
          }
        }
        primaryProvider {
          id
          givenName
          familyName
        }
        primaryGuardian {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
        }
        primaryGuarantor {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
        }
        patientRelatedPersonRelationships {
          ...PatientRelatedPersonRelationshipWithFullDetailsFragment
        }
        siblings {
          ...SiblingDemographicsFragment
        }
        insuranceCoverages {
          ...InsuranceCoverageFragment
          mostRecentEligibility(appointmentId: $appointmentId) {
            ...InsuranceEligibilityFragment
          }
          binary {
            contentType
            url
          }
        }
        requiredPracticeFormSummaries {
          type
          current {
            signedPracticeForm {
              id
              practiceFormId
              signedAt
              signedByPatientId
            }
            practiceFormTemplate {
              id
              version
              practiceFormDefinition {
                id
              }
            }
          }
        }
        optionalPracticeFormSummaries {
          type
          current {
            signedPracticeForm {
              id
              practiceFormId
              signedByPatientId
              signedAt
            }
            practiceFormTemplate {
              id
              version
              practiceFormDefinition {
                id
              }
            }
          }
        }
      }
      practiceFormsSignedUsingSelfCheckIn {
        id
        signedAt
        practiceForm {
          id
          version
          practiceFormDefinition {
            id
            type
          }
        }
      }
      patientEducationDocuments {
        id
        title
      }
      patientFacingTasks {
        __typename
        ... on PatientCheckInTask {
          id
          isComplete
          completedAt
          lastSentAt
          lastSentToNumber
        }
        ... on PatientRegistrationTask {
          id
          isComplete
          completedAt
          lastSentAt
          lastSentToNumber
        }
        ... on PatientScreeningTask {
          id
          isComplete
          questionnaireName
          questionnaireCode
          questionnaireIntendedFor
          completedAt
          lastSentAt
          lastSentToNumber
        }
      }
    }
  }
  ${CURRENT_INSURANCE_ELIGIBILITY_FRAGMENT}
  ${PHARMACY_FRAGMENT}
  ${PATIENT_VERIFICATION_FRAGMENT}
  ${INSURANCE_COVERAGE_FRAGMENT}
  ${SIBLING_DEMOGRAPHICS_FRAGMENT}
  ${PATIENT_RELATED_PERSON_RELATIONSHIP_WITH_FULL_DETAILS_FRAGMENT}
  ${PATIENT_STATUS_FRAGMENT}
`

export const VERIFY_PATIENT_ATTRIBUTE_MUTATION = gql`
  mutation VerifyPatientResidenceMutation(
    $patientId: String!
    $verificationType: PatientVerificationType!
  ) {
    verifyPatient(patientId: $patientId, verificationType: $verificationType) {
      id
      ...PatientVerificationFragment
    }
  }
  ${PATIENT_VERIFICATION_FRAGMENT}
`

export const VERIFY_INSURANCE_STATUS_QUERY = gql`
  mutation VerifyInsuranceStatus(
    $patientId: String!
    $insuranceId: String!
    $verificationType: PatientInsuranceVerificationType!
  ) {
    verifyPatientInsurance(
      patientId: $patientId
      insuranceId: $insuranceId
      verificationType: $verificationType
    ) {
      id
      insuranceCoverages {
        id
        statusVerifiedAt
        beneficiaryVerifiedAt
        subscriberVerifiedAt
      }
    }
  }
`

export const UPDATE_INSURANCE_STATUS_MUTATION = gql`
  mutation UpdateInsuranceCoverage(
    $id: String!
    $input: UpdateInsuranceCoverageInput!
  ) {
    updateInsuranceCoverage(id: $id, input: $input) {
      ...InsuranceCoverageFragment
      binary {
        url
        contentType
      }
    }
  }
  ${INSURANCE_COVERAGE_FRAGMENT}
`

export const CREATE_SIGNED_PRACTICE_FORM_MUTATION = gql`
  mutation CreateMandatorySignedPracticeFormMutation(
    $input: CreateSignedPracticeFormInput!
  ) {
    createSignedPracticeForm(input: $input) {
      id
      completionStatus
      practiceFormId
      signedAt
      signedByPatient {
        id
        requiredPracticeFormSummaries {
          type
          current {
            signedPracticeForm {
              id
              practiceFormId
              signedByPatientId
              signedAt
            }
            practiceFormTemplate {
              id
              version
              practiceFormDefinition {
                id
              }
            }
          }
        }
        optionalPracticeFormSummaries {
          type
          current {
            signedPracticeForm {
              id
              practiceFormId
              signedByPatientId
              signedAt
            }
            practiceFormTemplate {
              id
              version
              practiceFormDefinition {
                id
              }
            }
          }
        }
      }
    }
  }
`

export const UPDATE_APPOINTMENT_STATUS_MUTATION = gql`
  mutation UpdateAppointmentStatus(
    $id: String!
    $input: UpdateAppointmentStatusInput!
  ) {
    updateAppointmentStatus(id: $id, input: $input) {
      id
      status
      statusUpdatedAt
    }
  }
`

export const CANCEL_APPOINTMENT_MUTATION = gql`
  mutation CancelAppointment($id: String!, $input: CancelAppointmentInput!) {
    cancelAppointment(id: $id, input: $input) {
      id
      status
      statusUpdatedAt
    }
  }
`
