export const siteOptions = [
  {
    code: 'LT',
    display: 'Left thigh',
  },
  {
    code: 'LVL',
    display: 'Left vastus lateralis',
  },
  {
    code: 'RT',
    display: 'Right thigh',
  },
  {
    code: 'RVL',
    display: 'Right vastus lateralis',
  },
  {
    code: 'LD',
    display: 'Left deltoid',
  },
  {
    code: 'RD',
    display: 'Right deltoid',
  },
  {
    code: 'LA',
    display: 'Left arm',
  },
  {
    code: 'RA',
    display: 'Right arm',
  },
  {
    code: 'LVG',
    display: 'Left ventragluteal (hip)',
  },
  {
    code: 'RVG',
    display: 'Right ventragluteal (hip)',
  },
  {
    code: 'LG',
    display: 'Left gluteus medius',
  },
  {
    code: 'RG',
    display: 'Right gluteus medius',
  },
  {
    code: 'BU',
    display: 'Buttock',
  },
  {
    code: 'OTHER',
    display: 'Other',
  },
]
