import { useState } from 'react'

import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import { User } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'

import Box from 'src/components/atoms/Box/Box'
import Comment from 'src/components/atoms/Comment/Comment'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import CommentItem from 'src/pages/CasesPage/ActivityItems/CommentItem/CommentItem'
import NoteItem from 'src/pages/CasesPage/ActivityItems/NoteItem/NoteItem'
import { PatientMessageItem } from 'src/pages/CasesPage/ActivityItems/PatientMessageItem/PatientMessageItem'
import RoutedToItem from 'src/pages/CasesPage/ActivityItems/RoutedToItem/RoutedToItem'
import StatusUpdateItem from 'src/pages/CasesPage/ActivityItems/StatusUpdateItem/StatusUpdateItem'

interface ActivityItemProps {
  text: string
  createdAt: string
  icon?: React.FunctionComponent<React.ComponentProps<'svg'>>
  createdBy: User
  iconColor?: 'gray' | 'red' | 'green'
}

const ActivityItem = ({
  text,
  createdAt,
  icon: Icon,
  createdBy,
  iconColor = 'gray',
}: ActivityItemProps) => {
  let backgroundColor
  let iconFillColor

  switch (iconColor) {
    case 'red':
      backgroundColor = 'bg-base-color-bg-danger'
      iconFillColor = 'text-base-color-fg-danger'
      break
    case 'green':
      backgroundColor = 'bg-base-color-bg-success'
      iconFillColor = 'text-base-color-fg-success'
      break
    default:
      backgroundColor = 'bg-base-color-bg-subtle'
      iconFillColor = 'text-base-color-fg-subtle'
  }

  return (
    <Box horizontalPadding={50} verticalPadding={75}>
      <StackView direction="row" space={75} alignItems="start">
        <Box flex="1/12">
          <Box
            className="flex h-8 w-8 items-center justify-center rounded-full"
            color={backgroundColor}
          >
            <Icon className={clsx('h-5 w-5', iconFillColor)} />
          </Box>
        </Box>
        <Box grow>
          <StackView
            space={50}
            direction="row"
            alignItems="center"
            justifyContent="between"
          >
            <Typography>{text}</Typography>
            <Typography>·</Typography>
            <Typography color="text-base-color-fg-muted" size="s">
              {format(parseISO(createdAt), "MMM d, yyyy 'at' h:mm a")}
            </Typography>
          </StackView>
          <Typography>{formatDisplayName(createdBy)}</Typography>
        </Box>
      </StackView>
    </Box>
  )
}

const QUERY = gql`
  query GetActivityItems($baseCaseId: String!) {
    caseRoutedEvents(baseCaseId: $baseCaseId) {
      id
      createdBy {
        id
        givenName
        familyName
      }
      routedToPoolId
      routedToUser {
        id
        givenName
        familyName
      }
      routedToTaskUserPool {
        id
        displayName
        users {
          id
          givenName
          familyName
          userType {
            id
            name
          }
        }
      }
      createdAt
    }
    caseNotes(baseCaseId: $baseCaseId) {
      id
      createdBy {
        id
        givenName
        familyName
      }
      text
      createdAt
    }
    caseStatusUpdates(baseCaseId: $baseCaseId) {
      id
      icon
      text
      createdBy {
        id
        givenName
        familyName
      }
      text
      createdAt
    }
    caseComments(baseCaseId: $baseCaseId) {
      id
      createdBy {
        id
        givenName
        familyName
      }
      text
      createdAt
    }
    casePatientMessages(baseCaseId: $baseCaseId) {
      id
      type
      text
      createdAt
    }
  }
`

export const CREATE_CASE_COMMENT_MUTATION = gql`
  mutation CreateCaseComment($input: CreateCaseCommentInput!) {
    createCaseComment(input: $input) {
      id
      createdBy {
        id
        givenName
        familyName
      }
      text
      createdAt
    }
  }
`

const ActivityTab = ({
  createdAt,
  resolvedAt,
  baseCaseId,
  createdBy,
  resolvedBy,
  resolvedStatus,
}) => {
  const [allActivityItems, setAllActivityItems] = useState([])
  useQuery(QUERY, {
    variables: {
      baseCaseId,
    },
    onCompleted: (data) => {
      setAllActivityItems(
        [
          ...(resolvedAt
            ? [
                {
                  id: 'resolved-at',
                  text: 'Task resolved',
                  resolvedAt,
                  resolvedBy,
                },
              ]
            : []),
          ...data.caseRoutedEvents,
          ...data.caseComments,
          ...data.caseNotes.map((item, index) => ({
            ...item,
            index,
          })),
          ...data.caseStatusUpdates,
          ...data.casePatientMessages,
        ].sort((a, b) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        })
      )
    },
  })

  const renderActivityItems = () => {
    return allActivityItems.map(
      ({
        id,
        text,
        icon,
        createdBy,
        createdAt,
        routedToPoolId,
        routedToUser,
        routedToTaskUserPool,
        index,
        type,
        __typename,
      }) => {
        if (__typename === 'CaseStatusUpdate') {
          return (
            <StatusUpdateItem
              key={id}
              text={text}
              createdAt={createdAt}
              createdBy={createdBy}
              icon={icon}
            />
          )
        }
        if (__typename === 'CaseComment') {
          return (
            <CommentItem
              key={id}
              text={text}
              createdBy={createdBy}
              createdAt={createdAt}
            />
          )
        }
        if (__typename === 'CaseNote') {
          return (
            <NoteItem
              index={index}
              key={id}
              text={text}
              createdBy={createdBy}
              createdAt={createdAt}
            />
          )
        }
        if (__typename === 'CaseRoutedEvent') {
          return (
            <RoutedToItem
              key={id}
              toUserPool={routedToPoolId}
              toUser={routedToUser}
              toTaskUserPool={routedToTaskUserPool}
              createdAt={createdAt}
              createdBy={createdBy}
            />
          )
        }
        if (__typename === 'CasePatientMessage') {
          return (
            <PatientMessageItem
              key={id}
              text={text}
              createdAt={createdAt}
              type={type}
            />
          )
        }
        if (id === 'resolved-at') {
          return (
            <ActivityItem
              iconColor={resolvedStatus === 'CANCELLED' ? 'red' : 'green'}
              key={id}
              text={
                resolvedStatus === 'CANCELLED'
                  ? 'Task cancelled'
                  : 'Task resolved'
              }
              createdAt={resolvedAt}
              icon={
                resolvedStatus === 'CANCELLED'
                  ? XMarkIcon
                  : ClipboardDocumentCheckIcon
              }
              createdBy={resolvedBy}
            />
          )
        }
      }
    )
  }
  const [createCaseComment, { loading: creating }] = useMutation(
    CREATE_CASE_COMMENT_MUTATION
  )
  const formMethods = useForm<{ comment: string }>({
    defaultValues: {
      comment: '',
    },
  })

  return (
    <StackView justifyContent="center" alignItems="center" direction="row">
      <Box fullWidth padding={75}>
        <StackView space={100}>
          <Form
            formMethods={formMethods}
            onSubmit={({ comment }) => {
              createCaseComment({
                refetchQueries: ['GetActivityItems'],
                variables: {
                  input: {
                    baseCaseId,
                    text: comment,
                  },
                },
                onCompleted: () => {
                  formMethods.reset()
                },
              })
            }}
          >
            <Comment
              disabled={creating}
              submitButtonText="Comment"
              name="comment"
              placeholder="Add your comment..."
            />
          </Form>
          {renderActivityItems()}
          <ActivityItem
            text="Task created"
            createdAt={createdAt}
            icon={ClipboardDocumentListIcon}
            createdBy={createdBy}
          />
        </StackView>
      </Box>
    </StackView>
  )
}

export default ActivityTab
