const MILLISECONDS_IN_MINUTE = 1000 * 60

export const AUTH_TIMEOUTS = {
  IDLE_AFTER_MILLISECONDS:
    parseInt(process.env.IDLE_AFTER_MINUTES) * MILLISECONDS_IN_MINUTE,
  EXTEND_SESSION_INTERVAL_MILLLISECONDS:
    parseInt(process.env.EXTEND_SESSION_INTERVAL_MINUTES) *
    MILLISECONDS_IN_MINUTE,
}

export const HOST_URL = process.env.HOST_URL
export const PATIENT_PORTAL_URL =
  process.env.PORTAL_HOST_URL ?? process.env.HOST_URL

export const TILLED_PUBLISHABLE_KEY = process.env.TILLED_PUBLISHABLE_KEY
export const TILLED_BASE_URL =
  process.env.TILLED_BASE_URL ?? 'https://sandbox-api.tilled.com'
