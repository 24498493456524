import {
  CheckEligibilityForAppointment,
  CheckEligibilityForAppointmentVariables,
  CheckEligibilityForPatient,
  CheckEligibilityForPatientVariables,
  CheckEligibilityForPatientRegistrationIntent,
  CheckEligibilityForPatientRegistrationIntentVariables,
  CheckEligibilityForInsurance,
  CheckEligibilityForInsuranceVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { FRAGMENT as INSURANCE_ELIGIBILITY_FRAGMENT } from 'src/components/InsuranceEligibility/fragments'

const CHECK_ELIGIBILITY_FOR_APPOINTMENT = gql`
  mutation CheckEligibilityForAppointment($appointmentId: String!) {
    checkEligibilityForAppointment(appointmentId: $appointmentId) {
      id
      patient {
        id
        activeInsuranceCoverages {
          id
          mostRecentEligibility(appointmentId: $appointmentId) {
            ...InsuranceEligibilityFragment
          }
        }
      }
      patientRegistrationIntent {
        id
        mostRecentEligibility(appointmentId: $appointmentId) {
          ...InsuranceEligibilityFragment
        }
      }
    }
  }
  ${INSURANCE_ELIGIBILITY_FRAGMENT}
`

const CHECK_ELIGIBILITY_FOR_PATIENT = gql`
  mutation CheckEligibilityForPatient($patientId: String!) {
    checkEligibilityForPatient(patientId: $patientId) {
      id
      activeInsuranceCoverages {
        id
        mostRecentEligibility {
          ...InsuranceEligibilityFragment
        }
      }
    }
  }
  ${INSURANCE_ELIGIBILITY_FRAGMENT}
`

const CHECK_ELIGIBILITY_FOR_PATIENT_REGISTRATION_INTENT = gql`
  mutation CheckEligibilityForPatientRegistrationIntent(
    $patientRegistrationIntentId: String!
  ) {
    checkEligibilityForPatientRegistrationIntent(
      patientRegistrationIntentId: $patientRegistrationIntentId
    ) {
      id
      mostRecentEligibility {
        ...InsuranceEligibilityFragment
      }
    }
  }
  ${INSURANCE_ELIGIBILITY_FRAGMENT}
`

const CHECK_ELIGIBILITY_FOR_INSURANCE = gql`
  mutation CheckEligibilityForInsurance($insuranceCoverageId: String!) {
    checkEligibilityForInsurance(insuranceCoverageId: $insuranceCoverageId) {
      id
      mostRecentEligibility {
        ...InsuranceEligibilityFragment
      }
    }
  }
  ${INSURANCE_ELIGIBILITY_FRAGMENT}
`

export type CheckEligibilityVariables =
  | CheckEligibilityForAppointmentVariables
  | CheckEligibilityForPatientVariables
  | CheckEligibilityForPatientRegistrationIntentVariables
  | CheckEligibilityForInsuranceVariables

export const useCheckEligibility = <T extends CheckEligibilityVariables>(
  checkEligibilityArg: T
) => {
  switch (true) {
    case isAppointmentCheck(checkEligibilityArg):
      return useMutation<
        CheckEligibilityForAppointment,
        CheckEligibilityForAppointmentVariables
      >(CHECK_ELIGIBILITY_FOR_APPOINTMENT, {
        variables:
          checkEligibilityArg as CheckEligibilityForAppointmentVariables,
      })
    case isPatientCheck(checkEligibilityArg):
      return useMutation<
        CheckEligibilityForPatient,
        CheckEligibilityForPatientVariables
      >(CHECK_ELIGIBILITY_FOR_PATIENT, {
        variables: checkEligibilityArg as CheckEligibilityForPatientVariables,
      })
    case isInsuranceCheck(checkEligibilityArg):
      return useMutation<
        CheckEligibilityForInsurance,
        CheckEligibilityForInsuranceVariables
      >(CHECK_ELIGIBILITY_FOR_INSURANCE, {
        variables: checkEligibilityArg as CheckEligibilityForInsuranceVariables,
      })
    case isPatientRegistrationIntentCheck(checkEligibilityArg):
      return useMutation<
        CheckEligibilityForPatientRegistrationIntent,
        CheckEligibilityForPatientRegistrationIntentVariables
      >(CHECK_ELIGIBILITY_FOR_PATIENT_REGISTRATION_INTENT, {
        variables:
          checkEligibilityArg as CheckEligibilityForPatientRegistrationIntentVariables,
      })
    default:
      throw new Error('Invalid checkEligibilityArg')
  }
}

function isAppointmentCheck(
  variables: CheckEligibilityVariables
): variables is CheckEligibilityForAppointmentVariables {
  return (
    (variables as CheckEligibilityForAppointmentVariables).appointmentId !==
    undefined
  )
}

function isInsuranceCheck(
  variables: CheckEligibilityVariables
): variables is CheckEligibilityForInsuranceVariables {
  return (
    (variables as CheckEligibilityForInsuranceVariables).insuranceCoverageId !==
    undefined
  )
}

function isPatientCheck(
  variables: CheckEligibilityVariables
): variables is CheckEligibilityForPatientVariables {
  return (
    (variables as CheckEligibilityForPatientVariables).patientId !== undefined
  )
}

function isPatientRegistrationIntentCheck(
  variables: CheckEligibilityVariables
): variables is CheckEligibilityForPatientRegistrationIntentVariables {
  return (
    (variables as CheckEligibilityForPatientRegistrationIntentVariables)
      .patientRegistrationIntentId !== undefined
  )
}
