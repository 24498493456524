const QuoteIcon = ({ className }) => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.3332 9.16634C11.7139 9.16634 12.8332 8.04705 12.8332 6.66634V4.99976M12.8332 4.99976V2.16507C12.8332 1.69927 12.8332 1.46601 12.7424 1.28792C12.6625 1.13112 12.5347 1.00373 12.3779 0.923836C12.1997 0.833008 11.9666 0.833008 11.4999 0.833008H9.99992C9.53321 0.833008 9.29968 0.833008 9.12142 0.923836C8.96462 1.00373 8.83723 1.13112 8.75733 1.28792C8.6665 1.46618 8.6665 1.69971 8.6665 2.16642V3.66642C8.6665 4.13313 8.6665 4.36616 8.75733 4.54442C8.83723 4.70122 8.96462 4.82904 9.12142 4.90894C9.2995 4.99967 9.53275 4.99967 9.99855 4.99967L12.8332 4.99976ZM2.83317 9.16634C4.21388 9.16634 5.33317 8.04705 5.33317 6.66634L5.33317 4.99976M5.33317 4.99976V2.16506C5.33317 1.69926 5.33317 1.46601 5.24243 1.28792C5.16254 1.13112 5.03472 1.00373 4.87792 0.923836C4.69966 0.833008 4.46663 0.833008 3.99992 0.833008H2.49992C2.03321 0.833008 1.79968 0.833008 1.62142 0.923836C1.46462 1.00373 1.33723 1.13112 1.25733 1.28792C1.1665 1.46618 1.1665 1.69971 1.1665 2.16642V3.66642C1.1665 4.13313 1.1665 4.36616 1.25733 4.54442C1.33723 4.70122 1.46462 4.82904 1.62142 4.90894C1.7995 4.99967 2.03275 4.99967 2.49855 4.99967L5.33317 4.99976Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default QuoteIcon
