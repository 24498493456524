import { GetSelfSchedulingState } from 'types/graphql'

import { useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

const QUERY = gql`
  query GetSelfSchedulingState($schedulingCaseNotificationId: String!) {
    selfSchedulingState(
      schedulingCaseNotificationId: $schedulingCaseNotificationId
    ) {
      id
      schedulingCase {
        id
        patientMessage
        firstAvailableTargetDate
        lastAvailableTargetDate
        appointmentDefinitions {
          id
          type
        }
        targetDate {
          id
          date
          dateEnd
          value
          valueEnd
          unit
          unitEnd
          prefix
          rangeType
        }
        location {
          id
          timezone
          description
          name
          phoneNumber
          address {
            id
            line1
            line2
            city
            postalCode
            state
            country
            updatedAt
          }
          tenantId
        }
        patient {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
          birthDate
        }

        newAppointment {
          id
          start
          practitioner {
            id
            namePrefix
            givenName
            middleName
            familyName
            nameSuffix
          }
        }
      }
    }
  }
`

export function useSelfSchedulingState() {
  const { notificationId } = useParams()

  return useQuery<GetSelfSchedulingState>(QUERY, {
    variables: { schedulingCaseNotificationId: notificationId },
  })
}
