import { useEffect } from 'react'

import { DocumentTextIcon } from '@heroicons/react/24/solid'
import { useParams } from 'react-router-dom'

import { useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'

import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import WysiwygField from 'src/components/atoms/WysiwygField/WysiwygField'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { UPDATE_PATIENT_DEMOGRAPHICS_MUTATION } from 'src/pages/PatientChartsPage/PatientDemographics/usePatient'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const GET_PRACTICE_COMMENT_FOR_PATIENT_QUERY = gql`
  query GetPracticeCommentForPatient($id: String!) {
    patient(id: $id) {
      id
      practiceComment
    }
  }
`

const SidepanelPatientPracticeComment = () => {
  const { patientId } = useParams()
  const { closeSidePanel } = useSidepanel()

  const { data, loading } = useQuery(GET_PRACTICE_COMMENT_FOR_PATIENT_QUERY, {
    variables: { id: patientId },
    skip: !patientId,
  })

  const [updatePracticeComment, { loading: updatingPracticeComment }] =
    useMutation(UPDATE_PATIENT_DEMOGRAPHICS_MUTATION)

  const formMethods = useForm({
    defaultValues: data?.patient?.practiceComment,
  })
  const {
    formState: { isDirty },
  } = formMethods

  useEffect(() => {
    formMethods.reset({ practiceComment: data?.patient?.practiceComment })
  }, [formMethods, data])

  const onSubmit = (input) => {
    updatePracticeComment({
      variables: {
        id: patientId,
        input,
      },
      onCompleted: () => closeSidePanel(),
    })
  }

  return (
    <SidepanelPage
      header="Practice comments"
      subHeader="This internal comment regarding the patient is shared among all practice staff. Any practice staff member can view and edit the comment."
      subHeaderTextStyle="description"
      subHeaderFontSize="s"
      loading={loading}
    >
      <SidepanelForm
        footerProps={{
          disabled: !isDirty,
          submitText: 'Save',
          submitting: updatingPracticeComment,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <StackView space={25} className="py-4">
          <Typography textStyle="description" className="pb-2">
            To open up the practice comment again, click on the{' '}
            <DocumentTextIcon className="inline-block h-4 w-4" /> icon at the
            top right side of the patient header.
          </Typography>
          <Typography fontWeight="medium" color="text-base-color-fg-muted">
            Comment
          </Typography>
          <WysiwygField
            testId="practice-comment-wysiwyg"
            name="practiceComment"
            defaultValue={data?.patient?.practiceComment}
            loading={loading}
          />
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelPatientPracticeComment
