import { SelectFieldProps } from '@redwoodjs/forms/dist/SelectField'

import SelectField from 'src/components/atoms/SelectField/SelectField'
import { clinicalResultTypes } from 'src/data/clinicalResultTypes'

export const ClinicalResultTypeSelectField = (props: SelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={clinicalResultTypes.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default ClinicalResultTypeSelectField
