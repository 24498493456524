import { useParams } from 'react-router-dom'

import Box from 'src/components/atoms/Box'
import Typography from 'src/components/atoms/Typography'
import CreateExternalLabOrder from 'src/components/CreateExternalLabOrder/CreateExternalLabOrder'
import FeatureFlagged from 'src/components/molecules/FeatureFlagged/FeatureFlagged'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import OrderSidepanelForm from 'src/components/OrderSidepanelForm/OrderSidepanelForm'
import { orderCategoryConfig } from 'src/data/orderCategories'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

const SidepanelVisitOrderCreate = () => {
  const { patientId, isPatientChart } = useIsPatientChart()
  const { encounterId, category } = useParams()
  const config = orderCategoryConfig[category]

  if (!isPatientChart) {
    return <SidepanelNotAvailable />
  }

  if (!config) {
    return (
      <SidepanelPage testId="sidepanel-order-create" header="Create order">
        <Box padding={50}>
          <Typography textStyle="title">Invalid order category</Typography>
        </Box>
      </SidepanelPage>
    )
  }

  return (
    <SidepanelPage
      testId="sidepanel-order-create"
      header={`Create ${config.display.toLowerCase()} order`}
    >
      {category === 'HG_LAB' ? (
        <FeatureFlagged
          flagName="HEALTH_GORILLA"
          childrenIfFlagNotActive={<Box padding={50}>NOT ALLOWED</Box>}
        >
          <CreateExternalLabOrder
            patientId={patientId}
            encounterId={encounterId === 'undefined' ? undefined : encounterId}
          />
        </FeatureFlagged>
      ) : (
        <OrderSidepanelForm
          patientId={patientId}
          encounterId={encounterId === 'undefined' ? undefined : encounterId}
          category={config.category}
        />
      )}
    </SidepanelPage>
  )
}

export default SidepanelVisitOrderCreate
