import { useParams } from 'react-router-dom'
import {
  CreateMobileLoginForPortalUser,
  CreateMobileLoginForPortalUserInput,
  CreateMobileLoginForPortalUserVariables,
} from 'types/graphql'

import { FormError, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import PhoneInputField from 'src/components/atoms/PhoneInputField/PhoneInputField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { refetchQueries } from 'src/pages/FamilyPortalAdminPage/FamilyPortalAdminPage'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const CREATE_MOBILE_LOGIN_FOR_PORTAL_USER = gql`
  mutation CreateMobileLoginForPortalUser(
    $id: String!
    $input: UpdateContactInformationInput!
  ) {
    updatePatientContactInformation(id: $id, input: $input) {
      id
      contactInformation {
        id
        mobileNumber
      }
    }
  }
`
const SidepanelPortalUserAddLoginDetails = () => {
  const { patientId } = useParams()
  const { closeSidePanel } = useSidepanel()

  const formMethods = useForm<CreateMobileLoginForPortalUserInput>()

  const [createMobileLoginForPortalUser, { loading, error }] = useMutation<
    CreateMobileLoginForPortalUser,
    CreateMobileLoginForPortalUserVariables
  >(CREATE_MOBILE_LOGIN_FOR_PORTAL_USER)

  return (
    <SidepanelPage
      header="Add mobile login details"
      description="Please fill out all mandatory fields."
    >
      <SidepanelForm
        formMethods={formMethods}
        onSubmit={(data) => {
          void createMobileLoginForPortalUser({
            variables: {
              id: patientId,
              input: {
                mobileNumber: data.mobileNumber,
              },
            },
            onCompleted: () => {
              closeSidePanel()
              toast.success('Login details added successfully')
            },
            refetchQueries,
          })
        }}
        footerProps={{
          submitText: 'Save',
          cancelText: 'Cancel',
          submitting: loading || formMethods.formState.isSubmitting,
        }}
      >
        <StackView gap={150} className="py-core-space-100">
          <StackView gap={25}>
            <Typography textStyle="title-xs">Login details</Typography>

            <Typography
              textStyle="interface-default-xs"
              color="text-base-color-fg-subtle"
            >
              Ensure that the mobile phone number is unique for this account. If
              there is a duplicate phone number, you can resolve the issue by
              changing this mobile number.
            </Typography>
          </StackView>

          {error ? (
            <FormError
              wrapperClassName="bg-base-color-bg-danger px-core-space-100 py-core-space-75 rounded-md"
              titleClassName="text-base-color-fg-danger my-0"
              error={error}
            />
          ) : null}

          <FormInputList
            className="py-0"
            items={[
              {
                name: 'mobileNumber',
                label: 'Mobile number',
                direction: 'col',
                required: true,
                formInputComponent: PhoneInputField,
              },
            ]}
          />
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelPortalUserAddLoginDetails
