import { useEffect } from 'react'

import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { useParams } from 'react-router-dom'
import {
  Allergen,
  UpsertAllergyInput,
  FindPatientAllergies,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'

import AllergyManifestationsSelectField from 'src/components/atoms/AllergyManifestationSelectField/AllergyManifestationSelectField'
import AllergySeveritySelectField from 'src/components/atoms/AllergySeveritySelectField/AllergySeveritySelectField'
import AllergyStatusSelectField from 'src/components/atoms/AllergyStatusSelectField/AllergyStatusSelectField'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import InputField from 'src/components/atoms/InputField/InputField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import {
  useAllergiesQuery,
  useUpsertAllergy,
} from 'src/pages/PatientChartsPage/PatientHistory/Allergies/useAllergies'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

type FormState = UpsertAllergyInput & {
  allergenDisplayText: string
  allergen: Allergen
}

const SidepanelAllergyEdit = () => {
  const { patientId, allergenCode } = useParams()
  const { closeSidePanel } = useSidepanel()
  const formMethods = useForm<FormState>()

  const { allergies } = useAllergiesQuery(patientId)
  const [upsertAllergy, { loading: upsertingAllergy }] = useUpsertAllergy()

  const onSubmit = async (data: FormState) => {
    const input = {
      patientId,
      allergenCode: data.allergen.code,
      name: data.allergen.name,
      system: data.allergen.system,
      doseSpotGenericDrugCode: data.allergen.doseSpotGenericDrugCode,
      ...omit(data, ['allergen', 'allergenDisplayText']),
    }
    upsertAllergy({ variables: { input }, onCompleted: closeSidePanel })
  }

  useEffect(() => {
    if (isEmpty(allergies)) return

    let existingAllergy:
      | FindPatientAllergies['patient']['allergies'][0]
      | undefined

    if (allergenCode) {
      existingAllergy = allergies.find((allergy) => {
        return allergy.allergen.code === allergenCode
      })

      if (existingAllergy) {
        formMethods.setValue(
          'allergenDisplayText',
          `${existingAllergy.allergen.name} (${existingAllergy.allergen.category})`
        )
        formMethods.setValue('allergen', existingAllergy.allergen)
        formMethods.setValue('status', existingAllergy.status)
        formMethods.setValue('severity', existingAllergy.severity)
        formMethods.setValue(
          'manifestation',
          existingAllergy.manifestation?.code
        )
      }
    }
  }, [allergenCode, allergies, formMethods])

  return (
    <SidepanelPage header="Edit allergy">
      <SidepanelForm
        footerProps={{
          submitting: upsertingAllergy,
          submitText: 'Update',
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <FormInputList
          items={[
            {
              name: 'allergenDisplayText',
              label: 'Allergen',
              formInputComponent: InputField,
              inputProps: {
                disabled: true,
              },
            },
            {
              name: 'status',
              label: 'Status',
              required: true,
              inputProps: {
                includeEmptyOption: false,
              },
              formInputComponent: AllergyStatusSelectField,
            },
            {
              name: 'severity',
              label: 'Severity',
              formInputComponent: AllergySeveritySelectField,
            },
            {
              name: 'manifestation',
              label: 'Manifestation',
              formInputComponent: AllergyManifestationsSelectField,
            },
            {
              name: 'enteredInError',
              label: 'Mark this record as entered in error',
              formInputComponent: CheckboxField,
              message:
                'Marking this record as entered in error will archive and remove it from the chart.',
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelAllergyEdit
