import { useParams } from 'react-router-dom'

import UserCell from 'src/components/User/UserCell'

const SidepanelUserDisplay = () => {
  const { id } = useParams()
  return <UserCell id={id} />
}

export default SidepanelUserDisplay
