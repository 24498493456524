import { useEffect } from 'react'

import { datadogRum } from '@datadog/browser-rum'

import { useEmrAuth, usePatientPortalAuth } from 'src/auth'
import { appType } from 'src/utils/appType'

const useSyncEmrUserToDatadog = (): void => {
  const { currentUser } = useEmrAuth()

  const userId = currentUser?.id
  const email = currentUser?.email
  const tenantId = currentUser?.tenantId

  useEffect(() => {
    if (userId) {
      datadogRum.setUser({
        id: userId,
        email,
        tenantId,
        application: 'emr',
      })
    } else {
      datadogRum.clearUser()
    }
  }, [userId, email, tenantId])
}

const useSyncPortalUserToDatadog = (): void => {
  const { currentUser } = usePatientPortalAuth()

  const userId = currentUser?.id
  const mobileNumber = currentUser?.mobileNumber
  const tenantId = currentUser?.tenantId

  useEffect(() => {
    if (userId) {
      datadogRum.setUser({
        id: userId,
        mobileNumber,
        tenantId,
        application: 'portal',
      })
    } else {
      datadogRum.clearUser()
    }
  }, [userId, mobileNumber, tenantId])
}

export const useSyncUserToDatadog =
  appType === 'patientPortal'
    ? useSyncPortalUserToDatadog
    : useSyncEmrUserToDatadog
