export const getVisibleCaseInboxItems = () =>
  Array.from(
    document.querySelectorAll<HTMLElement>('[data-testid="case-inbox-item"]')
  )

export const selectPreviousTask = ({ caseId }: { caseId: string }) => {
  const caseInboxItems = getVisibleCaseInboxItems()

  const currentIndex = caseInboxItems.findIndex(
    (element) => element.id === `case-inbox-item-${caseId}`
  )

  const previousIndex =
    currentIndex !== -1 ? (currentIndex - 1) % caseInboxItems.length : 0

  const previousCase = caseInboxItems[previousIndex]

  previousCase.click()
}

export const selectNextTask = ({ caseId }: { caseId: string }) => {
  const caseInboxItems = getVisibleCaseInboxItems()

  const currentIndex = caseInboxItems.findIndex(
    (element) => element.id === `case-inbox-item-${caseId}`
  )

  const nextIndex =
    currentIndex !== -1 ? (currentIndex + 1) % caseInboxItems.length : 0

  const nextCase = caseInboxItems[nextIndex]

  nextCase.click()
}

export interface TaskAssigneeFilter {
  type: 'user' | 'pool'
  id: string
}

export const serializeTaskAssignee = ({ type, id }: TaskAssigneeFilter) =>
  [type, id].join(':')

export const parseTaskAssignee = (
  str: string
): TaskAssigneeFilter | undefined => {
  const parts = str.split(':')

  if (parts.length !== 2) return

  const [type, id] = parts

  if (type !== 'user' && type !== 'pool') return

  return {
    type,
    id,
  }
}
