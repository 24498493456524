import { Task, TaskType } from './types'

export const getTaskType = (task: Task): TaskType => {
  if (task.schedulingCase) return 'SchedulingCase'
  if (task.staffMessageCase) return 'StaffMessageCase'
  if (task.documentCase) return 'DocumentCase'
  if (task.screeningCase) return 'ScreeningCase'
  if (task.resultsReviewCase) return 'ResultsReviewCase'
  if (task.diagnosticReportReviewCase) return 'DiagnosticReportReviewCase'
  if (task.familyMessageCase) return 'FamilyMessageCase'
}

export const displayTaskType = (task: Task): string => {
  if (task.schedulingCase) return 'Scheduling'
  if (task.staffMessageCase) return 'Staff message'
  if (task.documentCase) return 'Document'
  if (task.screeningCase) return 'Screening'
  if (task.resultsReviewCase) return 'Results review'
  if (task.diagnosticReportReviewCase) return 'Results review'
  if (task.familyMessageCase) return 'Family message'
}

export const getCode = (task: Task): number => {
  if (task.schedulingCase) return task.schedulingCase.code
  if (task.staffMessageCase) return task.staffMessageCase.code
  if (task.documentCase) return task.documentCase.code
  if (task.screeningCase) return task.screeningCase.code
  if (task.resultsReviewCase) return task.resultsReviewCase.code
  if (task.diagnosticReportReviewCase)
    return task.diagnosticReportReviewCase.code
  if (task.familyMessageCase) return task.familyMessageCase.code
}

export const getResolvedAt = (task: Task): string => {
  if (task.schedulingCase) return task.schedulingCase.resolvedAt
  if (task.staffMessageCase) return task.staffMessageCase.resolvedAt
  if (task.documentCase) return task.documentCase.resolvedAt
  if (task.screeningCase) return task.screeningCase.resolvedAt
  if (task.resultsReviewCase) return task.resultsReviewCase.resolvedAt
  if (task.diagnosticReportReviewCase)
    return task.diagnosticReportReviewCase.resolvedAt
  if (task.familyMessageCase) return task.familyMessageCase.resolvedAt
}

export const getResolvedStatus = (task: Task): string => {
  if (task.schedulingCase) return task.schedulingCase.resolvedStatus
  if (task.staffMessageCase) return task.staffMessageCase.resolvedStatus
  if (task.documentCase) return task.documentCase.resolvedStatus
  if (task.screeningCase) return task.screeningCase.resolvedStatus
  if (task.resultsReviewCase) return task.resultsReviewCase.resolvedStatus
  if (task.diagnosticReportReviewCase)
    return task.diagnosticReportReviewCase.resolvedStatus
  if (task.familyMessageCase) return task.familyMessageCase.resolvedStatus
}

const getTaskId = (task: Task): string => {
  if (task.schedulingCase) return task.schedulingCase.id
  if (task.staffMessageCase) return task.staffMessageCase.id
  if (task.documentCase) return task.documentCase.id
  if (task.screeningCase) return task.screeningCase.id
  if (task.resultsReviewCase) return task.resultsReviewCase.id
  if (task.diagnosticReportReviewCase) return task.diagnosticReportReviewCase.id
  if (task.familyMessageCase) return task.familyMessageCase.id
}

const getCasesPageBaseGlob = (task: Task): string => {
  if (task.schedulingCase) return 'schedule'
  if (task.staffMessageCase) return 'staff-message'
  if (task.documentCase) return 'document'
  if (task.screeningCase) return 'screening'
  if (task.resultsReviewCase) return 'results-review'
  if (task.diagnosticReportReviewCase) return 'results-review'
  if (task.familyMessageCase) return 'family-message'
}

export const getCasesPageGlob = (task: Task): string =>
  `${getCasesPageBaseGlob(task)}/${getTaskId(task)}/details`
