import clsx from 'clsx'

import StackView from 'src/components/atoms/StackView'

import { PaginationControls } from '../PaginationControls/PaginationControls'

const CustomPaginationPanel = ({
  page,
  totalPages,
  onClickPaginationButton,
}: {
  page: number
  totalPages: number
  onClickPaginationButton: (page: number) => void
}) => {
  return (
    <StackView
      testId="custom-pagination-panel"
      direction="row"
      alignItems="center"
      justifyContent="between"
      className={clsx([
        'absolute',
        'border-x-base-border-width-selectable-s',
        'border-b-base-border-width-selectable-s',
        'border-base-color-border-subtle',
        'rounded-b-base-border-radius-container-m',
        'p-core-space-100',
      ])}
    >
      <PaginationControls
        page={page}
        totalPages={totalPages}
        onClickPaginationButton={onClickPaginationButton}
      />
    </StackView>
  )
}

export default CustomPaginationPanel
