import type { GetLocationsDropdown } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { DropdownField } from 'src/components/atoms/Dropdown/Dropdown'
import { Option } from 'src/components/atoms/Select/Select'

export const QUERY = gql`
  query GetLocationsDropdown {
    locations {
      id
      name
      description
      availabilityTimes {
        id
        locationId
        weekday
        configuration
        practitionerId
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps<GetLocationsDropdown>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

interface LocationDropdownCellProps {
  name: string
  includeLocationAvailabilityTimes?: boolean
  onSelection?: (opt: Option) => void
  prependedOptions?: Option[]
}

export const Success = ({
  locations,
  name,
  includeLocationAvailabilityTimes,
  onSelection,
  prependedOptions = [],
  ...rest
}: CellSuccessProps<GetLocationsDropdown> & LocationDropdownCellProps) => {
  const options = locations.map((location) => {
    if (includeLocationAvailabilityTimes) {
      return {
        value: location.id,
        ...location,
      }
    } else {
      return {
        name: location.name,
        value: location.id,
      }
    }
  })

  prependedOptions.forEach((option) => {
    options.unshift(option)
  })

  return (
    <DropdownField
      name={name}
      options={options}
      mode="id"
      onSelection={onSelection}
      {...rest}
    />
  )
}
