import {
  GetPatientDefaultPharmacy,
  GetPatientDefaultPharmacyVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

export const PHARMACY_FRAGMENT = gql`
  fragment PharmacyFragment on Pharmacy {
    id
    isDefault
    name
    address1
    address2
    city
    state
    postalCode
    phone
    fax
  }
`

const QUERY = gql`
  query GetPatientDefaultPharmacy($id: String!) {
    patient(id: $id) {
      id
      defaultPharmacy {
        ...PharmacyFragment
      }
    }
  }
  ${PHARMACY_FRAGMENT}
`

export const useRefetchPatientPharmacy = (patientId: string) => {
  const { refetch } = useQuery<
    GetPatientDefaultPharmacy,
    GetPatientDefaultPharmacyVariables
  >(QUERY, {
    variables: { id: patientId },
    skip: false,
  })
  return refetch
}

export default useRefetchPatientPharmacy
