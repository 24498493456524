import { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import { LocalDate, LocalTime, ZoneId, convert } from '@js-joda/core'
import { enumToCode } from 'common/data/timezones'
import { lastSchedulableDate } from 'common/scheduling'
import { addMinutes, isAfter, parse } from 'date-fns'
import { useParams } from 'react-router-dom'
import {
  ChiefComplaint,
  GetLocationForSchedulingCaseResolution,
  GetLocationForSchedulingCaseResolutionVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import {
  AppointmentBookingsWarningProvider,
  useAppointmentBookingWarningsContext,
} from 'src/components/AppointmentBookingWarningModals/AppointmentBookingWarningModals'
import AppointmentDefinitionComboBoxCell from 'src/components/AppointmentDefinition/AppointmentDefinitionComboBoxCell'
import ChiefComplaintSelectField from 'src/components/atoms/ChiefComplaintSelectField/ChiefComplaintSelectField'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import LocationDropdownCell from 'src/components/Location/LocationDropdownCell'
import { DatePickerField } from 'src/components/molecules/DatePicker/DatePicker'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import PractitionerDropdownCell from 'src/components/Practitioner/PractitionerDropdownCell'
import { useHandleCaseResolution } from 'src/hooks/useHandleCaseResolution/useHandleCaseResolution'
import {
  useScheduleFilter,
  useUpdateFilter,
} from 'src/hooks/useScheduleFilter/useScheduleFilter'
import { useAppointmentManager } from 'src/providers/context/AppointmentsManagementContext'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

interface ReasonForVisit {
  visitType: string[]
  visitComment: string
  chiefComplaints?: ChiefComplaint[]
}
export interface ResolveSchedulingCaseData {
  date: Date
  timeStart: string
  practitioner: string
  location: string
  reason: ReasonForVisit
  patient?: never
  registrationIntent?: never
}

const GET_LOCATION_QUERY = gql`
  query GetLocationForSchedulingCaseResolution($id: String!) {
    location(id: $id) {
      id
      name
      timezone
    }
  }
`

const RESOLVE_SCHEDULING_CASE_MUTATION = gql`
  mutation ResolveSchedulingCaseMutation(
    $id: String!
    $input: ResolveScheduleCaseInput!
  ) {
    resolveSchedulingCase(input: $input, id: $id) {
      resolvedAt
      priorAppointment {
        visitComment
      }
      code
      resolvedBy {
        id
        givenName
        familyName
      }
      resolvedStatus
      patientRegistrationIntent {
        id
        givenName
        familyName
        email
        phoneNumber
      }
      orderPractitioner {
        id
        givenName
        familyName
      }
      visitPractitioner {
        id
        givenName
        familyName
      }
      targetTime
      targetTimeUnit
      patient {
        id
        givenName
        familyName
      }
      appointmentDefinitions {
        id
        name
        duration
        code
      }
      chiefComplaints
      location {
        id
        name
        description
      }
      baseCase {
        id
        createdAt
        createdBy {
          id
        }
      }
      patient {
        id
        contactInformation {
          id
          mobileNumber
          homeAddress {
            id
            line1
            line2
            city
            state
            postalCode
          }
        }
        patientRelatedPersonRelationships {
          id
          doesResideWith
          relationshipType
          relatedPerson {
            id
            namePrefix
            givenName
            middleName
            familyName
            nameSuffix
            contactInformation {
              id
              mobileNumber
              emailAddress
            }
          }
        }
      }
    }
  }
`

const SidepanelSchedulingCaseResolutionHelper = () => {
  const [getLocation] = useLazyQuery<
    GetLocationForSchedulingCaseResolution,
    GetLocationForSchedulingCaseResolutionVariables
  >(GET_LOCATION_QUERY)
  const { checkForAppointmentBookingWarnings } =
    useAppointmentBookingWarningsContext()
  const { closeSidePanel, isSidepanelOpen } = useSidepanel()
  const { schedulingCaseId } = useParams()
  const { formMethods, hasOverlapWithInvalid, appointmentDefinitionDurations } =
    useAppointmentManager()

  const { visitDate: urlVisitDate } = useScheduleFilter()
  const updateFilter = useUpdateFilter()

  useEffect(() => {
    formMethods.setValue('date', urlVisitDate)
  }, [formMethods, urlVisitDate])

  useEffect(() => {
    if (!isSidepanelOpen) {
      closeSidePanel()
    }
  }, [isSidepanelOpen, formMethods, closeSidePanel])

  const { handleCaseResolution, getNextTaskId } = useHandleCaseResolution()
  const [resolveSchedulingCase, { loading: resolving }] = useMutation(
    RESOLVE_SCHEDULING_CASE_MUTATION
  )

  const visitType = formMethods.watch('reason.visitType')

  const cancelCase = () => {
    void resolveSchedulingCase({
      variables: {
        id: schedulingCaseId,
        input: {
          cancelVisit: true,
        },
      },
      refetchQueries: ['FindSchedulingCases', 'GetNotResolvedCasesCountByType'],
      onCompleted: () => {
        closeSidePanel()
      },
    })
  }

  const onSubmit = async (newAppointmentData: ResolveSchedulingCaseData) => {
    formMethods.clearErrors('date')
    const duration = (newAppointmentData.reason.visitType || []).reduce(
      (acc, appointmentDefinitionId) => {
        if (!appointmentDefinitionId) {
          return acc
        }
        const result =
          acc + appointmentDefinitionDurations[appointmentDefinitionId]
        return result
      },
      0
    )

    const startOfAppointment = parse(
      newAppointmentData.timeStart,
      'h:mm a',
      newAppointmentData.date
    )

    if (
      hasOverlapWithInvalid({
        start: startOfAppointment,
        end: addMinutes(startOfAppointment, duration),
        resource: `${newAppointmentData.practitioner}-${newAppointmentData.location}`,
      })
    ) {
      return formMethods.setError(
        'date',
        {
          message:
            'The practitioner is not available at the selected timeslot.',
        },
        {
          shouldFocus: true,
        }
      )
    }

    if (isAfter(newAppointmentData.date, lastSchedulableDate())) {
      return formMethods.setError('date', {
        message:
          'Visits can not be scheduled more than 2 years in advance from today’s date',
      })
    }

    // if there is an undefined visit type
    if (newAppointmentData.reason.visitType.some((value) => !value)) {
      // we should show an error message
      return formMethods.setError('reason.visitType', {
        message: 'Please select at least one visit type',
      })
    }

    const { confirmed } = await checkForAppointmentBookingWarnings({
      locationId: newAppointmentData.location,
      practitionerId: newAppointmentData.practitioner,
      visitTypes: newAppointmentData.reason.visitType,
      date: newAppointmentData.date,
      startTime: newAppointmentData.timeStart,
    })
    if (!confirmed) return

    const locationResult = await getLocation({
      variables: {
        id: newAppointmentData.location,
      },
    })
    const { location } = locationResult.data

    const [dateString] = newAppointmentData.date.toISOString().split('T')
    const appointmentDate = LocalDate.parse(dateString)
    const appointmentTime = LocalTime.of(
      startOfAppointment.getHours(),
      startOfAppointment.getMinutes()
    )

    const locationTimezone = ZoneId.of(enumToCode[location.timezone])
    const start = appointmentDate
      .atTime(appointmentTime)
      .atZone(locationTimezone)

    const nextTaskId = await getNextTaskId({ taskId: schedulingCaseId })

    void resolveSchedulingCase({
      variables: {
        id: schedulingCaseId,
        input: {
          startTime: convert(start).toDate(),
          practitionerId: newAppointmentData.practitioner,
          locationId: newAppointmentData.location,
          appointmentDefinitionIds: newAppointmentData.reason.visitType,
          chiefComplaints:
            newAppointmentData.reason.chiefComplaints?.filter(Boolean) ?? [],
          visitComment: newAppointmentData.reason.visitComment,
        },
      },
      refetchQueries: ['FindSchedulingCases', 'GetNotResolvedCasesCountByType'],
      onCompleted: () => {
        void handleCaseResolution({ nextTaskId })
      },
    })
  }

  return (
    <SidepanelPage
      header="Resolve task"
      description="Check the options that apply before resolving the task."
    >
      <SidepanelForm
        autoComplete="off"
        formMethods={formMethods}
        onSubmit={onSubmit}
        footerProps={{
          cancelText: 'Cancel task',
          onCancel: () => cancelCase(),
          submitText: 'Resolve task',
          disabled: formMethods.formState.isSubmitting || resolving,
          submitting: formMethods.formState.isSubmitting || resolving,
        }}
      >
        <FormInputList
          title="Visit details"
          subtitle="Complete these mandatory details."
          items={[
            {
              name: 'date',
              label: 'Date',
              required: true,
              inputProps: {
                onChange: (e) => {
                  updateFilter('scheduleDateFilter', e.value, {
                    saveSettings: false,
                  })
                },
              },
              formInputComponent: DatePickerField,
            },
            {
              name: 'timeStart',
              label: 'Start time',
              required: true,
              inputProps: {
                // not sure why this field isn't getting picked up, need to revisit InputProps definition
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                controls: ['time'],
                stepMinute: 5,
                emptyAs: null,
                validation: {
                  required: true,
                },
              },
              formInputComponent: DatePickerField,
            },
            {
              name: 'practitioner',
              label: 'Practitioner',
              formInputComponent: PractitionerDropdownCell,
              required: true,
            },
            {
              name: 'location',
              label: 'Location',
              formInputComponent: LocationDropdownCell,
              required: true,
            },
          ]}
        />
        <FormInputList
          title="Reason for visit"
          subtitle="Complete these mandatory details."
          items={[
            {
              name: 'reason.visitType',
              label: 'Visit type(s)',
              alignItems: 'start',
              formInputComponent: AppointmentDefinitionComboBoxCell,
              required: true,
              inputProps: {
                onChange: () => formMethods.clearErrors('reason.visitType'),
              },
            },
            {
              label: 'Chief complaints',
              name: 'reason.chiefComplaints',
              formInputComponent: ChiefComplaintSelectField,
              inputProps: {
                appointmentDefinitionId: visitType?.[0],
                onChange: () =>
                  formMethods.clearErrors('reason.chiefComplaints'),
              },
            },
            {
              name: 'reason.visitComment',
              label: 'Visit comments',
              alignItems: 'start',
              formInputComponent: TextAreaField,
              inputProps: {
                rows: 6,
              },
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

const SidepanelSchedulingCaseResolution = () => (
  <AppointmentBookingsWarningProvider>
    <SidepanelSchedulingCaseResolutionHelper />
  </AppointmentBookingsWarningProvider>
)

export default SidepanelSchedulingCaseResolution
