import { DirectPatientChargeBillingCodes } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

const DIRECT_PATIENT_CHARGE_BILLING_CODES_QUERY = gql`
  query DirectPatientChargeBillingCodes {
    directPatientChargeBillingCodes {
      id
      code
      procedureCode
      diagnosisCode
      description
      amountCents
    }
  }
`

export const useDirectPatientChargeBillingCodesQuery = () => {
  const { data, loading, error } = useQuery<DirectPatientChargeBillingCodes>(
    DIRECT_PATIENT_CHARGE_BILLING_CODES_QUERY
  )

  const billingCodes = data?.directPatientChargeBillingCodes

  return {
    billingCodes,
    loading,
    error,
  }
}
