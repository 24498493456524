export const codeGeneration = (caseObj: {
  __typename:
    | 'SchedulingCase'
    | 'DocumentCase'
    | 'StaffMessageCase'
    | 'ResultsReviewCase'
    | 'DiagnosticReportReviewCase'
    | 'ScreeningCase'
    | 'FamilyMessageCase'
  code: number
}) => {
  switch (caseObj.__typename) {
    case 'SchedulingCase':
      return `T-SCH${`${caseObj.code}`.padStart(6, '0')}`
    case 'DocumentCase':
      return `T-DOC${`${caseObj.code}`.padStart(6, '0')}`
    case 'StaffMessageCase':
      return `T-SFM${`${caseObj.code}`.padStart(6, '0')}`
    case 'ResultsReviewCase':
      return `T-CRR${`${caseObj.code}`.padStart(6, '0')}`
    case 'DiagnosticReportReviewCase':
      return `T-DRR${`${caseObj.code}`.padStart(6, '0')}`
    case 'ScreeningCase':
      return `T-SCN${`${caseObj.code}`.padStart(6, '0')}`
    case 'FamilyMessageCase':
      return `T-FYM${`${caseObj.code}`.padStart(6, '0')}`
  }
}
