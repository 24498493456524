import { useParams } from 'react-router-dom'

import { useConditionsQuery } from 'src/pages/PatientChartsPage/PatientHistory/Diagnoses/useConditions'
import SidepanelConditionCreate from 'src/pages/Sidepanel/SidepanelConditionCreate/SidepanelConditionCreate'

const SidepanelDiagnosisCreate = () => {
  const { patientId } = useParams()
  const { conditionsGroupedByCode } = useConditionsQuery(patientId, 'DIAGNOSIS')

  return (
    <SidepanelConditionCreate
      name="diagnosis"
      nameDisplay="diagnosis"
      searchLabel="Diagnosis"
      existingConditions={conditionsGroupedByCode}
    />
  )
}

export default SidepanelDiagnosisCreate
