import { PatientDetailedBillingStatement } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { BILLING_STATEMENT_TENANT_LETTERHEAD_FRAGMENT } from 'src/pdf/fragments'

export const PATIENT_DETAILED_BILLING_STATEMENT_FRAGMENT = gql`
  fragment BillingStatementFragment on BillingStatement {
    id
    billingStatementEncounters {
      id
      responsibleParty
      patientPaidAmountCents
      patientPaidTowardsServiceLinesAmountCents
      visitDescription
      primaryServiceDescription
      primaryServiceSubDescription
      claims {
        id
        status
        chargeAmountCents
        allowedAmountCents
        paidAmountCents
        adjustmentAmountCents
        adjustmentsColumnAmountCents
        patientOwesAmountCents
        visitRemainderIsGreaterThanWhatPatientOwes
        serviceLines {
          id
          adjustmentAmountCents
          billingCode
          chargeAmountCents
          description
          paidAmountCents
          patientOwesAmountCents
          patientPaidAmountCents
        }
      }
    }
    guarantorName
    summaryBalanceCents
    summaryChargeCents
    summaryCreditCents
    promptCopy
    tenant {
      ...BillingStatementTenantLetterheadFragment
    }
    currentPaymentPlan {
      id
      termAmountCents
      termMonths
      billingDayOfMonth
      totalBalanceCents
      paymentsRemaining
      totalPaidAmountCents
      remainingBalanceCents
      successfulPaymentPlanPayments {
        id
      }
    }
  }
  ${BILLING_STATEMENT_TENANT_LETTERHEAD_FRAGMENT}
`

const PATIENT_DETAILED_BILLING_STATEMENT_QUERY = gql`
  query PatientDetailedBillingStatement($id: String!) {
    patient(id: $id) {
      id
      billingStatement {
        ...BillingStatementFragment
      }
    }
  }
  ${PATIENT_DETAILED_BILLING_STATEMENT_FRAGMENT}
`

export const usePatientDetailedBillingStatementQuery = ({
  patientId,
  skip,
}: {
  patientId: string
  skip?: boolean
}) => {
  return useQuery<PatientDetailedBillingStatement>(
    PATIENT_DETAILED_BILLING_STATEMENT_QUERY,
    {
      variables: { id: patientId },
      skip,
    }
  )
}
