import { useEffect, useMemo } from 'react'

import { isEmpty, omit } from 'lodash'
import { OrderWithObservationsFragment } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/dist/toast'

import Divider from 'src/components/atoms/Divider'
import { FieldLabel } from 'src/components/atoms/Label'
import StackView from 'src/components/atoms/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import Typography from 'src/components/atoms/Typography/Typography'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { HearingScreeningTable } from 'src/components/Order/CompleteHearingScreeningOrderSidepanelForm/HearingScreeningTable'
import {
  OverallFindingInput,
  OrderRefusal,
} from 'src/components/Order/CompleteScreeningOrderSidepanelForm/CompleteScreeningOrderSidepanelForm'
import { useCompleteHearingScreeningOrderMutation } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const hearingScreeningResultQuestionCodeToField: Record<string, string> = {
  HEARING_THRESHOLD_RIGHT_125: 'rightOneHundredTwentyFive',
  '91374-9': 'rightTwoHundredFifty',
  '89025-1': 'rightFiveHundred',
  '89017-8': 'rightOneThousand',
  '89019-4': 'rightTwoThousand',
  '89021-0': 'rightThreeThousand',
  '89023-6': 'rightFourThousand',
  '91376-4': 'rightFiveThousand',
  '89027-7': 'rightSixThousand',
  HEARING_THRESHOLD_RIGHT_7000: 'rightSevenThousand',
  '89029-3': 'rightEightThousand',
  HEARING_THRESHOLD_LEFT_125: 'leftOneHundredTwentyFive',
  '91375-6': 'leftTwoHundredFifty',
  '89024-4': 'leftFiveHundred',
  '89016-0': 'leftOneThousand',
  '89018-6': 'leftTwoThousand',
  '89020-2': 'leftThreeThousand',
  '89022-8': 'leftFourThousand',
  '91377-2': 'leftFiveThousand',
  '89026-9': 'leftSixThousand',
  HEARING_THRESHOLD_LEFT_7000: 'leftSevenThousand',
  '89028-5': 'leftEightThousand',
}

const hearingScreeningResultFieldToQuestionCode: Record<string, string> = {
  rightOneHundredTwentyFive: 'HEARING_THRESHOLD_RIGHT_125',
  rightTwoHundredFifty: '91374-9',
  rightFiveHundred: '89025-1',
  rightOneThousand: '89017-8',
  rightTwoThousand: '89019-4',
  rightThreeThousand: '89021-0',
  rightFourThousand: '89023-6',
  rightFiveThousand: '91376-4',
  rightSixThousand: '89027-7',
  rightSevenThousand: 'HEARING_THRESHOLD_RIGHT_7000',
  rightEightThousand: '89029-3',
  leftOneHundredTwentyFive: 'HEARING_THRESHOLD_LEFT_125',
  leftTwoHundredFifty: '91375-6',
  leftFiveHundred: '89024-4',
  leftOneThousand: '89016-0',
  leftTwoThousand: '89018-6',
  leftThreeThousand: '89020-2',
  leftFourThousand: '89022-8',
  leftFiveThousand: '91377-2',
  leftSixThousand: '89026-9',
  leftSevenThousand: 'HEARING_THRESHOLD_LEFT_7000',
  leftEightThousand: '89028-5',
}

const defaultHearingScreeningResults = {
  rightOneHundredTwentyFive: {
    questionCode:
      hearingScreeningResultFieldToQuestionCode.rightOneHundredTwentyFive,
    answerCode: null,
  },
  rightTwoHundredFifty: {
    questionCode:
      hearingScreeningResultFieldToQuestionCode.rightTwoHundredFifty,
    answerCode: null,
  },
  rightFiveHundred: {
    questionCode: hearingScreeningResultFieldToQuestionCode.rightFiveHundred,
    answerCode: null,
  },
  rightOneThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.rightOneThousand,
    answerCode: null,
  },
  rightTwoThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.rightTwoThousand,
    answerCode: null,
  },
  rightThreeThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.rightThreeThousand,
    answerCode: null,
  },
  rightFourThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.rightFourThousand,
    answerCode: null,
  },
  rightFiveThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.rightFiveThousand,
    answerCode: null,
  },
  rightSixThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.rightSixThousand,
    answerCode: null,
  },
  rightSevenThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.rightSevenThousand,
    answerCode: null,
  },
  rightEightThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.rightEightThousand,
    answerCode: null,
  },
  leftOneHundredTwentyFive: {
    questionCode:
      hearingScreeningResultFieldToQuestionCode.leftOneHundredTwentyFive,
    answerCode: null,
  },
  leftTwoHundredFifty: {
    questionCode: hearingScreeningResultFieldToQuestionCode.leftTwoHundredFifty,
    answerCode: null,
  },
  leftFiveHundred: {
    questionCode: hearingScreeningResultFieldToQuestionCode.leftFiveHundred,
    answerCode: null,
  },
  leftOneThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.leftOneThousand,
    answerCode: null,
  },
  leftTwoThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.leftTwoThousand,
    answerCode: null,
  },
  leftThreeThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.leftThreeThousand,
    answerCode: null,
  },
  leftFourThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.leftFourThousand,
    answerCode: null,
  },
  leftFiveThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.leftFiveThousand,
    answerCode: null,
  },
  leftSixThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.leftSixThousand,
    answerCode: null,
  },
  leftSevenThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.leftSevenThousand,
    answerCode: null,
  },
  leftEightThousand: {
    questionCode: hearingScreeningResultFieldToQuestionCode.leftEightThousand,
    answerCode: null,
  },
}

const ScreeningFindings = ({
  order,
}: {
  order: OrderWithObservationsFragment
}) => {
  if (isEmpty(order) || isEmpty(order.observationDefinitions)) return null

  return (
    <StackView space={75}>
      <Typography textStyle="title" fontWeight="semibold">
        Findings
      </Typography>
      <Divider />
      <StackView space={75}>
        <OverallFindingInput
          observationDefinition={order.observationDefinitions[0]}
          disabled={order.doNotPerform}
        />
        <Divider />
        <HearingScreeningTable
          observationDefinitions={order.observationDefinitions}
        />
      </StackView>
    </StackView>
  )
}

const ScreeningNotes = ({ order }) => {
  return (
    <StackView space={50}>
      <FieldLabel name="notes">
        <Typography textStyle="subtitle"> Notes</Typography>
      </FieldLabel>
      <TextAreaField disabled={order.doNotPerform} name="notes" rows={5} />
    </StackView>
  )
}

export const CompleteHearingScreeningOrderSidepanelForm = ({
  patientId,
  encounterId,
  order,
}: {
  patientId: string
  encounterId: string
  order: OrderWithObservationsFragment
}) => {
  const [
    completeHearingScreeningOrder,
    { loading: completingOrder, error: _completeOrderError },
  ] = useCompleteHearingScreeningOrderMutation()

  const { closeSidePanel } = useSidepanel()

  const hearingScreeningResults = useMemo(() => {
    const observations = order.observations
    const screeningObservation = observations?.[0]
    if (
      isEmpty(screeningObservation) ||
      isEmpty(screeningObservation.components)
    ) {
      return {}
    }

    const screeningResult = {}
    for (const component of screeningObservation.components) {
      const questionCode = component.code.code
      const answerCode = component.value.value

      if (hearingScreeningResultQuestionCodeToField[questionCode]) {
        screeningResult[
          hearingScreeningResultQuestionCodeToField[questionCode]
        ] = {
          questionCode,
          answerCode,
        }
      }
    }

    return { ...defaultHearingScreeningResults, ...screeningResult }
  }, [order.observations])

  const defaultFormValues = useMemo(() => {
    return {
      notes: order.observations?.[0]?.notes || '',
      overallFinding: order.code
        ? {
            questionCode: order.code,
            answerCode:
              order.observations?.find(
                (observation) => observation.coding[0].code === order.code
              )?.value.value || null,
          }
        : undefined,
      findings: !isEmpty(hearingScreeningResults)
        ? hearingScreeningResults
        : defaultHearingScreeningResults,

      refusalReasons: order.refusalReasons || {
        refusalByPatient: false,
        refusalByCaregiver: false,
        uncooperativePatient: false,
        contraindicatedForPatient: false,
        underCareOfAnotherProvider: false,
      },
    }
  }, [
    order.code,
    order.observations,
    hearingScreeningResults,
    order.refusalReasons,
  ])

  const formMethods = useForm({
    defaultValues: defaultFormValues,
  })

  const refusalReasons = formMethods.watch('refusalReasons')

  const doNotPerform = Object.values(omit(refusalReasons, '__typename')).some(
    Boolean
  )

  useEffect(() => {
    formMethods.reset(defaultFormValues)
  }, [defaultFormValues, formMethods])

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Save',
        cancelText: order.doNotPerform ? 'Close' : 'Discard changes',
        submitting: completingOrder,
        disabled: completingOrder || order.doNotPerform,
      }}
      formMethods={formMethods}
      onSubmit={async (input) => {
        await completeHearingScreeningOrder({
          variables: {
            input: {
              ...input,
              orderId: order.id,
              patientId,
              encounterId,
              refusalReasons: omit(input.refusalReasons, '__typename'),
            },
          },
          onCompleted: () => {
            toast.success('Hearing screening results updated')
            closeSidePanel()
          },
        })
      }}
    >
      <StackView space={75} className="pb-4">
        <OrderRefusal order={order} description="Screening refusal" />
        {!doNotPerform && (
          <>
            <ScreeningFindings order={order} />
            <ScreeningNotes order={order} />
          </>
        )}
      </StackView>
    </SidepanelForm>
  )
}

export default CompleteHearingScreeningOrderSidepanelForm
