import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import Tabs from 'src/components/molecules/Tabs/Tabs'

import CreateContact from './CreateContact/CreateContact'
import CreateContactOrganization from './CreateContactOrganization/CreateContactOrganization'

const SidepanelContactCreate = () => {
  return (
    <SidepanelPage
      header="Add contact"
      description="Add a new contact so you centrally store their contact information and directly e-fax documents."
    >
      <Tabs
        contained
        tabs={[
          { name: 'Contact', content: <CreateContact /> },
          { name: 'Organization', content: <CreateContactOrganization /> },
        ]}
      />
    </SidepanelPage>
  )
}

export default SidepanelContactCreate
