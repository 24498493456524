import { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import {
  FetchVisitAudioRecording,
  FetchVisitAudioRecordings,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import AreaLoader from 'src/components/atoms/AreaLoader/AreaLoader'
import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import Accordion, {
  AccordionItem,
} from 'src/components/molecules/Accordion/Accordion'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { formatTime } from 'src/lib/formatters'
import { VISIT_AUDIO_RECORDING_FRAGMENT } from 'src/pages/PatientChartsPage/PatientVisits/fragments'
import { useVisitQuery } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { VISIT_AUDIO_RECORDINGS_QUERY } from 'src/pages/PatientChartsPage/PatientVisits/VisitAudioRecorder'

const VISIT_AUDIO_RECORDING_QUERY = gql`
  query FetchVisitAudioRecording($id: String!) {
    media(id: $id) {
      ...VisitAudioRecordingFragment
    }
  }
  ${VISIT_AUDIO_RECORDING_FRAGMENT}
`

const APPLY_GENERATED_NOTE_MUTATION = gql`
  mutation ApplyGeneratedNote(
    $appointmentId: String!
    $value: String!
    $documentationType: UpdateAppointmentDocumentationType!
  ) {
    applyGeneratedNote(
      input: {
        visitId: $appointmentId
        value: $value
        documentationType: $documentationType
      }
    ) {
      id
    }
  }
`

const AudioNotes = ({ media, visitType, appointmentId }) => {
  const [applyGeneratedNote] = useMutation(APPLY_GENERATED_NOTE_MUTATION)
  const { data, startPolling } = useQuery<FetchVisitAudioRecording>(
    VISIT_AUDIO_RECORDING_QUERY,
    {
      variables: { id: media.id },
    }
  )

  const hpi = data?.media.notes.find(
    (n) => n.type === 'HISTORY_PRESENT_ILLNESS'
  )
  const developmentalSurveillance = data?.media.notes.find(
    (n) => n.type === 'DEVELOPMENTAL_SURVEILLANCE'
  )

  useEffect(() => {
    startPolling(500)
  })

  if (!data) return null

  return (
    <>
      <StackView space={75}>
        <StackView justifyContent="between" alignItems="center" direction="row">
          <Typography
            textStyle="subtitle"
            fontWeight="bold"
            color="text-base-color-fg-muted"
          >
            {visitType === 'WELL_CHILD'
              ? 'Interim history and concerns'
              : 'History of present illness'}
          </Typography>
          <Button
            text="Add to visit note"
            tooltip="Replace visit note with this content"
            tooltipPosition="bottom"
            onClick={() => {
              applyGeneratedNote({
                variables: {
                  appointmentId,
                  value: hpi.text,
                  documentationType: 'HISTORY_PRESENT_ILLNESS',
                },
                onCompleted: () => {
                  window.location.reload()
                },
              })
            }}
          />
        </StackView>
        {hpi ? (
          <Typography className="whitespace-pre-wrap">{hpi.text}</Typography>
        ) : (
          <AreaLoader size={6} text={'generating..'} />
        )}
      </StackView>

      {visitType === 'WELL_CHILD' && (
        <StackView space={25}>
          <StackView
            justifyContent="between"
            alignItems="center"
            direction="row"
          >
            <Typography
              textStyle="subtitle"
              fontWeight="bold"
              color="text-base-color-fg-muted"
            >
              Developmental surveillance
            </Typography>
            <Button
              text="Add to visit note"
              tooltip="Replace the visit note with this content"
              tooltipPosition="bottom"
              onClick={() => {
                applyGeneratedNote({
                  variables: {
                    appointmentId,
                    value: developmentalSurveillance.text,
                    documentationType: 'DEVELOPMENTAL_SURVEILLANCE',
                  },
                  onCompleted: () => {
                    window.location.reload()
                  },
                })
              }}
            />
          </StackView>
          {developmentalSurveillance ? (
            <Typography className="whitespace-pre-wrap">
              {developmentalSurveillance.text}
            </Typography>
          ) : (
            <AreaLoader size={6} text={'generating..'} />
          )}
        </StackView>
      )}

      <StackView space={75}>
        <Typography
          textStyle="subtitle"
          fontWeight="bold"
          color="text-base-color-fg-muted"
        >
          Transcription
        </Typography>
        {data.media.transcription ? (
          data.media.transcription.map((dialogueItem) => (
            <StackView
              key={dialogueItem.timestamp}
              direction="row"
              alignItems="start"
              space={50}
            >
              <Box className="w-10 min-w-[2.5rem] pt-1">
                <Box className="flex items-center justify-center rounded-full bg-gray-200 text-gray-500">
                  <Typography textStyle="description" fontWeight="semibold">
                    {formatTime(parseFloat(dialogueItem.timestamp))}
                  </Typography>
                </Box>
              </Box>
              <StackView className="text-gray-700">
                <Typography
                  className="leading-5"
                  size="s"
                  fontWeight="semibold"
                >
                  {'Speaker ' +
                    (
                      parseInt(dialogueItem.speaker.split('_')[1]) + 1
                    ).toString()}
                </Typography>
                <Typography className="leading-5" size="s">
                  {dialogueItem.content}
                </Typography>
              </StackView>
            </StackView>
          ))
        ) : (
          <AreaLoader size={6} text={'transcribing..'} />
        )}
      </StackView>
    </>
  )
}
const SidepanelVisitAudioRecordings = () => {
  const { appointmentId } = useParams()
  const { data, loading } = useQuery<FetchVisitAudioRecordings>(
    VISIT_AUDIO_RECORDINGS_QUERY,
    {
      variables: { id: appointmentId },
    }
  )
  const { visit } = useVisitQuery(appointmentId)

  const visitType = visit?.appointmentDefinitions[0]?.type

  return (
    <SidepanelPage
      header="Visit audio recordings"
      description="Review audio recordings and transcriptions"
      loading={loading}
    >
      <StackView space={100} className="p-6">
        {data?.appointment?.encounter?.visitAudioRecordings?.map(
          (media, index) => {
            return (
              <Accordion key={media.id}>
                <AccordionItem
                  title={`Recording ${index + 1}`}
                  bordered
                  defaultOpen
                >
                  <StackView
                    direction="col"
                    className="rounded-b-lg border p-4"
                    space={125}
                  >
                    <audio
                      controls
                      src={media.content.url}
                      className="w-full flex-grow"
                    >
                      <track kind="captions" />
                    </audio>
                    <AudioNotes
                      media={media}
                      visitType={visitType}
                      appointmentId={appointmentId}
                    />
                  </StackView>
                </AccordionItem>
              </Accordion>
            )
          }
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelVisitAudioRecordings
