import { SIGNING_PRACTITIONER_FRAGMENT } from 'src/pdf/fragments'

import { OBSERVATION_FOR_VITAL_FRAGMENT } from '../PatientResults/Vitals/useVitals'

export const DIAGNOSIS_CODE_FRAGMENT = gql`
  fragment DiagnosisCodeFragment on DiagnosisCode {
    id
    code
    description
    diagnosisCodeGroupId
  }
`

export const VISIT_CONDITION_FRAGMENT = gql`
  fragment VisitConditionFragment on Condition {
    id
    code {
      code
      display
      system
    }
    diagnosisCode {
      ...DiagnosisCodeFragment
    }
  }
  ${DIAGNOSIS_CODE_FRAGMENT}
`

export const ENCOUNTER_DIAGNOSES_FRAGMENT = gql`
  fragment EncounterDiagnosesFragment on Encounter {
    primaryDiagnosis {
      ...VisitConditionFragment
    }
    additionalDiagnoses {
      ...VisitConditionFragment
    }
  }
  ${VISIT_CONDITION_FRAGMENT}
`

export const PATIENT_CONDITION_FRAGMENT = gql`
  fragment PatientConditionFragment on Patient {
    conditions {
      id
      externalId
      patientReference
      code {
        code
        display
        system
      }
      clinicalStatus
      verificationStatus
      onsetAt
      abatedAt
      updatedAt
    }
  }
`

export const VISIT_CLINICAL_REVIEW_FRAGMENT = gql`
  fragment VisitClinicalReviewFragment on Appointment {
    immunizationsReviewedAt
    growthChartsReviewedAt
    historyReviewedAt
    resultsReviewedAt
    documentsReviewedAt
    immunizationsReviewedBy {
      id
      givenName
      familyName
    }
    growthChartsReviewedBy {
      id
      givenName
      familyName
    }
    historyReviewedBy {
      id
      givenName
      familyName
    }
    resultsReviewedBy {
      id
      givenName
      familyName
    }
    documentsReviewedBy {
      id
      givenName
      familyName
    }
  }
`

export const VISIT_INTAKE_VITALS_FRAGMENT = gql`
  fragment VisitIntakeVitalsFragment on Encounter {
    bodyHeight {
      ...ObservationForVitalFragment
    }
    bodyWeight {
      ...ObservationForVitalFragment
    }
    headCircumference {
      ...ObservationForVitalFragment
    }
    bodyTemperature {
      ...ObservationForVitalFragment
    }
    bloodPressure {
      ...ObservationForVitalFragment
    }
    heartRate {
      ...ObservationForVitalFragment
    }
    respiratoryRate {
      ...ObservationForVitalFragment
    }
    bmi {
      ...ObservationForVitalFragment
    }
    pulseOximetry {
      ...ObservationForVitalFragment
    }
    fractionOfInspiredOxygen {
      ...ObservationForVitalFragment
    }
  }
  ${OBSERVATION_FOR_VITAL_FRAGMENT}
`

export const CHARGE_ITEM_FRAGMENT = gql`
  fragment ChargeItemFragment on ChargeItem {
    id
    isPrimary
    doNotBill
    quantity
    modifiers
    automationActions {
      ... on ModifierAutomationAction {
        id
        ruleId
        modifiers
        title
        descriptionBullets
      }
      ... on DiagnosisAutomationAction {
        id
        ruleId
        title
        descriptionBullets
      }
      ... on ManagementAutomationAction {
        id
        ruleId
        title
        descriptionBullets
      }
    }
    service {
      ... on NoLinkedService {
        id
        conditions {
          id
          diagnosisCode {
            ...DiagnosisCodeFragment
          }
        }
        encounterId
      }
      ... on Procedure {
        id
        order {
          id
          name
          status
          isInBillableState
          codeDisplay
          encounterReference
          diagnosisCodes {
            ...DiagnosisCodeFragment
          }
        }
      }
      ... on Immunization {
        id
        order {
          name
          id
          isInBillableState
          status
          encounterReference
          diagnosisCodes {
            ...DiagnosisCodeFragment
          }
          codeDisplay
        }
      }
    }
    billingCode {
      code
      id
      description
      isVisitCode
      billingCodeGroupId
    }
  }
  ${DIAGNOSIS_CODE_FRAGMENT}
`

export const CLAIM_FRAGMENT = gql`
  fragment ClaimFragment on Claim {
    id
    isSelfPay
    renderingProviderId
    supervisingProviderId
    billingProviderId
    placeOfService
    serviceFacilityId
    admissionDate
    dischargeDate
    chargeItems {
      ...ChargeItemFragment
    }
  }
  ${CHARGE_ITEM_FRAGMENT}
`

export const ENCOUNTER_BILLING_FRAGMENT = gql`
  fragment EncounterBillingFragment on Encounter {
    chargeItems {
      ...ChargeItemFragment
    }
    claim {
      ...ClaimFragment
    }
  }
  ${CHARGE_ITEM_FRAGMENT}
  ${CLAIM_FRAGMENT}
`

export const PATIENT_ALLERGY_FRAGMENT = gql`
  fragment PatientAllergyFragment on Patient {
    hasNoKnownAllergies
    allergies {
      id
      externalId
      patientReference
      allergen {
        name
        code
        category
        system
        doseSpotGenericDrugCode
      }
      severity
      manifestation {
        code
        display
        system
      }
      status
      verificationStatus
      recordedDate
      recorderReference
      updatedAt
    }
  }
`

export const OBSERVATION_FOR_ORDER_FRAGMENT = gql`
  fragment ObservationForOrderFragment on Observation {
    id
    name
    value
    coding {
      system
      code
      display
    }
    effectiveAt
    updatedAt
    components {
      code {
        system
        code
        display
      }
      value
      interpretation
    }
    interpretation {
      system
      code
      display
    }
    referenceRange {
      low
      high
      referenceAnswerCode
    }
    notes
    methods {
      system
      code
      display
    }
  }
`

export const ORDER_SCHEDULING_CASE_FRAGMENT = gql`
  fragment OrderSchedulingCaseFragment on SchedulingCase {
    id
    targetTime
    targetTimeUnit
    targetDate {
      id
      value
      valueEnd
      unit
      unitEnd
      prefix
      date
      dateEnd
      rangeType
    }
    firstAvailableTargetDate
    lastAvailableTargetDate
    resolvedAt
    resolvedStatus
    appointmentDefinitions {
      id
      name
      duration
      code
    }
    chiefComplaints
    locationId
    location {
      id
      name
      description
    }
    visitPractitionerId
    visitPractitioner {
      id
      givenName
      familyName
    }
    baseCase {
      id
      createdAt
      lastCaseNote {
        id
        text
      }
    }
    newAppointment {
      id
      start
      appointmentDefinitions {
        id
        name
      }
    }
  }
`

export const ENCOUNTER_ORDER_FRAGMENT = gql`
  fragment EncounterOrderFragment on Encounter {
    orders {
      id
      category
      name
      code
      encounterReference
      freeTextCode
      codeDisplay
      status
      intent
      reason
      performer
      existingContactPerformer {
        contactId
        display
        organization
        address {
          line1
          line2
          city
          district
          state
          postalCode
        }
        emailAddress
        faxNumber
        workNumber
        display
      }
      expiresAt
      isUrgent
      comments
      createdAt
      diagnosisCodes {
        id
        code
        description
      }
      patient {
        id
        birthDate
      }
      requester {
        id
        givenName
        familyName
      }
      schedulingCase {
        ...OrderSchedulingCaseFragment
      }
      supportingInfo
      immunization {
        id
        status
      }
      resultInterpretation
      observations {
        ...ObservationForOrderFragment
      }
    }
  }
  ${OBSERVATION_FOR_ORDER_FRAGMENT}
  ${ORDER_SCHEDULING_CASE_FRAGMENT}
`

export const OBSERVATION_FRAGMENT = gql`
  fragment ObservationFragment on Observation {
    id
    externalId
    createdAt
    effectiveAt
    updatedAt
    value
    name
    code {
      code
      display
      system
    }
    components {
      code {
        system
        code
        display
      }
      value
    }
  }
`

export const MINIMAL_OBSERVATION_FRAGMENT = gql`
  fragment MinimalObservationFragment on Observation {
    id
    value
    code {
      code
      display
      system
    }
  }
`

export const PATIENT_SOCIAL_HISTORY_FRAGMENT = gql`
  fragment PatientSocialHistoryFragment on Patient {
    socialHistory {
      id
      travelHistory {
        ...ObservationFragment
        components {
          code {
            code
            display
            system
          }
          value
        }
      }
      smokingTobaccoUse {
        ...ObservationFragment
      }
      smokelessTobaccoUse {
        ...ObservationFragment
      }
      vapingUse {
        ...ObservationFragment
      }
      alcoholUse {
        ...ObservationFragment
      }
      drugUse {
        id
        cannabisMarijuana {
          ...ObservationFragment
        }
        cannabisSynthetic {
          ...ObservationFragment
        }
        methamphetamine {
          ...ObservationFragment
        }
        amphetamine {
          ...ObservationFragment
        }
        mdma {
          ...ObservationFragment
        }
        barbiturates {
          ...ObservationFragment
        }
        benzodiazepines {
          ...ObservationFragment
        }
        opioids {
          ...ObservationFragment
        }
        hallucinogens {
          ...ObservationFragment
        }
        psychedelics {
          ...ObservationFragment
        }
        kava {
          ...ObservationFragment
        }
        cocaine {
          ...ObservationFragment
        }
        crackCocaine {
          ...ObservationFragment
        }
        other {
          ...ObservationFragment
        }
      }
      comment {
        ...ObservationFragment
      }
    }
  }
  ${OBSERVATION_FRAGMENT}
`

export const PATIENT_PRESCRIPTIONS_FRAGMENT = gql`
  fragment PatientPrescriptionsFragment on Patient {
    prescriptions {
      id
      name
      doseForm
      strength
      directions
      encounterId
      dateInactive
    }
  }
`

export const PATIENT_SELF_REPORTED_MEDICATIONS_FRAGMENT = gql`
  fragment PatientSelfReportedMedicationsFragment on Patient {
    selfReportedMedications {
      id
      name
      encounterId
    }
  }
`

export const MEDICATION_FRAGMENT = gql`
  fragment MedicationFragment on Medication {
    id
    patientId
    encounterId
    drug {
      description
      brandName
      genericName
      strength
      form
    }

    startDate
    endDate
    status
    statusReason
    diagnoses {
      code
      description
      system
    }
    dosage {
      text
      route
      method
      patientInstruction
      additionalInstructions
    }
  }
`

export const VISIT_PATIENT_EDUCATION_DOCUMENTS_FRAGMENT = gql`
  fragment VisitPatientEducationDocumentsFragment on Appointment {
    patientEducationDocuments {
      id
      title
    }
  }
`

export const VISIT_SIGNATURES_FRAGMENT = gql`
  fragment VisitSignaturesFragment on Appointment {
    appointmentSignatures {
      id
      attestation
      updatedAt
      signedBy {
        ...SigningPractitionerFragment
      }
    }
  }
  ${SIGNING_PRACTITIONER_FRAGMENT}
`

export const VISIT_AUDIO_RECORDING_FRAGMENT = gql`
  fragment VisitAudioRecordingFragment on Media {
    id
    type
    notes {
      id
      text
      type
    }
    content {
      url
      contentType
    }
    transcription {
      speaker
      content
      timestamp
    }
  }
`

export const VISIT_AUDIO_RECORDINGS_FRAGMENT = gql`
  fragment VisitAudioRecordingsFragment on Encounter {
    visitAudioRecordings {
      ...VisitAudioRecordingFragment
    }
  }
  ${VISIT_AUDIO_RECORDING_FRAGMENT}
`
