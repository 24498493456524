import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography, {
  TextStyle,
  FontSizeType,
} from 'src/components/atoms/Typography/Typography'

export interface SidepanelPageProps {
  header: string | React.ReactNode
  subHeader?: string
  subHeaderTextStyle?: TextStyle
  subHeaderFontSize?: FontSizeType
  description?: string
  testId?: string
  loading?: boolean
  metaContent?: React.ReactNode
}

/**
 * A page that renders a header and subHeader above its children components.
 *
 * This is meant to be used to render sidepanels consistently across the app.
 */
function SidepanelPage({
  children,
  header,
  subHeader,
  subHeaderTextStyle = 'subtitle',
  subHeaderFontSize = 'l',
  description,
  testId,
  loading = false,
  metaContent,
}: React.PropsWithChildren<SidepanelPageProps>) {
  return (
    <StackView divider data-testid={testId} className="h-full">
      <StackView className="px-core-space-150 pb-core-space-100" space={25}>
        {typeof header === 'string' ? (
          <Typography textStyle="title-s">{header}</Typography>
        ) : (
          header
        )}
        {subHeader ? (
          <Typography textStyle={subHeaderTextStyle} size={subHeaderFontSize}>
            {subHeader}
          </Typography>
        ) : null}
        {description ? (
          <Typography textStyle="body-s" color="text-base-color-fg-muted">
            {description}
          </Typography>
        ) : null}
        {metaContent}
      </StackView>
      {loading ? (
        <StackView className="py-core-space-150">
          <LoadingSpinner />
        </StackView>
      ) : (
        <>{children}</>
      )}
    </StackView>
  )
}

export default SidepanelPage
