import { ReactNode } from 'react'

import Box from 'src/components/atoms/Box'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

export const Section = ({
  header,
  subHeader,
  children,
}: {
  header?: string
  subHeader?: string
  children: ReactNode
}) => {
  return (
    <StackView gap={50}>
      {header && (
        <>
          <StackView gap={25}>
            <Typography textStyle="interface-default-l" fontWeight="semibold">
              {header}
            </Typography>
            {subHeader ? <Typography>{subHeader}</Typography> : null}
          </StackView>
          <Space space={25} />
        </>
      )}
      <Box rounded border className="border-base-color-border-default">
        {children}
      </Box>
    </StackView>
  )
}
