import { ChatBubbleLeftIcon } from '@heroicons/react/20/solid'
import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import { User } from 'types/graphql'

import Box from 'src/components/atoms/Box/Box'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

interface CommentItemProps {
  createdBy: Partial<User>
  createdAt: string
  text: string
}

const CommentItem = ({ createdBy, createdAt, text }: CommentItemProps) => {
  return (
    <Box
      color="bg-base-color-bg-subtle"
      rounded
      horizontalPadding={50}
      verticalPadding={75}
    >
      <StackView direction="row" space={75} alignItems="start">
        <Box flex={'1/12'}>
          <Box
            className="flex h-8 w-8 items-center justify-center rounded-full"
            color="bg-base-color-bg-subtle"
          >
            <ChatBubbleLeftIcon className="h-5 w-5 text-gray-500" />
          </Box>
        </Box>
        <Box grow>
          <StackView space={25}>
            <StackView
              space={50}
              direction="row"
              alignItems="center"
              justifyContent="between"
            >
              <Typography>Comment</Typography>
              <Typography color="text-base-color-fg-muted" size="s">
                {format(parseISO(createdAt), "MMM d, yyyy 'at' h:mm a")}
              </Typography>
            </StackView>
            <Typography color="text-base-color-fg-muted">{text}</Typography>
            <Typography>{formatDisplayName(createdBy)}</Typography>
          </StackView>
        </Box>
      </StackView>
    </Box>
  )
}

export default CommentItem
