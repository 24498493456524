import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import BinaryDisplayCell from 'src/components/molecules/BinaryDisplayCell'

const SidepanelBinaryDisplay = () => {
  const { binaryId } = useParams()
  const navigate = useNavigate()

  return (
    <StackView className="h-full p-6" space={100}>
      <Button
        buttonStyle="ghost"
        className="w-fit"
        icon={ArrowLeftIcon}
        text="Back"
        onClick={() => {
          navigate(-1)
        }}
      />
      <BinaryDisplayCell id={binaryId} />
    </StackView>
  )
}

export default SidepanelBinaryDisplay
