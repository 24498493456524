import { useMemo } from 'react'

import groupBy from 'lodash/groupBy'
import { FindContactsQuery } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { TypeAheadOption } from 'src/components/molecules/Typeahead'
import { formatPhoneNumber } from 'src/lib/formatters'

const QUERY = gql`
  query FindContactsQuery {
    contacts {
      id
      name
      faxNumber
      isPrimary
      contactOrganization {
        id
        name
        primaryContact {
          id
          faxNumber
        }
      }
    }
  }
`

export const useContactsQuery = () => {
  const { data, error, loading } = useQuery<FindContactsQuery>(QUERY)

  return {
    contacts: data?.contacts,
    error,
    loading,
  }
}

const isContactOptionDisabled = (contact: FindContactsQuery['contacts'][0]) => {
  if (!contact) return true
  return (
    !contact.faxNumber &&
    !contact.contactOrganization?.primaryContact?.faxNumber
  )
}

const buildContactOptionSupportingLabel = (
  contact: FindContactsQuery['contacts'][0]
): string => {
  if (contact.faxNumber) return formatPhoneNumber(contact.faxNumber)
  if (!contact.contactOrganization?.primaryContact?.faxNumber)
    return 'No fax number available'

  return `Org: ${formatPhoneNumber(
    contact.contactOrganization.primaryContact.faxNumber
  )}`
}

export const useContactsQueryAsTypeaheadOptions = () => {
  const queryResult = useContactsQuery()

  const groupedByOrganization = useMemo(() => {
    if (!queryResult.contacts) return {}

    return groupBy(
      queryResult.contacts,
      (contact) => contact.contactOrganization?.id
    )
  }, [queryResult.contacts])

  const contactOptions: TypeAheadOption[] =
    queryResult.contacts
      ?.map((contact) => {
        return {
          label: contact.name,
          supportingLabel: buildContactOptionSupportingLabel(contact),
          value: contact.id,
          disabled: isContactOptionDisabled(contact),
          headerKey: contact.contactOrganization?.id,
          headerLabel: contact.contactOrganization?.name,
          searchTags: contact.isPrimary
            ? groupedByOrganization[contact.contactOrganization.id].map(
                ({ name }) => name
              )
            : [contact.faxNumber].filter(Boolean),
        }
      })
      .sort((a, b) => a.headerLabel.localeCompare(b.headerLabel)) ?? []

  return {
    ...queryResult,
    contactOptions,
  }
}
