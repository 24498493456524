import { useParams } from 'react-router-dom'

import PatientEducationDocumentCell from 'src/components/PatientEducationDocumentCell'

const SidepanelPatientEducationDocument = () => {
  const { patientEducationDocumentId } = useParams()

  return <PatientEducationDocumentCell id={patientEducationDocumentId} />
}

export default SidepanelPatientEducationDocument
