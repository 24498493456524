import type { FindUserOptions, FindUserOptionsVariables } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

const QUERY = gql`
  query FindUserOptions {
    users {
      id
      givenName
      familyName
      userType {
        id
        name
      }
    }
  }
`

export const useUsers = () =>
  useQuery<FindUserOptions, FindUserOptionsVariables>(QUERY)
