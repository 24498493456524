import {
  Fragment,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from 'react'

import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'

import {
  RegisterOptions,
  get,
  useFormContext,
  useWatch,
} from '@redwoodjs/forms'

import Box from '../Box/Box'
import {
  InputFieldSizeType,
  getInputFieldClasses,
  getInputFieldContainerClasses,
  inputFieldErrorClasses,
} from '../InputField'
import Label from '../Label/Label'
import { Option } from '../Select/Select'
import StackView from '../StackView/StackView'
import Typography from '../Typography/Typography'

interface DropdownProps {
  label?: string
  id?: string
  name: string
  text?: string
  mode?: 'object' | 'id'
  options?: Option[]
  selected?: Option | string
  actionOption?: Option & { onSelect: (value: string) => void }
  selectedIcon?: ReactElement
  onSelect?: (value: string) => void
  className?: string
  ref?: React.LegacyRef<HTMLDivElement>
  error?: boolean
  dontCloseOnSelect?: boolean | string[]
  disabled?: boolean
  children?: ReactElement | ReactElement[]
  onClickOutside?: () => void
  itemsContainerClassName?: string
  showChevron?: boolean
  size?: InputFieldSizeType
  /** Run code before the change is applied
   *
   * If the function resolves to an object with `preventSelection` set to true, the selection is aborted.
   */
  beforeChange?: (
    value: string
  ) => Promise<{ preventSelection: boolean } | undefined>
}

interface DropdownItemProps {
  value?: string
  className?: string
  onSelect?: (value: string) => void
  notActiveClasses?: string
  activeClasses?: string
  defaultClasses?: string
}

export function DropdownItem({
  value,
  onSelect,
  activeClasses,
  notActiveClasses,
  defaultClasses,
  children,
}: PropsWithChildren<DropdownItemProps>) {
  return (
    <Menu.Item key={value}>
      {({ active }) => (
        <button
          onClick={() => onSelect(value)}
          type="button"
          className={clsx(
            'group flex w-full px-2 py-2 text-sm hover:bg-primary hover:text-white',
            defaultClasses,
            active && activeClasses,
            !active && notActiveClasses
          )}
        >
          {children}
        </button>
      )}
    </Menu.Item>
  )
}

export default function Dropdown({
  mode = 'object',
  name,
  text,
  label,
  id,
  options,
  selected,
  className,
  onSelect,
  disabled,
  error,
  children,
  dontCloseOnSelect,
  onClickOutside,
  itemsContainerClassName,
  actionOption,
  showChevron = true,
  size = 'm',
  beforeChange,
}: DropdownProps) {
  const dropdownRef = useRef(null)

  const handleClickOutsideDropdown = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        typeof onClickOutside === 'function' && onClickOutside()
      }
    },
    [onClickOutside]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideDropdown)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown)
    }
  }, [handleClickOutsideDropdown])

  const selectedOption =
    mode === 'object'
      ? (selected as Option)
      : options.find(({ value }: Option) => value === selected)

  const renderOption = (option: Option) => (
    <StackView className="h-full" direction="row" alignItems="center">
      {option.icon && (
        <Box className={`flex self-center pr-3 ${option.icon.class}`}>
          {option.icon.value}
        </Box>
      )}
      <StackView direction="row" justifyContent="between" alignItems="center">
        {option.render ? (
          <option.render />
        ) : (
          <span
            className={clsx(
              selectedOption?.value === option.value
                ? 'font-semibold'
                : 'font-normal',
              option.disabled && 'font-bold text-gray-400',
              'truncate overflow-ellipsis whitespace-nowrap text-left'
            )}
          >
            {option.name}
          </span>
        )}
        {option.selectedIcon && (
          <Box className={`flex self-center ${option.selectedIcon.class}`}>
            {selectedOption?.value === option.value &&
              option.selectedIcon.value}
          </Box>
        )}
      </StackView>
    </StackView>
  )

  return (
    <Menu
      onSubmit={(e) => e.preventDefault()}
      as="div"
      ref={dropdownRef}
      itemID={`dropdown-${name}`}
      data-testid={`dropdown-${name}`}
      className={clsx(
        getInputFieldContainerClasses({ fullWidth: false, disabled })
      )}
      id={id}
    >
      <div>
        {label && (
          <Label className="mb-1" htmlFor={id}>
            {label}
          </Label>
        )}
        <Menu.Button
          className={clsx(
            ...getInputFieldClasses({
              size,
            }),
            error && inputFieldErrorClasses,
            className
          )}
          type="button"
          disabled={disabled}
        >
          {text ? (
            <StackView
              direction="row"
              justifyContent="between"
              space={25}
              alignItems="center"
            >
              <Typography fontWeight="normal" noWrap>
                {text}
              </Typography>
              {showChevron && (
                <Box className="flex self-center">
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Box>
              )}
            </StackView>
          ) : (
            <StackView direction="row" alignItems="center" className="h-5">
              {selectedOption?.icon && (
                <Box
                  className={`flex items-start self-center pr-3 ${selectedOption?.icon.class}`}
                >
                  {selectedOption?.icon.value}
                </Box>
              )}
              <StackView
                direction="row"
                justifyContent="between"
                alignItems="center"
                space={25}
              >
                {selectedOption?.selectedRender ? (
                  <selectedOption.selectedRender />
                ) : selectedOption?.render ? (
                  <selectedOption.render />
                ) : (
                  <Typography className="truncate" fontWeight="normal" noWrap>
                    {selectedOption?.name}
                  </Typography>
                )}

                {showChevron && (
                  <Box className="flex self-center">
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  </Box>
                )}
              </StackView>
            </StackView>
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(
            'absolute right-0 mt-2 max-h-72 w-fit min-w-full origin-top-right divide-y divide-gray-100',
            'overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            'p-core-space-25',
            itemsContainerClassName
          )}
          style={{
            zIndex: 99,
          }}
        >
          {children ??
            options.map((option, index) => {
              return (
                <Menu.Item key={option.value} disabled={option.disabled}>
                  {({ active }) => (
                    <button
                      onClick={async (e) => {
                        if (
                          dontCloseOnSelect === true ||
                          (Array.isArray(dontCloseOnSelect) &&
                            dontCloseOnSelect.includes(option.value))
                        ) {
                          e.preventDefault()
                        }
                        if (beforeChange) {
                          const returnValue = await beforeChange(option.value)
                          if (returnValue?.preventSelection) {
                            return
                          }
                        }
                        onSelect(option.value)
                      }}
                      type="button"
                      disabled={option.disabled}
                      className={clsx(
                        (active || selectedOption?.value === option.value) &&
                          !option.disabled
                          ? !option.selectedIcon && 'bg-base-color-bg-subtle'
                          : 'text-gray-900',
                        'group flex w-full items-center px-2 py-2 text-sm',
                        !option.disabled
                          ? 'hover:!bg-base-color-bg-subtle'
                          : 'bg-gray-100',
                        index === 0 && 'rounded-t-md',
                        index === options.length - 1 && 'rounded-b-md',
                        'min-h-8'
                      )}
                    >
                      {renderOption(option)}
                    </button>
                  )}
                </Menu.Item>
              )
            })}
          {actionOption && (
            <DropdownItem
              value={actionOption.value}
              onSelect={actionOption.onSelect}
            >
              {renderOption(actionOption)}
            </DropdownItem>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export interface DropdownFieldProps extends DropdownProps {
  name: string
  onSelection?: (opt: Option) => void
  selected?: Option
  defaultValue?: Option
  validation?: RegisterOptions
  disabled?: boolean
}

export const DropdownField = ({
  name,
  validation,
  options,
  onSelection,
  disabled,
  ...rest
}: DropdownFieldProps) => {
  const {
    setValue,
    register,
    unregister,
    formState: { errors },
  } = useFormContext()

  register(name, validation)
  const value = useWatch({
    name,
  })

  const onChange = (value: string) => {
    setValue(name, value, { shouldDirty: true })
  }

  const error = !!get(errors, name)

  useEffect(() => {
    if (value && onSelection) {
      onSelection(
        options.find(({ value: selectedValue }) => selectedValue === value)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    return () => validation?.shouldUnregister && unregister(name)
  }, [name, unregister, validation?.shouldUnregister])

  return (
    <Dropdown
      disabled={disabled}
      name={name}
      error={error}
      options={options}
      mode="id"
      id={name}
      selected={value}
      onSelect={onChange}
      {...rest}
    />
  )
}
