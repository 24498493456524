import { formatDisplayName } from 'common/utils'
import { GetPatientDemographicsForPrinting } from 'types/graphql'

import { healthPlanTypeDisplay } from 'src/data/healthPlanTypes'
import { insuranceOptOutStatusDisplay } from 'src/data/insuranceOptOutStatuses'
import { formatDateDisplay } from 'src/lib/formatters'

import { DemographicsSection } from '../types'

export const createInsuranceSections = ({
  patient,
}: {
  patient: GetPatientDemographicsForPrinting['patient']
}): DemographicsSection[] => {
  const insurance = patient.insuranceCoverages.find(
    (coverage) =>
      coverage.status === 'ACTIVE' &&
      coverage.coordinationOfBenefitsType === 'PRIMARY'
  )

  if (!insurance)
    return patient.insuranceOptOutStatus
      ? [
          {
            header: 'Insurance',
            rows: [
              {
                title: 'Insurance opt-out',
                column: {
                  left: [
                    [
                      'Opt-out reason',
                      insuranceOptOutStatusDisplay[
                        patient.insuranceOptOutStatus
                      ],
                    ],
                  ],
                  right: [],
                },
              },
            ],
          },
        ]
      : []

  const policyholder = insurance.beneficiaryIsSubscriber
    ? patient
    : patient.patientRelatedPersonRelationships.find(
        (relationship) =>
          relationship.relatedPerson.id === insurance.subscriberId
      )?.relatedPerson

  return [
    {
      header: 'Primary insurance',
      subHeader: insurance.payer.displayName,
      rows: [
        {
          title: 'Insurance status',
          column: {
            left: [['Status', 'Active']],
            right: [['Type', 'Primary']],
          },
        },
        {
          title: 'Member information',
          column: {
            left: [
              ['Member name', formatDisplayName(patient)],
              ['Member date of birth', formatDateDisplay(patient.birthDate)],
            ],
            right: [
              ['Member ID', insurance.planMemberIdentifier],
              ['Insurance provider', insurance.payer.displayName],
            ],
          },
        },
        {
          title: 'Policyholder information',
          column: {
            left: [['Policyholder', formatDisplayName(policyholder)]],
            right: [],
          },
        },
        {
          title: 'General plan information',
          column: {
            left: [
              ['Health plan type', healthPlanTypeDisplay[insurance.planType]],
            ],
            right: [],
          },
        },
      ],
    },
  ]
}
