import { useEffect } from 'react'

import { XMarkIcon } from '@heroicons/react/24/solid'

import {
  RegisterOptions,
  useFieldArray,
  useFormContext,
} from '@redwoodjs/forms'

import { useEffectOnce } from 'src/utils'

import Button from '../../atoms/Button'
import StackView from '../../atoms/StackView'
import FieldError from '../../FieldError/FieldError'
import { InputProps } from '../FormInputList'

export type GenericFormFieldArrayProps = {
  name: string
  formInputComponent: React.FC<InputProps>
  addButtonLabel: string
  nestedName?: string
  addNewDefault?: Record<string, unknown>
  allowEmpty?: boolean
  inputProps?: Partial<InputProps>
  validation?: RegisterOptions
  nestedValidation?: RegisterOptions
  showItemErrors?: boolean
}

const GenericFormFieldArray = ({
  name,
  nestedName,
  formInputComponent: FormInputComponent,
  addButtonLabel,
  addNewDefault,
  allowEmpty = false,
  inputProps,
  validation,
  nestedValidation,
  showItemErrors = true,
}) => {
  const { control, unregister } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name,
    control: control,
    rules: validation,
  })

  useEffectOnce(() => {
    if (fields.length === 0 && !allowEmpty) {
      append(addNewDefault ?? { [nestedName]: null })
    }
    // only check fields length on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  useEffect(() => {
    const inputName = `${name}.${fields.length}${
      nestedName ? `.${nestedName}` : ''
    }`
    unregister(inputName)
  }, [fields, name, nestedName, unregister])

  return (
    <StackView space={75}>
      {fields.map((field, index) => {
        const inputName = `${name}.${index}${
          nestedName ? `.${nestedName}` : ''
        }`

        return (
          <StackView key={field.id}>
            <StackView direction="row" space={50} alignItems="center">
              <FormInputComponent
                name={inputName}
                validation={{ ...nestedValidation }}
                {...inputProps}
              />
              {((fields.length > 1 && index > 0) || allowEmpty) && (
                <Button
                  data-testid="remove-item-button"
                  buttonStyle="ghost"
                  icon={XMarkIcon}
                  onClick={() => {
                    remove(index)
                  }}
                  className="!text-gray-400"
                />
              )}
            </StackView>
            {showItemErrors && <FieldError name={inputName} />}
          </StackView>
        )
      })}
      <Button
        buttonStyle="secondary"
        className="border-dashed !text-gray-400"
        text={addButtonLabel}
        onClick={() => append(addNewDefault ?? { [nestedName]: null })}
      />
    </StackView>
  )
}

export default GenericFormFieldArray
