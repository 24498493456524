import { format } from 'date-fns'
import { GetSigningPractitionerForPdf } from 'types/graphql'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { OriginalFaxType } from 'src/hooks/useCreateDocumentCaseSignaturePdf/useCreateDocumentCaseSignaturePdf'
import { formatPhoneNumber } from 'src/lib/formatters'

import { LetterHead, Signature, SubHeading } from './common'

const PrintDocumentCaseSignatureDocument = ({
  tenant,
  practitioner,
  addSignature = false,
  comment,
  originalFax,
  testId,
}: {
  tenant: GetSigningPractitionerForPdf['practitioner']['tenant']
  practitioner: GetSigningPractitionerForPdf['practitioner']
  addSignature?: boolean
  comment?: string
  originalFax?: OriginalFaxType
  testId?: string
}) => {
  return (
    <StackView space={100} className="h-full" data-testid={testId}>
      <LetterHead tenant={tenant} />
      {originalFax && (
        <Box
          color="bg-base-color-bg-subtle"
          padding={50}
          data-testid="fax-details"
        >
          <StackView
            direction="row"
            justifyContent="between"
            space={50}
            className="pb-core-space-50"
          >
            <StackView>
              <SubHeading>Ref. fax date and time</SubHeading>
              <Typography textStyle="interface-default-xs">
                {format(originalFax.faxReceivedAt, "MMM d, yyyy 'at' h:mm a")}
              </Typography>
            </StackView>

            <StackView>
              <SubHeading>Ref. fax number</SubHeading>
              <Typography textStyle="interface-default-xs">
                {formatPhoneNumber(originalFax.faxNumber)}
              </Typography>
            </StackView>
          </StackView>
        </Box>
      )}

      <Signature
        signedAt={new Date().toISOString()}
        practitioner={practitioner}
        comment={comment}
        addSignature={addSignature}
      />
    </StackView>
  )
}

export default PrintDocumentCaseSignatureDocument
