import { useState } from 'react'

import Checkbox from 'src/components/atoms/Checkbox'
import Label from 'src/components/atoms/Label/Label'
import StackView from 'src/components/atoms/StackView/StackView'
import DataRow from 'src/components/molecules/DataRow/DataRow'

import AddressFormInputList from '../AddressFormInputList/AddressFormInputList'

export interface OptionalAddressFieldProps {
  name: string
}

export const OptionalAddressField: React.FC<OptionalAddressFieldProps> = ({
  name,
}) => {
  const [skip, setSkip] = useState(false)

  return (
    <>
      <StackView direction="row" space={50} alignItems="center">
        <DataRow
          value={
            <>
              <Checkbox
                name="skipAddress"
                checked={skip}
                onChange={() => setSkip(!skip)}
              />
              <Label htmlFor="skipAddress">Skip address</Label>
            </>
          }
          label={null}
        />
      </StackView>
      {!skip && <AddressFormInputList name={name} />}
    </>
  )
}
export default OptionalAddressField
