import { useEffect, useState } from 'react'

import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { UpsertBirthHistoryInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'

import BloodTypeSelectField from 'src/components/atoms/BloodTypeSelectField/BloodTypeSelectField'
import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import DeliveryMethodSelectField from 'src/components/atoms/DeliveryMethodSelectField/DeliveryMethodSelectField'
import InputField from 'src/components/atoms/InputField/InputField'
import LabTestResultSelectField from 'src/components/atoms/LabTestResultSelectField/LabTestResultSelectField'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import NewbornHearingScreeningResultSelectField from 'src/components/atoms/NewbornHearingScreeningResultSelectField/NewbornHearingScreeningResultSelectField'
import PresenceIndicatorSelectField from 'src/components/atoms/PresenceIndicatorSelectField/PresenceIndicatorSelectField'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
// import { DatePickerFieldThatWorks } from 'src/components/molecules/DatePicker'
import { DatePickerFieldThatWorks } from 'src/components/molecules/DatePicker'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import {
  InputFieldWithUnit,
  InputFieldWithUnits,
} from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import {
  compactObject,
  deepOmit,
  getNumericInputValidationFn,
  replaceEmptyObjectEntriesWithNull,
} from 'src/data/utils'
import {
  useBirthHistoryQuery,
  useUpsertBirthHistory,
} from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/useBirthHistory'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const GeneralBirthHistoryInputs = ({
  hasRequiredBirthMeasurements,
}: {
  hasRequiredBirthMeasurements: boolean
}) => {
  return (
    <FormInputList
      items={[
        {
          name: 'gestationalAgeAtBirth.wk',
          label: 'Gestational age at birth',
          direction: 'col',

          formInputComponent: InputFieldWithUnit,
          inputProps: {
            fullWidth: true,
            unit: 'wk',
            validation: getNumericInputValidationFn(),
            description:
              'Enter the GAB in weeks to support corrected age growth chart plotting in cases where the patient is born premature',
          },
          required: hasRequiredBirthMeasurements ? true : false,
        },
        {
          name: 'birthWeight',
          label: 'Birth weight',
          direction: 'col',
          formInputComponent: InputFieldWithUnits,
          inputProps: {
            units: ['lbs', 'oz'],
            validation: {
              validate: (value: { lbs: string; oz: string }) => {
                const { errorMessage } = validateNumericBirthWeightInputs(value)

                if (errorMessage) {
                  return errorMessage
                }

                if (value['lbs'] && !value['oz']) {
                  return 'Please specify an oz measurement as well, using 0 as necessary.'
                }

                if (!value['lbs'] && value['oz']) {
                  return 'Please specify an lbs measurement as well, using 0 as necessary.'
                }
              },
            },
          },
        },
        {
          name: 'dischargeWeight',
          label: 'Discharge weight',
          direction: 'col',
          formInputComponent: InputFieldWithUnits,
          inputProps: {
            units: ['lbs', 'oz'],
            validation: {
              validate: (value: { lbs: string; oz: string }) => {
                const { errorMessage } = validateNumericBirthWeightInputs(value)

                if (errorMessage) {
                  return errorMessage
                }

                if (value['lbs'] && !value['oz']) {
                  return 'Please specify an oz measurement as well, using 0 as necessary.'
                }

                if (!value['lbs'] && value['oz']) {
                  return 'Please specify an lbs measurement as well, using 0 as necessary.'
                }
              },
            },
          },
        },
        {
          name: 'birthLength.in', // make dynamic in future
          label: 'Birth length',
          direction: 'col',
          formInputComponent: InputFieldWithUnit,
          inputProps: {
            fullWidth: true,
            unit: 'in',
            validation: getNumericInputValidationFn(),
          },
        },
        {
          name: 'birthHeadCircumference.in', // make dynamic in future
          label: 'Birth head circumference',
          direction: 'col',
          formInputComponent: InputFieldWithUnit,
          inputProps: {
            fullWidth: true,
            unit: 'in',
            validation: getNumericInputValidationFn(),
          },
        },
        {
          name: 'deliveryMethod',
          label: 'Delivery method',
          direction: 'col',
          formInputComponent: DeliveryMethodSelectField,
        },
        {
          name: 'birthFacility',
          label: 'Birth facility',
          direction: 'col',
          formInputComponent: InputField,
          inputProps: {
            validation: {
              validate: (value: string) => {
                if (value && !isNaN(Number(value))) {
                  return 'Please enter at least one alphabetic character.'
                }
              },
            },
          },
        },
        {
          name: 'dischargeDate',
          label: 'Discharge date',
          direction: 'col',
          formInputComponent: DatePickerFieldThatWorks,
        },
        {
          name: 'newbornHearingScreeningResult',
          label: 'Hearing screening result',
          direction: 'col',
          formInputComponent: NewbornHearingScreeningResultSelectField,
        },

        {
          name: 'lastTotalSerumBilirubin.MILLIGRAMS_PER_DECILITER',
          label: 'Last total serum bilirubin',
          direction: 'col',
          formInputComponent: InputFieldWithUnit,
          inputProps: {
            fullWidth: true,
            unit: 'mg/dL',
            validation: getNumericInputValidationFn(),
          },
        },
        {
          name: 'totalSerumBilirubinDate',
          label: 'Total serum bilirubin date',
          direction: 'col',
          formInputComponent: DatePickerFieldThatWorks,
        },
      ]}
    />
  )
}

const validateNumericBirthWeightInputs = (value: {
  lbs: string
  oz: string
}) => {
  const validationResults = [
    getNumericInputValidationFn().validate(value['lbs']),
    getNumericInputValidationFn().validate(value['oz']),
  ]
  const errorMessages = validationResults.filter(
    (result) => typeof result === 'string'
  )
  return {
    errorMessage: errorMessages[0],
  }
}

const SidepanelBirthHistoryEdit = () => {
  const [existingFormValuesLoaded, setExistingFormValuesLoaded] =
    useState(false)
  const { patientId } = useParams()
  const { closeSidePanel } = useSidepanel()
  const { birthHistoryFormValues, hasRequiredBirthMeasurements } =
    useBirthHistoryQuery(patientId)

  const [upsertBirthHistory, { loading: upserting }] = useUpsertBirthHistory()

  const onSubmit = async (data: UpsertBirthHistoryInput) => {
    const input = deepOmit(
      {
        patientId,
        ...compactObject(replaceEmptyObjectEntriesWithNull(data)),
      },
      ['display', 'valueType', 'unit']
    )

    void upsertBirthHistory({
      variables: { input },
      onCompleted: closeSidePanel,
    })
  }

  const PatientBloodTypingInputs = () => {
    return (
      <FormInputList
        items={[
          {
            name: 'bloodType',
            label: 'Patient blood type',
            direction: 'col',
            formInputComponent: BloodTypeSelectField,
          },
          {
            name: 'naturalMotherHistory.bloodType',
            label: 'Natural mother blood type',
            direction: 'col',
            formInputComponent: BloodTypeSelectField,
          },
          {
            name: 'naturalMotherHistory.directAntiglobulinTestResult',
            label: 'Direct antiglobulin test (DAT)',
            direction: 'col',
            formInputComponent: LabTestResultSelectField,
          },
        ]}
      />
    )
  }

  const NaturalMotherHistoryInputs = () => {
    return (
      <FormInputList
        items={[
          {
            name: 'naturalMotherHistory.ageAtPatientBirth.a',
            label: "Mother's age at birth",
            direction: 'col',
            formInputComponent: InputFieldWithUnit,
            inputProps: {
              unit: 'a',
              validation: getNumericInputValidationFn(),
            },
          },
          {
            name: 'naturalMotherHistory.hadRoutinePrenatalCare',
            label: 'Routine prenatal care',
            direction: 'col',
            formInputComponent: PresenceIndicatorSelectField,
          },
          {
            name: 'naturalMotherHistory.hadPregnancyComplications',
            label: 'Pregnancy complications',
            direction: 'col',
            formInputComponent: PresenceIndicatorSelectField,
          },
        ]}
      />
    )
  }

  const PrenatalLabInputs = () => {
    return (
      <>
        <StackView direction="row" justifyContent="between">
          <Typography textStyle="subtitle">Prenatal Labs</Typography>
          <Button
            buttonStyle="link"
            onClick={() => {
              formMethods.setValue('naturalMotherHistory.prenatalLabResults', {
                streptococcusGroupB: 'NEGATIVE',
                hiv: 'NEGATIVE',
                chlamydia: 'NEGATIVE',
                gonorrhoeae: 'NEGATIVE',
                syphilis: 'NEGATIVE',
                rubella: 'NEGATIVE',
              })
            }}
          >
            Set all to negative
          </Button>
        </StackView>
        <FormInputList
          items={[
            {
              name: 'naturalMotherHistory.prenatalLabResults.streptococcusGroupB',
              label: 'Group B strep',
              direction: 'col',
              formInputComponent: LabTestResultSelectField,
            },
            {
              name: 'naturalMotherHistory.prenatalLabResults.hiv',
              label: 'Human immunodeficiency virus (HIV)',
              direction: 'col',
              formInputComponent: LabTestResultSelectField,
            },
            {
              name: 'naturalMotherHistory.prenatalLabResults.chlamydia',
              label: 'Chlamydia trachomatis',
              direction: 'col',
              formInputComponent: LabTestResultSelectField,
            },
            {
              name: 'naturalMotherHistory.prenatalLabResults.gonorrhoeae',
              label: 'Neisseria gonorrhoeae',
              direction: 'col',
              formInputComponent: LabTestResultSelectField,
            },
            {
              name: 'naturalMotherHistory.prenatalLabResults.syphilis',
              label: 'Treponema pallidum (syphilis)',
              direction: 'col',
              formInputComponent: LabTestResultSelectField,
            },
            {
              name: 'naturalMotherHistory.prenatalLabResults.rubella',
              label: 'Rubella',
              direction: 'col',
              formInputComponent: LabTestResultSelectField,
            },
          ]}
        />
      </>
    )
  }
  const formMethods = useForm<UpsertBirthHistoryInput>({
    defaultValues: birthHistoryFormValues,
  })

  useEffect(() => {
    if (isEmpty(birthHistoryFormValues)) return

    if (!existingFormValuesLoaded) {
      formMethods.reset(birthHistoryFormValues)
      setExistingFormValuesLoaded(true)
    }
  }, [birthHistoryFormValues, formMethods, existingFormValuesLoaded])

  if (!existingFormValuesLoaded)
    return (
      <Box className="flex h-screen items-center justify-center">
        <LoadingSpinner size="m" />
      </Box>
    )
  return (
    <SidepanelPage
      testId="patient-birth-history-edit"
      header="Edit birth history"
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Update',
          submitting: upserting,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <StackView space={25}>
          <Typography textStyle="subtitle">General</Typography>
          <GeneralBirthHistoryInputs
            hasRequiredBirthMeasurements={hasRequiredBirthMeasurements}
          />
        </StackView>
        <StackView space={25}>
          <Typography textStyle="subtitle">ABO and Rh Blood Typing</Typography>
          <PatientBloodTypingInputs />
        </StackView>

        <StackView space={50}>
          <Typography textStyle="subtitle">Natural Mother</Typography>

          <NaturalMotherHistoryInputs />
          <Box verticalPadding={75}>
            <PrenatalLabInputs />
          </Box>
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}
export default SidepanelBirthHistoryEdit
