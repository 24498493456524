import { Fragment, useState } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { Bars3BottomLeftIcon, XMarkIcon } from '@heroicons/react/20/solid'

import { Toaster } from '@redwoodjs/web/toast'

import Button from 'src/components/atoms/Button/Button'
import SidepanelLayout, {
  SidepanelRoutes,
} from 'src/layouts/SidepanelLayout/SidepanelLayout'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

interface ApplicationLayoutProps {
  sidebar: React.ReactNode
  mobileMenu: React.ReactNode
  children?: React.ReactNode
}

export default function ApplicationLayout({
  sidebar,
  mobileMenu,
  children,
}: ApplicationLayoutProps) {
  const { isSidepanelAvailable, sidepanelContext } = useSidepanel()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const shouldRenderSidepanelContentInMainContentArea =
    !isSidepanelAvailable && sidepanelContext.route

  return (
    <div className="flex h-full" id="application-layout">
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />

      {/* Sidebar */}
      {sidebar}

      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20 md:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pb-4 pt-5">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-1 -mr-14 p-1">
                    <Button
                      buttonStyle="ghost"
                      className="h-12 w-12 items-center justify-center rounded-full border-white bg-transparent px-0.5 py-0.5 text-gray-500"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Close sidebar</span>
                    </Button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="/img/develo-logo-square.svg"
                    alt="Develo"
                  />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                  <nav className="flex h-full flex-col">
                    <div className="space-y-1">{mobileMenu}</div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Content area */}
      <div className="flex flex-1 flex-col overflow-hidden">
        <header className="w-full md:hidden">
          <div className="relative z-10 flex h-16 flex-shrink-0 bg-white">
            <Button
              className="items-center justify-center px-2 py-2"
              buttonStyle="ghost"
              accessibilityText="Open sidebar"
              onClick={() => setMobileMenuOpen(true)}
            >
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </Button>
          </div>
        </header>

        {/* Main content */}
        <main className="flex flex-1 items-stretch justify-center overflow-y-auto">
          {shouldRenderSidepanelContentInMainContentArea ? (
            <SidepanelRoutes />
          ) : (
            children
          )}
        </main>
      </div>

      <SidepanelLayout />
    </div>
  )
}
