import { DocumentTypesConfig } from 'common/cdr/concepts/documents'
import type { PatientFormType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const patientFormTypeDisplay: {
  [key in PatientFormType]: string
} = {
  ...DocumentTypesConfig['PATIENT_FORM']['subTypes'],
}

export const patientFormTypes = nameValueArrayFromObject<PatientFormType>(
  patientFormTypeDisplay
)
