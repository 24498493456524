import { useEffect } from 'react'

import {
  ArrowTopRightOnSquareIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'

import { navigate, routes } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import DataDisplayList from 'src/components/molecules/DataDisplayList'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { insuranceOptOutStatusDisplay } from 'src/data/insuranceOptOutStatuses'
import { useNoInsuranceQuery } from 'src/hooks/useInsuranceCoverages/useInsuranceCoverages'
import { sidepanelRoute } from 'src/lib/routes'
import { sortInsuranceCoverages } from 'src/utils/insuranceCoverages'

const SidepanelPatientNoInsuranceCoveragesView = () => {
  const params = useParams()
  const { patientId } = params
  const { patient, loading } = useNoInsuranceQuery(patientId)

  useEffect(() => {
    if (patient && patient.activeInsuranceCoverages.length > 0) {
      const sortedActiveInsurances = sortInsuranceCoverages(
        patient.activeInsuranceCoverages
      )
      navigate(
        sidepanelRoute(
          {
            route: `/patients/${patient.id}/insurance-coverages/${sortedActiveInsurances[0].id}`,
          },
          location,
          params
        )
      )
    }
  }, [patient, params])

  if (loading || !patient) return <LoadingSpinner />

  return (
    <SidepanelPage
      testId="patient-no-insurance-coverages-view"
      header="Uninsured"
      metaContent={
        <StackView space={100}>
          <StackView direction="row" alignItems="center" space={25}>
            <XCircleIcon className="h-base-size-icon-xs w-base-size-icon-xs fill-base-color-fg-muted" />
            <Typography
              textStyle="interface-default-xs"
              color="text-base-color-fg-subtle"
            >
              Insurance opt-out
            </Typography>
          </StackView>
          <div className="w-fit">
            <Button
              text="View"
              buttonStyle="secondary"
              iconPlacement="left"
              icon={ArrowTopRightOnSquareIcon}
              onClick={() => {
                navigate(
                  routes.patientChartsGlob({
                    id: patientId,
                    glob: 'demographics/insurance',
                  })
                )
              }}
            />
          </div>
        </StackView>
      }
    >
      <StackView className="grow p-core-space-150" space={50}>
        <DataDisplayList
          title="Insurance opt-out"
          subtitle={
            patient.insuranceOptOutVerifiedAt
              ? `Last verified on ${format(
                  new Date(patient.insuranceOptOutVerifiedAt),
                  'PP'
                )}`
              : undefined
          }
          leftColumnWidth="lg"
          data={[
            {
              label: 'Is patient opting-out?',
              value: patient.insuranceOptedOutAt ? 'Yes' : 'No',
            },
            {
              label: 'Opt-out reason',
              value:
                insuranceOptOutStatusDisplay[patient.insuranceOptOutStatus],
            },
          ]}
        />
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPatientNoInsuranceCoveragesView
