import { AutomationRuleCategory } from 'types/graphql'

export const CATEGORY_INFO_BY_CODE: {
  [key in AutomationRuleCategory]: {
    sortOrder: number
    title: string
    description: string
  }
} = {
  CLAIM_SUMMARY: {
    sortOrder: 1,
    title: 'Summary',
    description:
      'These rules apply to claim-level summary details for the visit.',
  },
  VISIT: {
    sortOrder: 2,
    title: 'Visit type billing codes',
    description:
      'These rules apply to the visit type billing code(s) associated with the visit type(s) for the visit.',
  },
  ORDER: {
    sortOrder: 3,
    title: 'Order-linked billing codes',
    description:
      'These rules apply to the billing codes linked to orders that have been signed and completed.',
  },
  ADDITIONAL: {
    sortOrder: 4,
    title: 'Additional billing codes',
    description:
      'These rules apply to billing codes for the visit that were not brought in directly by completed orders, and are not a visit type billing code for the visit.',
  },
}
