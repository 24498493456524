import { sexualExperienceConceptCodings } from 'common/cdr/concepts/observations/sexualHistories/index'
import { SexualActivityStatus } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const sexualStatusDisplay: {
  [key in SexualActivityStatus]: string
} = {
  EVER_ACTIVE: 'Ever active',
  UNKNOWN: 'Unknown',
  NEVER: 'Never',
}

export const sexualStatusDisplayToEnum = Object.keys(
  sexualStatusDisplay
).reduce((acc, key) => {
  acc[sexualStatusDisplay[key]] = key
  return acc
}, {})

export const sexualStatusCodeToEnum = sexualExperienceConceptCodings.reduce(
  (acc, coding) => {
    if (sexualStatusDisplayToEnum[coding.display]) {
      acc[coding.code] = sexualStatusDisplayToEnum[coding.display]
    }
    return acc
  },
  {}
)

export const sexualStatuses =
  nameValueArrayFromObject<SexualActivityStatus>(sexualStatusDisplay)
