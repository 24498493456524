import { useParams } from 'react-router-dom'

import PracticeFormCell from 'src/components/PracticeFormCell'

const SidepanelPracticeForm = () => {
  const { practiceFormId } = useParams()

  return <PracticeFormCell id={practiceFormId} />
}

export default SidepanelPracticeForm
