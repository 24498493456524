import { useEffect, useState } from 'react'

import { isSameDay } from 'date-fns'
import { useParams } from 'react-router-dom'

import { useEmrAuth } from 'src/auth'
import Box from 'src/components/atoms/Box'
import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { usePatientPaymentsQuery } from 'src/hooks/usePatientPayments/usePatientPayments'
import PrintPatientPaymentReceipt from 'src/pdf/components/PrintPatientPaymentReceipt'
import { useGeneratePDF } from 'src/pdf/useGeneratePDF'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

const SidepanelPaymentReceipt = () => {
  const { currentUser } = useEmrAuth()
  const { patientId, date } = useParams()

  const {
    patient,
    candidPayments,
    develoCandidPatientRefunds,
    paymentPlanPayments,
    loading: loadingPayments,
  } = usePatientPaymentsQuery(patientId)

  const [filteredCandidPayments, setFilteredCandidPayments] = useState([])
  const [filteredCandidRefunds, setFilteredCandidRefunds] = useState([])

  const [isGenerating, setIsGenerating] = useState(false)
  const generatePDF = useGeneratePDF()
  const [fileUrl, setFileUrl] = useState<URL | null>(null)

  useEffect(() => {
    if (isGenerating) return
    if (fileUrl) return
    if (!patient || loadingPayments) return

    setIsGenerating(true)
    void generatePDF({
      forPatient: patient,
      component: (
        <PrintPatientPaymentReceipt
          patient={patient}
          paymentDate={new Date(date)}
          candidPayments={filteredCandidPayments}
          candidRefunds={filteredCandidRefunds}
        />
      ),
      callback: (doc) => {
        setFileUrl(doc.output('bloburl'))
        setIsGenerating(false)
      },
    })
  }, [
    generatePDF,
    date,
    patient,
    filteredCandidPayments,
    filteredCandidRefunds,
    loadingPayments,
    isGenerating,
    fileUrl,
  ])

  useEffect(() => {
    setFilteredCandidPayments(
      candidPayments?.filter(
        (payment) =>
          isSameDay(new Date(payment.paidAt), new Date(date)) &&
          payment.paymentLineItemType !== 'PAYMENT_PLAN'
      ) ?? []
    )

    setFilteredCandidRefunds(
      develoCandidPatientRefunds?.filter((refund) =>
        isSameDay(new Date(refund.createdAt), new Date(date))
      ) ?? []
    )
  }, [candidPayments, develoCandidPatientRefunds, date, paymentPlanPayments])

  if (!currentUser.featureFlags.includes('PAYMENT_RECEIPTS'))
    return <SidepanelNotAvailable />

  if (!patientId) return <SidepanelNotAvailable />

  if (loadingPayments) return <LoadingSpinner />

  return (
    <SidepanelPage header="Payment receipt">
      <StackView className="h-full" space={50}>
        {isGenerating ? (
          <Box padding={100}>
            <LoadingSpinner />
          </Box>
        ) : (
          <FileDisplay
            name="Receipt"
            type="application/pdf"
            url={fileUrl}
            showDownloadLink={false}
          />
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPaymentReceipt
