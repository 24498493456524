import { isEmptyLexical } from 'common/lexical/lexical'
import { FindPatientVisit } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import { calculateAge } from 'src/lib/formatters'
import { HtmlStrings } from 'src/pages/PatientChartsPage/PatientVisits/useSignVisit'

import { LetterHead, Section } from './common'
import {
  ActiveMedicationsSubSection,
  AllergiesSubSection,
  AssessmentDiagnosesSubSection,
  AssessmentNotesSubSection,
  ChiefComplaintSubSection,
  DiagnosesSubSection,
  DocumentationAdolescentConfidentialSubSection,
  FamilyHistorySubSection,
  HistorySubSection,
  ImmunizationsSubSection,
  InHouseTestsSubSection,
  PastMedicalHistorySubSection,
  PatientEducationAdolescentConfidentialSubSection,
  PatientEducationDocumentsSubSection,
  PatientEducationNotesSubSection,
  PhysicalExamSubSection,
  PlanNotesSubSection,
  PlanOrdersSubSection,
  ReviewOfSystemsSubSection,
  SocialHistorySubSection,
  VisitDetails,
  VisitFooter,
  VisitSummarySubSection,
  VitalsSubSection,
} from './visits'

const PrintVisitNoteDocument = ({
  visit,
  currentPractitioner,
  htmlStrings,
  testId,
  hideHeader = false,
  hideFooter = false,
  hideVisitDetails = false,
  hideAdolescentConfidentialInformation = false,
}: {
  visit: FindPatientVisit['appointment']
  currentPractitioner: FindPatientVisit['practitioner']
  htmlStrings: HtmlStrings
  testId?: string
  hideHeader?: boolean
  hideFooter?: boolean
  hideVisitDetails?: boolean
  hideAdolescentConfidentialInformation?: boolean
}) => {
  if (!visit) return null
  return (
    <StackView space={100} className="h-full" data-testid={testId}>
      {!hideHeader && <LetterHead tenant={visit.tenant} />}
      {!hideVisitDetails && <VisitDetails visit={visit} />}
      <Section header="Subjective">
        <ChiefComplaintSubSection visit={visit} />
        {visit.encounterMode === 'SIMPLIFIED' ? (
          <VisitSummarySubSection visit={visit} htmlStrings={htmlStrings} />
        ) : null}
        <HideForSimplifiedVisit visit={visit}>
          <HistorySubSection visit={visit} htmlStrings={htmlStrings} />
        </HideForSimplifiedVisit>
        <PastMedicalHistorySubSection visit={visit} htmlStrings={htmlStrings} />
        <HideForSimplifiedVisit visit={visit}>
          <ReviewOfSystemsSubSection visit={visit} />
        </HideForSimplifiedVisit>
        <AllergiesSubSection visit={visit} />
        <HideForSimplifiedVisit visit={visit}>
          <ImmunizationsSubSection visit={visit} />
        </HideForSimplifiedVisit>
        <HideForSimplifiedVisit visit={visit}>
          <DiagnosesSubSection visit={visit} />
        </HideForSimplifiedVisit>
        <FamilyHistorySubSection visit={visit} />
        <SocialHistorySubSection visit={visit} />
      </Section>
      <Section header="Objective">
        <VitalsSubSection visit={visit} />
        <HideForSimplifiedVisit visit={visit}>
          <PhysicalExamSubSection visit={visit} htmlStrings={htmlStrings} />
        </HideForSimplifiedVisit>
        <InHouseTestsSubSection visit={visit} />
      </Section>
      {calculateAge(visit.patient.birthDate).years >= 10 &&
        !hideAdolescentConfidentialInformation &&
        !isEmptyLexical(visit.documentationAdolescentConfidential) && (
          <Section header="Adolescent confidential">
            <DocumentationAdolescentConfidentialSubSection
              visit={visit}
              htmlStrings={htmlStrings}
            />
          </Section>
        )}
      <Section header="Assessment">
        <HideForSimplifiedVisit visit={visit}>
          <AssessmentNotesSubSection visit={visit} htmlStrings={htmlStrings} />
        </HideForSimplifiedVisit>
        <AssessmentDiagnosesSubSection visit={visit} />
      </Section>
      <Section header="Plan">
        <HideForSimplifiedVisit visit={visit}>
          <PlanNotesSubSection visit={visit} htmlStrings={htmlStrings} />
        </HideForSimplifiedVisit>
        <PlanOrdersSubSection visit={visit} />
        <ActiveMedicationsSubSection visit={visit} />
      </Section>

      {(!isEmptyLexical(visit.patientEducationAdditionalNotes) ||
        (calculateAge(visit.patient.birthDate).years >= 10 &&
          !isEmptyLexical(visit.patientEducationAdolescentConfidential)) ||
        visit.patientEducationDocuments.length > 0) && (
        <Section header="Patient education">
          <PatientEducationNotesSubSection
            visit={visit}
            htmlStrings={htmlStrings}
          />
          {!hideAdolescentConfidentialInformation ? (
            <PatientEducationAdolescentConfidentialSubSection
              visit={visit}
              htmlStrings={htmlStrings}
            />
          ) : null}
          <PatientEducationDocumentsSubSection visit={visit} />
        </Section>
      )}

      {!hideFooter && (
        <VisitFooter
          visit={visit}
          currentPractitioner={currentPractitioner}
          htmlStrings={htmlStrings}
        />
      )}
    </StackView>
  )
}

const HideForSimplifiedVisit = ({
  visit,
  children,
}: {
  visit: FindPatientVisit['appointment']
  children: React.ReactNode
}) => {
  if (visit.encounterMode === 'SIMPLIFIED') return null

  return children
}

export default PrintVisitNoteDocument
