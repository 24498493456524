import {
  UserCircleIcon,
  BookOpenIcon,
  UsersIcon,
  UserGroupIcon,
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  CalendarIcon,
  XMarkIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid'
import type { Icons } from 'types/graphql'

export const iconsStatusDisplay: {
  [key in Icons]: React.FunctionComponent<React.ComponentProps<'svg'>>
} = {
  BOOK_OPEN: BookOpenIcon,
  USER_CIRCLE: UserCircleIcon,
  USERS: UsersIcon,
  USER_GROUP: UserGroupIcon,
  ARROW_TRENDING_UP: ArrowTrendingUpIcon,
  ARROW_TRENDING_DOWN: ArrowTrendingDownIcon,
  CALENDAR: CalendarIcon,
  X_MARK: XMarkIcon,
  EXCLAMATION_TRIANGLE: ExclamationTriangleIcon,
}
