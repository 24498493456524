import type { MaritalStatus } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const maritalStatusDisplay: {
  [key in MaritalStatus]: string
} = {
  ANNULLED: 'Annulled',
  DIVORCED: 'Divorced',
  INTERLOCUTORY: 'Interlocutory',
  LEGALLY_SEPARATED: 'Legally separated',
  MARRIED: 'Married',
  COMMON_LAW: 'Common law',
  POLYGAMOUS: 'Polygamous',
  DOMESTIC_PARTNER: 'Domestic partner',
  UNMARRIED: 'Unmarried',
  NEVER_MARRIED: 'Never married',
  WIDOWED: 'Widowed',
}

export const maritalStatuses =
  nameValueArrayFromObject<MaritalStatus>(maritalStatusDisplay)
