import React, { Children, ReactChild, ReactElement, ReactNode } from 'react'

import { Disclosure, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

import Box from 'src/components/atoms/Box/Box'
import Card from 'src/components/atoms/Card/Card'
import Divider from 'src/components/atoms/Divider/Divider'

interface AccordionItemProps {
  title: string | ReactElement
  defaultOpen?: boolean
  children: ReactNode
  bordered?: boolean
  clickableHeader?: boolean
  headerClassName?: string
  isOpen?: boolean
  setIsOpen?: (boolean) => void
}

interface AccordionItemHeaderProps {
  setIsOpen: (boolean) => void
  title: string | ReactElement
  resolvedIsOpen?: boolean
  bordered?: boolean
  headerClassName?: string
}

const builderHeaderClasses = (
  bordered: boolean,
  resolvedIsOpen: boolean,
  headerClassName: string = null
) =>
  clsx(
    'flex w-full items-center justify-between py-4 px-6',
    headerClassName,
    bordered
      ? resolvedIsOpen
        ? 'rounded-t-lg border-l border-r border-t'
        : 'rounded-lg border'
      : ''
  )

const ClickableHeader = ({
  setIsOpen,
  title,
  resolvedIsOpen,
  bordered,
  headerClassName,
}: AccordionItemHeaderProps) => (
  <Disclosure.Button
    as="div"
    onClick={() => {
      if (setIsOpen) {
        setIsOpen(!resolvedIsOpen)
      }
    }}
    role="button"
    tabIndex={0}
    className={builderHeaderClasses(bordered, resolvedIsOpen, headerClassName)}
  >
    {typeof title == 'string' ? <span>{title}</span> : title}
    <ChevronDownIcon
      className={clsx(resolvedIsOpen && 'rotate-180 transform', 'h-5 w-5')}
    />
  </Disclosure.Button>
)

const ChevronClickableHeader = ({
  setIsOpen,
  title,
  resolvedIsOpen,
  bordered,
  headerClassName,
}: AccordionItemHeaderProps) => (
  <div
    className={builderHeaderClasses(bordered, resolvedIsOpen, headerClassName)}
  >
    {typeof title == 'string' ? <span>{title}</span> : title}
    <Disclosure.Button
      onClick={() => {
        if (setIsOpen) {
          setIsOpen(!resolvedIsOpen)
        }
      }}
    >
      <ChevronDownIcon
        className={clsx(resolvedIsOpen && 'rotate-180 transform', 'h-5 w-5')}
      />
    </Disclosure.Button>
  </div>
)

export function AccordionItem({
  children,
  title,
  defaultOpen = false,
  bordered = false,
  clickableHeader = true,
  headerClassName,
  isOpen,
  setIsOpen,
}: AccordionItemProps): ReactElement {
  return (
    <Disclosure as="div" className="mt-2" defaultOpen={defaultOpen}>
      {({ open: uncontrolledIsOpen }) => {
        const resolvedIsOpen =
          typeof isOpen === 'undefined' ? uncontrolledIsOpen : isOpen

        const HeaderComponent = clickableHeader
          ? ClickableHeader
          : ChevronClickableHeader

        return (
          <>
            <HeaderComponent
              resolvedIsOpen={resolvedIsOpen}
              setIsOpen={setIsOpen}
              title={title}
              bordered={bordered}
              headerClassName={headerClassName}
            />
            {typeof isOpen === 'undefined' ? (
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="text-gray-500">
                  {uncontrolledIsOpen && bordered && <Divider />}
                  {children}
                </Disclosure.Panel>
              </Transition>
            ) : (
              <Transition
                show={isOpen}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="text-gray-500" static>
                  {isOpen && bordered && <Divider />}
                  {children}
                </Disclosure.Panel>
              </Transition>
            )}
          </>
        )
      }}
    </Disclosure>
  )
}

type PaddingType = 0 | 25 | 50 | 75 | 100 | 125 | 150 | 175 | 200 | 2400

interface AccordionProps {
  children: ReactChild | ReactChild[]
  container?: 'box' | 'card' | 'list'
  divider?: boolean
  containerPadding?: PaddingType
  containerVerticalPadding?: PaddingType
  containerHorizontalPadding?: PaddingType
}

function Accordion({
  children,
  container = 'box',
  divider = false,
  containerPadding,
  containerVerticalPadding,
  containerHorizontalPadding,
}: AccordionProps): ReactElement {
  const paddingMapping = {
    x: {
      0: 'px-core-space-0',
      25: 'px-core-space-25',
      50: 'px-core-space-50',
      75: 'px-core-space-75',
      100: 'px-core-space-100',
      125: 'px-core-space-125',
      150: 'px-core-space-150',
      175: 'px-core-space-175',
      200: 'px-core-space-200',
      2400: 'px-core-space-2400',
    },
    y: {
      0: 'py-core-space-0',
      25: 'py-core-space-25',
      50: 'py-core-space-50',
      75: 'py-core-space-75',
      100: 'py-core-space-100',
      125: 'py-core-space-125',
      150: 'py-core-space-150',
      175: 'py-core-space-175',
      200: 'py-core-space-200',
      2400: 'py-core-space-2400',
    },
  }
  const arrayChildren = Children.toArray(children)
  const ContainerElement = container === 'box' ? Box : Card
  const containerClasses = [
    'mx-auto w-full rounded-2xl bg-white',
    containerPadding &&
      `${paddingMapping.x[containerPadding]} ${paddingMapping.y[containerPadding]}`,
    containerVerticalPadding && paddingMapping.y[containerVerticalPadding],
    containerHorizontalPadding && paddingMapping.x[containerHorizontalPadding],
  ]
  return (
    <ContainerElement className="w-full">
      <Box className={containerClasses.join().replace(/( )+/g, ' ')}>
        {arrayChildren.reduce((prev: ReactElement[], curr, index) => {
          const separatorKey = `separator-${index}`
          const boxKey = `box-${index}`
          const separator = <Divider key={separatorKey} />
          prev.push(<React.Fragment key={boxKey}>{curr}</React.Fragment>)
          if (index < arrayChildren.length - 1 && divider) {
            prev.push(separator)
          }
          return prev
        }, [])}
      </Box>
    </ContainerElement>
  )
}
Accordion.AccordionItem = AccordionItem
export default Accordion
