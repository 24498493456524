import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'

import { useFormContext } from '@redwoodjs/forms'
import { routes } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button/Button'
import { CheckboxField } from 'src/components/atoms/Checkbox/Checkbox'
import StackView from 'src/components/atoms/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import Typography from 'src/components/atoms/Typography'
import FormInputList from 'src/components/molecules/FormInputList'
import { TaskAssignmentComboboxField } from 'src/components/TaskAssignmentComboboxField/TaskAssignmentComboboxField'
import { openNewTab } from 'src/utils'

export const FollowUpStaffMessageTaskSection = ({
  staffMessageTaskId,
}: {
  staffMessageTaskId?: string
}) => {
  const formMethods = useFormContext()

  const shouldCreateStaffMessage = formMethods.watch('shouldCreateStaffMessage')

  if (staffMessageTaskId) {
    return (
      <StackView space={100} direction="row">
        <StackView space={25}>
          <Typography textStyle="title-xs">Task created</Typography>
          <Typography textStyle="body-s" color="text-base-color-fg-muted">
            A follow-up staff message task was created.
          </Typography>
        </StackView>

        <Button
          buttonStyle="secondary"
          onClick={() => {
            openNewTab(
              routes.cases({
                glob: `staff-message/${staffMessageTaskId}`,
              })
            )
          }}
          text="View task"
          icon={ArrowTopRightOnSquareIcon}
        />
      </StackView>
    )
  }

  return (
    <StackView space={100} direction="row">
      <StackView space={25}>
        <Typography textStyle="title-xs">
          Send a new follow-up staff message
        </Typography>
        <Typography textStyle="body-s" color="text-base-color-fg-muted">
          If applicable, send a follow up staff message with your task note
          below.
        </Typography>

        <FormInputList
          divider={false}
          items={[
            {
              name: 'shouldCreateStaffMessage',
              label: '',
              hideLabel: true,
              formInputComponent: CheckboxField,
              direction: 'col',
              inputProps: {
                label: 'Create a staff message task',
              },
            },
            {
              name: 'staffMessageUserOrUserPoolId',
              label: 'User or user pool',
              hide: !shouldCreateStaffMessage,
              required: shouldCreateStaffMessage,
              formInputComponent: TaskAssignmentComboboxField,
            },
            {
              name: 'staffMessageText',
              label: 'Message',
              hide: !shouldCreateStaffMessage,
              required: shouldCreateStaffMessage,
              formInputComponent: TextAreaField,
            },
          ]}
        />
      </StackView>
    </StackView>
  )
}
