import { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import {
  UpsertPatientConditionInput,
  SearchDiagnosisCodes,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'

import SelectField from 'src/components/atoms/SelectField/SelectField'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Alert from 'src/components/molecules/Alert/Alert'
import DiagnosisOccurrenceInputField from 'src/components/molecules/DiagnosisOccurrenceInputField/DiagnosisOccurrenceInputField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import GenericFormFieldArray from 'src/components/molecules/GenericFormFieldArray/GenericFormFieldArray'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { conditionStatuses } from 'src/data/conditionStatuses'
import {
  GroupedCondition,
  useUpsertCondition,
} from 'src/pages/PatientChartsPage/PatientHistory/Diagnoses/useConditions'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

type FormState = UpsertPatientConditionInput & {
  code: SearchDiagnosisCodes['searchDiagnosisCodes'][0]
}

interface SidepanelConditionEditProps {
  name: 'diagnosis' | 'zCode'
  nameDisplay: string
  existingConditions: Record<string, GroupedCondition>
}

const SidepanelConditionEdit = ({
  name,
  nameDisplay,
  existingConditions,
}: SidepanelConditionEditProps) => {
  const { patientId, diagnosisCode } = useParams()
  const { closeSidePanel } = useSidepanel()
  const [upsertPatientCondition, { loading: upsertingPatientCondition }] =
    useUpsertCondition()

  const diagnosisCodeEntry = existingConditions?.[diagnosisCode]

  const buildDefaultOccurrences = () =>
    diagnosisCodeEntry
      ? diagnosisCodeEntry.conditions.map((diagnosis) => ({
          onsetAt: diagnosis.onsetAt,
          abatedAt: diagnosis.abatedAt,
        }))
      : [
          {
            onsetAt: null,
            abatedAt: null,
          },
        ]

  const formMethods = useForm<FormState>({
    defaultValues: {
      occurrences: buildDefaultOccurrences(),
      status: diagnosisCodeEntry?.lastClinicalStatus ?? 'active',
    },
  })

  const occurrences = formMethods.watch('occurrences')

  useEffect(() => {
    formMethods.resetField('occurrences', {
      defaultValue: buildDefaultOccurrences(),
    })

    // adding diagnosis to dependency array will cause infinite re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnosisCode, formMethods])

  if (!diagnosisCodeEntry) {
    return null
  }

  const onSubmit = async (data: FormState) => {
    const input = {
      patientId,
      diagnosisCode,
      occurrences: data.occurrences ?? [],
      status: data.status,
    }
    upsertPatientCondition({
      variables: { input },
      onCompleted: closeSidePanel,
    })
  }

  return (
    <SidepanelPage
      testId={`sidepanel-${name}-edit`}
      header={`Edit ${nameDisplay}: ${diagnosisCodeEntry.display}`}
      description={diagnosisCodeEntry.code}
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Save and close',
          submitting: upsertingPatientCondition,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
        divider={false}
      >
        <StackView space={75}>
          <StackView className="pt-base-space-container-inset-m">
            <Typography textStyle="title-xs">Details</Typography>
            <Typography
              textStyle="body-s"
              color="text-base-color-fg-muted"
              className="!my-0"
            >{`Modify existing details regarding the ${nameDisplay}.`}</Typography>
          </StackView>
          <FormInputList
            items={[
              {
                name: 'status',
                label: 'Status',
                required: true,
                direction: 'col',
                formInputComponent: SelectField,
                inputProps: {
                  options: conditionStatuses.map((status) => ({
                    value: status.value,
                    display: status.name,
                  })),
                },
              },
              {
                name: 'occurrences',
                label: 'Occurence(s)',
                subtitle: `Click on 'Add occurrence' to add multiple occurrences of the same ${nameDisplay}. Selecting an end date is optional. To delete a ${nameDisplay}, remove all occurrences.`,
                direction: 'col',
                formInputComponent: GenericFormFieldArray,
                inputProps: {
                  formInputComponent: DiagnosisOccurrenceInputField,
                  addButtonLabel: 'Add occurrence',
                  addNewDefault: {
                    onsetAt: null,
                    abatedAt: null,
                  },
                  allowEmpty: true,
                  showItemErrors: false,
                },
              },
            ]}
          />
          {!occurrences && (
            <Alert
              title={`This ${nameDisplay} will be deleted`}
              description={`Removing all occurrences will delete this ${nameDisplay} from the patient's chart. This is different from marking the ${nameDisplay} as resolved.`}
            />
          )}
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelConditionEdit
