import { menstrualPainConceptCodings } from 'common/cdr/concepts/observations/menstrualHistories/index'
import { MenstrualPain } from 'types/graphql'

import { nameValueArrayFromObject } from 'src/data/utils'

export const menstrualPainDisplay: {
  [key in MenstrualPain]: string
} = {
  MILD: 'Mild',
  MODERATE: 'Moderate',
  SEVERE: 'Severe',
}

export const menstrualPainDisplayToEnum = Object.keys(
  menstrualPainDisplay
).reduce((acc, key) => {
  acc[menstrualPainDisplay[key]] = key
  return acc
}, {})

export const menstrualPainCodeToEnum = menstrualPainConceptCodings.reduce(
  (acc, coding) => {
    if (menstrualPainDisplayToEnum[coding.display]) {
      acc[coding.code] = menstrualPainDisplayToEnum[coding.display]
    }
    return acc
  },
  {}
)

export const menstrualPains =
  nameValueArrayFromObject<MenstrualPain>(menstrualPainDisplay)
