import { sexualOrientationConceptCodings } from 'common/cdr/concepts/observations/sexualHistories/index'
import type { SexualOrientation } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const sexualOrientationDisplay: {
  [key in SexualOrientation]: string
} = {
  HETEROSEXUAL: 'Heterosexual',
  HOMOSEXUAL: 'Homosexual',
  BISEXUAL: 'Bisexual',
  OTHER: 'Other',
  ASKED_BUT_UNKNOWN: "Don't know",
  CHOOSE_NOT_TO_DISCLOSE: 'Choose not to disclose',
}

export const sexualOrientationDisplayToEnum = Object.keys(
  sexualOrientationDisplay
).reduce((acc, key) => {
  acc[sexualOrientationDisplay[key]] = key
  return acc
}, {})

export const sexualOrientationCodeToEnum =
  sexualOrientationConceptCodings.reduce((acc, coding) => {
    if (sexualOrientationDisplayToEnum[coding.display]) {
      acc[coding.code] = sexualOrientationDisplayToEnum[coding.display]
    }
    return acc
  }, {})

export const sexualOrientations = nameValueArrayFromObject<SexualOrientation>(
  sexualOrientationDisplay
)
