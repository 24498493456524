import type {
  FindPracticeAdminDocumentQuery,
  FindPracticeAdminDocumentQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { practiceAdminDocumentTypeDisplay } from 'src/data/practiceAdminDocumentTypes'

import SidepanelDocumentPage from '../organisms/SidepanelDocumentPage/SidepanelDocumentPage'

export const QUERY = gql`
  query FindPracticeAdminDocumentQuery($id: String!) {
    practiceAdminDocument: practiceAdminDocument(id: $id) {
      id
      updatedAt
      updatedBy {
        id
        givenName
        familyName
      }
      type
      title
      binary {
        id
        content {
          url
          contentType
        }
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindPracticeAdminDocumentQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  practiceAdminDocument,
}: CellSuccessProps<
  FindPracticeAdminDocumentQuery,
  FindPracticeAdminDocumentQueryVariables
>) => {
  return (
    <SidepanelDocumentPage
      header={`Practice admin: ${
        practiceAdminDocumentTypeDisplay[practiceAdminDocument.type]
      }`}
      document={practiceAdminDocument}
      testId="practice-admin-document-view"
      faxable
      editRoute={`/documents/practice-admin/${practiceAdminDocument.id}/edit`}
    />
  )
}
