import { useParams } from 'react-router-dom'

import PracticeAdminDocumentEditCell from 'src/components/PracticeAdminDocumentEditCell'

const SidepanelPracticeAdminDocumentEdit = () => {
  const { practiceAdminDocumentId } = useParams()

  return <PracticeAdminDocumentEditCell id={practiceAdminDocumentId} />
}

export default SidepanelPracticeAdminDocumentEdit
