import { GetPatientImmunizationsToPrint } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'

import { LetterHead, PatientDetails } from './common'
import { ImmunizationPageTitle, ImmunizationTable } from './immunizations'

const PrintPatientImmunizationsDocument = ({
  patient,
  testId,
}: {
  patient: GetPatientImmunizationsToPrint['patient']
  testId?: string
}) => {
  return (
    <StackView space={100} className="h-full" data-testid={testId}>
      <LetterHead tenant={patient.tenant} />
      <PatientDetails patient={patient} />
      <ImmunizationPageTitle />
      <ImmunizationTable immunizations={patient.immunizations} />
    </StackView>
  )
}

export default PrintPatientImmunizationsDocument
