import { useParams } from 'react-router-dom'
import { DiagnosisCode, DiagnosisCodeFilter } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/dist/toast'

import { CheckboxField } from 'src/components/atoms/Checkbox'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import DiagnosisSearchField from 'src/components/organisms/DiagnosisSearchField/DiagnosisSearchField'
import { useCreateVisitTemplateDiagnosisMutation } from 'src/pages/VisitTemplatePage/useVisitTemplates'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

type FormState = {
  isPrimary: boolean
  code: DiagnosisCode
  codeSearchFilter: DiagnosisCodeFilter[]
  codeSearchInput: string
}

const SidepanelVisitTemplateDiagnosisCreate = () => {
  const { templateId } = useParams()
  const { closeSidePanel } = useSidepanel()
  const formMethods = useForm<FormState>({
    defaultValues: {
      isPrimary: false,
    },
  })
  const [addVisitTemplateDiagnosis, { loading: addingVisitTemplateDiagnosis }] =
    useCreateVisitTemplateDiagnosisMutation()

  const onSubmit = (data: FormState) => {
    addVisitTemplateDiagnosis({
      variables: {
        input: {
          visitTemplateId: templateId,
          diagnosisCodeId: data.code.id,
          isPrimaryDiagnosis: data.isPrimary,
        },
      },
      onCompleted: () => {
        toast.success('Visit template diagnosis added')
        closeSidePanel()
      },
    })
  }

  return (
    <SidepanelPage
      header="Add diagnosis"
      description="Complete all mandatory information. Diagnoses not linked to an order would be linked to the visit type-defined primary billing code."
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Add diagnosis',
          submitting: addingVisitTemplateDiagnosis,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <FormInputList
          items={[
            {
              name: 'code',
              label: 'Diagnosis code',
              required: true,
              direction: 'col',
              formInputComponent: DiagnosisSearchField,
              inputProps: {
                searchSet: 'ALL',
                defaultSearching: true,
              },
            },
            {
              name: 'isPrimary',
              label: '',
              hideLabel: true,
              direction: 'col',
              formInputComponent: CheckboxField,
              inputProps: {
                label: 'This is the primary diagnosis.',
                description:
                  'If there is an existing primary diagnosis, it will be replaced. The primary diagnosis code for the visit is always linked to the primary billing code for well child and E&M visit types.',
              },
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelVisitTemplateDiagnosisCreate
