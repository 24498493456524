import { parseISO } from 'date-fns'
import maxBy from 'lodash/maxBy'
import { PatientScores } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

const PATIENT_SCORES_QUERY = gql`
  query PatientScores($input: PatientScoresInput!) {
    patientScores(input: $input) {
      ... on AcoScore {
        score
        comments
        updatedAt
        history {
          action
          date
          stateTransition {
            previous
            current
          }
          user
        }
      }
    }
  }
`
export const usePatientScoresQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<PatientScores>(
    PATIENT_SCORES_QUERY,
    {
      variables: { input: { patientId } },
    }
  )

  const patientScores = data?.patientScores
  const patientScoresLastUpdated = data?.patientScores?.length
    ? maxBy(data?.patientScores, (a) => parseISO(a.updatedAt)).updatedAt
    : null

  return {
    patientScores,
    patientScoresLastUpdated,
    loading,
    error,
  }
}
