import { useMutation } from '@redwoodjs/web'

const SET_USER_SETTINGS_MUTATION = gql`
  mutation SetUserSettings($input: UserSettingsInput!) {
    setUserSettings(input: $input) {
      id
    }
  }
`

export const useSetUserSettingsMutation = () => {
  return useMutation(SET_USER_SETTINGS_MUTATION)
}
