import { keyBy } from 'lodash'

import { useForm } from '@redwoodjs/forms'

import InputField from 'src/components/atoms/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { UserListItem } from 'src/components/molecules/UserListItem/UserListItem'
import { UserMultiSelectDropdownField } from 'src/components/molecules/UserMultiSelectDropdownField/UserMultiSelectDropdownField'
import { useUsers } from 'src/hooks/useUsers/useUsers'
import { compareDisplayNames } from 'src/utils/user'

export interface TaskUserPoolSidepanelFormProps {
  onSubmit: (data: { displayName: string; userIds: string[] }) => void
  submitting: boolean
  defaultValues?: {
    displayName: string
    userIds: string[]
  }
  disableDisplayName?: boolean
}

export const TaskUserPoolSidepanelForm = ({
  onSubmit,
  submitting,
  defaultValues = { displayName: '', userIds: [] },
  disableDisplayName,
}: TaskUserPoolSidepanelFormProps) => {
  const { data } = useUsers()

  const allUsers = data?.users ?? []

  const usersById = keyBy(allUsers, (user) => user.id)

  const formMethods = useForm({
    defaultValues,
  })

  const userIds = formMethods.watch('userIds')

  const selectedUsers = userIds
    .map((id) => usersById[id])
    .filter(Boolean)
    .sort((a, b) => compareDisplayNames(a, b))

  return (
    <SidepanelForm
      formMethods={formMethods}
      divider={false}
      footerProps={{ submitText: 'Save', submitting }}
      onSubmit={onSubmit}
    >
      <FormInputList
        items={[
          {
            label: 'User pool name',
            name: 'displayName',
            direction: 'col',
            formInputComponent: InputField,
            required: true,
            inputProps: {
              disabled: disableDisplayName,
              validation: {
                minLength: {
                  value: 1,
                  message: 'User pool name must be at least 1 character',
                },
              },
            },
          },
          {
            label: 'Manage users',
            name: 'userIds',
            formInputComponent: UserMultiSelectDropdownField,
            inputContainerAlignItems: 'end',
            inputProps: {
              testId: 'task-user-pool-user-multi-select',
            },
          },
        ]}
      />
      <StackView direction="col" space={25}>
        {selectedUsers.map((user) => {
          return <UserListItem key={user.id} user={user} />
        })}
      </StackView>
    </SidepanelForm>
  )
}
