import { Message } from '@redwoodjs/forms'
import { SelectFieldProps } from '@redwoodjs/forms/dist/SelectField'

import SelectField from 'src/components/atoms/SelectField/SelectField'

export interface BooleanSelectFieldProps extends SelectFieldProps {
  trueDisplay: string
  falseDisplay: string
}

export const BooleanSelectField = ({
  trueDisplay,
  falseDisplay,
  ...rest
}: BooleanSelectFieldProps) => {
  return (
    <SelectField
      {...rest}
      validation={{
        ...rest.validation,
        required: false,
        validate: (value) => {
          if (rest.validation?.required && typeof value !== 'boolean') {
            return rest.validation?.required as Message
          }

          return true
        },
        setValueAs: (value) => {
          if (typeof value === 'boolean') {
            return value
          } else if (value === '' || value === null) {
            return null
          } else if (value === 'true') {
            return true
          } else if (value === 'false') {
            return false
          }

          throw "couldn't parse boolean value"
        },
      }}
      options={[
        {
          value: 'true',
          display: trueDisplay,
        },
        {
          value: 'false',
          display: falseDisplay,
        },
      ]}
    />
  )
}

export default BooleanSelectField
