import { DocumentSubType, DocumentType } from 'common/cdr/concepts/documents'
import { useParams } from 'react-router-dom'
import { FindPatientDocument } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import SidepanelDocumentPage, {
  GenericDisplayDocument,
} from 'src/components/organisms/SidepanelDocumentPage/SidepanelDocumentPage'
import { useDeleteDocument } from 'src/pages/PatientChartsPage/PatientDocuments/usePatientDocuments'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const shouldNotDeleteTypes: DocumentType[] = ['SIGNED_PRACTICE_FORM']

const shouldNotDeleteSubTypes: DocumentSubType[] = [
  'PRACTICE_VISIT_NOTE',
  'PRACTICE_AFTER_VISIT_SUMMARY',
  'INSURANCE_CARD',
]

const canDeleteDocument = (document: GenericDisplayDocument) => {
  return (
    !shouldNotDeleteTypes.includes(
      document['type']?.['code'] as DocumentType
    ) &&
    !shouldNotDeleteSubTypes.includes(
      document['subType']?.['code'] as DocumentSubType
    )
  )
}

export const QUERY = gql`
  query FindPatientDocument($id: String!) {
    document: document(id: $id) {
      id
      createdAt
      updatedAt
      authoredByReference
      authenticatedByReference
      encounterReference
      type {
        code
        display
        system
      }
      subType {
        code
        display
        system
      }
      title
      content {
        url
        contentType
      }
      docStatus
      textContent
    }
  }
`

const SidepanelPatientDocument = () => {
  const { patientDocumentId, id: patientId } = useParams()
  const { sidepanelContext } = useSidepanel()
  const { data, loading, error } = useQuery<FindPatientDocument>(QUERY, {
    variables: { id: patientDocumentId },
  })

  const [deletePatientDocument] = useDeleteDocument()

  if (loading || error) return null

  const genericDocument: GenericDisplayDocument = {
    ...data.document,
    binary: {
      id: data.document.id,
      content: {
        url: data.document.content.url,
        contentType: data.document.content.contentType,
      },
    },
  }

  return (
    <SidepanelDocumentPage
      header={`${data.document.type.display}${
        data.document.subType ? `: ${data.document.subType.display}` : ''
      }`}
      document={genericDocument}
      testId="patient-document-view"
      faxable={!sidepanelContext.disableFax ? true : false}
      editRoute={
        !sidepanelContext.disableEdit
          ? `/documents/patient-document/${patientDocumentId}/edit`
          : null
      }
      onDelete={
        canDeleteDocument(genericDocument)
          ? () =>
              deletePatientDocument({
                variables: { id: data.document.id, input: { patientId } },
              })
          : undefined
      }
    />
  )
}

export default SidepanelPatientDocument
