import { Popover } from '@headlessui/react'
import clsx from 'clsx'

import Button from '../atoms/Button/Button'

interface PopoverButtonProps {
  buttonProps: Omit<React.ComponentProps<typeof Button>, 'children' | 'onClick'>
  renderPanel: ({ close }) => React.ReactNode
  panelWidth?: string
  panelHeight?: string
  panelXPosition?: 'left' | 'right'
  panelZIndex?: number
  unmount?: boolean
  testId?: string
}

const PopoverButton: React.FC<PopoverButtonProps> = ({
  buttonProps,
  renderPanel,
  panelWidth = 'w-[30rem]',
  panelHeight = 'h-auto',
  panelXPosition = 'right',
  panelZIndex = 10,
  unmount = true,
  testId,
}) => {
  return (
    <Popover data-testid={testId}>
      {({ close }) => (
        <>
          <Popover.Button as={React.Fragment}>
            <Button tabIndex={-1} {...buttonProps} />
          </Popover.Button>
          <Popover.Panel
            className={clsx([
              'absolute right-0 mt-2 origin-top-right divide-y divide-gray-100  rounded-md bg-white shadow-lg',
              'ring-1 ring-black ring-opacity-5 focus:outline-none',
              'overflow-auto',
              panelWidth,
              panelHeight,
              `${panelXPosition}-0`,
            ])}
            style={{
              zIndex: panelZIndex,
            }}
            unmount={unmount}
          >
            {renderPanel({ close })}
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}

export default PopoverButton
