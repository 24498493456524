import type { ScreeningCase } from 'types/graphql'

import { BadgeColor } from 'src/components/atoms/Badge'

import { nameValueArrayFromObject } from './utils'

export const screeningCaseReasonDisplay: {
  [key in ScreeningCase['reason']]: string
} = {
  ABNORMAL_RESULT: 'Abnormal',
  CRITICAL_RESULT: 'Critical',
}

export const screeningCaseReasonBadgeColor: {
  [key in ScreeningCase['reason']]: BadgeColor
} = {
  ABNORMAL_RESULT: 'yellow',
  CRITICAL_RESULT: 'red',
}

export const screeningCaseReasonType = nameValueArrayFromObject<
  ScreeningCase['reason']
>(screeningCaseReasonDisplay)
