import { useContext, useState } from 'react'

import { useForm, UseFormReturn, UseFormSetValue } from '@redwoodjs/forms'

import { PractitionerAvailabilityInputForm } from 'src/components/Location/AvailabilityExceptionLocationCell'
import { VISIT_AVAILABILITY } from 'src/data/availabilityTypes'
import { createNamedContext } from 'src/utils'

interface State {
  newAvailabilityData?: PractitionerAvailabilityInputForm
}

const WeeklyScheduleContext = createNamedContext<
  State & {
    formMethods: UseFormReturn<PractitionerAvailabilityInputForm>
    setAvailabilityFormValue: UseFormSetValue<PractitionerAvailabilityInputForm>
    showDetailsModal: boolean
    showEditCreateModal: boolean
    setIsUpdating: (b: boolean) => void
    setShowEditCreateModal: (b: boolean) => void
    setShowDetailsModal: (b: boolean) => void
    closeModal: () => void
    modalOpen: boolean
  }
>('WeeklyScheduleContext')

export function useWeeklySchedule() {
  const context = useContext(WeeklyScheduleContext)
  return context
}

export function WeeklyScheduleContextProvider({ children }) {
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false)
  const [showEditCreateModal, setShowEditCreateModal] = useState<boolean>(false)
  const formMethods = useForm<PractitionerAvailabilityInputForm>({
    mode: 'onSubmit',
    defaultValues: {
      location: '',
      time: {
        start: '',
        end: '',
      },
      isUpdating: false,
      eventId: undefined,
      originalEvent: undefined,
      availabilityType: VISIT_AVAILABILITY,
    },
  })
  const closeModal = () => {
    setShowEditCreateModal(false)
    setShowDetailsModal(false)
  }
  const setIsUpdating = (isUpdating) => {
    formMethods.setValue('isUpdating', isUpdating)
  }
  const { setValue } = formMethods
  const modalOpen = showDetailsModal || showEditCreateModal
  return (
    <WeeklyScheduleContext.Provider
      value={{
        formMethods,
        setAvailabilityFormValue: setValue,
        setIsUpdating,
        setShowDetailsModal,
        closeModal,
        showDetailsModal,
        setShowEditCreateModal,
        showEditCreateModal,
        modalOpen,
      }}
    >
      {children}
    </WeeklyScheduleContext.Provider>
  )
}

export default WeeklyScheduleContext
