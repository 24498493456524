import { DocumentTypesConfig } from 'common/cdr/concepts/documents'
import type { PracticeAdminDocumentType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const practiceAdminDocumentTypeDisplay: {
  [key in PracticeAdminDocumentType]: string
} = {
  ...DocumentTypesConfig['PRACTICE_ADMIN']['subTypes'],
}

export const practiceAdminDocumentTypes =
  nameValueArrayFromObject<PracticeAdminDocumentType>(
    practiceAdminDocumentTypeDisplay
  )
