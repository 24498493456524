import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import groupBy from 'lodash/groupBy'
import { useParams } from 'react-router-dom'
import {
  ListFavoriteOrdersForSidepanel,
  ListFavoriteOrdersForSidepanelVariables,
} from 'types/graphql'

import { Controller, useForm } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import Box from 'src/components/atoms/Box/Box'
import Divider from 'src/components/atoms/Divider/Divider'
import InputField from 'src/components/atoms/InputField/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { DropdownMenuItem } from 'src/components/DropdownMenu/DropdownMenuItem'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { orderCategoryConfig } from 'src/data/orderCategories'
import { useCreateOrderMutation } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const QUERY = gql`
  query ListFavoriteOrdersForSidepanel {
    favoriteOrders {
      id
      orderCategory
      name
      code
      codeDisplay
      defaultCPTCode
      defaultICDCode
    }
  }
`

const SidepanelFavoriteOrdersCreate = () => {
  const { currentUser } = useEmrAuth()
  const { patientId } = useParams()
  const { sidepanelContext, closeSidePanel } = useSidepanel()
  const encounterId = sidepanelContext.encounterId as string

  const { data } = useQuery<
    ListFavoriteOrdersForSidepanel,
    ListFavoriteOrdersForSidepanelVariables
  >(QUERY)
  const [createOrder] = useCreateOrderMutation()

  const formMethods = useForm({
    defaultValues: {
      query: '',
      orders: [],
    },
  })
  const query = formMethods.watch('query') ?? ''
  const orderIds = formMethods.watch('orders')

  const favoriteOrders = data?.favoriteOrders ?? []
  const groupedOrders = groupBy(favoriteOrders, (order) => order.orderCategory)

  const onSubmit = async (data) => {
    const orders: ListFavoriteOrdersForSidepanel['favoriteOrders'] =
      data.orders.map((orderId) =>
        favoriteOrders.find((order) => order.id === orderId)
      )

    for (const order of orders) {
      await createOrder({
        variables: {
          input: {
            patientId,
            encounterId,
            category: order.orderCategory,
            orderInput: {
              status: 'ACTIVE',
              code: order.code,
              cptCode: order.defaultCPTCode,
              flatDiagnosisCodes: [order.defaultICDCode].filter(Boolean),
              intent:
                currentUser.roles.includes('PRACTITIONER') ||
                orderCategoryConfig[
                  order.orderCategory
                ].canBeSignedByNonPractitioner(currentUser)
                  ? 'ORDER'
                  : 'PROPOSAL',
              isUrgent: false,
            },
          },
        },
      })
    }

    closeSidePanel()
  }

  const ordersToCreate = orderIds.map((orderId) =>
    favoriteOrders.find((order) => order.id === orderId)
  )

  const canSignAllOrders =
    currentUser.roles.includes('PRACTITIONER') ||
    ordersToCreate.every((order) =>
      orderCategoryConfig[order.orderCategory].canBeSignedByNonPractitioner(
        currentUser
      )
    )

  return (
    <SidepanelPage
      header="Add favorite orders"
      description="Select all orders that need to be added."
    >
      <SidepanelForm
        formMethods={formMethods}
        footerProps={{
          submitting: formMethods.formState.isSubmitting,
          submitText: canSignAllOrders ? 'Sign orders' : 'Pend orders',
          disabled: !formMethods.formState.isValid,
        }}
        containerClassName="!px-0"
        divider={false}
        onSubmit={onSubmit}
      >
        <Box className="px-core-space-150 py-core-space-50">
          <InputField
            name="query"
            placeholder="Search by order name or type"
            iconRight={MagnifyingGlassIcon}
          />
        </Box>
        <Divider />
        <Controller
          control={formMethods.control}
          name="orders"
          rules={{
            minLength: 1,
          }}
          render={({ field }) => {
            const selectedOrderIds = field.value

            return (
              <StackView>
                <StackView className="px-core-space-100">
                  {selectedOrderIds.map((orderId) => {
                    const order = favoriteOrders.find(
                      (order) => order.id === orderId
                    )
                    return (
                      <DropdownMenuItem
                        key={orderId}
                        selected
                        direction="col"
                        label={order.name}
                        description={
                          order.orderCategory === 'VAX'
                            ? [order.code, order.codeDisplay]
                                .filter(Boolean)
                                .join(' - ')
                            : [order.defaultICDCode, order.defaultCPTCode]
                                .filter(Boolean)
                                .join(' \u2022 ')
                        }
                        displaySelected="checkbox"
                        onClick={() =>
                          field.onChange(
                            selectedOrderIds.filter(
                              (value) => value !== orderId
                            )
                          )
                        }
                      />
                    )
                  })}
                </StackView>
                {selectedOrderIds.length ? <Divider /> : null}
                <StackView className="px-core-space-100">
                  {Object.entries(groupedOrders).map(([category, orders]) => {
                    const filteredOrders = orders.filter((order) => {
                      return (
                        order.name
                          .toLocaleLowerCase()
                          .includes(query.toLowerCase()) &&
                        !selectedOrderIds.includes(order.id)
                      )
                    })

                    if (!filteredOrders.length) return null

                    return (
                      <StackView key={category}>
                        <Box className="px-core-space-75 pb-core-space-25 pt-core-space-75">
                          <Typography
                            textStyle="interface-strong-xs"
                            color="text-base-color-fg-subtle"
                          >
                            {orderCategoryConfig[category].display}
                          </Typography>
                        </Box>
                        {filteredOrders.map((order) => {
                          return (
                            <DropdownMenuItem
                              key={order.id}
                              selected={false}
                              label={order.name}
                              description={
                                order.orderCategory === 'VAX'
                                  ? [order.code, order.codeDisplay]
                                      .filter(Boolean)
                                      .join(' - ')
                                  : [order.defaultICDCode, order.defaultCPTCode]
                                      .filter(Boolean)
                                      .join(' \u2022 ')
                              }
                              displaySelected="checkbox"
                              direction="col"
                              onClick={() =>
                                field.onChange([order.id, ...selectedOrderIds])
                              }
                            />
                          )
                        })}
                      </StackView>
                    )
                  })}
                </StackView>
              </StackView>
            )
          }}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelFavoriteOrdersCreate
