import {
  SendPaymentReminders,
  SendPaymentRemindersVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

const SEND_REMINDER = gql`
  mutation SendPaymentReminders($guarantors: [GuarantorIdInput!]!) {
    sendFamilyBalanceReminders(guarantors: $guarantors) {
      successfullyQueuedMessageCount
      unsentMessages {
        guarantor {
          id
          guarantorType
        }
        reason
      }
    }
  }
`
export const useSendPaymentReminders = () =>
  useMutation<SendPaymentReminders, SendPaymentRemindersVariables>(
    SEND_REMINDER
  )
