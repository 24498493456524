import { DocumentTypesConfig } from 'common/cdr/concepts/documents'
import type { ScreeningToolType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const screeningToolTypeDisplay: {
  [key in ScreeningToolType]: string
} = {
  ...DocumentTypesConfig['SCREENING_TOOL']['subTypes'],
}

export const screeningToolTypes = nameValueArrayFromObject<ScreeningToolType>(
  screeningToolTypeDisplay
)
