import { Order } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

const ORDER_PERFORMERS_QUERY = gql`
  query GetOrder($id: String!) {
    order(id: $id) {
      id
      category
      performer
      existingContactPerformer {
        contactId
        display
        organization
        address {
          line1
          line2
          city
          district
          state
          postalCode
        }
        emailAddress
        faxNumber
        workNumber
        display
      }
    }
  }
`
export const useOrderPerformers = (category: Order) => {
  return useQuery(ORDER_PERFORMERS_QUERY, {
    variables: {
      category,
    },
  })
}

export const useGetOrderPerformers = (
  orderId: string
): {
  existingContactPerformer: Order['existingContactPerformer']
  performer: Order['performer']
  orderCategory: Order['category']
  loading: boolean
} => {
  const { data, loading } = useQuery(ORDER_PERFORMERS_QUERY, {
    variables: {
      id: orderId,
    },
  })
  return {
    existingContactPerformer: data?.order?.existingContactPerformer,
    performer: data?.order?.performer,
    orderCategory: data?.order?.category,
    loading,
  }
}
