import { appointmentTypeDisplay } from 'common/data/appointmentTypes'
import {
  chiefComplaintConfig,
  chiefComplaintVisitTypeOrder,
  chiefComplaints,
} from 'common/data/chiefComplaints'
import { FindAppointmentDefinition } from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import MultiSelectComboBoxFieldArray, {
  MultiSelectComboBoxFieldArrayProps,
} from 'src/components/molecules/MultiSelectComboBoxFieldArray/MultiSelectComboBoxFieldArray'

import LoadingSpinner from '../LoadingSpinner'

export type ChiefComplaintSelectFieldProps = Omit<
  MultiSelectComboBoxFieldArrayProps,
  'options' | 'addSelectionText'
> & {
  appointmentDefinitionId: string
  addSelectionText?: string
  listAllComplaints?: boolean
}

const QUERY = gql`
  query FindAppointmentDefinition($appointmentDefinitionId: String!) {
    appointmentDefinition(id: $appointmentDefinitionId) {
      id
      name
      duration
      type
    }
  }
`

export const ChiefComplaintSelectField = ({
  appointmentDefinitionId,
  name,
  addSelectionText = 'Add chief complaint',
  listAllComplaints = false,
  ...props
}: ChiefComplaintSelectFieldProps) => {
  const { data, loading } = useQuery<FindAppointmentDefinition>(QUERY, {
    variables: { appointmentDefinitionId },
    skip: !appointmentDefinitionId,
  })

  const { watch } = useFormContext()
  const valueArray = watch(name)

  if (loading) return <LoadingSpinner />
  const allOptions = chiefComplaintVisitTypeOrder.reduce((acc, visitType) => {
    acc.push({
      name: appointmentTypeDisplay[visitType],
      value: visitType,
      heading: true,
    })
    acc.push(
      ...chiefComplaints
        .filter(({ value }) =>
          chiefComplaintConfig[value].appointmentType.includes(visitType)
        )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((complaint) => {
          return {
            ...complaint,
            synonyms: [appointmentTypeDisplay[visitType]],
            key: `${visitType}-${complaint.value}`,
          }
        })
    )
    return acc
  }, [])

  const options = listAllComplaints
    ? allOptions
    : appointmentDefinitionId && data?.appointmentDefinition
    ? chiefComplaints
        .filter(({ value }) => {
          return (
            valueArray?.includes(value) ||
            chiefComplaintConfig[value].appointmentType.includes(
              data.appointmentDefinition.type
            )
          )
        })
        .sort((a, b) => {
          if (a.value === 'OTHER') return 1
          return a.name.localeCompare(b.name)
        })
    : []

  return (
    <MultiSelectComboBoxFieldArray
      testId="chiefComplaintSelectField"
      name={name}
      {...props}
      options={options}
      addSelectionText={addSelectionText}
      limit={listAllComplaints ? Infinity : chiefComplaints.length}
      minimumValueLength={0}
      firstInputValue={null}
    />
  )
}

export default ChiefComplaintSelectField
