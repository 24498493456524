import { useEffect, useState } from 'react'

import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { useParams } from 'react-router-dom'
import { ResolveScreeningCase } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useParams as useParamsRW } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import Divider from 'src/components/atoms/Divider/Divider'
import { DropdownField } from 'src/components/atoms/Dropdown'
import { FieldLabel } from 'src/components/atoms/Label'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField'
import Typography from 'src/components/atoms/Typography/Typography'
import FieldError from 'src/components/FieldError/FieldError'
import { DataRow } from 'src/components/molecules/DataRow/DataRow'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useHandleCaseResolution } from 'src/hooks/useHandleCaseResolution/useHandleCaseResolution'
import { codeGeneration } from 'src/pages/CasesPage/utils/codeGeneration'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

export const RESOLVE_SCREENING_CASE_MUTATION = gql`
  mutation ResolveScreeningCase(
    $id: String!
    $input: ResolveScreeningCaseInput!
  ) {
    resolveScreeningCase(id: $id, input: $input) {
      id
      resolvedAt
      resolvedBy {
        id
        givenName
        familyName
      }
      resolvedStatus
      baseCase {
        id
        updatedAt
        screeningCase {
          id
        }
      }
    }
  }
`

const READ_PATIENT = gql`
  query ReadPatientScreeningCase($id: String!, $screeningCaseId: String!) {
    screeningCase(id: $screeningCaseId) {
      id
      baseCaseId
      code
    }
    patient(id: $id) {
      id
      givenName
      familyName
      contactInformation {
        id
        mobileNumber
        homeAddress {
          id
          line1
          line2
          city
          state
          postalCode
        }
      }
      patientRelatedPersonRelationships {
        id
        doesResideWith
        relationshipType
        relatedPerson {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
          contactInformation {
            id
            mobileNumber
            emailAddress
          }
        }
      }
    }
  }
`

export const CREATE_SCREENING_CASE_STATUS_UPDATE_MUTATION = gql`
  mutation CreateScreeningCaseStatusUpdate(
    $input: CreateCaseStatusUpdateInput!
  ) {
    createCaseStatusUpdate(input: $input) {
      id
      baseCaseId
      text
      baseCase {
        id
        updatedAt
        screeningCase {
          id
        }
      }
    }
  }
`

enum ScreeningCaseReviewerType {
  CAREGIVER = 'CAREGIVER',
  PATIENT = 'PATIENT',
  BOTH_PATIENT_AND_CAREGIVER = 'BOTH_PATIENT_AND_CAREGIVER',
  NEITHER_PATIENT_OR_CAREGIVER = 'NEITHER_PATIENT_OR_CAREGIVER',
}

enum ScreeningCaseResultsDiscussionType {
  VIRTUAL = 'VIRTUAL',
  IN_CLINIC = 'IN_CLINIC',
}

interface FormProps {
  resultsReviewedAnswerCode: 'YES' | 'NO'
  resultsDiscussedWith: ScreeningCaseReviewerType
  resultsDiscussionType: ScreeningCaseResultsDiscussionType
  comments?: string
  patientId: string
  patient: { id: string }
  caregiverId?: string
}

export const SidepanelScreeningCaseResolution = () => {
  const { patientId } = useParams()
  const paramsRW = useParamsRW()
  const { closeSidePanel } = useSidepanel()

  const [caregiversLoaded, setCaregiversLoaded] = useState(false)

  const { handleCaseResolution, getNextTaskId } = useHandleCaseResolution()
  const [resolveScreeningCase, { loading: _resolvingCase }] =
    useMutation<ResolveScreeningCase>(RESOLVE_SCREENING_CASE_MUTATION)

  const [createCaseStatusUpdate, { loading: updatingCaseStatus }] = useMutation(
    CREATE_SCREENING_CASE_STATUS_UPDATE_MUTATION
  )

  const { glob } = paramsRW

  const screeningCaseId = glob?.split('/')[1]
  const { data, loading } = useQuery(READ_PATIENT, {
    variables: {
      id: patientId,
      screeningCaseId,
    },
    fetchPolicy: 'cache-first',
  })

  const relatedPersonOptions = (
    data?.patient?.patientRelatedPersonRelationships || []
  ).map(({ relatedPerson, relationshipType }) => {
    return {
      key: relatedPerson.id,
      id: relatedPerson.id,
      value: relatedPerson.id,
      name: `${formatDisplayName(relatedPerson)} - ${
        relationshipTypeDisplay[relationshipType]
      }`,
    }
  })

  const formMethods = useForm<FormProps>({
    defaultValues: {
      resultsReviewedAnswerCode: 'YES',
      resultsDiscussedWith: undefined,
      comments: undefined,
      caregiverId:
        relatedPersonOptions.length > 0
          ? relatedPersonOptions[0].id
          : undefined,
    },
  })

  useEffect(() => {
    if (!caregiversLoaded && relatedPersonOptions.length > 0) {
      formMethods.setValue('caregiverId', relatedPersonOptions[0].id)
      setCaregiversLoaded(true)
    }
  }, [relatedPersonOptions, caregiversLoaded, formMethods])

  if (loading) {
    return <LoadingSpinner />
  }

  const [resultsReviewedAnswerCode, resultsDiscussedWith] = formMethods.watch([
    'resultsReviewedAnswerCode',
    'resultsDiscussedWith',
  ])

  const baseCaseId = data?.screeningCase?.baseCaseId

  const onSubmit = async (formData: FormProps) => {
    const promises = []

    const createScreeningCaseStatusUpdatePromise = ({
      updateText,
    }: {
      updateText: string
    }) => {
      return new Promise((res) => {
        createCaseStatusUpdate({
          variables: {
            input: {
              type: 'SCREENING',
              baseCaseId,
              text: updateText,
              icon: 'USER_CIRCLE',
            },
          },
          onCompleted: () => res(true),
          refetchQueries: [
            'FindScreeningCases',
            'GetActivityItems',
            'GetNotResolvedCasesCountByType',
          ],
        })
      })
    }

    if (formData.resultsReviewedAnswerCode === 'YES') {
      if (formData.resultsDiscussedWith) {
        promises.push(
          createScreeningCaseStatusUpdatePromise({
            updateText: formData.resultsDiscussedWith.toString(),
          })
        )
      }
      if (formData.resultsDiscussionType) {
        promises.push(
          createScreeningCaseStatusUpdatePromise({
            updateText: formData.resultsDiscussionType.toString(),
          })
        )
      }
    }
    await Promise.all(promises)
    const nextTaskId = await getNextTaskId({ taskId: screeningCaseId })
    await resolveScreeningCase({
      variables: {
        id: screeningCaseId,
        input: formData,
      },
      onCompleted: () => {
        handleCaseResolution({ nextTaskId })
      },
    })

    closeSidePanel()
  }

  const code = data?.screeningCase ? codeGeneration(data.screeningCase) : '...'

  const cancelCase = () => {
    resolveScreeningCase({
      variables: {
        id: screeningCaseId,
        input: {
          cancelScreeningCase: true,
        },
      },
      refetchQueries: ['FindScreeningCases', 'GetNotResolvedCasesCountByType'],
      onCompleted: () => {
        closeSidePanel()
      },
    })
  }
  return (
    <SidepanelPage
      header={`Resolve task: ${code}`}
      description="Check the option(s) that apply before resolving the task."
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Resolve task',
          submitting: updatingCaseStatus,
          cancelText: 'Cancel task',
          onCancel: cancelCase,
        }}
        autoComplete="off"
        title="Resolve task"
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <StackView space={25}>
          <Space space={75} />
          <Typography textStyle="title" fontWeight="medium">
            Resolution status options
          </Typography>
          <Space space={75} />
          <Divider />

          <FormInputList
            items={[
              {
                name: 'resultsReviewedAnswerCode',
                label: 'Were the results reviewed?',
                required: true,
                formInputComponent: MultiRadioButtonField,
                inputProps: {
                  labelClassName: 'font-normal',
                  values: [
                    {
                      label: 'Yes',
                      value: 'YES',
                    },
                    {
                      label: 'No',
                      value: 'NO',
                    },
                  ],
                },
              },
            ]}
          />

          <Divider />

          {resultsReviewedAnswerCode === 'YES' && (
            <DataRow
              label={
                <StackView space={50}>
                  <FieldLabel name="resultsDiscussedWith" required={true}>
                    Who were the results discussed with?
                  </FieldLabel>
                </StackView>
              }
              value={
                <StackView space={75}>
                  <MultiRadioButtonField
                    name="resultsDiscussedWith"
                    labelClassName="font-normal"
                    validation={{
                      required:
                        resultsReviewedAnswerCode === 'YES' ? true : false,
                      shouldUnregister: true,
                    }}
                    values={[
                      {
                        label: 'Caregiver',
                        value: 'CAREGIVER',
                        disabled: !relatedPersonOptions.length,
                      },
                      {
                        label: 'Patient',
                        value: 'PATIENT',
                      },
                      {
                        label: 'Both caregiver and patient',
                        value: 'BOTH_PATIENT_AND_CAREGIVER',
                        disabled: !relatedPersonOptions.length,
                      },
                      {
                        label: 'Did not discuss with caregiver and/or patient',
                        value: 'NEITHER_PATIENT_OR_CAREGIVER',
                      },
                    ]}
                  />
                  {data.patient && // unregistered patients won't have caregivers to select
                    (resultsDiscussedWith ===
                      ScreeningCaseReviewerType.BOTH_PATIENT_AND_CAREGIVER ||
                      resultsDiscussedWith ===
                        ScreeningCaseReviewerType.CAREGIVER) && (
                      <StackView direction="col" space={50}>
                        <FieldLabel name="caregiverId">
                          Please select a caregiver
                        </FieldLabel>
                        <DropdownField
                          options={relatedPersonOptions}
                          name="caregiverId"
                          validation={{
                            required:
                              resultsReviewedAnswerCode === 'YES'
                                ? true
                                : false,
                            shouldUnregister: true,
                          }}
                        />
                        <FieldError name="caregiverId" label="Caregiver" />
                      </StackView>
                    )}
                  <FieldError
                    label="Who were the results discussed with?"
                    name="resultsDiscussedWith"
                  />
                </StackView>
              }
            />
          )}

          {(resultsDiscussedWith ===
            ScreeningCaseReviewerType.BOTH_PATIENT_AND_CAREGIVER ||
            resultsDiscussedWith === ScreeningCaseReviewerType.CAREGIVER) && (
            <FormInputList
              items={[
                {
                  name: 'resultsDiscussionType',
                  label: 'How were the results discussed?',
                  formInputComponent: MultiRadioButtonField,
                  required: resultsReviewedAnswerCode === 'YES' ? true : false,
                  inputProps: {
                    labelClassName: 'font-normal',
                    values: [
                      {
                        label: 'In clinic',
                        value: 'IN_CLINIC',
                      },
                      {
                        label: 'Virtually',
                        value: 'VIRTUAL',
                      },
                    ],
                  },
                },
              ]}
            />
          )}
          <FormInputList
            items={[
              {
                label: 'Comments',
                name: 'comments',
                formInputComponent: TextAreaField,
                inputProps: {
                  emptyAs: '',
                  rows: 5,
                },
              },
            ]}
          />
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelScreeningCaseResolution
