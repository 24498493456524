import { GetPatientForGenericPdf, Practitioner } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

import { LetterHead, PatientDetails, Signature } from './common'

const PrintGenericPatientDocument = ({
  patient,
  title,
  content,
  tenant,
  practitioner,
  signedByPractitioner,
  addSignature = false,
  testId,
}: {
  patient: GetPatientForGenericPdf['patient']
  title?: string
  content: string
  tenant: GetPatientForGenericPdf['patient']['tenant']
  practitioner: GetPatientForGenericPdf['practitioner']
  signedByPractitioner?: Practitioner
  addSignature?: boolean
  testId?: string
}) => {
  return (
    <StackView space={100} className="h-full" data-testid={testId}>
      <LetterHead tenant={tenant} />
      <PatientDetails
        patient={patient}
        showCaregivers={false}
        showPrimaryPractitioner={false}
        showSigningPractitioner={true}
        signingPractitioner={practitioner}
      />
      {title && <Typography textStyle="title-s">{title}</Typography>}
      <div
        className="mt-core-space-100 border-t-core-border-width-40 border-base-color-border-subtle pt-core-space-100"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
      {addSignature && (
        <Signature
          signedAt={new Date().toISOString()}
          practitioner={signedByPractitioner ?? practitioner}
        />
      )}
    </StackView>
  )
}

export default PrintGenericPatientDocument
