import { useParams } from 'react-router-dom'

import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'

import ContactOrganizationEditCell from './ContactOrganizationEditCell'

const SidepanelContactEdit = () => {
  const { contactOrganizationId } = useParams()

  return (
    <SidepanelPage
      testId="sidepanel-contact-organization-edit"
      header="Edit contact organization"
    >
      <ContactOrganizationEditCell id={contactOrganizationId} />
    </SidepanelPage>
  )
}

export default SidepanelContactEdit
