import {
  CreateBinaryInput,
  ResolveDocumentCaseMultiFaxMutation,
  ResolveDocumentCaseMultiFaxMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useHandleCaseResolution } from 'src/hooks/useHandleCaseResolution/useHandleCaseResolution'
import { RESOLVE_DOCUMENT_CASE_MULTIFAX_MUTATION } from 'src/pages/Sidepanel/SidepanelDocumentCaseResolution'

export const useResolveDocumentCaseMultiFax = ({
  documentCaseId,
  onCompleted,
}: {
  documentCaseId: string
  onCompleted: () => void
}) => {
  const { handleCaseResolution, getNextTaskId } = useHandleCaseResolution()
  const [resolveDocumentCaseMultiFax, mutationResult] = useMutation<
    ResolveDocumentCaseMultiFaxMutation,
    ResolveDocumentCaseMultiFaxMutationVariables
  >(RESOLVE_DOCUMENT_CASE_MULTIFAX_MUTATION, {
    refetchQueries: [
      'FindDocumentCases',
      'GetNotResolvedCasesCountByType',
      'GetTaskNotificationCount',
    ],
    onCompleted,
  })
  const resolveCaseMultiFax = async (
    input,
    sendEFaxBackToSenderBinary?: CreateBinaryInput
  ) => {
    const nextTaskId = await getNextTaskId({ taskId: documentCaseId })

    return resolveDocumentCaseMultiFax({
      variables: {
        documentCaseId,
        input,
        sendEFaxBackToSenderBinary,
      },
      onCompleted: () => {
        void handleCaseResolution({ nextTaskId })
      },
    })
  }

  return [resolveCaseMultiFax, mutationResult] as const
}
