import type { Sex } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const sexDisplay: {
  [key in Sex]: string
} = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
  UNKNOWN: 'Unknown',
}

export const sexes = nameValueArrayFromObject<Sex>(sexDisplay)

export const sexAbbreviation: {
  [key in Sex]: string
} = {
  MALE: 'M',
  FEMALE: 'F',
  OTHER: 'O',
  UNKNOWN: 'U',
}
