import { ReactElement } from 'react'

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'

import StackView from 'src/components/atoms/StackView/StackView'

import Typography from '../Typography/Typography'

interface ChevronLinkProps {
  link?: string
  text?: string
  chevronPosition?: 'left' | 'right'
  onClick?: () => void
  underline?: boolean
  icon?: React.FunctionComponent<React.ComponentProps<'svg'>>
  iconColor?: string
  className?: string
  testId?: string
}

const ChevronLink = ({
  link,
  text,
  chevronPosition = 'left',
  onClick,
  underline = false,
  iconColor,
  icon: Icon = chevronPosition === 'left' ? ChevronLeftIcon : ChevronRightIcon,
  className,
  testId,
}: ChevronLinkProps): ReactElement => {
  return (
    <a
      href={link}
      className={clsx(
        'cursor-pointer whitespace-nowrap text-base-color-fg-muted hover:text-base-color-fg-default',
        underline && 'underline',
        className
      )}
      onClick={onClick}
      data-testid={testId}
    >
      <StackView direction="row" space={25} alignItems="center">
        {chevronPosition === 'left' && (
          <Icon
            className={clsx(
              'h-4 w-4 flex-shrink-0',
              iconColor || 'text-gray-500'
            )}
            aria-hidden="true"
          />
        )}
        {text && <Typography textStyle="interface-strong-s">{text}</Typography>}
        {chevronPosition === 'right' && (
          <Icon
            className={clsx(
              'h-4 w-4 flex-shrink-0',
              iconColor || 'text-gray-500'
            )}
            aria-hidden="true"
          />
        )}
      </StackView>
    </a>
  )
}

export default ChevronLink
