import { formatMoneyInCents } from 'common/utils'
import { format } from 'date-fns'
import { capitalize } from 'lodash'
import { useParams } from 'react-router-dom'

import Badge from 'src/components/atoms/Badge'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import DataDisplayList from 'src/components/molecules/DataDisplayList'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import {
  PAYMENT_STATUS,
  PAYMENT_TYPES,
} from 'src/components/PatientFinancials/components/HistoricalPatientPaymentsTable'
import { usePaymentPlanRefundQuery } from 'src/hooks/usePatientRefunds/usePatientRefunds'

const SidepanelPaymentPlanRefundView = () => {
  const params = useParams()
  const { paymentPlanRefundId } = params

  const { paymentPlanRefund, loading } = usePaymentPlanRefundQuery({
    paymentPlanRefundId,
  })

  return (
    <SidepanelPage
      testId="payment-plan-refund-view"
      header="Payment plan refund"
      loading={loading}
    >
      <StackView className="grow p-core-space-150" space={50}>
        {paymentPlanRefund && (
          <DataDisplayList
            title="Details"
            leftColumnWidth="lg"
            data={[
              {
                label: 'Amount',
                value: formatMoneyInCents(paymentPlanRefund.amountCents),
              },
              {
                label: 'Payment method',
                value: (
                  <StackView>
                    <Typography
                      textStyle="interface-default-s"
                      color="text-base-color-fg-muted"
                    >
                      Pre-authorized credit
                    </Typography>
                    <Typography
                      textStyle="interface-default-xs"
                      color="text-base-color-fg-subtle"
                    >
                      {[
                        capitalize(
                          paymentPlanRefund.paymentPlanPayment.paymentMethod
                            .brand
                        ),
                        'ending in',
                        paymentPlanRefund.paymentPlanPayment.paymentMethod
                          .last4,
                      ].join(' ')}
                    </Typography>
                  </StackView>
                ),
              },
              {
                label: 'Type',
                value: (
                  <Badge
                    testId="refund-type-badge"
                    color={PAYMENT_TYPES['REFUND'].color}
                    text={PAYMENT_TYPES['REFUND'].label}
                  />
                ),
              },
              {
                label: 'Status',
                value: (
                  <Badge
                    testId="refund-status-badge"
                    color={PAYMENT_STATUS[paymentPlanRefund.status].color}
                    text={PAYMENT_STATUS[paymentPlanRefund.status].label}
                    showDot
                  />
                ),
              },
              {
                label: 'Processed date and time',
                value: format(
                  new Date(paymentPlanRefund.createdAt),
                  "MM/dd/yyyy 'at' h:mma"
                ),
              },
            ]}
          />
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPaymentPlanRefundView
