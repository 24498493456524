import {
  BellAlertIcon,
  DocumentCheckIcon,
  ListBulletIcon,
} from '@heroicons/react/20/solid'
import { isOverdue, alertText, isUpcoming } from 'common/data/baseCases'
import {
  hasSentNotification,
  canBeSelfScheduled,
} from 'common/data/schedulingCases'
import { formatDisplayName } from 'common/utils'
import { capitalize } from 'lodash'
import {
  CaseFilterInput,
  DiagnosticReportReviewCase,
  DocumentCase,
  ResultsReviewCase,
  SchedulingCase,
  ScreeningCase,
  StaffMessageCase,
} from 'types/graphql'

import { routes } from '@redwoodjs/router'

import Badge from 'src/components/atoms/Badge/Badge'
import Box from 'src/components/atoms/Box/Box'
import Card from 'src/components/atoms/Card/Card'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { Chip } from 'src/components/Chip/Chip'
import {
  screeningCaseReasonBadgeColor,
  screeningCaseReasonDisplay,
} from 'src/data/screeningCaseReason'
import { formatInitials } from 'src/lib/formatters'
import { codeGeneration } from 'src/pages/CasesPage/utils/codeGeneration'
import { serializeTaskAssignee } from 'src/utils/task'

import {
  getCasesPageGlob,
  getCode,
  getResolvedAt,
  getResolvedStatus,
  displayTaskType,
  getTaskType,
} from './helpers'
import { Task } from './types'

export type Case =
  | SchedulingCase
  | DocumentCase
  | StaffMessageCase
  | ResultsReviewCase
  | DiagnosticReportReviewCase
  | ScreeningCase

export interface TaskInboxItemProps {
  caseObj: Case
}

export const PatientTaskListItem = ({
  task,
  filters,
}: {
  task: Task
  filters?: CaseFilterInput
}) => {
  const renderResolvedStatusBadge = () => {
    const status = getResolvedStatus(task)
    return (
      <Badge
        text={capitalize(status)}
        color={status === 'CANCELLED' ? 'red' : 'green'}
      />
    )
  }

  const code = getCode(task)
  const resolvedAt = getResolvedAt(task)

  const userIds = filters?.userIds ?? []
  const userPoolIds = filters?.userPoolIds ?? []

  return (
    <a
      href={routes.cases({
        glob: getCasesPageGlob(task),
        caseStatuses: filters?.statuses?.join(','),
        userUserPoolId: [
          ...userIds.map((id) => serializeTaskAssignee({ type: 'user', id })),
          ...userPoolIds.map((id) =>
            serializeTaskAssignee({ type: 'pool', id })
          ),
        ].join(','),
        patientId: filters?.patientId,
      })}
      target="_blank"
      rel="noopener noreferrer"
      data-testId="task-list-item"
    >
      <Card
        padding={100}
        className="hover:bg-comp-button-color-secondary-hover-bg"
      >
        <StackView space={100}>
          <StackView direction="row" justifyContent="between">
            <Box>
              <StackView direction="row" space={50}>
                <Typography
                  textStyle="subtitle"
                  id="inbox-item-case-code"
                  data-code={code}
                >
                  {codeGeneration({
                    __typename: getTaskType(task),
                    code,
                  })}
                </Typography>
              </StackView>
            </Box>
            <Box>
              {resolvedAt ? (
                <>{renderResolvedStatusBadge()}</>
              ) : (
                <StackView direction="row" space={50}>
                  <Badge
                    color={
                      isOverdue(task)
                        ? 'red'
                        : isUpcoming(task)
                        ? 'dark-gray'
                        : 'yellow'
                    }
                    className="min-w-24"
                    size="s"
                    icon={BellAlertIcon}
                    text={alertText(task)}
                  />

                  {task.screeningCase?.reason && (
                    <Badge
                      text={
                        screeningCaseReasonDisplay[task.screeningCase.reason]
                      }
                      color={
                        screeningCaseReasonBadgeColor[task.screeningCase.reason]
                      }
                    />
                  )}
                </StackView>
              )}
            </Box>
          </StackView>
          <StackView direction="row" justifyContent="between" className="mt-2">
            <Box>
              <StackView direction="row" alignItems="center" space={50}>
                <Chip
                  text={
                    task.assignedUser
                      ? formatDisplayName(task.assignedUser)
                      : task.assignedTaskUserPool.displayName
                  }
                  leftContent={{
                    type: 'avatars',
                    avatars: task.assignedUser
                      ? [
                          {
                            text: [
                              task.assignedUser.givenName.charAt(0),
                              task.assignedUser.familyName.charAt(0),
                            ].join(''),
                          },
                        ]
                      : task.assignedTaskUserPool.users.map((user) => ({
                          text: formatInitials(user),
                        })),
                  }}
                />
                <Chip
                  leftContent={{ type: 'icon', icon: ListBulletIcon }}
                  text={displayTaskType(task)}
                />
              </StackView>
            </Box>
          </StackView>
          {task.schedulingCase ? (
            <Box>
              <StackView direction="row" space={100}>
                {canBeSelfScheduled(task.schedulingCase) &&
                hasSentNotification(task.schedulingCase) ? (
                  <StackView
                    direction="row"
                    space={25}
                    fullWidth={false}
                    testId="task-recall-badge"
                  >
                    <DocumentCheckIcon className="h-base-size-icon-s w-base-size-icon-s" />
                    <Typography>Recall</Typography>
                  </StackView>
                ) : null}
              </StackView>
            </Box>
          ) : null}
          {task.screeningCase?.order?.questionnaire && (
            <Box>
              <ListBulletIcon className="h-5 w-5" />
              <Typography>
                {task.screeningCase?.order?.questionnaire?.['title']}
              </Typography>
            </Box>
          )}
        </StackView>
      </Card>
    </a>
  )
}
