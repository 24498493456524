import FlatFieldArray from 'src/components/molecules/GenericFormFieldArray/FlatFieldArray'
import { chargeItemModifiers } from 'src/data/chargeItemModifiers'

import { ComboboxField } from '../ComboboxField'

const ChargeItemModifierMultiSelectField = ({ name }: { name: string }) => (
  <FlatFieldArray
    name={name}
    addButtonLabel="Add modifier"
    FormInputComponent={ChargeItemModifierComboboxField}
  />
)

const ChargeItemModifierComboboxField = ({ name }) => {
  const options = chargeItemModifiers.map(({ name, value }) => {
    const modifierCode = value.replace('MODIFIER_', '')
    return {
      name: `${modifierCode}: ${name}`,
      value,
    }
  })

  return <ComboboxField name={name} options={options} />
}

export default ChargeItemModifierMultiSelectField
