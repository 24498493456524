import { useParams } from 'react-router-dom'

import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import OrderSidepanelForm from 'src/components/OrderSidepanelForm/OrderSidepanelForm'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import { usePatientOrdersQuery } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

const SidepanelVisitOrderEdit = () => {
  const { patientId, isPatientChart } = useIsPatientChart()
  const { encounterId, orderId } = useParams()
  const { orders } = usePatientOrdersQuery(patientId)
  const order = orders?.find((order) => order.id === orderId)

  if (!isPatientChart || !order || order.category === 'HG_LAB') {
    // TODO: Do a better job of preventing edits of HG_LAB orders
    return <SidepanelNotAvailable />
  }

  return (
    <SidepanelPage
      testId="sidepanel-order-create"
      header={`Edit order: ${order.name}`}
    >
      <OrderSidepanelForm
        key={order.id}
        patientId={patientId}
        encounterId={encounterId === 'undefined' ? undefined : encounterId}
        category={order.category}
        order={order}
      />
    </SidepanelPage>
  )
}

export default SidepanelVisitOrderEdit
