import { presenceConceptCodings } from 'common/cdr/concepts/observations/index'
import { Presence } from 'types/graphql'

// import { nameValueArrayFromObject } from 'src/data/utils'
// not sure why but for this file specifically importing nameValueArrayFromObject is not working

export const nameValueArrayFromObject = <T extends string>(object: {
  [key in T]: string
}): { name: string; value: T }[] => {
  return Object.entries<string>(object).map(([key, value]) => ({
    name: value,
    value: key as T,
  }))
}

export const presenceIndicatorDisplay: {
  [key in Presence]: string
} = {
  YES: 'Yes',
  NO: 'No',
  UNDETERMINED: 'Undetermined',
}

export const presenceIndicatorDisplayToEnum = Object.keys(
  presenceIndicatorDisplay
).reduce((acc, key) => {
  acc[presenceIndicatorDisplay[key]] = key
  return acc
}, {})

export const presenceIndicatorCodeToEnum = presenceConceptCodings.reduce(
  (acc, coding) => {
    if (presenceIndicatorDisplayToEnum[coding.display]) {
      acc[coding.code] = presenceIndicatorDisplayToEnum[coding.display]
    }
    return acc
  },
  {}
)

export const presenceIndicators = nameValueArrayFromObject<Presence>(
  presenceIndicatorDisplay
)
