import { useFormContext } from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box'
import { InputFieldProps } from 'src/components/atoms/InputField'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import FieldError from 'src/components/FieldError/FieldError'
import {
  getUnitOptions,
  InputFieldWithSelectableUnit,
} from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import { validateVitalFn } from 'src/components/VisitIntakeVital'

export const BloodPressureInput = ({ name, ...props }: InputFieldProps) => {
  const formMethods = useFormContext()

  const currentSystolicValue = formMethods.watch(`${name}.systolic.value`)
  const currentDiastolicValue = formMethods.watch(`${name}.diastolic.value`)

  formMethods.register(name, {
    validate: (value) => {
      return (value.systolic.value && value.diastolic.value) ||
        (!value.systolic.value && !value.diastolic.value)
        ? true
        : 'Both systolic and diastolic are required'
    },
  })

  return (
    <StackView id={name}>
      <StackView fullWidth direction="row" gap={50}>
        <Box fullWidth>
          <Typography>Systolic</Typography>
          <InputFieldWithSelectableUnit
            {...props}
            name={`${name}.systolic`}
            unitOptions={getUnitOptions(['mmHg'])}
            validation={
              currentSystolicValue
                ? {
                    validate: validateVitalFn('bloodPressureSystolic', 'mmHg'),
                    shouldUnregister: true,
                  }
                : undefined
            }
            setValueAsString
          />
          <FieldError
            name={`${name}.systolic.value`}
            label="Systolic blood pressure"
          />
        </Box>
        <Box fullWidth>
          <Typography>Diastolic</Typography>
          <InputFieldWithSelectableUnit
            {...props}
            name={`${name}.diastolic`}
            unitOptions={getUnitOptions(['mmHg'])}
            validation={
              currentDiastolicValue
                ? {
                    validate: validateVitalFn('bloodPressureDiastolic', 'mmHg'),
                    shouldUnregister: true,
                  }
                : undefined
            }
            setValueAsString
          />
          <FieldError
            name={`${name}.diastolic.value`}
            label="Diastolic blood pressure"
          />
        </Box>
      </StackView>
    </StackView>
  )
}
