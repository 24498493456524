import { useEffect, useMemo, useRef, useState } from 'react'

import { PencilIcon } from '@heroicons/react/20/solid'
import { GridOptions } from 'ag-grid-community'
import type { AgGridReact } from 'ag-grid-react'
import clsx from 'clsx'
import { format } from 'date-fns'
import { parsePhoneNumber } from 'libphonenumber-js'
import { omit } from 'lodash'
import { ContactOrganizationsQuery } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Badge from 'src/components/atoms/Badge/Badge'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import EFaxIcon from 'src/components/icons/EFaxIcon/EFaxIcon'
import MultiSelectHoverBar from 'src/components/molecules/MultiSelectHoverBar/MultiSelectHoverBar'
import Table from 'src/components/molecules/Table/Table'
import { contactOrganizationTypeDisplay } from 'src/data/contactOrganizationTypes'
import {
  formatAddressLine1,
  formatAddressLine2,
  formatPhoneNumber,
} from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'

type Contact = ReturnType<typeof buildContacts>[0]
const buildContacts = (
  contactOrganizations: ContactOrganizationsQuery['contactOrganizations']
) => {
  return contactOrganizations.flatMap((organization) => {
    return [
      {
        contactOrganization: {
          ...omit(organization, 'contacts'),
          contactNames: organization.contacts.map((contact) => contact.name),
        },
        isPrimary: true,
        ...organization.primaryContact,
      },
      ...organization.contacts.map((contact) => ({
        contactOrganization: {
          ...omit(organization, 'contacts'),
          contactNames: [],
        },
        ...contact,
      })),
    ]
  })
}

const ContactOrganizationsTable = ({
  contactOrganizations,
  searchInput,
}: {
  contactOrganizations: ContactOrganizationsQuery['contactOrganizations']
  searchInput?: string
}) => {
  const [selected, setSelected] = useState([])
  const location = useLocation()
  const params = useParams()

  const gridRef = useRef<AgGridReact>()

  const contacts = useMemo(
    () => buildContacts(contactOrganizations),
    [contactOrganizations]
  )

  useEffect(() => {
    if (gridRef?.current?.api) {
      gridRef.current.api.refreshCells()
    }
  }, [contacts])

  useEffect(() => {
    gridRef.current?.api?.setQuickFilter(searchInput)
  }, [searchInput])

  const gridOptions: GridOptions = {
    // unique row Id even for rows that are organization-only (no primary contact exists for organization)
    getRowId: ({ data }) => data.contactOrganization.id + '/' + data.id,
    isRowSelectable: ({ data }) => !!data.faxNumber,
    rowHeight: 72,
    onRowClicked: (_e) => {},
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    suppressCellFocus: true,
  }

  return (
    <StackView className="relative h-full">
      <Table<Contact>
        innerRef={gridRef}
        gridOptions={gridOptions}
        rowData={contacts}
        rowSelection="multiple"
        onSelectionChanged={({ api }) => {
          setSelected(api.getSelectedRows().map((x) => x.id))
          api.sizeColumnsToFit()
        }}
        columnDefs={[
          {
            headerName: '',
            checkboxSelection: ({ data }) => data.isPrimary && data.faxNumber,
            headerCheckboxSelection: true,
            maxWidth: 40,
          },
          {
            headerName: '',
            checkboxSelection: ({ data }) => !data.isPrimary && data.faxNumber,
            maxWidth: 40,
          },
          {
            field: 'name',
            headerName: 'contact name',
            cellRenderer: ({ data }) =>
              data.isPrimary ? (
                <StackView
                  fullWidth={false}
                  space={75}
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                >
                  <StackView space={25}>
                    <Typography>{data.contactOrganization.name}</Typography>
                    <Typography textStyle="description">
                      {
                        contactOrganizationTypeDisplay[
                          data.contactOrganization.type
                        ]
                      }
                    </Typography>
                  </StackView>
                  <Badge>{data.contactOrganization.contactCount}</Badge>
                </StackView>
              ) : (
                <Typography>{data.name}</Typography>
              ),
            minWidth: 300,
            resizable: true,
            wrapText: true,
            autoHeight: true,
            getQuickFilterText: ({ data }) => {
              const isPrimary = data.isPrimary
              const quickFilter = isPrimary
                ? [data.name, ...data.contactOrganization.contactNames].join(
                    ' '
                  )
                : data.name

              return quickFilter
            },
          },
          {
            headerName: 'fax number',
            valueGetter: ({ data }) => formatPhoneNumber(data.faxNumber),
            resizable: true,
            minWidth: 150,
            getQuickFilterText: ({ data }) => {
              return data.faxNumber?.replaceAll('+1', '')
            },
          },
          {
            headerName: 'phone number',
            cellRenderer: ({ data }) => {
              return (
                <StackView>
                  <Typography>
                    {data.mobileNumber
                      ? `M: ${parsePhoneNumber(
                          data.mobileNumber
                        ).formatNational()}`
                      : null}
                  </Typography>
                  <Typography>
                    {data.homeNumber
                      ? `H: ${parsePhoneNumber(
                          data.homeNumber
                        ).formatNational()}`
                      : null}
                  </Typography>
                  <Typography>
                    {data.workNumber
                      ? `W: ${parsePhoneNumber(
                          data.workNumber
                        ).formatNational()}`
                      : null}
                  </Typography>
                </StackView>
              )
            },
            minWidth: 150,
            resizable: true,
          },
          {
            headerName: 'email address',
            cellRenderer: ({ data }) => {
              if (!data.emailAddress) {
                return null
              }
              return <Typography>{data.emailAddress}</Typography>
            },
            minWidth: 150,
            resizable: true,
          },
          {
            headerName: 'address',
            cellRenderer: ({ data }) => {
              const address = data.useOrganizationAddress
                ? data.contactOrganization.primaryContact?.address
                : data.address
              const muted = data.useOrganizationAddress

              if (!address) {
                return null
              }

              return (
                <StackView>
                  <Typography
                    color={muted ? 'text-base-color-fg-muted' : undefined}
                    className="whitespace-nowrap"
                  >
                    {formatAddressLine1(address)}
                  </Typography>
                  <Typography
                    color={muted ? 'text-base-color-fg-muted' : undefined}
                    className="whitespace-nowrap"
                  >
                    {formatAddressLine2(address)}
                  </Typography>
                </StackView>
              )
            },
            minWidth: 180,
            resizable: true,
            autoHeight: true,
          },
          {
            headerName: 'last modified',
            cellRenderer: ({ data }) =>
              data.updatedAt ? (
                <Typography>
                  {format(new Date(data.updatedAt), 'PP')}
                </Typography>
              ) : null,
            resizable: true,
            wrapText: true,
            autoHeight: true,
            minWidth: 170,
          },
          {
            colId: 'buttons',
            headerName: '',
            cellRenderer: ({ data }) => (
              <StackView space={50} direction="row">
                <Button
                  buttonStyle="secondary"
                  icon={EFaxIcon}
                  className={clsx(!data.faxNumber && 'opacity-50')}
                  disabled={!data.faxNumber}
                  innerRef={(ref) => {
                    if (!ref) return
                    ref.onclick = (e) => {
                      e.stopPropagation()
                      navigate(
                        sidepanelRoute(
                          {
                            route: '/send-fax',
                            contactIds: JSON.stringify([data.id]),
                          },
                          location,
                          params
                        )
                      )
                    }
                  }}
                />
                <Button
                  data-testid={`edit-${data.id}`}
                  buttonStyle="secondary"
                  icon={PencilIcon}
                  innerRef={(ref) => {
                    if (!ref) return
                    ref.onclick = (e) => {
                      e.stopPropagation()
                      const route = data.isPrimary
                        ? `/contact-organizations/${data.contactOrganization.id}/edit`
                        : `/contacts/${data.id}/edit`
                      navigate(
                        sidepanelRoute(
                          {
                            route,
                          },
                          location,
                          params
                        )
                      )
                    }
                  }}
                />
              </StackView>
            ),
            minWidth: 150,
            maxWidth: 150,
          },
        ]}
      />
      {selected.length !== 0 && (
        <MultiSelectHoverBar
          selected={selected}
          deselectAll={() => gridRef.current.api.deselectAll()}
          itemName="contact"
          actions={[
            {
              key: 'efax',
              text: 'E-fax selected',
              icon: EFaxIcon,
              onClick: (selected) =>
                navigate(
                  sidepanelRoute(
                    {
                      route: '/send-fax',
                      contactIds: JSON.stringify(selected),
                    },
                    location,
                    params
                  )
                ),
            },
          ]}
        />
      )}
    </StackView>
  )
}

export default ContactOrganizationsTable
