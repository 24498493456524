import { Redirect, routes, useParams } from '@redwoodjs/router'

import { usePatientPortalAuth } from 'src/auth'

export const PrivatePaymentLinkRoute = ({ children }) => {
  const { notificationId } = useParams()
  const { isAuthenticated, loading } = usePatientPortalAuth()

  if (loading) return null

  if (!isAuthenticated) {
    const currentLocation =
      globalThis.location.pathname +
      encodeURIComponent(globalThis.location.search)

    return (
      <Redirect
        to={routes.paymentLinkLoginPage({
          notificationId,
          redirectTo: currentLocation,
        })}
        options={{
          replace: true,
        }}
      />
    )
  }

  return <>{children}</>
}
