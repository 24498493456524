import { useEffect, useState } from 'react'

import {
  QuestionnaireResponseItem,
  QuestionnaireItem,
  QuestionnaireResponseItemAnswer,
  Questionnaire as FHIRQuestionnaire,
  ResourceType,
} from '@medplum/fhirtypes'
import { unitEnumToUCUMUnitMap } from 'common/unitConverter/unitConverter'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import {
  ObservationDefinition,
  OrderWithObservationsFragment,
  QuestionnaireScoringDetail,
} from 'types/graphql'

import { RegisterOptions, useForm } from '@redwoodjs/forms'

import Divider from 'src/components/atoms/Divider'
import { DropdownField } from 'src/components/atoms/Dropdown'
import { FieldLabel } from 'src/components/atoms/Label'
import { Option } from 'src/components/atoms/Select/Select'
import Space from 'src/components/atoms/Space'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import FieldError from 'src/components/FieldError/FieldError'
import { InputFieldWithSelectableUnit } from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import Modal from 'src/components/molecules/Modal'
import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import Tabs from 'src/components/molecules/Tabs/Tabs'
import { buildDefaultFindings } from 'src/components/Order/CompleteInHouseTestOrderForm/CompleteInHouseTestOrderForm'
import {
  OrderRefusal as QuestionnaireRefusal,
  ScreeningNotes,
} from 'src/components/Order/CompleteScreeningOrderSidepanelForm'
import RelatedPersonSelectCell from 'src/components/RelatedPerson/RelatedPersonSelectCell'
import { useFetchQuestionnaire } from 'src/hooks/useFetchQuestionnaire/useFetchQuestionnaire'
import { useCompleteQuestionnaireOrderMutation } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import {
  getEnabledQuestionnaireItemLinkIds,
  Questionnaire,
  QuestionnaireResultType,
} from './Questionnaire'

export const idToReference = (id: string, resourceType: ResourceType) => {
  return `${resourceType}/${id}`
}

export const referenceToId = (
  reference: string,
  expectedResourceType?: ResourceType
) => {
  const [resourceType, id] = reference.split('/') as [ResourceType, string]
  if (expectedResourceType && resourceType !== expectedResourceType) {
    throw new Error(
      `Expected resource type ${expectedResourceType} but got ${resourceType}`
    )
  }
  return id
}

enum ASQ_QUESTIONNAIRE_CODES {
  ASQ_3_60MONTHS,
  ASQ_3_54MONTHS,
  ASQ_3_48MONTHS,
  ASQ_3_42MONTHS,
  ASQ_3_36MONTHS,
  ASQ_3_33MONTHS,
  ASQ_3_30MONTHS,
  ASQ_3_27MONTHS,
  ASQ_3_24MONTHS,
  ASQ_3_22MONTHS,
  ASQ_3_20MONTHS,
  ASQ_3_18MONTHS,
  ASQ_3_16MONTHS,
  ASQ_3_14MONTHS,
  ASQ_3_12MONTHS,
  ASQ_3_10MONTHS,
  ASQ_3_9MONTHS,
  ASQ_3_8MONTHS,
  ASQ_3_6MONTHS,
  ASQ_3_4MONTHS,
  ASQ_3_2MONTHS,
}

type ASQQuestionnaireCode = keyof typeof ASQ_QUESTIONNAIRE_CODES

const asqQuestionnaireCodeDisplay: Record<ASQQuestionnaireCode, string> = {
  ASQ_3_60MONTHS: '60 month',
  ASQ_3_54MONTHS: '54 month',
  ASQ_3_48MONTHS: '48 month',
  ASQ_3_42MONTHS: '42 month',
  ASQ_3_36MONTHS: '36 month',
  ASQ_3_33MONTHS: '33 month',
  ASQ_3_30MONTHS: '30 month',
  ASQ_3_27MONTHS: '27 month',
  ASQ_3_24MONTHS: '24 month',
  ASQ_3_22MONTHS: '22 month',
  ASQ_3_20MONTHS: '20 month',
  ASQ_3_18MONTHS: '18 month',
  ASQ_3_16MONTHS: '16 month',
  ASQ_3_14MONTHS: '14 month',
  ASQ_3_12MONTHS: '12 month',
  ASQ_3_10MONTHS: '10 month',
  ASQ_3_9MONTHS: '9 month',
  ASQ_3_8MONTHS: '8 month',
  ASQ_3_6MONTHS: '6 month',
  ASQ_3_4MONTHS: '4 month',
  ASQ_3_2MONTHS: '2 month',
}

const asqDropdownOptions: Option[] = Object.keys(
  asqQuestionnaireCodeDisplay
).map((code) => {
  return { name: asqQuestionnaireCodeDisplay[code], value: code }
})

const getQuestionnaireResponseMetadata = (order) => {
  return {
    resourceType: 'QuestionnaireResponse',
    source: {
      reference: `Practitioner/${order.requester.id}`,
    },
  }
}

const FindingInput = ({
  name,
  observationDefinition,
  hideLabel,
  disabled,
  validation,
}: {
  name: string
  observationDefinition: ObservationDefinition
  hideLabel?: boolean
  disabled?: boolean
  validation?: RegisterOptions
}) => {
  if (isEmpty(observationDefinition)) return null

  const { permittedDataType, permittedUnit, label } = observationDefinition

  return (
    <StackView space={50}>
      {!hideLabel && label && (
        <FieldLabel name={`${name}`} className="font-semibold">
          <Typography textStyle="subtitle" fontWeight="semibold">
            {label}
          </Typography>
        </FieldLabel>
      )}
      {permittedDataType === 'Quantity' && permittedUnit && (
        <>
          <InputFieldWithSelectableUnit
            disabled={disabled}
            name={`${name}.unitInput`}
            unitOptions={[
              {
                name: unitEnumToUCUMUnitMap[permittedUnit], // for display
                value: permittedUnit,
              },
            ]}
            setValueAsString
          />
          <FieldError
            name={`${name}.unitInput.value`}
            defaultErrorMessage={`${label} has an error`}
          />
        </>
      )}
      {permittedDataType === 'CodeableConcept' && (
        <>
          <MultiRadioButtonField
            disabled={disabled}
            labelClassName="font-normal"
            name={`${name}.answerCode`}
            validation={validation}
            values={observationDefinition.validCodedValueSet.map((coding) => {
              return { label: coding.display, value: coding.code }
            })}
          />
          <FieldError name={`${name}.answerCode`} />
        </>
      )}
    </StackView>
  )
}

const OverallFindingInput = ({
  observationDefinition,
  disabled,
}: {
  observationDefinition: ObservationDefinition
  disabled?: boolean
}) => {
  return (
    <>
      <FieldLabel name={'overallFinding'} required={true}>
        <Typography textStyle="subtitle" fontWeight="semibold">
          Overall Finding
        </Typography>
      </FieldLabel>
      <FindingInput
        key={observationDefinition.id}
        disabled={disabled}
        hideLabel={true}
        name="overallFinding"
        validation={{
          validate: (value) => {
            return !value ? 'Please select an overall finding' : true
          },
        }}
        observationDefinition={observationDefinition}
      />
    </>
  )
}

const QuestionnaireResultTypeSelection = ({
  order,
  onChange,
}: {
  order: OrderWithObservationsFragment
  onChange: (event: Event) => void
}) => {
  return (
    <StackView space={75}>
      <Space />
      <Typography textStyle="title" fontWeight="semibold">
        Questionnaire Selection
      </Typography>
      <Divider />
      <StackView space={50} justifyContent="center">
        <Typography textStyle="subtitle">
          How do you want to record results?
        </Typography>
        <MultiRadioButtonField
          disabled={
            !isEmpty(order.observations) ||
            !isEmpty(order.questionnaireResults?.detailed) ||
            order.doNotPerform
          }
          labelClassName="font-normal"
          name={'questionnaireResultType'}
          onChange={onChange}
          values={[
            {
              label: 'Detailed questionnaire',
              description: 'Full set of questions for the selected screen.',
              value: 'DETAILED',
            },
            {
              label: 'Overall finding',
              description: 'Summary finding for the selected screen.',
              value: 'OVERALL_FINDING',
            },
          ]}
        />
      </StackView>
      <Divider />
    </StackView>
  )
}

export const AsqQuestionnaireSelection = ({
  order,
  onSelect,
  hasQuestionnaireBeenTouched,
}: {
  order: OrderWithObservationsFragment
  onSelect: (questionnaireCode: string) => void
  hasQuestionnaireBeenTouched: boolean
}) => {
  const [selectedQuestionnaireCode, setSelectedQuestionnaireCode] =
    useState<string>(null)

  return (
    <StackView space={75} direction="col">
      <Space />
      <Typography textStyle="title" fontWeight="semibold">
        Select the appropriate questionnaire
      </Typography>
      <Divider />
      <StackView space={50} direction="col">
        <Typography> Questionnaire selection</Typography>
        <DropdownField
          disabled={!isEmpty(order.questionnaireResults?.detailed)}
          name="questionnaireSelection"
          options={asqDropdownOptions}
          onSelect={(questionnaireCode: string) => {
            if (hasQuestionnaireBeenTouched) {
              setSelectedQuestionnaireCode(questionnaireCode)
            } else {
              onSelect(questionnaireCode)
            }
          }}
        />
        {hasQuestionnaireBeenTouched && (
          <Modal
            title="Are you sure you want to change the ASQ-3 questionnaire selection?"
            modalStyle="warning"
            isOpen={!!selectedQuestionnaireCode}
            content="Any previously entered information entered by a patient, caregiver, another external party and/or practice staff will be lost. You cannot undo this action."
            primaryButton={{
              text: 'Continue',
              onClick: () => {
                onSelect(selectedQuestionnaireCode)
              },
            }}
            setIsOpen={(isOpen) =>
              !isOpen && setSelectedQuestionnaireCode(null)
            }
            onClose={() => {
              setSelectedQuestionnaireCode(null)
            }}
            hideIcon
          />
        )}

        <Typography textStyle="description">
          Changing the questionnaire once a patient, caregiver, another external
          party and/or practice staff has entered information below will result
          in loss of any previously entered information. Only one set of
          questionnaire results can be saved per order.
        </Typography>
      </StackView>
    </StackView>
  )
}

export const QuestionnaireAuthor = ({ patientId }: { patientId: string }) => {
  return (
    <StackView space={75} direction="col">
      <Space />
      <Typography textStyle="title" fontWeight="semibold">
        Person filling out questionnaire
      </Typography>
      <Divider />
      <RelatedPersonSelectCell name="authorId" patientId={patientId} />
    </StackView>
  )
}

const buildQuestionnaireResponseItemsFromQuestionnaireItems = (
  questionnaireItems: QuestionnaireItem[],
  answersGroupedByLinkId?: Record<string, QuestionnaireResponseItemAnswer[]>
): QuestionnaireResponseItem[] => {
  if (!questionnaireItems && !answersGroupedByLinkId) return

  return compact(
    questionnaireItems.map((questionnaireItem) => {
      if (
        answersGroupedByLinkId &&
        !answersGroupedByLinkId[questionnaireItem.linkId] &&
        !questionnaireItem.item
      )
        return

      if (questionnaireItem.item) {
        // handle any amount of nesting
        return {
          linkId: questionnaireItem.linkId,
          text: questionnaireItem.text,
          item: buildQuestionnaireResponseItemsFromQuestionnaireItems(
            questionnaireItem.item,
            answersGroupedByLinkId
          ),
        }
      }
      // no nesting
      return {
        linkId: questionnaireItem.linkId,
        text: questionnaireItem.text,
        answer: answersGroupedByLinkId?.[questionnaireItem.linkId],
      }
    })
  )
}

export const mapAnswersToFrontendValues = (
  answers: QuestionnaireResponseItemAnswer[]
) => {
  if (!answers) return
  return answers.map((answer) => {
    if (answer.valueQuantity) {
      return { valueInteger: answer.valueQuantity.value }
    }

    if (answer.valueCoding) {
      return { valueCoding: answer.valueCoding }
    }

    return answer
  })
}

export const getAnswersGroupedByLinkIdFromResponse = (
  responseItems: QuestionnaireResponseItem[]
): Record<string, QuestionnaireResponseItemAnswer[]> | undefined => {
  if (!responseItems) return

  let answersGroupedByLinkId: Record<
    string,
    QuestionnaireResponseItemAnswer[]
  > = {}

  for (const responseItem of responseItems) {
    if (responseItem.item) {
      answersGroupedByLinkId = {
        ...answersGroupedByLinkId,
        ...getAnswersGroupedByLinkIdFromResponse(responseItem.item),
      }
    } else {
      answersGroupedByLinkId[responseItem.linkId] = mapAnswersToFrontendValues(
        responseItem.answer
      )
    }
  }

  return answersGroupedByLinkId
}

const convertResponseItemsToResponseItemMap = (
  responseItems:
    | QuestionnaireResponseItem[] // can be a response item (original state)
    | Record<string, QuestionnaireResponseItem> // or partially reduced
): QuestionnaireResponseItem[] | Record<string, QuestionnaireResponseItem> => {
  return (responseItems as QuestionnaireResponseItem[]).reduce(
    (responseItemMap, responseItem) => {
      if (responseItem.item) {
        responseItem.item = convertResponseItemsToResponseItemMap(
          responseItem.item
        ) as QuestionnaireResponseItem[]
      }

      responseItemMap[responseItem.linkId] = responseItem

      return responseItemMap
    },
    {} as Record<string, QuestionnaireResponseItem>
  )
}

const buildQuestionnaireResponseItemMap = ({
  questionnaire,
  detailedQuestionnaireResults,
  requesterId,
}: {
  questionnaire: FHIRQuestionnaire
  detailedQuestionnaireResults: QuestionnaireResponseItem[]
  requesterId: string
}) => {
  if (!questionnaire || !detailedQuestionnaireResults) return

  const questionnaireResponseItemMap = {}

  const questionnaireResponseItemMetadata = {
    questionnaire: `Questionnaire/${questionnaire.code?.[0]?.code}`,
    resourceType: 'QuestionnaireResponse',
    source: {
      reference: `Practitioner/${requesterId}`,
    },
  }

  const answersGroupedByLinkId = getAnswersGroupedByLinkIdFromResponse(
    detailedQuestionnaireResults
  )

  if (isEmpty(answersGroupedByLinkId)) return

  for (const questionnaireItem of questionnaire['item']) {
    if (questionnaireItem.type === 'group') {
      const groupResponseItems =
        buildQuestionnaireResponseItemsFromQuestionnaireItems(
          questionnaireItem.item,
          answersGroupedByLinkId
        )

      questionnaireResponseItemMap[questionnaireItem.linkId] = {
        ...questionnaireResponseItemMetadata,
        linkId: questionnaireItem.linkId,
        text: questionnaireItem.text,
        item: convertResponseItemsToResponseItemMap(groupResponseItems),
      }
    } else {
      if (!answersGroupedByLinkId[questionnaireItem.linkId]) continue

      questionnaireResponseItemMap[questionnaireItem.linkId] = {
        ...questionnaireResponseItemMetadata,
        linkId: questionnaireItem.linkId,
        text: questionnaireItem.text,
        answer: answersGroupedByLinkId[questionnaireItem.linkId],
      }
    }
  }

  return questionnaireResponseItemMap
}

const buildQuestionnaireResponseItemMapFromOrder = ({
  order,
  requestedQuestionnaire,
  responseIndex = 0,
}: {
  order: OrderWithObservationsFragment
  requestedQuestionnaire?: FHIRQuestionnaire
  responseIndex?
}): Record<string, QuestionnaireResponseItem | undefined> => {
  if (!order.questionnaire && !order.questionnaires) return

  if (order.questionnaireResults) {
    const answersGroupedByLinkId = getAnswersGroupedByLinkIdFromResponse(
      order.questionnaireResults?.detailed as QuestionnaireResponseItem[]
    )

    if (isEmpty(answersGroupedByLinkId)) return

    return buildQuestionnaireResponseItemMap({
      questionnaire:
        requestedQuestionnaire ??
        (order.questionnaireResults?.develoQuestionnaire?.[
          'questionnaire'
        ] as unknown as FHIRQuestionnaire) ??
        (order.questionnaire as unknown as FHIRQuestionnaire),
      detailedQuestionnaireResults: order.questionnaireResults
        ?.detailed as QuestionnaireResponseItem[],
      requesterId: order.requester.id,
    })
  } else if (order.questionnaires && order.resultsForQuestionnaires) {
    const allResponseItemMaps = []
    for (const [index, questionnaire] of order.questionnaires.entries()) {
      const answersGroupedByLinkId = getAnswersGroupedByLinkIdFromResponse(
        order.resultsForQuestionnaires[index]
          ?.detailed as QuestionnaireResponseItem[]
      )
      if (isEmpty(answersGroupedByLinkId)) return

      const questionnaireResponseItemMetadata = {
        resourceType: 'QuestionnaireResponse',
        source: {
          reference: `Practitioner/${order.requester.id}`,
        },
      }
      for (const questionnaireItem of questionnaire['item']) {
        switch (questionnaireItem.type) {
          case 'group': {
            const groupResponseItems =
              buildQuestionnaireResponseItemsFromQuestionnaireItems(
                questionnaireItem.item,
                answersGroupedByLinkId
              )

            if (!allResponseItemMaps[responseIndex]) {
              allResponseItemMaps[responseIndex] = {}
            }

            allResponseItemMaps[responseIndex][questionnaireItem.linkId] = {
              ...questionnaireResponseItemMetadata,
              linkId: questionnaireItem.linkId,
              text: questionnaireItem.text,
              item: convertResponseItemsToResponseItemMap(groupResponseItems),
            }

            break
          }
          default: {
            if (answersGroupedByLinkId[questionnaireItem.linkId]) {
              if (!allResponseItemMaps[responseIndex]) {
                allResponseItemMaps[responseIndex] = {}
              }

              allResponseItemMaps[responseIndex][questionnaireItem.linkId] = {
                ...questionnaireResponseItemMetadata,
                linkId: questionnaireItem.linkId,
                text: questionnaireItem.text,
                answer: answersGroupedByLinkId[questionnaireItem.linkId],
              }
            }
          }
        }
      }
    }
    return allResponseItemMaps[responseIndex]
  }
}

const getValueFromAnswer = (
  answer: QuestionnaireResponseItemAnswer
): number | string | undefined => {
  if (answer === undefined) return
  // ! check would fail for value of 0
  if (answer.valueInteger !== undefined) {
    return answer.valueInteger
  }

  if (answer.valueCoding) {
    return answer.valueCoding.code
  }

  if (answer.valueString) {
    return answer.valueString
  }
}

const getCleanedResponseItemAnswers = (
  itemAnswers: QuestionnaireResponseItemAnswer[]
): QuestionnaireResponseItemAnswer[] | undefined => {
  if (!itemAnswers) return
  // would not handle nested QuestionnaireResponseItemAnswer[] right now
  return itemAnswers.filter(
    (answer) => getValueFromAnswer(answer) !== undefined
  )
}

// side effect: 'cleans' response items with no answers or items by setting to undefined
export const cleanQuestionnaireResponseItems = (
  responseItems: QuestionnaireResponseItem[],
  enabledItemLinkIds: string[]
): QuestionnaireResponseItem[] => {
  if (!responseItems) return []

  // compact will remove possibly undefined entries at depth = 0
  const cleanedResponseItems = compact(
    responseItems.map((responseItem, index) => {
      if (
        (!responseItem.answer && !responseItem.item) ||
        !enabledItemLinkIds.includes(responseItem.linkId)
      ) {
        responseItems[index] = undefined
        return
      }

      if (responseItem.item) {
        cleanQuestionnaireResponseItems(responseItem.item, enabledItemLinkIds)
        // previous line will have 'cleaned' empty items by setting them to undefined
        // which we then filter out below
        responseItem.item = compact(responseItem.item)

        if (isEmpty(responseItem.item)) {
          // no answers no items
          responseItems[index] = undefined
        }
      }

      if (responseItem.answer) {
        const cleanedAnswers = getCleanedResponseItemAnswers(
          responseItem.answer
        )

        if (cleanedAnswers.length > 0) {
          responseItem.answer = cleanedAnswers
        } else if (isEmpty(responseItem.item)) {
          // no answers no items
          responseItems[index] = undefined
        }
      }

      return responseItems[index]
    })
  )

  return cleanedResponseItems
}

export const convertQuestionnaireResponseItemMapsToResponseItems = (
  questionnaireItemMap: Record<string, QuestionnaireResponseItem>
): QuestionnaireResponseItem[] => {
  if (!questionnaireItemMap) return []

  const responseItems: QuestionnaireResponseItem[] = []
  for (const responseItem of Object.values(questionnaireItemMap)) {
    if (responseItem.item) {
      const childrenResponseItems =
        convertQuestionnaireResponseItemMapsToResponseItems(
          responseItem.item as unknown as Record<
            string,
            QuestionnaireResponseItem
          >
        )

      if (childrenResponseItems) {
        responseItem.item = childrenResponseItems
        responseItems.push(responseItem)
        continue
      }
    }
    responseItems.push(responseItem)
  }

  return responseItems
}

export const CompleteQuestionnaireOrderSidepanelForm = ({
  patientId,
  encounterId,
  order,
}: {
  patientId: string
  encounterId: string
  order: OrderWithObservationsFragment

  questionnaireCode?: string
}): JSX.Element => {
  const [
    completeQuestionnaireOrder,
    { loading: completingQuestionnaireOrder },
  ] = useCompleteQuestionnaireOrderMutation()
  const [requestedQuestionnaire, setRequestedQuestionnaire] = useState<{
    questionnaire: FHIRQuestionnaire
    scoringDetails: QuestionnaireScoringDetail[]
  }>({ questionnaire: null, scoringDetails: null })

  const selectedQuestionnaireResultType = !isEmpty(order.questionnaireResults)
    ? QuestionnaireResultType.DETAILED
    : !isEmpty(order.observations)
    ? QuestionnaireResultType.OVERALL_FINDING
    : QuestionnaireResultType.DETAILED // default to Detailed selection

  const [questionnaireResultType, setQuestionnaireResultType] =
    useState<QuestionnaireResultType>(selectedQuestionnaireResultType)

  const [hasQuestionnaireBeenTouched, setHasQuestionnaireBeenTouched] =
    useState<boolean>(false)

  const [fetchQuestionnaire] = useFetchQuestionnaire()

  const { closeSidePanel } = useSidepanel()

  const questionnaireResponseMetadata = getQuestionnaireResponseMetadata(order)

  const defaultResponseItems = buildQuestionnaireResponseItemMapFromOrder({
    order,
    requestedQuestionnaire:
      (requestedQuestionnaire?.questionnaire as unknown as FHIRQuestionnaire) ??
      (order.questionnaireResults?.develoQuestionnaire?.[
        'questionnaire'
      ] as unknown as FHIRQuestionnaire),
  }) as unknown

  const formMethods = useForm({
    shouldUnregister: true,
    defaultValues: {
      questionnaireSelection:
        requestedQuestionnaire?.questionnaire?.code?.[0]?.code ??
        order.questionnaireResults?.questionnaireCode ??
        order.questionnaire?.['code']?.[0]?.code, // the default questionnaire by Patient age
      authorId: order.questionnaireResults?.authorId,
      notes: order.questionnaireResults?.notes,
      refusalReasons: order.refusalReasons || {
        refusalByPatient: false,
        refusalByCaregiver: false,
        contraindicatedForPatient: false,
        uncooperativePatient: false,
        underCareOfAnotherProvider: false,
      },
      questionnaireResponse: {
        ...questionnaireResponseMetadata,
        item: defaultResponseItems,
      },
      overallFinding: buildDefaultFindings({
        order: order,
        orderCode: order.code,
      })[0],
      questionnaireResultType: selectedQuestionnaireResultType,
    },
  })

  const onSelectQuestionnaireResultType = async (event) => {
    const {
      target: { value },
    } = event

    if (!(value in QuestionnaireResultType)) return

    if (value === QuestionnaireResultType.OVERALL_FINDING) {
      formMethods.setValue(
        'overallFinding',
        buildDefaultFindings({
          order: order,
          orderCode: order.code,
        })[0]
      )
      formMethods.setValue('questionnaireResponse', undefined)
    } else if (value === QuestionnaireResultType.DETAILED) {
      formMethods.setValue('overallFinding', undefined)
    }

    setQuestionnaireResultType(value)
    formMethods.setValue(
      'questionnaireResultType',
      value as QuestionnaireResultType,
      {
        shouldDirty: true,
      }
    )
  }

  const onSelectAsqQuestionnaire = async (questionnaireCode: string) => {
    const { data } = await fetchQuestionnaire({
      variables: { questionnaireCode },
    })

    const questionnaire = omit(
      Object(data['questionnaire']),
      'scoringDetails'
    ) as unknown as FHIRQuestionnaire

    const scoringDetails = data['questionnaire']['scoringDetails']

    setRequestedQuestionnaire({ questionnaire, scoringDetails })

    formMethods.setValue(
      'questionnaireSelection',
      questionnaire['code']?.[0]?.code
    )

    formMethods.setValue('questionnaireResponse', {
      ...questionnaireResponseMetadata,
      item: convertResponseItemsToResponseItemMap(
        buildQuestionnaireResponseItemsFromQuestionnaireItems(
          questionnaire['item']
        )
      ),
    })
    setHasQuestionnaireBeenTouched(false)
  }

  const getQuestionnaireResponseHasAnswers = (
    questionnaireResponseItems: QuestionnaireResponseItem[]
  ) => {
    for (const linkId in questionnaireResponseItems) {
      const responseItem = questionnaireResponseItems[linkId]

      if (responseItem.item) {
        const childrenHasAnswers = getQuestionnaireResponseHasAnswers(
          responseItem.item
        )

        if (childrenHasAnswers) return true
      }

      if (responseItem.answer && getValueFromAnswer(responseItem.answer[0])) {
        return true
      }
    }
    return false
  }

  const questionnaireResponseHasAnswers = getQuestionnaireResponseHasAnswers(
    formMethods.watch(
      'questionnaireResponse.item'
    ) as QuestionnaireResponseItem[]
  )

  useEffect(() => {
    if (!hasQuestionnaireBeenTouched && questionnaireResponseHasAnswers) {
      setHasQuestionnaireBeenTouched(true)
    }
  }, [
    setHasQuestionnaireBeenTouched,
    hasQuestionnaireBeenTouched,
    questionnaireResponseHasAnswers,
    order.refusalReasons,
  ])

  const refusalReasons = formMethods.watch('refusalReasons')

  const doNotPerform = Object.values(omit(refusalReasons, '__typename')).some(
    Boolean
  )

  if (!order.questionnaire && !order.questionnaires && !requestedQuestionnaire)
    return null

  const isAsqQuestionnaireOrder = order.code?.includes('ASQ')

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Save',
        cancelText: order.doNotPerform ? 'Close' : 'Discard changes',
        disabled: order.doNotPerform,
        submitting: completingQuestionnaireOrder,
      }}
      formMethods={formMethods}
      onSubmit={async (input) => {
        const enabledItemLinkIds = getEnabledQuestionnaireItemLinkIds(
          requestedQuestionnaire?.questionnaire?.['item'] || // very first submit for selected questionnaire
            order.questionnaireResults?.develoQuestionnaire?.['item'] || // selected questionnaire - source from results
            order.questionnaire?.['item'] || // default questionnaire
            order.questionnaires?.[0]?.['item'], // for questionnaires having more than one tab (e.g. VANDERBILT)
          formMethods
        )
        await completeQuestionnaireOrder({
          variables: {
            input: {
              ...omit(input, 'questionnaireResultType'),
              questionnaireResponse: !isEmpty(input.questionnaireResponse?.item)
                ? {
                    ...questionnaireResponseMetadata,
                    item: cleanQuestionnaireResponseItems(
                      convertQuestionnaireResponseItemMapsToResponseItems(
                        input.questionnaireResponse?.item as unknown as Record<
                          string,
                          QuestionnaireResponseItem
                        >
                      ),
                      enabledItemLinkIds
                    ),
                  }
                : undefined,
              overallFinding: input.overallFinding?.answerCode
                ? {
                    ...buildDefaultFindings({
                      order: order,
                      orderCode: order.code,
                    })[0],
                    ...input.overallFinding,
                  }
                : undefined,
              responseIndex: 0, // TODO: when tabs implemented, update this to be the tab index
              orderCode: order.code,
              orderId: order.id,
              patientId,
              encounterId,
              refusalReasons: omit(input.refusalReasons, '__typename'),
            },
          },
          onCompleted: (data) => {
            const updatedOrder = data.completeQuestionnaireOrder.orders.find(
              (updatedOrder) => {
                return updatedOrder.id === order.id
              }
            )

            if (!updatedOrder) return

            formMethods.reset({
              questionnaireSelection:
                requestedQuestionnaire?.questionnaire?.code?.[0]?.code ??
                order.questionnaire?.['code']?.[0]?.code,
              authorId: order.questionnaireResults?.authorId,
              notes: order.questionnaireResults?.notes,
              refusalReasons: order.refusalReasons,
              questionnaireResponse: {
                ...questionnaireResponseMetadata,
                item: buildQuestionnaireResponseItemMapFromOrder({
                  order,
                  requestedQuestionnaire:
                    requestedQuestionnaire.questionnaire ??
                    (order.questionnaireResults?.develoQuestionnaire?.[
                      'questionnaire'
                    ] as unknown as FHIRQuestionnaire),
                }),
              },
            })

            closeSidePanel()
          },
        })
      }}
    >
      <StackView space={75} className="pb-6">
        {doNotPerform && (
          <QuestionnaireRefusal order={order} description="Screening refusal" />
        )}
        {!doNotPerform && order.questionnaire && (
          <>
            {isAsqQuestionnaireOrder ? (
              <>
                <QuestionnaireResultTypeSelection
                  order={order}
                  onChange={onSelectQuestionnaireResultType}
                />
                {questionnaireResultType ===
                QuestionnaireResultType.DETAILED ? (
                  <>
                    <AsqQuestionnaireSelection
                      order={order}
                      hasQuestionnaireBeenTouched={hasQuestionnaireBeenTouched}
                      onSelect={onSelectAsqQuestionnaire}
                    />
                    <QuestionnaireAuthor patientId={patientId} />
                  </>
                ) : null}
              </>
            ) : (
              <QuestionnaireResultTypeSelection
                order={order}
                onChange={onSelectQuestionnaireResultType}
              />
            )}
            <QuestionnaireRefusal
              order={order}
              description="Screening refusal"
            />

            {questionnaireResultType === QuestionnaireResultType.DETAILED ? (
              <Questionnaire
                questionnaire={
                  requestedQuestionnaire.questionnaire ??
                  (order.questionnaireResults?.develoQuestionnaire?.[
                    'questionnaire'
                  ] as unknown as FHIRQuestionnaire) ??
                  (order.questionnaire as unknown as FHIRQuestionnaire)
                }
              />
            ) : null}
            {questionnaireResultType ===
              QuestionnaireResultType.OVERALL_FINDING && (
              <OverallFindingInput
                observationDefinition={order.observationDefinitions?.[0]}
              />
            )}
            <ScreeningNotes title="Comments" order={order} />
          </>
        )}

        {!doNotPerform && order.questionnaires && (
          <Tabs
            tabs={order.questionnaires.map((questionnaire, index) => {
              return {
                name: questionnaire['title'],
                key: index.toString(),
                content: (
                  <>
                    <QuestionnaireRefusal
                      order={order}
                      description="Screening refusal"
                    />
                    <Questionnaire
                      questionnaire={
                        requestedQuestionnaire.questionnaire ??
                        (questionnaire as unknown as FHIRQuestionnaire)
                      }
                    />
                    <ScreeningNotes title="Comments" order={order} />
                  </>
                ),
              }
            })}
          />
        )}
      </StackView>
    </SidepanelForm>
  )
}

export default CompleteQuestionnaireOrderSidepanelForm
