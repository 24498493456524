import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

import { SubHeading } from '../common'

export const FilterBox = ({
  filters,
}: {
  filters: {
    practitionerNames: string[]
    locationNames: string[]
    appointmentStatusNames: string[]
  }
}) => {
  const filterRows = [
    { label: 'Visit status', value: filters.appointmentStatusNames },
    { label: 'Practitioner(s)', value: filters.practitionerNames },
    { label: 'Clinic location(s)', value: filters.locationNames },
  ].map((row) => ({
    ...row,
    value: row.value.join(', '),
  }))

  return (
    <StackView
      gap={50}
      className="border-base-color-subtle rounded-core-border-radius-25 border-core-border-width-10 p-core-space-100"
    >
      <SubHeading>Parameters</SubHeading>
      {filterRows.map(({ label, value }) => (
        <StackView key={label} direction="row">
          <Typography
            className="w-core-space-1200"
            color="text-base-color-fg-muted"
          >
            {label}
          </Typography>
          <Typography>{value}</Typography>
        </StackView>
      ))}
    </StackView>
  )
}
