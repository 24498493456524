import { useParams } from 'react-router-dom'

import PatientEducationDocumentEditCell from 'src/components/PatientEducationDocumentEditCell'

const SidepanelPatientEducationDocumentEdit = () => {
  const { patientEducationDocumentId } = useParams()

  return <PatientEducationDocumentEditCell id={patientEducationDocumentId} />
}

export default SidepanelPatientEducationDocumentEdit
