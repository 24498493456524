import { ReactElement } from 'react'

import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'

import { FormInputListProps } from '../FormInputList'

type FormBoxProps = {
  InputList?: () => ReactElement<FormInputListProps>
  onSave: () => void
  onCancel: () => void
  children?: ReactElement
  isValid?: boolean
  rounded?: boolean
  testId: string
}

const FormBox = ({
  InputList,
  onSave,
  onCancel,
  children,
  isValid = true,
  rounded = false,
  testId,
}: FormBoxProps) => {
  return (
    <Box
      color="bg-base-color-bg-subtle"
      padding={100}
      rounded={rounded}
      data-testid={testId}
    >
      {InputList ? <InputList /> : children}

      <Button
        onClick={() => onSave()}
        className="w-full"
        disabled={!isValid}
        testId={`${testId}-save`}
      >
        Save
      </Button>
      <Button
        onClick={() => onCancel()}
        buttonStyle="secondary"
        className="mt-4 w-full"
        testId={`${testId}-cancel`}
      >
        Cancel
      </Button>
    </Box>
  )
}

export default FormBox
