import { SelectFieldProps } from '@redwoodjs/forms/dist/SelectField'

import SelectField from 'src/components/atoms/SelectField/SelectField'
import { practiceFormTypes } from 'src/data/practiceformTypes'

export const PracticeFormTypeSelectField = (props: SelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={practiceFormTypes.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default PracticeFormTypeSelectField
