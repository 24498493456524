import type { EditUserById, UpdateUserInput } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import UserForm from 'src/components/User/UserForm'

export const QUERY = gql`
  query EditUserById($id: String!) {
    user: user(id: $id) {
      id
      email
      givenName
      familyName
      status
      userTypeId
      credentialsLabel
      primaryLocationId
      locations {
        id
        name
      }
    }
  }
`
const UPDATE_USER_MUTATION = gql`
  mutation UpdateUserMutation($id: String!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      id
      email
      givenName
      familyName
      status
      userTypeId
      credentialsLabel
      primaryLocationId
      locations {
        id
        name
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({ user }: CellSuccessProps<EditUserById>) => {
  const [updateUser, { loading, error }] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => {
      toast.success('User updated')
      navigate(routes.staff())
    },
    refetchQueries: ['FindUsers'],
  })

  const onSave = (input: UpdateUserInput, id: EditUserById['user']['id']) => {
    void updateUser({ variables: { id, input } })
  }

  return (
    <UserForm user={user} onSave={onSave} error={error} loading={loading} />
  )
}
