import { formatISO } from 'date-fns'

import { useFormContext } from '@redwoodjs/forms'

import { FieldLabel } from 'src/components/atoms/Label'
import StackView from 'src/components/atoms/StackView'
import FieldError from 'src/components/FieldError/FieldError'

import { DatePickerField } from '../DatePicker'

const DiagnosisOccurrenceInputField = ({ name }: { name: string }) => {
  const startName = `${name}.onsetAt`
  const endName = `${name}.abatedAt`

  const { watch } = useFormContext()
  const value = watch(name)

  return (
    <StackView direction="row" space={50}>
      <StackView>
        <FieldLabel name={startName} required>
          Start
        </FieldLabel>
        <DatePickerField
          name={startName}
          validation={{
            setValueAs: (value: Date) => (value ? formatISO(value) : null),
            validate: (start: string) => {
              if (!start) {
                return 'Start date is required'
              }

              if (start && new Date(start) > new Date()) {
                return 'Start cannot be in the future'
              }

              if (
                start &&
                value?.abatedAt &&
                new Date(start) > new Date(value.abatedAt)
              ) {
                return 'Start cannot be after end'
              }
            },
          }}
        />
        <FieldError name={startName} />
      </StackView>
      <StackView>
        <FieldLabel name={endName}>End</FieldLabel>
        <DatePickerField
          name={endName}
          validation={{
            setValueAs: (value: Date) => (value ? formatISO(value) : null),
            validate: (end: string) => {
              if (end && new Date(end) > new Date()) {
                return 'End cannot be in the future'
              }
            },
          }}
        />
        <FieldError name={endName} />
      </StackView>
    </StackView>
  )
}

export default DiagnosisOccurrenceInputField
