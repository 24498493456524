import { isUndefined } from 'lodash'

import Modal from 'src/components/molecules/Modal'
import { AppointmentToCancel } from 'src/providers/context/AppointmentsManagementContext'

const CancelAppointmentModal: React.FC<{
  appointmentToCancel: AppointmentToCancel
  onClose: () => void
}> = ({ appointmentToCancel, onClose }) => {
  const { cancellationType, cancelAppointmentCallback } = appointmentToCancel

  if (cancellationType === 'now') {
    return (
      <Modal
        title="Reschedule now"
        modalStyle="warning"
        isOpen={!isUndefined(appointmentToCancel)}
        content="Cancel the currently scheduled visit, and immediately start the visit reschedule process."
        primaryButton={{
          text: 'Reschedule',
          onClick: () => cancelAppointmentCallback(),
        }}
        setIsOpen={(isOpen) => !isOpen && onClose()}
        onClose={onClose}
      />
    )
  }
  if (cancellationType === 'later') {
    return (
      <Modal
        title="Reschedule later"
        modalStyle="warning"
        isOpen={!isUndefined(appointmentToCancel)}
        content="Cancel the currently scheduled visit and create a task to schedule it later."
        primaryButton={{
          text: 'Reschedule later',
          onClick: () => cancelAppointmentCallback(),
        }}
        setIsOpen={(isOpen) => !isOpen && onClose()}
        onClose={onClose}
      />
    )
  }
  return (
    <Modal
      title="Cancel visit without rescheduling"
      isOpen={!isUndefined(appointmentToCancel)}
      content="Cancel the currently scheduled visit without rescheduling."
      primaryButton={{
        text: 'Cancel visit',
        onClick: () => cancelAppointmentCallback(),
      }}
      setIsOpen={(isOpen) => !isOpen && onClose()}
      onClose={onClose}
    />
  )
}

export default CancelAppointmentModal
