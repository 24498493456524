import { useContext } from 'react'

import { FetchAppointmentNotification } from 'types/graphql'

import { Redirect, routes, useMatch, useParams } from '@redwoodjs/router'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import { useAppointmentConfirmation } from 'src/hooks/useAppointmentConfirmation/useAppointmentConfirmation'
import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage'
import { createNamedContext } from 'src/utils'

type AppointmentConfirmation =
  FetchAppointmentNotification['appointmentConfirmation']

const PatientSelfCheckInContext = createNamedContext<{
  appointmentConfirmation: AppointmentConfirmation
}>('PatientSelfCheckInContext')

export const usePatientSelfCheckInContext = () =>
  useContext(PatientSelfCheckInContext)

export const PatientSelfCheckInContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { notificationId } = useParams()
  const isSuccessPage = useMatch(
    routes.patientSelfCheckInSuccess({ notificationId })
  )

  const { data, loading } = useAppointmentConfirmation({ notificationId })

  if (loading) {
    return <LoadingSpinner />
  }

  if (!data?.appointmentConfirmation) {
    return <NotFoundPage />
  }

  const { appointmentConfirmation } = data
  const { patientCheckInCompletedAt } = appointmentConfirmation

  if (appointmentConfirmation.status !== 'SCHEDULED') {
    return (
      <Redirect
        to={routes.patientDashboardChooser({ notificationId })}
        options={{ replace: true }}
      />
    )
  }

  if (patientCheckInCompletedAt && !isSuccessPage.match) {
    return (
      <Redirect
        to={routes.patientSelfCheckInSuccess({ notificationId })}
        options={{ replace: true }}
      />
    )
  }

  if (!patientCheckInCompletedAt && isSuccessPage.match) {
    return (
      <Redirect
        to={routes.patientSelfCheckInIntro({ notificationId })}
        options={{ replace: true }}
      />
    )
  }

  return (
    <PatientSelfCheckInContext.Provider
      value={{
        appointmentConfirmation,
      }}
    >
      {children}
    </PatientSelfCheckInContext.Provider>
  )
}
