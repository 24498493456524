import { genderIdentityConceptCodings } from 'common/cdr/concepts/observations/sexualHistories/index'
import type { GenderIdentity } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const genderIdentityDisplay: {
  [key in GenderIdentity]: string
} = {
  MALE: 'Male',
  FEMALE: 'Female',
  FEMALE_TO_MALE: 'Female-to-male transsexual',
  MALE_TO_FEMALE: 'Male-to-female transsexual',
  NON_CONFORMING: 'Non-conforming',
  NON_BINARY: 'Non-binary',
  OTHER: 'Other',
  ASKED_BUT_UNKNOWN: 'Asked but unknown',
}

export const genderIdentityDisplayToEnum = Object.keys(
  genderIdentityDisplay
).reduce((acc, key) => {
  acc[genderIdentityDisplay[key]] = key
  return acc
}, {})

export const genderIdentityCodeToEnum = genderIdentityConceptCodings.reduce(
  (acc, coding) => {
    if (genderIdentityDisplayToEnum[coding.display]) {
      acc[coding.code] = genderIdentityDisplayToEnum[coding.display]
    }
    return acc
  },
  {}
)

export const genderIdentities = nameValueArrayFromObject<GenderIdentity>(
  genderIdentityDisplay
)

export const genderIdentityAbbreviation: {
  [key in GenderIdentity]: string
} = {
  MALE: 'M',
  FEMALE: 'F',
  FEMALE_TO_MALE: 'FTM',
  MALE_TO_FEMALE: 'MTF',
  NON_CONFORMING: 'NC',
  NON_BINARY: 'NB',
  OTHER: 'O',
  ASKED_BUT_UNKNOWN: 'U',
}
