import type { InsuranceCoveragePlanStatus } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const insuranceCoveragePlanStatusDisplay: {
  [key in InsuranceCoveragePlanStatus]: string
} = {
  ACTIVE_COVERAGE: 'Active',
  INACTIVE: 'Inactive',
  UNKNOWN: 'Unknown',
}

export const insuranceCoveragePlanStatuses =
  nameValueArrayFromObject<InsuranceCoveragePlanStatus>(
    insuranceCoveragePlanStatusDisplay
  )
