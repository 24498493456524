import { useParams as useSidepanelParams } from 'react-router-dom'
import {
  GetTaskUserPool,
  GetTaskUserPoolVariables,
  UpdateTaskUserPool,
  UpdateTaskUserPoolVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { TaskUserPoolSidepanelForm } from 'src/components/organisms/TaskUserPoolSidepanelForm/TaskUserPoolSidepanelForm'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const GET_TASK_USER_POOL = gql`
  query GetTaskUserPool($id: String!) {
    taskUserPool(id: $id) {
      id
      displayName
      users {
        id
      }
      isDefault
    }
  }
`

const UPDATE_TASK_USER_POOL = gql`
  mutation UpdateTaskUserPool($input: UpdateTaskUserPoolInput!) {
    updateTaskUserPool(input: $input) {
      id
      displayName
      users {
        id
        givenName
        familyName
        userType {
          id
          name
        }
      }
    }
  }
`

const SidepanelTaskUserPoolEdit = () => {
  const { closeSidePanel } = useSidepanel()
  const { id } = useSidepanelParams()

  const { data, loading } = useQuery<GetTaskUserPool, GetTaskUserPoolVariables>(
    GET_TASK_USER_POOL,
    {
      variables: {
        id,
      },
    }
  )

  const [updateTaskUserPool, { loading: submitting }] = useMutation<
    UpdateTaskUserPool,
    UpdateTaskUserPoolVariables
  >(UPDATE_TASK_USER_POOL, {
    onCompleted: () => {
      toast.success('User pool updated successfully')
      closeSidePanel()
    },
  })

  if (loading) return null

  return (
    <SidepanelPage
      header="Create user pool"
      description="Set up a custom user pool for your practice."
    >
      <TaskUserPoolSidepanelForm
        onSubmit={(data) =>
          updateTaskUserPool({
            variables: {
              input: {
                ...data,
                id,
              },
            },
          })
        }
        submitting={submitting}
        defaultValues={{
          displayName: data?.taskUserPool?.displayName ?? '',
          userIds: data?.taskUserPool?.users?.map((user) => user.id) ?? [],
        }}
        disableDisplayName={data?.taskUserPool?.isDefault}
      />
    </SidepanelPage>
  )
}

export default SidepanelTaskUserPoolEdit
