import { useEffect, useState } from 'react'

import { assertUnreachable } from 'common/utils'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { useParams } from 'react-router-dom'
import {
  SearchDiagnosisCodes,
  UpsertPatientConditionInput,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'

import SelectField from 'src/components/atoms/SelectField/SelectField'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Alert from 'src/components/molecules/Alert/Alert'
import DiagnosisOccurrenceInputField from 'src/components/molecules/DiagnosisOccurrenceInputField/DiagnosisOccurrenceInputField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import GenericFormFieldArray from 'src/components/molecules/GenericFormFieldArray/GenericFormFieldArray'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import DiagnosisSearchField from 'src/components/organisms/DiagnosisSearchField/DiagnosisSearchField'
import { conditionStatuses } from 'src/data/conditionStatuses'
import {
  GroupedCondition,
  useUpsertCondition,
} from 'src/pages/PatientChartsPage/PatientHistory/Diagnoses/useConditions'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

type FormState = UpsertPatientConditionInput & {
  code: SearchDiagnosisCodes['searchDiagnosisCodes'][0]
}

interface SidepanelConditionCreateProps {
  name: 'diagnosis' | 'zCode'
  nameDisplay: string
  existingConditions: Record<string, GroupedCondition>
  searchLabel: string
}

const SidepanelConditionCreate = ({
  name,
  nameDisplay,
  existingConditions,
  searchLabel,
}: SidepanelConditionCreateProps) => {
  const [currentExistingDiagnosisCode, setCurrentExistingDiagnosisCode] =
    useState<string | null>()
  const { patientId } = useParams()
  const { closeSidePanel } = useSidepanel()
  const [upsertPatientCondition, { loading: upsertingPatientCondition }] =
    useUpsertCondition()

  const formMethods = useForm<FormState>({
    defaultValues: {
      occurrences: [
        {
          onsetAt: null,
          abatedAt: null,
        },
      ],
      status: 'active',
    },
  })

  const occurrences = formMethods.watch('occurrences')

  const onSubmit = async (data: FormState) => {
    const input = {
      patientId,
      diagnosisCode: data.code.code,
      occurrences: data.occurrences || [],
      status: data.status,
    }
    upsertPatientCondition({
      variables: { input },
      onCompleted: closeSidePanel,
    })
  }

  // watch for updates to the diagnosis field, if it's an existing diagnosis
  // then update the form with the existing values
  useEffect(() => {
    const { unsubscribe } = formMethods.watch(({ code, occurrences }) => {
      if (isEmpty(existingConditions) || isEmpty(code)) return

      const existingDiagnosis = existingConditions[code.code]

      if (!existingDiagnosis) {
        setCurrentExistingDiagnosisCode(null)
        return
      }

      if (existingDiagnosis.code !== currentExistingDiagnosisCode) {
        setCurrentExistingDiagnosisCode(existingDiagnosis.code)

        const existingOccurrences = existingDiagnosis?.conditions.map((d) => ({
          id: d.id,
          onsetAt: d.onsetAt,
          abatedAt: d.abatedAt,
        }))

        if (!isEqual(occurrences, existingOccurrences)) {
          formMethods.resetField('occurrences', {
            defaultValue: existingOccurrences,
          })
          formMethods.resetField('status', {
            defaultValue: existingDiagnosis.lastClinicalStatus,
          })
        }
      }
    })

    return () => unsubscribe()
  }, [currentExistingDiagnosisCode, existingConditions, formMethods])

  return (
    <SidepanelPage
      testId={`sidepanel-${name}-create`}
      header={`Add ${nameDisplay}`}
      description="Fill out all required fields"
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Save and close',
          submitting: upsertingPatientCondition,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
        divider={false}
      >
        <StackView className="pt-base-space-container-inset-m">
          <Typography textStyle="title-xs">Details</Typography>
          <Typography
            textStyle="body-s"
            color="text-base-color-fg-muted"
            className="!my-0"
          >{`Select a ${nameDisplay} and it’s current status.`}</Typography>
        </StackView>
        <FormInputList
          items={[
            {
              name: 'code',
              label: searchLabel,
              required: true,
              direction: 'col',
              formInputComponent: DiagnosisSearchField,
              inputProps: {
                patientId,
                searchSet:
                  name === 'diagnosis'
                    ? 'DIAGNOSIS'
                    : name === 'zCode'
                    ? 'Z_CODE'
                    : assertUnreachable(name),
                defaultSearching: true,
              },
            },
            {
              name: 'status',
              label: 'Status',
              required: true,
              direction: 'col',
              formInputComponent: SelectField,
              inputProps: {
                options: conditionStatuses.map((status) => ({
                  value: status.value,
                  display: status.name,
                })),
              },
            },
            {
              name: 'occurrences',
              label: 'Occurence(s)',
              subtitle: `Click on 'Add occurrence' to add multiple occurrences
              of the same ${nameDisplay}`,
              direction: 'col',
              formInputComponent: GenericFormFieldArray,
              inputProps: {
                formInputComponent: DiagnosisOccurrenceInputField,
                addButtonLabel: 'Add occurrence',
                addNewDefault: {
                  onsetAt: null,
                  abatedAt: null,
                },
                allowEmpty: true,
                showItemErrors: false,
              },
            },
          ]}
        />
        <StackView space={75} divider>
          {currentExistingDiagnosisCode && !occurrences && (
            <Alert
              title={`This ${nameDisplay} will be deleted`}
              description={`Removing all occurrences will delete this ${nameDisplay} from the patient's chart. This is different from marking the ${nameDisplay} as resolved.`}
            />
          )}
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelConditionCreate
