import { useContext, useState } from 'react'

import { createNamedContext } from 'src/utils'

const PDFAnnotationContext = createNamedContext<{
  isAnnotatingPDF?: boolean
  setisAnnotatingPDF?: (open: boolean) => void
}>('PDFAnnotationContext')

export function usePDFAnnotationContext() {
  const context = useContext(PDFAnnotationContext)
  return context
}

export function PDFAnnotationContextProvider({ children }) {
  const [isAnnotatingPDF, setisAnnotatingPDF] = useState<boolean>(false)

  return (
    <PDFAnnotationContext.Provider
      value={{
        isAnnotatingPDF,
        setisAnnotatingPDF,
      }}
    >
      {children}
    </PDFAnnotationContext.Provider>
  )
}

export default PDFAnnotationContext
