import { useCallback, useEffect, useRef, useState } from 'react'

import {
  ArrowTopRightOnSquareIcon,
  PencilIcon,
} from '@heroicons/react/24/solid'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import clsx from 'clsx'
import {
  $getSelection,
  $isRangeSelection,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'

import Box from '../../Box'
import Button from '../../Button/Button'
import StackView from '../../StackView/StackView'
import { getSelectedNode } from '../utils/utils'

const positionEditorElement = (editor, floatingLinkEditor, selection) => {
  const editorRect = editor._rootElement.getBoundingClientRect()
  const floatingLinkEditorRect = floatingLinkEditor?.getBoundingClientRect()
  const selectionRect = selection?.getBoundingClientRect()

  if (floatingLinkEditorRect) {
    if (selection === null) {
      floatingLinkEditor.style.opacity = '0'
      floatingLinkEditor.style.top = '-1000px'
      floatingLinkEditor.style.left = '-1000px'
    } else {
      floatingLinkEditor.style.opacity = '1'
      // Position 10px above selection
      floatingLinkEditor.style.top = `${
        selectionRect.top -
        editorRect.top -
        floatingLinkEditorRect.height / 2 -
        10
      }px`

      // Don't allow us to position the floatingLinkEditor off the right side of the page
      if (
        selectionRect.right + floatingLinkEditorRect.width >
        window.innerWidth
      ) {
        floatingLinkEditor.style.right = 0
      } else {
        floatingLinkEditor.style.left = `${
          selectionRect.left - editorRect.left - 50
        }px`
      }
    }
  }
}

const FloatingLinkEditor = () => {
  const [editor] = useLexicalComposerContext()
  const floatingLinkEditorRef = useRef(null)
  const inputRef = useRef(null)
  const mouseDownRef = useRef(false)
  const [linkUrl, setLinkUrl] = useState('')
  const [isEditMode, setEditMode] = useState(false)
  const [lastSelection, setLastSelection] = useState(null)

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL())
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL())
      } else {
        setLinkUrl('')
      }
    }
    const nativeSelection = window.getSelection()
    const activeElement = document.activeElement

    if (floatingLinkEditorRef.current === null) {
      return
    }

    const rootElement = editor.getRootElement()
    if (
      selection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const selection = nativeSelection.getRangeAt(0)

      if (!mouseDownRef.current) {
        positionEditorElement(editor, floatingLinkEditorRef.current, selection)
      }
      setLastSelection(selection)
    } else if (!activeElement || activeElement.className !== 'link-input') {
      positionEditorElement(editor, floatingLinkEditorRef.current, null)
      setLastSelection(null)
      setEditMode(false)
      setLinkUrl('')
    }

    return true
  }, [editor])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor()
        })
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor()
          return true
        },
        1
      )
    )
  }, [editor, updateLinkEditor])

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor()
    })
  }, [editor, updateLinkEditor])

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditMode])

  return (
    <div ref={floatingLinkEditorRef} className="absolute z-50">
      <Box
        color="bg-base-color-bg-default"
        className="w-96 p-3 shadow-md"
        rounded
        border
      >
        {isEditMode ? (
          <input
            data-testid="floating-link-editor-input"
            ref={inputRef}
            className={clsx([
              'link-input',
              'block w-full text-sm text-gray-900',
              'rounded-md border-gray-300 shadow-sm',
              'focus:border-primary focus:ring-primary',
              'border p-2',
            ])}
            value={linkUrl}
            onChange={(event) => {
              setLinkUrl(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
                if (lastSelection !== null) {
                  if (linkUrl !== '') {
                    editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl)
                  }
                  setEditMode(false)
                }
              } else if (event.key === 'Escape') {
                event.preventDefault()
                setEditMode(false)
              }
            }}
          />
        ) : (
          <StackView
            className="link-input text-primary"
            direction="row"
            alignItems="center"
          >
            <a
              className="flex w-full items-center truncate whitespace-nowrap text-sm"
              href={linkUrl}
              target="_blank"
              rel="noreferrer"
            >
              {linkUrl} <ArrowTopRightOnSquareIcon className="ml-2 h-5 w-5" />
            </a>
            <Button
              testId="floating-link-editor-edit-button"
              className="link-edit w-min"
              onClick={() => {
                setEditMode(true)
              }}
              buttonSize="s"
              buttonStyle="ghost"
              icon={PencilIcon}
            />
          </StackView>
        )}
      </Box>
    </div>
  )
}

export default FloatingLinkEditor
