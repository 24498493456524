import { CheckCircleIcon } from '@heroicons/react/24/solid'
import {
  UpdateAppointmentConfirmation,
  ConfirmationStatus,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Dropdown from 'src/components/atoms/Dropdown/Dropdown'

import { Option } from '../Select/Select'

interface Props {
  appointmentId: string
  confirmedAt?: string
}

const UPDATE_APPOINTMENT_CONFIRMATION_MUTATION = gql`
  mutation UpdateAppointmentConfirmation(
    $id: String!
    $confirmationStatus: ConfirmationStatus!
  ) {
    updateAppointmentConfirmation(
      id: $id
      confirmationStatus: $confirmationStatus
    ) {
      id
      confirmedAt
    }
  }
`

const AppointmentConfirmationSelect = ({
  appointmentId,
  confirmedAt,
}: Props) => {
  const [updateAppointmentConfirmation] =
    useMutation<UpdateAppointmentConfirmation>(
      UPDATE_APPOINTMENT_CONFIRMATION_MUTATION
    )

  const options: Option[] = [
    {
      name: 'Confirmed',
      value: 'CONFIRMED',
      icon: {
        value: <CheckCircleIcon className="h-5 w-5 text-success" />,
        class: '',
      },
    },
    {
      name: 'Unconfirmed',
      value: 'UNCONFIRMED',
      icon: {
        value: <CheckCircleIcon className="h-5 w-5 text-gray-400" />,
        class: '',
      },
    },
  ]

  const onSelect = (confirmationStatus: ConfirmationStatus) => {
    updateAppointmentConfirmation({
      variables: {
        id: appointmentId,
        confirmationStatus,
      },
    })
  }
  return (
    <Dropdown
      mode="id"
      name="appointment-confirmation-select"
      options={options}
      selected={confirmedAt ? 'CONFIRMED' : 'UNCONFIRMED'}
      onSelect={onSelect}
    />
  )
}

export default AppointmentConfirmationSelect
