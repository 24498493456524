import { formatDisplayName } from 'common/utils'
import { GetPractitionersAndUserPools } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { ComboboxField } from 'src/components/atoms/ComboboxField'
import { serializeTaskAssignee } from 'src/utils/task'

const QUERY = gql`
  query GetPractitionersAndUserPoolsQuery {
    taskUserPools {
      id
      displayName
    }
    users {
      id
      givenName
      familyName
    }
  }
`

export const TaskAssignmentComboboxField = ({ name }: { name: string }) => {
  const { data } = useQuery<GetPractitionersAndUserPools>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return (
    <ComboboxField
      name={name}
      options={[
        { heading: true, name: 'User pools', value: 'pool' },
        ...(data?.taskUserPools || []).map((pool) => ({
          name: pool.displayName,
          value: serializeTaskAssignee({
            type: 'pool',
            id: pool.id,
          }),
        })),
        { heading: true, name: 'Users', value: 'user' },
        ...(data?.users || []).map((user) => ({
          name: formatDisplayName(user),
          value: serializeTaskAssignee({
            type: 'user',
            id: user.id,
          }),
        })),
      ]}
    />
  )
}
