import { Form, useForm } from '@redwoodjs/forms'
import { Redirect, useParams, routes, navigate } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button/Button'
import InputField from 'src/components/atoms/InputField/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import FieldError from 'src/components/FieldError/FieldError'
import Page from 'src/components/molecules/Page/LegacyPage'
import TabNav from 'src/components/molecules/TabNav/TabNav'

import { CaregiverTable } from './CaregiverTable'
import { PatientTable } from './PatientTable'

export const refetchQueries = [
  'SearchCaregiversForFamilyPortalAdmin',
  'SearchPatientsForFamilyPortalAdmin',
]

const validUserTypes = ['caregiver', 'patient']

const FamilyPortalAdminPage = () => {
  const { userType, query } = useParams()
  const formMethods = useForm()

  if (!validUserTypes.includes(userType)) {
    return (
      <Redirect
        to={routes.familyPortalAdmin({ userType: 'caregiver' })}
        options={{ replace: true }}
      />
    )
  }

  return (
    <Page
      tag={{ title: 'Family portal admin' }}
      title="Family portal admin"
      description="Centrally manage family portal access for patients and their caregivers."
    >
      <TabNav
        rootPath={routes.familyPortalAdmin({ userType: '' })}
        tabs={[
          {
            name: 'Caregivers',
            key: 'caregiver',
            to: routes.familyPortalAdmin({ userType: 'caregiver' }),
          },
          {
            name: 'Patients',
            key: 'patient',
            to: routes.familyPortalAdmin({ userType: 'patient' }),
          },
        ]}
      />
      <Form
        formMethods={formMethods}
        onSubmit={(data) => {
          formMethods.reset()
          navigate(
            routes.familyPortalAdmin({
              userType,
              query: data.query,
            })
          )
        }}
      >
        <StackView gap={50}>
          <StackView direction="row" gap={50}>
            <StackView>
              <InputField
                name="query"
                validation={{ minLength: 2 }}
                fullWidth
              />
              <FieldError name="query" />
            </StackView>
            <Button type="submit" text="Search" />
          </StackView>

          {query ? (
            <Typography
              textStyle="interface-default-xs"
              color="text-base-color-fg-subtle"
            >
              Showing results for {`"${query}"`}
            </Typography>
          ) : null}
        </StackView>
      </Form>
      {userType === 'patient' ? <PatientTable /> : <CaregiverTable />}
    </Page>
  )
}

export default FamilyPortalAdminPage
