import { ContraceptivePresence } from 'types/graphql'

const nameValueArrayFromObject = <T extends string>(object: {
  [key in T]: string
}): { name: string; value: T }[] => {
  return Object.entries<string>(object).map(([key, value]) => ({
    name: value,
    value: key as T,
  }))
}

export const contraceptivePresenceIndicatorDisplay: {
  [key in ContraceptivePresence]: string
} = {
  YES: 'Yes',
  NEVER: 'Never',
  UNKNOWN: 'Unknown',
}

export const contraceptivePresenceIndicatorDisplayToEnum = Object.keys(
  contraceptivePresenceIndicatorDisplay
).reduce((acc, key) => {
  acc[contraceptivePresenceIndicatorDisplay[key]] = key
  return acc
}, {})

export const contraceptivePresenceIndicators =
  nameValueArrayFromObject<ContraceptivePresence>(
    contraceptivePresenceIndicatorDisplay
  )
