export const PATIENT_RELATED_PERSON_RELATIONSHIP_WITH_FULL_DETAILS_FRAGMENT = gql`
  fragment PatientRelatedPersonRelationshipWithFullDetailsFragment on PatientRelatedPersonRelationship {
    id
    verifiedAt
    isGuarantor
    guardianshipType
    relationshipType
    doesResideWith
    relatedPersonId
    relatedPerson {
      id
      birthDate
      namePrefix
      givenName
      middleName
      familyName
      nameSuffix
      occupation
      employer
      maritalStatus
      preferredName
      genderIdentity
      sexualOrientation
      race
      ethnicity
      coreDemographicsVerifiedAt
      additionalDemographicsVerifiedAt
      genderIdentity
      sexualOrientation
      preferredPronouns
      race
      ethnicity
      socialSecurityNumber
      contactInformation {
        id
        verifiedAt
        emailAddress
        homeNumber
        mobileNumber
        faxNumber
        workNumber
        primaryLanguage
        secondaryLanguage
        primaryPreferredCommunication
        secondaryPreferredCommunication
        homeAddress {
          id
          line1
          line2
          city
          postalCode
          state
          country
        }
        mailingAddress {
          id
          line1
          line2
          city
          postalCode
          state
          country
        }
      }
    }
  }
`

export const PATIENT_STATUS_FRAGMENT = gql`
  fragment PatientStatusFragment on Patient {
    active
    deceased
    inCollections
    hasMembership
  }
`
