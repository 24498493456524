import {
  CreatePatientDocumentInput,
  FindPatientDocuments,
  FindPatientDocumentById,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

const PATIENT_DOCUMENT_FRAGMENT = gql`
  fragment PatientDocumentFragment on Document {
    id
    createdAt
    updatedAt
    authoredByReference
    authoredBy {
      id
      namePrefix
      givenName
      middleName
      familyName
      nameSuffix
    }
    authenticatedByReference
    encounterReference
    type {
      code
      system
      display
    }
    subType {
      code
      system
      display
    }
    title
    content {
      url
      contentType
    }
    docStatus
    textContent
    followUpStaffMessageTask {
      id
    }
  }
`

export const PATIENT_DIAGNOSTIC_REPORT_FRAGMENT = gql`
  fragment PatientDiagnosticReportFragment on DiagnosticReport {
    id
    healthGorillaId
    encounterId
    name
    coding {
      code
      system
      display
    }
    status
    issuedAt
    effectiveAt
    updatedAt
    resultIds
    category
    presentedForm {
      url
      contentType
      title
    }
    reviews {
      id
      reviewer {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
      }
    }
  }
`

export const FIND_PATIENT_DOCUMENTS_QUERY = gql`
  query FindPatientDocuments($id: String!) {
    patient(id: $id) {
      id
      diagnosticReports {
        ...PatientDiagnosticReportFragment
      }
      documents {
        ...PatientDocumentFragment
      }
    }
  }
  ${PATIENT_DOCUMENT_FRAGMENT}
  ${PATIENT_DIAGNOSTIC_REPORT_FRAGMENT}
`

const FIND_PATIENT_DOCUMENT_QUERY = gql`
  query FindPatientDocumentById($id: String!) {
    document: document(id: $id) {
      ...PatientDocumentFragment
    }
  }
  ${PATIENT_DOCUMENT_FRAGMENT}
`

const CREATE_PATIENT_DOCUMENT_MUTATION = gql`
  mutation createPatientDocument($input: CreatePatientDocumentInput!) {
    createPatientDocument(input: $input) {
      id
      documents {
        ...PatientDocumentFragment
      }
    }
  }
  ${PATIENT_DOCUMENT_FRAGMENT}
`

const UPDATE_PATIENT_DOCUMENT_MUTATION = gql`
  mutation UpdatePatientDocument(
    $id: String!
    $input: UpdatePatientDocumentInput!
  ) {
    updatePatientDocument(id: $id, input: $input) {
      id
      documents {
        ...PatientDocumentFragment
      }
    }
  }
  ${PATIENT_DOCUMENT_FRAGMENT}
`

const DELETE_PATIENT_DOCUMENT_MUTATION = gql`
  mutation DeletePatientDocument($id: String!) {
    deletePatientDocument(id: $id) {
      id
      documents {
        ...PatientDocumentFragment
      }
    }
  }
  ${PATIENT_DOCUMENT_FRAGMENT}
`

export const usePatientDocumentsQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<FindPatientDocuments>(
    FIND_PATIENT_DOCUMENTS_QUERY,
    {
      variables: { id: patientId },
      skip: !patientId,
    }
  )

  return {
    documents: data?.patient?.documents,
    diagnosticReports: data?.patient?.diagnosticReports,
    loading,
    error,
  }
}

export const useCreateDocument = () => {
  return useMutation<CreatePatientDocumentInput>(
    CREATE_PATIENT_DOCUMENT_MUTATION,
    {
      onCompleted: () => {
        toast.success('Document created')
      },
    }
  )
}

export const useUpdateDocument = () => {
  return useMutation(UPDATE_PATIENT_DOCUMENT_MUTATION, {
    onCompleted: () => {
      toast.success('Document updated')
    },
  })
}

export const useDeleteDocument = () => {
  return useMutation(DELETE_PATIENT_DOCUMENT_MUTATION, {
    onCompleted: () => {
      toast.success('Document deleted')
    },
  })
}

export const useFindPatientDocumentQuery = (id: string) => {
  return useQuery<FindPatientDocumentById>(FIND_PATIENT_DOCUMENT_QUERY, {
    variables: { id },
    skip: !id,
  })
}
