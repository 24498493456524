import clsx from 'clsx'

import Typography from 'src/components/atoms/Typography'

import Box from '../../atoms/Box/Box'
import StackView from '../../atoms/StackView/StackView'

export const DataRow: React.FC<{
  label?: React.ReactElement | string
  value: React.ReactElement | string | null
  alignItems?: 'center' | 'start'
  direction?: 'row' | 'col'
  rowHeight?: 'sm' | 'md' | 'lg'
  leftColumnWidth?: 'sm' | 'md' | 'lg'
}> = ({
  label,
  value,
  alignItems = 'center',
  direction = 'row',
  rowHeight = 'md',
  leftColumnWidth = 'md',
  ...rest
}) => {
  let heightInt
  if (rowHeight === 'sm') {
    heightInt = 4
  } else if (rowHeight === 'md') {
    heightInt = 8
  } else if (rowHeight === 'lg') {
    heightInt = 16
  }

  let basis
  if (leftColumnWidth === 'sm') {
    basis = '2/12'
  } else if (leftColumnWidth === 'md') {
    basis = '4/12'
  } else if (leftColumnWidth === 'lg') {
    basis = '6/12'
  }
  return (
    <StackView
      {...rest}
      direction={direction}
      alignItems={alignItems}
      className="h-fit min-h-min py-3 first:pt-0 last:pb-0"
      space={direction === 'row' ? 75 : 25}
    >
      {label && (
        <Box
          flex={direction === 'row' ? basis : 'auto'}
          className={clsx(
            'flex shrink-0',
            `items-${alignItems}`,
            direction === 'row' ? `min-h-${heightInt}` : 'w-full'
          )}
        >
          {typeof label === 'string' ? (
            <Typography
              textStyle="interface-strong-s"
              color="text-base-color-fg-subtle"
            >
              {label}
            </Typography>
          ) : (
            <>{label}</>
          )}
        </Box>
      )}
      <Box
        grow
        className={clsx(
          'flex items-stretch text-gray-900',
          direction === 'row' ? '' : 'w-full'
        )}
      >
        {typeof value === 'string' ? (
          <Typography textStyle="body-s" color="text-base-color-fg-muted">
            {value}
          </Typography>
        ) : (
          <>{value}</>
        )}
      </Box>
    </StackView>
  )
}

export default DataRow
