import React, { useState } from 'react'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

import DataDisplayList, { Data } from '../DataDisplayList/DataDisplayList'

export interface EditComponentProps {
  onComplete?: () => void
  onCancel?: () => void
}

interface DataDisplayCardProps {
  title: string
  description?: string
  data: Data[]
  EditComponent?: React.FC<EditComponentProps>
  testId?: string
}

export const DataDisplayCard: React.FC<DataDisplayCardProps> = ({
  title,
  description,
  data,
  EditComponent,
  testId,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const setIsEditingFalse = () => {
    setIsEditing(false)
  }
  return (
    <Card
      testId={testId}
      header={
        <StackView>
          <StackView
            direction="row"
            alignItems="center"
            justifyContent="between"
          >
            <Typography textStyle="title">{title}</Typography>
            {EditComponent && !isEditing && (
              <Button onClick={() => setIsEditing(true)}>Edit</Button>
            )}
          </StackView>
          {description ? (
            <Typography color="text-base-color-fg-muted">
              {description}
            </Typography>
          ) : null}
        </StackView>
      }
    >
      <Box horizontalPadding={100}>
        <StackView divider>
          {isEditing ? (
            <EditComponent
              onComplete={() => setIsEditingFalse()}
              onCancel={() => setIsEditingFalse()}
            />
          ) : (
            <DataDisplayList data={data} />
          )}
        </StackView>
      </Box>
    </Card>
  )
}

export default DataDisplayCard
