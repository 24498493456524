export const REPEAT_OPTIONS = [
  {
    value: 'daily',
    name: 'Day(s)',
  },
  {
    value: 'weekly',
    name: 'Week(s)',
  },
  {
    value: 'monthly',
    name: 'Month(s)',
  },
  {
    value: 'yearly',
    name: 'Year(s)',
  },
]
