import clsx from 'clsx'

import Badge from 'src/components/atoms/Badge/Badge'
import Box, { BoxColorType } from 'src/components/atoms/Box/Box'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography, {
  TypographyColor,
} from 'src/components/atoms/Typography/Typography'

export const Circle = ({
  color,
  className = '',
  text,
  fontColor = 'text-base-color-fg-emphasis',
}: {
  color: BoxColorType
  className?: string
  text: string
  fontColor?: TypographyColor
}) => {
  return (
    <Box
      className={clsx(
        'flex h-5 w-5 items-center justify-center rounded-full',
        className
      )}
      color={color}
    >
      <Box>
        <Typography style={{ fontSize: 10 }} color={fontColor}>
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

const MultiUserBadge = ({ totalUsers, usersNameInitials, badgeTitle }) => {
  const isOnlyOne = totalUsers === 1
  const nameInitials = usersNameInitials.split(',')
  const totalNameInitials = nameInitials.length
  const shouldRenderExtraBadge = nameInitials.length != totalUsers
  const colors: BoxColorType[] = [
    'bg-base-color-fg-brand',
    'bg-core-color-teal-50',
    'bg-base-color-fg-danger',
    'bg-base-color-fg-accent',
  ]
  if (isOnlyOne) {
    return (
      <Badge>
        <StackView direction="row" space={50} alignItems="center">
          <Box>{badgeTitle}</Box>
          <Box>
            <Circle color="bg-core-color-teal-50" text={nameInitials[0]} />
          </Box>
        </StackView>
      </Badge>
    )
  }
  return (
    <Badge>
      <StackView direction="row" space={50} alignItems="center">
        <Box>{badgeTitle}</Box>
        <StackView direction="row">
          {nameInitials.map((initial, index) => {
            return (
              <Circle
                key={`${initial}-${index}`}
                color={colors[index]}
                className={clsx(
                  'box-content',
                  'border',
                  `border-${colors[index]}`,
                  index > 0 && '-ml-1.5 '
                )}
                text={initial}
              />
            )
          })}
          {shouldRenderExtraBadge && (
            <Circle
              text={`+${totalUsers - totalNameInitials}`}
              color="bg-base-color-bg-subtle"
              className="-ml-1.5"
              fontColor="text-base-color-fg-muted"
            />
          )}
        </StackView>
      </StackView>
    </Badge>
  )
}

export default MultiUserBadge
