import { maxBy } from 'lodash'
import {
  DocumentCaseResolutionType,
  PatientDocumentType,
  DocumentType,
  DocumentCase,
} from 'types/graphql'

import { externalRequestInputListItems } from 'src/components/CreateExternalRequest'
import { patientEducationInputListItems } from 'src/components/CreatePatientEducationDocument'
import { patientFormInputListItems } from 'src/components/CreatePatientForm'
import { practiceAdminInputListItems } from 'src/components/CreatePracticeAdminDocument'
import { practiceFormInputListItems } from 'src/components/CreatePracticeForm'
import { practiceLetterInputListItems } from 'src/components/CreatePracticeLetter'
import { screeningToolInputListItems } from 'src/components/CreateScreeningTool'
import { Item } from 'src/components/molecules/FormInputList'
import { patientDocumentTypeDisplay } from 'src/data/patientDocumentTypes'
import { patientDocumentFormInputListItems } from 'src/pages/Sidepanel/SidepanelPatientDocumentUpload'

export type DocumentCaseDocumentType = DocumentType | PatientDocumentType

export type DocumentCaseFormInputList = (opts: {
  type: string
  patientId?: string
  defaultPatientId?: string
  isDocumentCase?: boolean
}) => Item[]

export const documentCaseResolutionConfig: {
  [key in DocumentCaseResolutionType]: {
    getFormInputListItems: DocumentCaseFormInputList
    inputField: string
  }
} = {
  PRACTICE_ADMIN: {
    getFormInputListItems: practiceAdminInputListItems,
    inputField: 'createPracticeAdminDocument',
  },
  PATIENT_FORM: {
    getFormInputListItems: patientFormInputListItems,
    inputField: 'createPatientForm',
  },
  EXTERNAL_REQUEST: {
    getFormInputListItems: externalRequestInputListItems,
    inputField: 'createExternalRequest',
  },
  SCREENING_TOOL: {
    getFormInputListItems: screeningToolInputListItems,
    inputField: 'createScreeningTool',
  },
  PRACTICE_LETTER: {
    getFormInputListItems: practiceLetterInputListItems,
    inputField: 'createPracticeLetter',
  },
  PRACTICE_FORM: {
    getFormInputListItems: practiceFormInputListItems,
    inputField: 'createPracticeForm',
  },
  PATIENT_EDUCATION: {
    getFormInputListItems: patientEducationInputListItems,
    inputField: 'createPatientEducationDocument',
  },
  CLINICAL_RESULT: {
    getFormInputListItems: patientDocumentFormInputListItems,
    inputField: 'createClinicalResultsDocument',
  },
  PATIENT_DOCUMENT: {
    getFormInputListItems: patientDocumentFormInputListItems,
    inputField: 'createPatientDocument',
  },
}

export const getDocumentResolutionType = (
  documentType: DocumentCaseDocumentType
): DocumentCaseResolutionType | undefined => {
  if (!documentType) return undefined
  return documentType === 'CLINICAL_RESULT'
    ? 'CLINICAL_RESULT'
    : getIsPatientDocument(documentType)
    ? 'PATIENT_DOCUMENT'
    : (documentType as DocumentCaseResolutionType)
}

export const getIsPatientDocument = (
  documentType: DocumentCaseDocumentType
): boolean => {
  if (!documentType) return false
  return documentType && !!patientDocumentTypeDisplay[documentType]
}

export const documentCaseTypeNameToEnum: Record<string, string> = {
  PatientEducationDocument: 'PATIENT_EDUCATION',
  PatientForm: 'PATIENT_FORM',
  PracticeForm: 'PRACTICE_FORM',
  PracticeLetter: 'PRACTICE_LETTER',
  PatientDocument: 'PATIENT_DOCUMENT',
  PracticeAdminDocument: 'PRACTICE_ADMIN',
  ScreeningTool: 'SCREENING_TOOL',
  ExternalRequest: 'EXTERNAL_REQUEST',
  ClinicalResult: 'CLINICAL_RESULT',
}

export const getLatestDocumentCaseUpdate = (documentCase: DocumentCase) => {
  if (!documentCase) return
  const {
    binary: {
      practiceForm,
      externalRequest,
      screeningTool,
      practiceLetter,
      patientEducationDocument,
      patientForm,
      practiceAdminDocument,
      clinicalResultsDocument,
    },
    type,
    subType,
    title,
  } = documentCase

  const latestUpdate = maxBy(
    [
      practiceForm,
      externalRequest,
      screeningTool,
      practiceLetter,
      patientEducationDocument,
      patientForm,
      practiceAdminDocument,
      clinicalResultsDocument,
    ],
    (entry) => entry?.['updatedAt']
  )

  return latestUpdate ?? { type, subType, title }
}
