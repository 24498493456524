import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { dateTimeFormatter, jsDateToLocalDate } from 'common/data/date'
import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName, formatMoneyInCents } from 'common/utils'

import { routes } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { BalanceDisplay } from 'src/components/molecules/BalanceDisplay/BalanceDisplay'
import { DataDisplayList } from 'src/components/molecules/DataDisplayList'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'
import PatientPaymentPlanBalanceCard from 'src/components/PatientPaymentPlanBalanceCard/PatientPaymentPlanBalanceCard'
import { guardianshipTypeDisplay } from 'src/data/guardianshipTypes'
import { sexAbbreviation } from 'src/data/sexes'
import { formatAge } from 'src/lib/formatters'
import { openNewTab } from 'src/utils'

import { useSidepanelCaregiverFinancialsContext } from './SidepanelCaregiverFinancialsContext'

export const PatientsTab = () => {
  const { guarantor } = useSidepanelCaregiverFinancialsContext()

  return (
    <StackView className="p-core-space-150">
      {guarantor.guarantees.map((patient) => {
        const birthDate = jsDateToLocalDate(patient.birthDate)
        const formattedBirthDate = birthDate.format(
          dateTimeFormatter('MM/dd/yyyy')
        )
        const relationship = patient.patientRelatedPersonRelationships.find(
          (relationship) => relationship.relatedPerson.id === guarantor.id
        )

        return (
          <StackView key={patient.id} testId="patient-list-item">
            <SectionHeader
              title={formatDisplayName(patient)}
              description={[
                sexAbbreviation[patient.sexAtBirth],
                `${formatAge(patient.birthDate)} (${formattedBirthDate})`,
                relationship.guardianshipType === 'NON_GUARDIAN'
                  ? guardianshipTypeDisplay[relationship.guardianshipType]
                  : `${
                      guardianshipTypeDisplay[relationship.guardianshipType]
                    } guardian`,
                relationshipTypeDisplay[relationship.relationshipType],
              ].join(' • ')}
              actions={
                <Button
                  text="View"
                  buttonStyle="secondary"
                  buttonSize="s"
                  icon={ArrowTopRightOnSquareIcon}
                  onClick={() =>
                    openNewTab(
                      routes.patientCharts({
                        id: patient.id,
                      })
                    )
                  }
                />
              }
            />

            {patient.currentPaymentPlan ? (
              <PatientPaymentPlanBalanceCard
                patientPaymentPlanId={patient.currentPaymentPlan.id}
              />
            ) : null}

            <DataDisplayList
              data={[
                {
                  label: 'Total patient balance',
                  value: (
                    <BalanceDisplay textStyle="interface-default-s">
                      {patient.totalOutstandingBalanceCents}
                    </BalanceDisplay>
                  ),
                },
                patient.currentPaymentPlan
                  ? {
                      label: 'Payment plan balance',
                      value: (
                        <BalanceDisplay textStyle="interface-default-s">
                          {patient.currentPaymentPlan.remainingBalanceCents}
                        </BalanceDisplay>
                      ),
                    }
                  : null,
                patient.currentPaymentPlan
                  ? {
                      label: 'Additional balance',
                      value: (
                        <BalanceDisplay textStyle="interface-default-s">
                          {patient.totalOutstandingBalanceCents -
                            patient.currentPaymentPlan.remainingBalanceCents}
                        </BalanceDisplay>
                      ),
                    }
                  : null,
                {
                  label: 'Last payment',
                  value: patient.lastPayment ? (
                    <StackView>
                      <Typography
                        textStyle="interface-default-s"
                        color="text-base-color-fg-muted"
                      >
                        {jsDateToLocalDate(patient.lastPayment.date).format(
                          dateTimeFormatter('MM/dd/yyyy')
                        )}
                      </Typography>
                      <Typography
                        textStyle="interface-default-xs"
                        color="text-base-color-fg-subtle"
                      >
                        {formatMoneyInCents(
                          patient.lastPayment.amountCents ?? 0
                        )}
                      </Typography>
                    </StackView>
                  ) : (
                    <Typography
                      textStyle="interface-default-s"
                      color="text-base-color-fg-muted"
                    >
                      N/A
                    </Typography>
                  ),
                },
                {
                  label: 'Additional guarantor(s)',
                  value:
                    patient.patientRelatedPersonRelationships
                      .filter(
                        (relationship) =>
                          relationship.isGuarantor &&
                          relationship.relatedPerson.id !== guarantor.id
                      )
                      .map((relationship) =>
                        formatDisplayName(relationship.relatedPerson)
                      )
                      .join(', ') || 'N/A',
                },
              ].filter(Boolean)}
            />
          </StackView>
        )
      })}
    </StackView>
  )
}
