import { useParams } from 'react-router-dom'

import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'

import ContactEditCell from './ContactEditCell'

const SidepanelContactEdit = () => {
  const { contactId } = useParams()

  return (
    <SidepanelPage testId="sidepanel-contact-edit" header="Edit contact">
      <ContactEditCell id={contactId} />
    </SidepanelPage>
  )
}

export default SidepanelContactEdit
