import { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { generateVisitNote } from 'src/pages/PatientChartsPage/PatientVisits/useSignVisit'
import { useVisitQuery } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const SidepanelVisitPrintVisitNote = () => {
  const { visitId } = useParams()
  const { sidepanelContext } = useSidepanel()
  const { visit, currentPractitioner } = useVisitQuery(visitId)

  const [isGenerating, setIsGenerating] = useState(false)
  const [generated, setGenerated] = useState<URL>()

  useEffect(() => {
    if (visit) {
      setIsGenerating(true)
      generateVisitNote(visit, currentPractitioner, {
        hideAdolescentConfidentialInfo:
          !!sidepanelContext.hideAdolescentConfidentialInfo,
      }).then((visitNote) => {
        setGenerated(visitNote)
        setIsGenerating(false)
      })
    }
  }, [visit, currentPractitioner])

  if (!visit) return null

  return (
    <SidepanelPage header="Print visit note">
      <StackView className="h-full pt-4" space={50}>
        {isGenerating ? (
          <LoadingSpinner />
        ) : (
          <FileDisplay
            type="application/pdf"
            url={generated}
            showDownloadLink={false}
          />
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelVisitPrintVisitNote
