import CreateDocument from 'src/components/CreateDocument/CreateDocument'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'

const SidepanelDocumentCreate = () => {
  return (
    <SidepanelPage
      header="Document upload"
      description="Upload your document and tag it appropriately."
      testId="sidepanel-document-create"
    >
      <CreateDocument />
    </SidepanelPage>
  )
}

export default SidepanelDocumentCreate
