import type { GetAppointmentDefinitionsCombobox } from 'types/graphql'

import { RegisterOptions } from '@redwoodjs/forms'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { ComboboxOption } from 'src/components/atoms/ComboboxField/ComboboxField'
import MultiSelectComboBoxFieldArray from 'src/components/molecules/MultiSelectComboBoxFieldArray/MultiSelectComboBoxFieldArray'
export const QUERY = gql`
  query GetAppointmentDefinitionsCombobox {
    appointmentDefinitions {
      name
      id
      duration
      code
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export interface AppointmentDefinitionComboboxProps {
  value?: string
  name: string
  onChange?: (selectedId: string, selectedOption?: ComboboxOption) => void
  selectFirstAsDefault?: boolean
  validation?: RegisterOptions
}

export const Success = ({
  appointmentDefinitions,
  name,
  validation,
  ...rest
}: CellSuccessProps<GetAppointmentDefinitionsCombobox> &
  AppointmentDefinitionComboboxProps) => {
  const options = appointmentDefinitions.map(({ id, name, duration }) => {
    return {
      value: id,
      name: `${name} - ${duration}min`,
    }
  })
  return (
    <MultiSelectComboBoxFieldArray
      testId="appointmentDefinitionCombobx"
      name={name}
      options={options}
      addSelectionText="Add visit type"
      validation={validation}
      {...rest}
    />
  )
}
