import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { hasAccessToPortalForPatient } from 'common/patientPortalAuth'
import { formatDisplayName } from 'common/utils'
import {
  GetPatientForRecipientOptions,
  GetPatientForRecipientOptionsVariables,
} from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'
import { routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { FamilyMessageSubjectSelect } from 'src/components/atoms/FamilyMessageSubjectSelect/FamilyMessageSubjectSelect'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import { FormInputList } from 'src/components/molecules/FormInputList'
import { PatientDocumentsTypeaheadField } from 'src/components/molecules/PatientDocumentsTypeaheadField/PatientDocumentsTypeaheadField'
import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'
import EnhancedPatientSearch from 'src/components/Patient/EnhancedPatientSearch/EnhancedPatientSearch'
import { guardianshipTypeDisplay } from 'src/data/guardianshipTypes'
import { HOST_URL } from 'src/lib/constants'
import { PATIENT_STATUS_FRAGMENT } from 'src/pages/PatientChartsPage/PatientDemographics/fragments'

const QUERY = gql`
  query GetPatientForRecipientOptions($patientId: String!) {
    patient(id: $patientId) {
      id
      mrn
      ...PatientStatusFragment
      birthDate
      givenName
      familyName
      portalUserStatus
      patientRelatedPersonRelationships {
        id
        relationshipType
        guardianshipType
        isGuarantor
        relatedPersonPortalAccessOverride
        relatedPerson {
          id
          givenName
          familyName
          portalUserStatus
        }
      }
    }
  }
  ${PATIENT_STATUS_FRAGMENT}
`

export const FamilyMessageCaseFormFields = ({
  defaultPatientId,
}: {
  defaultPatientId?: string
}) => {
  const formMethods = useFormContext()

  const patient = formMethods.watch('patient')

  const { data, loading } = useQuery<
    GetPatientForRecipientOptions,
    GetPatientForRecipientOptionsVariables
  >(QUERY, {
    variables: {
      patientId: patient?.id,
    },
    skip: !patient?.id,
  })

  const allRelationships = data?.patient
    ? [
        {
          patient: data.patient,
          relationshipType: 'SELF' as const,
          person: data.patient,
        },
        ...(data.patient.patientRelatedPersonRelationships ?? []).map(
          (relationship) => ({
            ...relationship,
            person: relationship.relatedPerson,
            patient: data.patient,
          })
        ),
      ]
    : []

  const validRelationships = allRelationships.filter(
    (relationship) =>
      relationship.person.portalUserStatus === 'ACTIVE' &&
      hasAccessToPortalForPatient({
        user: {
          relationships: [relationship],
        },
        patientId: patient?.id,
        feature: 'MESSAGES',
      })
  )

  return (
    <FormInputList
      items={[
        {
          name: 'patient',
          formInputComponent: EnhancedPatientSearch,
          label: 'Patient',
          required: true,
          inputProps: {
            placeholder: '',
            defaultPatientId,
          },
        },
        ...(patient
          ? [
              {
                name: 'assignToPortalUserId',
                direction: 'col' as const,
                formInputComponent: MultiRadioButtonField,
                label: 'Recipient',
                subtitle:
                  'The following recipients have family portal access to the patient selected.',
                required: true,
                alert:
                  !loading && !validRelationships.length
                    ? {
                        style: 'danger' as const,
                        title:
                          'There are no active or eligible family portal accounts associated with this patient. Please resolve the error before trying to send a message.',
                        link: {
                          text: 'Resolve error',
                          to: `${HOST_URL}${routes.familyPortalAdmin({
                            userType: 'patient',
                            query: data.patient?.mrn,
                          })}`,
                          target: '_blank',
                          rel: 'noopener noreferrer',
                        },
                      }
                    : null,
                inputProps: {
                  radioStyle: 'card' as const,
                  radioPosition: 'right' as const,
                  values: validRelationships.map((relationship) => ({
                    value:
                      relationship.relationshipType === 'SELF'
                        ? 'patient'
                        : relationship.relatedPerson.id,
                    label: formatDisplayName(relationship.person),
                    description:
                      relationship.relationshipType === 'SELF'
                        ? 'Patient'
                        : [
                            guardianshipTypeDisplay[
                              relationship.guardianshipType
                            ],
                            relationship.isGuarantor ? 'Guarantor' : null,
                            relationshipTypeDisplay[
                              relationship.relationshipType
                            ],
                          ]
                            .filter(Boolean)
                            .join(' • '),
                  })),
                },
              },
              {
                name: 'subject',
                formInputComponent: FamilyMessageSubjectSelect,
                label: 'Subject',
                required: true,
              },
              {
                name: 'message',
                formInputComponent: TextAreaField,
                label: 'Message',
                required: true,
              },
              {
                name: 'documentReferenceId',
                formInputComponent: PatientDocumentsTypeaheadField,
                label: 'Attachment',
                required: false,
                inputProps: {
                  patientId: patient?.id,
                },
              },
            ]
          : []),
      ]}
    />
  )
}
