import { useMemo } from 'react'

import { getDefaultDiagnosisByCvxCode } from 'common/cdr/concepts/immunizations/CVXDetails'
import { match } from 'ts-pattern'
import { BillingCode, OrderCategory, OrderType } from 'types/graphql'

import { UseFormReturn } from '@redwoodjs/forms'

import { useOrderTypes } from 'src/hooks/useOrderTypes/useOrderTypes'
import { useUpdateEffect } from 'src/utils'

import { ComboboxOption } from '../atoms/ComboboxField'

export const useGetOrderType = (category: OrderCategory, code: string) => {
  const { data: orderTypes } = useOrderTypes(category)
  return orderTypes?.orderTypes?.find((option) => option.code === code)
}

export const useOrderTypeOptions = (
  category: OrderCategory
): ComboboxOption[] => {
  const { data: orderTypes } = useOrderTypes(category)
  return useMemo(() => {
    if (!orderTypes) return []
    return orderTypes.orderTypes.map((orderType) => ({
      value: orderType.code,
      name: orderType.display,
      synonyms: orderType.synonyms,
    }))
  }, [orderTypes])
}

export const getOrderTypeDefaults = (
  orderType: Pick<OrderType, 'defaultICD'> & {
    defaultCPT?: Pick<BillingCode, 'id' | 'code' | 'description'>
  },
  formMethods
) => {
  if (!orderType) return
  const { defaultICD, defaultCPT } = orderType

  if (defaultICD) {
    formMethods.setValue('orderInput.diagnosisCodes', [
      {
        code: {
          // id: defaultICD.id,
          code: defaultICD.code,
          description: defaultICD.description,
        },
      },
    ])
  }
  if (defaultCPT) {
    // TODO: fix naming. This is really a billing code. Not just CPT.
    formMethods.setValue('orderInput.cptCode', defaultCPT)
  }
}

export const useSetDefaultOrderDiagnosesAndBillingCodes = <FieldValues>({
  category,
  code,
  formMethods,
}: {
  category: OrderCategory
  code: string
  formMethods: UseFormReturn<FieldValues>
}) => {
  const orderType = useGetOrderType(category, code)
  const orderTypeToUse = match(category)
    .with('VAX', () => {
      // Unlike the other order types, `code` for vaccine orders are CVX. Not CPT.
      const cvxCode = code
      return {
        defaultICD: getDefaultDiagnosisByCvxCode(cvxCode),
      }
    })
    .otherwise(() => orderType)
  useUpdateEffect(() => {
    getOrderTypeDefaults(orderTypeToUse, formMethods)
  }, [category, orderTypeToUse, formMethods])
}
