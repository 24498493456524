import { useEffect, useState } from 'react'

import { useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import { DropdownField } from 'src/components/atoms/Dropdown/Dropdown'
import InputField from 'src/components/atoms/InputField'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { FormInputList } from 'src/components/molecules/FormInputList'
import { RadioButtonCardTableField } from 'src/components/molecules/RadioButton'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import { fundingSourceDescriptionMap } from './AddInventoryTable'

const GET_VACCINE_INVENTORY_BY_ID_QUERY = gql`
  query GetVaccineInventoryById($vaccineInventoryId: String!) {
    getVaccineInventoryById(vaccineInventoryId: $vaccineInventoryId) {
      id
      lotNumber
      expirationDate
      status
      comment
      ndcSale
      ndcDetails {
        brandName
        cvxCodeAndDescription
      }
      vaccineInventoryLocations {
        id
        location {
          id
          name
        }
        fundingSource
        currentDoses
      }
    }
  }
`

const TRANSFER_VACCINE_INVENTORY = gql`
  mutation TransferVaccineInventory($input: TransferVaccineInventoryInput!) {
    transferVaccineInventory(input: $input) {
      id
      lotNumber
      expirationDate
      status
      comment
      ndcSale
      ndcDetails {
        brandName
        cvxCodeAndDescription
      }
      vaccineInventoryLocations {
        id
        location {
          id
          name
        }
        fundingSource
        currentDoses
      }
    }
  }
`

const LOCATION_QUERY = gql`
  query GetLocationsForPractice {
    locations {
      id
      name
    }
  }
`

const SidepanelVaccineTransferInventory = () => {
  const {
    closeSidePanel,
    sidepanelContext: { vaccineInventoryId },
  } = useSidepanel()

  const [toOptions, setToOptions] = useState([])
  const [allowAddingNewOptions, setAllowAddingNewOptions] = useState(true)

  const {
    data: getVaccineInventoryByIdData,
    loading: getVaccineInventoryByIdLoading,
  } = useQuery(GET_VACCINE_INVENTORY_BY_ID_QUERY, {
    variables: {
      vaccineInventoryId,
    },
  })

  const { data: locationData, loading: locationLoading } =
    useQuery(LOCATION_QUERY)

  const [transferVaccineInventory, { loading: transferingVaccineInventory }] =
    useMutation(TRANSFER_VACCINE_INVENTORY, {
      onCompleted: () => {
        toast.success(`Inventory transferred`)
        closeSidePanel()
      },
      refetchQueries: ['GetVaccineInventory'],
    })

  const inventoryDetails = getVaccineInventoryByIdData?.getVaccineInventoryById

  const formMethods = useForm()
  const from = formMethods.watch('from')

  useEffect(() => {
    formMethods.setValue('to', null)
  }, [from, formMethods])

  useEffect(() => {
    setToOptions(
      inventoryDetails?.vaccineInventoryLocations
        ?.map((row) => {
          return {
            ...row,
            value: row.id,
          }
        })
        ?.filter((row) => row.value !== from)
    )
    setAllowAddingNewOptions(true)
  }, [from, inventoryDetails])

  const onSubmit = (data) => {
    void transferVaccineInventory({
      variables: {
        input: {
          to: data.to,
          from: data.from,
          doses: data.doses,
          newOption: {
            locationId: data.newOptionLocation,
            fundingSource: data.newOptionFundingSource,
          },
        },
      },
    })
    closeSidePanel()
  }

  const fromOptions =
    inventoryDetails?.vaccineInventoryLocations?.map((row) => {
      return {
        name: `${row.location.name} - ${
          fundingSourceDescriptionMap[row.fundingSource]
        }`,
        value: row.id,
      }
    }) || []

  const numFromDoses = inventoryDetails?.vaccineInventoryLocations?.find(
    (row) => row.id === from
  )?.currentDoses

  const newOptionLocation = formMethods.watch('newOptionLocation')
  const newOptionFundingSource = formMethods.watch('newOptionFundingSource')

  const onStartToAddOption = () => {
    formMethods.setValue('newOptionLocation', null)
    formMethods.setValue('newOptionFundingSource', null)
  }

  const onSaveAddingOption = () => {
    setToOptions([
      ...toOptions,
      {
        value: `${newOptionLocation}__${newOptionFundingSource}`,
        fundingSource: newOptionFundingSource,
        location: locationData.locations.find(
          (l) => l.id === newOptionLocation
        ),
        currentDoses: 0,
      },
    ])
    setAllowAddingNewOptions(false)

    // Set value to the newly created option
    formMethods.setValue(
      'to',
      `${newOptionLocation}__${newOptionFundingSource}`
    )
  }

  const fundingSources = Object.keys(fundingSourceDescriptionMap).map((key) => {
    return { name: fundingSourceDescriptionMap[key], value: key }
  })

  return (
    <SidepanelPage
      header="Transfer Vaccine"
      loading={getVaccineInventoryByIdLoading || locationLoading}
    >
      <SidepanelForm
        footerProps={{
          cancelText: 'Discard changes',
          submitText: 'Transfer',
          submitting: transferingVaccineInventory,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
        data-testid="transfer-inventory-form"
      >
        {inventoryDetails && (
          <StackView className="mt-3">
            <Typography textStyle="title" fontWeight="bold">
              {inventoryDetails.ndcDetails.brandName} (
              {inventoryDetails.lotNumber})
            </Typography>

            <FormInputList
              leftColumnWidth="sm"
              items={[
                {
                  name: 'from',
                  label: 'From',
                  required: true,
                  formInputComponent: DropdownField,
                  inputProps: {
                    options: fromOptions,
                  },
                },
                {
                  name: 'doses',
                  label: 'Doses',
                  required: true,
                  formInputComponent: InputField,
                  inputProps: {
                    type: 'number',
                    validation: {
                      validate: (value) => {
                        return value > numFromDoses
                          ? 'Number of doses is higher than what is currently available'
                          : value < 1
                          ? 'Number of doses must be greater than 0'
                          : null
                      },
                    },
                  },
                  message: numFromDoses
                    ? `${numFromDoses} doses are currently available from the selected
                  vaccine inventory. Enter the number of doses to be transferred.`
                    : '',
                },
                {
                  name: 'to',
                  label: 'To',
                  required: true,
                  formInputComponent: RadioButtonCardTableField,
                  inputProps: {
                    values: toOptions || [],
                    columns: [
                      {
                        id: 'fundingSourceForLocation',
                        heading: 'Location & Funding Source',
                        basis: '8/12',
                        columnRenderer: ({ data }) => (
                          <StackView>
                            <Typography textStyle="subtitle">
                              {data.location.name}
                            </Typography>
                            <Typography textStyle="description">
                              {fundingSourceDescriptionMap[data.fundingSource]}
                            </Typography>
                          </StackView>
                        ),
                      },
                      {
                        id: 'currentDoses',
                        heading: 'Doses',
                        basis: '2/12',
                        columnRenderer: ({ data }) => (
                          <Typography fontWeight="bold">
                            {data.currentDoses}
                          </Typography>
                        ),
                      },
                    ],
                    allowAddingNewOptions: allowAddingNewOptions,
                    NewOptionFormInputList: () => (
                      <FormInputList
                        items={[
                          {
                            name: 'newOptionLocation',
                            label: 'Storage location',
                            required: true,
                            formInputComponent: DropdownField,
                            inputProps: {
                              options: [
                                ...locationData.locations.map((location) => {
                                  return {
                                    name: location.name,
                                    value: location.id,
                                  }
                                }),
                              ],
                            },
                          },
                          {
                            name: 'newOptionFundingSource',
                            label: 'Funding source',
                            required: true,
                            formInputComponent: DropdownField,
                            inputProps: {
                              options: fundingSources.filter((o) => {
                                return toOptions
                                  .filter(
                                    (locationOption) =>
                                      locationOption.location.id ===
                                      newOptionLocation
                                  )
                                  .reduce((acc, curr) => {
                                    if (acc) {
                                      acc = curr.fundingSource
                                        ? !curr.fundingSource.includes(o.value)
                                        : true
                                      return acc
                                    }
                                  }, true)
                              }),
                            },
                          },
                        ]}
                      />
                    ),
                    onStartToAddOption: onStartToAddOption,
                    onSaveAddingOption: onSaveAddingOption,
                    newOptionFormIsValid:
                      newOptionLocation && newOptionFundingSource,
                  },
                },
              ]}
            />
          </StackView>
        )}
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelVaccineTransferInventory
