import { DocumentTypesConfig } from 'common/cdr/concepts/documents'
import type { MedicalRecordType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const medicalRecordTypeDisplay: {
  [key in MedicalRecordType]: string
} = {
  ...DocumentTypesConfig['MEDICAL_RECORD']['subTypes'],
}

export const medicalRecordTypes = nameValueArrayFromObject<MedicalRecordType>(
  medicalRecordTypeDisplay
)
