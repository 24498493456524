import { match } from 'ts-pattern'
import { MessageJobStatus, OutboundTextMessage } from 'types/graphql'

import Badge, { BadgeColor } from 'src/components/atoms/Badge'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { textMessageErrorCodeDisplay } from 'src/data/textMessageErrors'

export const OutboundTextMessageStatusBadge = ({
  outboundTextMessage,
}: {
  outboundTextMessage: Omit<OutboundTextMessage, 'body' | 'resultCode'>
}) => {
  const badgeProps = match<
    MessageJobStatus,
    { color: BadgeColor; text: string }
  >(outboundTextMessage.status)
    .with('SUCCEEDED', () => ({
      color: 'green',
      text: 'Delivered',
    }))
    .with('FAILED', () => ({
      color: 'red',
      text: 'Failed',
    }))
    .with('PENDING', 'PROCESSING', 'SUBMITTED_TO_TWILIO', () => ({
      color: 'dark-gray',
      text: 'Pending',
    }))
    .exhaustive()

  return (
    <StackView>
      <Badge showDot {...badgeProps} className="w-fit" />
      {outboundTextMessage.errorCode &&
        outboundTextMessage.status === 'FAILED' && (
          <span>
            <Typography textStyle="interface-default-xs">
              {
                textMessageErrorCodeDisplay[outboundTextMessage.errorCode]
                  .shortDescripton
              }
              :{' '}
            </Typography>
            <Typography
              textStyle="interface-default-xs"
              color="text-base-color-fg-subtle"
            >
              {
                textMessageErrorCodeDisplay[outboundTextMessage.errorCode]
                  .details
              }
            </Typography>
          </span>
        )}
    </StackView>
  )
}
