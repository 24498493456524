import {
  BillingCode,
  CreateOrderInput,
  DiagnosisCode,
  OrderCategory,
  OrderFragment,
  UpdateOrderInput,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'

import { useEmrAuth } from 'src/auth'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import {
  useCreateOrderMutation,
  useUpdateOrderMutation,
} from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import Button, { Submit } from '../atoms/Button'
import StackView from '../atoms/StackView'
import FormInputList from '../molecules/FormInputList'

import buildDefaultValues from './buildDefaultValues'
import buildFormInputList, {
  FormType,
  ProviderPreference,
} from './buildFormInputList'
import buildSubmitInput from './buildSubmitInput'
import {
  useOrderTypeOptions,
  useSetDefaultOrderDiagnosesAndBillingCodes,
} from './useOrderTypes'

export type OrderSidepanelFormFormType = (
  | CreateOrderInput
  | UpdateOrderInput
) & {
  orderInput: {
    diagnosisCodes: { code: DiagnosisCode }[]
    cptCode: { code: Omit<BillingCode, 'chargeItems' | 'group'> }
    providerPreference?: ProviderPreference
  }
}
type Props = {
  patientId?: string
  category: Exclude<OrderCategory, 'HG_LAB'>
  order?: OrderFragment
  encounterId?: string
}

const OrderSidepanelForm = ({
  patientId,
  category,
  order,
  encounterId,
}: Props) => {
  const { currentUser } = useEmrAuth()
  const { closeSidePanel } = useSidepanel()
  const [createOrder, { loading: creatingOrder }] = useCreateOrderMutation()
  const [updateOrder, { loading: updatingOrder }] = useUpdateOrderMutation()

  const orderTypeOptions = useOrderTypeOptions(category)

  const formMethods = useForm<FormType>({
    defaultValues: buildDefaultValues({
      order,
      category,
    }),
  })

  const providerPreference = formMethods.watch('orderInput.providerPreference')
  const code = formMethods.watch('orderInput.code')
  const appointmentDefinitionIds = formMethods.watch(
    'schedulingOrderInput.appointmentDefinitionIds'
  )
  useSetDefaultOrderDiagnosesAndBillingCodes({
    category,
    code,
    formMethods,
  })

  const inputListItems = buildFormInputList({
    category,
    code,
    providerPreference,
    orderTypeOptions,
    patientId,
    encounterId,
    appointmentDefinitionIds,
    isEdit: !!order,
    formMethods,
  })

  const onSubmit = async (
    { orderInput, schedulingOrderInput }: FormType,
    { shouldCloseSidepanel }: { shouldCloseSidepanel: boolean }
  ) => {
    const input = buildSubmitInput({
      order,
      category,
      encounterId,
      patientId,
      orderInput,
      schedulingOrderInput,
      currentUser,
    })

    // when order is defined, we are updating an existing order
    if (order) {
      void updateOrder({
        variables: { input },
        onCompleted: closeSidePanel,
        refetchQueries: ['FindPatientVisit'],
      })
    } else {
      void createOrder({
        variables: { input },
        // if not closing sidepanel, reset the form so a new order can be created
        onCompleted: shouldCloseSidepanel
          ? closeSidePanel
          : () => {
              formMethods.reset(buildDefaultValues({ order, category }))
              formMethods.setValue('orderInput.code', null)
            },
        refetchQueries: ['FindPatientVisit'],
      })
    }
  }

  return (
    <SidepanelForm
      footerElement={
        <SidepanelFooter
          onCancel={() => closeSidePanel()}
          onAnother={
            !order
              ? () =>
                  onSubmit(formMethods.getValues(), {
                    shouldCloseSidepanel: false,
                  })
              : undefined
          }
          disabled={creatingOrder || updatingOrder}
          submitting={creatingOrder || updatingOrder}
          currentUser={currentUser}
        />
      }
      onSubmit={(data) => onSubmit(data, { shouldCloseSidepanel: true })}
      formMethods={formMethods}
    >
      <FormInputList items={inputListItems} />
    </SidepanelForm>
  )
}

export default OrderSidepanelForm

const SidepanelFooter = ({
  onCancel,
  onAnother,
  disabled,
  submitting,
  currentUser,
}) => {
  return (
    <StackView
      space={50}
      direction="row"
      justifyContent="between"
      data-testid="sidepanel-button-footer"
    >
      <Button
        data-testid="sidepanel-cancel-btn"
        buttonStyle="ghost"
        onClick={onCancel}
      >
        Cancel
      </Button>

      <StackView space={50} direction="row" fullWidth={false}>
        {onAnother && (
          <Button
            data-testid="another-order-btn"
            buttonStyle="secondary"
            onClick={onAnother}
          >
            {currentUser.roles.includes('PRACTITIONER')
              ? 'Sign and next order'
              : 'Pend and next order'}
          </Button>
        )}

        <Submit
          data-testid="button-footer-submit-btn"
          buttonStyle="primary"
          disabled={disabled}
          loading={submitting}
        >
          {currentUser.roles.includes('PRACTITIONER')
            ? 'Sign and close'
            : 'Pend and close'}
        </Submit>
      </StackView>
    </StackView>
  )
}
