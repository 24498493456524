import { useEffect, useState } from 'react'

import { convertParagraphToLexical } from 'common/lexical/lexical'

import { lexicalToHTML } from '../utils/utils'

export const useGetLexicalHtmlString = (lexical) => {
  const [string, setString] = useState('')
  useEffect(() => {
    async function updateLexicalHtml() {
      setString(await lexicalToHTML(convertParagraphToLexical(lexical)))
    }
    void updateLexicalHtml()
  }, [lexical])

  return string
}

export const useGetLexicalHtml = (lexical) => {
  const string = useGetLexicalHtmlString(lexical)
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: string,
      }}
    ></div>
  )
}
