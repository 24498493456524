import { Serie } from '@nivo/line'
import {
  growthChartObservations,
  lengthCodes,
  weightCodes,
  headCircumferenceCodes,
} from 'common/cdr/concepts/observations/growthCharts/index'
import { format } from 'date-fns'

import { useQuery } from '@redwoodjs/web'

import { calculateMonthsSinceBirth } from 'src/lib/formatters'
import {
  PATIENT_DETAILS_FRAGMENT,
  TENANT_LETTERHEAD_FRAGMENT,
} from 'src/pdf/fragments'

import { PATIENT_RELATED_PERSON_RELATIONSHIPS_FRAGMENT } from '../PatientVisits/useVisit'

import { GrowthChartDatasetType } from './PatientGrowthCharts'

export type TransformedGrowthChartDatasetsType = {
  [key: string]: Serie[]
  patient?: Serie[]
}

const GROWTH_CHART_FRAGMENT = gql`
  fragment GrowthChartFragment on GrowthCharts {
    infantWeightForAge {
      Month
      P2
      P5
      P10
      P25
      P50
      P75
      P90
      P95
      P98
    }
    infantLengthForAge {
      Month
      P2
      P5
      P10
      P25
      P50
      P75
      P90
      P95
      P98
    }
    infantHeadCircumferenceForAge {
      Month
      P2
      P5
      P10
      P25
      P50
      P75
      P90
      P95
      P98
    }
    infantWeightForLength {
      Length
      P2
      P5
      P10
      P25
      P50
      P75
      P90
      P95
      P98
    }
    weightForAge {
      Month
      P3
      P5
      P10
      P25
      P50
      P75
      P90
      P95
      P97
    }
    statureForAge {
      Month
      P3
      P5
      P10
      P25
      P50
      P75
      P90
      P95
      P97
    }
    bmiForAge {
      Month
      L
      M
      S
      P3
      P5
      P10
      P25
      P50
      P75
      P85
      P90
      P95
      P97
    }
    weightForStature {
      Stature
      P3
      P5
      P10
      P25
      P50
      P75
      P85
      P90
      P95
      P97
    }
    patient {
      id
      ...PatientDetailsFragment
      ...PatientRelatedPersonRelationshipsFragment
      lastUsedGrowthChartDataset
      vitals: vitalsForGrowthCharts {
        id
        name
        value
        imperial
        effectiveAt
        updatedAt
        percentile
        code {
          code
          system
          display
        }
      }
      vitalsForCorrectedAge {
        id
        name
        value
        imperial
        effectiveAt
        updatedAt
        percentile
        code {
          code
          system
          display
        }
      }
      vitalsForWeightAndHeight {
        height
        weight
        heightImperial
        weightImperial
        effectiveAt
        percentile
      }
      primaryProvider {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
      }
      tenant {
        ...TenantLetterheadFragment
      }
    }
    gestationalAgeAtBirthInWeeks
  }
  ${TENANT_LETTERHEAD_FRAGMENT}
  ${PATIENT_DETAILS_FRAGMENT}
  ${PATIENT_RELATED_PERSON_RELATIONSHIPS_FRAGMENT}
`

const GROWTH_CHART_QUERY = gql`
  query GetGrowthChartData($input: GrowthChartInput!) {
    growthChartData(input: $input) {
      ...GrowthChartFragment
    }
  }
  ${GROWTH_CHART_FRAGMENT}
`

const PATIENT_PORTAL_GROWTH_CHART_QUERY = gql`
  query GetGrowthChartDataForPatientPortal($input: GrowthChartInput!) {
    patientPortalGrowthChartData(input: $input) {
      ...GrowthChartFragment
    }
  }
  ${GROWTH_CHART_FRAGMENT}
`

export const useGrowthChartQuery = ({
  patientId,
  growthChartDatasetType,
  isPatientPortal = false,
  skip = false,
}: {
  patientId: string
  growthChartDatasetType?: GrowthChartDatasetType
  isPatientPortal?: boolean
  skip?: boolean
}) => {
  const dataKey = isPatientPortal
    ? 'patientPortalGrowthChartData'
    : 'growthChartData'
  const { data, loading, error } = useQuery(
    isPatientPortal ? PATIENT_PORTAL_GROWTH_CHART_QUERY : GROWTH_CHART_QUERY,
    {
      variables: {
        input: {
          patientId: patientId,
          growthChartDatasetType: growthChartDatasetType,
          codes: [
            growthChartObservations['BODY_WEIGHT'].code,
            growthChartObservations['BODY_HEIGHT'].code,
            growthChartObservations['HEAD_CIRCUMFERENCE'].code,
            growthChartObservations['WEIGHT_FOR_LENGTH'].code,
            growthChartObservations['BODY_MASS_INDEX'].code,
            growthChartObservations['GESTATIONAL_AGE_AT_BIRTH'].code,
            growthChartObservations['BIRTH_WEIGHT'].code,
            growthChartObservations['BIRTH_LENGTH'].code,
            growthChartObservations['BIRTH_HEAD_CIRCUMFERENCE'].code,
          ],
        },
      },
      skip,
    }
  )

  const patient = data?.[dataKey].patient

  const growthChartDatasets = data?.[dataKey]

  const gestationalAgeAtBirthInWeeks =
    data?.[dataKey].gestationalAgeAtBirthInWeeks

  let transformedGrowthChartDatasets = transformChartData(growthChartDatasets)

  let todayLineX, showGestationalAgeAtBirth, weeksPremature
  if (patient) {
    const currentAgeInMonths = calculateMonthsSinceBirth(
      patient.birthDate,
      format(new Date(), 'yyyy-MM-dd')
    )

    todayLineX = currentAgeInMonths

    if (
      gestationalAgeAtBirthInWeeks &&
      gestationalAgeAtBirthInWeeks < 37 &&
      patient.lastUsedGrowthChartDataset.includes('WHO')
    ) {
      showGestationalAgeAtBirth = true
      weeksPremature = 40 - gestationalAgeAtBirthInWeeks
    }

    transformedGrowthChartDatasets = addPatientDataToChart(
      transformedGrowthChartDatasets,
      patient,
      showGestationalAgeAtBirth,
      weeksPremature
    )
  }

  return {
    growthChartDatasets,
    transformedGrowthChartDatasets,
    patient,
    todayLineX,
    gestationalAgeAtBirthInWeeks,
    showGestationalAgeAtBirth,
    weeksPremature,
    queriedDataset: patient?.lastUsedGrowthChartDataset,
    data,
    loading,
    error,
  }
}

const chartKeys = [
  'infantWeightForAge',
  'infantLengthForAge',
  'infantHeadCircumferenceForAge',
  'infantWeightForLength',
  'weightForAge',
  'statureForAge',
  'bmiForAge',
  'weightForStature',
]

const transformChartData = (chartData) => {
  if (chartData) {
    // Initialize our object structure
    const res = {
      infantWeightForAge: [],
      infantLengthForAge: [],
      infantHeadCircumferenceForAge: [],
      infantWeightForLength: [],
      weightForAge: [],
      statureForAge: [],
      bmiForAge: [],
      weightForStature: [],
    }

    // Loop through each of the chart keys
    chartKeys.forEach((k) => {
      if (k !== 'patient') {
        // Get the actual data for each chart
        const data = chartData[k]
        // If there is data for the chart, we'll use it
        if (data) {
          // Loop through each row of data, structured as below
          // {
          //   Month: 24,
          //   P3: 14.14735,
          //   P5: 14.39787,
          //   P10: 14.80134,
          //   P25: 15.52808,
          //   P50: 16.4234,
          //   P75: 17.42746,
          //   P85: 18.01821,
          //   P90: 18.44139,
          //   P95: 19.10624,
          //   P97: 19.56411,
          // },
          data.forEach((row) => {
            // Initialize an array that will get pushed to result object
            const array = []
            // Get the data label
            Object.keys(row).forEach((label) => {
              // We only care about some data, the rest is dead to us right now
              if (
                label === 'P2' ||
                label === 'P3' ||
                label === 'P5' ||
                label === 'P10' ||
                label === 'P25' ||
                label === 'P50' ||
                label === 'P75' ||
                label === 'P85' ||
                label === 'P90' ||
                label === 'P95' ||
                label === 'P97' ||
                label === 'P98'
              ) {
                // Each label will have it's own data
                array.push({ id: label, data: [] })
              }
            })
            // Add the arraw we just built to the result
            res[k] = array
          })
        } else {
          // Otherwise, set that key to null on the result object
          res[k] = null
        }
      }
    })

    // Now the result has data that looks like:
    // {
    //   ...
    //   headCircumferenceForAge: [
    //     {
    //       id: 'P2',
    //       data: []
    //     }
    //     ...
    //   ]
    //   ...
    // }

    // Loop over each key in the result
    Object.keys(res).forEach((k) => {
      // If there is data, we'll use it
      if (res[k]) {
        // Loop over each set of data, which looks like:
        // {
        //   id: 'P2',
        //   data: []
        // }
        res[k].forEach((l, index) => {
          // Get all of the actual values for this label
          const values = chartData[k].map((x) => x[l.id])

          // Loop through each of the values
          values.forEach((value) => {
            // We are statically setting the x axis key. Every chart is using Month, except weightForLength
            let xkey
            if (k === 'weightForLength' || k === 'infantWeightForLength') {
              xkey = 'Length'
            } else if (k === 'weightForStature') {
              xkey = 'Stature'
            } else {
              xkey = 'Month'
            }

            // Push our data into res, in the shape that we prefer
            res[k][index].data.push({
              x: chartData[k].find((x) => x[l.id] === value)[xkey],
              y: value,
            })
          })
        })
      }
    })

    // Now that we have all the data, let's sort it
    // Loop over each key in the result
    Object.keys(res).forEach((k) => {
      if (res[k]) {
        res[k].forEach((l, i) => {
          // Set the object data equal to the sorted data
          res[k][i].data = l.data.sort((a, b) => {
            return a.x - b.x
          })
        })
      }
    })

    return res as TransformedGrowthChartDatasetsType
  }

  return null
}

const addPatientDataToChart = (
  chartData,
  patient,
  showGestationalAgeAtBirth,
  weeksPremature
) => {
  if (chartData && patient) {
    const chartAndCodeMapper = [
      {
        chart: 'infantWeightForAge',
        codes: weightCodes,
      },
      {
        chart: 'infantLengthForAge',
        codes: lengthCodes,
      },
      {
        chart: 'infantHeadCircumferenceForAge',
        codes: headCircumferenceCodes,
      },
      {
        chart: 'infantWeightForLength',
        codes: [growthChartObservations['WEIGHT_FOR_LENGTH'].code],
      },
      {
        chart: 'weightForAge',
        codes: weightCodes,
      },
      {
        chart: 'statureForAge',
        codes: [growthChartObservations['BODY_HEIGHT'].code],
      },
      {
        chart: 'bmiForAge',
        codes: [growthChartObservations['BODY_MASS_INDEX'].code],
      },
      {
        chart: 'weightForStature',
        codes: [growthChartObservations['WEIGHT_FOR_LENGTH'].code],
      },
    ]
    // Add patient data to charts
    chartAndCodeMapper.forEach((c) => {
      if (
        chartData[c.chart] &&
        !chartData[c.chart].some((x) => x.id === 'patient')
      ) {
        chartData[c.chart].push(
          transformPatientDataToAddToChart(patient, c.codes)
        )
      }

      if (
        showGestationalAgeAtBirth &&
        weeksPremature &&
        chartData[c.chart] &&
        !chartData[c.chart].some((x) => x.id === 'correctedPatient')
      ) {
        chartData[c.chart].push(
          transformCorrectedPatientDataToAddToChart(patient, c.codes)
        )
      }
    })
  }
  return chartData as TransformedGrowthChartDatasetsType
}

// When we add data to our D3 Series, we need to make sure that it conforms to the following structure:
// {
//   ...
//   headCircumferenceForAge: [
//     {
//       id: 'P2',
//       data: [
//         {
//           x: [float],
//           y: [float],
//         }
//         ...
//       ]
//     }
//     ...
//   ]
//   ...
// }
const transformPatientDataToAddToChart = (patient, keys: string[]) => {
  let data
  if (keys.includes(growthChartObservations['WEIGHT_FOR_LENGTH'].code)) {
    data = transformWeightForLengthData(patient.vitalsForWeightAndHeight)
  } else {
    data = patient.vitals
      .filter((d) => keys.includes(d.code.code))
      .map((d) => {
        return {
          ...d,
          x: calculateMonthsSinceBirth(patient.birthDate, d.effectiveAt),

          y: parseFloat(d.value.value),
        }
      })
  }

  data = data?.sort((a, b) => Number(a.x) - Number(b.x))

  return {
    id: 'patient',
    data: [...new Set(data)],
  }
}

const transformCorrectedPatientDataToAddToChart = (patient, key) => {
  let data
  if (
    key !== growthChartObservations['WEIGHT_FOR_LENGTH'].code &&
    patient.vitalsForCorrectedAge
  ) {
    data = patient.vitalsForCorrectedAge
      .filter((d) => key.includes(d.code.code))
      .map((d) => {
        return {
          ...d,
          x: calculateMonthsSinceBirth(patient.birthDate, d.effectiveAt),
          y: parseFloat(d.value.value),
        }
      })
      .sort((a, b) => Number(a.x) - Number(b.x))
  }

  return {
    id: 'correctedPatient',
    data: [...new Set(data)],
  }
}

const transformWeightForLengthData = (vitals) => {
  return vitals.map((v) => {
    return {
      ...v,
      x: v.height.value,
      y: v.weight.value,
    }
  })
}
