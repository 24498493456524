import { omit } from 'lodash'
import { isEmpty } from 'lodash'
import type { EditUserById, UpdateUserInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import type { RWGqlError } from '@redwoodjs/forms'

import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import UserFormInputList from 'src/components/User/UserFormInputList/UserFormInputList'

interface UserFormProps {
  user: EditUserById['user']
  onSave: (data: UpdateUserInput, id: EditUserById['user']['id']) => void
  error: RWGqlError
  loading: boolean
}

type UpdateUserFormData = Omit<UpdateUserInput, 'locationIds'> & {
  additionalLocationIds: string[]
}

const UserForm = (props: UserFormProps) => {
  const formMethods = useForm<UpdateUserFormData>({
    defaultValues: {
      email: props.user.email,
      givenName: props.user.givenName,
      familyName: props.user.familyName,
      primaryLocationId: props.user.primaryLocationId,
      credentialsLabel: props.user.credentialsLabel,
      additionalLocationIds: props.user.locations
        .map((l) => l.id)
        .filter((id) => id !== props.user.primaryLocationId),
      userTypeId: props.user.userTypeId,
    },
  })
  const {
    formState: { dirtyFields, isDirty },
  } = formMethods
  const onSubmit = (data: UpdateUserFormData) => {
    const dirtyValues = Object.fromEntries(
      Object.keys(dirtyFields).map((fieldName) => [fieldName, data[fieldName]])
    )
    const toSubmit: UpdateUserInput = omit(dirtyValues, 'additionalLocationIds')
    if (
      'additionalLocationIds' in dirtyValues ||
      'primaryLocationId' in dirtyValues
    ) {
      toSubmit.locationIds = [
        data.primaryLocationId,
        ...data.additionalLocationIds.filter(
          (id) => id !== data.primaryLocationId
        ),
      ]
    }
    if (!isEmpty(toSubmit)) {
      props.onSave(toSubmit, props.user.id)
    }
  }

  return (
    <SidepanelForm
      footerProps={{
        disabled: !isDirty,
        submitText: 'Update',
        submitting: props.loading,
      }}
      formMethods={formMethods}
      onSubmit={onSubmit}
      error={props.error}
    >
      <UserFormInputList />
    </SidepanelForm>
  )
}

export default UserForm
