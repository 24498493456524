import { patientDocumentTypes } from 'src/data/patientDocumentTypes'

import { DropdownField, DropdownFieldProps } from '../Dropdown/Dropdown'

export const PatientDocumentTypeSelectField = (props: DropdownFieldProps) => {
  return (
    <DropdownField
      {...props}
      options={patientDocumentTypes.map(({ name, value }) => ({
        value,
        name,
      }))}
    />
  )
}

export default PatientDocumentTypeSelectField
