import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import { Icons, User } from 'types/graphql'

import Box from 'src/components/atoms/Box/Box'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { iconsStatusDisplay } from 'src/data/statusUpdateIcons'

interface StatusUpdateItemProps {
  createdBy: Partial<User>
  createdAt: string
  text: string
  icon: Icons
}

const StatusUpdateItem = ({
  createdBy,
  text,
  createdAt,
  icon = 'BOOK_OPEN',
}: StatusUpdateItemProps) => {
  const Icon = iconsStatusDisplay[icon]
  return (
    <Box horizontalPadding={50} verticalPadding={75}>
      <StackView direction="row" space={75} alignItems="start">
        <Box flex={'1/12'}>
          <Box
            className="flex h-8 w-8 items-center justify-center rounded-full"
            color="bg-base-color-bg-subtle"
          >
            <Icon className="h-5 w-5 text-gray-500" />
          </Box>
        </Box>
        <Box grow>
          <StackView space={25}>
            <StackView
              space={50}
              direction="row"
              alignItems="center"
              justifyContent="between"
            >
              <Typography>Status update</Typography>
              <Typography color="text-base-color-fg-muted" size="s">
                {format(parseISO(createdAt), "MMM d, yyyy 'at' h:mm a")}
              </Typography>
            </StackView>
            <Typography color="text-base-color-fg-muted">{text}</Typography>
            <Typography>{formatDisplayName(createdBy)}</Typography>
          </StackView>
        </Box>
      </StackView>
    </Box>
  )
}

export default StatusUpdateItem
