import { ReactElement, useEffect, useState } from 'react'

import { Switch } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import {
  ControllerRenderProps,
  RegisterOptions,
  useWatch,
  useFormContext,
} from '@redwoodjs/forms'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

interface BaseProps {
  label?: string | ReactElement
  labelPosition?: 'left' | 'right'
  onChange?: (boolean) => void
  id?: string
  disabled?: boolean
  testId?: string
  description?: string
  size?: 's' | 'l'
  stopPropagation?: boolean
}
interface UncontrolledProps extends BaseProps {
  checked?: never
  defaultChecked?: boolean
}
interface ControlledProps extends BaseProps {
  checked?: boolean
  defaultChecked?: never
}

interface ToggleFieldProps extends Omit<ControlledProps, 'onChange'> {
  onChange?: (controllerRender: ControllerRenderProps, value: boolean) => void
  name: string
  validation?: RegisterOptions
  activeText?: string
  inactiveText?: string
  invertSelection?: boolean
}

const switchBackgroundClasses = [
  'cursor-pointer',
  'relative inline-flex flex-shrink-0',
  'rounded-base-border-radius-selectable-pill',
  'border-base-border-width-selectable-s',
  'focus:shadow-base-box-shadow-general-focus-border',
  'transition-colors duration-200 ease-in-out',
]

const switchBackgroundCheckedClasses = [
  'bg-comp-toggle-color-primary-selected-enabled-border',
  'border-comp-toggle-color-primary-selected-enabled-border',
  'hover:bg-comp-toggle-color-primary-selected-hover-border',
  'hover:border-comp-toggle-color-primary-selected-hover-border',
]

const switchBackgroundUncheckedClasses = [
  'bg-comp-toggle-color-primary-unselected-enabled-secondary-bg',
  'border-comp-toggle-color-primary-unselected-enabled-border',
  'hover:bg-comp-toggle-color-primary-unselected-hover-bg',
  'hover:border-comp-toggle-color-primary-unselected-hover-border',
]

const switchBackgroundLargeClasses = ['h-core-size-150 w-core-size-250']
const switchBackgroundSmallClasses = ['h-core-size-100 w-core-size-175']

const switchClasses = [
  'pointer-events-none',
  'relative inline-block transform',
  'rounded-base-border-radius-selectable-pill',
  'bg-base-color-bg-default',
  'shadow-base-box-shadow-selectable-m',
  'transition duration-200 ease-in-out',
  'translate-y-[1px]',
]

const switchUncheckedClasses = ['translate-x-0']

const switchLargeClasses = ['h-core-size-125 w-core-size-125']
const switchSmallClasses = ['h-core-size-75 w-core-size-75']

const switchLargeCheckedClasses = ['translate-x-[18px]']
const switchSmallCheckedClasses = ['translate-x-[14px]']

const switchIconClasses = ['absolute inset-1 h-core-size-75 w-core-size-75']

function Toggle({
  label,
  labelPosition = 'left',
  checked,
  id,
  onChange,
  defaultChecked,
  disabled,
  testId,
  description,
  size = 's',
  stopPropagation,
}: ControlledProps | UncontrolledProps): ReactElement {
  const [enabled, setEnabled] = useState(defaultChecked)
  const isChecked = defaultChecked ? enabled : checked
  return (
    <Switch.Group as="div">
      <StackView
        direction="row"
        className={disabled && 'opacity-base-opacity-disabled'}
        gap={50}
        alignItems="center"
        justifyContent={labelPosition === 'left' ? 'start' : 'end'}
        reverse={labelPosition === 'right'}
        onClick={(e) => stopPropagation && e.stopPropagation()}
      >
        <Switch.Label as="div">
          <StackView>
            {label && (
              <Typography
                textStyle="interface-default-s"
                color="text-comp-toggle-color-primary-unselected-enabled-primary-fg"
              >
                {label}
              </Typography>
            )}
            {description && (
              <Typography
                textStyle="interface-default-xs"
                color="text-comp-toggle-color-primary-unselected-enabled-secondary-fg"
              >
                {description}
              </Typography>
            )}
          </StackView>
        </Switch.Label>
        <Switch
          data-testid={testId}
          itemID={id}
          checked={isChecked}
          disabled={disabled}
          onChange={
            defaultChecked
              ? onChange
              : (e) => {
                  setEnabled(e)
                  onChange(e)
                }
          }
          defaultChecked={defaultChecked}
          className={clsx(
            isChecked
              ? switchBackgroundCheckedClasses
              : switchBackgroundUncheckedClasses,
            disabled && '!pointer-events-none !cursor-default',
            switchBackgroundClasses,
            size === 'l'
              ? switchBackgroundLargeClasses
              : switchBackgroundSmallClasses
          )}
        >
          <div
            aria-hidden="true"
            className={clsx(
              switchClasses,
              size === 'l' ? switchLargeClasses : switchSmallClasses,
              !isChecked && switchUncheckedClasses,
              isChecked &&
                (size === 'l'
                  ? switchLargeCheckedClasses
                  : switchSmallCheckedClasses)
            )}
          >
            {size === 'l' && (
              <>
                {isChecked ? (
                  <CheckIcon
                    className={clsx([
                      ...switchIconClasses,
                      'fill-comp-toggle-color-primary-selected-enabled-border',
                    ])}
                  />
                ) : (
                  <XMarkIcon
                    className={clsx([
                      ...switchIconClasses,
                      'fill-comp-toggle-color-primary-unselected-enabled-border',
                    ])}
                  />
                )}
              </>
            )}
          </div>
        </Switch>
      </StackView>
    </Switch.Group>
  )
}

export const ToggleField = ({
  name,
  validation,
  activeText = 'Active',
  inactiveText = 'Inactive',
  invertSelection = false,
  disabled = false,
}: ToggleFieldProps) => {
  const { register, setValue, unregister } = useFormContext()

  register(name, validation)

  const checked = useWatch({
    name,
  })

  useEffect(() => {
    return () => validation?.shouldUnregister && unregister(name)
  }, [name, unregister, validation?.shouldUnregister])

  const onChange = (checked) => {
    const toSet = invertSelection ? !checked : checked
    setValue(name, toSet)
  }

  return (
    <Toggle
      checked={invertSelection ? !checked : checked}
      onChange={onChange}
      label={checked ? activeText : inactiveText}
      disabled={disabled}
    />
  )
}

export default Toggle
