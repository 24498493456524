import { useCallback, useEffect, useRef, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { formatDisplayName } from 'common/utils'
import {
  GetDefaultPatient,
  SearchPatients,
  SearchPatientsVariables,
} from 'types/graphql'
import { useDebounceCallback } from 'usehooks-ts'

import { RegisterOptions, useFormContext } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button'
import InputField from 'src/components/atoms/InputField/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import RenderPatientSearchResults from 'src/components/molecules/Search/RenderPatientSearchResults'
import { formatDateDisplay } from 'src/lib/formatters'
import { PATIENT_STATUS_FRAGMENT } from 'src/pages/PatientChartsPage/PatientDemographics/fragments'

export interface EnhancedPatientSearchProps {
  onSearchResultClickCallback?: (
    id: string,
    patient: NonNullable<GetDefaultPatient['patient']>
  ) => void
  onSearchButtonClickCallback?: (patientIds: string[]) => void
  onClearSelection?: () => void
  name?: string
  disabled?: boolean
  placeholder?: string
  validation?: RegisterOptions
  defaultPatientId?: string
  showIcon?: boolean
  testId?: string
}

export const ENHANCED_SEARCH_PATIENTS_QUERY = gql`
  query SearchPatients($input: SearchPatientsInput!) {
    searchPatients(input: $input) {
      id
      mrn
      namePrefix
      givenName
      middleName
      familyName
      nameSuffix
      birthDate
      sexAtBirth
      ...PatientStatusFragment
      practiceComment
      contactInformation {
        id
        mobileNumber
        homeAddress {
          id
          line1
          line2
          city
          state
          postalCode
        }
      }
      primaryGuardian {
        id
        contactInformation {
          id
          homeAddress {
            id
            line1
            line2
            city
            state
            postalCode
          }
        }
      }
      patientRelatedPersonRelationships {
        id
        doesResideWith
        relationshipType
        relatedPerson {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
          contactInformation {
            id
            mobileNumber
          }
        }
      }
      openAppointments {
        id
        mostRecentVistFlowTab
        chartingStatus
      }
    }
  }
  ${PATIENT_STATUS_FRAGMENT}
`

export const GET_PATIENT = gql`
  query GetDefaultPatient($id: String!) {
    patient(id: $id) {
      id
      mrn
      namePrefix
      givenName
      middleName
      familyName
      nameSuffix
      birthDate
      sexAtBirth
      active
      practiceComment
      contactInformation {
        id
        mobileNumber
        homeAddress {
          id
          line1
          line2
          city
          state
          postalCode
        }
      }
      primaryGuardian {
        id
        contactInformation {
          id
          homeAddress {
            id
            line1
            line2
            city
            state
            postalCode
          }
        }
      }
      patientRelatedPersonRelationships {
        id
        doesResideWith
        relationshipType
        relatedPerson {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
          contactInformation {
            id
            mobileNumber
          }
        }
      }
      openAppointments {
        id
        mostRecentVistFlowTab
        chartingStatus
      }
    }
  }
`

const MIN_QUERY_LENGTH = 2

const EnhancedPatientSearch = ({
  onSearchResultClickCallback,
  onSearchButtonClickCallback,
  name = 'searchPatientsInput',
  disabled,
  placeholder,
  onClearSelection,
  defaultPatientId,
  showIcon = true,
  testId,
  ...rest
}: EnhancedPatientSearchProps) => {
  const patientHasBeenDefaulted = useRef(false)
  const [selectedPatient, onSelectPatient] =
    useState<GetDefaultPatient['patient']>(undefined)
  const { trigger, setValue, register, unregister } = useFormContext()

  const [query, setQuery] = useState('')

  const [searchPatients, { data, loading }] = useLazyQuery<
    SearchPatients,
    SearchPatientsVariables
  >(ENHANCED_SEARCH_PATIENTS_QUERY)

  const searchResults =
    !loading && query.length >= MIN_QUERY_LENGTH
      ? data?.searchPatients ?? []
      : []

  const searchCallback = useCallback(
    (text: string) => {
      if (text.length < MIN_QUERY_LENGTH) return

      void searchPatients({
        variables: {
          input: {
            searchQuery: text,
          },
        },
      })
    },
    [searchPatients]
  )
  const debouncedSearch = useDebounceCallback(searchCallback, 300)

  const onSearchPatientsType = (event) => {
    const searchQuery = event.target.value
    setQuery(searchQuery)
    trigger(name)
    void debouncedSearch(searchQuery)
  }

  const displayName = `${name}DisplayText`
  const valueName = `${name}Id`

  useEffect(() => {
    register(displayName)
    register(valueName)
    return () => {
      unregister(valueName)
      unregister(displayName)
    }
  }, [displayName, register, unregister, valueName])

  const onSearchResultClick = useCallback(
    (value, patient) => {
      onSelectPatient(patient)
      setValue(name, patient)
      setValue(valueName, patient.id)
      setValue(
        displayName,
        `${formatDisplayName(patient)} - ${formatDateDisplay(
          patient.birthDate
        )}`
      )
      onSearchResultClickCallback && onSearchResultClickCallback(value, patient)
    },
    [
      onSearchResultClickCallback,
      onSelectPatient,
      displayName,
      name,
      valueName,
      setValue,
    ]
  )
  const clearSearch = () => {
    setValue(valueName, undefined)
    setValue(name, undefined)
    onSelectPatient(undefined)
    setValue(displayName, '')
    setQuery('')
  }

  const { data: defaultPatient, loading: loadingDefaultPatient } =
    useQuery<GetDefaultPatient>(GET_PATIENT, {
      skip: !defaultPatientId,
      variables: { id: defaultPatientId },
    })

  useEffect(() => {
    if (!defaultPatient?.patient) return
    if (patientHasBeenDefaulted.current) return

    patientHasBeenDefaulted.current = true

    onSelectPatient(defaultPatient.patient)
    onSearchResultClick(defaultPatient.patient.id, defaultPatient.patient)
  }, [defaultPatient, onSearchResultClick])

  const hasXIcon = (query.length > 0 || selectedPatient) && !disabled

  if (loadingDefaultPatient) return null

  return (
    <Box className="relative">
      <StackView direction="row" space={50}>
        <StackView direction="col">
          <InputField
            autoComplete="off"
            data-testid={testId ?? 'search-patient-input'}
            name={displayName}
            id={name}
            readOnly={!!selectedPatient}
            placeholder={
              typeof placeholder === 'string'
                ? placeholder
                : 'Search or add a patient'
            }
            onChange={(e) => {
              onSearchPatientsType(e)
            }}
            onClickIconRight={() => {
              if (hasXIcon) {
                clearSearch()
                onClearSelection && onClearSelection()
              }
            }}
            iconLeft={MagnifyingGlassIcon}
            iconRight={!showIcon ? undefined : hasXIcon ? XMarkIcon : undefined}
            validation={{
              required: rest?.validation?.required
                ? 'A patient must be selected'
                : false,
              pattern: {
                message:
                  'Search by MRN, name, DOB (mm/dd/yyyy), or phone number (1234567890)',
                value: /([a-zA-Z0-9._-]+)/g,
              },
            }}
          />

          {!selectedPatient && !loading && searchResults && (
            <RenderPatientSearchResults
              name={displayName}
              isSearchActive={query.length >= MIN_QUERY_LENGTH}
              data-testid="search-patient-results"
              searchResults={searchResults}
              onSearchResultClick={onSearchResultClick}
            />
          )}
        </StackView>
        {onSearchButtonClickCallback && (
          <Button
            disabled={loading || !searchResults.length}
            onClick={() => {
              clearSearch()
              onSearchButtonClickCallback(
                searchResults.map((patient) => patient.id)
              )
            }}
          >
            Search
          </Button>
        )}
      </StackView>
    </Box>
  )
}

export default EnhancedPatientSearch
