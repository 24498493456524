import { TextareaHTMLAttributes } from 'react'

import clsx from 'clsx'

import { TextAreaField as RWTextAreaField } from '@redwoodjs/forms'
import { TextAreaFieldProps as RWTextAreaFieldProps } from '@redwoodjs/forms/dist/TextAreaField'

import {
  getInputFieldClasses,
  getInputFieldContainerClasses,
  inputFieldErrorClasses,
} from '../InputField'

export interface TextAreaFieldProps extends RWTextAreaFieldProps {
  testId?: string
}

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  hasError?: boolean
}

const textareaSpecificClasses = '!h-auto min-h-core-space-500 py-core-space-50'

export const TextArea = ({
  className,
  hasError,
  disabled = false,
  ...rest
}: Props) => {
  return (
    <div className={clsx(getInputFieldContainerClasses({ disabled }))}>
      <textarea
        disabled={disabled}
        className={clsx(
          ...getInputFieldClasses({
            size: 'm',
          }),
          hasError && inputFieldErrorClasses,
          textareaSpecificClasses,
          className
        )}
        {...rest}
      />
    </div>
  )
}

const TextAreaField = ({
  name,
  testId,
  emptyAs = null,
  disabled,
  className,
  errorClassName,
  ...rest
}: TextAreaFieldProps) => {
  return (
    <div>
      <div className={clsx(getInputFieldContainerClasses({ disabled }))}>
        <RWTextAreaField
          name={name}
          data-testid={testId}
          disabled={disabled}
          emptyAs={emptyAs}
          className={clsx(
            ...getInputFieldClasses({
              size: 'm',
            }),
            textareaSpecificClasses,
            className
          )}
          errorClassName={clsx(
            ...inputFieldErrorClasses,
            ...getInputFieldClasses({
              size: 'm',
            }),
            textareaSpecificClasses,
            errorClassName
          )}
          {...rest}
        />
      </div>
    </div>
  )
}

export default TextAreaField
