import { useParams } from 'react-router-dom'

import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import OrderSidepanelForm from 'src/components/OrderSidepanelForm/OrderSidepanelForm'
import { usePatientOrdersQuery } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

const SidepanelOrderEdit = () => {
  const { patientId, orderId } = useParams()
  const { orders } = usePatientOrdersQuery(patientId)
  const order = orders?.find((order) => order.id === orderId)

  if (!order || order.category === 'HG_LAB') {
    return <SidepanelNotAvailable />
  }

  return (
    <SidepanelPage
      testId="sidepanel-order-create"
      header={`Edit order: ${order.name}`}
    >
      <OrderSidepanelForm
        key={order.id}
        patientId={patientId}
        category={order.category}
        order={order}
      />
    </SidepanelPage>
  )
}

export default SidepanelOrderEdit
