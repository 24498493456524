import React from 'react'

import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { format } from 'date-fns'

import Button from 'src/components/atoms/Button'
import Checkbox from 'src/components/atoms/Checkbox'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { Tooltip, TooltipProps } from 'src/components/molecules/Tooltip/Tooltip'

export type DataDisplayableValue =
  | Date
  | string
  | string[]
  | boolean
  | number
  | null
  | React.ReactElement

const DataDisplayValue = ({
  value,
  tooltipProps,
}: {
  value: DataDisplayableValue
  tooltipProps?: Omit<TooltipProps, 'children'>
}) => {
  if (tooltipProps) {
    return (
      <StackView space={50} direction="row" alignItems="center">
        <Content value={value} />
        <Tooltip {...tooltipProps}>
          <Button
            buttonSize="s"
            buttonStyle="ghost"
            icon={QuestionMarkCircleIcon}
          />
        </Tooltip>
      </StackView>
    )
  }

  return <Content value={value} />
}

const Content = ({ value }: { value: DataDisplayableValue }) => {
  if (React.isValidElement(value)) {
    return value
  }
  if (value instanceof Date) {
    return <Typography>{format(value, 'MM/dd/yyyy')}</Typography>
  } else if (['string', 'number'].includes(typeof value)) {
    return <Typography>{value}</Typography>
  } else if (Array.isArray(value) && value.length > 0) {
    const displayString = value.join(', ')
    return <Typography>{displayString}</Typography>
  } else if (typeof value === 'boolean') {
    return <Checkbox checked={value} name="cb" disabled />
  }

  return null
}

export default DataDisplayValue
