import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import {
  GetPatientImmunizationsToPrint,
  GetGrowthChartData,
  GetPatientForGenericPdf,
} from 'types/graphql'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { sexDisplay } from 'src/data/sexes'

import { SubHeading } from './SubHeading'

export const PatientDetails = ({
  patient,
  showCaregivers = true,
  showPrimaryPractitioner = true,
  showSigningPractitioner = false,
  signingPractitioner,
}: {
  patient:
    | GetPatientImmunizationsToPrint['patient']
    | GetGrowthChartData['growthChartData']['patient']
    | GetPatientForGenericPdf['patient']
  showCaregivers?: boolean
  showPrimaryPractitioner?: boolean
  showSigningPractitioner?: boolean
  signingPractitioner?: GetPatientForGenericPdf['practitioner']
}) => {
  return (
    <Box
      color="bg-base-color-bg-subtle"
      padding={50}
      data-testid="patient-details"
    >
      <StackView
        direction="row"
        justifyContent="between"
        space={50}
        className="pb-core-space-50"
      >
        <StackView>
          <SubHeading>Patient name</SubHeading>
          <Typography textStyle="interface-default-xs">
            {formatDisplayName(patient)}
          </Typography>
          <Typography textStyle="interface-default-xs">
            {sexDisplay[patient.sexAtBirth]} &bull;{' '}
            {format(parseISO(patient.birthDate), 'M/d/yyyy')}
          </Typography>
        </StackView>

        {showCaregivers && (
          <StackView>
            <SubHeading>{'Caregiver(s)'}</SubHeading>
            {patient.patientRelatedPersonRelationships.map((caregiver) => {
              if (caregiver.relatedPerson) {
                return (
                  <Typography
                    key={caregiver.id}
                    textStyle="interface-default-xs"
                  >{`${formatDisplayName(caregiver.relatedPerson)} (${
                    relationshipTypeDisplay[caregiver.relationshipType]
                  })`}</Typography>
                )
              }
              return (
                <Typography key={caregiver.id} textStyle="interface-default-xs">
                  {relationshipTypeDisplay[caregiver.relationshipType]}
                </Typography>
              )
            })}
          </StackView>
        )}

        {showPrimaryPractitioner && (
          <StackView>
            <SubHeading>Primary practitioner</SubHeading>
            <Typography textStyle="interface-default-xs">
              {formatDisplayName(patient.primaryProvider)}
            </Typography>
          </StackView>
        )}

        {showSigningPractitioner && signingPractitioner && (
          <StackView>
            <SubHeading>Practice staff</SubHeading>
            <Typography textStyle="interface-default-xs">
              {formatDisplayName(signingPractitioner)}
            </Typography>
          </StackView>
        )}

        <StackView>
          <SubHeading>Date</SubHeading>
          <Typography textStyle="interface-default-xs">
            {format(new Date(), "MMM d, yyyy 'at' h:mm a")}
          </Typography>
        </StackView>
      </StackView>
    </Box>
  )
}
