import { useEffect } from 'react'

import { formatDisplayName } from 'common/utils'
import type { GetPractitionersDropdown } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import {
  DropdownField,
  DropdownFieldProps,
} from 'src/components/atoms/Dropdown/Dropdown'
import { Option } from 'src/components/atoms/Select/Select'

export const QUERY = gql`
  query GetPractitionersDropdown {
    practitioners {
      id
      namePrefix
      givenName
      familyName
      nameSuffix
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<GetPractitionersDropdown>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

interface PractitionerDropdownCellProps {
  name?: string
  label?: string
  selected?: Option
  onSelect?: (Option) => void
  validation?: DropdownFieldProps['validation']
  // if true it does not set the default value as first element.
  // value will be undefined
  disableDefaultSelection?: boolean
  prependedOptions?: Option[]
}

export const Success = ({
  practitioners,
  name,
  onSelect,
  disableDefaultSelection = false,
  prependedOptions = [],
  ...rest
}: CellSuccessProps<GetPractitionersDropdown> &
  PractitionerDropdownCellProps) => {
  const options = practitioners.map((practitioner) => {
    return {
      name: formatDisplayName(practitioner),
      value: practitioner.id,
    }
  })

  prependedOptions.forEach((option) => {
    options.unshift(option)
  })

  useEffect(() => {
    if (onSelect && practitioners.length > 0 && !disableDefaultSelection)
      onSelect({ value: practitioners[0].id || [] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <DropdownField name={name} options={options} mode="id" {...rest} />
}
