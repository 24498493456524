import { omit } from 'lodash'
import { useParams } from 'react-router-dom'
import {
  FindDocumentCaseDetails,
  PatientDocumentType,
  DocumentType,
} from 'types/graphql'
import { useCancelDocumentCase } from 'web/src/components/DocumentCaseResolution/useCancelDocumentCase'
import { useResolveDocumentCaseMultiFax } from 'web/src/components/DocumentCaseResolution/useResolveDocumentCaseMultiFax'

import { Form, useForm } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import Divider from 'src/components/atoms/Divider'
import DocumentTypeSelectField from 'src/components/atoms/DocumentTypeSelectField'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import FormInputList from 'src/components/molecules/FormInputList'
import SidepanelButtonFooter from 'src/components/molecules/SidepanelButtonFooter'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { useCreateDocumentCaseSignaturePdf } from 'src/hooks/useCreateDocumentCaseSignaturePdf/useCreateDocumentCaseSignaturePdf'
import { FIND_DOCUMENT_CASE_DETAILS_QUERY } from 'src/pages/CasesPage/DocumentCasesTab/DocumentCase/DocumentCase'
import { AppendSignaturePageInput } from 'src/pages/Sidepanel/SidepanelDocumentCase/AppendSignaturePageInput'
import { EfaxWhenResolvingTaskInput } from 'src/pages/Sidepanel/SidepanelDocumentCase/EfaxWhenResolvingTaskInput'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import {
  documentCaseResolutionConfig,
  getDocumentResolutionType,
  getIsPatientDocument,
} from './helpers'

export const DocumentCaseResolutionForm = ({
  documentType,
  documentCase,
}: {
  documentType?: DocumentType | PatientDocumentType
  documentCase: FindDocumentCaseDetails['documentCase']
}) => {
  const { closeSidePanel } = useSidepanel()

  const [
    resolveDocumentCaseMultiFax,
    { loading: resolvingDocumentCaseMultifax },
  ] = useResolveDocumentCaseMultiFax({
    documentCaseId: documentCase.id,
    onCompleted: () => {
      closeSidePanel()
    },
  })

  const [cancelCase, { loading: cancellingDocumentCase }] =
    useCancelDocumentCase({
      documentCaseId: documentCase.id,
      onCompleted: closeSidePanel,
    })
  const { isGenerating: isGeneratingPdf, generateDocumentCaseSignaturePdf } =
    useCreateDocumentCaseSignaturePdf()

  const isPatientDocument = getIsPatientDocument(documentType)

  // Untyped form state for now -- dynamic due to form building based on document type leads to complex typing here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formMethods = useForm<any>({
    defaultValues: {
      // HACK: Patient document form / mutation resolver uses a different key compared practice documents
      // Ideally for the current way that forms are build, we should have a consistent key for subtype
      ...(isPatientDocument
        ? { subType: documentCase.subType }
        : { type: documentCase.subType }),
      title: documentCase.title,
      comment: documentCase.comment,
      signedByPractitionerId: documentCase?.signedByPractitionerId,
      ...(documentCase?.typeSpecificInputs ?? {}),
    },
    shouldUnregister: true,
  })

  const patientId = formMethods.watch('patientId')

  const resolutionType = getDocumentResolutionType(documentType)
  const nonTypeSpecificFormFields = [
    'documentType',
    'patient',
    'patientDisplayText',
    'shouldAddSignature',
    'signedByPractitionerId',
    'comment',
    'faxRecipientContactIds',
    'shouldEfax',
    'sendEFaxBackToSenderBinary',
  ]

  const generateInboundFaxAppendedWithSignaturePdf = ({
    comment,
    signedByPractitionerId,
    documentCase,
  }: {
    signedByPractitionerId: string
    documentCase: FindDocumentCaseDetails['documentCase']
    comment?: string
  }): Promise<string | undefined> => {
    return generateDocumentCaseSignaturePdf({
      comment,
      signedByPractitionerId,
      addSignature: true,
      originalFax: documentCase.inboundFax
        ? {
            faxReceivedAt: new Date(documentCase.baseCase.createdAt),
            faxNumber: documentCase.inboundFax.faxedFrom,
          }
        : undefined,
    })
  }

  const resolveCase = async (input) => {
    const {
      signedByPractitionerId,
      faxRecipientContactIds,
      shouldAddSignature,
      shouldEfax,
      comment,
    } = input

    return resolveDocumentCaseMultiFax(
      {
        signedByPractitionerId: shouldAddSignature
          ? signedByPractitionerId
          : null,
        resolutionType,
        comment,
        faxRecipientContactIds: shouldEfax ? faxRecipientContactIds : [],
        [documentCaseResolutionConfig[resolutionType].inputField]: {
          ...omit(input, nonTypeSpecificFormFields),
          // HACK: Patient document form / mutation resolver requires type to be passed in which is not the case
          // for practice documents since they have their own resolver per type
          ...(isPatientDocument
            ? { type: documentType }
            : { subType: undefined }),
        },
      },
      shouldAddSignature && signedByPractitionerId
        ? {
            contentType: 'application/pdf',
            content: await generateInboundFaxAppendedWithSignaturePdf({
              signedByPractitionerId,
              documentCase,
              comment,
            }),
          }
        : undefined
    )
  }

  if (!documentType) {
    return (
      <SidepanelForm
        footerElement={
          <SidepanelButtonFooter
            onCancel={cancelCase}
            cancelText="Cancel task"
            disabled
            submitText="Resolve task"
            submitting={false}
          />
        }
      />
    )
  }

  const { getFormInputListItems } = documentCaseResolutionConfig[resolutionType]
  const originalSenderContact = documentCase.inboundFax?.from
  const originalSenderFaxNumber = documentCase.inboundFax?.faxedFrom
  const defaultSelectedContactIds = documentCase.contacts?.map(({ id }) => id)

  return (
    <SidepanelForm
      formMethods={formMethods}
      onSubmit={(input) => resolveCase(input)}
      footerElement={
        <SidepanelButtonFooter
          onCancel={cancelCase}
          hideEditIcon
          submitting={
            isGeneratingPdf ||
            resolvingDocumentCaseMultifax ||
            cancellingDocumentCase
          }
          submitText="Resolve case"
          cancelText="Cancel"
        />
      }
    >
      <StackView space={100}>
        <FormInputList
          items={getFormInputListItems({
            type: documentType,
            patientId,
            defaultPatientId: documentCase?.patient?.id,
            isDocumentCase: true,
          })}
        />
        <Divider />
        <AppendSignaturePageInput />
        <Divider />
        <EfaxWhenResolvingTaskInput
          name="faxRecipientContactIds"
          originalSenderContact={originalSenderContact}
          originalSenderFaxNumber={originalSenderFaxNumber}
          defaultSelectedContactIds={defaultSelectedContactIds}
        />
      </StackView>
    </SidepanelForm>
  )
}

export const DocumentCaseResolution = () => {
  const { documentCaseId } = useParams()
  const { data, loading } = useQuery(FIND_DOCUMENT_CASE_DETAILS_QUERY, {
    variables: {
      documentCaseId,
    },
    fetchPolicy: 'cache-first',
  })

  const formMethods = useForm<{ documentType: DocumentType }>({
    defaultValues: {
      documentType: data?.documentCase?.type,
    },
  })

  if (loading || !data?.documentCase) {
    return (
      <StackView className="flex h-full">
        <LoadingSpinner />
      </StackView>
    )
  }

  const documentType = formMethods.watch('documentType')
  const documentCase = data.documentCase

  return (
    <StackView className="flex h-full">
      <Form className="flex-0 px-6" formMethods={formMethods}>
        <FormInputList
          title="Document Details"
          items={[
            {
              name: 'documentType',
              label: 'Document type',
              formInputComponent: DocumentTypeSelectField,
              inputProps: {
                patient: true,
                clinic: true,
              },
              required: true,
            },
          ]}
        />
        <Divider />
      </Form>
      <DocumentCaseResolutionForm
        documentType={documentType}
        documentCase={documentCase}
      />
    </StackView>
  )
}
