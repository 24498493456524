import { ClipboardIcon, PencilSquareIcon } from '@heroicons/react/20/solid'
import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import { User } from 'types/graphql'

import Box from 'src/components/atoms/Box/Box'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

interface NoteItemProps {
  createdBy: Partial<User>
  createdAt: string
  text: string
  index: number
}

const NoteItem = ({ createdBy, createdAt, index }: NoteItemProps) => {
  return (
    <Box horizontalPadding={50} verticalPadding={75}>
      <StackView direction="row" space={75} alignItems="start">
        <Box flex={'1/12'}>
          <Box
            className="flex h-8 w-8 items-center justify-center rounded-full"
            color="bg-base-color-bg-subtle"
          >
            {index === 0 ? (
              <ClipboardIcon className="h-5 w-5 text-gray-500" />
            ) : (
              <PencilSquareIcon className="h-5 w-5 text-gray-500" />
            )}
          </Box>
        </Box>
        <Box grow>
          <StackView space={25}>
            <StackView
              space={50}
              direction="row"
              alignItems="center"
              justifyContent="between"
            >
              <Typography>{`Note ${
                index === 0 ? 'created' : 'updated'
              }`}</Typography>
              <Typography>·</Typography>
              <Typography color="text-base-color-fg-muted" size="s">
                {format(parseISO(createdAt), "MMM d, yyyy 'at' h:mm a")}
              </Typography>
            </StackView>
            <Typography>{formatDisplayName(createdBy)}</Typography>
          </StackView>
        </Box>
      </StackView>
    </Box>
  )
}

export default NoteItem
