import { Eventcalendar, MbscCalendarEvent } from '@mobiscroll/react'
import {
  format,
  startOfMonth,
  endOfMonth,
  isSameDay,
  parse,
  addMinutes,
  differenceInMinutes,
  startOfDay,
  addHours,
  endOfDay,
} from 'date-fns'
import { Options, RRule, Weekday } from 'rrule'

import { Option } from 'src/components/atoms/Select/Select'
import { DayType } from 'src/components/AvailabilityException/AvailabilityExceptionForm/AvailabilityExceptionForm'
import { DEFAULT_DATE_FORMAT } from 'src/components/molecules/DatePicker/DatePicker'

export const getMonthlyRecurrenceOptions = (date: Date): Option[] => {
  const dayKey = format(date, 'EEEEEE').toUpperCase()
  const weekDayRule = new RRule({
    byweekday: [RRule[dayKey]],
    dtstart: startOfMonth(date),
    until: endOfMonth(date),
  })
  const weekdayIndexOfMonth = weekDayRule.all().reduce((acc, curr, index) => {
    if (isSameDay(date, curr)) {
      return index + 1
    }
    return acc
  }, undefined)
  const nthRule = new RRule({
    freq: RRule.MONTHLY,
    byweekday: [RRule[dayKey].nth(weekdayIndexOfMonth)],
  })
  const dayNumberRule = new RRule({
    freq: RRule.MONTHLY,
    bymonthday: [date.getDate()],
  })
  return [
    {
      value: dayNumberRule.toString(),
      name: dayNumberRule.toText(),
    },
    {
      value: nthRule.toString(),
      name: nthRule.toText(),
    },
  ]
}

export const getWeeklyRecurrenceValue = (rrule: string) => {
  if (!rrule) {
    return {}
  }
  const weekdayMap = {
    [RRule.SU.weekday]: 'SUN',
    [RRule.MO.weekday]: 'MON',
    [RRule.TU.weekday]: 'TUE',
    [RRule.WE.weekday]: 'WED',
    [RRule.TH.weekday]: 'THU',
    [RRule.FR.weekday]: 'FRI',
    [RRule.SA.weekday]: 'SAT',
  }
  const rule = RRule.fromString(rrule)
  if (!rule?.origOptions?.byweekday) {
    return {}
  }
  const days = Array.isArray(rule.origOptions.byweekday)
    ? rule.origOptions.byweekday
    : [rule?.origOptions?.byweekday]
  const result = days.reduce((acc, { weekday }: Weekday) => {
    acc[weekdayMap[weekday]] = true
    return acc
  }, {})
  return result
}

export const getMonthlyRecurrenceValue = (rrule: string) => {
  if (!rrule) {
    return ''
  }
  const rule = RRule.fromString(rrule)
  const monthlyValue = new RRule({
    freq: RRule.MONTHLY,
    bymonthday: rule.origOptions.bymonthday,
    byweekday: rule.origOptions.byweekday,
  }).toString()
  return monthlyValue
}

export const getWeekdaysRecurrenceRule = (daysObj: DayType) => {
  if (!daysObj) return []
  return Object.keys(daysObj).reduce((acc, dayKey) => {
    if (daysObj[dayKey]) {
      acc.push(RRule[dayKey.slice(0, 2)])
    }
    return acc
  }, [])
}

export const getEndRecurrenceValues = (
  rrule: string
): {
  ends: 'never' | 'on' | 'occurrences'
  occurrences?: number
  endsOn?: string
} => {
  if (!rrule) {
    return {
      ends: 'never',
      occurrences: 1,
      endsOn: format(new Date(), DEFAULT_DATE_FORMAT),
    }
  }
  const rule = RRule.fromString(rrule)
  if (rule.origOptions.until) {
    return {
      ends: 'on',
      endsOn: format(rule.origOptions.until, DEFAULT_DATE_FORMAT),
    }
  }
  if (rule.origOptions.count) {
    return {
      ends: 'occurrences',
      occurrences: rule.origOptions.count,
    }
  }
  return {
    ends: 'never',
  }
}

export const getEndsRecurrenceOptions = (
  ends: 'on' | 'occurrences' | 'never',
  on: Date = undefined,
  occurrences = 0
) => {
  const options = {}
  if (ends === 'on') {
    options['until'] = on
  }
  if (ends === 'occurrences') {
    options['count'] = occurrences
  }
  return options
}

export type RecurrenceRuleType = {
  rule: string
  text: string
}

export const getRecurrenceRule = (
  dtstart: Date,
  allDayOrClosed: boolean,
  startTime: string, // h:mm a
  endTime: string, // h:mm a
  frequency: string,
  interval: number,
  weekdaysRRule,
  monthlyRRule: string,
  endOptions,
  repeatActive
): RecurrenceRuleType => {
  if (!repeatActive) {
    const options = {
      freq: RRule.DAILY,
      dtstart: allDayOrClosed
        ? startOfDay(dtstart)
        : addMinutes(
            addHours(
              startOfDay(dtstart),
              parseInt(format12HoursTo24(startTime).split(':')[0])
            ),
            parseInt(startTime.split(':')[1])
          ),
      ...getEndsRecurrenceOptions(
        'on',
        allDayOrClosed
          ? endOfDay(dtstart)
          : addMinutes(
              addHours(
                startOfDay(dtstart),
                parseInt(format12HoursTo24(endTime).split(':')[0])
              ),
              parseInt(endTime.split(':')[1])
            )
      ),
    }
    const rule = new RRule(options)
    return {
      rule: rule.toString(),
      text: rule.toText(),
    }
  }
  const options: Partial<Options> = {
    freq: RRule[frequency.toUpperCase()],
    interval,
    dtstart: allDayOrClosed
      ? startOfDay(dtstart)
      : addMinutes(
          addHours(
            startOfDay(dtstart),
            parseInt(format12HoursTo24(startTime).split(':')[0])
          ),
          parseInt(format12HoursTo24(startTime).split(':')[1])
        ),
    ...endOptions,
  }
  const rule = new RRule(options)
  if (frequency === 'weekly') {
    rule.origOptions.byweekday = weekdaysRRule
  }
  if (frequency === 'monthly') {
    const monthly = RRule.fromString(monthlyRRule)
    rule.origOptions.bymonthday = monthly.origOptions?.bymonthday
    rule.origOptions.byweekday = monthly.origOptions?.byweekday
  }
  return {
    rule: rule.toString(),
    text: rule.toText(),
  }
}

export const format12HoursTo24 = (
  time: string | Date | number | undefined
): string => {
  if (!time) {
    return ''
  }
  if (typeof time === 'string')
    return format(parse(time, 'h:mm a', new Date()), 'HH:mm')
  if (time instanceof Date) {
    return format(time, 'HH:mm')
  }
}

export const format24HoursTo12 = (
  time: string | Date | number | undefined
): string => {
  if (!time) {
    return ''
  }

  if (typeof time === 'string')
    return format(parse(time, 'HH:mm', new Date()), 'h:mm a')
  if (time instanceof Date) {
    return format(time, 'h:mm a')
  }
}

export const isGrayClicked = (
  inst: Eventcalendar,
  event: MbscCalendarEvent,
  returnMinMax = false
): [grayClicked: boolean, minMax?: { min?: Date; max?: Date }] => {
  // making all day events invalid, since appointments cant be that long
  if (event.allDay) {
    return [true, {}]
  }
  const duration = differenceInMinutes(event.end as Date, event.start as Date)
  const freeBlocksOf5minutes = Array(duration / 5)
    .fill({ start: '', end: '' })
    .reduce((acc, _, index) => {
      const startInterval = addMinutes(event.start as Date, index * 5)
      const endInterval = addMinutes(event.start as Date, (index + 1) * 5)
      acc.push({
        start: startInterval,
        end: endInterval,
        index: index,
      })
      return acc
    }, [])
    .filter((block) => {
      const invalidsInRange = inst
        .getInvalids(block.start, block.end)
        .filter(({ resource }) => resource === event.resource)
      return invalidsInRange.length === 0
    })
  if (freeBlocksOf5minutes.length === 0) {
    return [true, {}]
  }
  if (returnMinMax) {
    return [
      false,
      {
        min: freeBlocksOf5minutes[0].start,
        max: freeBlocksOf5minutes[freeBlocksOf5minutes.length - 1].end,
      },
    ]
  }
  return [false, {}]
}
