import {
  FindPatientDemographicsQuery,
  FindPatientPastMedicalHistoryQuery,
  UpdatePatientPastMedicalHistoryMutation,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import { FRAGMENT as CURRENT_INSURANCE_ELIGIBILITY_FRAGMENT } from 'src/components/InsuranceEligibility/fragments'
import { SIBLING_DEMOGRAPHICS_FRAGMENT } from 'src/components/organisms/PatientSiblings/SiblingInfoCard'
import { PHARMACY_FRAGMENT as PHARMACY_FRAGMENT } from 'src/hooks/useRefetchPatientPharmacy/useRefetchPatientPharmacy'
import { INSURANCE_COVERAGE_FRAGMENT } from 'src/providers/context/AppointmentCheckInContext/schema'

import {
  PATIENT_RELATED_PERSON_RELATIONSHIP_WITH_FULL_DETAILS_FRAGMENT,
  PATIENT_STATUS_FRAGMENT,
} from './fragments'

const PATIENT_DEMOGRAPHICS_FRAGMENT = gql`
  fragment PatientDemographicsFragment on Patient {
    id
    mrn
    ...PatientStatusFragment
    birthDate
    primaryGuardianId
    primaryGuarantorId
    legalStatus
    practiceComment
    namePrefix
    nameSuffix
    givenName
    middleName
    familyName
    preferredName
    sexAtBirth
    genderIdentity
    preferredPronouns
    race
    ethnicity
    socialSecurityNumber
    sexualOrientation
    caregiverOptedOutAt
    insuranceOptOutStatus
    insuranceOptedOutAt
    defaultPharmacy {
      ...PharmacyFragment
    }
    doseSpotURL
    copayCents
    contactInformation {
      id
      verifiedAt
      emailAddress
      primaryLanguage
      secondaryLanguage
      faxNumber
      mobileNumber
      homeNumber
      primaryPreferredCommunication
      secondaryPreferredCommunication
      homeAddress {
        id
        line1
        line2
        city
        postalCode
        state
        country
      }
      mailingAddress {
        id
        line1
        line2
        city
        postalCode
        state
        country
      }
    }
    primaryProvider {
      id
      givenName
      familyName
    }
    primaryGuardian {
      id
      namePrefix
      givenName
      middleName
      familyName
      nameSuffix
    }
    primaryGuarantor {
      id
      namePrefix
      givenName
      middleName
      familyName
      nameSuffix
    }
    patientRelatedPersonRelationships {
      ...PatientRelatedPersonRelationshipWithFullDetailsFragment
    }
    insuranceCoverages {
      ...InsuranceCoverageFragment
      mostRecentEligibility {
        ...InsuranceEligibilityFragment
      }
      binary {
        url
        contentType
      }
    }
    siblings {
      ...SiblingDemographicsFragment
    }
  }
  ${PHARMACY_FRAGMENT}
  ${CURRENT_INSURANCE_ELIGIBILITY_FRAGMENT}
  ${INSURANCE_COVERAGE_FRAGMENT}
  ${SIBLING_DEMOGRAPHICS_FRAGMENT}
  ${PATIENT_RELATED_PERSON_RELATIONSHIP_WITH_FULL_DETAILS_FRAGMENT}
  ${PATIENT_STATUS_FRAGMENT}
`

export const PATIENT_LAST_COPAY_AUDIT_RECORD_FRAGMENT = gql`
  fragment PatientLastCopayAuditRecordFragment on Patient {
    lastCopayAuditRecord {
      id
      insuranceCoverageEligibilityId
      createdAt
      lastCopayCents
      createdByPractitioner {
        id
        namePrefix
        nameSuffix
        givenName
        middleName
        familyName
      }
    }
  }
`

const FIND_PATIENT_DEMOGRAPHICS_QUERY = gql`
  query FindPatientDemographicsQuery($id: String!) {
    patient(id: $id) {
      ...PatientDemographicsFragment
    }
  }
  ${PATIENT_DEMOGRAPHICS_FRAGMENT}
`

export const UPDATE_PATIENT_DEMOGRAPHICS_MUTATION = gql`
  mutation UpdatePatientDemographicsMutation(
    $id: String!
    $input: UpdatePatientInput!
  ) {
    updatePatient(id: $id, input: $input) {
      ...PatientDemographicsFragment
    }
  }
  ${PATIENT_DEMOGRAPHICS_FRAGMENT}
`

export const UPDATE_PATIENT_COPAY_MUTATION = gql`
  mutation UpdatePatientQueryMutation(
    $id: String!
    $input: UpdatePatientCopayInput!
  ) {
    updatePatientCopay(id: $id, input: $input) {
      id
      copayCents
      ...PatientLastCopayAuditRecordFragment
    }
  }
  ${PATIENT_LAST_COPAY_AUDIT_RECORD_FRAGMENT}
`

const FIND_PATIENT_PAST_MEDICAL_HISTORY_QUERY = gql`
  query FindPatientPastMedicalHistoryQuery($id: String!) {
    patient(id: $id) {
      id
      pastMedicalHistory
      pastMedicalHistoryLastUpdatedAt
    }
  }
`

const UPDATE_PATIENT_PAST_MEDICAL_HISTORY_MUTATION = gql`
  mutation UpdatePatientPastMedicalHistoryMutation(
    $input: UpdatePastMedicalHistoryInput!
  ) {
    updatePastMedicalHistory(input: $input) {
      id
      pastMedicalHistory
      pastMedicalHistoryLastUpdatedAt
    }
  }
`

export const usePatientDemographicsQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<FindPatientDemographicsQuery>(
    FIND_PATIENT_DEMOGRAPHICS_QUERY,
    {
      variables: { id: patientId },
    }
  )

  return {
    patient: data?.patient,
    loading,
    error,
  }
}

export const usePatientPastMedicalHistoryQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<FindPatientPastMedicalHistoryQuery>(
    FIND_PATIENT_PAST_MEDICAL_HISTORY_QUERY,
    {
      variables: { id: patientId },
    }
  )

  return {
    patient: data?.patient,
    loading,
    error,
  }
}

export const usePatientPastMedicalHistoryMutation = () => {
  return useMutation<UpdatePatientPastMedicalHistoryMutation>(
    UPDATE_PATIENT_PAST_MEDICAL_HISTORY_MUTATION
  )
}
