import { LocationContextType } from '@redwoodjs/router/dist/location'

import {
  SidepanelRouteContext,
  SIDEPANEL_QUERY_PARAM_KEY,
} from 'src/providers/context/SidepanelContext'

export function routeTo(
  to: string,
  location: LocationContextType,
  retainSidepanelContext = true
) {
  return `${to}${retainSidepanelContext ? location.search : ''}`
}

export function sidepanelRoute(
  sidepanelContext: SidepanelRouteContext,
  location: LocationContextType,
  params: Record<string, string> = {}
) {
  const newParams = new URLSearchParams({
    ...params,
    [SIDEPANEL_QUERY_PARAM_KEY]: JSON.stringify(sidepanelContext),
  })

  return `${location.pathname}?${newParams.toString()}`
}

export function isActiveRoute(currentPath: string, toPath: string) {
  return ['/', ''].includes(toPath)
    ? currentPath === toPath
    : ['/', ''].includes(currentPath)
    ? currentPath === toPath
    : currentPath.startsWith(toPath) || toPath.startsWith(currentPath)
}

export function isActiveNestedRoute(
  currentPath: string,
  toPath: string,
  commonPath: string
) {
  if (!currentPath.startsWith(commonPath) || !toPath.startsWith(commonPath)) {
    return false
  }

  return isActiveRoute(
    currentPath.replace(commonPath, ''),
    toPath.replace(commonPath, '')
  )
}
