import { observationConcepts } from 'common/cdr/concepts/observations/index'
import {
  FindPatientSocialHistory,
  UpsertSocialHistory,
  Observation,
  ObservationComponent,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import {
  FrontendConfiguration,
  deepOmit,
  getExistingFormValues,
  getExistingValuesForFrontend,
  useGetFrontendMappings,
} from 'src/data/utils'

import { OBSERVATION_FRAGMENT } from '../../PatientVisits/fragments'

import { Answer, SocialHistoryResponse, keyToCategoryDisplay } from './config'

const SOCIAL_HISTORY_FRAGMENT = gql`
  fragment SocialHistoryFragment on SocialHistory {
    id
    travelHistory {
      ...ObservationFragment
      components {
        code {
          code
          display
          system
        }
        value
      }
    }
    smokingTobaccoUse {
      ...ObservationFragment
    }
    smokelessTobaccoUse {
      ...ObservationFragment
    }
    vapingUse {
      ...ObservationFragment
    }
    alcoholUse {
      ...ObservationFragment
    }
    drugUse {
      id
      cannabisMarijuana {
        ...ObservationFragment
      }
      cannabisSynthetic {
        ...ObservationFragment
      }
      methamphetamine {
        ...ObservationFragment
      }
      amphetamine {
        ...ObservationFragment
      }
      mdma {
        ...ObservationFragment
      }
      barbiturates {
        ...ObservationFragment
      }
      benzodiazepines {
        ...ObservationFragment
      }
      opioids {
        ...ObservationFragment
      }
      hallucinogens {
        ...ObservationFragment
      }
      psychedelics {
        ...ObservationFragment
      }
      kava {
        ...ObservationFragment
      }
      cocaine {
        ...ObservationFragment
      }
      crackCocaine {
        ...ObservationFragment
      }
      other {
        ...ObservationFragment
      }
    }
    comment {
      ...ObservationFragment
    }
  }
  ${OBSERVATION_FRAGMENT}
`

const SOCIAL_HISTORY_QUERY = gql`
  query FindPatientSocialHistory($id: String!) {
    patient(id: $id) {
      id
      socialHistory {
        ...SocialHistoryFragment
      }
    }
  }
  ${SOCIAL_HISTORY_FRAGMENT}
`
const UPSERT_SOCIAL_HISTORY_MUTATION = gql`
  mutation UpsertSocialHistory($input: UpsertSocialHistoryInput!) {
    upsertSocialHistory(input: $input) {
      id
      socialHistory {
        ...SocialHistoryFragment
      }
    }
  }
  ${SOCIAL_HISTORY_FRAGMENT}
`

const getSocialHistoryForFrontend = (
  socialHistory,
  codeToEnumMap,
  enumToDisplayMap,
  config?: FrontendConfiguration
) => {
  return getExistingValuesForFrontend({
    obj: socialHistory,
    codeToEnumMap,
    enumToDisplayMap,
    config,
  })
}

export const useSocialHistoryQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<FindPatientSocialHistory>(
    SOCIAL_HISTORY_QUERY,
    {
      variables: { id: patientId },
    }
  )

  const socialHistory = data?.patient?.socialHistory

  const { codeToEnumMap, enumToDisplayMap } = useGetFrontendMappings(
    patientId,
    socialHistory?.__typename
  )
  if (!codeToEnumMap || !enumToDisplayMap) return {}

  const getAnswerFromObservation = (entry: Observation): Answer => {
    return {
      questionDisplay: entry?.code?.display,
      display: entry?.value?.display,
      code: entry?.value?.value,
      lastUpdatedAt: entry?.updatedAt,
    }
  }

  const getAnswerFromComponent = (component: ObservationComponent): Answer => {
    return {
      questionDisplay: component?.code?.display,
      display: component?.value?.display,
      code: component?.value?.value,
    }
  }

  const fieldToSocialHistoryObservations = data?.patient?.socialHistory
    ? Object.entries(deepOmit(data?.patient?.socialHistory, ['id'])).reduce(
        (acc, [key, socialHistoryEntry]) => {
          if (key === '__typename' || !socialHistoryEntry) return acc
          if (!acc[key])
            acc[key] = {
              category: keyToCategoryDisplay[key],
              questionCode:
                socialHistoryEntry.code?.code ||
                observationConcepts['DRUG_USE_STATUS'].code,
              lastUpdatedAt: socialHistoryEntry.updatedAt,
              // because we do not represent this question in socialHistoryFieldsToCodes
            }
          if (socialHistoryEntry?.__typename === 'Observation') {
            if (socialHistoryEntry?.value) {
              acc[key] = {
                ...acc[key],
                answer: { ...getAnswerFromObservation(socialHistoryEntry) },
              }
            } else if (socialHistoryEntry?.components) {
              if (!acc[key].answers) acc[key].answers = [] as Answer[]
              for (const component of socialHistoryEntry.components) {
                acc[key].answers.push(getAnswerFromComponent(component))
              }
            }
          } else if (socialHistoryEntry?.__typename === 'DrugUseSummary') {
            if (!acc[key].answers) acc[key].answers = [] as Answer[]
            for (const [_childKey, childEntry] of Object.entries(
              deepOmit(socialHistoryEntry, ['id', '__typename'])
            )) {
              acc[key].answers.push(getAnswerFromObservation(childEntry))
            }
          }
          return acc
        },
        {} as Record<string, SocialHistoryResponse>
      )
    : {}

  const socialHistoryForFrontend: FindPatientSocialHistory['patient']['socialHistory'] =
    deepOmit(
      getSocialHistoryForFrontend(
        socialHistory,
        codeToEnumMap,
        enumToDisplayMap,
        {
          unitInputs: {
            birthWeight: { metricUnit: 'g', displayUnits: ['lbs', 'oz'] },
          },
          checkboxInputs: [
            'cannabisMarijuana',
            'cannabisSynthetic',
            'methamphetamine',
            'amphetamine',
            'mdma',
            'barbiturates',
            'benzodiazepines',
            'opioids',
            'hallucinogens',
            'psychedelics',
            'kava',
            'cocaine',
            'crackCocaine',
            'other',
          ],
        }
      ),
      ['id', '__typename']
    )

  const socialHistoryFormValues = getExistingFormValues(
    {},
    socialHistoryForFrontend
  )

  return {
    socialHistory: socialHistoryForFrontend,
    socialHistoryFormValues,
    fieldToSocialHistoryObservations,
    enumToDisplayMap,
    loading,
    error,
  }
}

export const useUpsertSocialHistory = () => {
  return useMutation<UpsertSocialHistory>(UPSERT_SOCIAL_HISTORY_MUTATION, {
    onCompleted: () => {
      toast.success('Social history saved')
    },
  })
}
