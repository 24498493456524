import { formatDollarsToCents, formatMoneyInCents } from 'common/utils'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'

import { useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/dist/toast'

import Button, { Submit } from 'src/components/atoms/Button'
import { DropdownField } from 'src/components/atoms/Dropdown'
import StackView from 'src/components/atoms/StackView'
import { DatePickerFieldThatWorks } from 'src/components/molecules/DatePicker'
import { FormInputList } from 'src/components/molecules/FormInputList'
import { InputFieldWithUnit } from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useDirectPatientChargeBillingCodesQuery } from 'src/hooks/useDirectPatientChargeBillingCodes/useDirectPatientChargeBillingCodes'
import { useCreatePatientChargesMutation } from 'src/hooks/usePatientCharges/usePatientCharges'
import { useGetPatientPaymentPlanConfig } from 'src/hooks/usePatientPaymentPlans/usePatientPaymentPlans'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const SidepanelFooter = ({ onCancel, onAnother, disabled, submitting }) => {
  return (
    <StackView
      space={50}
      direction="row"
      justifyContent="between"
      data-testid="sidepanel-button-footer"
    >
      <StackView space={50} direction="row" justifyContent="end">
        <Button
          data-testid="sidepanel-cancel-btn"
          buttonStyle="ghost"
          onClick={onCancel}
        >
          Cancel
        </Button>

        {onAnother && (
          <Button
            data-testid="another-charge-btn"
            buttonStyle="secondary"
            onClick={onAnother}
          >
            Save and next charge
          </Button>
        )}

        <Submit
          data-testid="button-footer-submit-btn"
          buttonStyle="primary"
          disabled={disabled}
          loading={submitting}
        >
          Save and close
        </Submit>
      </StackView>
    </StackView>
  )
}

const SidepanelPatientChargesAdd = () => {
  const { patientId } = useParams()
  const { billingCodes } = useDirectPatientChargeBillingCodesQuery()
  const { createCharges, loading: creatingCharges } =
    useCreatePatientChargesMutation()
  const { paymentPlanCancellationDirectPatientChargeBillingCodeId } =
    useGetPatientPaymentPlanConfig()
  const { closeSidePanel, sidepanelContext } = useSidepanel()

  const formMethods = useForm({
    defaultValues: {
      chargeId: undefined,
      amountDollars: undefined,
      dateOfService: format(new Date(), 'yyyy-MM-dd'),
    },
  })

  const { watch } = formMethods

  const chargeId = watch('chargeId')
  const selectedChargeAmountCents =
    chargeId &&
    billingCodes?.find((billingCode) => billingCode.id === chargeId)
      ?.amountCents
  const useVariableAmount = selectedChargeAmountCents === 0

  const onSubmit = async (data, { shouldCloseSidepanel = true }) => {
    await createCharges({
      variables: {
        input: {
          patientId,
          appointmentId: sidepanelContext.appointmentId ?? undefined,
          charges: [
            {
              directPatientChargeBillingCodeId: data.chargeId,
              amountCentsOverride: useVariableAmount
                ? formatDollarsToCents(data.amountDollars)
                : undefined,
              dateOfService: data.dateOfService,
            },
          ],
        },
      },
      onCompleted: (res) => {
        const someChargesDidNotComplete =
          res.createCandidDirectPatientCharges.some(
            (charge) => charge.status !== 'COMPLETE'
          )
        if (someChargesDidNotComplete) {
          const errorReasons = res.createCandidDirectPatientCharges
            .filter((charge) => charge.status !== 'COMPLETE')
            .map((charge) => charge.errorReason)
            .join(', ')
          toast.error(
            `Some charges could not be created. Reason(s): ${errorReasons}`
          )
        } else {
          toast.success('Charge created successfully')
        }
        if (shouldCloseSidepanel) {
          closeSidePanel()
        } else {
          formMethods.reset({
            chargeId: undefined,
            amountDollars: undefined,
          })
        }
      },
    })
  }

  return (
    <SidepanelPage
      testId="patient-charge-add"
      header="Create charges"
      description="Directly add an applicable charge for the patient."
    >
      <SidepanelForm
        formMethods={formMethods}
        onSubmit={(data) => onSubmit(data, { shouldCloseSidepanel: true })}
        footerElement={
          <SidepanelFooter
            onCancel={() => closeSidePanel()}
            onAnother={() =>
              onSubmit(formMethods.getValues(), {
                shouldCloseSidepanel: false,
              })
            }
            disabled={creatingCharges}
            submitting={creatingCharges}
          />
        }
      >
        <FormInputList
          className="py-core-space-150"
          title="Details"
          divider={false}
          items={[
            {
              name: 'chargeId',
              label: 'Charge or billing code & description',
              required: true,
              formInputComponent: DropdownField,
              direction: 'col',
              inputProps: {
                options:
                  billingCodes
                    ?.filter(
                      (billingCode) =>
                        billingCode.id !==
                        paymentPlanCancellationDirectPatientChargeBillingCodeId
                    )
                    .map((billingCode) => ({
                      name: [
                        billingCode.description,
                        billingCode.amountCents === 0
                          ? 'Variable'
                          : formatMoneyInCents(billingCode.amountCents),
                      ].join(' · '),
                      value: billingCode.id,
                    })) ?? [],
                includeCheckbox: true,
                placeholder: 'Search by code or description...',
              },
            },
            {
              name: 'amountDollars',
              label: 'Charge amount',
              message: 'Set the correct charge amount for the patient.',
              required: useVariableAmount,
              hide: !useVariableAmount,
              formInputComponent: InputFieldWithUnit,
              direction: 'col',
              inputProps: {
                unit: 'USD',
                type: 'number',
                min: 0,
                step: 0.01,
                placeholder: '0.00',
              },
            },
            {
              name: 'dateOfService',
              label: 'Charge date',
              required: true,
              formInputComponent: DatePickerFieldThatWorks,
              direction: 'col',
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelPatientChargesAdd
