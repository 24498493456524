import { useMemo } from 'react'

import maxBy from 'lodash/maxBy'
import { FindPatientVitals, UpsertPatientVitals } from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import { calculateAge } from 'src/lib/formatters'

import { transformVitals } from './VitalsTable'

export const OBSERVATION_FOR_VITAL_FRAGMENT = gql`
  fragment ObservationForVitalFragment on Observation {
    id
    name
    value
    coding {
      system
      code
      display
    }
    effectiveAt
    updatedAt
    components {
      code {
        system
        code
        display
      }
      value
    }
    percentile
  }
`

export const VITALS_QUERY = gql`
  query FindPatientVitals($id: String!) {
    patient(id: $id) {
      id
      birthDate
      vitals {
        ...ObservationForVitalFragment
      }
    }
  }
  ${OBSERVATION_FOR_VITAL_FRAGMENT}
`

export enum ValueType {
  Quantity,
  CodeableConcept,
  String,
}

export const useVitalsQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<FindPatientVitals>(VITALS_QUERY, {
    variables: { id: patientId },
  })

  const vitalsLastUpdated = useMemo(() => {
    return data?.patient?.vitals?.length > 0
      ? maxBy(data.patient.vitals, (a) => a.updatedAt).updatedAt
      : null
  }, [data])

  if (!data) return {}

  return {
    patientAge: calculateAge(data?.patient?.birthDate),
    vitals: data?.patient?.vitals,
    vitalsByDateAndCode: data?.patient?.vitals
      ? transformVitals(data.patient.vitals)
      : [],
    vitalsLastUpdated,
    loading,
    error,
  }
}

export const PATIENT_VITALS_FRAGMENT = gql`
  fragment PatientVitalsFragment on Patient {
    id
    vitals {
      ...ObservationForVitalFragment
    }
  }
  ${OBSERVATION_FOR_VITAL_FRAGMENT}
`

export const UPSERT_PATIENT_VITALS_MUTATION = gql`
  mutation UpsertPatientVitals(
    $input: UpsertPatientVitalsInput!
    $encounterId: String
  ) {
    upsertPatientVitals(input: $input, encounterId: $encounterId) {
      id
      ...PatientVitalsFragment
    }
  }
  ${PATIENT_VITALS_FRAGMENT}
`
export const useUpsertPatientVitalsMutation = () => {
  return useMutation<UpsertPatientVitals>(UPSERT_PATIENT_VITALS_MUTATION)
}
