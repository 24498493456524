import type { LocationsQuery } from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'

import { FieldLabel } from 'src/components/atoms/Label/Label'
import SelectField, {
  SelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'

export type LocationSelectFieldProps = {
  additionalName?: string
} & SelectFieldProps &
  LocationsQuery

export const LocationSelectField = ({
  locations,
  name,
  additionalName,
}: LocationSelectFieldProps) => {
  const formContext = useFormContext()
  const primaryLocationId = formContext.watch(name)
  const secondaryLocations = locations.filter(
    (loc) => loc.id !== primaryLocationId
  )
  return (
    <>
      <FieldLabel name={name} required={true}>
        Primary Location
      </FieldLabel>
      <SelectField
        name={name}
        data-testid="location-primary-select-field"
        emptyAs={null}
        required={true}
        options={[
          ...locations.map((location) => {
            return {
              value: location.id,
              display: location.name,
            }
          }),
        ]}
      />

      {additionalName && (
        <>
          <FieldLabel name={additionalName} required={false}>
            Additional Locations
          </FieldLabel>
          <SelectField
            name={additionalName}
            data-testid="location-additional-select-field"
            includeEmptyOption={false}
            options={[
              ...secondaryLocations.map((location) => {
                return {
                  value: location.id,
                  display: location.name,
                }
              }),
            ]}
            multiple
          />
        </>
      )}
    </>
  )
}

export default LocationSelectField
