import { DocumentTypesConfig } from 'common/cdr/concepts/documents'
import type { PracticeLetterType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const practiceLetterTypeDisplay: {
  [key in PracticeLetterType]: string
} = {
  ...DocumentTypesConfig['PRACTICE_LETTER']['subTypes'],
}

export const practiceLetterTypes = nameValueArrayFromObject<PracticeLetterType>(
  practiceLetterTypeDisplay
)
