import type { CreatePracticeLetterInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import FileInput from 'src/components/atoms/FileInput/FileInput'
import InputField from 'src/components/atoms/InputField'
import PracticeLetterTypeSelectField from 'src/components/atoms/PracticeLetterTypeSelectField/PracticeLetterTypeSelectField'
import FormInputList, {
  Item,
} from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

const CREATE_PRACTICE_LETTER_MUTATION = gql`
  mutation CreatePracticeLetter($input: CreatePracticeLetterInput!) {
    createPracticeLetter(input: $input) {
      id
    }
  }
`

export const practiceLetterInputListItems: (opts?: {
  type?: string
  patientId?: string
  isDocumentCase?: boolean
}) => Item[] = () => [
  {
    name: 'type',
    label: 'Document sub-type',
    required: true,
    formInputComponent: PracticeLetterTypeSelectField,
  },
  {
    name: 'title',
    label: 'Document title',
    formInputComponent: InputField,
    required: false,
  },
]

const CreatePracticeLetter = () => {
  const { closeSidePanel } = useSidepanel()
  const [createPracticeLetter, { loading: creatingPracticeLetter }] =
    useMutation(CREATE_PRACTICE_LETTER_MUTATION, {
      onCompleted: () => {
        toast.success('Practice letter created')
        closeSidePanel()
      },
      refetchQueries: ['PracticeLettersQuery'],
    })
  const formMethods = useForm<CreatePracticeLetterInput>()

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Upload document',
        submitting: creatingPracticeLetter,
      }}
      formMethods={formMethods}
      onSubmit={async (value) => {
        const { binary: binaryInput, ...input } = value

        input['binary'] = {
          content: await base64EncodeFile(binaryInput[0]),
          contentType: binaryInput[0].type,
        }
        createPracticeLetter({ variables: { input } })
      }}
    >
      <FormInputList
        items={[
          ...practiceLetterInputListItems(),
          {
            name: 'binary',
            label: 'Document upload',
            required: true,
            formInputComponent: FileInput,
          },
        ]}
      />
    </SidepanelForm>
  )
}

export default CreatePracticeLetter
