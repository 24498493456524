import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import Button, { Submit } from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'

import ChangePasswordFormInputList from '../ChangePasswordFormInputList/ChangePasswordFormInputList'

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      id
    }
  }
`

interface ChangePasswordFormProps {
  onCancel?: () => void
  onSuccess?: () => void
  hideCancel?: boolean
  hideCurrentPassword?: boolean
  disableSubmit?: boolean
}

export const ChangePasswordForm = ({
  onCancel = () => {},
  onSuccess = () => {},
  hideCancel = false,
  hideCurrentPassword = false,
  disableSubmit = false,
}: ChangePasswordFormProps) => {
  const [changePassword, { loading: changingPassword }] = useMutation(
    CHANGE_PASSWORD_MUTATION,
    {
      onCompleted: () => {
        toast.success('Password Changed')
        onSuccess()
      },
    }
  )

  const onSubmit = async (data) => {
    delete data.confirmPassword
    changePassword({ variables: { input: data } })
  }

  const formMethods = useForm()

  return (
    <Form
      onSubmit={onSubmit}
      config={{ mode: 'onBlur' }}
      formMethods={formMethods}
    >
      <ChangePasswordFormInputList
        name="newPassword"
        hideCurrentPassword={hideCurrentPassword}
      />

      <StackView direction="row" justifyContent="end" space={75}>
        {!hideCancel && (
          <Button buttonStyle="secondary" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Submit
          disabled={disableSubmit || changingPassword}
          loading={changingPassword}
        >
          Submit
        </Submit>
      </StackView>
    </Form>
  )
}

export default ChangePasswordForm
