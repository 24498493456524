import { ReactNode, ReactElement } from 'react'

import clsx from 'clsx'

import Box, { BoxProps } from '../Box/Box'
import Typography from '../Typography/Typography'

interface CardProps extends BoxProps {
  footer?: ReactNode
  header?: ReactNode
  title?: string
  children?: ReactNode
  className?: string
  contentClassName?: string
  contentPadding?: boolean
  footerClassName?: string
  hideHeaderBorder?: boolean
}

function Card({
  footer,
  header,
  title,
  children,
  className,
  contentClassName,
  footerClassName,
  hideHeaderBorder,
  contentPadding = false,
  ...rest
}: CardProps): ReactElement {
  const classes = [
    'rounded-base-border-radius-container-l',
    'bg-base-color-bg-default',
    'border-base-border-width-container-s',
    'border-base-color-border-subtle',
    hideHeaderBorder ? '' : 'divide-y divide-base-color-border-subtle',
    className,
  ]
  const headerSpacingClasses =
    'px-core-space-100 py-core-space-100 sm:px-core-space-150'
  return (
    <Box className={clsx(classes)} {...rest}>
      {header || title ? (
        <div className={headerSpacingClasses}>
          {header || <Typography textStyle="title">{title}</Typography>}
        </div>
      ) : null}
      <div
        className={clsx(
          { [headerSpacingClasses]: contentPadding },
          contentClassName
        )}
      >
        {children}
      </div>
      {footer ? (
        <div
          className={clsx(
            'px-core-space-100 py-core-space-100 sm:px-core-space-150',
            footerClassName
          )}
        >
          {footer}
        </div>
      ) : null}
    </Box>
  )
}

export default Card
