import { GetPatientRefund, GetPaymentPlanRefund } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { CANDID_PATIENT_PAYMENT_FRAGMENT } from '../usePaymentTransactions/usePaymentTransactions'

const PATIENT_REFUND_QUERY = gql`
  query GetPatientRefund($develoCandidPatientRefundId: String!) {
    develoCandidPatientRefund(
      develoCandidPatientRefundId: $develoCandidPatientRefundId
    ) {
      id
      amountCents
      candidPatientPayment {
        ...CandidPatientPaymentFragment
      }
      createdAt
      paymentTransactionId
      paymentTransaction {
        createdByUser {
          id
          givenName
          familyName
          userType {
            id
            name
          }
        }
      }
      status
    }
  }
  ${CANDID_PATIENT_PAYMENT_FRAGMENT}
`

const PAYMENT_PLAN_REFUND_QUERY = gql`
  query GetPaymentPlanRefund($id: String!) {
    paymentPlanRefund(id: $id) {
      id
      amountCents
      createdAt
      paymentPlanPayment {
        id
        paymentMethod {
          id
          brand
          last4
        }
      }
      status
    }
  }
`

export const usePatientRefundQuery = ({
  develoCandidPatientRefundId,
}: {
  develoCandidPatientRefundId: string
}) => {
  const { data, ...rest } = useQuery<GetPatientRefund>(PATIENT_REFUND_QUERY, {
    variables: { develoCandidPatientRefundId },
  })

  return {
    refund: data?.develoCandidPatientRefund,
    ...rest,
  }
}

export const usePaymentPlanRefundQuery = ({
  paymentPlanRefundId,
}: {
  paymentPlanRefundId: string
}) => {
  const { data, ...rest } = useQuery<GetPaymentPlanRefund>(
    PAYMENT_PLAN_REFUND_QUERY,
    {
      variables: { id: paymentPlanRefundId },
    }
  )

  return {
    paymentPlanRefund: data?.paymentPlanRefund,
    ...rest,
  }
}
