import { DropdownField } from 'src/components/atoms/Dropdown/Dropdown'
import InputField, {
  type InputFieldProps,
} from 'src/components/atoms/InputField/InputField'
import { Option } from 'src/components/atoms/Select/Select'

export type InputDropdownProps = {
  dropdownName?: string
  options: Option[]
} & InputFieldProps

const InputDropdown = ({
  name,
  type,
  dropdownName,
  options,
  ...rest
}: InputDropdownProps) => {
  return (
    <div className="relative mt-1 rounded-md shadow-sm">
      <InputField
        type={type ?? 'text'}
        name={name}
        id={name}
        className="block w-full rounded-md border-gray-300 pr-12 arrow-hide focus:border-success focus:ring-success sm:text-sm"
        placeholder="1"
        {...rest}
      />
      <div className="absolute inset-y-0 right-0 flex items-center">
        <DropdownField
          options={options}
          name={dropdownName ?? `${name}Option`}
          className="w-26 rounded-bl-none rounded-tl-none border-y-0 border-r-0"
          validation={{
            shouldUnregister: rest.validation?.shouldUnregister,
          }}
        />
      </div>
    </div>
  )
}

export default InputDropdown
