import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import type {
  FindRelatedPersonByPatientId,
  FindRelatedPersonByPatientIdVariables,
} from 'types/graphql'

import { RegisterOptions } from '@redwoodjs/forms'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import SelectField, { SelectFieldProps } from 'src/components/atoms/SelectField'

export const QUERY = gql`
  query FindRelatedPersonByPatientId($patientId: String!) {
    patient: patient(id: $patientId) {
      id
      patientRelatedPersonRelationships {
        id
        relationshipType
        relatedPerson {
          id
          namePrefix
          givenName
          familyName
        }
      }
    }
  }
`

interface SuccessProps
  extends CellSuccessProps<
    FindRelatedPersonByPatientId,
    FindRelatedPersonByPatientIdVariables
  > {
  name: string
  defaultValue?: string
  validation?: RegisterOptions
}

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindRelatedPersonByPatientIdVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  patient,
  name,
  defaultValue,
  validation,
  emptyAs = null,
}: SuccessProps & SelectFieldProps) => {
  return (
    <SelectField
      name={name}
      defaultValue={defaultValue}
      validation={validation}
      options={patient.patientRelatedPersonRelationships.map(
        (relationship) => ({
          value: relationship.relatedPerson.id,
          display: `${formatDisplayName(relationship.relatedPerson)} (${
            relationshipTypeDisplay[relationship.relationshipType]
          })`,
        })
      )}
      emptyAs={emptyAs}
    />
  )
}
