import {
  GetPatientActiveInsuranceCoverages,
  GetPatientNoInsurance,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

const GET_PATIENT_ACTIVE_INSURANCE_COVERAGES = gql`
  query GetPatientActiveInsuranceCoverages($id: String!) {
    patient: patient(id: $id) {
      id
      insuranceOptOutStatus
      activeInsuranceCoverages {
        id
        status
        coordinationOfBenefitsType
        planType
        planName
        statusVerifiedAt
        beneficiaryVerifiedAt
        subscriberVerifiedAt
        planInformationVerifiedAt
        planWebsite
        payer {
          id
          displayName
        }
        mostRecentEligibility {
          id
          status
        }
        binary {
          contentType
          url
        }
        beneficiary {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
          birthDate
        }
        subscriber {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
          birthDate
          patientRelatedPersonRelationships {
            id
            relationshipType
          }
        }
        payerAddress {
          id
          purpose
          line1
          line2
          city
          country
          district
          postalCode
          state
          zipPlusFour
        }
        planMemberIdentifier
        beneficiaryIsSubscriber
      }
    }
  }
`
const GET_PATIENT_NO_INSURANCE_QUERY = gql`
  query GetPatientNoInsurance($id: String!) {
    patient: patient(id: $id) {
      id
      insuranceOptOutStatus
      insuranceOptedOutAt
      insuranceOptOutVerifiedAt
      activeInsuranceCoverages {
        id
        coordinationOfBenefitsType
      }
    }
  }
`
export const useActiveInsuranceCoveragesQuery = (patientId: string) => {
  const { data, ...rest } = useQuery<GetPatientActiveInsuranceCoverages>(
    GET_PATIENT_ACTIVE_INSURANCE_COVERAGES,
    {
      variables: { id: patientId },
    }
  )

  return {
    patient: data?.patient,
    activeInsuranceCoverages: data?.patient?.activeInsuranceCoverages ?? [],
    insuranceOptOutStatus: data?.patient?.insuranceOptOutStatus,
    ...rest,
  }
}

export const useNoInsuranceQuery = (patientId: string) => {
  const { data, ...rest } = useQuery<GetPatientNoInsurance>(
    GET_PATIENT_NO_INSURANCE_QUERY,
    {
      variables: { id: patientId },
    }
  )

  return {
    patient: data?.patient,
    ...rest,
  }
}
