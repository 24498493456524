import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { UpsertMenstrualHistoryInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box'
import InputField from 'src/components/atoms/InputField/InputField'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import { MenstrualClotPresenceIndicatorSelectField } from 'src/components/atoms/MenstrualClotPresenceIndicatorSelectField/MenstrualClotPresenceIndicatorSelectField'
import MenstrualFlowSelectField from 'src/components/atoms/MenstrualFlowSelectField/MenstrualFlowSelectField'
import MenstrualPainSelectField from 'src/components/atoms/MenstrualPainSelectField/MenstrualPainSelectField'
import MenstrualRegularitySelectField from 'src/components/atoms/MenstrualRegularitySelectField/MenstrualRegularitySelectField'
import { DatePickerField } from 'src/components/molecules/DatePicker'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { InputFieldWithUnit } from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { deepOmit } from 'src/data/utils'
import { formatDateFieldValue } from 'src/lib/formatters'
import {
  useMenstrualHistoryQuery,
  useUpsertMenstrualHistory,
} from 'src/pages/PatientChartsPage/PatientHistory/MenstrualHistory/useMenstrualHistory'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const SidepanelMenstrualHistoryEdit = () => {
  const [existingFormValuesLoaded, setExistingFormValuesLoaded] =
    useState(false)
  const { patientId } = useParams()
  const { closeSidePanel } = useSidepanel()

  const { menstrualHistoryFormValues } = useMenstrualHistoryQuery(patientId)
  const [upsertMenstrualHistory, { loading: upserting }] =
    useUpsertMenstrualHistory()

  const onSubmit = async (data: UpsertMenstrualHistoryInput) => {
    const dataWithoutFrontendFields: Partial<UpsertMenstrualHistoryInput> =
      deepOmit(data, ['valueType', 'display', 'unit'])

    const input = {
      patientId,
      ...dataWithoutFrontendFields,
    }

    upsertMenstrualHistory({
      variables: { input },
      onCompleted: closeSidePanel,
    })
  }

  const formMethods = useForm<UpsertMenstrualHistoryInput>({
    defaultValues: menstrualHistoryFormValues,
  })

  useEffect(() => {
    if (!existingFormValuesLoaded) {
      formMethods.reset(menstrualHistoryFormValues)
      setExistingFormValuesLoaded(true)
    }
  }, [menstrualHistoryFormValues, formMethods, existingFormValuesLoaded])

  if (!existingFormValuesLoaded)
    return (
      <Box className="flex h-screen items-center justify-center">
        <LoadingSpinner size="m" />
      </Box>
    )
  return (
    <SidepanelPage
      testId={'patient-menstrual-history-edit'}
      header="Edit menstrual history"
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Update',
          submitting: upserting,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <FormInputList
          items={[
            {
              name: 'ageAtMenarche.a',
              label: 'Menarche',
              direction: 'col',
              formInputComponent: InputFieldWithUnit,
              inputProps: {
                unit: 'a',
              },
            },
            {
              name: 'lastMenstrualPeriodDate',
              label: 'Last menstrual period (LMP) date ',
              direction: 'col',
              formInputComponent: DatePickerField,
              inputProps: {
                validation: {
                  setValueAs: (value) => formatDateFieldValue(value) || null,
                },
              },
            },
            {
              name: 'lastMenstrualPeriodDuration.d',
              label: 'Last menstrual period (LMP) duration',
              direction: 'col',
              formInputComponent: InputFieldWithUnit,
              inputProps: {
                unit: 'd',
              },
            },
            {
              name: 'regularity',
              label: 'Regularity',
              direction: 'col',
              formInputComponent: MenstrualRegularitySelectField,
            },
            {
              name: 'menstrualFlow',
              label: 'Flow',
              direction: 'col',
              formInputComponent: MenstrualFlowSelectField,
            },
            {
              name: 'numberOfPadsUsed',
              label: 'Average number of pads per day since period onset',
              direction: 'col',
              formInputComponent: InputField,
              inputProps: {
                type: 'number',
              },
            },
            {
              name: 'numberOfTamponsUsed',
              label: 'Average number of tampons per day since period onset',
              direction: 'col',
              formInputComponent: InputField,
              inputProps: {
                type: 'number',
              },
            },
            {
              name: 'clotsPresent',
              label: 'Clots present',
              direction: 'col',
              formInputComponent: MenstrualClotPresenceIndicatorSelectField,
            },
            {
              name: 'menstrualPain',
              label: 'Pain',
              direction: 'col',
              formInputComponent: MenstrualPainSelectField,
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelMenstrualHistoryEdit
