import type {
  FindPatientEducationDocumentQuery,
  FindPatientEducationDocumentQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { patientEducationDocumentTypeDisplay } from 'src/data/patientEducationDocumentTypes'

import SidepanelDocumentPage from '../organisms/SidepanelDocumentPage/SidepanelDocumentPage'

export const QUERY = gql`
  query FindPatientEducationDocumentQuery($id: String!) {
    patientEducationDocument: patientEducationDocument(id: $id) {
      id
      isGlobal
      updatedAt
      updatedBy {
        id
        givenName
        familyName
      }
      type
      title
      binary {
        id
        content {
          url
          contentType
        }
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindPatientEducationDocumentQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  patientEducationDocument,
}: CellSuccessProps<
  FindPatientEducationDocumentQuery,
  FindPatientEducationDocumentQueryVariables
>) => {
  return (
    <SidepanelDocumentPage
      header={`Patient education: ${
        patientEducationDocumentTypeDisplay[patientEducationDocument.type]
      }`}
      document={patientEducationDocument}
      testId="patient-education-document-view"
      faxable={!patientEducationDocument.isGlobal}
      editRoute={
        patientEducationDocument.isGlobal
          ? undefined
          : `/documents/patient-education/${patientEducationDocument.id}/edit`
      }
    />
  )
}
