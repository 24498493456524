import { useParams } from 'react-router-dom'

import PracticeLetterEditCell from 'src/components/PracticeLetterEditCell'

const SidepanelPracticeLetterEdit = () => {
  const { practiceLetterId } = useParams()

  return <PracticeLetterEditCell id={practiceLetterId} />
}

export default SidepanelPracticeLetterEdit
