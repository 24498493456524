import { ResendFax, ResendFaxVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

const RESEND_FAX = gql`
  mutation ResendFax($id: String!) {
    resendFax(id: $id) {
      id
      status
    }
  }
`
export const useResendFax = (faxId: string) => {
  return useMutation<ResendFax, ResendFaxVariables>(RESEND_FAX, {
    variables: { id: faxId },
    refetchQueries: ['PaginatedOutboundFaxesQuery'],
  })
}
