import { ContactOrganizationsQuery } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import InputField from 'src/components/atoms/InputField'
import StackView from 'src/components/atoms/StackView'

import ContactOrganizationsTable from '../ContactOrganizationsTable/ContactOrganizationsTable'

export const CONTACT_FRAGMENT = gql`
  fragment ContactFragment on Contact {
    id
    updatedAt
    name
    faxNumber
    homeNumber
    mobileNumber
    workNumber
    emailAddress
    isPrimary
    contactOrganizationId
    useOrganizationAddress
    address {
      id
      line1
      line2
      city
      postalCode
      state
      country
    }
  }
`

export const QUERY = gql`
  query ContactOrganizationsQuery {
    contactOrganizations {
      id
      name
      type
      contactCount
      primaryContact {
        ...ContactFragment
      }
      contacts {
        ...ContactFragment
      }
    }
  }
  ${CONTACT_FRAGMENT}
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  contactOrganizations,
}: CellSuccessProps<ContactOrganizationsQuery>) => {
  const formMethods = useForm()

  const searchInput = formMethods.watch('search')
  return (
    <StackView space={75} className="h-full">
      <Form formMethods={formMethods} className="w-80">
        <InputField name="search" placeholder="Search by name or fax number" />
      </Form>
      <ContactOrganizationsTable
        contactOrganizations={contactOrganizations}
        searchInput={searchInput}
      />
    </StackView>
  )
}
