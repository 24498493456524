import { forwardRef } from 'react'

import clsx from 'clsx'

import { AvatarGroup, AvatarGroupProps } from '../atoms/Avatar/Avatar'
import StackView from '../atoms/StackView/StackView'
import {
  StatusIndicator,
  StatusIndicatorColor,
} from '../StatusIndicator/StatusIndicator'

export type ChipLeftContent =
  | {
      type: 'icon'
      icon: React.FunctionComponent<React.ComponentProps<'svg'>>
      iconColor?: string
    }
  | {
      type: 'status'
      color: StatusIndicatorColor
    }
  | {
      type: 'avatars'
      avatars: AvatarGroupProps['avatars']
    }
  | {
      type: 'element'
      element: React.ReactNode
    }

export interface ChipProps {
  text: string
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  leftContent?: ChipLeftContent
  testId?: string
}

const className = clsx([
  'flex',
  'items-center',
  'w-fit',
  'bg-comp-button-color-secondary-enabled-bg',
  'shadow-base-box-shadow-selectable-s',
  'whitespace-nowrap',

  'hover:bg-comp-button-color-secondary-hover-bg',
  'hover:text-comp-button-color-secondary-hover-fg',
  'hover:border-comp-button-color-secondary-hover-border',

  'focus:bg-comp-button-color-secondary-focused-bg',
  'focus:text-comp-button-color-secondary-focused-fg',
  'focus:border-comp-button-color-secondary-focused-border',

  'active:bg-comp-button-color-secondary-pressed-bg',
  'active:text-comp-button-color-secondary-pressed-fg',
  'active:border-comp-button-color-secondary-pressed-border',

  'text-comp-button-color-secondary-enabled-fg',
  'font-base-typography-interface-default-xs-font-family',
  'text-base-typography-interface-default-xs-font-size',
  'font-base-typography-interface-default-xs-font-weight',
  'leading-base-typography-interface-default-xs-line-height',

  'disabled:pointer-events-none',
  'focus:shadow-base-box-shadow-general-focus-border',
  'disabled:opacity-base-opacity-disabled',

  'rounded-base-border-radius-selectable-s',
  'border-base-border-width-selectable-s',
  'border-comp-button-color-secondary-enabled-border',
  'px-core-space-50',
  'h-core-space-150',
])

const renderLeftContent = (content: ChipLeftContent): React.ReactNode => {
  if (content.type === 'icon') {
    const Icon = content.icon

    return (
      <Icon
        className={clsx([
          'h-base-size-icon-xs w-base-size-icon-xs',
          content.iconColor,
        ])}
      />
    )
  }

  if (content.type === 'status') {
    return <StatusIndicator color={content.color} />
  }

  if (content.type === 'avatars') {
    return <AvatarGroup maxItems={4} size="xs" avatars={content.avatars} />
  }

  if (content.type === 'element') {
    return content.element
  }
}

const ChipContent = ({
  text,
  leftContent,
}: Pick<ChipProps, 'text' | 'leftContent'>) => (
  <StackView gap={25} direction="row" alignItems="center">
    {leftContent ? renderLeftContent(leftContent) : null}
    {text}
  </StackView>
)

export const Chip = forwardRef<HTMLButtonElement, ChipProps>(
  ({ text, disabled, onClick, leftContent, testId }, ref) => {
    if (!onClick) {
      return (
        <div className={className} data-testid={testId}>
          <ChipContent text={text} leftContent={leftContent} />
        </div>
      )
    }

    return (
      <button
        ref={ref}
        disabled={disabled}
        className={className}
        onClick={(e) => onClick(e)}
        data-testid={testId}
      >
        <ChipContent text={text} leftContent={leftContent} />
      </button>
    )
  }
)

export interface NavChipProps {
  to: string
  text: string
  leftContent?: ChipLeftContent
  testId?: string
  newTab?: boolean
}

export const NavChip = ({
  to,
  text,
  leftContent,
  testId,
  newTab,
}: NavChipProps) => {
  return (
    <a
      className={className}
      data-testid={testId}
      href={to}
      target={newTab ? '_blank' : undefined}
      rel={newTab ? 'noopener noreferrer' : undefined}
    >
      <ChipContent text={text} leftContent={leftContent} />
    </a>
  )
}
