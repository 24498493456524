import type {
  FindPatientTaskListQuery,
  FindPatientTaskListQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import StackView from 'src/components/atoms/StackView/StackView'

import LoadingSpinner from '../atoms/LoadingSpinner/LoadingSpinner'
import EmptyDataContent from '../molecules/EmptyDataContent/EmptyDataContent'

import { PatientTaskListItem } from './PatientTaskListItem'

export const QUERY = gql`
  query FindPatientTaskListQuery($filters: CaseFilterInput!) {
    patientTaskList: cases(filters: $filters) {
      id
      createdAt
      updatedAt
      assignedUser {
        id
        givenName
        familyName
      }
      assignedTaskUserPoolId
      assignedTaskUserPool {
        id
        displayName
        users {
          id
          givenName
          familyName
          userType {
            id
            name
          }
        }
      }
      createdBy {
        id
        familyName
        givenName
      }
      dueAt
      schedulingCase {
        id
        code
        resolvedAt
        resolvedStatus
        resolvedBy {
          id
          givenName
          familyName
        }
        appointmentDefinitions {
          id
          name
          duration
          code
          canBeSelfScheduled
        }
        schedulingCaseNotifications {
          id
          outboundTextMessage {
            id
            finishedAt
          }
        }
      }
      documentCase {
        id
        code
        resolvedAt
        resolvedStatus
        resolvedBy {
          id
          givenName
          familyName
        }
      }
      screeningCase {
        id
        code
        resolvedAt
        resolvedStatus
        resolvedBy {
          id
          givenName
          familyName
        }
        reason
        order {
          questionnaire
        }
      }
      resultsReviewCase {
        id
        code
        resolvedAt
        resolvedStatus
        resolvedBy {
          id
          givenName
          familyName
        }
      }
      diagnosticReportReviewCase {
        id
        code
        resolvedAt
        resolvedStatus
        resolvedBy {
          id
          givenName
          familyName
        }
      }
      staffMessageCase {
        id
        code
        resolvedAt
        resolvedStatus
        resolvedBy {
          id
          givenName
          familyName
        }
      }
      familyMessageCase {
        id
        code
        resolvedAt
        resolvedStatus
        resolvedBy {
          id
          givenName
          familyName
        }
      }
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => (
  <EmptyDataContent message="The patient does not have any tasks matching the configured filters." />
)

export const Failure = ({
  error,
}: CellFailureProps<FindPatientTaskListQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({
  patientTaskList,
  queryResult,
}: CellSuccessProps<
  FindPatientTaskListQuery,
  FindPatientTaskListQueryVariables
>) => {
  const filters = queryResult?.variables?.filters

  return (
    <StackView direction="col" space={50}>
      {patientTaskList.map((task) => (
        <PatientTaskListItem key={task.id} task={task} filters={filters} />
      ))}
    </StackView>
  )
}
