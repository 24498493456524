import { PlusIcon } from '@heroicons/react/24/solid'
import { CreateInsuranceEligibilityInputForInsuranceCoverage } from 'types/graphql'

import { Form, useForm, useFieldArray } from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import ButtonFooter from 'src/components/molecules/ButtonFooter/ButtonFooter'
import { EditComponentProps } from 'src/components/molecules/DataDisplayCard/DataDisplayCard'
import {
  useCreateInsuranceEligibilityForInsuranceCoverage,
  useCreateInsuranceEligibilityForPatientRegistrationIntent,
} from 'src/hooks/useCreateInsuranceEligibility/useCreateInsuranceEligibility'
import { useEffectOnce } from 'src/utils'

import InsuranceCoverageEligibilityCategoryInput from './InsuranceCoverageEligibilityCategoryInput'

export const CreateInsuranceEligibility: React.FC<
  EditComponentProps & {
    insuranceId?: string
    patientRegistrationIntentId?: string
    appointmentId?: string
  }
> = ({
  insuranceId,
  patientRegistrationIntentId,
  appointmentId,
  onCancel,
  onComplete,
}) => {
  if (!insuranceId && !patientRegistrationIntentId) {
    throw new Error(
      'must provide either an insuranceId or patientRegistrationIntentId'
    )
  }
  if (insuranceId && patientRegistrationIntentId) {
    throw new Error(
      'cannot provide both an insuranceId and a patientRegistrationIntentId'
    )
  }

  const [
    createInsuranceEligibilityForInsuranceCoverage,
    { loading: creatingInsuranceEligibilityForInsuranceCoverage },
  ] = useCreateInsuranceEligibilityForInsuranceCoverage({
    afterCompleted: onComplete,
  })
  const [
    createInsuranceEligibilityForPatientRegistrationIntent,
    { loading: creatingInsuranceEligibilityForPatientRegistrationIntent },
  ] = useCreateInsuranceEligibilityForPatientRegistrationIntent({
    afterCompleted: onComplete,
  })

  const onSubmit = async (
    data: CreateInsuranceEligibilityInputForInsuranceCoverage
  ) => {
    const createInsuranceEligibility = insuranceId
      ? createInsuranceEligibilityForInsuranceCoverage
      : createInsuranceEligibilityForPatientRegistrationIntent
    const id = insuranceId ?? patientRegistrationIntentId

    await createInsuranceEligibility({
      variables: {
        id,
        input: { appointmentId: appointmentId, ...data },
        appointmentId,
      },
    })
  }

  const formMethods =
    useForm<CreateInsuranceEligibilityInputForInsuranceCoverage>()
  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: 'insuranceCoverageEligibilityCategories',
  })
  useEffectOnce(() => {
    append({
      inNetworkIndicator: 'NOT_APPLICABLE_OR_NOT_INDICATED',
      planStatus: 'ACTIVE_COVERAGE',
      deductibleTimeQualifierCode: 'SERVICE_YEAR',
    })
  })

  return (
    <Form
      data-testid="create-insurance-eligiblilty-form"
      formMethods={formMethods}
      onSubmit={onSubmit}
    >
      <StackView divider>
        {fields.map((item, index) => {
          return (
            <InsuranceCoverageEligibilityCategoryInput
              key={item.id}
              prefix={`insuranceCoverageEligibilityCategories.${index}`}
              item={item}
              removeSelf={() => remove(index)}
            />
          )
        })}
        <Button
          icon={PlusIcon}
          buttonStyle="secondary"
          text="Eligibility section"
          onClick={() =>
            append({
              inNetworkIndicator: null,
              planStatus: 'ACTIVE_COVERAGE',
              deductibleTimeQualifierCode: 'SERVICE_YEAR',
            })
          }
          className="mb-3"
        />
      </StackView>
      <Box inverse verticalPadding={100} horizontalPadding={100}>
        <ButtonFooter
          submitText="Save eligibility"
          secondaryButton={{
            text: 'Cancel',
            onClick: onCancel,
            buttonStyle: 'secondary',
            position: 'right',
          }}
          submitting={
            creatingInsuranceEligibilityForInsuranceCoverage ||
            creatingInsuranceEligibilityForPatientRegistrationIntent
          }
        />
      </Box>
    </Form>
  )
}

export default CreateInsuranceEligibility
