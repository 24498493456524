import { CodeNode } from '@lexical/code'
import { createHeadlessEditor } from '@lexical/headless'
import { $generateHtmlFromNodes } from '@lexical/html'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { $isAtNodeEnd } from '@lexical/selection'
import { initialLexicalEditorStateValue } from 'common/lexical/lexical'

import wysiwygTheme from '../themes/theme'

export const getSelectedNode = (selection) => {
  const anchor = selection.anchor
  const focus = selection.focus
  const anchorNode = selection.anchor.getNode()
  const focusNode = selection.focus.getNode()
  if (anchorNode === focusNode) {
    return anchorNode
  }
  const isBackward = selection.isBackward()
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode
  }
}

export const lexicalConfig = {
  theme: wysiwygTheme,
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    AutoLinkNode,
    LinkNode,
  ],
}

export const lexicalToHTML = async (lexical: string): Promise<string> => {
  const editor = createHeadlessEditor({
    ...lexicalConfig,
    namespace: 'headless-editor',
  })

  editor.setEditorState(editor.parseEditorState(lexical))

  return new Promise<string>((resolve) => {
    return editor.update(() => {
      const html = $generateHtmlFromNodes(editor)
      // We need to reset the editor so that future editors do not have the value that was used here
      editor.setEditorState(
        editor.parseEditorState(JSON.stringify(initialLexicalEditorStateValue))
      )
      resolve(html)
    })
  })
}
