import { formatDisplayName, formatMoneyInCents } from 'common/utils'
import { format, parseISO } from 'date-fns'
import { match } from 'ts-pattern'
import type { GetPatientPortalPaymentTransaction } from 'types/graphql'

import Box from 'src/components/atoms/Box'
import Divider from 'src/components/atoms/Divider'
import StackView from 'src/components/atoms/StackView'
import Typography, { TypographyColor } from 'src/components/atoms/Typography'
import {
  getPaymentNoteDescription,
  getPaymentNoteName,
} from 'src/components/PatientFinancials/components/HistoricalPatientPaymentsTable'

import { LetterHead, SubHeading } from './common'

type CandidPayment =
  GetPatientPortalPaymentTransaction['patientPortalPaymentTransaction']['candidPatientPayments'][number]
type PaymentPlanPayment =
  GetPatientPortalPaymentTransaction['patientPortalPaymentTransaction']['paymentPlanPayments'][number]
type CandidRefund =
  GetPatientPortalPaymentTransaction['patientPortalPaymentTransaction']['candidPatientRefunds'][number]
type PaymentPlanRefund =
  GetPatientPortalPaymentTransaction['patientPortalPaymentTransaction']['paymentPlanRefunds'][number]

type ColumnId = 'description' | 'patient' | 'amount'

const TableHeaderCell = ({
  text,
  colId,
}: {
  text: string
  lastColumn?: boolean
  colId: ColumnId
}) => {
  const textDirection = match(colId)
    .with('amount', () => 'text-right')
    .otherwise(() => 'text-left')

  const width = match(colId)
    .with('description', () => 'w-8/12')
    .with('patient', () => 'w-2/12')
    .with('amount', () => 'w-2/12')
    .exhaustive()

  return (
    <StackView className={width} fullWidth={false}>
      <Typography
        textStyle="interface-strong-xs"
        color="text-base-color-fg-muted"
        className={textDirection}
      >
        {text}
      </Typography>
    </StackView>
  )
}

const TableRowCell = ({
  text,
  subtext,
  color = 'text-base-color-fg-muted',
  testId,
  bold,
  colId,
}: {
  text: string
  subtext?: string
  color?: TypographyColor
  testId?: string
  bold?: boolean
  colId: ColumnId
}) => {
  const textDirection = match(colId)
    .with('amount', () => 'text-right')
    .otherwise(() => 'text-left')

  const width = match(colId)
    .with('description', () => 'w-8/12')
    .with('patient', () => 'w-2/12')
    .with('amount', () => 'w-2/12')
    .exhaustive()

  return (
    <StackView className={width} data-testid={testId} fullWidth={false}>
      <Typography
        textStyle={bold ? 'interface-strong-xs' : 'interface-default-xs'}
        color={color}
        className={textDirection}
      >
        {text}
      </Typography>
      {subtext && (
        <Typography
          textStyle="interface-default-xs"
          color="text-base-color-fg-subtle"
          className={textDirection}
        >
          {subtext}
        </Typography>
      )}
    </StackView>
  )
}

const TablePaymentRow = ({
  candidPayment,
  paymentPlanPayment,
}: {
  candidPayment?: CandidPayment
  paymentPlanPayment?: PaymentPlanPayment
}) => {
  const payment = candidPayment ?? paymentPlanPayment

  return (
    <StackView
      direction="row"
      space={75}
      className="p-core-space-75"
      justifyContent="between"
      alignItems="center"
    >
      {match(payment.__typename)
        .with('CandidPayment', () => (
          <TableRowCell
            colId="description"
            text={getPaymentNoteName(payment as CandidPayment)}
            subtext={getPaymentNoteDescription(payment as CandidPayment)}
          />
        ))
        .with('PaymentPlanPayment', () => (
          <TableRowCell colId="description" text={'Payment plan payment'} />
        ))
        .exhaustive()}
      <TableRowCell colId="patient" text={formatDisplayName(payment.patient)} />
      <TableRowCell
        colId="amount"
        text={formatMoneyInCents(payment.amountCents)}
      />
    </StackView>
  )
}

const TableRefundRow = ({
  candidRefund,
  paymentPlanRefund,
}: {
  candidRefund?: CandidRefund
  paymentPlanRefund?: PaymentPlanRefund
}) => {
  const refund = candidRefund ?? paymentPlanRefund

  return (
    <StackView
      direction="row"
      space={75}
      className="p-core-space-75"
      justifyContent="between"
      alignItems="center"
    >
      {match(refund.__typename)
        .with('DeveloCandidPatientRefund', () => (
          <TableRowCell
            colId="description"
            text={
              refund.__typename === 'DeveloCandidPatientRefund' &&
              refund.candidPatientPayment
                ? `Refund for ${getPaymentNoteName(
                    refund.candidPatientPayment as CandidPayment
                  )}`
                : 'Refund'
            }
          />
        ))
        .with('PaymentPlanRefund', () => (
          <TableRowCell colId="description" text={'Payment plan refund'} />
        ))
        .exhaustive()}
      <TableRowCell colId="patient" text={formatDisplayName(refund.patient)} />
      <TableRowCell
        colId="amount"
        text={formatMoneyInCents(-refund.amountCents)}
      />
    </StackView>
  )
}

const PrintPaymentTransactionReceipt = ({
  paymentTransaction,
  testId,
}: {
  paymentTransaction: GetPatientPortalPaymentTransaction['patientPortalPaymentTransaction']
  testId?: string
}) => {
  if (!paymentTransaction) return null
  return (
    <StackView space={150} data-testid={testId}>
      <LetterHead tenant={paymentTransaction.tenant} />
      <Box
        color="bg-base-color-bg-subtle"
        padding={50}
        data-testid="patient-details"
      >
        <StackView
          direction="row"
          justifyContent="between"
          space={50}
          className="pb-core-space-50"
        >
          <StackView>
            <SubHeading>Payment date and time</SubHeading>
            <Typography textStyle="interface-default-xs">
              {format(
                parseISO(paymentTransaction.createdAt),
                "MMM d, yyyy 'at' h:mm a"
              )}
            </Typography>
          </StackView>
          <StackView>
            <SubHeading>Receipt generated at</SubHeading>
            <Typography textStyle="interface-default-xs">
              {format(new Date(), "MMM d, yyyy 'at' h:mm a")}
            </Typography>
          </StackView>
        </StackView>
      </Box>
      <StackView space={50}>
        <Typography textStyle="title-l">Payment receipt</Typography>
      </StackView>
      <Box rounded border data-testid="visit-section">
        <Box
          color="bg-base-color-bg-subtle"
          horizontalPadding={75}
          verticalPadding={50}
        >
          <StackView
            direction="row"
            space={75}
            className="bg-base-color-bg-subtle p-core-space-75"
            justifyContent="between"
            alignItems="center"
          >
            <TableHeaderCell colId="description" text="Description" />
            <TableHeaderCell colId="patient" text="Patient" />
            <TableHeaderCell colId="amount" text="Amount" lastColumn />
          </StackView>
        </Box>
        <Divider />
        <Box horizontalPadding={75} verticalPadding={50}>
          {paymentTransaction.candidPatientPayments.map((payment) => (
            <TablePaymentRow candidPayment={payment} key={payment.id} />
          ))}
          {paymentTransaction.candidPatientRefunds.map((refund) => (
            <TableRefundRow candidRefund={refund} key={refund.id} />
          ))}
          {paymentTransaction.paymentPlanPayments.map((payment) => (
            <TablePaymentRow paymentPlanPayment={payment} key={payment.id} />
          ))}
          {paymentTransaction.paymentPlanRefunds.map((refund) => (
            <TableRefundRow paymentPlanRefund={refund} key={refund.id} />
          ))}
        </Box>
        <Divider />
        <Box
          color="bg-base-color-bg-subtle"
          horizontalPadding={75}
          verticalPadding={50}
        >
          <StackView
            direction="row"
            space={75}
            className="bg-base-color-bg-subtle p-core-space-75"
            justifyContent="between"
            alignItems="center"
          >
            <TableRowCell colId="description" text="Total" bold />
            <TableRowCell
              colId="amount"
              text={formatMoneyInCents(paymentTransaction.amountCents)}
              bold
            />
          </StackView>
        </Box>
      </Box>
    </StackView>
  )
}

export default PrintPaymentTransactionReceipt
