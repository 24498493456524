import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { formatDisplayName } from 'common/utils'
import {
  PatientRelatedPersonRelationshipWithFullDetailsFragment,
  SiblingDemographicsFragment,
} from 'types/graphql'

import Badge from 'src/components/atoms/Badge'
import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button/Button'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { AccordionItem } from 'src/components/molecules/Accordion/Accordion'
import { SiblingCaregiverInfo } from 'src/components/organisms/PatientSiblings/SiblingCaregiverInfo'
import { sexDisplay } from 'src/data/sexes'
import { usePatientOutstandingBalance } from 'src/hooks/usePatientPayments/usePatientPayments'
import { formatDateDisplay } from 'src/lib/formatters'
import { openNewTab } from 'src/utils'

export const SIBLING_DEMOGRAPHICS_FRAGMENT = gql`
  fragment SiblingDemographicsFragment on Patient {
    id
    active
    mrn
    givenName
    middleName
    familyName
    preferredName
    sexAtBirth
    birthDate
    patientRelatedPersonRelationships {
      id
      isGuarantor
      guardianshipType
      relationshipType
      relatedPersonId
      relatedPerson {
        id
        givenName
        middleName
        familyName
        preferredName
      }
      patientId
      patient {
        id
        mrn
        id
        givenName
        middleName
        familyName
        preferredName
        sexAtBirth
        birthDate
      }
    }
  }
`

const SiblingDemographics = ({
  sibling,
}: {
  sibling: SiblingDemographicsFragment
}) => {
  const { totalOutstandingBalanceCents, loading: loadingBalance } =
    usePatientOutstandingBalance({ patientId: sibling.id })

  const hasBalance = !!totalOutstandingBalanceCents
  const isCredit = hasBalance && totalOutstandingBalanceCents < 0
  const absoluteBalance = Math.abs(totalOutstandingBalanceCents || 0)
  const absoluteBalanceDollars = `$${(absoluteBalance / 100).toFixed(2)}`
  const balanceText = isCredit
    ? `${absoluteBalanceDollars} credit`
    : `${absoluteBalanceDollars} balance`
  return (
    <StackView
      className="mr-core-space-100"
      direction="row"
      justifyContent="between"
      alignItems="center"
      gap={50}
    >
      <StackView direction="col">
        <Typography textStyle="interface-strong-s">
          {sibling.preferredName
            ? `${formatDisplayName(sibling)} (${sibling.preferredName})`
            : formatDisplayName(sibling)}
        </Typography>
        <StackView direction="row" space={50} alignItems="center">
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {sexDisplay[sibling.sexAtBirth]}
          </Typography>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            &bull;
          </Typography>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {formatDateDisplay(sibling.birthDate)}
          </Typography>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            &bull;
          </Typography>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {sibling.mrn}
          </Typography>
        </StackView>
      </StackView>
      {loadingBalance ? (
        <LoadingSpinner size="xs" />
      ) : (
        hasBalance && (
          <Badge
            color={isCredit ? 'green' : 'red'}
            size="l"
            testId="sibling-outstanding-balance-badge"
            text={balanceText}
          />
        )
      )}
      {sibling.active ? (
        <Badge
          color="dark-gray"
          size="l"
          testId="sibling-active-patient-badge"
          text="Active"
          showDot={true}
        />
      ) : (
        <Badge
          color="red"
          size="l"
          testId="sibling-inactive-patient-badge"
          text="Inactive"
          showDot={true}
        />
      )}

      <Button
        buttonSize="m"
        icon={ArrowTopRightOnSquareIcon}
        iconColor="text-base-color-fg-muted"
        buttonStyle="link"
        onClick={() => openNewTab(`/patient-charts/${sibling.id}/visits`)}
      />
    </StackView>
  )
}

export const SiblingInfoCard = ({
  sibling,
  sharedRelationships,
}: {
  sibling: SiblingDemographicsFragment
  sharedRelationships?: PatientRelatedPersonRelationshipWithFullDetailsFragment[]
}) => {
  return (
    <AccordionItem title={<SiblingDemographics sibling={sibling} />} bordered>
      <Box border className="rounded-b p-core-space-75">
        <StackView className="rounded-b" space={25}>
          {sibling.patientRelatedPersonRelationships.map((relationship) => (
            <SiblingCaregiverInfo
              key={relationship.id}
              siblingCaregiverRelationship={relationship}
              sharesWithPatient={(sharedRelationships ?? []).some(
                (sharedRelationship) =>
                  sharedRelationship.relatedPersonId ===
                  relationship.relatedPersonId
              )}
            />
          ))}
        </StackView>
      </Box>
    </AccordionItem>
  )
}
