import { useEffect, useState } from 'react'

import { PauseIcon, PlayIcon, StopIcon } from '@heroicons/react/20/solid'
import { PlayCircleIcon } from '@heroicons/react/24/outline'
import { MicrophoneIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { LiveAudioVisualizer } from 'react-audio-visualize'
import { FetchVisitAudioRecordings } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import useAudioRecorder from 'src/hooks/useAudioRecorder/useAudioRecorder'
import { formatTime } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'
import { base64EncodeFile, usePersistedState } from 'src/utils'

import { VISIT_AUDIO_RECORDINGS_FRAGMENT } from './fragments'
import { useVisitQuery } from './useVisit'

const RECORD_VISIT_AUDIO_MUTATION = gql`
  mutation RecordVisitAudio(
    $encounterId: String!
    $binary: CreateBinaryInput!
  ) {
    recordVisitAudio(encounterId: $encounterId, binary: $binary) {
      id
      ...VisitAudioRecordingsFragment
    }
  }
  ${VISIT_AUDIO_RECORDINGS_FRAGMENT}
`

export const VISIT_AUDIO_RECORDINGS_QUERY = gql`
  query FetchVisitAudioRecordings($id: String!) {
    appointment(id: $id) {
      id
      encounter {
        id
        ...VisitAudioRecordingsFragment
      }
    }
  }
  ${VISIT_AUDIO_RECORDINGS_FRAGMENT}
`

const VisitAudioRecorder = ({ appointmentId }) => {
  const location = useLocation()
  const params = useParams()
  const { visit, refetch: refetchVisit } = useVisitQuery(appointmentId)
  const [isPolling, setIsPolling] = usePersistedState(
    `visit-audio-recorder-${appointmentId}`,
    false
  )
  const { data, startPolling, stopPolling, loading } =
    useQuery<FetchVisitAudioRecordings>(VISIT_AUDIO_RECORDINGS_QUERY, {
      variables: { id: appointmentId },
    })
  const [recordVisitAudio, { loading: transcribing }] = useMutation(
    RECORD_VISIT_AUDIO_MUTATION,
    {
      onCompleted: () => {
        toast.success('Visit audio recorded, transcribing...')
      },
    }
  )
  const onRecordingComplete = async (recordingBlob: Blob) => {
    setIsPolling(true)
    const variables = {
      encounterId: visit.encounter.id,
      binary: {
        filename: 'visit-audio',
        contentType: 'audio/webm',
        content: await base64EncodeFile(recordingBlob),
      },
    }
    await recordVisitAudio({ variables })
  }

  useEffect(() => {
    if (isPolling) {
      startPolling(500)
    } else {
      stopPolling()
      refetchVisit()
    }
  }, [isPolling, refetchVisit, startPolling, stopPolling])

  if (
    isPolling &&
    !loading &&
    data &&
    data.appointment.encounter.visitAudioRecordings.length &&
    data.appointment.encounter.visitAudioRecordings.slice(-1)[0].notes.length >
      1
  ) {
    setIsPolling(false)
    toast.success('Visit documentation updated')
    navigate(
      sidepanelRoute(
        {
          route: `/appointments/${appointmentId}/visitAudioRecordings`,
        },
        location,
        params
      )
    )
  }

  if (!visit?.encounter) {
    return null
  }

  return (
    <StackView
      direction="row"
      space={25}
      justifyContent="center"
      alignItems="center"
    >
      {isPolling ? (
        <Button
          buttonStyle="ghost"
          iconColor="text-base-color-fg-brand"
          text="Transcribing"
          className="bg-purple-100 !py-1.5 text-primary hover:bg-purple-200 hover:text-primary"
          tooltip="Transcribing audio and generating documentation.."
          tooltipPosition="bottom"
          loading
          disabled
        />
      ) : (
        <AudioRecorder onRecordingComplete={onRecordingComplete} />
      )}
      {transcribing ? (
        <LoadingSpinner />
      ) : visit.encounter.visitAudioRecordings.length > 0 ? (
        <Button
          buttonStyle="ghost"
          className="!px-1 !py-1.5"
          text={visit.encounter.visitAudioRecordings.length.toString()}
          icon={PlayCircleIcon}
          tooltip="View recordings and transcriptions"
          tooltipPosition="bottom"
          onClick={() => {
            navigate(
              sidepanelRoute(
                {
                  route: `/appointments/${appointmentId}/visitAudioRecordings`,
                },
                location,
                params
              )
            )
          }}
        />
      ) : null}
    </StackView>
  )
}

const AudioRecorder = ({ onRecordingComplete }) => {
  const [shouldSave, setShouldSave] = useState(false)

  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder({
    noiseSuppression: true,
    echoCancellation: true,
  })

  const stopAudioRecorder = () => {
    setShouldSave(true)
    stopRecording()
  }

  if (!isRecording && recordingBlob != null && shouldSave) {
    setShouldSave(false)
    onRecordingComplete(recordingBlob)
  }

  if (isRecording) {
    return (
      <StackView
        direction="row"
        space={25}
        className="!items-center !justify-center rounded-md bg-purple-100 px-3 py-1.5 text-primary"
      >
        <Box
          className={clsx(
            'flex h-6 w-6 items-center justify-center rounded-full hover:cursor-pointer hover:bg-purple-300',
            !isPaused && 'bg-purple-200'
          )}
          onClick={stopAudioRecorder}
        >
          <StopIcon className="h-3 w-3" />
        </Box>
        <Box
          className={clsx(
            'flex h-6 w-6 items-center justify-center rounded-full hover:cursor-pointer hover:bg-purple-300',
            isPaused && 'bg-purple-200 '
          )}
          onClick={togglePauseResume}
        >
          {isPaused ? (
            <PlayIcon className="h-3 w-3" />
          ) : (
            <PauseIcon className="h-3 w-3" />
          )}
        </Box>
        {mediaRecorder && (
          <LiveAudioVisualizer
            mediaRecorder={mediaRecorder}
            barColor="rgb(100, 67, 232)"
            barWidth={1}
            gap={25}
            width={36}
            height={20}
            fftSize={512}
            maxDecibels={-10}
            minDecibels={-80}
            smoothingTimeConstant={0.4}
          />
        )}
        <Typography>{formatTime(recordingTime)}</Typography>
      </StackView>
    )
  }

  return (
    <Button
      buttonStyle="ghost"
      icon={MicrophoneIcon}
      iconColor="text-base-color-fg-brand"
      text="Record"
      className="bg-purple-100 !py-1.5 text-primary hover:bg-purple-200 hover:text-primary"
      onClick={startRecording}
      tooltip="Record visit audio"
      tooltipPosition="bottom"
    />
  )
}

export default VisitAudioRecorder
