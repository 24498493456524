import { useParams } from 'react-router-dom'

import { useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import AvailabilityExceptionForm from 'src/components/AvailabilityException/AvailabilityExceptionForm/AvailabilityExceptionForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const QUERY = gql`
  query GetGroupedAvailabilityException($referenceId: String!) {
    groupedAvailabilityException(referenceId: $referenceId) {
      id
      date
      practitionerId
      updatedAt
      configuration
      updatedAt
      locationIds
      locations {
        name
        id
        description
      }
    }
  }
`

const SidepanelPracticeScheduleEditOverride = () => {
  const { referenceId } = useParams()
  const { closeSidePanel } = useSidepanel()

  const onClose = () => {
    toast.success('Clinic override updated')
    closeSidePanel()
  }

  const { data, loading } = useQuery(QUERY, { variables: { referenceId } })

  return (
    <SidepanelPage
      header="Edit clinic override"
      description="Update this schedule override to make one-time and/or repeat modfications to clinic availability."
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <AvailabilityExceptionForm
          variant="sidepanel"
          onClose={onClose}
          groupedAvailabilityException={data.groupedAvailabilityException}
        />
      )}
    </SidepanelPage>
  )
}

export default SidepanelPracticeScheduleEditOverride
