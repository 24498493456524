import type { SchedulingCaseResolutionStatus } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const schedulingCaseStatusDisplay: {
  [key in SchedulingCaseResolutionStatus]: string
} = {
  OPEN: 'Open',
  CANCELLED: 'Cancelled',
  RESOLVED: 'Resolved',
}

export const schedulingCaseStatusType =
  nameValueArrayFromObject<SchedulingCaseResolutionStatus>(
    schedulingCaseStatusDisplay
  )
