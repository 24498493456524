// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { match } from 'ts-pattern'

import { Route, Router, Set } from '@redwoodjs/router'

import { useAuth, useEmrAuth } from 'src/auth'
import AuthPageLayout from 'src/layouts/AuthPageLayout/AuthPageLayout'
import ConstrainedPageLayout from 'src/layouts/ConstrainedPageLayout/ConstrainedPageLayout'
import FullPageLayout from 'src/layouts/FullPageLayout/FullPageLayout'
import { PatientPortalLargeWidthContent } from 'src/layouts/PatientPortalLayout/PatientPortalLargeWidthContent'
import { PatientPortalLayout } from 'src/layouts/PatientPortalLayout/PatientPortalLayout'
import { PatientPortalMediumWidthContent } from 'src/layouts/PatientPortalLayout/PatientPortalMediumWidthContent'
import PrimaryLayout from 'src/layouts/PrimaryLayout/PrimaryLayout'
import SettingsLayout from 'src/layouts/SettingsLayout/SettingsLayout'
import WideConstrainedPageLayout from 'src/layouts/WideConstrainedPageLayout/WideConstrainedPageLayout'
import { PatientSelfCheckInContextProvider } from 'src/pages/PatientSelfCheckIn/ContextProvider'
import PatientSelfSchedulingLayout from 'src/pages/PatientSelfScheduling/PatientSelfSchedulingLayout'
import { AppointmentManagementContextProvider } from 'src/providers/context/AppointmentsManagementContext'
import { FullScreenContextProvider } from 'src/providers/context/FullScreenContext'
import { PatientSelfRegistrationProvider } from 'src/providers/context/PatientSelfRegistrationContext/PatientSelfRegistrationContext'
import { SidepanelProvider } from 'src/providers/context/SidepanelContext'
import { WeeklyScheduleContextProvider } from 'src/providers/context/WeeklyScheduleContext'
import { appType } from 'src/utils/appType'

import { PatientPortalProvider } from './providers/context/PatientPortalContext/PatientPortalContext'
import { PrivatePatientPortalRoute } from './wrappers/PrivatePatientPortalRoute/PrivatePatientPortalRoute'
import { PrivatePaymentLinkRoute } from './wrappers/PrivatePaymentLinkRoute/PrivatePaymentLinkRoute'
import PrivateRoles from './wrappers/PrivateRoles/PrivateRoles'
import PrivateStatus from './wrappers/PrivateStatus/PrivateStatus'
import { PrivateWebLinkRoute } from './wrappers/PrivateWebLinkRoute/PrivateWebLinkRoute'

const Routes = () => {
  const { currentUser } = useEmrAuth()

  const externalPayerUserTypesEnabled = currentUser?.featureFlags?.includes('EXTERNAL_PAYER_USER_TYPES')

  return match(appType)
    .with('emr', () => {
      return (
        <Router useAuth={useAuth}>
          <Route path="/feature-flag-disabled" page={FeatureFlagDisabledPage} name="featureFlagDisabled" />
          <Route path="/lab-order-callback/{encounterId:String}" page={LabOrderCallbackPage} name="labOrderCallbackWithEncounterId" />
          <Route path="/lab-order-callback" page={LabOrderCallbackPage} name="labOrderCallback" />
          <Set wrap={SidepanelProvider}>
            <Set wrap={AuthPageLayout}>
              <Route path="/reset-password" page={ResetPasswordPage} name="resetPassword" />
              <Route path="/login" page={LoginPage} name="login" />
            </Set>

            {/* CLAIMED */}
            <Set wrap={PrivateStatus} status="CLAIMED" unauthenticated="login">
              <Set wrap={[PrimaryLayout, ConstrainedPageLayout]}>
                <Route path="/onboarding" page={OnboardingPage} name="onboarding" />
              </Set>
            </Set>

            {/* ACTIVE */}
            <Set wrap={PrivateStatus} status="ACTIVE" unauthenticated="login">
              <Set wrap={AppointmentManagementContextProvider}>
                <Set wrap={PrimaryLayout}>
                  <Set wrap={FullPageLayout}>
                    <Route path="/" page={HomePage} name="home" />
                    <Route path="/schedule" page={SchedulePage} name="schedule" />
                    <Route path="/patients" page={PatientsPage} name="patients" />
                    <Set wrap={PrivateRoles} roles={externalPayerUserTypesEnabled ? ['VIEW_FINANCIALS'] : []}>
                      <Route path="/financials" page={PracticeFinancialsPage} name="financials" />
                    </Set>
                    <Set wrap={PrivateRoles} roles={externalPayerUserTypesEnabled ? ['VIEW_MEDICATIONS'] : []}>
                      <Route path="/medications" page={MedicationsPage} name="medications" />
                    </Set>
                    <Route path="/patient-charts/{id:String}" page={PatientChartsPage} name="patientCharts" />
                    <Route path="/patient-charts/{id:String}/{glob...}" page={PatientChartsPage} name="patientChartsGlob" />
                    <Set wrap={FullScreenContextProvider}>
                      <Route path="/cases/{glob...}" page={CasesPage} name="cases" />
                    </Set>
                    <Route path="/open-orders" page={OpenOrdersPage} name="openOrders" />
                    <Route path="/open-visits" page={OpenVisitsPage} name="openVisits" />
                    <Set wrap={PrivateRoles} roles={externalPayerUserTypesEnabled ? ['REPORTS'] : []}>
                      <Route path="/reports/{reportType}" page={ReportPage} name="report" />
                    </Set>
                  </Set>
                  <Set wrap={ConstrainedPageLayout}>
                    <Route path="/holidays-update" page={HolidaysUpdatePage} name="holidaysUpdate" />
                    <Route path="/appointments/{id:String}/check-in/{glob...}" page={AppointmentCheckInPage} name="appointmentCheckInGlob" />
                    <Route path="/appointments/{id:String}/check-in" page={AppointmentCheckInPage} name="appointmentCheckIn" />
                    <Route path="/patients/new/{glob...}" page={PatientRegistrationPage} name="patientRegistrationGlob" />
                    <Route path="/patients/new" page={PatientRegistrationPage} name="patientRegistration" />
                    <Set wrap={PrivateRoles} roles={externalPayerUserTypesEnabled ? ['REPORTS'] : []}>
                      <Route path="/reports" page={ReportsPage} name="reports" />
                    </Set>
                  </Set>
                </Set>
                <Set wrap={SettingsLayout}>
                  <Set wrap={PrivateRoles} roles={externalPayerUserTypesEnabled ? ['VIEW_TENANT_SETTINGS'] : []}>
                    <Set wrap={FullPageLayout}>
                      <Route path="/visit-template/{id:String}" page={VisitTemplatePage} name="visitTemplate" />
                      <Route path="/visit-template/{id:String}/{glob...}" page={VisitTemplatePage} name="visitTemplateGlob" />
                    </Set>
                    <Set wrap={WideConstrainedPageLayout}>
                      <Route path="/users/new" page={UserNewUserPage} name="newUser" />
                      <Route path="/users/{id:String}" page={UserUserPage} name="user" />
                      <Route path="/users/{id:String}/edit" page={UserEditUserPage} name="editUser" />
                      <Route path="/staff" page={UserUsersPage} name="staff" />
                      <Route path="/resources" page={ResourcesPage} name="resources" />
                      <Route path="/resources/{glob...}" page={ResourcesPage} name="resourcesGlob" />
                      <Route path="/contacts" page={ContactsPage} name="contacts" />
                      <Route path="/outbound-fax" page={OutboundFaxPage} name="outboundFax" />
                      <Route path="/vaccine-inventory" page={VaccineInventoryManagementPage} name="vaccineInventoryManagement" />
                      <Route path="/macro-phrases" page={MacroPhrasesPage} name="macroPhrases" />
                      <Route path="/macro-phrases/{glob...}" page={MacroPhrasesPage} name="macroPhrasesGlob" />
                      <Route path="/practitioner-schedule" page={PractitionerSchedulePage} name="practitionerSchedule" />
                      <Route path="/settings/tasks" page={TaskSettingsPage} name="taskSettings" />
                      <Route path="/settings/family-portal/{userType:String}" page={FamilyPortalAdminPage} name="familyPortalAdmin" />
                      <Route path="/settings/billing" page={BillingSettingsPage} name="billingSettings" />
                      <Route path="/settings/orders" page={OrderSettingsRootPage} name="orderSettingsRoot" />
                      <Route path="/settings/orders/{category:String}" page={OrderSettingsPage} name="orderSettings" />
                    </Set>
                    <Set wrap={ConstrainedPageLayout}>
                      <Route path="/practice-visit-types" page={PracticeVisitTypesPage} name="practiceVisitTypes" />
                      <Route path="/practice-schedule" page={PracticeSchedulePage} name="practiceSchedule" />
                      <Route path="/practice-schedule/edit" page={PracticeScheduleEditPage} name="practiceScheduleEdit" />
                      <Set wrap={WeeklyScheduleContextProvider}>
                        <Route path="/md-weekly-schedule" page={MDWeeklySchedulePage} name="mdWeeklySchedule" />
                      </Set>
                    </Set>
                  </Set>
                  <Set wrap={ConstrainedPageLayout}>
                    <Route path="/profile" page={UserProfilePage} name="profile" />
                    <Route path="/visit-templates" page={VisitTemplatesPage} name="visitTemplates" />
                    <Route path="/charge-capture" page={ChargeCaptureSettingsPage} name="chargeCapture" />
                  </Set>
                  <Route path="/404" page={NotFoundPage} name="notFound" />
                  <Route notfound page={NotFoundPage} />
                </Set>
              </Set>
            </Set>
          </Set>
        </Router>
      )
    })
    .with('patientPortal', () => {
      return (
        <Router useAuth={useAuth}>
          <Set wrap={PatientPortalProvider}>
            <Route path="/{tenantPortalKey:String}/login" page={PatientPortalLoginPage} name="patientPortalLogin" />

            <Set wrap={PrivatePatientPortalRoute}>
              <Set wrap={PatientPortalLayout}>
                <Set wrap={PatientPortalLargeWidthContent}>
                  <Route path="/{tenantPortalKey:String}" page={PatientPortalHomePage} name="patientPortalHome" />
                  <Route path="/{tenantPortalKey:String}/payments" page={PatientPortalPaymentsPage} name="patientPortalPayments" />
                  <Route path="/{tenantPortalKey:String}/make-payment" page={PatientPortalMakePaymentPage} name="patientPortalMakePayment" />
                  <Route path="/{tenantPortalKey:String}/payment-success" page={PatientPortalPaymentSuccessPage} name="patientPortalPaymentSuccess" />
                </Set>
                <Set wrap={PatientPortalMediumWidthContent}>
                  <Route path="/{tenantPortalKey:String}/messages" page={PatientPortalMessageListPage} name="patientPortalMessageList" />
                  <Route path="/{tenantPortalKey:String}/messages/new" page={PatientPortalNewMessagePage} name="patientPortalNewMessage" />
                  <Route path="/{tenantPortalKey:String}/messages/{familyMessageCaseId:String}" page={PatientPortalMessageDetailsPage} name="patientPortalMessageDetails" />
                  <Route path="/{tenantPortalKey:String}/medical-records" page={PatientPortalMedicalRecordsRootPage} name="patientPortalMedicalRecordsRoot" />
                  <Route path="/{tenantPortalKey:String}/medical-records/{patientId:String}" page={PatientPortalMedicalRecordsPage} name="patientPortalMedicalRecords" />
                </Set>
              </Set>
            </Set>
          </Set>

          <Route path="/patient-web-link/{notificationId:String}/login" page={PatientWebLinkLoginPage} name="patientWebLinkLogin" />
          <Route path="/confirm-visit/{notificationId:String}" page={PatientDashboardChooserPage} name="patientDashboardChooser" />
          <Route path="/patient-screening/{notificationId:String}/questionnaire/{questionnaireId:String}/{glob...}" page={PatientScreeningPage} name="patientScreening" />
          <Route path="/payments/{notificationId:String}/{glob...}" page={PatientPaymentsPage} name="patientPayment" />
          <Set wrap={PatientSelfRegistrationProvider}>
            <Route path="/patient-self-registration/{notificationId:String}" page={PatientSelfRegistrationRootPage} name="patientSelfRegistrationRoot" />
            <Route path="/patient-self-registration/{notificationId:String}/intro" page={PatientSelfRegistrationIntroPage} name="patientSelfRegistrationIntro" />
            <Route path="/patient-self-registration/{notificationId:String}/form/{step:String}" page={PatientSelfRegistrationFormsPage} name="patientSelfRegistrationForms" />
            <Route path="/patient-self-registration/{notificationId:String}/sign-practice-forms" page={PatientSelfRegistrationSignPracticeFormsPage} name="patientSelfRegistrationSignPracticeForms" />
            <Route path="/patient-self-registration/{notificationId:String}/success" page={PatientSelfRegistrationSuccessPage} name="patientSelfRegistrationSuccess" />
          </Set>
          <Set wrap={PatientSelfCheckInContextProvider}>
            <Route path="/patient-self-check-in/{notificationId:String}" page={PatientSelfCheckInRootPage} name="patientSelfCheckInRoot" />
            <Route path="/patient-self-check-in/{notificationId:String}/intro" page={PatientSelfCheckInIntroPage} name="patientSelfCheckInIntro" />
            <Set wrap={PrivateWebLinkRoute}>
              <Route path="/patient-self-check-in/{notificationId:String}/caregivers" page={PatientSelfCheckInCaregiversPage} name="patientSelfCheckInCaregivers" />
              <Route path="/patient-self-check-in/{notificationId:String}/caregiver/{relatedPersonId:String}/edit/personal-details" page={PatientSelfCheckInCaregiverEditPersonalDetailsPage} name="patientSelfCheckInCaregiverEditPersonalDetails" />
              <Route path="/patient-self-check-in/{notificationId:String}/caregiver/{relatedPersonId:String}/edit/contact-information" page={PatientSelfCheckInCaregiverEditContactInformationPage} name="patientSelfCheckInCaregiverEditContactInformation" />
              <Route path="/patient-self-check-in/{notificationId:String}/caregiver/{relatedPersonId:String}/edit/address" page={PatientSelfCheckInCaregiverEditAddressPage} name="patientSelfCheckInCaregiverEditAddress" />
              <Route path="/patient-self-check-in/{notificationId:String}/caregiver/{relatedPersonId:String}/edit/additional-details" page={PatientSelfCheckInCaregiverEditAdditionalDetailsPage} name="patientSelfCheckInCaregiverEditAdditionalDetails" />
              <Route path="/patient-self-check-in/{notificationId:String}/caregiver/new/{step:String}" page={PatientSelfCheckInNewCaregiverPage} name="patientSelfCheckInNewCaregiver" />
              <Route path="/patient-self-check-in/{notificationId:String}/patient" page={PatientSelfCheckInPatientPage} name="patientSelfCheckInPatient" />
              <Route path="/patient-self-check-in/{notificationId:String}/patient/edit/personal-details" page={PatientSelfCheckInPatientEditPersonalDetailsPage} name="patientSelfCheckInPatientEditPersonalDetails" />
              <Route path="/patient-self-check-in/{notificationId:String}/patient/edit/contact-information" page={PatientSelfCheckInPatientEditContactInformationPage} name="patientSelfCheckInPatientEditContactInformation" />
              <Route path="/patient-self-check-in/{notificationId:String}/patient/edit/address" page={PatientSelfCheckInPatientEditAddressPage} name="patientSelfCheckInPatientEditAddress" />
              <Route path="/patient-self-check-in/{notificationId:String}/patient/edit/additional-details" page={PatientSelfCheckInPatientEditAdditionalDetailsPage} name="patientSelfCheckInPatientEditAdditionalDetails" />
              <Route path="/patient-self-check-in/{notificationId:String}/insurance" page={PatientSelfCheckInInsurancePage} name="patientSelfCheckInInsurance" />
              <Route path="/patient-self-check-in/{notificationId:String}/insurance/{insuranceCoverageId:String}/edit/policyholder" page={PatientSelfCheckInInsuranceEditPolicyholderPage} name="patientSelfCheckInInsuranceEditPolicyholder" />
              <Route path="/patient-self-check-in/{notificationId:String}/insurance/{insuranceCoverageId:String}/edit/plan-details" page={PatientSelfCheckInInsuranceEditPlanDetailsPage} name="patientSelfCheckInInsuranceEditPlanDetails" />
              <Route path="/patient-self-check-in/{notificationId:String}/insurance/{insuranceCoverageId:String}/edit/card" page={PatientSelfCheckInInsuranceEditCardPage} name="patientSelfCheckInInsuranceEditCard" />
              <Route path="/patient-self-check-in/{notificationId:String}/insurance/new/{step:String}" page={PatientSelfCheckInNewInsurancePage} name="patientSelfCheckInNewInsurance" />
              <Route path="/patient-self-check-in/{notificationId:String}/sign-practice-forms" page={PatientSelfCheckInSignPracticeFormsPage} name="patientSelfCheckInSignPracticeForms" />
              <Route path="/patient-self-check-in/{notificationId:String}/success" page={PatientSelfCheckInSuccessPage} name="patientSelfCheckInSuccess" />
            </Set>
          </Set>
          <Set wrap={PatientSelfSchedulingLayout}>
            <Route path="/patient-self-scheduling/{notificationId:String}" page={PatientSelfSchedulingRootPage} name="patientSelfSchedulingRoot" />
            <Route path="/patient-self-scheduling/{notificationId:String}/intro" page={PatientSelfSchedulingIntroPage} name="patientSelfSchedulingIntro" />
            <Route path="/patient-self-scheduling/{notificationId:String}/select-time" page={PatientSelfSchedulingSelectTimePage} name="patientSelfSchedulingSelectTime" />
            <Route path="/patient-self-scheduling/{notificationId:String}/confirmed" page={PatientSelfSchedulingConfirmedPage} name="patientSelfSchedulingConfirmed" />
          </Set>

          <Route path="/patient-payments/{notificationId:String}/login" page={PaymentLinkLoginPage} name="paymentLinkLoginPage" />
          <Set wrap={PrivatePaymentLinkRoute}>
            <Route path="/patient-payments/{notificationId:String}/billing-statement" page={PatientBillingStatementPage} name="patientBillingStatement" />
          </Set>
        </Router>
      )
    })
    .exhaustive()
}

export default Routes
