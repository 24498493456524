import compact from 'lodash/compact'
import omit from 'lodash/omit'
import {
  BillingCode,
  ClinicalOrderInput,
  DiagnosisCode,
  OrderCategory,
  OrderFragment,
  PerformerContactInput,
  SchedulingOrderInput,
  UpdateClinicalOrderInput,
  UpdateSchedulingOrderInput,
} from 'types/graphql'

import { EmrCurrentUser } from 'src/lib/auth'

import { ProviderPreference } from './buildFormInputList'

export const buildPerformerContactInput = (
  providerPreference: ProviderPreference,
  orderInput: ClinicalOrderInput | UpdateClinicalOrderInput
): string | null => {
  if (
    providerPreference === 'any' ||
    providerPreference === 'existingContact'
  ) {
    return null
  } else if (providerPreference === 'custom') {
    return orderInput.performer
  }
}

const buildExistingContactPerformerContactInput = (
  providerPreference: ProviderPreference,
  existingContactPerformer: (
    | ClinicalOrderInput
    | UpdateClinicalOrderInput
  )['existingContactPerformer']
): PerformerContactInput | null => {
  if (providerPreference !== 'existingContact') return null
  const { display, contactId } = existingContactPerformer
  return { display, contactId }
}

const buildSubmitInput = ({
  order,
  category,
  encounterId,
  patientId,
  orderInput,
  schedulingOrderInput,
  currentUser,
}: {
  order?: OrderFragment
  category: Exclude<OrderCategory, 'HG_LAB'>
  encounterId?: string
  patientId?: string
  orderInput?: (ClinicalOrderInput | UpdateClinicalOrderInput) & {
    providerPreference?: ProviderPreference
    diagnosisCodes: {
      code: DiagnosisCode
    }[]
    cptCode: { code: Omit<BillingCode, 'chargeItems' | 'group'> }
  }
  schedulingOrderInput?: SchedulingOrderInput | UpdateSchedulingOrderInput
  currentUser?: EmrCurrentUser
}) => {
  let input

  // when order is defined, we are updating an existing order
  if (order) {
    if (category === 'SCH') {
      input = {
        orderId: order.id,
        encounterId,
        schedulingOrderInput: {
          ...schedulingOrderInput,
          chiefComplaints: schedulingOrderInput.chiefComplaints.filter(Boolean),
        },
        intent: currentUser.roles.includes('PRACTITIONER')
          ? 'ORDER'
          : undefined,
      }
    } else {
      const { providerPreference, diagnosisCodes, ...data } = orderInput

      input = {
        orderId: order.id,
        encounterId,
        orderInput: {
          ...omit(data, 'cptCode', 'cptCodeSearchFilter', 'cptCodeSearchInput'),
          flatDiagnosisCodes: compact(
            diagnosisCodes?.map(({ code }) => code?.code) ?? []
          ),
          performer: buildPerformerContactInput(providerPreference, orderInput),
          existingContactPerformer: buildExistingContactPerformerContactInput(
            providerPreference,
            orderInput.existingContactPerformer
          ),
        },
        intent: currentUser.roles.includes('PRACTITIONER')
          ? 'ORDER'
          : undefined,
      }
    }
  } else {
    if (category === 'SCH') {
      input = {
        patientId,
        encounterId,
        category,
        schedulingOrderInput: {
          ...schedulingOrderInput,
          chiefComplaints: schedulingOrderInput.chiefComplaints.filter(Boolean),
          patientId,
          intent: currentUser.roles.includes('PRACTITIONER')
            ? 'ORDER'
            : 'PROPOSAL',
        },
      }
    } else {
      const {
        existingContactPerformer,
        providerPreference,
        diagnosisCodes,
        ...data
      } = orderInput

      input = {
        patientId,
        encounterId,
        category,
        orderInput: {
          ...omit(
            data,
            'cptCode',
            'cptCodeSearchFilter',
            'cptCodeSearchInput',
            'existingContactPerformer',
            'existingContactPerformerDisplayText'
          ),
          cptCode: data.cptCode?.code,
          flatDiagnosisCodes: compact(
            diagnosisCodes?.map(({ code }) => code?.code) ?? []
          ),
          status: 'ACTIVE',
          intent: currentUser.roles.includes('PRACTITIONER')
            ? 'ORDER'
            : 'PROPOSAL',
          performer: providerPreference !== 'any' ? orderInput.performer : null,
          existingContactPerformer: buildExistingContactPerformerContactInput(
            providerPreference,
            existingContactPerformer
          ),
        },
      }
    }
  }
  return input
}

export default buildSubmitInput
