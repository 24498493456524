import {
  FetchAppointmentNotification,
  FetchAppointmentNotificationVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

const QUERY = gql`
  query FetchAppointmentNotification($id: String!) {
    appointmentConfirmation(id: $id) {
      id
      status
      start
      tenantId
      patientRegistrationIntentId
      patientName
      patientCheckInCompletedAt
      locationName
      locationAddress
      locationPhoneNumber
      practiceName
      practiceWebsiteUrl
      confirmedAt
      appointmentTypes
      practitionerName
      patientId
      notificationSentTo {
        patientId
        patientLegalStatus
        relatedPersonId
        name
        relationshipToPatient
        guardianshipType
      }
      requiredPracticeForms {
        id
        practiceFormDefinition {
          id
          type
        }
        binary {
          content {
            url
            contentType
          }
        }
      }
    }
  }
`

export const useAppointmentConfirmation = ({
  notificationId,
}: {
  notificationId: string
}) =>
  useQuery<FetchAppointmentNotification, FetchAppointmentNotificationVariables>(
    QUERY,
    {
      variables: { id: notificationId },
    }
  )
