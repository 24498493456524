import { useParams } from 'react-router-dom'

import PracticeFormEditCell from 'src/components/PracticeFormEditCell'

const SidepanelPracticeFormEdit = () => {
  const { practiceFormId } = useParams()

  return <PracticeFormEditCell id={practiceFormId} />
}

export default SidepanelPracticeFormEdit
