import { useContext } from 'react'

import { useParams } from 'react-router-dom'
import {
  GetPatientFinancialInfo,
  GetPatientFinancialInfoVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import { createNamedContext } from 'src/utils'

const QUERY = gql`
  query GetPatientFinancialInfo($patientId: String!) {
    patient(id: $patientId) {
      id
      namePrefix
      nameSuffix
      givenName
      middleName
      familyName
      contactInformationId
      primaryGuarantorId
      portalUserStatus
      patientRelatedPersonRelationships {
        id
        isGuarantor
        relatedPerson {
          id
          namePrefix
          nameSuffix
          givenName
          middleName
          familyName
          portalUserStatus
        }
      }
      latestPaymentNotification {
        id
        outboundTextMessage {
          id
          createdAt
        }
      }
      currentPaymentPlan {
        id
        remainingBalanceCents
      }
      totalOutstandingBalanceCents
      lastPayment {
        amountCents
        date
      }
    }
  }
`
const SidepanelPatientFinancialsContext = createNamedContext<{
  patient: GetPatientFinancialInfo['patient']
}>('SidepanelPatientFinancialsContext')

export const useSidepanelPatientFinancialsContext = () => {
  const context = useContext(SidepanelPatientFinancialsContext)
  return context
}

export const SidepanelPatientFinancialsProvider = ({ children }) => {
  const { patientId } = useParams()
  const { data, loading } = useQuery<
    GetPatientFinancialInfo,
    GetPatientFinancialInfoVariables
  >(QUERY, {
    variables: {
      patientId,
    },
  })

  if (loading) return <LoadingSpinner />

  if (!data) return null

  return (
    <SidepanelPatientFinancialsContext.Provider
      value={{ patient: data.patient }}
    >
      {children}
    </SidepanelPatientFinancialsContext.Provider>
  )
}
