import type { PatientLegalStatus } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const patientLegalStatusDisplay: {
  [key in PatientLegalStatus]: string
} = {
  DEPENDENT: 'Dependent',
  EMANCIPATED_MINOR: 'Emancipated minor',
  INDEPENDENT_ADULT: 'Independent adult',
}

export const patientLegalStatusDescription: {
  [key in PatientLegalStatus]: string
} = {
  DEPENDENT: 'Child who is under the care of a natural or legal guardian.',
  EMANCIPATED_MINOR:
    'Adolescent who is legally independent and no longer under the care of a guardian.',
  INDEPENDENT_ADULT:
    'Adult age 18+ who is independent and no longer under the care of a guardian.',
}

export const patientLegalStatuses =
  nameValueArrayFromObject<PatientLegalStatus>(patientLegalStatusDisplay)
