import { useEffect, useState } from 'react'

import clsx from 'clsx'
import {
  growthChartObservations,
  lengthCodes,
  weightCodes,
  headCircumferenceCodes,
} from 'common/cdr/concepts/observations/growthCharts/index'
import { GrowthChartDataset, Patient } from 'types/graphql'

import ChartSection, { chartColors } from './ChartSection/ChartSection'
import { TransformedGrowthChartDatasetsType } from './useGrowthCharts'
const AllGrowthCharts = ({
  patient,
  transformedGrowthChartDatasets,
  hideTables,
  showCorrectedAge,
  zoomToToday,
  todayLineX,
  connectPoints,
  queriedDataset,
  growthChartHeight,
  onAllGrowthChartsRendered,
  isPdf = false,
}: {
  patient: Patient
  transformedGrowthChartDatasets: TransformedGrowthChartDatasetsType
  hideTables: boolean
  showCorrectedAge: boolean
  zoomToToday: boolean
  todayLineX: number
  connectPoints: boolean
  queriedDataset: GrowthChartDataset
  growthChartHeight: string
  onAllGrowthChartsRendered?: () => void
  isPdf?: boolean
}) => {
  const [growthChartsRendered, setGrowthChartsRendered] = useState<number>(0)

  useEffect(() => {
    if (growthChartsRendered >= 4) {
      onAllGrowthChartsRendered?.()
    }
  }, [growthChartsRendered, onAllGrowthChartsRendered])

  const onGrowthChartFinishedRendering = () => {
    setGrowthChartsRendered((prev) => prev + 1)
  }

  return (
    <div
      className={clsx([
        'grid grid-cols-2',
        isPdf ? 'gap-core-space-50' : 'gap-core-space-150',
      ])}
    >
      <ChartSection
        title="Length-for-age"
        testId="infant-length-for-age"
        patient={patient}
        chartData={transformedGrowthChartDatasets?.infantLengthForAge}
        hideTables={hideTables}
        showCorrectedAge={showCorrectedAge}
        xAxisLabel="Months"
        yAxisLabel="Length"
        yAxisUnit="cm"
        xMin={0}
        xMax={zoomToToday ? Math.round(todayLineX) : 24}
        percentileLabelDistance={2}
        todayLineX={todayLineX}
        tableRows={[
          {
            rowHeader: 'Length (in)',
            codes: lengthCodes,
            key: 'imperial.value',
          },
          {
            rowHeader: 'Length (cm)',
            codes: lengthCodes,
            key: 'value.value',
          },
          {
            rowHeader: 'Percentile (%)',
            codes: lengthCodes,
            key: 'percentile',
          },
        ]}
        isInfantData={true}
        isWeightForHeightData={false}
        hasConnectedPoints={connectPoints}
        dataset={queriedDataset}
        growthChartHeight={growthChartHeight}
        onGrowthChartFinishedRendering={onGrowthChartFinishedRendering}
        isPdf={isPdf}
      />

      <ChartSection
        title="Weight-for-age"
        testId="infant-weight-for-age"
        patient={patient}
        chartData={transformedGrowthChartDatasets?.infantWeightForAge}
        hideTables={hideTables}
        showCorrectedAge={showCorrectedAge}
        xAxisLabel="Months"
        yAxisLabel="Weight"
        yAxisUnit="kg"
        xMin={0}
        xMax={zoomToToday ? Math.round(todayLineX) : 24}
        percentileLabelDistance={2}
        todayLineX={todayLineX}
        tableRows={[
          {
            rowHeader: 'Weight (lbs)',
            codes: weightCodes,
            key: 'imperial.value',
          },
          {
            rowHeader: 'Weight (kg)',
            codes: weightCodes,
            key: 'value.value',
          },
          {
            rowHeader: 'Percentile (%)',
            codes: weightCodes,
            key: 'percentile',
          },
        ]}
        isInfantData={true}
        isWeightForHeightData={false}
        hasConnectedPoints={connectPoints}
        dataset={queriedDataset}
        growthChartHeight={growthChartHeight}
        onGrowthChartFinishedRendering={onGrowthChartFinishedRendering}
        isPdf={isPdf}
      />

      <ChartSection
        title="Head circumference-for-age"
        testId="infant-head-circumference-for-age"
        patient={patient}
        chartData={
          transformedGrowthChartDatasets?.infantHeadCircumferenceForAge
        }
        hideTables={hideTables}
        showCorrectedAge={showCorrectedAge}
        xAxisLabel="Months"
        yAxisLabel="Head Circumference"
        yAxisUnit="cm"
        xMin={0}
        xMax={zoomToToday ? Math.round(todayLineX) : 24}
        percentileLabelDistance={2}
        todayLineX={todayLineX}
        tableRows={[
          {
            rowHeader: 'Circumference (in)',
            codes: headCircumferenceCodes,
            key: 'imperial.value',
          },
          {
            rowHeader: 'Circumference (cm)',
            codes: headCircumferenceCodes,
            key: 'value.value',
          },
          {
            rowHeader: 'Percentile (%)',
            codes: headCircumferenceCodes,
            key: 'percentile',
          },
        ]}
        isInfantData={true}
        isWeightForHeightData={false}
        hasConnectedPoints={connectPoints}
        dataset={queriedDataset}
        growthChartHeight={growthChartHeight}
        onGrowthChartFinishedRendering={onGrowthChartFinishedRendering}
        isPdf={isPdf}
      />

      <ChartSection
        title="Weight-for-length"
        testId="infant-weight-for-length"
        patient={patient}
        chartData={transformedGrowthChartDatasets?.infantWeightForLength}
        hideTables={hideTables}
        showCorrectedAge={false}
        xAxisLabel="Length (cm)"
        yAxisLabel="Weight"
        yAxisUnit="kg"
        xMin={45}
        xMax={110}
        percentileLabelDistance={10}
        tableRows={[
          {
            rowHeader: 'Weight (lbs)',
            codes: weightCodes,
            key: 'weightImperial.value',
          },
          {
            rowHeader: 'Weight (kg)',
            codes: weightCodes,
            key: 'weight.value',
          },
          {
            rowHeader: 'Length (in)',
            codes: lengthCodes,
            key: 'heightImperial.value',
          },
          {
            rowHeader: 'Length (cm)',
            codes: lengthCodes,
            key: 'height.value',
          },
          {
            rowHeader: 'Percentile (%)',
            codes: lengthCodes,
            key: 'percentile',
          },
        ]}
        isInfantData={true}
        isWeightForHeightData={true}
        hasConnectedPoints={connectPoints}
        dataset={queriedDataset}
        growthChartHeight={growthChartHeight}
        onGrowthChartFinishedRendering={onGrowthChartFinishedRendering}
        isPdf={isPdf}
      />

      <ChartSection
        title="Stature-for-age"
        testId="stature-for-age"
        patient={patient}
        chartData={transformedGrowthChartDatasets?.statureForAge}
        hideTables={hideTables}
        showCorrectedAge={showCorrectedAge}
        xAxisLabel="Years"
        yAxisLabel="Stature"
        yAxisUnit="cm"
        xMin={2}
        xMax={zoomToToday ? Math.round(todayLineX) / 12 : 20}
        percentileLabelDistance={18}
        todayLineX={todayLineX}
        tableRows={[
          {
            rowHeader: 'Stature (in)',
            codes: lengthCodes,
            key: 'imperial.value',
          },
          {
            rowHeader: 'Stature (cm)',
            codes: lengthCodes,
            key: 'value.value',
          },
          {
            rowHeader: 'Percentile (%)',
            codes: lengthCodes,
            key: 'percentile',
          },
        ]}
        isInfantData={false}
        isWeightForHeightData={false}
        hasConnectedPoints={connectPoints}
        dataset={queriedDataset}
        growthChartHeight={growthChartHeight}
        onGrowthChartFinishedRendering={onGrowthChartFinishedRendering}
        isPdf={isPdf}
      />

      <ChartSection
        title="Weight-for-age"
        testId="weight-for-age"
        patient={patient}
        chartData={transformedGrowthChartDatasets?.weightForAge}
        hideTables={hideTables}
        showCorrectedAge={showCorrectedAge}
        xAxisLabel="Years"
        yAxisLabel="Weight"
        yAxisUnit="kg"
        xMin={2}
        xMax={zoomToToday ? Math.round(todayLineX) / 12 : 20}
        percentileLabelDistance={18}
        todayLineX={todayLineX}
        tableRows={[
          {
            rowHeader: 'Weight (lbs)',
            codes: weightCodes,
            key: 'imperial.value',
          },
          {
            rowHeader: 'Weight (kg)',
            codes: weightCodes,
            key: 'value.value',
          },
          {
            rowHeader: 'Percentile (%)',
            codes: weightCodes,
            key: 'percentile',
          },
        ]}
        isInfantData={false}
        isWeightForHeightData={false}
        hasConnectedPoints={connectPoints}
        dataset={queriedDataset}
        growthChartHeight={growthChartHeight}
        onGrowthChartFinishedRendering={onGrowthChartFinishedRendering}
        isPdf={isPdf}
      />

      <ChartSection
        title="BMI-for-age"
        testId="bmi-for-age"
        patient={patient}
        chartData={transformedGrowthChartDatasets?.bmiForAge}
        hideTables={hideTables}
        showCorrectedAge={showCorrectedAge}
        xAxisLabel="Years"
        yAxisLabel="BMI"
        yAxisUnit="kg/m2"
        xMin={2}
        xMax={zoomToToday ? Math.round(todayLineX) / 12 : 20}
        percentileLabelDistance={18}
        areaLayers={[
          {
            bottom: 'bottomAxis',
            top: 'p5',
            color: chartColors.orange,
            fillOpacity: 0.15,
          },
          {
            bottom: 'bottomLine',
            top: 'p5',
            color: chartColors.orange,
            fillOpacity: 0.5,
          },
          {
            bottom: 'p5',
            top: 'p85',
            color: chartColors.green,
            fillOpacity: 0.5,
          },
          {
            bottom: 'p85',
            top: 'p95',
            color: chartColors.yellow,
            fillOpacity: 0.5,
          },
          {
            bottom: 'p95',
            top: 'topLine',
            color: chartColors.red,
            fillOpacity: 0.5,
          },
          {
            bottom: 'p95',
            top: 'topAxis',
            color: chartColors.red,
            fillOpacity: 0.15,
          },
        ]}
        lineColors={[
          chartColors.orange,
          chartColors.orange,
          chartColors.greenDark,
          chartColors.greenDark,
          chartColors.greenDark,
          chartColors.greenDark,
          chartColors.orange,
          chartColors.orange,
          chartColors.red,
          chartColors.red,
          'transparent',
          'transparent',
        ]}
        percentileLabels={[
          {
            dataKey: 'P3',
            circleFill: chartColors.orangeLight,
            circleStroke: chartColors.orange,
          },
          {
            dataKey: 'P5',
            circleFill: chartColors.orangeLight,
            circleStroke: chartColors.orange,
          },
          {
            dataKey: 'P10',
            circleFill: chartColors.greenLight,
            circleStroke: chartColors.greenDark,
            labelFill: chartColors.greenDark,
          },
          {
            dataKey: 'P25',
            circleFill: chartColors.greenLight,
            circleStroke: chartColors.greenDark,
            labelFill: chartColors.greenDark,
          },
          {
            dataKey: 'P50',
            circleFill: chartColors.greenLight,
            circleStroke: chartColors.greenDark,
            labelFill: chartColors.greenDark,
          },
          {
            dataKey: 'P75',
            circleFill: chartColors.greenLight,
            circleStroke: chartColors.greenDark,
            labelFill: chartColors.greenDark,
          },
          {
            dataKey: 'P85',
          },
          {
            dataKey: 'P90',
          },
          {
            dataKey: 'P95',
            circleFill: chartColors.redLight,
            circleStroke: chartColors.red,
            labelFill: chartColors.red,
          },
          {
            dataKey: 'P97',
            circleFill: chartColors.redLight,
            circleStroke: chartColors.red,
            labelFill: chartColors.red,
          },
        ]}
        todayLineX={todayLineX}
        tableRows={[
          {
            rowHeader: 'BMI (kg/m2)',
            codes: [growthChartObservations['BODY_MASS_INDEX'].code],
            key: 'value.value',
          },
          {
            rowHeader: 'Percentile (%)',
            codes: [growthChartObservations['BODY_MASS_INDEX'].code],
            key: 'percentile',
          },
        ]}
        tableColorThreshold={[
          {
            range: {
              start: 0,
              end: 5,
            },
            color: chartColors.orangeLight,
          },
          {
            range: {
              start: 5,
              end: 85,
            },
            color: chartColors.greenLight,
          },
          {
            range: {
              start: 85,
              end: 95,
            },
            color: chartColors.yellowLight,
          },
          {
            range: {
              start: 95,
              end: 100,
            },
            color: chartColors.redLight,
          },
        ]}
        isInfantData={false}
        isWeightForHeightData={false}
        hasConnectedPoints={connectPoints}
        dataset={queriedDataset}
        growthChartHeight={growthChartHeight}
        onGrowthChartFinishedRendering={onGrowthChartFinishedRendering}
        isPdf={isPdf}
      />

      <ChartSection
        title="Weight-for-stature"
        testId="weight-for-stature"
        patient={patient}
        chartData={transformedGrowthChartDatasets?.weightForStature}
        hideTables={hideTables}
        showCorrectedAge={false}
        xAxisLabel="Stature (cm)"
        yAxisLabel="Weight"
        yAxisUnit="kg"
        xMin={77}
        xMax={121.5}
        lineColors={[
          chartColors.red,
          chartColors.orange,
          chartColors.orange,
          chartColors.orange,
          chartColors.greenDark,
          chartColors.orange,
          chartColors.orange,
          chartColors.orange,
          chartColors.orange,
          chartColors.red,
          'transparent',
          'transparent',
        ]}
        tableRows={[
          {
            rowHeader: 'Weight (lbs)',
            codes: weightCodes,
            key: 'weightImperial.value',
          },
          {
            rowHeader: 'Weight (kg)',
            codes: weightCodes,
            key: 'weight.value',
          },
          {
            rowHeader: 'Stature (in)',
            codes: lengthCodes,
            key: 'heightImperial.value',
          },
          {
            rowHeader: 'Stature (cm)',
            codes: lengthCodes,
            key: 'height.value',
          },
          {
            rowHeader: 'Percentile (%)',
            codes: lengthCodes,
            key: 'percentile',
          },
        ]}
        isInfantData={false}
        isWeightForHeightData={true}
        hasConnectedPoints={connectPoints}
        dataset={queriedDataset}
        growthChartHeight={growthChartHeight}
        onGrowthChartFinishedRendering={onGrowthChartFinishedRendering}
        isPdf={isPdf}
      />
    </div>
  )
}

export default AllGrowthCharts
