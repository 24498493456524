import { DocumentTypesConfig } from 'common/cdr/concepts/documents'
import type { PatientCaregiverInformationDocumentType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const patientCaregiverInformationDocumentTypeDisplay: {
  [key in PatientCaregiverInformationDocumentType]: string
} = {
  ...DocumentTypesConfig['PATIENT_OR_CAREGIVER_INFORMATION']['subTypes'],
}

export const patientCaregiverInformationDocumentTypes =
  nameValueArrayFromObject<PatientCaregiverInformationDocumentType>(
    patientCaregiverInformationDocumentTypeDisplay
  )
