import { useEffect, useRef, useState } from 'react'

import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import { AgGridReact } from 'ag-grid-react'
import { ProductGuides } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import Button from 'src/components/atoms/Button'
import InputField from 'src/components/atoms/InputField'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import Table from 'src/components/molecules/Table'

const QUERY = gql`
  query ProductGuides {
    productGuides {
      id
      title
      category
      scribeLink
    }
  }
`

const SidepanelProductGuide = () => {
  const { data, loading } = useQuery<ProductGuides>(QUERY)
  const gridRef = useRef<AgGridReact>()
  const formMethods = useForm()

  const searchInput = formMethods.watch('search')
  useEffect(() => {
    gridRef.current?.api?.setQuickFilter(searchInput)
  }, [searchInput])
  const [selectedGuide, setSelectedGuide] = useState<
    ProductGuides['productGuides'][number] | null
  >(null)

  const productGuides = data?.productGuides ?? []

  return (
    <SidepanelPage
      loading={loading}
      description="Search and view helpful product guides below. Reach out to support@getdevelo.com for any questions that are not answered below."
      header="Product guides"
    >
      <StackView className="mb-6 h-full overflow-y-auto p-6" space={100}>
        {selectedGuide ? (
          <>
            <div>
              <Button
                buttonStyle="secondary"
                icon={ArrowLeftIcon}
                text="Back"
                onClick={() => {
                  setSelectedGuide(null)
                }}
              />
            </div>
            {selectedGuide.scribeLink ? (
              <iframe
                title={selectedGuide.title}
                src={`${selectedGuide.scribeLink.replaceAll(
                  '/shared/',
                  '/embed/'
                )}?as=scrollable`}
                width="100%"
                height="100%"
                allowFullScreen
                frameBorder="0"
              />
            ) : (
              <StackView
                className="h-full"
                justifyContent="center"
                alignItems="center"
              >
                <Typography textStyle="title" fontWeight="semibold">
                  {selectedGuide.title} not found
                </Typography>
              </StackView>
            )}
          </>
        ) : (
          <>
            <Form formMethods={formMethods} className="w-80">
              <InputField name="search" placeholder="Search to filter guides" />
            </Form>
            <Table
              testId="product-guides-table"
              innerRef={gridRef}
              rowData={productGuides}
              defaultColDef={{
                cellStyle: {
                  border: 'none',
                  display: 'flex',
                  alignItems: 'center',
                },
                cellClass: 'cursor-pointer',
                resizable: true,
                sortable: true,
              }}
              columnDefs={[
                {
                  headerName: 'Title',
                  field: 'title',
                  flex: 3,
                  wrapText: true,
                },
                {
                  headerName: 'Category',
                  field: 'category',
                  flex: 1,
                  maxWidth: 150,
                },
              ]}
              domLayout="autoHeight"
              rowHeight={72}
              onRowClicked={({ data }) => setSelectedGuide(data)}
            />
          </>
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelProductGuide
