import { DocumentTypesConfig } from 'common/cdr/concepts/documents'
import type { PatientEducationDocumentType } from 'types/graphql'

import { nameValueArrayFromObject } from 'src/data/utils'

export const patientEducationDocumentTypeDisplay: {
  [key in PatientEducationDocumentType]: string
} = {
  ...DocumentTypesConfig['PATIENT_EDUCATION']['subTypes'],
}

export const patientEducationDocumentTypes =
  nameValueArrayFromObject<PatientEducationDocumentType>(
    patientEducationDocumentTypeDisplay
  )
