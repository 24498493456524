import { useParams } from 'react-router-dom'

import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import EditUserCell from 'src/components/User/EditUserCell'

const SidepanelUserEdit = () => {
  const { id } = useParams()
  return (
    <SidepanelPage header="Editing Staff Member">
      <EditUserCell id={id} />
    </SidepanelPage>
  )
}

export default SidepanelUserEdit
