import { ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { datadogRum } from '@datadog/browser-rum'
import { BrowserRouter } from 'react-router-dom'

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import {
  GraphQLClientConfigProp,
  RedwoodApolloProvider,
} from '@redwoodjs/web/apollo'
import { toast } from '@redwoodjs/web/dist/toast'

import { retryIf, statusCodeToErrorMessage } from 'src/apollo/retryIf'
import { restoreSettings } from 'src/lib/settings'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import { LocalSettingsProvider } from 'src/providers/context/LocalSettingsContext'
import Routes from 'src/Routes'
import TimedSession from 'src/wrappers/TimedSession'

import { AuthProvider, useAuth } from './auth'
import { useSyncUserToDatadog } from './hooks/useSyncUserToDatadog/useSyncUserToDatadog'
import 'src/scaffold.css'
import 'src/index.css'
import 'src/mobiscroll.scss'
import { appType } from './utils/appType'

const App = () => {
  if (process.env.DATADOG_RUM_ENABLED === 'true') {
    datadogRum.init({
      applicationId: process.env.DATADOG_RUM_APPLICATION_ID,
      clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
      site: 'us5.datadoghq.com',
      service: appType === 'patientPortal' ? 'develo-portal' : 'develo-emr',
      env: process.env.DATADOG_RUM_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.FC_GIT_COMMIT_SHA || '0.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: [process.env.REDWOOD_API_URL],
    })

    datadogRum.startSessionReplayRecording()
  }
  return (
    <FatalErrorBoundary page={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle">
        <AuthProvider>
          <AppApollo />
        </AuthProvider>
      </RedwoodProvider>
    </FatalErrorBoundary>
  )
}

const MUTATIONS_TO_TOAST_SERVER_ERROR = [
  'AddendVisit',
  'AddVaccineInventory',
  'AddVisitDiagnosis',
  'AdministerImmunization',
  'ChangePassword',
  'CompleteQuestionnaireOrder',
  'CompleteQuestionnaireForAppointment',
  'CreateAppointmentMutation',
  'CreateChargeItemForEncounter',
  'CreateContact',
  'CreateContactOrganization',
  'CreateExistingCaregiverMutation',
  'CreateExternalRequest',
  'CreateFamilyMemberHistory',
  'CreateImmunizationsMutation',
  'CreateInsuranceCoverageMutation',
  'CreateInsuranceEligibilityForInsuranceCoverage',
  'CreateNewCaregiverMutation',
  'CreateOrder',
  // no typo. It is camel cased
  'createPatientDocument',
  'CreatePatientEducationDocument',
  'CreatePatientForm',
  'CreatePatientPayment',
  // no typo. It is camel cased
  'createPayerAddress',
  'CreatePracticeAdminDocument',
  'CreatePracticeForm',
  'CreatePracticeLetter',
  'CreateScreeningTool',
  'CreateSignedPracticeFormMutation',
  'CreateUserMutation',
  'CreateVisitTemplateDiagnosis',
  'DeleteMacroPhrase',
  'DeletePatientPayment',
  'DeleteVisitDiagnosis',
  'DeveloResetPassword',
  'EditImmunizationsMutation',
  'EditVaccineInventory',
  'MarkChargeItemDoNotBill',
  'OnboardPractitioner',
  'RegenerateResetToken',
  'RegisterPatientMutation',
  'SendFaxes',
  'SetNoKnownAllergies',
  'SetVaccineInventoryStatus',
  'SignVisit',
  'StartVisitMutation',
  'SwapBillingCodeMutation',
  'SwapDiagnosisCodeMutation',
  'TransferVaccineInventory',
  'UndoAdministerImmunization',
  'UpdateAppointmentDocumentation',
  'UpdateAppointmentStatus',
  'UpdateContact',
  'UpdateContactOrganization',
  'UpdateExternalRequest',
  'UpdateFamilyMemberHistory',
  'UpdateOrder',
  'UpdateOwnPractitioner',
  'UpdateOwnPractitionerClinicalDetail',
  'UpdatePatientDocument',
  'UpdatePatientEducationDocument',
  'UpdatePatientForm',
  'UpdatePatientPayment',
  'UpdatePracticeAdminDocument',
  'UpdatePracticeForm',
  'UpdatePracticeLetter',
  'UpdateScreeningTool',
  'UpdateUserMutation',
  'UpdateVisitClinicalReview',
  'UpdateVisitDiagnosis',
  'UpdateVisitFlowTabForAppointment',
  'UpdateVisitIntakeVitals',
  'UpdateVisitPatientEducationDocuments',
  'UpsertBirthHistoryUpdateVisitPatientEducationDocuments',
  'UpsertMacroPhrase',
  'UpsertMenstrualHistory',
  'UpsertPatientAllergy',
  'UpsertPatientCondition',
  'UpsertSexualHistory',
  'UpsertSocialHistory',
  'UpsertVisitTemplate',
]

/** These mutations define their own custom toast behavior */
const MUTATIONS_TO_TOAST_NOTHING = ['SubmitClaimForEncounter']

const MAX_RETRY_ATTEMPTS = 3
const MUTATIONS_TO_RETRY = []

const AppApollo = () => {
  const { logOut } = useAuth()

  useSyncUserToDatadog()

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    if (networkError) {
      console.error('Network error', networkError)
      toast.error(
        statusCodeToErrorMessage[networkError['statusCode']] ??
          'Network error. Please try again.'
      )
    }

    const isMutation = operation.query.definitions.some(
      (definition) =>
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'mutation'
    )
    if (!graphQLErrors) return
    if (
      graphQLErrors.some(
        ({ extensions }) => extensions?.code === 'SESSION_EXPIRED'
      )
    ) {
      // eslint-disable-next-line no-console
      console.error('Session expiration detected in error link. Logging out.')
      toast.error('Session expired. Please login again.')
      logOut()
    } else if (
      graphQLErrors.some(
        ({ extensions }) => extensions?.code === 'IP_RESTRICTED'
      )
    ) {
      toast.error('Remote access not allowed.')
      logOut()
    } else if (
      graphQLErrors.some(({ extensions }) => extensions?.code === 'FORBIDDEN')
    ) {
      toast.error('Permission denied')
    } else if (isMutation) {
      if (MUTATIONS_TO_TOAST_SERVER_ERROR.includes(operation.operationName)) {
        const errorMessages = graphQLErrors.map(({ message }) => message)

        for (const message of errorMessages) {
          toast.error(message)
        }
      } else if (
        !MUTATIONS_TO_TOAST_NOTHING.includes(operation.operationName)
      ) {
        toast.error(
          'Something went wrong, please try again. If the problem persists, please contact Develo support.'
        )
      }
    }
  })
  // const { uri } = useFetchConfig()
  const graphQLClientConfig: GraphQLClientConfigProp = {
    connectToDevTools: Boolean(process.env.APOLLO_DEV_TOOLS_ACTIVE),
    httpLinkConfig: { credentials: 'include' },
    link(rwLinks) {
      const links = rwLinks.map(({ link }) => link)
      const httpLink = links.pop()
      // const restLink = new RestLink({
      //   uri: uri.replace('/graphql', ''),
      //   credentials: 'include',
      // })

      const retryLink = new RetryLink({
        attempts: {
          max: MAX_RETRY_ATTEMPTS,
          retryIf: retryIf(MUTATIONS_TO_RETRY),
        },
      })

      return ApolloLink.from([
        ...links,
        errorLink,
        retryLink,
        // restLink,
        httpLink,
      ])
    },
  }

  return (
    <RedwoodApolloProvider
      useAuth={useAuth}
      graphQLClientConfig={graphQLClientConfig}
    >
      <LocalSettingsProvider settings={restoreSettings()}>
        <TimedSession>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </TimedSession>
      </LocalSettingsProvider>
    </RedwoodApolloProvider>
  )
}

export default App
