import { ReactElement, useState } from 'react'

import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import Typography from 'src/components/atoms/Typography/Typography'

type NotificationType = 'success' | 'undo'

interface NotificationProps {
  onClose: () => void
  onUndo?: () => void
  callback?: () => void
  title: string
  description?: string
  type?: NotificationType
  /** durationBeforeCallback in seconds */
  durationBeforeCallback?: number
}

export const NotificationUndo = ({
  onClose,
  onUndo,
  title,
}: NotificationProps) => {
  return (
    <Box className="pointer-events-auto mt-10 w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
      <Box className="p-4">
        <Box className="flex items-center">
          <Box className="flex w-0 flex-1 justify-between">
            <Typography className="w-0 flex-1 text-sm font-medium text-gray-900">
              {title}
            </Typography>
            <button
              type="button"
              onClick={() => {
                onUndo()
                onClose()
              }}
              className="ml-3 flex-shrink-0 rounded-md bg-white text-sm font-medium text-primary hover:text-primary focus:outline-none focus:ring-1 focus:ring-primary focus:ring-offset-2"
            >
              Undo
            </button>
          </Box>
          <Box className="ml-4 flex flex-shrink-0">
            <button
              type="button"
              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-primary focus:ring-offset-2"
              onClick={() => {
                onClose()
              }}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default function NotificationSuccess({
  onClose,
  title,
  description,
}: NotificationProps): ReactElement {
  const [closed, setClosed] = useState(false)
  return (
    <Box
      className={clsx(
        'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5',
        closed && 'hidden'
      )}
    >
      <Box className="p-4">
        <Box className="flex items-start">
          <Box className="flex-shrink-0">
            <CheckCircleIcon
              className="h-6 w-6 text-green-400"
              aria-hidden="true"
            />
          </Box>
          <Box className="ml-3 w-0 flex-1 pt-0.5">
            <Typography className="text-sm font-medium text-gray-900">
              {title}
            </Typography>
            {!!description && (
              <Typography className="mt-1 text-sm text-gray-50">
                {description}
              </Typography>
            )}
          </Box>
          <Box className="ml-4 flex flex-shrink-0">
            <Button
              buttonStyle="ghost"
              onClick={() => {
                setClosed(true)
                onClose()
              }}
              accessibilityText="Close"
            >
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
