import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { DocumentCaseStatusUpdate } from 'src/pages/Sidepanel/SidepanelDocumentCaseStatusUpdate/DocumentCaseStatusUpdate'

const SidepanelDocumentCaseStatusUpdate = () => {
  return (
    <SidepanelPage
      header="Status update"
      description="Fill out the mandatory fields before submitting a status update."
    >
      <DocumentCaseStatusUpdate />
    </SidepanelPage>
  )
}

export default SidepanelDocumentCaseStatusUpdate
