import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { useParams } from 'react-router-dom'
import {
  ChiefComplaint,
  ReadPatientResultReviewResolution,
  ResolveResultsReviewCaseVariables,
  TargetDate,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useParams as useParamsRW } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import AppointmentDefinitionComboBoxCell from 'src/components/AppointmentDefinition/AppointmentDefinitionComboBoxCell'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import ChiefComplaintSelectField from 'src/components/atoms/ChiefComplaintSelectField/ChiefComplaintSelectField'
import Divider from 'src/components/atoms/Divider/Divider'
import { DropdownField } from 'src/components/atoms/Dropdown/Dropdown'
import Label from 'src/components/atoms/Label/Label'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import TargetDateField from 'src/components/atoms/TargetDateField/TargetDateField'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import Typography from 'src/components/atoms/Typography/Typography'
import FieldError from 'src/components/FieldError/FieldError'
import LocationDropdownCell from 'src/components/Location/LocationDropdownCell'
import DataRow from 'src/components/molecules/DataRow/DataRow'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import EnhancedPatientSearch from 'src/components/Patient/EnhancedPatientSearch/EnhancedPatientSearch'
import PractitionerDropdownCell from 'src/components/Practitioner/PractitionerDropdownCell'
import { useHandleCaseResolution } from 'src/hooks/useHandleCaseResolution/useHandleCaseResolution'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import { CREATE_CASE_REVIEW_STATUS_UDPATE_MUTATION } from '../SidepanelResultsReviewCaseStatusUpdate/SidepanelResultsReviewCaseStatusUpdate'
interface ReasonForVisit {
  visitType: string[]
  visitComment: string
  chiefComplaints?: ChiefComplaint[]
}
export interface ResolveSchedulingCaseData {
  date: Date
  timeStart: string
  practitioner: string
  location: string
  reason: ReasonForVisit
  patient?: never
  registrationIntent?: never
}

const READ_PATIENT = gql`
  query ReadPatientResultReviewResolution(
    $id: String!
    $resultsReviewCaseId: String!
  ) {
    resultsReviewCase(id: $resultsReviewCaseId) {
      id
      baseCaseId
      orders {
        id
        requester {
          id
        }
      }
    }
    diagnosticReportReviewCase(id: $resultsReviewCaseId) {
      id
      baseCaseId
      diagnosticReport {
        basedOnOrders {
          id
          requester {
            id
          }
        }
      }
    }
    patient(id: $id) {
      id
      givenName
      familyName
      contactInformation {
        id
        mobileNumber
        homeAddress {
          id
          line1
          line2
          city
          state
          postalCode
        }
      }
      patientRelatedPersonRelationships {
        id
        doesResideWith
        relationshipType
        relatedPerson {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
          contactInformation {
            id
            mobileNumber
            emailAddress
          }
        }
      }
    }
  }
`

const RESOLVE_RESULTS_REVIEW_CASE_MUTATION = gql`
  mutation ResolveResultsReviewCase(
    $resultsReviewCaseId: String!
    $input: ResolveResultsReviewCaseInput!
  ) {
    resolveResultsReviewCase(
      input: $input
      resultsReviewCaseId: $resultsReviewCaseId
    ) {
      ... on ResultsReviewCase {
        id
        resolvedAt
        baseCase {
          id
          updatedAt
        }
        resolvedBy {
          id
          familyName
          givenName
        }
        resolvedStatus
      }
      ... on DiagnosticReportReviewCase {
        id
        resolvedAt
        baseCase {
          id
          updatedAt
        }
        resolvedBy {
          id
          familyName
          givenName
        }
        resolvedStatus
      }
    }
  }
`

interface FormProps {
  resultsReviewed: boolean
  resultsDiscussedWithPatient: boolean
  resultsDiscussedWithCaregiver: boolean
  scheduleFollowupVisit: boolean
  targetDate: TargetDate
  patientId: string
  patient: { id: string }
  visitPractitioner: string
  patientDisplayText: string
  visitType?: string[]
}

const SidepanelResultsReviewCaseResolution = () => {
  const { patientId } = useParams()
  const { closeSidePanel } = useSidepanel()
  const paramsRW = useParamsRW()
  const { glob } = paramsRW
  const resultsReviewCaseId = glob?.split('/')[1]
  const { handleCaseResolution, getNextTaskId } = useHandleCaseResolution()
  const [resolveResultsReviewCase, { loading: resolvingResultsReviewCase }] =
    useMutation(RESOLVE_RESULTS_REVIEW_CASE_MUTATION)

  const [createCaseStatusUpdate, { loading: creatingCaseStatusUpdate }] =
    useMutation(CREATE_CASE_REVIEW_STATUS_UDPATE_MUTATION)
  const formMethods = useForm<FormProps>({
    defaultValues: {
      resultsReviewed: false,
      resultsDiscussedWithCaregiver: false,
      resultsDiscussedWithPatient: false,
      scheduleFollowupVisit: false,
      targetDate: {
        value: 1,
        unit: 'WEEK',
        prefix: 'AFTER',
      },
      patientId,
    },
  })
  const { data } = useQuery(READ_PATIENT, {
    variables: {
      id: patientId,
      resultsReviewCaseId: resultsReviewCaseId,
    },
    skip: !patientId,
    fetchPolicy: 'cache-first',
    onCompleted: (data: ReadPatientResultReviewResolution) => {
      const orders = data.resultsReviewCase
        ? data.resultsReviewCase.orders
        : data.diagnosticReportReviewCase.diagnosticReport.basedOnOrders
      const practitionerId = orders?.[0]?.requester?.id

      formMethods.setValue('visitPractitioner', practitionerId)
      formMethods.setValue('patient', data.patient)
      formMethods.setValue(
        'patientDisplayText',
        formatDisplayName(data.patient)
      )
    },
  })

  const options = (data?.patient?.patientRelatedPersonRelationships || []).map(
    ({ relatedPerson, relationshipType }) => {
      return {
        key: relatedPerson.id,
        id: relatedPerson.id,
        value: `${formatDisplayName(relatedPerson)} - ${
          relationshipTypeDisplay[relationshipType]
        }`,
        name: `${formatDisplayName(relatedPerson)} - ${
          relationshipTypeDisplay[relationshipType]
        }`,
      }
    }
  )

  const visitType = formMethods.watch('visitType')

  const [resultsDiscussedWithCaregiver, scheduleFollowupVisit] =
    formMethods.watch([
      'resultsDiscussedWithCaregiver',
      'scheduleFollowupVisit',
    ])

  const onSubmit = async (resultsReviewForm) => {
    const variables: ResolveResultsReviewCaseVariables = {
      resultsReviewCaseId,
      input: {
        cancelCase: false,
        schedulingFollowupVisit: scheduleFollowupVisit,
        schedulingOrder: scheduleFollowupVisit
          ? {
              appointmentDefinitionIds: resultsReviewForm.visitType,
              chiefComplaints:
                resultsReviewForm.chiefComplaints?.filter(Boolean) ?? [],
              locationId: resultsReviewForm.location,
              patientId: resultsReviewForm.patientId,
              targetDate: resultsReviewForm.targetDate,
              visitPractitionerId: resultsReviewForm.visitPractitioner,
              note: resultsReviewForm.scheduleNote,
              intent: 'ORDER',
            }
          : undefined,
      },
    }

    const {
      resultsReviewed,
      resultsDiscussedWithCaregiver,
      resultsDiscussedWithPatient,
      caregiver,
    } = resultsReviewForm

    const baseCaseId = data.resultsReviewCase
      ? data.resultsReviewCase.baseCaseId
      : data.diagnosticReportReviewCase.baseCaseId
    const promises = []
    if (resultsReviewed) {
      promises.push(
        new Promise((resolve) => {
          void createCaseStatusUpdate({
            variables: {
              input: {
                type: 'RESULTS_REVIEW',
                baseCaseId,
                text: `Results reviewed`,
                icon: 'USER_CIRCLE',
              },
            },
            onCompleted: () => {
              resolve(true)
            },
          })
        })
      )
    }

    if (resultsDiscussedWithPatient) {
      promises.push(
        new Promise((resolve) => {
          void createCaseStatusUpdate({
            variables: {
              input: {
                type: 'RESULTS_REVIEW',
                baseCaseId,
                text: `Results reviewed; discussed with patient`,
                icon: 'USER_CIRCLE',
              },
            },
            onCompleted: () => {
              resolve(true)
            },
          })
        })
      )
    }

    if (resultsDiscussedWithCaregiver) {
      promises.push(
        new Promise((resolve) => {
          void createCaseStatusUpdate({
            variables: {
              input: {
                type: 'RESULTS_REVIEW',
                baseCaseId,
                text: `Results reviewed; discussed with caregiver - ${caregiver}`,
                icon: 'USERS',
              },
            },
            onCompleted: () => {
              resolve(true)
            },
          })
        })
      )
    }

    await Promise.all(promises)

    const nextTaskId = await getNextTaskId({
      taskId: data.resultsReviewCase?.id,
    })

    void resolveResultsReviewCase({
      variables: {
        ...variables,
        input: {
          ...variables.input,
          caseType: data.resultsReviewCase
            ? 'RESULT_REVIEW'
            : 'DIAGNOSTIC_REPORT',
        },
      },
      refetchQueries: [
        'FindSchedulingCases',
        'FindResultsReviewCases',
        'GetNotResolvedCasesCountByType',
        'GetActivityItems',
        'GetTaskNotificationCount',
      ],
      onCompleted: () => {
        void handleCaseResolution({ nextTaskId })
      },
    })
  }

  return (
    <SidepanelPage
      header="Resolve task"
      description="Check the option(s) that apply before resolving the task."
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Resolve',
          onCancel: () => {
            void resolveResultsReviewCase({
              variables: {
                resultsReviewCaseId,
                input: {
                  cancelCase: true,
                  schedulingFollowupVisit: false,
                },
              },
              onCompleted: () => {
                closeSidePanel()
              },
              refetchQueries: [
                'FindSchedulingCases',
                'FindResultsReviewCases',
                'GetNotResolvedCasesCountByType',
                'GetTaskNotificationCount',
              ],
            })
          },
          cancelText: 'Cancel task',
          submitting: resolvingResultsReviewCase || creatingCaseStatusUpdate,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <StackView space={25}>
          <Space space={75} />
          <Typography textStyle="title" fontWeight="medium">
            Resolution status options
          </Typography>
          <Space space={75} />
          <Divider />
          <DataRow
            label={
              <StackView space={50}>
                <Label>Check the option(s) that apply to the task:</Label>
              </StackView>
            }
            value={
              <StackView space={75}>
                <CheckboxField
                  name="resultsReviewed"
                  label="Results reviewed"
                />
                <CheckboxField
                  name="resultsDiscussedWithPatient"
                  label="Results reviewed; discussed with patient"
                />
                <CheckboxField
                  name="resultsDiscussedWithCaregiver"
                  label="Results reviewed; discussed with caregiver"
                />
                {resultsDiscussedWithCaregiver && (
                  <>
                    <DropdownField
                      options={options}
                      name="caregiver"
                      validation={{
                        required: resultsDiscussedWithCaregiver,
                        shouldUnregister: true,
                      }}
                    />
                    <FieldError
                      name="caregiver"
                      defaultErrorMessage="This field is required"
                    />
                  </>
                )}
                <CheckboxField
                  name="scheduleFollowupVisit"
                  label="Schedule follow-up visit"
                />
              </StackView>
            }
          />
          {scheduleFollowupVisit && (
            <>
              <Divider />
              <FormInputList
                title="Scheduling order"
                items={[
                  {
                    label: 'Patient',
                    required: true,
                    name: 'patient',
                    inputProps: {
                      disabled: true,
                      placeholder: formatDisplayName(data.patient),
                    },
                    formInputComponent: EnhancedPatientSearch,
                  },
                  {
                    label: 'Target visit timing',
                    name: 'targetDate',
                    formInputComponent: TargetDateField,
                  },
                  {
                    label: 'Visit type',
                    required: true,
                    formInputComponent: AppointmentDefinitionComboBoxCell,
                    name: 'visitType',
                  },
                  {
                    label: 'Chief complaints',
                    name: 'chiefComplaints',
                    formInputComponent: ChiefComplaintSelectField,
                    inputProps: {
                      appointmentDefinitionId: visitType?.[0],
                    },
                  },
                  {
                    label: 'Visit practitioner',
                    name: 'visitPractitioner',
                    formInputComponent: PractitionerDropdownCell,
                    required: true,
                    inputProps: {
                      validation: {
                        shouldUnregister: true,
                      },
                    },
                  },
                  {
                    label: 'Location',
                    formInputComponent: LocationDropdownCell,
                    name: 'location',
                    required: true,
                    inputProps: {
                      validation: {
                        shouldUnregister: true,
                      },
                    },
                  },
                  {
                    label: 'Order comments',
                    name: 'scheduleNote',
                    alignItems: 'start',
                    inputProps: {
                      rows: 5,
                    },
                    formInputComponent: TextAreaField,
                  },
                ]}
              />
            </>
          )}
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelResultsReviewCaseResolution
