import clsx from 'clsx'

import Button from 'src/components/atoms/Button'
import DataDisplayValue, {
  DataDisplayableValue,
} from 'src/components/atoms/DataDisplayValue/DataDisplayValue'
import Label from 'src/components/atoms/Label/Label'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import DataRow from 'src/components/molecules/DataRow/DataRow'
import { TooltipProps } from 'src/components/molecules/Tooltip/Tooltip'

export type Data = {
  label: string
  value: DataDisplayableValue
  hide?: boolean
  key?: string
  tooltipProps?: Omit<TooltipProps, 'children'>
}

export interface DataDisplayListProps {
  title?: string
  subtitle?: string
  action?: {
    label: string
    callback: (data: unknown) => void
  }
  data: Data[]
  className?: string
  dataDisplay?: 'inline' | 'stacked'
  rowHeight?: 'sm' | 'md' | 'lg'
  grayedOut?: boolean
  leftColumnWidth?: 'sm' | 'md' | 'lg'
  testId?: string
}

export const DataDisplayList: React.FC<DataDisplayListProps> = ({
  title,
  subtitle,
  action,
  data,
  dataDisplay = 'inline',
  className = 'py-3',
  rowHeight = 'md',
  grayedOut = false,
  leftColumnWidth = 'md',
  testId,
}) => (
  <StackView
    data-testid={testId}
    divider={dataDisplay === 'stacked' ? false : true}
    className={clsx(grayedOut ? 'opacity-50' : '', className)}
  >
    {title || action ? (
      <StackView direction="row">
        {title && (
          <StackView direction="col" className="pb-3" space={25}>
            <Typography
              textStyle="title-xs"
              color={grayedOut ? 'text-base-color-fg-muted' : undefined}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography color="text-base-color-fg-muted">
                {subtitle}
              </Typography>
            )}
          </StackView>
        )}
        {action && action.label.length > 0 && (
          <Button className="h-10 min-w-fit" onClick={action.callback}>
            {action.label}
          </Button>
        )}
      </StackView>
    ) : null}
    {data.map((data) => {
      if (data.hide) return null

      return (
        <DataRow
          direction={dataDisplay === 'stacked' ? 'col' : 'row'}
          key={data.key || data.label}
          data-testid={data.key || data.label}
          label={<Label>{data.label}</Label>}
          value={
            <DataDisplayValue
              value={data.value}
              tooltipProps={data.tooltipProps}
            />
          }
          rowHeight={rowHeight}
          leftColumnWidth={leftColumnWidth}
        />
      )
    })}
  </StackView>
)

export default DataDisplayList
