import { DocumentTypesConfig } from 'common/cdr/concepts/documents'
import { documentTypeDisplay } from 'common/data/documentTypes'
import { DocumentCaseDocumentType } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const documentCaseDocumentTypeDisplay: {
  [key in DocumentCaseDocumentType]: string
} = {
  PATIENT_DOCUMENT: 'Patient document',
  ...documentTypeDisplay,
  SIGNED_PRACTICE_FORM: DocumentTypesConfig['SIGNED_PRACTICE_FORM'].display,
  COMPLETED_PATIENT_FORM: DocumentTypesConfig['COMPLETED_PATIENT_FORM'].display,
  COMPLETED_EXTERNAL_REQUEST:
    DocumentTypesConfig['COMPLETED_EXTERNAL_REQUEST'].display,
  COMPLETED_SCREENING_TOOL:
    DocumentTypesConfig['COMPLETED_SCREENING_TOOL'].display,
  COMPLETED_PRACTICE_LETTER:
    DocumentTypesConfig['COMPLETED_PRACTICE_LETTER'].display,
  CLINICAL_RESULT: DocumentTypesConfig['CLINICAL_RESULT'].display,
  MEDICAL_RECORD: DocumentTypesConfig['MEDICAL_RECORD'].display,
  MEDICATION: DocumentTypesConfig['MEDICATION'].display,
  PATIENT_OR_CAREGIVER_INFORMATION:
    DocumentTypesConfig['PATIENT_OR_CAREGIVER_INFORMATION'].display,
  PATIENT_ORDER: DocumentTypesConfig['PATIENT_ORDER'].display,
  OTHER: DocumentTypesConfig['OTHER'].display,
}

export const documentCaseDocumentTypes =
  nameValueArrayFromObject<DocumentCaseDocumentType>(
    documentCaseDocumentTypeDisplay
  )
