import { NoSymbolIcon } from '@heroicons/react/24/outline'
import { formatDisplayName } from 'common/utils'
import type { AppointmentInsuranceDetailsQuery } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { FRAGMENT as CURRENT_INSURANCE_ELIGIBILITY_FRAGMENT } from 'src/components/InsuranceEligibility/fragments'
import InsuranceEligibility from 'src/components/InsuranceEligibility/InsuranceEligibility'
import DataDisplayList from 'src/components/molecules/DataDisplayList/DataDisplayList'
import { coordinationOfBenefitTypeDisplay } from 'src/data/coordinationOfBenefitTypes'
import { healthPlanTypeDisplay } from 'src/data/healthPlanTypes'

export const QUERY = () => gql`
  query AppointmentInsuranceDetailsQuery($appointmentId: String!) {
    appointment: appointment(id: $appointmentId) {
      id
      patient {
        id
        activeInsuranceCoverages {
          id
          planName
          planMemberIdentifier
          planGroupIdentifier
          coordinationOfBenefitsType
          beneficiaryIsSubscriber
          planType
          subscriber {
            id
            givenName
            familyName
          }
          payer {
            id
            displayName
          }
          mostRecentEligibility(appointmentId: $appointmentId) {
            ...InsuranceEligibilityFragment
          }
        }
      }
      patientRegistrationIntent {
        id
        mostRecentEligibility(appointmentId: $appointmentId) {
          ...InsuranceEligibilityFragment
        }
      }
    }
  }
  ${CURRENT_INSURANCE_ELIGIBILITY_FRAGMENT}
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => (
  <StackView justifyContent="center" alignItems="center" className="h-56">
    <NoSymbolIcon className="h-8 w-8 text-gray-400" />
    <Space space={50} />
    <Typography textStyle="subtitle">No insurance</Typography>
    <Typography color="text-base-color-fg-muted" className="text-center">
      Try contacting the patient and/or caregivers to enter this information.
    </Typography>
  </StackView>
)

export const isEmpty = ({
  appointment,
}: CellSuccessProps<AppointmentInsuranceDetailsQuery>) =>
  !appointment.patient.activeInsuranceCoverages.length

export const Failure = ({ error }: CellFailureProps) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  appointment,
}: CellSuccessProps<AppointmentInsuranceDetailsQuery>) => {
  const {
    patient: { activeInsuranceCoverages },
  } = appointment

  return (
    <StackView space={125}>
      {activeInsuranceCoverages.map((insurance) => (
        <StackView key={insurance.id} space={100}>
          <DataDisplayList
            title={`${
              coordinationOfBenefitTypeDisplay[
                insurance.coordinationOfBenefitsType
              ]
            } Insurance`}
            subtitle="This summarizes important details on a health plan for the patient."
            data={[
              {
                label: 'Payer name',
                value: insurance.payer.displayName,
              },
              {
                label: 'Health plan name',
                value: insurance.planName,
              },
              {
                label: 'Health plan type',
                value: healthPlanTypeDisplay[insurance.planType],
              },
              {
                label: 'Member ID',
                value: insurance.planMemberIdentifier,
              },
              {
                label: 'Group Number',
                value: insurance.planGroupIdentifier,
              },
              {
                label: 'Subscriber',
                value: insurance.beneficiaryIsSubscriber
                  ? 'Patient is subscriber'
                  : formatDisplayName(insurance.subscriber),
              },
            ]}
          />
          <InsuranceEligibility
            insuranceId={insurance.id}
            insuranceEligibility={insurance.mostRecentEligibility}
            disableEdit
            appointmentId={appointment.id}
          />
        </StackView>
      ))}
    </StackView>
  )
}
