import {
  GetRoomOptionsForAppointment,
  GetRoomOptionsForAppointmentVariables,
  UpdateAppointmentRoom,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import Dropdown from 'src/components/atoms/Dropdown/Dropdown'
import { StatusIndicator } from 'src/components/StatusIndicator/StatusIndicator'

interface Props {
  appointmentId: string
}

const ROOMS_QUERY = gql`
  query GetRoomOptionsForAppointment($appointmentId: String!) {
    appointment(id: $appointmentId) {
      id
      roomId
      location {
        id
        rooms {
          id
          name
          occupancy
        }
      }
    }
  }
`

const UPDATE_APPOINTMENT_ROOM_MUTATION = gql`
  mutation UpdateAppointmentRoom($id: String!, $roomId: String) {
    updateAppointmentRoom(id: $id, roomId: $roomId) {
      id
      room {
        id
        name
      }
    }
  }
`

const AppointmentRoomSelect = ({ appointmentId }: Props) => {
  const { data } = useQuery<
    GetRoomOptionsForAppointment,
    GetRoomOptionsForAppointmentVariables
  >(ROOMS_QUERY, {
    variables: {
      appointmentId,
    },
  })
  const [updateAppointmentRoom] = useMutation<UpdateAppointmentRoom>(
    UPDATE_APPOINTMENT_ROOM_MUTATION,
    {
      refetchQueries: ['GetRoomOptionsForAppointment'],
    }
  )

  const rooms = data?.appointment?.location?.rooms ?? []
  const selected = data?.appointment?.roomId

  const options = [
    {
      name: 'No room',
      value: null,
      icon: { value: <StatusIndicator color="light-gray" />, class: '' },
    },
    ...rooms.map(({ id, name, occupancy }) => ({
      name,
      value: id,
      icon: {
        value: (
          <StatusIndicator
            testId="room-option-status-indicator"
            color={
              id === selected ? 'dark-gray' : occupancy > 0 ? 'red' : 'green'
            }
          />
        ),
        class: '',
      },
    })),
  ]

  const onSelect = (roomId: string) => {
    updateAppointmentRoom({
      variables: {
        id: appointmentId,
        roomId,
      },
    })
  }
  return (
    <Dropdown
      mode="id"
      name="appointment-room-select"
      options={options}
      selected={selected ?? null}
      onSelect={onSelect}
    />
  )
}

export default AppointmentRoomSelect
