import { assertUnreachable } from 'common/utils'
import { Patient } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import AllGrowthCharts from 'src/pages/PatientChartsPage/PatientGrowthCharts/AllGrowthCharts'
import { GrowthChartDatasetType } from 'src/pages/PatientChartsPage/PatientGrowthCharts/PatientGrowthCharts'
import { TransformedGrowthChartDatasetsType } from 'src/pages/PatientChartsPage/PatientGrowthCharts/useGrowthCharts'

import { PDFWrapper } from '../useGeneratePDF'

import { LetterHead, PatientDetails } from './common'

const PrintPatientGrowthChartsDocument = ({
  patient,
  transformedGrowthChartDatasets,
  showCorrectedAge,
  zoomToToday,
  connectPoints,
  todayLineX,
  queriedDataset,
  testId,
  onAllGrowthChartsRendered,
}: {
  patient: Patient
  transformedGrowthChartDatasets: TransformedGrowthChartDatasetsType
  showCorrectedAge: boolean
  zoomToToday: boolean
  connectPoints: boolean
  todayLineX: number
  queriedDataset: GrowthChartDatasetType
  testId?: string
  onAllGrowthChartsRendered?: () => void
}) => {
  if (!queriedDataset || !patient) return
  let queriedDatasetString: string

  switch (queriedDataset) {
    case 'WHO_MALE':
      queriedDatasetString = 'WHO 0-2, male'
      break
    case 'WHO_FEMALE':
      queriedDatasetString = 'WHO 0-2, female'
      break
    case 'CDC_MALE':
      queriedDatasetString = 'CDC 2-20, male'
      break
    case 'CDC_FEMALE':
      queriedDatasetString = 'CDC 2-20, female'
      break
    default:
      assertUnreachable(queriedDataset)
  }

  return (
    <PDFWrapper useHtml2Canvas>
      <StackView space={25} className="h-full" data-testid={testId}>
        <LetterHead tenant={patient.tenant} />
        <PatientDetails patient={patient} />

        <StackView
          space={25}
          className="border-b-core-border-width-40 border-base-color-border-subtle pb-core-space-75"
        >
          <Typography textStyle="title-m">Growth Chart</Typography>
          <Typography textStyle="interface-default-s">
            Summary plots using {queriedDatasetString} growth charts.
          </Typography>
        </StackView>

        <AllGrowthCharts
          patient={patient}
          transformedGrowthChartDatasets={transformedGrowthChartDatasets}
          hideTables={true}
          showCorrectedAge={showCorrectedAge}
          zoomToToday={zoomToToday}
          todayLineX={todayLineX}
          connectPoints={connectPoints}
          queriedDataset={queriedDataset}
          growthChartHeight="295px"
          onAllGrowthChartsRendered={() => onAllGrowthChartsRendered?.()}
          isPdf={true}
        />
      </StackView>
    </PDFWrapper>
  )
}

export default PrintPatientGrowthChartsDocument
