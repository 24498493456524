const WideConstrainedPageLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <section
      aria-labelledby="primary-heading"
      className="lg:max-w-8xl flex min-w-0 flex-1 flex-col px-8 sm:px-12 md:px-16 md:pt-12 lg:order-last"
    >
      {children}
    </section>
  )
}

export default WideConstrainedPageLayout
