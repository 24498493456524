import { add, format, parseISO, startOfDay, subMilliseconds } from 'date-fns'

export const getImmunizationColumns = (birthDate) => {
  return [
    {
      colId: 'birth',
      headerName: 'Birth',
      intervalStart: format(
        startOfDay(parseISO(birthDate)),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { weeks: 1 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '1w',
      headerName: '1W',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { weeks: 1 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { months: 1 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '1m',
      headerName: '1M',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { months: 1 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { months: 2 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '2m',
      headerName: '2M',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { months: 2 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { months: 4 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '4m',
      headerName: '4M',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { months: 4 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { months: 6 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '6m',
      headerName: '6M',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { months: 6 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { months: 9 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '9m',
      headerName: '9M',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { months: 9 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { months: 12 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '12m',
      headerName: '12M',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { months: 12 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { months: 15 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '15m',
      headerName: '15M',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { months: 15 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { months: 18 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '18m',
      headerName: '18M',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { months: 18 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { months: 24 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '24m',
      headerName: '24M',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { months: 24 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { months: 30 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '30m',
      headerName: '30M',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { months: 30 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { months: 36 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '3y',
      headerName: '3Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 3 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 4 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '4y',
      headerName: '4Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 4 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 5 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '5y',
      headerName: '5Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 5 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 6 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '6y',
      headerName: '6Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 6 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 7 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '7y',
      headerName: '7Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 7 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 8 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '8y',
      headerName: '8Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 8 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 9 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '9y',
      headerName: '9Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 9 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 10 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '10y',
      headerName: '10Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 10 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 11 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '11y',
      headerName: '11Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 11 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 12 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '12y',
      headerName: '12Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 12 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 13 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '13y',
      headerName: '13Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 13 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 14 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '14y',
      headerName: '14Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 14 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 15 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '15y',
      headerName: '15Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 15 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 16 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '16y',
      headerName: '16Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 16 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 17 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '17y',
      headerName: '17Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 17 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 18 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '18y',
      headerName: '18Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 18 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 19 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '19y',
      headerName: '19Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 19 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 20 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '20y',
      headerName: '20Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 20 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 21 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '21y',
      headerName: '21Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 21 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 22 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '22y',
      headerName: '22Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 22 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 23 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '23y',
      headerName: '23Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 23 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 24 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '24y',
      headerName: '24Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 24 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 25 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '25y',
      headerName: '25Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 25 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 26 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
    {
      colId: '26y',
      headerName: '26Y',
      intervalStart: format(
        startOfDay(add(parseISO(birthDate), { years: 26 })),
        'yyyy-MM-dd HH:mm:ss'
      ),
      intervalEnd: format(
        subMilliseconds(add(parseISO(birthDate), { years: 27 }), 1),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
  ]
}

export const immunizationRows = [
  {
    id: 1,
    immunization: 'Hepatitis B',
    abbreviation: 'HepB',
    totalDosesDescription: '3-dose series',
    routine: true,
    schedule: 1,
    cvxCodes: ['08', '43', '44', '104', '110', '146', '189', '220', '51'],
  },
  {
    id: 42,
    immunization: 'Nirsevimab',
    abbreviation: 'N-RSV-MAb',
    totalDosesDescription: '1-dose series',
    routine: true,
    schedule: 42,
    cvxCodes: ['306', '307'],
  },
  {
    id: 2,
    immunization: 'Rotavirus monovalent',
    abbreviation: 'RV1',
    totalDosesDescription: '2-dose series',
    OR: [2, 3],
    routine: true,
    schedule: 2,
    cvxCodes: ['119'],
  },
  {
    id: 3,
    immunization: 'Rotavirus pentavalent',
    abbreviation: 'RV5',
    totalDosesDescription: '3-dose series',
    OR: [2, 3],
    routine: true,
    schedule: 3,
    cvxCodes: ['116'],
  },
  {
    id: 4,
    immunization: 'Diphtheria, tetanus, acellular pertussis',
    abbreviation: 'DTaP',
    totalDosesDescription: '5-dose series',
    routine: true,
    schedule: 4,
    cvxCodes: ['20', '106', '110', '120', '130', '146'],
  },
  {
    id: 5,
    immunization: 'H. influenzae type b OMP congugate',
    abbreviation: 'Hib PRP-OMP',
    totalDosesDescription: '3-dose series',
    OR: [5, 6],
    routine: true,
    schedule: 5,
    cvxCodes: ['49', '120', '51'],
  },
  {
    id: 6,
    immunization: 'H. influenzae type b TT conjugate',
    abbreviation: 'Hib PRP-T',
    totalDosesDescription: '4-dose series',
    OR: [5, 6],
    routine: true,
    schedule: 6,
    cvxCodes: ['48', '146'],
  },
  {
    id: 43,
    immunization: 'Pneumococcal conjugate, unspecified',
    abbreviation: 'PCV-UF',
    totalDosesDescription: '4-dose series',
    OR: [43, 44, 45, 7, 8, 27],
    routine: true,
    schedule: 7,
    cvxCodes: ['152'],
  },
  {
    id: 44,
    immunization: 'Pneumococcal 7-valent conjugate',
    abbreviation: 'PCV7',
    totalDosesDescription: '4-dose series',
    OR: [43, 44, 45, 7, 8, 27],
    routine: true,
    schedule: 7,
    cvxCodes: ['100'],
  },
  {
    id: 45,
    immunization: 'Pneumococcal 10-valent conjugate',
    abbreviation: 'PCV10',
    totalDosesDescription: '4-dose series',
    OR: [43, 44, 45, 7, 8, 27],
    routine: true,
    schedule: 7,
    cvxCodes: ['177'],
  },
  {
    id: 7,
    immunization: 'Pneumococcal 13-valent conjugate',
    abbreviation: 'PCV13',
    totalDosesDescription: '4-dose series',
    OR: [43, 44, 45, 7, 8, 27],
    routine: true,
    schedule: 7,
    cvxCodes: ['133'],
  },
  {
    id: 8,
    immunization: 'Pneumococcal 15-valent conjugate',
    abbreviation: 'PCV15',
    totalDosesDescription: '4-dose series',
    OR: [43, 44, 45, 7, 8, 27],
    routine: true,
    schedule: 7,
    cvxCodes: ['215'],
  },
  {
    id: 27,
    immunization: 'Pneumococcal 20-valent conjugate',
    abbreviation: 'PCV20',
    totalDosesDescription: '4-dose series',
    OR: [43, 44, 45, 7, 8, 27],
    routine: true,
    schedule: 7,
    cvxCodes: ['216'],
  },
  {
    id: 9,
    immunization: 'Inactivated poliovirus',
    abbreviation: 'IPV',
    totalDosesDescription: '4-dose series',
    routine: true,
    schedule: 9,
    cvxCodes: ['10', '110', '120', '130', '146', '182'],
  },
  {
    id: 10,
    immunization: 'COVID-19 monovalent mRNA',
    abbreviation: '1vCOV-mRNA',
    totalDosesDescription: '3-dose series',
    AND: [10, 11],
    OR: [10, 12],
    routine: true,
    schedule: 10,
    cvxCodes: ['207', '208', '212', '217', '218', '219', '221', '228'],
  },
  {
    id: 11,
    immunization: 'COVID-19 bivalent mRNA',
    abbreviation: '2vCOV-mRNA',
    totalDosesDescription: '3-dose series',
    AND: [10, 11],
    routine: true,
    schedule: 11,
    cvxCodes: [
      '229',
      '230',
      '300',
      '301',
      '302',
      '308',
      '309',
      '310',
      '311',
      '312',
    ],
  },
  {
    id: 12,
    immunization: 'COVID-19 adjuvanted spike protein',
    abbreviation: '1vCOV-aPS',
    totalDosesDescription: '3-dose series',
    OR: [10, 12],
    routine: true,
    schedule: 12,
    cvxCodes: ['211', '313'],
  },
  {
    id: 13,
    immunization: 'Influenza, inactivated',
    abbreviation: 'IIV',
    totalDosesDescription: 'Annual',
    OR: [13, 14, 15],
    routine: true,
    schedule: 13,
    cvxCodes: [
      '135',
      '141',
      '150',
      '158',
      '161',
      '168',
      '171',
      '186',
      '205',
      '140',
      '153',
      '197',
      '320',
      '111',
    ],
  },
  {
    id: 14,
    immunization: 'Influenza, live attenuated',
    abbreviation: 'LAIV',
    totalDosesDescription: 'Annual',
    OR: [13, 14, 15],
    routine: true,
    schedule: 14,
    cvxCodes: ['149'],
  },
  {
    id: 15,
    immunization: 'Influenza, recombinant',
    abbreviation: 'RIV',
    totalDosesDescription: 'Annual',
    OR: [13, 14, 15],
    routine: true,
    schedule: 15,
    cvxCodes: ['155', '185'],
  },
  {
    id: 16,
    immunization: 'Measles, mumps, rubella',
    abbreviation: 'MMR',
    totalDosesDescription: '2-dose series',
    routine: true,
    schedule: 16,
    cvxCodes: ['03', '94'],
    OR: [16, 37, 39],
  },
  {
    id: 37,
    immunization: 'Measles',
    totalDosesDescription: '2-dose series',
    routine: true,
    schedule: 16,
    cvxCodes: ['05'],
    OR: [16, 37, 39],
    AND: [37, 38],
  },
  {
    id: 38,
    immunization: 'Rubella/mumps',
    abbreviation: 'RM',
    totalDosesDescription: '2-dose series',
    routine: true,
    schedule: 16,
    cvxCodes: ['38'],
    AND: [37, 38],
  },
  {
    id: 39,
    immunization: 'Mumps',
    totalDosesDescription: '2-dose series',
    routine: true,
    schedule: 16,
    cvxCodes: ['07'],
    OR: [16, 37, 39],
    AND: [39, 40],
  },
  {
    id: 40,
    immunization: 'Measles/rubella',
    abbreviation: 'MR',
    totalDosesDescription: '2-dose series',
    routine: true,
    schedule: 16,
    cvxCodes: ['04'],
    AND: [39, 40],
  },
  {
    id: 17,
    immunization: 'Varicella',
    abbreviation: 'VAR',
    totalDosesDescription: '2-dose series',
    routine: true,
    schedule: 17,
    cvxCodes: ['21', '94'],
  },
  {
    id: 18,
    immunization: 'Hepatitis A',
    abbreviation: 'HepA',
    totalDosesDescription: '2-dose series',
    routine: true,
    schedule: 18,
    cvxCodes: ['52', '83', '104'],
  },
  {
    id: 19,
    immunization: 'Tetanus, diphtheria, acellular pertussis',
    abbreviation: 'Tdap',
    totalDosesDescription: '1-dose series and 10-year booster',
    OR: [19, 20],
    routine: true,
    schedule: 19,
    cvxCodes: ['115'],
  },
  {
    id: 20,
    immunization: 'Tetanus, diphtheria',
    abbreviation: 'Td',
    totalDosesDescription: '10-year booster',
    OR: [19, 20],
    routine: true,
    schedule: 20,
    cvxCodes: ['09', '28', '113', '196'],
  },
  {
    id: 21,
    immunization: 'Human papillomavirus',
    abbreviation: 'HPV',
    totalDosesDescription: '2-dose series',
    routine: true,
    schedule: 21,
    cvxCodes: ['62', '165'],
  },
  {
    id: 22,
    immunization: 'Meningococcal ACWY CRM conjugate',
    abbreviation: 'MenACWY-CRM',
    totalDosesDescription: '2-dose series',
    OR: [22, 23, 24],
    routine: true,
    schedule: 22,
    cvxCodes: ['136'],
  },
  {
    id: 23,
    immunization: 'Meningococcal ACWY DT conjugate',
    abbreviation: 'MenACWY-D',
    totalDosesDescription: '2-dose series',
    OR: [22, 23, 24],
    routine: true,
    schedule: 23,
    cvxCodes: ['114'],
  },
  {
    id: 24,
    immunization: 'Meningococcal ACWY TT conjugate',
    abbreviation: 'MenACWY-TT',
    totalDosesDescription: '2-dose series',
    OR: [22, 23, 24],
    routine: true,
    schedule: 24,
    cvxCodes: ['203', '316'],
  },
  {
    id: 25,
    immunization: 'Meningococcal B 4-component',
    abbreviation: 'MenB-4C',
    totalDosesDescription: '2-dose series',
    OR: [25, 26],
    routine: true,
    schedule: 25,
    cvxCodes: ['163'],
  },
  {
    id: 26,
    immunization: 'Meningococcal B bivalent',
    abbreviation: 'MenB-FHbp',
    totalDosesDescription: '2-dose series',
    OR: [25, 26],
    routine: true,
    schedule: 26,
    cvxCodes: ['162', '316'],
  },
  {
    id: 41,
    immunization: 'Palivizumab',
    abbreviation: 'P-RSV-MAb',
    totalDosesDescription: 'As needed for high-risk groups',
    routine: false,
    schedule: 41,
    cvxCodes: ['93'],
  },
  {
    id: 28,
    immunization: 'Pneumococcal 23-valent PS',
    abbreviation: 'PPSV23',
    totalDosesDescription: 'As needed for high-risk groups',
    routine: false,
    schedule: 28,
    cvxCodes: ['33'],
  },
  {
    id: 29,
    immunization: 'Zoster',
    abbreviation: 'RZV',
    totalDosesDescription: 'As needed for high-risk groups',
    routine: false,
    schedule: 29,
    cvxCodes: ['121', '187'],
  },
  {
    id: 30,
    immunization: 'Dengue',
    abbreviation: 'DEN4CYD',
    totalDosesDescription: '3-dose series',
    routine: false,
    schedule: 30,
    cvxCodes: ['56'],
  },
  {
    id: 31,
    immunization: 'Typhoid',
    abbreviation: 'TYP',
    totalDosesDescription: 'As needed for overseas travel',
    routine: false,
    schedule: 31,
    cvxCodes: ['25', '101'],
  },
  {
    id: 32,
    immunization: 'Yellow fever',
    abbreviation: 'YF',
    totalDosesDescription: 'As needed for overseas travel',
    routine: false,
    schedule: 32,
    cvxCodes: ['37', '183'],
  },
  {
    id: 33,
    immunization: 'Cholera',
    abbreviation: 'CHO',
    totalDosesDescription: 'As needed for overseas travel',
    routine: false,
    schedule: 33,
    cvxCodes: ['174'],
  },
  {
    id: 34,
    immunization: 'Rabies',
    abbreviation: 'RAB',
    totalDosesDescription: 'As needed for overseas travel',
    routine: false,
    schedule: 34,
    cvxCodes: ['175', '176'],
  },
  {
    id: 35,
    immunization: 'Tuberculosis',
    abbreviation: 'BCG',
    totalDosesDescription: 'As needed for overseas travel',
    routine: false,
    schedule: 35,
    cvxCodes: ['19'],
  },
  {
    id: 36,
    immunization: 'Japanese encephalitis',
    abbreviation: 'JE',
    totalDosesDescription: 'As needed for overseas travel',
    routine: false,
    schedule: 36,
    cvxCodes: ['134'],
  },
]

export const immunizationSchedules = [
  {
    id: 1,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: 'birth',
        end: 'birth',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '1m',
        end: '2m',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '4m',
        end: '4m',
        numberOfDoses: 1,
      },
      {
        name: 'D3',
        scheduleType: 'RECOMMENDED',
        start: '6m',
        end: '18m',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '24m',
        end: '26y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 2,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '2m',
        end: '2m',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '4m',
        end: '4m',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 3,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '2m',
        end: '2m',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '4m',
        end: '4m',
        numberOfDoses: 1,
      },
      {
        name: 'D3',
        scheduleType: 'RECOMMENDED',
        start: '6m',
        end: '6m',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 4,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '2m',
        end: '2m',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '4m',
        end: '4m',
        numberOfDoses: 1,
      },
      {
        name: 'D3',
        scheduleType: 'RECOMMENDED',
        start: '6m',
        end: '6m',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '9m',
        end: '12m',
        numberOfDoses: 1,
      },
      {
        name: 'D4',
        scheduleType: 'RECOMMENDED',
        start: '15m',
        end: '18m',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '24m',
        end: '3y',
        numberOfDoses: 1,
      },
      {
        name: 'D5',
        scheduleType: 'RECOMMENDED',
        start: '4y',
        end: '6y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 5,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '2m',
        end: '2m',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '4m',
        end: '4m',
        numberOfDoses: 1,
      },

      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '9m',
        end: '9m',
        numberOfDoses: 1,
      },
      {
        name: 'D3',
        scheduleType: 'RECOMMENDED',
        start: '12m',
        end: '15m',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '18m',
        end: '3y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 6,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '2m',
        end: '2m',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '4m',
        end: '4m',
        numberOfDoses: 1,
      },
      {
        name: 'D3',
        scheduleType: 'RECOMMENDED',
        start: '6m',
        end: '6m',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '9m',
        end: '9m',
        numberOfDoses: 1,
      },
      {
        name: 'D4',
        scheduleType: 'RECOMMENDED',
        start: '12m',
        end: '15m',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '18m',
        end: '3y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 7,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '2m',
        end: '2m',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '4m',
        end: '4m',
        numberOfDoses: 1,
      },
      {
        name: 'D3',
        scheduleType: 'RECOMMENDED',
        start: '6m',
        end: '6m',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '9m',
        end: '9m',
        numberOfDoses: 1,
      },
      {
        name: 'D4',
        scheduleType: 'RECOMMENDED',
        start: '12m',
        end: '15m',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '18m',
        end: '5y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 9,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '2m',
        end: '2m',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '4m',
        end: '4m',
        numberOfDoses: 1,
      },
      {
        name: 'D3',
        scheduleType: 'RECOMMENDED',
        start: '6m',
        end: '18m',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '24m',
        end: '3y',
        numberOfDoses: 1,
      },
      {
        name: 'D4',
        scheduleType: 'RECOMMENDED',
        start: '4y',
        end: '6y',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '7y',
        end: '17y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 10,
    schedule: [
      {
        name: 'D1 & D2',
        scheduleType: 'RECOMMENDED',
        start: '6m',
        end: '6m',
        numberOfDoses: 2,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '9m',
        end: '26y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 11,
    schedule: [
      {
        name: 'D3',
        scheduleType: 'RECOMMENDED',
        start: '9m',
        end: '9m',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '12m',
        end: '26y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 12,
    schedule: [
      {
        name: 'D1 to D3',
        scheduleType: 'RECOMMENDED',
        start: '12y',
        end: '12y',
        numberOfDoses: 3,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '13y',
        end: '26y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 13,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '6m',
        end: '6m',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '9m',
        end: '9m',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '12m',
        end: '18m',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '24m',
        end: '30m',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '3y',
        end: '3y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '4y',
        end: '4y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '5y',
        end: '5y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '6y',
        end: '6y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '7y',
        end: '7y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '8y',
        end: '8y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '9y',
        end: '9y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '10y',
        end: '10y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '11y',
        end: '11y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '12y',
        end: '12y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '13y',
        end: '13y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '14y',
        end: '14y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '15y',
        end: '15y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '16y',
        end: '16y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '17y',
        end: '17y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '18y',
        end: '18y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '19y',
        end: '19y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '20y',
        end: '20y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '21y',
        end: '21y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '22y',
        end: '22y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '23y',
        end: '23y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '24y',
        end: '24y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '25y',
        end: '25y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '26y',
        end: '26y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 14,
    schedule: [
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '24m',
        end: '30m',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '3y',
        end: '3y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '4y',
        end: '4y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '5y',
        end: '5y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '6y',
        end: '6y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '7y',
        end: '7y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '8y',
        end: '8y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '9y',
        end: '9y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '10y',
        end: '10y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '11y',
        end: '11y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '12y',
        end: '12y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '13y',
        end: '13y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '14y',
        end: '14y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '15y',
        end: '15y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '16y',
        end: '16y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '17y',
        end: '17y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '18y',
        end: '18y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '19y',
        end: '19y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '20y',
        end: '20y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '21y',
        end: '21y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '22y',
        end: '22y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '23y',
        end: '23y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '24y',
        end: '24y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '25y',
        end: '25y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '26y',
        end: '26y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 15,
    schedule: [
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '19y',
        end: '19y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '20y',
        end: '20y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '21y',
        end: '21y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '22y',
        end: '22y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '23y',
        end: '23y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '24y',
        end: '24y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '25y',
        end: '25y',
        numberOfDoses: 1,
      },
      {
        name: 'Annual',
        scheduleType: 'RECOMMENDED',
        start: '26y',
        end: '26y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 16,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '12m',
        end: '15m',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '18m',
        end: '3y',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '4y',
        end: '6y',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '7y',
        end: '26y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 17,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '12m',
        end: '15m',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '18m',
        end: '3y',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '4y',
        end: '6y',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '7y',
        end: '26y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 18,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '12m',
        end: '12m',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '18m',
        end: '18m',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '24m',
        end: '26y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 19,
    schedule: [
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '7y',
        end: '10y',
        numberOfDoses: 1,
      },
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '11y',
        end: '12y',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '13y',
        end: '20y',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '21y',
        end: '22y',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '23y',
        end: '26y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 20,
    schedule: [
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '21y',
        end: '22y',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '23y',
        end: '26y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 21,
    schedule: [
      {
        name: 'D1 & D2',
        scheduleType: 'RECOMMENDED',
        start: '9y',
        end: '12y',
        numberOfDoses: 2,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '13y',
        end: '26y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 22,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '11y',
        end: '12y',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '13y',
        end: '15y',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '16y',
        end: '16y',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '17y',
        end: '19y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 23,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '11y',
        end: '12y',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '13y',
        end: '15y',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '16y',
        end: '16y',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '17y',
        end: '19y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 24,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '11y',
        end: '12y',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '13y',
        end: '15y',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '16y',
        end: '16y',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '17y',
        end: '19y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 25,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '16y',
        end: '16y',
        numberOfDoses: 1,
      },

      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '17y',
        end: '17y',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '18y',
        end: '23y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 26,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '16y',
        end: '16y',
        numberOfDoses: 1,
      },
      {
        name: 'D2',
        scheduleType: 'RECOMMENDED',
        start: '17y',
        end: '17y',
        numberOfDoses: 1,
      },
      {
        name: '',
        scheduleType: 'CATCHUP',
        start: '18y',
        end: '23y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 30,
    schedule: [
      {
        name: 'Seropositive in endemic areas: 0, 6, and 12 months with 3-dose series',
        scheduleType: 'RECOMMENDED',
        start: '9y',
        end: '16y',
        numberOfDoses: 1,
      },
    ],
  },
  {
    id: 42,
    schedule: [
      {
        name: 'D1',
        scheduleType: 'RECOMMENDED',
        start: '1w',
        end: '6m',
        numberOfDoses: 1,
      },
    ],
  },
]
