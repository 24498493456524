import { useMemo, useState } from 'react'
import * as React from 'react'

import { $generateNodesFromDOM } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin'
import clsx from 'clsx'
import { $getSelection, $isRangeSelection, TextNode } from 'lexical'
import * as ReactDOM from 'react-dom'
import { MacroPhraseSection, MacroPhraseSubSection } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { lexicalToHTML } from 'src/components/atoms/WysiwygField/utils/utils'

const QUERY = gql`
  query GetMacroPhrasesBySection($section: MacroPhraseSection!) {
    macroPhrasesBySection(section: $section) {
      id
      keyword
      title
      phrase
      subsection
      tenantId
    }
  }
`

class MacroPhraseOption extends MenuOption {
  id: string
  title: string
  keyword: string
  phrase: string

  constructor(
    id: string,
    options: {
      title: string
      keyword: string
      phrase: string
    }
  ) {
    super(id)
    this.id = id
    this.title = options.title
    this.keyword = options.keyword
    this.phrase = options.phrase
  }
}

function MenuItem({
  isSelected,
  onClick,
  option,
}: {
  isSelected: boolean
  onClick: () => void
  option: MacroPhraseOption
}) {
  return (
    <StackView
      key={option.id}
      className={clsx([
        'group flex w-full items-center px-2 py-2 text-sm text-gray-900',
        'min-h-8 whitespace-nowrap hover:!bg-primary hover:!text-white',
        isSelected && 'bg-primary text-white',
      ])}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      onClick={onClick}
      space={75}
      direction="row"
    >
      <Typography>/{option.keyword}</Typography>
      <Typography className="truncate">{option.title}</Typography>
    </StackView>
  )
}

const MacroPhrasePlugin = ({
  section,
  subsection,
  typeaheadPosition,
}: {
  section?: MacroPhraseSection
  subsection?: MacroPhraseSubSection
  typeaheadPosition: 'above' | 'below'
}) => {
  const [editor] = useLexicalComposerContext()
  const [queryString, setQueryString] = useState<string | null>(null)

  const { data } = useQuery(QUERY, {
    variables: {
      section,
    },
    skip: !section,
  })

  const checkForTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  })

  const options = useMemo(() => {
    if (data) {
      const filteredData = data.macroPhrasesBySection.filter((macroPhrase) => {
        if (subsection) {
          return subsection === macroPhrase.subsection
        }
        return true
      })
      const macroPhrases: MacroPhraseOption[] = filteredData.map(
        (macroPhrase) =>
          new MacroPhraseOption(macroPhrase.id, {
            title: macroPhrase.title,
            keyword: macroPhrase.keyword,
            phrase: macroPhrase.phrase,
          })
      )

      return queryString
        ? macroPhrases.filter((option) => {
            return (
              new RegExp(queryString, 'gi').exec(option.keyword) ||
              new RegExp(queryString, 'gi').exec(option.title)
            )
          })
        : macroPhrases
    } else {
      return []
    }
  }, [data, queryString, subsection])

  const onSelect = (
    selectedOption: MacroPhraseOption,
    textNodeContainingQuery: TextNode
  ) => {
    setQueryString(null)

    lexicalToHTML(selectedOption.phrase).then((res) => {
      const parser = new DOMParser()
      const html = parser.parseFromString(res as string, 'text/html')
      editor.update(() => {
        textNodeContainingQuery.remove()
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          const nodes = $generateNodesFromDOM(editor, html)

          selection.insertNodes(nodes)
        }
      })
    })
  }

  const onQueryChange = (queryString) => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        // // Only set the query string if there is no other text in this Node
        if (selection.getNodes()[0].getTextContent().startsWith('/')) {
          setQueryString(queryString)
        }
      }
    })
  }

  return (
    <LexicalTypeaheadMenuPlugin<MacroPhraseOption>
      onQueryChange={onQueryChange}
      onSelectOption={onSelect}
      triggerFn={checkForTriggerMatch}
      options={options}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp }
      ) =>
        anchorElementRef.current && options.length && queryString !== null
          ? ReactDOM.createPortal(
              <Box
                color="bg-base-color-bg-default"
                border
                rounded
                className={clsx([
                  'absolute max-h-72 w-fit min-w-full max-w-sm origin-top-right divide-y divide-gray-100',
                  'overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                  'left-0',
                  `${typeaheadPosition === 'above' ? 'bottom-8' : 'top-8'}`,
                ])}
              >
                {options.map((option, i: number) => (
                  <MenuItem
                    key={option.id}
                    isSelected={selectedIndex === i}
                    onClick={() => selectOptionAndCleanUp(option)}
                    option={option}
                  />
                ))}
              </Box>,
              anchorElementRef.current
            )
          : null
      }
    />
  )
}

export default MacroPhrasePlugin
